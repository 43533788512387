import React, { useEffect } from 'react';
import { QuoteListConnectedProps } from './quote-list.container';
import { FormattedMessage, useIntl } from 'react-intl';
import DropDown from '../../components/common/dropdown';
import VaultButton from '../../components/common/vault-button';
import Button from '@material-ui/core/Button';
import ServerSideDataGrid from '../../components/common/VpDataGrid/ServerSideDataGrid';
import { GridApi, IDatasource } from '@ag-grid-community/all-modules';
import { AgGridColumn } from '@ag-grid-community/react';
import AlertModal from '../../components/common/alert-modal';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import useStyles from './styles';
function QuoteList({
  emailTemplateList,
  fetchQuoteList,
  deleteQuotesList,
  getAllEmailTemplates,
  sendMassFollowUp,
  updateEmailTemplateList,
}: QuoteListConnectedProps) {
  const intl = useIntl();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<QuoteListItem[]>([]);
  const [gridApi, setGridApi] = React.useState<null | GridApi>(null);
  const classes = useStyles();

  useEffect(() => {
    getAllEmailTemplates({ relatedToObj: ['quoteFollowup'] });

    return () => {
      updateEmailTemplateList([]);
    };
  }, []);

  React.useEffect(() => {
    if (gridApi) {
      gridApi.addEventListener('select', () => {
        setSelectedRows([]);
      });
    }
  }, [gridApi]);

  const handleDeleteList = async () => {
    if (selectedRows?.length) {
      const uuidArray = selectedRows.map((q) => q.uuid);
      await deleteQuotesList(uuidArray);
      gridApi?.refreshInfiniteCache();
      gridApi?.deselectAll();
    }

    setShowDeleteModal(false);
  };

  const handleFollowUp = async (template: string) => {
    if (selectedRows.length > 0) {
      await sendMassFollowUp(
        selectedRows.map((q) => q.uuid),
        template,
      );
      gridApi?.deselectAll();
    }
  };

  const datasource: IDatasource = {
    getRows: async (params) => {
      const quotes = await fetchQuoteList(params);

      params.successCallback(quotes?.data ?? [], quotes?.total);
    },
  };

  const checkSelectedNodes = () => {
    const selectedNodes = gridApi?.getSelectedNodes();
    if (selectedNodes) {
      setSelectedRows(selectedNodes?.map((n) => n.data as QuoteListItem));
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <div>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        alignItems="center"
        className={classes.headerSpaceBottom}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Hidden smDown>
            <Typography data-cy="quotes-header" variant="h1" gutterBottom>
              {intl.formatMessage({ id: 'quote.quotes' })}
            </Typography>
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.buttonsRoot}>
          <DropDown
            options={emailTemplateList.map((t) => ({
              label: intl.formatMessage({ id: t.label }),
              handler: () => handleFollowUp(t.value),
            }))}
          >
            <VaultButton
              dataCy="followup-quote-btn"
              label={intl.formatMessage({ id: 'button.follow_up' })}
              disabled={!selectedRows.length}
            />
          </DropDown>
          <Button
            data-cy="delete-quote-btn"
            size="medium"
            variant="contained"
            color="primary"
            className={classes.spaceLeft}
            disabled={!selectedRows.length}
            onClick={() => setShowDeleteModal(true)}
          >
            <FormattedMessage id="grid.delete" />
          </Button>
        </Grid>
      </Grid>

      <ServerSideDataGrid
        onInit={setGridApi}
        api={gridApi}
        onSelectionChanged={checkSelectedNodes}
        datasource={datasource}
        fitType="fit"
        rowSelection="multiple"
      >
        <AgGridColumn type="quote:selectAll" />
        <AgGridColumn floatingFilter type="quote:idLabelWithLink" />
        <AgGridColumn floatingFilter type="quote:companyName" />
        <AgGridColumn floatingFilter type="quote:customerEmail" />
        <AgGridColumn floatingFilter type="quote:equipmentCost" />
        <AgGridColumn floatingFilter type="quote:status" />
        <AgGridColumn floatingFilter type="quote:createdAt" />
        <AgGridColumn floatingFilter type="quote:updatedAt" />
      </ServerSideDataGrid>
      <AlertModal
        title={intl.formatMessage({ id: 'grid.delete_question_title' })}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={handleDeleteList}
      />
    </div>
  );
}

export default QuoteList;
