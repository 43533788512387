import React, { useEffect } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { useFormContext, useWatch } from 'react-hook-form';

import useStyles from './styles';
import { getBackendUrl } from '../../../utils/getBackendUrl';
import BooleanInput from '../BooleanInput';
import NoAvailableLogoIcon from '../../icons/no-available-logo';

type VendorLogoSettingsPropsType = {
  changeLogo?: (
    logo: FormData,
    vendorDocumentsLogo?: boolean,
    companyDocumentsLogo?: boolean,
  ) => Promise<boolean>;
  deleteLogo?: (
    vendorUuid: string,
    vendorDocumentsLogo?: boolean,
    companyDocumentsLogo?: boolean,
  ) => Promise<boolean>;
  handleLogoDocumentsStates: () => void;
  vendorUuid: string;
  vendorDocumentsLogoState: boolean;
  companyDocumentsLogoState: boolean;
  currentLogo?: string; // file name in static folder
  isVendorFormDirty?: boolean;
  setDisplayChangesModal?: (state: string | false) => void;
  displayChangesModal?: string | false;
};

export default function VendorLogoSettings({
  changeLogo,
  deleteLogo,
  handleLogoDocumentsStates,
  vendorUuid,
  vendorDocumentsLogoState,
  companyDocumentsLogoState,
  currentLogo,
  isVendorFormDirty,
  setDisplayChangesModal,
  displayChangesModal,
}: VendorLogoSettingsPropsType) {
  const classes = useStyles();
  const intl = useIntl();
  const { setValue } = useFormContext();
  const vendorDocumentsLogo = useWatch({
    name: 'vendorDocumentsLogo',
    defaultValue: vendorDocumentsLogoState,
  });
  const companyDocumentsLogo = useWatch({
    name: 'companyDocumentsLogo',
    defaultValue: companyDocumentsLogoState,
  });
  const onChange = async (
    formData: FormData,
    vendorDocumentsLogo?: boolean,
    companyDocumentsLogo?: boolean,
  ) => {
    await changeLogo?.(formData, vendorDocumentsLogo, companyDocumentsLogo);
  };

  const onDrop = async (files: File[]) => {
    const file = files[0];
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('logo', file);
    await onChange?.(formData, vendorDocumentsLogo, companyDocumentsLogo);
    // await changeLogo?.(formData, vendorDocumentsLogo, companyDocumentsLogo);
  };

  const { open, getInputProps } = useDropzone({
    onDrop: changeLogo ? onDrop : undefined,
    maxFiles: 1,
    accept: 'image/*,',
  });

  const deleteLogoAction = async () => {
    const result = await deleteLogo?.(vendorUuid, vendorDocumentsLogo, companyDocumentsLogo);
    if (result) {
      setValue('vendorDocumentsLogo', false);
    }
  };
  const deleteLogoHandler = async () => {
    if (!displayChangesModal && isVendorFormDirty && setDisplayChangesModal)
      return setDisplayChangesModal('deleting');
    await deleteLogoAction();
  };
  const openLogoHandler = () => {
    if (!displayChangesModal && isVendorFormDirty && setDisplayChangesModal)
      return setDisplayChangesModal('replacing');
    return open();
  };

  const isReadOnly = !(deleteLogo && changeLogo);

  useEffect(() => {
    if (!isReadOnly) {
      handleLogoDocumentsStates();
    }
  }, [vendorDocumentsLogo, isReadOnly]);

  useEffect(() => {
    switch (displayChangesModal) {
      case 'deleting_saved':
        deleteLogoAction();
        setDisplayChangesModal && setDisplayChangesModal(false);
        return;
      case 'replacing_saved':
        open();
        setDisplayChangesModal && setDisplayChangesModal(false);
        return;
    }
  }, [displayChangesModal]);

  return (
    <>
      <input {...getInputProps()} />
      <div className={classes.container}>
        <Typography data-cy="vend-logo-header" className={classes.header} variant="h2">
          <span>{intl.formatMessage({ id: 'company.logo' })}</span>
        </Typography>
        <div className={classes.previewSection}>
          {currentLogo ? (
            <div
              data-cy="vend-logo-img"
              className={classes.preview}
              style={{
                backgroundImage: `url(${getBackendUrl()}/static/${encodeURI(currentLogo)}`,
              }}
            />
          ) : (
            <div data-cy="vend-logo-img" className={classes.preview}>
              <NoAvailableLogoIcon viewBox="0 0 56 56" className={classes.noPhotoIcon} />
            </div>
          )}
        </div>
        <Grid container>
          {!isReadOnly && (
            <Grid item className={currentLogo ? classes.buttonsWrapper : classes.addLogoButton}>
              {currentLogo ? (
                <React.Fragment>
                  <Button
                    data-cy="replace-logo-btn"
                    size="medium"
                    // onClick={open}
                    onClick={openLogoHandler}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    {intl.formatMessage({ id: 'common.logo_replace' })}
                  </Button>
                  <Button
                    data-cy="delete-logo-btn"
                    size="medium"
                    onClick={deleteLogoHandler}
                    variant="contained"
                    color="default"
                    className={classes.button}
                  >
                    {intl.formatMessage({
                      id: 'common.logo_delete',
                    })}
                  </Button>
                </React.Fragment>
              ) : (
                <Button
                  data-cy="add-logo-btn"
                  size="medium"
                  // onClick={open}
                  onClick={openLogoHandler}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {intl.formatMessage({ id: 'common.add_logo' })}
                </Button>
              )}
            </Grid>
          )}
          <BooleanInput
            data-cy="add-vend-logo-to-document"
            label={intl.formatMessage({ id: 'common.add_logo_to_document' }, { name: 'Vendor' })}
            name="vendorDocumentsLogo"
            disabled={!currentLogo}
            labelPosition="after"
            size="small"
            color="primary"
            className={classes.checkboxSpan}
          />
        </Grid>
      </div>
    </>
  );
}
