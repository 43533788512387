import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function NoAvailableLogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M48.4875 14.5126C49.1709 15.196 49.1709 16.304 48.4875 16.9875C47.804 17.6709 46.696 17.6709 46.0126 16.9875C45.3291 16.304 45.3291 15.196 46.0126 14.5126C46.696 13.8291 47.804 13.8291 48.4875 14.5126Z"
          fill="#BDBDBD"
        />
        <path
          d="M50.75 7.00006H41.7009C40.9779 4.9629 39.0315 3.50012 36.75 3.50012H19.25C17.0015 3.50012 15.0898 4.9275 14.3427 6.91895L17.1585 9.7347C17.3576 9.45061 17.5001 9.12367 17.5001 8.75015C17.5001 7.78461 18.2845 7.00018 19.25 7.00018H36.75C37.7155 7.00018 38.4999 7.78461 38.4999 8.75015C38.4999 9.71743 39.2826 10.5001 40.2499 10.5001H50.7498C51.7154 10.5001 52.4998 11.2846 52.4998 12.2501V43.75C52.4998 44.1231 52.3568 44.4499 52.1576 44.7339L54.6521 47.2285C55.4805 46.3001 55.9999 45.0895 55.9999 43.75V12.25C55.9999 9.35497 53.645 7.00006 50.75 7.00006Z"
          fill="#BDBDBD"
        />
        <path
          d="M40.8874 33.4637C41.6024 31.7838 41.9999 29.9378 41.9999 28C41.9999 20.2805 35.7193 14 27.9999 14C26.0621 14 24.2162 14.3975 22.5362 15.1125L25.3151 17.8913C26.1772 17.6617 27.0661 17.4999 27.9999 17.4999C33.7899 17.4999 38.4998 22.2099 38.4998 27.9999C38.4998 28.9336 38.3381 29.8225 38.1084 30.6847C38.1085 30.6848 40.8874 33.4637 40.8874 33.4637Z"
          fill="#BDBDBD"
        />
        <path
          d="M0 2.47478L4.5918 7.06658C2.00996 7.39426 0 9.58048 0 12.2502V43.7501C0 46.6451 2.35503 49.0001 5.25003 49.0001H46.5255L53.5255 56.0001L56.0001 53.5255L2.47465 0L0 2.47478ZM5.25003 45.5002C4.28449 45.5002 3.50006 44.7158 3.50006 43.7502V12.2502C3.50006 11.2846 4.28449 10.5002 5.25003 10.5002H8.02541L17.0183 19.4931C15.1705 21.858 14.0001 24.7732 14.0001 28.0002C14.0001 35.7197 20.2807 42.0002 28.0001 42.0002C31.2275 42.0002 34.1501 40.838 36.5162 38.991L43.0255 45.5003L5.25003 45.5002ZM19.5024 21.9773L34.0322 36.5071C32.3215 37.7346 30.2622 38.5002 28 38.5002C22.21 38.5002 17.5001 33.7902 17.5001 28.0002C17.4999 25.7385 18.2746 23.6872 19.5024 21.9773Z"
          fill="#BDBDBD"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
