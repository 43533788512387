import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  editorWrapper: {
    position: 'relative',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      marginTop: theme.spacing(3),
    },
    '& > label': {
      position: 'absolute',
      backgroundColor: 'white',
      padding: '5px',
      top: '-12px',
      left: '5px',
      zIndex: 10,
      fontSize: '0.6rem',
      '& > span': {
        color: 'red',
      },
    },
    '&.invalid > label': {
      color: 'red',
    },
  },
  invalid: {
    border: '1px solid red',
    '& > label': {
      color: 'red',
    },
  },
  bodyInput: {
    position: 'relative',
    zIndex: 9,
    '& > .monaco-editor': {
      border: '0 !important',
    },
  },
}));

export default useStyles;
