import { Dispatch } from 'react';

import * as vendorApi from '../../apis/vendor';
import * as vendorProgramApi from '../../apis/vendorProgram';
import { actions as toastActions } from '../../store/toast';
import { actions } from './reducer';
import { actions as contactsActions } from '../contacts';
import { getIntl } from '../../utils/language';

export const fetchVendorsList =
  () => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let vendorsList;
    try {
      vendorsList = (await vendorApi.getAllVendors()).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }
    dispatch(actions.updateVendorList(vendorsList));
  };
export const getVendorUsers =
  () => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let vendorsUsers;

    try {
      vendorsUsers = (await vendorProgramApi.getVendorReps()).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }
    dispatch(actions.updateVendorUsers(vendorsUsers));
  };

export const getVendorById =
  (vendorUuid: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let vendor;
    try {
      vendor = (await vendorApi.getVendorByUuid(vendorUuid)).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.updateVendorOnEdit(vendor));
  };

export const getVendorBySiteUrl =
  (vendorSiteUrl: string, userSiteUrl?: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let vendor;
    try {
      vendor = (await vendorApi.getVendorBySiteUrl(vendorSiteUrl, userSiteUrl)).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));
      dispatch(actions.updateApplicationDisabledStatus());

      return;
    }
    dispatch(actions.updateVendorUsers([vendor]));
  };

export const updateVendor =
  (vendorUuid: string, vendorObj: Omit<VendorCompanyType, 'vendorCompany'>) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await vendorApi.updateVendorById(vendorUuid, vendorObj);
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'common.vendor_updated' }),
    };

    dispatch(toastActions.open(toastOptions));
  };

export const changeVendorLogo =
  (
    vendorUuid: string,
    logo: FormData,
    isUpdateSetting?: boolean,
    vendorDocumentsLogo?: boolean,
    companyDocumentsLogo?: boolean,
  ) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      const logoImage = (await vendorApi.changeLogo(vendorUuid, logo)).data;
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'common.vendor_logo_uploaded' }),
      };

      dispatch(actions.updateVendor({ ...logoImage, vendorDocumentsLogo, companyDocumentsLogo }));
      if (isUpdateSetting) {
        dispatch(
          contactsActions.updateVendorLogo({
            logo: logoImage.logoImage,
            logoExist: !!logoImage.logoImage,
          }),
        );
      }
      dispatch(toastActions.open(toastOptions));

      return true;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));

      return false;
    }
  };

export const deleteVendorLogo =
  (vendorUuid: string, vendorDocumentsLogo?: boolean, companyDocumentsLogo?: boolean) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await vendorApi.removeVendorLogo(vendorUuid);
      dispatch(actions.updateVendor({ logoImage: '', vendorDocumentsLogo, companyDocumentsLogo }));
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'common.vendor_logo_deleted' }),
      };
      dispatch(contactsActions.updateVendorLogo({ logo: '', logoExist: false }));
      dispatch(toastActions.open(toastOptions));

      return true;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));

      return false;
    }
  };

export const addFilesToVendor =
  (vendorUuid: string, files: File[]) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    const formData = new FormData();
    files.map((file) => {
      formData.append('file', file);
    });
    let savedFiles;
    try {
      savedFiles = (await vendorApi.addFileToVendor(vendorUuid, formData)).data;
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'quote.attachments_file_save_error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.addFiles(savedFiles));
  };

export const removeFileFromVendor =
  (quoteUuid: string, fileUuid: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await vendorApi.deleteFileFromVendor(quoteUuid, fileUuid);
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.error_removing_file' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.removeFile(fileUuid));
  };
