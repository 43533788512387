import React from 'react';
import { Typography, Button, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { UseFormMethods, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import TextInput from '../TextInput';
import { DEFAULT_LANGUAGE, USER_ROLE } from '../../../constants/formValues';
import VaultMenu from '../VaultMenu';
import AreaCodeFormatter from '../AreaCodeFormatter';
import PhoneFormatter from '../PhoneFormatter';
import ExtensionFormatter from '../ExtensionFormatter';
import { USER_EMAIL_PATTERN } from '../../../constants/regex';
import ContentCopyIcon from '../../icons/content-copy';
import BooleanInput from '../BooleanInput';
import ClipboardButton from '../ClipboardButton';

import useStyles from './styles';

type CreateUserFormProps = {
  handleSubmit: UseFormMethods['handleSubmit'];
  handleSaveUser: () => void;
  myProfile?: boolean;
  vendorSiteUrl: string;
  userSiteUrl?: string;
  isReadOnly?: boolean;
  isSidebar?: boolean;
};

function EditUserForm(props: CreateUserFormProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { errors, reset, control } = useFormContext();

  const {
    handleSubmit,
    handleSaveUser,
    myProfile = false,
    vendorSiteUrl,
    userSiteUrl,
    isReadOnly,
    isSidebar,
  } = props;
  const userSiteUrlString = `${document.location.origin}/${vendorSiteUrl}/${
    userSiteUrl || control.getValues('userSiteUrl')
  }/instant-quote`;
  const userSiteUrlStringFr = `${document.location.origin}/${vendorSiteUrl}/${
    userSiteUrl || control.getValues('userSiteUrl')
  }/instant-quote?lang=fr`;
  const userSiteUrlApplicationString = `${document.location.origin}/${vendorSiteUrl}/${
    userSiteUrl || control.getValues('userSiteUrl')
  }/create-application`;
  const userSiteUrlApplicationStringFr = `${document.location.origin}/${vendorSiteUrl}/${
    userSiteUrl || control.getValues('userSiteUrl')
  }/create-application?lang=fr`;
  const gridLgWidth = isSidebar ? 7 : 6;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={11} md={9} lg={gridLgWidth}>
        <Paper
          component="form"
          aria-disabled
          onSubmit={handleSubmit(handleSaveUser)}
          className={classes.formWrapper}
          variant="outlined"
        >
          <Grid container spacing={6}>
            {myProfile ? (
              <Grid item xs={12} sm={12}>
                <TextInput
                  name="email"
                  label={intl.formatMessage({ id: 'common.email_login' })}
                  fullWidth
                  disabled={isReadOnly}
                  required
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage({ id: 'common.input_email' }),
                    },
                    pattern: USER_EMAIL_PATTERN,
                  }}
                  defaultValue=""
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={4}>
                  <VaultMenu
                    required
                    options={USER_ROLE}
                    disabled={isReadOnly}
                    className={classes.formControl}
                    label={intl.formatMessage({ id: 'user.user_role' })}
                    name="role"
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextInput
                    name="email"
                    disabled={isReadOnly}
                    label={intl.formatMessage({ id: 'common.email_login' })}
                    fullWidth
                    required
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage({ id: 'common.input_email' }),
                      },
                      pattern: USER_EMAIL_PATTERN,
                    }}
                    defaultValue=""
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={8}>
              <TextInput
                name="firstName"
                label={intl.formatMessage({ id: 'common.first_name' })}
                fullWidth
                required
                rules={{ required: intl.formatMessage({ id: 'common.please_input_first_name' }) }}
                disabled={myProfile || isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput
                name="lastName"
                label={intl.formatMessage({ id: 'common.last_name' })}
                fullWidth
                required
                rules={{ required: intl.formatMessage({ id: 'common.please_input_last_name' }) }}
                disabled={myProfile || isReadOnly}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography variant="button">
                {intl.formatMessage({ id: 'user_managment.phone' })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={4}>
              <AreaCodeFormatter
                name="phoneAreaCode"
                label={intl.formatMessage({ id: 'common.area_code' })}
                fullWidth
                error={errors.phoneAreaCode}
                helperText={errors.phoneAreaCode?.message}
                phoneformatter={{ id: 'phone' }}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PhoneFormatter
                name="phone"
                error={errors.phone}
                helperText={errors.phone?.message}
                label={intl.formatMessage({ id: 'common.phone' })}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ExtensionFormatter
                name="phoneExt"
                label={intl.formatMessage({ id: 'common.phone_ext' })}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography variant="button">
                {intl.formatMessage({ id: 'common.mobile_phone' })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={4}>
              <AreaCodeFormatter
                name="mobileAreaCode"
                label={intl.formatMessage({ id: 'common.area_code' })}
                error={errors.mobilePhoneAreaCode}
                helperText={errors.mobilePhoneAreaCode?.message}
                fullWidth
                phoneformatter={{ id: 'mobilePhone' }}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <PhoneFormatter
                name="mobilePhone"
                error={errors.mobilePhone}
                helperText={errors.mobilePhone?.message}
                label={intl.formatMessage({ id: 'common.mobile_phone' })}
                required={false}
                disabled={isReadOnly}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={classes.siteUrl}>
            <Grid item xs={12} sm={12}>
              <TextInput
                name="userSiteUrl"
                disabled
                label={`${intl.formatMessage({
                  id: 'common.user_site_url_for_quote',
                })} - ${intl.formatMessage({ id: 'common.english' })}`}
                fullWidth
                value={userSiteUrlString}
                className={classes.userSite}
              />
              <ClipboardButton
                data-cy="copy-btn"
                variant="contained"
                color="primary"
                value={userSiteUrlString}
                className={classes.copyButton}
                startIcon={<ContentCopyIcon />}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextInput
                name="userSiteUrl"
                disabled
                label={`${intl.formatMessage({
                  id: 'common.user_site_url_for_quote',
                })} - ${intl.formatMessage({ id: 'common.french' })}`}
                fullWidth
                value={userSiteUrlStringFr}
                className={classes.userSite}
              />
              <ClipboardButton
                data-cy="copy-btn"
                variant="contained"
                color="primary"
                value={userSiteUrlStringFr}
                className={clsx(classes.copyButton, 'copyFr')}
                startIcon={<ContentCopyIcon />}
              />
            </Grid>
          </Grid>
          <Grid
            data-cy="site-active"
            item
            xs={4}
            sm={4}
            justifyContent="center"
            direction="column"
            alignItems="flex-start"
            container
            className={classes.booleanInputRight}
          >
            <BooleanInput
              label={intl.formatMessage({ id: 'common.site_active' })}
              name="siteActive"
              size="small"
              color="primary"
              disabled={isReadOnly}
              labelPosition="after"
            />
          </Grid>
          <Grid container className={classes.siteApplicationUrl}>
            <Grid item xs={12} sm={12}>
              <TextInput
                name="userSiteUrl"
                disabled
                label={`${intl.formatMessage({
                  id: 'common.user_site_url_for_application',
                })} - ${intl.formatMessage({ id: 'common.english' })}`}
                fullWidth
                value={userSiteUrlApplicationString}
                className={classes.userSite}
              />
              <ClipboardButton
                data-cy="copy-btn"
                variant="contained"
                color="primary"
                value={userSiteUrlApplicationString}
                className={classes.copyApplicationButton}
                startIcon={<ContentCopyIcon />}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextInput
                name="userSiteUrl"
                disabled
                label={`${intl.formatMessage({
                  id: 'common.user_site_url_for_application',
                })} - ${intl.formatMessage({ id: 'common.french' })}`}
                fullWidth
                value={userSiteUrlApplicationStringFr}
                className={classes.userSite}
              />
              <ClipboardButton
                data-cy="copy-btn"
                variant="contained"
                color="primary"
                value={userSiteUrlApplicationStringFr}
                className={clsx(classes.copyApplicationButton, 'copyApplicationButtonFr')}
                startIcon={<ContentCopyIcon />}
              />
            </Grid>
          </Grid>
          <Grid
            data-cy="site-application-active"
            item
            xs={4}
            sm={4}
            justifyContent="center"
            direction="column"
            alignItems="flex-start"
            container
            className={classes.booleanInputRight}
          >
            <BooleanInput
              label={intl.formatMessage({ id: 'common.site_active' })}
              name="siteApplicationActive"
              size="small"
              color="primary"
              disabled={isReadOnly}
              labelPosition="after"
            />
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <VaultMenu
                data-cy="default-language"
                required
                options={DEFAULT_LANGUAGE}
                disabled={isReadOnly}
                className={classes.formControl}
                label={intl.formatMessage({ id: 'common.default_language' })}
                name="defaultLanguage"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="replyTo"
                label={intl.formatMessage({ id: 'common.reply_to_email' })}
                fullWidth
                disabled={isReadOnly}
                rules={{
                  required: false,
                  pattern: USER_EMAIL_PATTERN,
                }}
              />
            </Grid>
          </Grid>
          {!isReadOnly && (
            <Grid container justifyContent="center" className={classes.controlButtonsWrap}>
              <Button
                data-cy="cancel-changes"
                onClick={() => reset()}
                variant="contained"
                color="default"
                className={classes.controlButtons}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
              <Button
                data-cy="save-changes"
                type="submit"
                variant="contained"
                color="primary"
                className={classes.controlButtons}
              >
                {intl.formatMessage({ id: 'button.save' })}
              </Button>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default EditUserForm;
