import { GridOptions } from '@ag-grid-community/all-modules';
import { useIntl } from 'react-intl';
import { commonColumnTypes } from './common';
import { USER_EDIT_PAGE } from '../../../../constants/navigations';
import { HeaderComponent } from '../components/HeaderComponent';

// all possible columns in app
// each property must have it's own type

export const useUserManagmentColumnTypes = () => {
  const intl = useIntl();

  const columnTypes: GridOptions['columnTypes'] = {
    'userManagment:id': {
      ...commonColumnTypes['common:button'],
      field: 'idLabel',
      headerName: 'ID',
      cellRendererParams: {
        path: USER_EDIT_PAGE.path,
        pathParams: {
          userVendorUuid: 'uuid',
        },
      },
    },
    'userManagment:firstName': {
      ...commonColumnTypes['common:text'],
      field: 'firstName',
      minWidth: 130,
      headerComponentParams: { name: 'user_managment.first_name' },
      headerComponentFramework: HeaderComponent,
    },
    'userManagment:lastName': {
      ...commonColumnTypes['common:text'],
      field: 'lastName',
      minWidth: 130,
      headerComponentParams: { name: 'user_managment.last_name' },
      headerComponentFramework: HeaderComponent,
    },
    'userManagment:company': {
      ...commonColumnTypes['common:text'],
      field: 'companyName',
      minWidth: 170,
      headerComponentParams: { name: 'user_managment.company' },
      headerComponentFramework: HeaderComponent,
    },
    'userManagment:email': {
      ...commonColumnTypes['common:text'],
      field: 'email',
      maxWidth: 300,
      minWidth: 250,
      headerComponentParams: { name: 'user_managment.email' },
      headerComponentFramework: HeaderComponent,
    },
    'userManagment:phone': {
      ...commonColumnTypes['common:text'],
      field: 'phone',
      minWidth: 200,
      headerComponentParams: { name: 'user_managment.phone' },
      headerComponentFramework: HeaderComponent,
    },
    'userManagment:mobilePhone': {
      ...commonColumnTypes['common:text'],
      field: 'mobilePhone',
      minWidth: 190,
      headerComponentParams: { name: 'user_managment.mobile' },
      headerComponentFramework: HeaderComponent,
    },
    'userManagment:role': {
      field: 'role',
      minWidth: 160,
      headerName: intl.formatMessage({ id: 'user_managment.role' }),
      headerComponentParams: { name: 'user_managment.role' },
      headerComponentFramework: HeaderComponent,
    },
    'userManagment:active': {
      ...commonColumnTypes['common:state'],
      field: 'active',
      minWidth: 160,
      headerComponentParams: { name: 'user_managment.status' },
      headerComponentFramework: HeaderComponent,
    },
    'userManagment:createdAt': {
      ...commonColumnTypes['common:date'],
      field: 'createdAt',
      minWidth: 180,
      headerComponentParams: { name: 'user_managment.created_on' },
      headerComponentFramework: HeaderComponent,
    },
    'userManagment:lastLogin': {
      ...commonColumnTypes['common:date'],
      field: 'lastLogin',
      minWidth: 180,
      headerComponentParams: { name: 'user_managment.last_login' },
      headerComponentFramework: HeaderComponent,
    },
    'userManagment:userLoginAs': {
      cellRenderer: 'loginAsButton',
      field: 'uuid',
      suppressSizeToFit: true,
      headerComponentParams: { name: 'user_managment.login' },
      headerComponentFramework: HeaderComponent,
    },
    'quote:selectAll': {
      ...commonColumnTypes['common:selectAll'],
    },
  };

  return columnTypes;
};
