const defaultColumns = [
  {
    field: 'createdAt',
    label: 'common.date',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    flex: true,
  },
  {
    field: 'summary',
    label: 'amendment.summary',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    flex: true,
  },
  {
    field: 'files',
    label: 'common.files',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    width: 100,
  },
  {
    field: 'status',
    label: 'common.status',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    width: 200,
    flex: true,
  },
  {
    field: 'statusReason',
    label: 'common.reason',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    flex: true,
  },
];
const defaultGridView = {
  label: 'All records',
  columns: [...defaultColumns],
  filterList: {
    email: {
      condition1: {
        filter: '.com',
        filterType: 'text',
        type: 'contains',
      },
      condition2: {
        filter: 'xyz',
        filterType: 'text',
        type: 'contains',
      },
      filterType: 'text',
      operator: 'OR',
    },
  },
};

export const initialState: ApplicationAmendmentStoreType = {
  selectedGridView: 'All records',
  gridViews: [defaultGridView],
  columns: [...defaultColumns],
  filterList: {},
  amendmentsList: [],
  isLoading: false,
};
