import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ExpandInIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.9999 5C19.9999 4.73478 19.8946 4.48043 19.707 4.29289C19.5195 4.10536 19.2652 4 18.9999 4H13.9999C13.7347 4 13.4804 4.10536 13.2928 4.29289C13.1053 4.48043 12.9999 4.73478 12.9999 5C12.9999 5.26522 13.1053 5.51957 13.2928 5.70711C13.4804 5.89464 13.7347 6 13.9999 6H16.5699L13.2899 9.29C13.1962 9.38296 13.1218 9.49356 13.071 9.61542C13.0203 9.73728 12.9941 9.86799 12.9941 10C12.9941 10.132 13.0203 10.2627 13.071 10.3846C13.1218 10.5064 13.1962 10.617 13.2899 10.71C13.3829 10.8037 13.4935 10.8781 13.6154 10.9289C13.7372 10.9797 13.8679 11.0058 13.9999 11.0058C14.132 11.0058 14.2627 10.9797 14.3845 10.9289C14.5064 10.8781 14.617 10.8037 14.7099 10.71L17.9999 7.42V10C17.9999 10.2652 18.1053 10.5196 18.2928 10.7071C18.4804 10.8946 18.7347 11 18.9999 11C19.2652 11 19.5195 10.8946 19.707 10.7071C19.8946 10.5196 19.9999 10.2652 19.9999 10V5Z"
        fill="#4153AF"
      />
      <path
        d="M10.71 13.2899C10.617 13.1962 10.5064 13.1218 10.3846 13.071C10.2627 13.0203 10.132 12.9941 10 12.9941C9.86799 12.9941 9.73728 13.0203 9.61542 13.071C9.49356 13.1218 9.38296 13.1962 9.29 13.2899L6 16.5699V13.9999C6 13.7347 5.89464 13.4804 5.70711 13.2928C5.51957 13.1053 5.26522 12.9999 5 12.9999C4.73478 12.9999 4.48043 13.1053 4.29289 13.2928C4.10536 13.4804 4 13.7347 4 13.9999V18.9999C4 19.2652 4.10536 19.5195 4.29289 19.707C4.48043 19.8946 4.73478 19.9999 5 19.9999H10C10.2652 19.9999 10.5196 19.8946 10.7071 19.707C10.8946 19.5195 11 19.2652 11 18.9999C11 18.7347 10.8946 18.4804 10.7071 18.2928C10.5196 18.1053 10.2652 17.9999 10 17.9999H7.42L10.71 14.7099C10.8037 14.617 10.8781 14.5064 10.9289 14.3845C10.9797 14.2627 11.0058 14.132 11.0058 13.9999C11.0058 13.8679 10.9797 13.7372 10.9289 13.6154C10.8781 13.4935 10.8037 13.3829 10.71 13.2899Z"
        fill="#4153AF"
      />
    </SvgIcon>
  );
}
