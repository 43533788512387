import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  errorRoot: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  quoteToApplicationPaperContainer: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
export default useStyles;
