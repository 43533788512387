import { Dispatch } from 'react';
import * as applicationApi from '../../apis/application';
import { actions as toastActions } from '../toast';
import { getIntl } from '../../utils/language';
import { IGetRowsParams } from '@ag-grid-community/all-modules';

const fetchDraftApplicationList =
  (params: IGetRowsParams) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let applicationList;

    try {
      applicationList = (await applicationApi.getAllDraftApplication(params)).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    return applicationList;
  };

const deleteDraftApplications =
  (uuidList: string[]) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await applicationApi.deleteDraftApplications(uuidList);
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'application.draft_applications_deleted' }),
      };
      dispatch(toastActions.open(toastOptions));
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.draft_applications_deleted_error' }),
      };
      dispatch(toastActions.open(toastOptions));
    }
  };

export const actions = {
  fetchDraftApplicationList,
  deleteDraftApplications,
};
