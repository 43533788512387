import React from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { DOCUMENT_OVERRIDE_TYPES } from '../../../constants/document';

type ApplicationDocumentListStepsPropsType = {
  step: number;
  type: string;
};

export default function ApplicationDocumentListSteps(props: ApplicationDocumentListStepsPropsType) {
  const { step, type } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.dialogSteps}>
      <Grid container>
        {(type === DOCUMENT_OVERRIDE_TYPES.INTERMEDIATE ||
          type === DOCUMENT_OVERRIDE_TYPES.GENERATE) && (
          <>
            <Grid item xs={6} className={classes.dialogStep} data-cy="generate-doc-step-1">
              <span className={clsx(classes.dialogStepNumber, classes.activeDialogStepNumber)}>
                {step === 1 ? 1 : <CheckIcon />}
              </span>
              <span
                className={
                  step === 1
                    ? clsx(classes.dialogStepTitle, classes.activeDialogStepTitle)
                    : classes.dialogStepTitle
                }
              >
                {intl.formatMessage({ id: 'application.select_templates' })}
              </span>
              <span className={classes.separator} />
            </Grid>
            <Grid item xs={6} className={classes.dialogStep} data-cy="generate-doc-step-2">
              <span
                className={clsx(classes.dialogStepNumber, {
                  [classes.activeDialogStepNumber]: step === 2,
                })}
              >
                {step > 2 ? <CheckIcon /> : 2}
              </span>
              <span
                className={
                  step === 2
                    ? clsx(classes.dialogStepTitle, classes.activeDialogStepTitle)
                    : classes.dialogStepTitle
                }
              >
                {intl.formatMessage({ id: 'application.verify_and_update_info' })}
              </span>
            </Grid>
          </>
        )}

        {type === DOCUMENT_OVERRIDE_TYPES.SIGNATURE && (
          <>
            <Grid item xs={4} className={classes.dialogStep} data-cy="generate-doc-step-2">
              <span className={clsx(classes.dialogStepNumber, classes.activeDialogStepNumber)}>
                {step === 2 ? 1 : <CheckIcon />}
              </span>
              <span
                className={
                  step === 2
                    ? clsx(classes.dialogStepTitle, classes.activeDialogStepTitle)
                    : classes.dialogStepTitle
                }
              >
                {intl.formatMessage({ id: 'application.verify_and_update_info' })}
              </span>
              <span className={classes.separator} />
            </Grid>
            <Grid item xs={4} className={classes.dialogStep} data-cy="generate-doc-step-3">
              <span
                className={clsx(classes.dialogStepNumber, {
                  [classes.activeDialogStepNumber]: step > 2,
                })}
              >
                {step > 3 ? <CheckIcon /> : 2}
              </span>
              <span
                className={
                  step === 3
                    ? clsx(classes.dialogStepTitle, classes.activeDialogStepTitle)
                    : classes.dialogStepTitle
                }
              >
                {intl.formatMessage({ id: 'application.document_preview' })}
              </span>
              <span className={classes.separator} />
            </Grid>
            <Grid item xs={4} className={classes.dialogStep} data-cy="generate-doc-step-4">
              <span
                className={clsx(classes.dialogStepNumber, {
                  [classes.activeDialogStepNumber]: step > 3,
                })}
              >
                3
              </span>
              <span
                className={
                  step === 4
                    ? clsx(classes.dialogStepTitle, classes.activeDialogStepTitle)
                    : classes.dialogStepTitle
                }
              >
                {intl.formatMessage({ id: 'application.sign_options' })}
              </span>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
