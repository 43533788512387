import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import { List } from '@material-ui/core';
import { Sections } from '../../constants/search';
import SectionComponents from '../../components/common/SearchSelector/SectionComponents';

function SearchAllResults({
  searchQuery,
  selectedTab,
}: {
  searchQuery: string;
  selectedTab: string;
}) {
  const globalSearchList = useSelector(
    (state: AppStoreType) => state.globalSearch.globalSearchList,
  );
  const classes = useStyles();
  const isAllResultsTab = selectedTab === Sections.allResults;

  return (
    <List className={classes.listBox}>
      <SectionComponents
        globalSearchList={globalSearchList}
        searchQuery={searchQuery}
        sectionsToShow={isAllResultsTab ? [] : [selectedTab]}
        sectionNames={isAllResultsTab}
      />
    </List>
  );
}

export default SearchAllResults;
