import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#edeff1',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& svg': {
      verticalAlign: 'middle',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    [theme.breakpoints.down('md')]: {
      //tableLayout: 'fixed',
      minWidth: '580px',
    },
  },
  title: {},
}));

export { useStyles, StyledTableCell, StyledTableRow };
