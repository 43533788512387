import React from 'react';
import { Paper } from '@material-ui/core';
import Loading from '../../components/common/Loading';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useParams } from 'react-router-dom';
import { declineQuote } from '../../apis/quote';
import useStyles from './styles';
import { useIntl } from 'react-intl';

export default function DeclineQuote() {
  const styles = useStyles();
  const intl = useIntl();
  const { generatedQuoteUuid } = useParams<{ generatedQuoteUuid: string }>();
  const [isLoading, setLoading] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [errorContact, setErrorContact] = React.useState('');

  React.useEffect(() => {
    declineQuote(generatedQuoteUuid)
      .then(() => {
        setIsSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        setErrorContact(e?.response?.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [generatedQuoteUuid]);

  const successMessage = (
    <>
      <CheckCircleOutlineIcon fontSize="large" style={{ color: 'green' }} />
      <h2>{intl.formatMessage({ id: 'decline.app_success' })}</h2>
    </>
  );
  const errorMessage = (
    <>
      <ErrorOutlineIcon fontSize="large" color="error" />
      <h2>{intl.formatMessage({ id: 'decline.app_processed' })}</h2>
      <br />
      <p>{intl.formatMessage({ id: 'decline.contact' })}</p>
      <p>{errorContact}</p>
    </>
  );

  return (
    <div className={styles.root}>
      <Loading isOpen={isLoading} />
      <Paper className={styles.paper}>{isSuccess ? successMessage : errorMessage}</Paper>
    </div>
  );
}
