import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CreateApplicationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M19.74 8.33018L14.3 2.33018C14.2065 2.22659 14.0924 2.14371 13.9649 2.08688C13.8375 2.03004 13.6995 2.00051 13.56 2.00018H6.56C6.22775 1.99622 5.89797 2.05774 5.5895 2.18124C5.28103 2.30473 4.9999 2.48778 4.76218 2.71993C4.52446 2.95209 4.33479 3.22879 4.20402 3.53425C4.07324 3.8397 4.00392 4.16793 4 4.50018V19.5002C4.00392 19.8324 4.07324 20.1607 4.20402 20.4661C4.33479 20.7716 4.52446 21.0483 4.76218 21.2804C4.9999 21.5126 5.28103 21.6956 5.5895 21.8191C5.89797 21.9426 6.22775 22.0041 6.56 22.0002H17.44C17.7723 22.0041 18.102 21.9426 18.4105 21.8191C18.719 21.6956 19.0001 21.5126 19.2378 21.2804C19.4755 21.0483 19.6652 20.7716 19.796 20.4661C19.9268 20.1607 19.9961 19.8324 20 19.5002V9.00018C19.9994 8.75234 19.9067 8.51358 19.74 8.33018ZM14 5.00018L16.74 8.00018H14.74C14.6353 7.99386 14.5329 7.96674 14.4387 7.92041C14.3446 7.87408 14.2607 7.80947 14.1918 7.73034C14.1229 7.65122 14.0704 7.55916 14.0375 7.45955C14.0046 7.35994 13.9918 7.25477 14 7.15018V5.00018ZM17.44 20.0002H6.56C6.49037 20.0042 6.42063 19.9945 6.35477 19.9715C6.28892 19.9486 6.22824 19.9129 6.17621 19.8664C6.12419 19.82 6.08184 19.7637 6.0516 19.7009C6.02137 19.638 6.00383 19.5698 6 19.5002V4.50018C6.00383 4.43054 6.02137 4.36234 6.0516 4.2995C6.08184 4.23665 6.12419 4.18039 6.17621 4.13394C6.22824 4.08749 6.28892 4.05176 6.35477 4.02881C6.42063 4.00586 6.49037 3.99613 6.56 4.00018H12V7.15018C11.9839 7.8868 12.2598 8.59991 12.7675 9.13385C13.2752 9.6678 13.9735 9.97923 14.71 10.0002H18V19.5002C17.9962 19.5698 17.9786 19.638 17.9484 19.7009C17.9182 19.7637 17.8758 19.82 17.8238 19.8664C17.7718 19.9129 17.7111 19.9486 17.6452 19.9715C17.5794 19.9945 17.5096 20.0042 17.44 20.0002Z" />
      <path d="M14 13H13V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V13H10C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15H11V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14C15 13.7348 14.8946 13.4804 14.7071 13.2929C14.5196 13.1054 14.2652 13 14 13Z" />
    </SvgIcon>
  );
}
