import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function DocumentNotAvailableIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M50.7873 17.9924H29.2308C28.7395 17.993 28.2686 18.1932 27.9212 18.549C27.5739 18.9049 27.3785 19.3874 27.3779 19.8906V68.972L27.1308 69.0492L21.8427 70.7083C21.592 70.7866 21.3213 70.7598 21.09 70.6338C20.8586 70.5078 20.6855 70.2929 20.6087 70.0363L4.87881 17.3989C4.80226 17.1421 4.82835 16.8647 4.95135 16.6277C5.07435 16.3906 5.28418 16.2133 5.53474 16.1347L13.6838 13.5784L37.3082 6.17032L45.4571 3.61406C45.5812 3.57496 45.7115 3.5613 45.8407 3.57386C45.9698 3.58641 46.0953 3.62494 46.2099 3.68724C46.3246 3.74954 46.4261 3.83438 46.5087 3.93691C46.5912 4.03944 46.6533 4.15765 46.6912 4.28477L50.712 17.7393L50.7873 17.9924Z"
          fill="#F2F2F2"
        />
        <path
          d="M55.4909 17.7386L50.6449 1.5228C50.5643 1.25261 50.4325 1.00135 50.2571 0.783369C50.0816 0.565387 49.866 0.38496 49.6224 0.252397C49.3789 0.119833 49.1122 0.0377293 48.8377 0.0107812C48.5632 -0.0161669 48.2862 0.0125683 48.0225 0.0953419L36.5654 3.68802L12.9423 11.0974L1.48529 14.6913C0.953056 14.8588 0.507409 15.2357 0.246168 15.7394C-0.0150724 16.2431 -0.0705635 16.8324 0.0918774 17.3779L16.6543 72.7969C16.7862 73.2373 17.0525 73.6227 17.414 73.8967C17.7755 74.1707 18.2132 74.3188 18.6628 74.3192C18.871 74.3193 19.0779 74.2873 19.2767 74.2243L27.1305 71.7617L27.3776 71.6833V71.4188L27.1305 71.496L19.2039 73.9826C18.7341 74.1293 18.2267 74.0792 17.7929 73.8431C17.3592 73.6071 17.0346 73.2044 16.8902 72.7235L0.329098 17.3033C0.257588 17.065 0.232664 16.8146 0.255755 16.5664C0.278845 16.3182 0.349493 16.0771 0.463658 15.857C0.577824 15.6368 0.733263 15.4418 0.921072 15.2833C1.10888 15.1248 1.32537 15.0057 1.55815 14.933L13.0152 11.3391L36.6384 3.931L48.0954 0.33705C48.272 0.28185 48.4555 0.253704 48.6401 0.253528C49.0363 0.254439 49.4218 0.385284 49.7401 0.626897C50.0584 0.86851 50.2928 1.20819 50.4091 1.5962L55.2327 17.7386L55.3093 17.9917H55.5663L55.4909 17.7386Z"
          fill="#3F3D56"
        />
        <path
          d="M15.1543 16.176C14.9162 16.1758 14.6844 16.0975 14.493 15.9525C14.3015 15.8075 14.1605 15.6034 14.0905 15.3703L12.4995 10.0462C12.4567 9.90319 12.4419 9.75294 12.4558 9.60406C12.4697 9.45517 12.5122 9.31055 12.5807 9.17846C12.6492 9.04637 12.7424 8.9294 12.855 8.83423C12.9676 8.73905 13.0975 8.66754 13.2371 8.62378L34.9701 1.80745C35.2521 1.71931 35.5566 1.74937 35.8169 1.89103C36.0772 2.03269 36.2721 2.27437 36.3586 2.56303L37.9497 7.88717C38.0357 8.176 38.0063 8.48797 37.868 8.75465C37.7298 9.02132 37.4939 9.22092 37.2122 9.30965L15.4791 16.126C15.3738 16.1591 15.2643 16.1759 15.1543 16.176Z"
          fill="#4153AF"
        />
        <path
          d="M23.489 5.68836C24.8534 5.68836 25.9595 4.55522 25.9595 3.15741C25.9595 1.75961 24.8534 0.626465 23.489 0.626465C22.1245 0.626465 21.0184 1.75961 21.0184 3.15741C21.0184 4.55522 22.1245 5.68836 23.489 5.68836Z"
          fill="#4153AF"
        />
        <path
          d="M23.4889 4.76102C24.3529 4.76102 25.0533 4.04348 25.0533 3.15834C25.0533 2.27321 24.3529 1.55566 23.4889 1.55566C22.6249 1.55566 21.9245 2.27321 21.9245 3.15834C21.9245 4.04348 22.6249 4.76102 23.4889 4.76102Z"
          fill="white"
        />
        <path
          d="M74.4413 73.6731H32.6895C32.4111 73.6727 32.1442 73.5593 31.9474 73.3577C31.7506 73.156 31.6398 72.8826 31.6395 72.5974V21.3457C31.6398 21.0605 31.7506 20.7871 31.9474 20.5854C32.1442 20.3838 32.4111 20.2703 32.6895 20.27H74.4413C74.7197 20.2703 74.9866 20.3838 75.1834 20.5854C75.3803 20.7871 75.491 21.0605 75.4913 21.3457V72.5974C75.491 72.8826 75.3802 73.156 75.1834 73.3576C74.9866 73.5593 74.7197 73.6727 74.4413 73.6731Z"
          fill="#E6E6E6"
        />
        <path
          d="M55.233 17.7388H29.2307C28.674 17.7396 28.1404 17.9665 27.7467 18.3698C27.3531 18.773 27.1316 19.3198 27.1308 19.8901V71.4961L27.3779 71.4189V19.8901C27.3784 19.3868 27.5739 18.9044 27.9212 18.5485C28.2686 18.1927 28.7395 17.9925 29.2307 17.9919H55.3096L55.233 17.7388ZM77.9 17.7388H29.2307C28.674 17.7396 28.1404 17.9665 27.7467 18.3698C27.3531 18.773 27.1316 19.3198 27.1308 19.8901V77.8488C27.1316 78.4191 27.3531 78.9659 27.7467 79.3691C28.1404 79.7724 28.674 79.9993 29.2307 80.0001H77.9C78.4567 79.9993 78.9904 79.7724 79.384 79.3691C79.7777 78.9659 79.9992 78.4191 80 77.8488V19.8901C79.9992 19.3198 79.7777 18.773 79.384 18.3698C78.9904 17.9665 78.4567 17.7396 77.9 17.7388ZM79.7529 77.8488C79.7523 78.3521 79.5569 78.8345 79.2096 79.1904C78.8622 79.5462 78.3913 79.7464 77.9 79.747H29.2307C28.7395 79.7464 28.2686 79.5462 27.9212 79.1904C27.5739 78.8345 27.3784 78.3521 27.3779 77.8488V19.8901C27.3784 19.3868 27.5739 18.9044 27.9212 18.5485C28.2686 18.1927 28.7395 17.9925 29.2307 17.9919H77.9C78.3913 17.9925 78.8622 18.1927 79.2096 18.5485C79.5569 18.9044 79.7523 19.3868 79.7529 19.8901V77.8488Z"
          fill="#3F3D56"
        />
        <path
          d="M64.9298 23.3069H42.201C41.9063 23.3065 41.6237 23.1864 41.4153 22.9729C41.2069 22.7594 41.0896 22.4699 41.0893 22.168V16.5999C41.0896 16.2979 41.2069 16.0084 41.4153 15.7949C41.6237 15.5814 41.9063 15.4613 42.201 15.4609H64.9298C65.2246 15.4613 65.5072 15.5814 65.7156 15.7949C65.924 16.0084 66.0412 16.2979 66.0416 16.5999V22.168C66.0412 22.4699 65.924 22.7594 65.7156 22.9729C65.5072 23.1864 65.2246 23.3065 64.9298 23.3069Z"
          fill="#4153AF"
        />
        <path
          d="M53.5654 15.8402C54.9299 15.8402 56.036 14.7071 56.036 13.3093C56.036 11.9115 54.9299 10.7783 53.5654 10.7783C52.201 10.7783 51.0949 11.9115 51.0949 13.3093C51.0949 14.7071 52.201 15.8402 53.5654 15.8402Z"
          fill="#4153AF"
        />
        <path
          d="M53.5654 14.8507C54.3965 14.8507 55.0702 14.1606 55.0702 13.3092C55.0702 12.4578 54.3965 11.7676 53.5654 11.7676C52.7344 11.7676 52.0607 12.4578 52.0607 13.3092C52.0607 14.1606 52.7344 14.8507 53.5654 14.8507Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
