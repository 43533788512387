import React, { useState } from 'react';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import DateFormatter from '../date-formatter';
import parse from 'html-react-parser';
import ModalBox from '../modal';
import { useIntl } from 'react-intl';
import { swapImgAttr } from '../../../utils/common';

export default function EmailHistory(props) {
  const classes = useStyles();
  const { title, emails } = props;
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const intl = useIntl();

  const emailBody = (body) => {
    const formattedBody = swapImgAttr(body, 'data-img-name', 'src');
    return parse(formattedBody);
  };

  const openEmail = (selectedEmail) => {
    setOpenModal(true);
    setModalContent({ ...selectedEmail });
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const emailsViewFormatter = (recipients) => {
    const toRecipients = recipients.filter((recipient) => recipient.type === 'to');
    let targetEmails = toRecipients.map((recipient) => recipient.email);
    targetEmails = targetEmails.map((email) => email.replace(';', ''));

    return targetEmails.map((email, i) => (
      <span className={classes.emailRow} key={i}>
        {email}
      </span>
    ));
  };

  return (
    <React.Fragment>
      {title && (
        <Typography variant="h2" align="center" gutterBottom>
          {title}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>{intl.formatMessage({ id: 'email.date' })}</StyledTableCell>
              <StyledTableCell>{intl.formatMessage({ id: 'email.to' })}</StyledTableCell>
              <StyledTableCell>{intl.formatMessage({ id: 'email.subject' })}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emails.map((email) => (
              <StyledTableRow key={email.uuid}>
                <StyledTableCell component="th" scope="row">
                  <DateFormatter date={email.createdAt} showTime />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {emailsViewFormatter(email.recipients)}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <span className={classes.previewLink} onClick={() => openEmail(email)}>
                    {email.subject}
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openModal && (
        <ModalBox
          header={modalContent.subject}
          body={emailBody(modalContent.body)}
          isOpen={openModal}
          handleClose={closeModal}
          dataCy="sent-email-modal"
          isEmailPreview
        />
      )}
    </React.Fragment>
  );
}
