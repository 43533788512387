import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    maxWidth: '100%',
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
  fullWidth: {
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

type SearchBarPropsType = {
  isSearching: boolean;
  searchText: string;
  searchLabel: string;
  onChange: (value: string) => void;
  onCancel: () => void;
  fullWidth?: boolean;
};

export default function GridSearchBar(props: SearchBarPropsType) {
  const { isSearching, searchText, searchLabel, onChange, onCancel, fullWidth } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Paper
      component="form"
      className={fullWidth ? clsx(classes.root, classes.fullWidth) : classes.root}
      variant="outlined">
      <InputBase
        value={searchText}
        className={classes.input}
        placeholder={searchLabel}
        inputProps={{ 'aria-label': intl.formatMessage({ id: 'grid.search_record' }) }}
        onChange={(event) => onChange(event.target.value)}
      />
      {!isSearching && (
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label={intl.formatMessage({ id: 'grid.search' })}>
          <SearchIcon />
        </IconButton>
      )}
      {isSearching && (
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label={intl.formatMessage({ id: 'grid.search' })}
          onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      )}
      {/* <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
      >
        <SettingsIcon />
      </IconButton> */}
    </Paper>
  );
}
