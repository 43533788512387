import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { actions as toastActions } from '../toast';
import * as applicationApi from '../../apis/application';
import { initialState } from './initialState';
import { getIntl } from '../../utils/language';

const slice = createSlice({
  name: 'applicationAmendments',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    endLoading: (state) => {
      state.isLoading = false;
    },
    updateFilterList: (state, action: PayloadAction<GridViewFilterListType>) => {
      state.filterList = action.payload;
      state.isLoading = false;
    },
    resetApplicationAmendments: () => initialState,
    updateAmendmentsList: (state, action: PayloadAction<AmendmentType[]>) => {
      state.amendmentsList = action.payload;
    },
    addAmendment: (state, action: PayloadAction<AmendmentType>) => {
      state.amendmentsList = [action.payload, ...state.amendmentsList];
    },
    updateAmendment: (state, action: PayloadAction<AmendmentType>) => {
      state.amendmentsList = state.amendmentsList.map((a) => {
        if (a.uuid === action.payload.uuid) {
          return action.payload;
        }
        return a;
      });
    },
  },
});

const getApplicationAmendments =
  (applicationUuid: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    dispatch(slice.actions.startLoading());
    const intl = getIntl(getState);
    let amendmentsList;
    try {
      amendmentsList = (await applicationApi.getApplicationAmendments(applicationUuid)).data;
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.application.application.something_wrong' }),
      };
      dispatch(slice.actions.endLoading());
      dispatch(toastActions.open(toastOptions));
    }
    if (!amendmentsList) {
      return;
    }

    dispatch(slice.actions.updateAmendmentsList(amendmentsList));
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'amendment.list_loaded' }),
    };
    dispatch(slice.actions.endLoading());
    dispatch(toastActions.open(toastOptions));
  };

const postApplicationAmendment =
  (applicationUuid: string, amendmentData: AmendmentType) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    dispatch(slice.actions.startLoading());
    const intl = getIntl(getState);
    try {
      const amendment = (
        await applicationApi.postApplicationAmendments(applicationUuid, amendmentData)
      ).data;
      dispatch(slice.actions.addAmendment(amendment));
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'amendment.created' }),
      };
      dispatch(slice.actions.endLoading());
      dispatch(toastActions.open(toastOptions));
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(slice.actions.endLoading());
      dispatch(toastActions.open(toastOptions));
    }
  };

const putApplicationAmendment =
  (applicationUuid: string, amendmentData: AmendmentType) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    dispatch(slice.actions.startLoading());
    const intl = getIntl(getState);
    try {
      const amendment = (
        await applicationApi.putApplicationAmendments(applicationUuid, amendmentData)
      ).data;
      dispatch(slice.actions.updateAmendment(amendment));
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'amendment.updated' }),
      };
      dispatch(slice.actions.endLoading());
      dispatch(toastActions.open(toastOptions));
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(slice.actions.endLoading());
      dispatch(toastActions.open(toastOptions));
    }
  };

export const actions = {
  ...slice.actions,
  getApplicationAmendments,
  postApplicationAmendment,
  putApplicationAmendment,
};
export default slice.reducer;
