import React from 'react';
import Chip from '../../components/common/chip';
import { useStyles } from './styles';
import { useIntl } from 'react-intl';
import { getApplicationStatusesMap } from '../../constants/language';
import Html from '../../components/common/Html';
import Paper from '@material-ui/core/Paper';
import MirStatus from '../../components/common/MirStatus';

type ApplicationStatusPropsType = {
  status?: ApplicationStatus | null;
  statusDescription?: string | null;
  approvalTerms: string | null;
  declineReason: string | null;
  mirReason: string | null;
  mirItems: MirItem[];
};

function ApplicationStatus(props: ApplicationStatusPropsType) {
  const { status, statusDescription, approvalTerms, declineReason, mirItems, mirReason } = props;
  const classes = useStyles();
  const intl = useIntl();
  const statusesMap = getApplicationStatusesMap(intl);
  const shouldMirBeShown = status?.status === 'applicationMoreInformation' && !!mirItems.length;
  const shouldMirReasonBeShown =
    !shouldMirBeShown && status?.status === 'applicationMoreInformation' && !!mirReason;
  const shouldApproveTermsBeShown = status?.status === 'applicationApproved' && !!approvalTerms;
  const shouldDeclineReasonBeShown = status?.status === 'applicationDeclined' && !!declineReason;

  if (!status) {
    return null;
  }

  return (
    <React.Fragment>
      <Paper variant="outlined" className={classes.documentRoot}>
        <div className={classes.tabPanelBlock}>
          <div data-cy="application-status-header" className={classes.tabPanelCaption}>
            {intl.formatMessage({ id: 'application.status_header' })}
          </div>
          <div>
            <Chip
              label={statusesMap[status.status]}
              backgroundColor={status.color}
              dataCy="application-status"
            />
          </div>
        </div>
        {statusDescription && (
          <>
            <hr />
            <div className={classes.tabPanelBlock}>
              <div className={classes.tabPanelSecondCaption}>
                {intl.formatMessage({ id: 'application.additional_information' })}
              </div>
              <p className={classes.statusAdditionalInfo}>{statusDescription}</p>
            </div>
          </>
        )}
        {shouldApproveTermsBeShown && (
          <>
            <hr />
            <div className={classes.tabPanelBlock}>
              <div className={classes.tabPanelSecondCaption}>
                {intl.formatMessage({ id: 'application.approval_terms' })}
              </div>
              <div className={classes.statusAdditionalInfo}>
                <Html html={approvalTerms} />
              </div>
            </div>
          </>
        )}
        {shouldDeclineReasonBeShown && (
          <>
            <hr />
            <div className={classes.tabPanelBlock}>
              <div className={classes.tabPanelSecondCaption}>
                {intl.formatMessage({ id: 'application.decline_reason' })}
              </div>
              <div className={classes.statusAdditionalInfo}>
                <Html html={declineReason} />
              </div>
            </div>
          </>
        )}
        {shouldMirReasonBeShown && (
          <>
            <hr />
            <div className={classes.tabPanelBlock}>
              <div className={classes.tabPanelSecondCaption}>
                {intl.formatMessage({ id: 'application.more_information_requested' })}
              </div>
              <div className={classes.statusAdditionalInfo}>
                <Html html={mirReason} />
              </div>
            </div>
          </>
        )}
        {shouldMirBeShown && (
          <>
            <hr />
            <div className={classes.tabPanelBlock}>
              <div className={classes.tabPanelSecondCaption}>
                {intl.formatMessage({ id: 'application.more_information_requested' })}
              </div>
              <div className={classes.statusAdditionalInfo}>
                {mirItems.map((mir, i) => {
                  return (
                    <div className={classes.mirRow} key={i}>
                      {mir.description}
                      <MirStatus status={mir.status} />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
}

export default ApplicationStatus;
