import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  smallText: {
    fontSize: 14,
  },
  accountTop: {
    padding: '8px',
    borderBottom: '1px solid #dcdcdc',
  },
  separator: {
    margin: '8px 0',
    padding: '0 15px',
    fontSize: '11px',
    color: '#666666',
  },
  profileLink: {
    color: theme.palette.text.primary,
    float: 'left',
    '&:hover': {
      textDecoration: 'none',
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: '3px',
    },
    '& svg': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  logoutLink: {
    float: 'right',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: '3px',
      color: 'red',
    },
    '& svg': {
      display: 'inline-block',
      verticalAlign: 'middle',
      color: 'red',
    },
  },
  logoutAsVendorLink: {
    float: 'left',
    paddingTop: '5px',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: '3px',
      color: 'red',
    },
    '& svg': {
      display: 'inline-block',
      verticalAlign: 'middle',
      color: 'red',
    },
  },
  accountsWrapper: {
    borderBottom: '1px solid #dcdcdc',
    '&:last-child': {
      borderBottom: 'none',
    },
    '& > span:last-child': {
      display: 'inline-block',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      '& svg': {
        display: 'inline-block',
        verticalAlign: 'middle',
      },
    },
    '& > div': {
      padding: '8px 15px',
      '& > a': {
        display: 'block',
        margin: '5px 0',
        textDecoration: 'none',
        color: '#000000',
        '&:hover': {
          color: theme.palette.text.secondary,
        },
        '& span': {
          display: 'inline-block',
          verticalAlign: 'middle',
        },
        '& svg': {
          marginLeft: '5px',
        },
      },
    },
  },
  brokerPhoto: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '40px',
    height: '40px',
    lineHeight: '40px',
    textAlign: 'center',
    borderRadius: '50%',
    border: '1px solid #cccccc',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    '& img': {
      maxWidth: '40px',
      maxHeight: '40px',
    },
  },
  activeVendorIcon: {
    fontSize: '16px',
  },
  accountWrapperHeader: {
    padding: '5px',
    backgroundColor: '#dcdcdc',
    '& > span:last-child': {
      marginLeft: '5px',
      fontWeight: 'bold',
    },
  },
  labelText: {
    fontSize: '14px',
    paddingLeft: '10px',
    width: 'calc(100% - 10px)',
    whiteSpace: 'normal',
  },
  clearFix: {
    clear: 'both',
  },
}));

export default useStyles;
