import React from 'react';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { useStyles } from './styles';

type HtmlPropsType = {
  html?: string | null;
};

function Html(props: HtmlPropsType) {
  const { html } = props;
  const classes = useStyles();

  if (!html) {
    return <></>;
  }

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode.name === 'ul' && domNode.children) {
        return <ul className={classes.ul}>{domToReact(domNode.children, options)}</ul>;
      }
      if (domNode.name === 'ol' && domNode.children) {
        return <ol className={classes.ol}>{domToReact(domNode.children, options)}</ol>;
      }
      if (domNode.name === 'li' && domNode.children) {
        return <li className={classes.listItem}>{domToReact(domNode.children, options)}</li>;
      }
      if (domNode instanceof Element && domNode.name === 'br') {
        return <br />;
      }
      if (domNode.name === 'b' && domNode.children) {
        return <>{domToReact(domNode.children, options)}</>;
      }
      if (domNode.name === 'p' && domNode.children) {
        return <p>{domToReact(domNode.children, options)}</p>;
      }
    },
  };

  return <>{parse(html, options)}</>;
}

export default Html;
