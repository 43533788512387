import React from 'react';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import { getFormattedDateTime } from '../../../services/formatter-service';
import { INSTANT_QUOTE_PAGE } from '../../../constants/navigations';

import { generatePath, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import CurrencyFormat from '../currency-format';
import { useParams } from 'react-router-dom';
import { isPublicRoute } from '../../../utils/common';
import { ApplicationRouteParamsType } from '../../../pages/new-application/new-application';
import clsx from 'clsx';
import { useApplicationInputs } from '../ApplicationEntityForm/inputs';

type ApplicationInfoPropsType = {
  applicationInfo: VpCompleteApplicationType | null;
  vendorReps: VendorRepType[];
  userUuid?: string | null;
  path: string;
};

function ApplicationInfo(props: ApplicationInfoPropsType) {
  const { applicationInfo, vendorReps, userUuid, path } = props;
  const { VendorRepInput } = useApplicationInputs(path);
  const classes = useStyles();
  const intl = useIntl();
  const dateTime = getFormattedDateTime(
    applicationInfo?.application.updatedAt,
    intl.locale.toLowerCase(),
  );
  const routeParams = useParams<ApplicationRouteParamsType>();
  const isPublicPage = React.useMemo(() => isPublicRoute(routeParams), [routeParams]);
  const vendorRepSelectionBlock = !!vendorReps.length && !!userUuid && (
    <div className={classes.vendorRepSection}>
      <Typography
        variant="h2"
        data-cy="vendor-rep-title"
        gutterBottom
        className={clsx(classes.vendorRepSectionLabel, classes.vendorRepSectionFont)}
      >
        {intl.formatMessage({ id: 'common.vendor_rep' })}:
      </Typography>
      <Grid item xs={12} sm={4} className={classes.content}>
        <VendorRepInput
          className={classes.vendorRepSectionFont}
          vendorReps={vendorReps}
          defaultUser={userUuid}
        />
      </Grid>
    </div>
  );

  return (
    <React.Fragment>
      {!applicationInfo && !isPublicPage && (
        <div className={classes.root}>{vendorRepSelectionBlock}</div>
      )}
      {applicationInfo && (
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography data-cy="application-id-and-title" variant="h6" gutterBottom>
                {applicationInfo?.application?.applicationName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Alert data-cy="draft-saved-at-alert" severity="success">
                {intl.formatMessage({ id: 'application.draft_saved_at' }, { dateTime })}
              </Alert>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            {applicationInfo?.quote?.quoteInfo && (
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8} md={8} lg={6}>
                    <Typography data-cy="associated-quote-title" variant="h6" gutterBottom>
                      {intl.formatMessage({ id: 'application.associated_quote' })}#
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6}>
                    {isPublicPage ? (
                      <Typography data-cy="associated-quote-id" variant="h6" gutterBottom>
                        {applicationInfo?.quote?.quoteInfo?.idLabel}
                      </Typography>
                    ) : (
                      <Link
                        to={generatePath(INSTANT_QUOTE_PAGE.path, {
                          id: applicationInfo?.quote?.quoteInfo?.uuid,
                        })}
                      >
                        <Typography data-cy="associated-quote-id" variant="h6" gutterBottom>
                          {applicationInfo?.quote?.quoteInfo?.idLabel}
                        </Typography>
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="caption" gutterBottom>
                        {applicationInfo?.quote?.selectedPaymentOption?.term
                          ? intl.formatMessage({ id: 'application.term' })
                          : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography data-cy="associated-quote-term" variant="h6" gutterBottom>
                        {applicationInfo?.quote?.selectedPaymentOption?.term}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="caption" gutterBottom>
                        {applicationInfo?.quote?.selectedPaymentOption?.amount
                          ? intl.formatMessage({ id: 'application.payment' })
                          : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography data-cy="associated-quote-amount" variant="h6" gutterBottom>
                        <CurrencyFormat
                          value={applicationInfo?.quote?.selectedPaymentOption?.amount}
                          currencyType={
                            applicationInfo?.quote?.quoteInfo?.equipmentCostCurrencyType
                          }
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="caption" gutterBottom>
                        {applicationInfo?.quote?.selectedPaymentOption?.purchaseOption
                          ? 'Option'
                          : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        data-cy="associated-quote-purchase-option"
                        variant="h6"
                        gutterBottom
                      >
                        {applicationInfo?.quote?.selectedPaymentOption?.purchaseOption}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {vendorRepSelectionBlock}
        </div>
      )}
    </React.Fragment>
  );
}

export default ApplicationInfo;
