import React from 'react';
import Chip from '../../chip';
import { Typography } from '@material-ui/core';
import { allStatusesMap } from '../../../../constants/language';
import { useIntl } from 'react-intl';

export default function StatusRenderer(props: {
  value: string;
  statusColor: string;
  data?: { [key: string]: string | number };
}) {
  const intl = useIntl();
  const statusesMap = React.useMemo(() => allStatusesMap(intl), [intl]);
  const color = (props.data?.[props.statusColor] as string | undefined) || '#d9d9d9';
  const statusText = statusesMap[props.value as keyof typeof statusesMap];

  if (!color) return <Typography variant="inherit">{statusText}</Typography>;
  return statusText ? <Chip label={statusText} backgroundColor={color} /> : ''; // hack with empty string to avoid warning about long time to init React component
}
