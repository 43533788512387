import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: VendorSettingsStoreType = {
  vendorsList: {
    data: [],
    total: 0,
  },
  vendor: {},
  files: [],
  vendorReps: [],
  isSiteApplicationDisabled: false,
};

const slice = createSlice({
  name: 'vendorSettings',
  initialState,
  reducers: {
    updateVendorList: (state, action: PayloadAction<{ data: VendorType[]; total: number }>) => {
      state.vendorsList = action.payload;
    },
    updateVendorUsers: (state, action: PayloadAction<VendorRepType[]>) => {
      state.vendorReps = action.payload;
    },
    updateVendorOnEdit: (state, action: PayloadAction<VendorCompanyType>) => {
      Object.assign(state, {
        vendor: action.payload,
        files: action.payload.files,
      });
    },
    addFiles: (state, action: PayloadAction<FileType[]>) => {
      state.files.push(...action.payload);
    },
    removeFile: (state, action: PayloadAction<string>) => {
      state.files = state.files.filter((f) => f.uuid !== action.payload);
    },
    updateVendor: (state, action: PayloadAction<Partial<VendorCompanyType>>) => {
      Object.assign(state.vendor, {
        ...action.payload,
      });
    },
    updateApplicationDisabledStatus: (state) => {
      state.isSiteApplicationDisabled = true;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
