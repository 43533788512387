import React, { useState, useEffect } from 'react';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useIntl } from 'react-intl';
import useStyles from './styles';
import { formattedDate, isValidDateString, parseDate } from '../../../services/formatter-service';
import { DatePickerToolbar } from '../DatePickerToolbar';
import { translateLabel } from '../../../utils/translateLabel';
type VaultDatePickerPropsType = Partial<
  Omit<React.ComponentProps<typeof KeyboardDatePicker>, 'onChange'>
> & {
  name: string;
  label?: string;
  required?: boolean;
  onChange?: (val: string | null, name: string) => unknown;
};

const datePickerFormatMap = {
  'dd/MM/yyyy': {
    format: 'dd/MM/yyyy',
    label: 'dd/mm/yyyy',
  },
};

const BaseDatePicker = ({
  name,
  label,
  onChange: propOnChange,
  disabled,
  required,
  ...props
}: VaultDatePickerPropsType) => {
  const classes = useStyles();
  const intl = useIntl();
  const inputFormat = datePickerFormatMap['dd/MM/yyyy'].format;
  const inputLabel = datePickerFormatMap['dd/MM/yyyy'].label;
  const [value, setValue] = useState<string | null>(null);

  const onChange = propOnChange;

  useEffect(() => {
    props.value && setValue(props.value as string);
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        className={classes.container}
        id={name}
        ToolbarComponent={(props) => <DatePickerToolbar {...props} />}
        name={name}
        label={translateLabel(intl, label as string)}
        value={value}
        required={required}
        format="dd MMM yyyy"
        mask="__/__/____"
        onChange={(date, input) => {
          const isManual = input?.includes('/');
          const usedFormat = isManual ? inputFormat : 'd MMM yyyy';

          if (input && !input?.includes('_') && isValidDateString(input, usedFormat)) {
            const date = parseDate(input, usedFormat);
            const newDate = date ? formattedDate(date, 'd MMM yyyy') : null;
            onChange && onChange(newDate, name);
            setValue(newDate);
          }
        }}
        onFocus={(e) => {
          e.persist();
          const val = e.target.value;
          setTimeout(() => {
            if (val && isValidDateString(val, 'dd MMM yyyy')) {
              e.target.value = formattedDate(new Date(val), inputFormat);
              props.inputValue = formattedDate(new Date(val), inputFormat);
            }
          });
        }}
        disabled={disabled}
        invalidDateMessage={
          <span className={classes.error}>
            {intl.formatMessage({ id: 'email.error.invalid_date' })}
          </span>
        }
        InputProps={{
          placeholder: inputLabel,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
export default BaseDatePicker;
