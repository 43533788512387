import React, { ComponentProps } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import get from 'lodash/get';

type BooleanInputPropsType = {
  shouldTrigger?: boolean;
  label?: string;
  name: ComponentProps<typeof Controller>['name'];
  rules?: ComponentProps<typeof Controller>['rules'];
  defaultValue?: ComponentProps<typeof Controller>['defaultValue'];
  labelPosition?: 'before' | 'after';
} & Partial<ComponentProps<typeof Checkbox>>;

export default function BooleanInput({
  shouldTrigger,
  label,
  name,
  defaultValue,
  rules,
  labelPosition = 'before',
  ...props
}: BooleanInputPropsType) {
  const { control, errors, trigger } = useFormContext();
  const error = get(errors, name);

  return (
    <>
      <InputLabel error={Boolean(error)}>
        {labelPosition === 'before' && <small>{label}</small>}
        <Controller
          render={({ value, ...renderProps }) => {
            return (
              <Checkbox
                {...renderProps}
                {...props}
                id={name}
                checked={value || false}
                onChange={(e) => {
                  control!.setValue(name, e.target.checked, { shouldDirty: true });
                  shouldTrigger && trigger();
                }}
              />
            );
          }}
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue ?? false}
        />
        {labelPosition === 'after' && <small>{label}</small>}
      </InputLabel>
    </>
  );
}
