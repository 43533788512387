import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { AlertProps } from '@material-ui/lab/Alert/Alert';
import { useSelector } from 'react-redux';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

type VaultSnackbarPropsType = {
  closeToast: () => void;
};

const toastSelector = (state: AppStoreType) => state.toast;

export default function VaultSnackbar({ closeToast }: VaultSnackbarPropsType) {
  const { open, message, toastType } = useSelector<AppStoreType, ReturnType<typeof toastSelector>>(
    toastSelector,
  );
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={closeToast}>
        <Alert onClose={closeToast} severity={toastType}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
