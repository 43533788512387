import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import parse from 'html-react-parser';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { ListItemIcon } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import useStyles from './styles';
import { Recipients } from '../../../apis/email';
import { swapImgAttr } from '../../../utils/common';

type EmailPreviewModalPropsType = {
  header: string;
  body: string;
  isOpen: boolean;
  handleClose: () => unknown;
  actions?: React.ReactNode;
  isBodyEditable: boolean;
  isFollowUp: boolean;
  onSendClick?: (header: string, body: string, isFollowUp?: boolean) => unknown;
  emailList?: Recipients;
  handleEmailChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => unknown;
};

export default function EmailPreviewModal({
  header,
  body,
  isOpen,
  handleClose,
  actions,
  isFollowUp,
  isBodyEditable,
  onSendClick,
  emailList,
  handleEmailChange,
}: EmailPreviewModalPropsType) {
  const [emailBody, setEmailBody] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const formattedBody = swapImgAttr(body, 'data-img-name', 'src');
    setEmailBody(formattedBody);
  }, [body]);

  const handleBodyChange = (evt: ContentEditableEvent) => {
    setEmailBody(evt.target.value);
  };

  const intl = useIntl();

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        className={classes.previewWide}
        data-cy="email-preview-modal"
        open={isOpen}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {!isEmpty(emailList) && (
          <div>
            <List aria-label="email">
              {emailList!.map((email, index) => (
                <div key={index}>
                  <ListItem>
                    <ListItemIcon>{email.type}</ListItemIcon>
                    <ListItemText>
                      <TextField
                        id={email.type}
                        name={email.type}
                        value={email.email}
                        fullWidth
                        onChange={(e) => handleEmailChange?.(e)}
                      />
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </div>
        )}

        <DialogTitle id="scroll-dialog-title">{header}</DialogTitle>
        <DialogContent data-cy="email-preview-body" dividers>
          {isBodyEditable && emailBody && (
            <ContentEditable
              html={emailBody} // innerHTML of the editable div
              disabled={false} // use true to disable edition
              onChange={(e) => handleBodyChange(e)} // handle innerHTML change
            />
          )}
          {!isBodyEditable && parse(body)}
        </DialogContent>
        <DialogActions>
          {actions}
          {onSendClick && (
            <Button
              data-cy="email-preview-send-button"
              onClick={() =>
                onSendClick(header, swapImgAttr(emailBody, 'src', 'data-img-name'), isFollowUp)
              }
              color="primary"
            >
              {intl.formatMessage({ id: 'button.send' })}
            </Button>
          )}
          <Button data-cy="email-preview-close-button" onClick={handleClose} color="primary">
            {intl.formatMessage({ id: 'button.close' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
