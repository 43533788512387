import React from 'react';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Controller, UseControllerOptions, useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import PopperExpanded from './PopperExpanded';
import { translateLabel } from '../../../utils/translateLabel';

type NaicsSelectorPropsType = {
  name: string;
  label: string;
  options: Array<Naics & { label: string }>;
  rules?: UseControllerOptions['rules'];
  defaultValue?: string;
  required?: boolean;
  value?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  multiple?: boolean;
  onChange: (name: string, v: (Naics & { label: string }) | null) => unknown;
  onInputChange?: React.ComponentProps<typeof Autocomplete>['onInputChange'];
} & Partial<Omit<React.ComponentProps<typeof Autocomplete>, 'onChange'>>;

const NaicsSelector = ({
  name,
  label,
  options,
  defaultValue,
  required,
  rules,
  error,
  helperText,
  disabled,
  onChange,
  onInputChange,
  getOptionSelected,
}: NaicsSelectorPropsType) => {
  const intl = useIntl();
  const classes = useStyles();
  const { control, errors } = useFormContext();

  const getOptionLabel = (option: typeof options[number] | null) => {
    const val = option?.['label'];
    return val ? val : '';
  };

  return (
    <FormControl variant="standard" className={classes.fullWidth} error={error}>
      <Controller
        render={(controllerProps) => {
          return (
            <Autocomplete<
              typeof options[number],
              boolean | undefined,
              boolean | undefined,
              boolean | undefined
            >
              PopperComponent={PopperExpanded}
              fullWidth
              {...controllerProps}
              onChange={(e, v) => onChange(name, v as typeof options[number])}
              onInputChange={onInputChange}
              id={name}
              value={
                Reflect.ownKeys(controllerProps.value || {}).length ? controllerProps.value : null
              }
              {...{ getOptionSelected }}
              options={options}
              getOptionLabel={getOptionLabel}
              disabled={disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={required}
                  error={get(errors, name)?.message !== undefined}
                  label={translateLabel(intl, label as string)}
                  autoComplete="naics"
                />
              )}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultValue !== undefined ? defaultValue : ''}
        rules={{
          required,
          validate: (value: string) => {
            if (!value || (Object.keys(value).length === 0 && required)) {
              return intl.formatMessage({ id: 'common.required' });
            }
            return;
          },
          ...rules,
        }}
      />

      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default NaicsSelector;
