import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { getContactsInfo, getSiteContactsBySiteUrls } from '../../apis/vendor';
import { getBackendUrl } from '../../utils/getBackendUrl';
import { getBrokerContactsInfo } from '../../apis/broker-rep';

const initialState: ContactsStoreType = {
  vendorRep: null,
  vendor: null,
  broker: null,
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    updateContacts: (state, action: PayloadAction<ContactsStoreType>) => {
      state.vendor = action.payload.vendor;
      state.broker = action.payload.broker
        ? {
            ...action.payload.broker,
            establishedMinYears: action.payload?.broker?.establishedMinYears,
          }
        : action.payload.broker;
      state.vendorRep = action.payload.vendorRep;
    },
    updateVendorLogo: (state, action: PayloadAction<{ logo: string; logoExist: boolean }>) => {
      Object.assign(state.vendor, {
        ...state.vendor,
        logo: action.payload.logo,
        logoExist: action.payload.logoExist,
      });
    },
  },
});

const isLogoExist = async (logo: string) => {
  try {
    const res = await fetch(`${getBackendUrl()}/static/${encodeURI(logo)}`, { method: 'HEAD' });

    return res.ok;
  } catch {
    return false;
  }
};

const loadContacts = (vendorUuid: string) => async (dispatch: Dispatch<unknown>) => {
  let contacts;

  try {
    contacts = (await getContactsInfo(vendorUuid)).data;
    if (contacts.broker?.logo) contacts.broker.logoExist = await isLogoExist(contacts.broker.logo);
    if (contacts.vendor?.logo) contacts.vendor.logoExist = await isLogoExist(contacts.vendor.logo);
  } catch {
    return false;
  }

  dispatch(slice.actions.updateContacts(contacts));

  return true;
};

const loadBrokerContacts = (brokerUuid: string) => async (dispatch: Dispatch<unknown>) => {
  let contacts;

  try {
    contacts = (await getBrokerContactsInfo(brokerUuid)).data;
    if (contacts.broker?.logo) contacts.broker.logoExist = await isLogoExist(contacts.broker.logo);
  } catch {
    return false;
  }

  dispatch(slice.actions.updateContacts(contacts));

  return true;
};

const loadContactsBySiteUrls =
  (vendorSiteUrl: string, userSiteUrl?: string) => async (dispatch: Dispatch<unknown>) => {
    let contacts;
    try {
      contacts = (await getSiteContactsBySiteUrls(vendorSiteUrl, userSiteUrl)).data;
      if (contacts.broker?.logo)
        contacts.broker.logoExist = await isLogoExist(contacts.broker.logo);
      if (contacts.vendor?.logo)
        contacts.vendor.logoExist = await isLogoExist(contacts.vendor.logo);
    } catch {
      return false;
    }

    dispatch(slice.actions.updateContacts(contacts));

    return true;
  };

export const actions = {
  ...slice.actions,
  loadContacts,
  loadBrokerContacts,
  loadContactsBySiteUrls,
};

export default slice.reducer;
