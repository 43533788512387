import React from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { validateChangeExpiredPassword } from '../../store/user';
import { Copyright } from '../../components/common/Copyright';
import Link from '../../components/common/link';
import { useIntl } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import { useStyles } from './style';
import { getValidationArray } from './utils';
import { useQuery } from '../../utils/common';
import { Alert } from '@material-ui/lab';
import { REPRESENTATIVE_TYPES } from '../../constants/application';
import { SignInToolbar } from '../../components/common/SignInToolbar';

type ChangePasswordFormValues = {
  newPassword: string;
  prevPassword: string;
  repeatPassword: string;
  type?: string | undefined;
};

const userErrorSelector = (state: AppStoreType) => state.user;

export default function ChangeExpiredPassword() {
  const classes = useStyles();
  const intl = useIntl();
  const query = useQuery();
  const { error } = useSelector<AppStoreType, ReturnType<typeof userErrorSelector>>(
    userErrorSelector,
  );
  const [newPassVisible, setNewPassVisible] = React.useState(false);
  const [prevPassVisible, setPrevPassVisible] = React.useState(false);
  const [confirmPassVisible, setConfirmPassVisible] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const formMethods = useForm<ChangePasswordFormValues>({
    defaultValues: {
      prevPassword: '',
      newPassword: '',
      repeatPassword: '',
      type: undefined,
    },
  });
  const { register, handleSubmit, reset, errors, watch, setValue } = formMethods;
  const [isTypeSelection, setIsTypeSelection] = React.useState(false);
  const [isTypeError, setIsTypeError] = React.useState(false);

  const newPassword = watch('newPassword');
  const prevPassword = watch('prevPassword');

  const email = query.get('email');

  const submit = async (data: ChangePasswordFormValues) => {
    const { newPassword, type } = data;

    if (isTypeSelection && !type) {
      setIsTypeError(true);
      return false;
    }
    try {
      await dispatch(
        validateChangeExpiredPassword(newPassword, email ?? '', prevPassword, type && type),
      );
      reset();
      return history.push(
        `/sign-in?passwordChangedSuccessfully=true${
          email && '&email=' + encodeURIComponent(email)
        }`,
      );
    } catch (e) {
      if (e?.code === 409) setIsTypeSelection(false);
      return;
    }
  };

  const validationArray = React.useMemo(() => getValidationArray(intl), [intl]);

  return (
    <>
      <SignInToolbar />
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SettingsBackupRestoreIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {intl.formatMessage({ id: 'common.change_your_password' })}
          </Typography>
          <Alert className={classes.alertBlock} severity="warning">
            <Typography component="span" variant="h6" className={classes.alertHeader}>
              {intl.formatMessage({ id: 'common.password_expired' })}
            </Typography>
            <Typography component="div" variant="subtitle2">
              {intl.formatMessage({ id: 'common.change-password_to-proceed' })}
            </Typography>
          </Alert>
          <FormProvider {...formMethods}>
            <form className={classes.form} onSubmit={handleSubmit(submit)} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
                name="email"
                className={classes.disabled}
                value={email}
                label={intl.formatMessage({ id: 'common.email_address' })}
                id="email"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={register({
                  validate: (input) => {
                    if (!input) {
                      return intl.formatMessage({ id: 'common.password_not_empty' });
                    }

                    return undefined;
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setPrevPassVisible(!prevPassVisible)}
                        edge="end"
                      >
                        {prevPassVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="prevPassword"
                label={intl.formatMessage({ id: 'common.prev_password' })}
                type={prevPassVisible ? 'text' : 'password'}
                id="prevPassword"
                error={!!errors.prevPassword}
                helperText={errors.prevPassword?.message}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={register({
                  validate: (input) => {
                    if (!input) {
                      return intl.formatMessage({ id: 'common.password_not_empty' });
                    }
                    if (validationArray.some((v) => v.isInvalid(input))) {
                      return intl.formatMessage({ id: 'common.more_secured_password_needed' });
                    }

                    return undefined;
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setNewPassVisible(!newPassVisible)}
                        edge="end"
                      >
                        {newPassVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="newPassword"
                label={intl.formatMessage({ id: 'common.new_password' })}
                type={newPassVisible ? 'text' : 'password'}
                id="newPassword"
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message}
              />
              <div>
                {validationArray.map((v) => {
                  const isInvalid = v.isInvalid(newPassword);
                  return (
                    <FormHelperText className={classes.validationWrapper} key={v.text}>
                      <FiberManualRecordIcon
                        className={clsx(classes.dot, {
                          [classes.dotSuccess]: !isInvalid,
                          [classes.dotFailed]: isInvalid,
                        })}
                      />
                      <span
                        className={clsx({
                          [classes.validationTextSuccess]: !isInvalid,
                        })}
                      >
                        {v.text}
                      </span>
                    </FormHelperText>
                  );
                })}
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={register({
                  validate: (value) => {
                    if (!value) {
                      return intl.formatMessage({ id: 'common.password_not_empty' });
                    } else if (value !== newPassword) {
                      return intl.formatMessage({ id: 'common.password_not_match' });
                    }
                  },
                })}
                name="repeatPassword"
                label={intl.formatMessage({ id: 'common.confirm_new_password' })}
                type={confirmPassVisible ? 'text' : 'password'}
                id="repeatPassword"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={() => setConfirmPassVisible(!confirmPassVisible)}
                        edge="end"
                      >
                        {confirmPassVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.repeatPassword}
                helperText={errors.repeatPassword?.message}
              />
              {error && (
                <Typography variant="subtitle2" color="error">
                  {error}
                </Typography>
              )}
              {isTypeSelection && (
                <TextField
                  {...register({
                    name: 'type',
                    required: true,
                  })}
                  className={classes.selectAcc}
                  variant="outlined"
                  required
                  fullWidth
                  select
                  label={intl.formatMessage({ id: 'common.select_representative' })}
                  name="type"
                  id="type"
                  data-cy="type-field"
                  defaultValue=""
                  error={isTypeError}
                  helperText={
                    isTypeError && intl.formatMessage({ id: 'common.representative_required' })
                  }
                  onChange={(e) => {
                    setIsTypeError(false);
                    setValue('type', e.target.value, { shouldDirty: true, shouldValidate: true });
                  }}
                >
                  {REPRESENTATIVE_TYPES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {intl.formatMessage({ id: option.label })}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit(submit)}
              >
                {intl.formatMessage({ id: 'common.change_password' })}
              </Button>
              <Grid container>
                <Grid item>
                  <Link to="/sign-in">
                    <span className={classes.backToSignIn}>
                      <ArrowBackIcon className={classes.backToSignInIcon} />
                      {intl.formatMessage({ id: 'common.back_and_sign_in' })}
                    </span>
                  </Link>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
