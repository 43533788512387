import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  headerPlate: {
    width: 'calc(100% - 30px)',
    backgroundColor: '#F2F2F2',
    fontSize: '0.8rem',
    padding: '15px 27px',
    paddingRight: '15px',
    margin: 'auto',
    marginBottom: '20px',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
    },
    '& .MuiButtonBase-root ': {
      float: 'right',
      padding: '0',
      marginTop: '-5px',
      color: '#eb5757',
    },
  },
  verticalSpacing: {
    marginTop: theme.spacing(4),
  },
  alignFlexEnd: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  switchFormatting: {
    '& .MuiFormControlLabel-label ': {
      fontSize: '0.8rem',
      fontWeight: 'bold',
    },
  },
}));

export default useStyles;
