import { connect, ConnectedProps } from 'react-redux';

import { actions } from '../../store/instantQuote';
const {
  generateInstantQuote,
  getQuoteInfo,
  updateInstantQuote,
  cleanState,
  addFilesToQuote,
  removeFileFromQuote,
  sendQuoteDetailsEmail,
  sendQuoteFollowUps,
  previewQuoteDetailsEmail,
  previewQuoteFollowUp,
} = actions;
import { actions as formValuesActions } from '../../store/formValues';
import { actions as toastActions } from '../../store/toast';

import InstantQuote from './instant-quote.component';

const mapStateToProps = (state: AppStoreType) => ({
  isAdmin: state.user.role !== 'Vendor User',
  vendorId: (state.user as UserBasedStoreType).vendorId,
  vendorUuid: (state.user as UserBasedStoreType).vendorUuid,
  loading: state.instantQuote.loading,
  error: state.instantQuote.error,
  vendorProgramList: state.formValues.vendorProgramList,
  quoteInfo: state.instantQuote.quoteInfo,
  application: state.instantQuote.application,
  generatedQuotes: state.instantQuote.generatedQuotes,
  lastFollowUpDate: state.instantQuote.lastFollowUpDate,
  files: state.instantQuote.files,
  selectedPaymentOption: state.instantQuote.selectedPaymentOption,
  emailPreview: state.instantQuote.emailPreview,
  emailTemplates: state.formValues.emailTemplateList,
  vendorSetting: state.formValues.vendorSetting,
  vendorReps: state.formValues.vendorReps,
});

const mapDispatchToProps = {
  generateInstantQuote,
  getVendorProgramList: formValuesActions.getVendorProgramList,
  getVendorReps: formValuesActions.getVendorReps,
  getQuoteInfo,
  getAllEmailTemplates: formValuesActions.getAllEmailTemplates,
  updateEmailTemplateList: formValuesActions.updateEmailTemplateList,
  getVendorSettings: formValuesActions.getVendorSettings,
  openToast: toastActions.open,
  updateInstantQuote,
  cleanState,
  addFilesToQuote,
  removeFileFromQuote,
  sendQuoteDetailsEmail,
  sendQuoteFollowUps,
  previewQuoteDetailsEmail,
  previewQuoteFollowUp,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type InstantQuoteConnectedPropsType = ConnectedProps<typeof connector>;

export default connector(InstantQuote);
