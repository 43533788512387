import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import NotificationBar from '../common/notification-bar';
import LanguageSwitcherBar from '../common/language-switcher-bar';
import { navigationLinks } from '../../constants/navigations';
import AppBarLink from './appBarLink';
import { setCookieValue, getCookieValue, isSidebarOpened } from '../../services/cookies-service';
import { actions as settingsActions } from '../../store/settings';

import useStyles from './styles';
import Contacts from '../common/Contacts';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { logout } from '../../store/user';
import Button from '@material-ui/core/Button';
import SearchSelector from '../common/SearchSelector';
import { SEARCH_RESULT_PAGE } from '../../constants/navigations';
import { FIRST_LOAD } from '../../constants/application';
import { logoutAsVendorUser } from '../../utils/common';

export default function AppBarWithDrawer({ type }: { type: 'public' | 'user' | 'brokerRep' }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const contactsInMenu = useMediaQuery('(max-width:900px)');
  const userInMenu = useMediaQuery('(max-width:900px)');

  const open = useSelector<AppStoreType, AppStoreType['settings']['isSidebarOpened']>(
    (state) => state.settings.isSidebarOpened,
  );
  const userRole = useSelector<AppStoreType, AppStoreType['user']['role']>(
    (state) => state.user.role,
  );
  const isUserHimself = useSelector<AppStoreType, AppStoreType['user']['isUserHimself']>(
    (state) => state.user.isUserHimself,
  );
  const contacts = useSelector<AppStoreType, AppStoreType['contacts']>((state) => state.contacts);
  const isAdmin = userRole === 'Vendor Admin' || userRole === 'Portal Admin';
  const isVendorAdmin = userRole === 'Vendor Admin';
  const isPortalAdmin = userRole === 'Portal Admin';
  const isUser = type === 'user';
  const isBrokerRep = type === 'brokerRep';
  const isSearchPage = useRouteMatch(SEARCH_RESULT_PAGE.path);

  const isBrokerRepLinkAvailable = (link: typeof navigationLinks[number]) =>
    !(link.isBrokerRep && !isBrokerRep);
  const isPortalAdminLinkAvailable = (link: typeof navigationLinks[number]) =>
    !(link.isPortalAdmin && !isPortalAdmin);
  const isVendorAdminLinkAvailable = (link: typeof navigationLinks[number]) =>
    !(link.isVendorAdmin && !isPortalAdmin && !isVendorAdmin);
  const isMobileOnlyLink = (link: typeof navigationLinks[number]) =>
    !(link.mobileOnly && !userInMenu);
  const isAllowedToVisit = (link: typeof navigationLinks[number]) => !(link.isUser && !isUser);

  const links = React.useMemo(
    () =>
      navigationLinks.filter((link) => {
        return (
          isVendorAdminLinkAvailable(link) &&
          isPortalAdminLinkAvailable(link) &&
          isMobileOnlyLink(link) &&
          isAllowedToVisit(link) &&
          isBrokerRepLinkAvailable(link)
        );
      }),
    [isAdmin, isVendorAdmin, isPortalAdmin, userInMenu, isBrokerRep],
  );

  const handleDrawerOpen = () => {
    setCookieValue('sidebar_status', 'opened');
    dispatch(settingsActions.changeSidebarStatus(true));
  };

  const handleDrawerClose = () => {
    setCookieValue('sidebar_status', 'closed');
    dispatch(settingsActions.changeSidebarStatus(false));
  };

  const handleLinkClick = () => {
    if (contactsInMenu) {
      handleDrawerClose();
    }
  };

  const logoutUser = async () => {
    try {
      await dispatch(logout());
      location.href = '/sign-in';
    } catch (e) {
      return;
    }
  };

  const sideMenuVisible = () => {
    return type !== 'public' || userInMenu;
  };

  const checkSidebarState = () => {
    const sidebarStatus = getCookieValue('sidebar_status');

    if (typeof sidebarStatus !== 'string') {
      return true;
    }

    const isOpened = isSidebarOpened(sidebarStatus);

    return isOpened;
  };

  const setSidebarStatus = () => {
    const sidebarState = checkSidebarState();

    dispatch(settingsActions.changeSidebarStatus(sidebarState));
  };

  React.useEffect(() => {
    setSidebarStatus();
  }, [sideMenuVisible()]);

  React.useEffect(() => {
    const firstLoad = sessionStorage.getItem(FIRST_LOAD);
    !firstLoad && handleDrawerClose();
    sessionStorage.setItem(FIRST_LOAD, 'true');
  }, []);

  const renderUserLink = () =>
    userRole ? (
      <Button
        className={classes.userLinkButton}
        onClick={logoutUser}
        color="primary"
        variant="contained"
      >
        Logout
      </Button>
    ) : (
      <Button
        className={classes.userLinkButton}
        onClick={() => (location.href = '/sign-in')}
        color="primary"
        variant="contained"
      >
        Sign in
      </Button>
    );

  const renderContacts = (type: 'inHeader' | 'inMenu') => (
    <>
      {contacts.vendor && (
        <Contacts
          type={type}
          name={contacts.vendor.name}
          email={contacts.vendor.email}
          phone={contacts.vendor.phone}
          logo={contacts.vendor.logo}
          logoExist={contacts.vendor.logoExist}
        />
      )}
      {contacts.broker && (contacts.vendor?.showBrokerInfo || isBrokerRep) && (
        <Contacts
          type={type}
          name={contacts.broker.name}
          email={contacts.broker.email}
          phone={contacts.broker.phone}
          logo={contacts.broker.logo}
          logoExist={contacts.broker.logoExist}
        />
      )}
    </>
  );

  return (
    <React.Fragment>
      <AppBar
        data-cy="header"
        color="default"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.publicUrl]: !sideMenuVisible(),
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbarWrapper}>
          {sideMenuVisible() && (
            <div className={clsx(classes.menuButtonWrapper, 'MuiListItem-root', 'Mui-selected')}>
              <IconButton
                data-cy="hamburger-menu-btn"
                className={classes.IconButton}
                color="inherit"
                aria-label="open drawer"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}
          <div className={classes.contacts}>{renderContacts('inHeader')}</div>
          {isUser && !isSearchPage && <SearchSelector />}
          <NotificationBar
            isBrokerRep={isBrokerRep}
            logoutAsVendorUser={!isUserHimself ? logoutAsVendorUser : undefined}
            isLoggedIn={!!userRole}
            logout={logoutUser}
          />
          <LanguageSwitcherBar />
        </Toolbar>
      </AppBar>
      {sideMenuVisible() && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.menuOverContent]: true,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <List>
            {links.map((link) => (
              <AppBarLink key={link.route.path} link={link} onClick={handleLinkClick} />
            ))}
            {userInMenu && <div className={classes.userLink}>{renderUserLink()}</div>}
            {contactsInMenu && renderContacts('inMenu')}
          </List>
        </Drawer>
      )}
    </React.Fragment>
  );
}
