import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  },
  layout: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  actionsBlock: {
    marginTop: '10px',
  },
  optionField: {
    '& > div': {},
  },
}));

export default useStyles;
