import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fieldGroup: {
    position: 'relative',
  },
  signorSelector: {
    marginRight: theme.spacing(1.5),
  },
  signorElement: {
    marginLeft: theme.spacing(1.5),
  },
  warningIcon: {
    position: 'absolute',
    right: 0,
    top: '20px',
    color: '#ff9800',
  },
  warningSelectIcon: {
    position: 'absolute',
    right: '30px',
    top: '20px',
    color: '#ff9800',
  },
  helperText: {
    marginLeft: theme.spacing(1.5),
    color: '#f44336',
    fontSize: '0.7rem',
    fontWeight: 'normal',
  },
}));

export default useStyles;
