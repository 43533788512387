import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  newApplicationStyles: {
    '& .MuiStepLabel-iconContainer': {
      paddingRight: '15px',
    },
    '& .MuiDivider-root': {
      display: 'none',
    },
    maxHeight: 'calc(100vh - 240px)',
    paddingLeft: '20px',
    paddingRight: '20px',
    overflow: 'auto',
  },
  paperContainer: {
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  stepContent: {
    paddingLeft: '10px',
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  buttonOnRight: {
    marginTop: theme.spacing(1),
    float: 'right',
  },
  buttonMarginTop: {
    marginTop: theme.spacing(2),
  },
  colesseeAlert: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: theme.spacing(2),
  },
  completeButton: {
    float: 'right',
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
  actionsContainer: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  headerTitle: {
    marginBottom: theme.spacing(3),
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  liftEquipmentBtn: {
    marginTop: '-45px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '40px',
    },
  },
  failedMessage: {
    color: '#f2994a',
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  accordionDetails: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  mainHeaderPlate: {
    display: 'flex',
    alignItems: 'center',
    width: '100% ',
    fontSize: '0.8rem',
    padding: '8px 27px',
    paddingRight: '15px',
    margin: 0,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
    },
    '& .MuiButtonBase-root ': {
      float: 'right',
      padding: '0',
      marginTop: 0,
      color: '#eb5757',
    },
  },
  openedHeader: {
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    backgroundColor: '#F2F2F2',
  },
  collapsedHeader: {
    minHeight: 'auto',
    backgroundColor: theme.palette.common.white,
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  isClosed: {
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  isOpened: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  noMargin: {
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: 0,
    },
    margin: 0,
  },
  formButtons: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#F2F2F2',
  },
  marginButton: {
    marginRight: '5px',
  },
  backdropLoader: {
    zIndex: 1,
    '& .MuiCircularProgress-svg': {
      color: 'white',
    },
  },
  skippedSteps: {
    // paddingLeft: theme.spacing(2),
  },
  headerPlate: {
    width: '100%',
    backgroundColor: '#F2F2F2',
    fontSize: '0.8rem',
    padding: '10px 52px',
    paddingRight: '15px',
    margin: 'auto',
    marginTop: '10px',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  skippedStepAlert: {
    padding: '5px 52px',
  },
  accordeonSummaryNopadXs: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  containerConsent: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      '& .MuiContainer-root': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  },
}));

export default useStyles;
