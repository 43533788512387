import Typography from '@material-ui/core/Typography';
import React from 'react';

export const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center" data-cy="copyright-text">
    <span data-cy="copyright-link" color="inherit">
      mycreditportal.ca {' ©'}
    </span>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);
