import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';

export default function DateFormatter(props) {
  const { date, showTime = false } = props;

  return showTime ? (
    <FormattedMessage
      id="intl.datetime"
      defaultMessage="{day} {month} {year} {time}"
      values={{
        day: <FormattedDate value={date} day="2-digit" />,
        month: <FormattedDate value={date} month="short" />,
        year: <FormattedDate value={date} year="numeric" />,
        time: <FormattedTime value={date} hour="numeric" minute="numeric" />,
      }}
    />
  ) : (
    <FormattedMessage
      id="intl.date"
      defaultMessage="{day} {month} {year}"
      values={{
        day: <FormattedDate value={date} day="2-digit" />,
        month: <FormattedDate value={date} month="short" />,
        year: <FormattedDate value={date} year="numeric" />,
      }}
    />
  );
}
