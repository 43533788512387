import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { useStyles } from './styles';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import { translateLabel } from '../../../utils/translateLabel';
import { useIntl } from 'react-intl';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>(function OuterElementType(props, ref) {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: unknown) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);

  return ref;
}

// Adapter for react-window
const getListboxComponent = (dataCy?: string) =>
  React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));
    const itemCount = itemData.length;
    const minItemSize = 36;
    const maxItemSize = 48;
    const itemSize = smUp ? minItemSize : maxItemSize;
    const minItemCountForHeight = 8;
    const dataCyListAttribute = dataCy
      ? {
          'data-cy': `entity-search${dataCy}-list`,
        }
      : {};

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return maxItemSize;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > minItemCountForHeight) {
        return minItemCountForHeight * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div {...dataCyListAttribute} ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

export type VirtualizedMenuPropsType<T> = Partial<
  AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>
> & {
  options: T[];
  getOptionLabel: (option: T) => string;
  label: string;
  dataCy?: string;
};

export default function Virtualize<T>({
  options,
  getOptionLabel,
  label,
  dataCy,
  ...other
}: VirtualizedMenuPropsType<T>) {
  const classes = useStyles();
  const dataCyInputAttribute = dataCy
    ? {
        'data-cy': `entity-search-${dataCy}-input`,
      }
    : {};

  const ListboxComponent = React.useMemo(() => {
    return getListboxComponent(dataCy);
  }, [dataCy]);
  const intl = useIntl();

  return (
    <Autocomplete<T, boolean | undefined, boolean | undefined, boolean | undefined>
      {...other}
      disableListWrap
      classes={classes}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      renderGroup={renderGroup}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...dataCyInputAttribute}
          {...params}
          variant="outlined"
          label={translateLabel(intl, label as string)}
        />
      )}
    />
  );
}
