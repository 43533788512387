import React, { ReactChild } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Paper } from '@material-ui/core';
import TransferList from './transfer-list';
import Filter from './filter';
import { useIntl } from 'react-intl';

type TabPanelPropType = {
  index: number;
  value: number;
  children: ReactChild;
};

function TabPanel(props: TabPanelPropType) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}>
      {value === index && (
        <Paper variant="outlined" style={{ padding: '10px' }} square>
          <Typography>{children}</Typography>
        </Paper>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

type SimpleTabPropsType = {
  filterList: GridViewFilterListType;
  updateFilterList: (filterList: GridViewFilterListType) => void;
  columns: GridViewColumnType[];
};

export default function SimpleTabs(props: SimpleTabPropsType) {
  const { columns, filterList, updateFilterList } = props;

  const classes = useStyles();
  const intl = useIntl();
  const [value, setValue] = React.useState(0);

  const handleChange: React.ComponentProps<typeof Tabs>['onChange'] = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab label={intl.formatMessage({ id: 'grid.customize_columns' })} {...a11yProps(0)} />
          <Tab label={intl.formatMessage({ id: 'grid.customize_filter' })} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TransferList columns={columns} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Filter columns={columns} filterList={filterList} updateFilterList={updateFilterList} />
      </TabPanel>
    </div>
  );
}
