import React from 'react';
import { SubmittedApplicationsConnectedProps } from './submitted-applications.container';
import { useIntl } from 'react-intl';
import ServerSideDataGrid from '../../components/common/VpDataGrid/ServerSideDataGrid';
import { GridApi, IDatasource } from '@ag-grid-community/all-modules';
import { AgGridColumn } from '@ag-grid-community/react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import useStyles from './styles';
import { APPLICATION_PAGE } from '../../constants/navigations';

function DraftApplications(props: SubmittedApplicationsConnectedProps) {
  const { fetchApplicationList, userRole } = props;
  const intl = useIntl();
  const [gridApi, setGridApi] = React.useState<null | GridApi>(null);
  const classes = useStyles();
  const idLabelRendererParams = {
    path: APPLICATION_PAGE.path,
    pathParams: {
      id: 'uuid',
    },
  };
  const isNotPortalAdmin = userRole !== 'Portal Admin';

  const datasource: IDatasource = {
    getRows: async (params) => {
      const quotes = await fetchApplicationList(params);

      params.successCallback(quotes?.data ?? [], quotes?.total);
    },
  };

  return (
    <div>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        alignItems="center"
        className={classes.headerSpaceBottom}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Hidden smDown>
            <Typography data-cy="quotes-header" variant="h1" gutterBottom>
              {intl.formatMessage({ id: 'application.submitted_applications' })}
            </Typography>
          </Hidden>
        </Grid>
      </Grid>

      <ServerSideDataGrid
        onInit={setGridApi}
        api={gridApi}
        datasource={datasource}
        fitType="fit"
        rowSelection="multiple"
      >
        <AgGridColumn type="quote:selectAll" />
        <AgGridColumn
          floatingFilter
          type="draftApplication:contractId"
          valueFormatter={(params) => params.value || 'N/A'}
        />
        <AgGridColumn floatingFilter type="draftApplication:customer" />
        <AgGridColumn
          floatingFilter
          type="draftApplication:idLabelWithLink"
          cellRendererParams={idLabelRendererParams}
        />
        <AgGridColumn floatingFilter type="draftApplication:status" />
        <AgGridColumn floatingFilter type="draftApplication:equipment" />
        <AgGridColumn floatingFilter type="draftApplication:equipmentCost" />
        <AgGridColumn floatingFilter type="draftApplication:customerEmail" />
        <AgGridColumn floatingFilter type="draftApplication:phoneNumber" />
        <AgGridColumn floatingFilter type="draftApplication:createdAt" />
        <AgGridColumn
          hide={isNotPortalAdmin}
          type="applicationDeleteLink"
          flex={1}
          resizable
          cellClass={'ag-custom-text-align-center'}
          headerClass={'ag-custom-text-align-center'}
        />
      </ServerSideDataGrid>
    </div>
  );
}

export default DraftApplications;
