import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: EmailTemplateStoreType = {
  emailTemplateEdit: {},
  previewEmail: null,
};

const slice = createSlice({
  name: 'emailTemplateList',
  initialState,
  reducers: {
    updateEmailTemplateOnEdit: (state, action: PayloadAction<EmailTemplateEditBEResponse>) => {
      state.emailTemplateEdit = action.payload;
    },
    updatePreviewEmail: (state, action: PayloadAction<EmailTemplatePreviewBEResponse>) => {
      state.previewEmail = action.payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
