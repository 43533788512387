import { BaseDataGrid } from '../../components/common/VpDataGrid';
import { AgGridColumn } from '@ag-grid-community/react';
import React from 'react';
import { getBrokersVendorsList } from '../../apis/broker-rep';

import { AllCommunityModules, GridApi, IDatasource } from '@ag-grid-community/all-modules';
import useStyles from '../../components/common/VpDataGrid/styles';

const VendorList = () => {
  const gridApi = React.useRef<GridApi | null>(null);
  const classes = useStyles();

  const dataSource: IDatasource = {
    getRows: async (params) => {
      const queryArray: Array<[string, string | number]> = [];
      queryArray.push(['limit', params.endRow - params.startRow]);
      queryArray.push(['offset', params.startRow]);
      queryArray.push(['sortingField', params.sortModel[0]?.colId]);
      queryArray.push(['sortingDirection', params.sortModel[0]?.sort]);

      Reflect.ownKeys(params.filterModel).forEach((field) => {
        const filter = encodeURIComponent(params.filterModel[field]?.filter);
        return queryArray.push([field as string, filter]);
      });

      const queryString = queryArray
        .filter((a) => a[1])
        .map((a) => a.join('='))
        .join('&');

      getBrokersVendorsList(queryString)
        .then((res) => {
          const vendors = res.data;
          params.successCallback(vendors.rows, vendors.count);
          setTimeout(() => {
            gridApi.current?.sizeColumnsToFit();
          });
        })
        .catch(() => {
          params.failCallback();
        });
    },
  };

  return (
    <div className={`${classes.alpineThemeContainer} ag-theme-alpine`}>
      <BaseDataGrid
        modules={AllCommunityModules}
        rowModelType="infinite"
        datasource={dataSource}
        onInit={(api) => (gridApi.current = api)}
        onViewportChanged={() => gridApi.current?.sizeColumnsToFit()}
        pagination
        paginationPageSize={10}
        cacheBlockSize={10}
      >
        <AgGridColumn floatingFilter flex={4} type="vendorCompanyName" />
        <AgGridColumn floatingFilter type="email" />
        <AgGridColumn floatingFilter type="companyWebsite" />
        <AgGridColumn width={150} maxWidth={150} suppressSizeToFit type="active" />
        <AgGridColumn type="createdAt" />
      </BaseDataGrid>
    </div>
  );
};

export default VendorList;
