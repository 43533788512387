import MyProfile from './my-profile.container';
import * as Navigations from '../../constants/navigations';
import MyBrokerRepProfile from './my-broker-rep-profile.container';
const { path } = Navigations.MY_PROFILE_PAGE;
const { path: brokerRepPath } = Navigations.MY_BROKER_REP_PROFILE_PAGE;
const route = [
  {
    path,
    name: 'MyProfile',
    component: MyProfile,
    exact: true,
  },
  {
    path: brokerRepPath,
    name: 'MyBrokerRepProfile',
    component: MyBrokerRepProfile,
    exact: true,
  },
];
export default route;
