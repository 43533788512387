import React, { useState } from 'react';
import { AttachmentsRendererType } from './index';
import {
  Button,
  Paper,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Delete from '@material-ui/icons/Delete';
import Visibility from '@material-ui/icons/Visibility';
import fileSize from 'filesize';
import { MonetizationOn } from '@material-ui/icons';
import { useIntl } from 'react-intl';

import UploadProgress from '../UploadProgress';
import { isPreviewPossible, usePreview } from '../usePreviewModal';
import { getBackendUrl } from '../../../utils/getBackendUrl';
import FileNotAvailableIcon from '../../icons/file-not-available';
import SortingIcon from '../../icons/sorting-icon';
import clsx from 'clsx';
import AlertModal from '../alert-modal';
import AppAttachmentsFileRow from './AppAttachmentsFileRow';

import { AttachTableCell, StyledTableBody } from './styles';
import useStyles from './styles';
import { setFileType } from '../../../constants/application';

export default function getApplicationAttachmentsRenderer({
  applicationUuid,
}: {
  applicationUuid: string;
}): AttachmentsRendererType {
  return function ApplicationAttachmentsRenderer({
    files,
    removeFile,
    uploadingFiles,
    openSelection,
    sendApplicationDocuments,
    canSubmitDocuments,
  }) {
    const { PreviewModal, openPreview } = usePreview(applicationUuid);
    const classes = useStyles();
    const intl = useIntl();
    const [selected, setSelected] = useState<string[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [sorting, setSorting] = useState<{ name: string; direction: string }>({
      name: 'file_created_date',
      direction: 'asc',
    });
    const isSelected = selected.length > 0;
    const isFiles = files.length > 0;
    const isUploading = uploadingFiles.length > 0;

    const downloadSelected = () => {
      if (selected.length == 1) {
        const downloadUrl =
          getBackendUrl() + `/application/${applicationUuid}/file/${selected[0]}?download`;
        return window.open(downloadUrl, '_blank');
      }
      const filesString = selected.map((uuid) => `files[]=${uuid}`).join('&');
      const downloadUrl =
        getBackendUrl() +
        `/application/${applicationUuid}/file/archive/create-archive?${filesString}`;
      window.open(downloadUrl);
    };

    const previewFile = () => openPreview(selected[0]);

    const isPreviewAvailable = () => {
      const file = files.find((file) => file.uuid == selected[0]);
      return file && isPreviewPossible(file.type);
    };

    const removeSelected = () => {
      const promises = selected.map((uuid: string) => {
        return removeFile(uuid);
      });
      setShowDeleteModal(false);
      return Promise.all(promises).then(() => setSelected([]));
    };

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target && event.target.checked) {
        return setSelected(files.map((file) => file.uuid));
      }
      setSelected([]);
    };

    const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
      if (event.target && event.target.checked && !selected.includes(uuid))
        return setSelected([...selected, uuid]);
      setSelected(selected.filter((item) => item !== uuid));
    };

    const sort = (name: string) => {
      let dir = '';
      if (sorting.direction === 'asc') dir = 'desc';
      if (sorting.direction === '') dir = 'asc';
      if (name !== sorting.name) dir = 'asc';

      setSorting({ name, direction: dir });
    };

    const sortingDirection = (
      arrayForSort: FileType[],
      key: string,
      sorterFunc: (a: string, b: string) => number,
    ) => {
      const { direction } = sorting;

      if (direction == 'asc')
        return arrayForSort.sort((a, b) => {
          const A = a[key as keyof FileType] || '';
          const B = b[key as keyof FileType] || '';
          return sorterFunc(A, B);
        });
      if (direction == 'desc')
        return arrayForSort.sort((a, b) => {
          const A = a[key as keyof FileType] || '';
          const B = b[key as keyof FileType] || '';
          return sorterFunc(B, A);
        });
    };

    const sortFiles = () => {
      const { name } = sorting;
      const arrayForSort = [...files];

      if (name == 'file_name' || name == 'file_type')
        sortingDirection(arrayForSort, 'name', (a, b) => {
          const lowerA = a || '';
          const lowerB = b || '';
          return lowerB.localeCompare(lowerA);
        });
      if (name == 'file_size') sortingDirection(arrayForSort, 'size', (a, b) => (a > b ? 1 : -1));
      if (name == 'file_created_date')
        sortingDirection(arrayForSort, 'createdAt', (a, b) => (new Date(a) > new Date(b) ? 1 : -1));
      return arrayForSort;
    };

    const submitDocuments = async () => {
      const sent = await sendApplicationDocuments?.(selected);
      if (sent) {
        setSelected([]);
      }
    };

    return (
      <>
        <Paper data-cy="files" className={classes.container} variant="outlined">
          <Typography
            data-cy="application-files-header"
            className={classes.header}
            variant="h2"
            gutterBottom
          >
            <span>
              <span>{intl.formatMessage({ id: 'application.files' })}</span>
            </span>
            <Button
              data-cy="add-file-btn"
              size="small"
              onClick={openSelection}
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<CloudUploadIcon />}
            >
              {intl.formatMessage({ id: 'common.add_file' })}
            </Button>
          </Typography>

          {!files.length && !uploadingFiles.length && (
            <div className={classes.notAvailableContainer}>
              <FileNotAvailableIcon viewBox="0 0 80 80" className={classes.notAvailableIcon} />
              <p data-cy="files-na-texting">
                {intl.formatMessage({ id: 'application.file_not_available' })}
              </p>
            </div>
          )}
          {isFiles && (
            <div className={classes.buttonGroup}>
              <Button
                data-cy="submit"
                size="small"
                onClick={submitDocuments}
                disabled={!canSubmitDocuments}
                variant="contained"
                className={classes.button}
                startIcon={<MonetizationOn />}
              >
                {intl.formatMessage({ id: 'application.files_submit_for_funding' })}
              </Button>
              <Button
                data-cy="preview"
                disabled={selected.length > 1 || selected.length === 0 || !isPreviewAvailable()}
                size="small"
                onClick={previewFile}
                variant="contained"
                className={classes.button}
                startIcon={<Visibility />}
              >
                {intl.formatMessage({ id: 'application.files_preview' })}
              </Button>
              <Button
                data-cy="download"
                disabled={!selected.length}
                size="small"
                onClick={downloadSelected}
                variant="contained"
                className={classes.button}
                startIcon={<CloudDownload />}
              >
                {intl.formatMessage({ id: 'application.files_download' })}
              </Button>
              <Button
                data-cy="delete"
                disabled={!selected.length}
                size="small"
                onClick={() => setShowDeleteModal(true)}
                variant="contained"
                className={classes.button}
                startIcon={<Delete />}
              >
                {intl.formatMessage({ id: 'application.files_delete' })}
              </Button>
            </div>
          )}
          {isFiles && (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table" size="small">
                <TableHead data-cy="application-attachment-header">
                  <TableRow>
                    <AttachTableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={isSelected && selected.length < files.length}
                        checked={selected.length === files.length}
                        onChange={handleSelectAll}
                      />
                    </AttachTableCell>
                    <AttachTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.sortingIcon}
                      onClick={() => sort('file_name')}
                    >
                      {intl.formatMessage({ id: 'application.file_name' })}
                      {sorting.name == 'file_name' && <SortingIcon direction={sorting.direction} />}
                    </AttachTableCell>
                    <AttachTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.sortingIcon}
                      onClick={() => sort('file_type')}
                    >
                      {intl.formatMessage({ id: 'application.file_type' })}
                      {sorting.name == 'file_type' && <SortingIcon direction={sorting.direction} />}
                    </AttachTableCell>
                    <AttachTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.sortingIcon}
                      onClick={() => sort('file_size')}
                    >
                      {intl.formatMessage({ id: 'application.file_size' })}
                      {sorting.name == 'file_size' && <SortingIcon direction={sorting.direction} />}
                    </AttachTableCell>
                    <AttachTableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.sortingIcon}
                      onClick={() => sort('file_created_date')}
                    >
                      {intl.formatMessage({ id: 'application.file_created_date' })}
                      {sorting.name == 'file_created_date' && (
                        <SortingIcon direction={sorting.direction} />
                      )}
                    </AttachTableCell>
                  </TableRow>
                </TableHead>
                <StyledTableBody data-cy="files-table-body">
                  {sortFiles().map((file) => (
                    <AppAttachmentsFileRow
                      key={file.uuid}
                      name={file.name}
                      type={setFileType(file.type)}
                      size={fileSize(+file.size)}
                      uuid={file.uuid}
                      createdAt={file.createdAt || new Date().toDateString()}
                      isSelected={selected.includes(file.uuid)}
                      handleCheckboxClick={handleCheckboxClick}
                      className={clsx({ [classes.fileIsSelected]: selected.includes(file.uuid) })}
                    />
                  ))}
                </StyledTableBody>
              </Table>
            </TableContainer>
          )}
          {isUploading && (
            <TableContainer>
              {uploadingFiles.map((file) => (
                <UploadProgress key={file.uuid} uuid={file.uuid} />
              ))}
            </TableContainer>
          )}
        </Paper>
        <PreviewModal />
        <AlertModal
          title={intl.formatMessage({ id: 'application.delete_question_title' })}
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onSubmit={removeSelected}
        />
      </>
    );
  };
}
