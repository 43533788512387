import React, { useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { AgGridColumn, AgGridReact, ChangeDetectionStrategyType } from 'ag-grid-react';
import Filter from './customize-view';
import VaultButton from '../vault-button';
import Grid from '@material-ui/core/Grid';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import SearchBar from './search-bar';
import AlertModal from '../alert-modal';
import useStyles from './styles';
import { useIntl, FormattedMessage } from 'react-intl';
import DropDown from '../dropdown';
import clsx from 'clsx';
import { RenderColumn } from './columns';
import { GridApi, RowNode } from 'ag-grid-community';
import { Variant } from '@material-ui/core/styles/createTypography';
import { useDataGrid } from './useDataGrid';

type VaultDataGridPropsType = {
  rows: ApplicationListItemType[] | QuoteType[] | UserType[] | AmendmentType[];
  selectedGridView: string;
  updateSelectedGridView?: (value: string) => void;
  gridViews: GridViewType[];
  filterList: GridViewFilterListType;
  updateFilterList?: (filterList: GridViewFilterListType) => void;
  handleColumnsChange?: (columns: GridViewColumnType[]) => void;
  columns: GridViewColumnType[];
  addCustomGridView?: (gridView: GridViewType) => void;
  editButtonLabel: string;
  handleEdit1?: ((key: string) => void) | false;
  loginAs?: ((key: string) => void) | false;
  linkPagePath2?: string;
  link2Header?: string;
  isMaskButton?: boolean;
  maskButtonLabel?: string;
  maskButtonClick?: (uuids: Array<string>) => void;
  maskButtonType?: string;
  CustomButtons?: React.FC | (() => JSX.Element) | null;
  customOnSelectionChange?: (selectedRows?: RowNode[]) => void;
  showFilterButton?: boolean;
  onDeleteSelected?: (uuids: Array<string>) => void;
  dropdown?: {
    options: Array<EmailTemplateListType>;
    renderItem: (t: EmailTemplateListType) => string;
    onSelect: (item: EmailTemplateListType, uuids: string[]) => void;
    label: string;
  };
  statusesMap?: { [key: string]: string };
  title: string;
  titleVariant?: Variant | 'inherit';
  hideSearchBar?: boolean;
  hideTable?: boolean;
  searchLabel?: string;

  paginationLimit?: number;
  paginationTotal?: number;
  paginationSwitchPage?: (limit: number, offset: number) => void;
  headerHeight?: number;
  filteringPaginationSwitchPage?: (params: FetchSortRequestListType) => void;
  websocketUpdate?: {
    entity: string;
    filter: string;
  };
};

export default function VaultDataGrid(props: VaultDataGridPropsType) {
  const {
    rows,
    selectedGridView,
    gridViews,
    filterList,
    updateFilterList,
    columns,
    addCustomGridView,
    editButtonLabel,
    handleEdit1,
    loginAs,
    linkPagePath2,
    link2Header,
    isMaskButton,
    maskButtonLabel,
    maskButtonClick,
    maskButtonType,
    CustomButtons,
    customOnSelectionChange,
    onDeleteSelected,
    dropdown,
    statusesMap,
    title,
    titleVariant,
    hideSearchBar,
    hideTable,
    searchLabel,
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const selectedGrid = gridViews.find((x) => x.label === selectedGridView);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [disableButtons, setDisableButtons] = useState(true);
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedRowsCounter, setSelectedRowsCounter] = useState<number | undefined>(0);

  const {
    onSelectionChanged,
    onGridSizeChanged,
    onChange,
    onCancel,
    handleDeleteSelected,
    handleOpenDeleteModal,
    onMaskButtonClick,
    onDropdownSelect,
    ViewDetailRenderer1,
    loginAsRenderer,
    handleLinkPagePath2Renderer,
    idLabelRenderer,
    applicationNameRenderer,
    contractIdRenderer,
    statusRenderer,
    dateFieldRenderer,
    filesRenderer,
    currencyRenderer,
  } = useDataGrid(gridApi, classes);

  const renderedColumns = useMemo(
    () => selectedGrid?.columns.map((column) => RenderColumn({ column, title: link2Header ?? '' })),
    [selectedGrid],
  );

  const onCloseModel = () => {
    setOpen(false);
  };

  const handleOnSelectChange = () => {
    setSelectedRowsCounter(gridApi?.getSelectedRows().length);
    return onSelectionChanged(setDisableButtons, customOnSelectionChange);
  };

  const showDeleteButton = onDeleteSelected && rows.length > 0;
  const showSelectedRowsCounterString =
    selectedRowsCounter && selectedRowsCounter > 0 ? true : false;

  const defaultComponents = {
    viewDetailRenderer1: (props: { node: RowNode }) =>
      ViewDetailRenderer1(props, editButtonLabel, handleEdit1),
    loginAsRenderer: (props: { node: RowNode }) =>
      loginAsRenderer(props, intl.formatMessage({ id: 'button.login_as' }), loginAs),
    linkPagePath2Renderer: (props: { node: RowNode }) =>
      handleLinkPagePath2Renderer(props, linkPagePath2),
    idLabelRenderer: (props: { node: RowNode }) => idLabelRenderer(props, handleEdit1),
    applicationNameRenderer: (props: { node: RowNode }) =>
      applicationNameRenderer(props, handleEdit1),
    contractIdRenderer,
    statusRenderer: (props: { node: RowNode }) => statusRenderer(props, statusesMap),
    filesRenderer,
    dateFieldRenderer: dateFieldRenderer,
    currencyRenderer,
  };
  const frameworkComponents = defaultComponents;

  return (
    <React.Fragment>
      {updateFilterList && addCustomGridView && (
        <Filter
          selectedGridView={selectedGridView}
          onCloseModel={onCloseModel}
          columns={columns}
          open={open}
          filterList={filterList}
          updateFilterList={updateFilterList}
          addCustomGridView={addCustomGridView}
        />
      )}
      <div className={`${classes.alpineThemeContainer} ag-theme-alpine`}>
        <Grid container spacing={2} className={classes.container}>
          {title && (
            <Grid item container xs={12} alignItems="center" style={{ padding: 0 }}>
              <Hidden mdUp>
                <Typography variant={titleVariant ?? 'h1'} className={classes.mainTitle}>
                  {title}
                </Typography>
              </Hidden>
            </Grid>
          )}

          {(title || !hideSearchBar) && (
            <Grid item container xs={12} sm={12} md={12} lg={12} alignItems="center">
              <Grid item xs={12} sm={6} md={6} lg={6}>
                {title && (
                  <Hidden smDown>
                    <Typography variant={titleVariant ?? 'h1'} className={classes.title}>
                      {title}
                    </Typography>
                  </Hidden>
                )}
                {!hideSearchBar && (
                  <SearchBar
                    searchText={searchText}
                    searchLabel={searchLabel ?? ''}
                    onChange={(value) => onChange(value, setIsSearchResult, setSearchText)}
                    isSearching={isSearchResult}
                    onCancel={() => onCancel(setIsSearchResult, setSearchText)}
                  />
                )}
              </Grid>
              {CustomButtons && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <CustomButtons />
                </Grid>
              )}
              {showDeleteButton && (
                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.deleteBtnContainer}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    disabled={disableButtons}
                    onClick={() => handleOpenDeleteModal(setShowDeleteModal)}
                  >
                    <FormattedMessage id="grid.delete" />
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            {isMaskButton && (
              <Grid item xs={12} sm={4}>
                <VaultButton
                  onClick={() => onMaskButtonClick(maskButtonClick)}
                  label={maskButtonLabel}
                  type={maskButtonType}
                />
              </Grid>
            )}
            {dropdown && (
              <div className={clsx(classes.actionsContainer, classes.rightSpacing)}>
                <DropDown
                  options={dropdown.options.map((t) => ({
                    label: dropdown.renderItem(t),
                    handler: () => onDropdownSelect(t, dropdown),
                  }))}
                >
                  <VaultButton label={dropdown.label} disabled={disableButtons} />
                </DropDown>
              </div>
            )}
          </Grid>
        </Grid>
        {/* there are problems with immer and AgGrid app crashes on rerender changing rowDataChangeDetectionStrategy should work */}
        {!hideTable && (
          <div onTouchEndCapture={(e) => e.stopPropagation()} className={classes.tableHolder}>
            <AgGridReact
              rowData={rows}
              rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
              onGridReady={(params) => setGridApi(params.api)}
              onGridSizeChanged={onGridSizeChanged}
              suppressRowClickSelection
              frameworkComponents={frameworkComponents}
              onSelectionChanged={handleOnSelectChange}
              rowSelection="multiple"
              groupSelectsChildren
              suppressRowDeselection
              rowMultiSelectWithClick
            >
              {renderedColumns}
              <AgGridColumn
                hide={!handleEdit1}
                key="Action"
                field="Action"
                width={100}
                cellRenderer="viewDetailRenderer1"
              />
              <AgGridColumn
                hide={!loginAs}
                key="Login"
                field="Login"
                width={100}
                cellRenderer="loginAsRenderer"
              />
            </AgGridReact>
            <Grid container alignItems="baseline" spacing={2}>
              <Grid item>
                {showSelectedRowsCounterString && (
                  <span>
                    {gridApi?.getSelectedRows().length}{' '}
                    {intl.formatMessage({ id: 'grid.items_selected_on_this_page' })}
                  </span>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <AlertModal
        title={intl.formatMessage({ id: 'grid.delete_question_title' })}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => handleDeleteSelected(setShowDeleteModal, onDeleteSelected)}
      />
    </React.Fragment>
  );
}
