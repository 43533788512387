import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InstantQuoteStoreType = {
  loading: false,
  error: '',
  quoteInfo: {},
  application: {},
  generatedQuotes: [],
  files: [],
  selectedPaymentOption: null,
  emailPreview: null,
  lastFollowUpDate: null,
};

const slice = createSlice({
  name: 'instantQuote',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    fetchQuoteSuccess: (state, action: PayloadAction<GetInstantQuoteResponse>) => {
      Object.assign(state, {
        files: action.payload.files,
        quoteInfo: action.payload.quoteInfo,
        generatedQuotes: action.payload.generatedQuotes,
        application: action.payload.application,
        selectedPaymentOption: action.payload.selectedPaymentOption,
      });
    },
    cleanState: (state) => {
      state.quoteInfo = {};
      state.generatedQuotes = [];
      state.selectedPaymentOption = null;
      state.application = {};
      state.files = [];
    },
    quoteError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    addFiles: (state, action: PayloadAction<FileType[]>) => {
      state.files.push(...action.payload);
    },
    removeFile: (state, action: PayloadAction<string>) => {
      state.files = state.files.filter((f) => f.uuid !== action.payload);
    },
    updateLastFollowUpDate: (state, action: PayloadAction<string | null>) => {
      state.lastFollowUpDate = action.payload;
    },
    previewEmail: (state, action: PayloadAction<InstantQuoteStoreType['emailPreview']>) => {
      state.loading = false;
      state.emailPreview = action.payload;
    },
  },
});

export default slice.reducer;
export const actions = slice.actions;
