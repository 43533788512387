import React from 'react';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import { getBackendUrl } from '../../../utils/getBackendUrl';
import clsx from 'clsx';

type ContactsPropType = {
  logo: string;
  name: string;
  phone: string;
  email: string;
  type: 'inHeader' | 'inMenu';
  logoExist?: boolean;
};

export default function Contacts({ email, logo, name, phone, type, logoExist }: ContactsPropType) {
  const style = useStyles();
  const logoUrl = `${getBackendUrl()}/static/${encodeURI(logo)}`;
  const logoStyleProp = logo && logoExist ? { backgroundImage: `url(${logoUrl})` } : {};

  return (
    <div
      data-cy="header-item"
      className={clsx(style.root, style[type], {
        [style.noImage]: !logoExist,
      })}>
      {logoExist && <div data-cy="logo-image" style={logoStyleProp} className={style.logo} />}
      <div className={style.infoWrapper}>
        <div className={style.nameWrapper}>
          <Typography data-cy="header-title" className={style.name} variant="h6">
            {name}
          </Typography>
        </div>
        <a data-cy="header-phone" className={style.link} href={`tel:${phone}`}>
          {phone}
        </a>
        <a data-cy="header-email" className={style.link} title={email} href={`mailto:${email}`}>
          {email}
        </a>
      </div>
    </div>
  );
}
