import { connect, ConnectedProps } from 'react-redux';
import { actions as emailTemplatesActions } from '../../store/emailTemplatesList';
import { actions as vendorSettingsActions } from '../../store/vendorSettings';
import EmailTemplate from './email-template.component';

export const mapStateToProps = (state: AppStoreType) => ({
  vendorUuid: (state.user as UserBasedStoreType).vendorUuid,
  emailTemplateEdit: state.emailTemplateList.emailTemplateEdit,
  emailPreviewContent: state.emailTemplateList.previewEmail,
  vendorsList: state.vendorSettings.vendorsList,
});

export const mapDispatchToProps = {
  getEmailTemplate: emailTemplatesActions.getEmailTemplate,
  updateEmailTemplate: emailTemplatesActions.updateEmailTemplate,
  createEmailTemplate: emailTemplatesActions.createEmailTemplate,
  deleteEmailTemplate: emailTemplatesActions.deleteEmailTemplate,
  duplicateEmailTemplate: emailTemplatesActions.duplicateEmailTemplate,
  previewEmailTemplate: emailTemplatesActions.previewEmailTemplate,
  fetchVendorsList: vendorSettingsActions.fetchVendorsList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type EmailTemplatesConnectedProps = ConnectedProps<typeof connector>;
export default connector(EmailTemplate);
