import QuoteToApplication from './quote-to-application.container';
import * as Navigations from '../../constants/navigations';
const { path } = Navigations.QUOTE_TO_APPLICATION_PAGE;

export const publicRoute = [
  { path: path, name: 'PublicQuoteToApplication', component: QuoteToApplication, exact: true },
];

export const routeWithAuth = [
  { path, name: 'QuoteToApplication', component: QuoteToApplication, exact: true },
];
