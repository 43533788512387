import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ApplicationInfo from '../../components/common/ApplicationInfo';
import ApplicationApplicantSection from './applicant-information-section';
import ColesseeSection from './colessee-section';
import EquipmentSection from './equipment-section';
import NoteSection from './note-section';
import AttachmentsSection from './attachments-section';
import ConsentSection from './consent-section';
import React from 'react';
import useStyles from './styles';
import NewApplicationContext from './new-application.context';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { SectionType } from './applicationUtils';
import { useParams } from 'react-router-dom';
import { ApplicationRouteParamsType } from './new-application';
import SameAsContext, { SameAsContextProps, SameAsListItem } from './same-as.context';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormButtons from './form-buttons';
import { CURRENCY_TYPE } from '../../constants/currency';

type NewApplicationSectionsPropsType = {
  application: VpCompleteApplicationType | null;
};

export default function NewApplicationSections({ application }: NewApplicationSectionsPropsType) {
  const routeParams = useParams<ApplicationRouteParamsType>();
  const classes = useStyles();
  const {
    isNewApplicationPageReady,
    userUuid,
    vendorReps,
    sections,
    loadApplication,
    handleSectionClose,
    handleSectionOpen,
  } = React.useContext(NewApplicationContext);
  const sameAsList = React.useRef<SameAsListItem[]>([]);
  const intl = useIntl();
  const sameAsContext: SameAsContextProps = {
    sameAsList: sameAsList.current,
    getSameAsList: (currentKey) =>
      sameAsList.current
        .filter((i) => i.value && i.key !== currentKey && !currentKey.includes('Applicant'))
        .sort((a, b) => a.label.localeCompare(b.label)),
    updateSameAsList: (item) => {
      const list = sameAsList.current.filter((i) => i.key !== item.key);
      list.push(item);
      sameAsList.current = list;
    },
  };
  const hasFailedSections = React.useMemo(() => {
    return sections.filter((s) => s.validationState === 'failed')?.length > 0;
  }, [sections]);

  React.useEffect(() => {
    if (routeParams.applicationUuid) {
      loadApplication(routeParams.applicationUuid);
    }
  }, [routeParams.applicationUuid]);

  const accordionOnChange =
    (section: number) => (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
      if (newExpanded) {
        handleSectionOpen(section);
      } else {
        handleSectionClose(section);
      }
    };

  const accordionIsExpanded = (section: number) => {
    return sections.find((s) => s.orderNo === section)?.isOpened ?? false;
  };

  if (!isNewApplicationPageReady) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={8}>
        <Paper variant="outlined" className={classes.newApplicationStyles}>
          <SameAsContext.Provider value={sameAsContext}>
            <Grid container justifyContent="flex-start" alignItems="center" alignContent="center">
              <Grid item>
                <ApplicationInfo
                  applicationInfo={application}
                  vendorReps={vendorReps}
                  userUuid={application?.application.userUuid || userUuid}
                  path="application"
                />
                <Accordion
                  className={classes.noMargin}
                  onChange={accordionOnChange(1)}
                  square
                  expanded={accordionIsExpanded(1)}
                >
                  <SectionLabel {...sections[0]} />
                  <AccordionDetails className={classes.accordionDetails}>
                    <ApplicationApplicantSection defaultData={application?.applicant ?? null} />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.noMargin}
                  square
                  onChange={accordionOnChange(2)}
                  expanded={accordionIsExpanded(2)}
                >
                  <SectionLabel {...sections[1]} />
                  <AccordionDetails className={classes.accordionDetails}>
                    <ColesseeSection
                      applicantData={application?.applicant ?? null}
                      defaultData={application?.parties ?? []}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.noMargin}
                  square
                  onChange={accordionOnChange(3)}
                  expanded={accordionIsExpanded(3)}
                >
                  <SectionLabel {...sections[2]} />
                  <AccordionDetails className={classes.accordionDetails}>
                    <EquipmentSection
                      defaultData={{
                        equipments: application?.equipments ?? [],
                        frequency: application?.application?.frequency ?? null,
                        term: application?.application?.term ?? null,
                        endOfTermOption: application?.application?.endOfTermOption ?? '',
                        currency:
                          (application?.application.currency as CURRENCY_TYPE) ?? CURRENCY_TYPE.CAD,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.noMargin}
                  square
                  onChange={accordionOnChange(4)}
                  expanded={accordionIsExpanded(4)}
                >
                  <SectionLabel {...sections[3]} />
                  <AccordionDetails className={classes.accordionDetails}>
                    <NoteSection defaultData={application?.notes[0] ?? null} />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.noMargin}
                  square
                  onChange={accordionOnChange(5)}
                  expanded={accordionIsExpanded(5)}
                >
                  <SectionLabel {...sections[4]} />
                  <AccordionDetails className={classes.accordionDetails}>
                    <AttachmentsSection defaultData={application?.attachments ?? []} />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.noMargin}
                  square
                  onChange={accordionOnChange(6)}
                  expanded={accordionIsExpanded(6)}
                >
                  <SectionLabel {...sections[5]} />
                  <AccordionDetails className={classes.accordionDetails}>
                    <ConsentSection path="consent" />
                  </AccordionDetails>
                </Accordion>
                {hasFailedSections && (
                  <div className={classes.skippedSteps}>
                    <div className={classes.headerPlate} data-cy="application-skipped-steps">
                      {intl.formatMessage({ id: 'application.steps_skipped' })}
                    </div>
                    {sections
                      .filter((s) => s.validationState === 'failed')
                      .map((s) => (
                        <div className={classes.skippedStepAlert} key={s.orderNo}>
                          <Alert data-cy={`skipped-step-${s.orderNo}`} severity="warning">
                            {s.orderNo}. {intl.formatMessage({ id: s.titleId })}
                          </Alert>
                        </div>
                      ))}
                  </div>
                )}
              </Grid>
            </Grid>
          </SameAsContext.Provider>
        </Paper>
        <FormButtons application={application} />
      </Grid>
    </Grid>
  );
}

const SectionLabel = React.forwardRef(function SectionSummary(
  {
    titleId,
    validationState,
    isOpened,
  }: SectionType & React.ComponentProps<typeof AccordionSummary>,
  ref,
) {
  const classes = useStyles();
  const intl = useIntl();
  const isFailed = validationState === 'failed';

  return (
    <AccordionSummary
      innerRef={ref}
      className={clsx(classes.accordeonSummaryNopadXs, {
        [classes.collapsedHeader]: !isOpened,
        [classes.openedHeader]: isOpened,
      })}
    >
      <Typography variant="h2" gutterBottom className={classes.mainHeaderPlate}>
        <ExpandMoreIcon
          className={clsx({
            [classes.isClosed]: !isOpened,
            [classes.isOpened]: isOpened,
          })}
        />
        {intl.formatMessage({ id: titleId })}
        {isFailed && (
          <div className={classes.failedMessage}>
            <ReportProblemOutlinedIcon />
            {intl.formatMessage({ id: 'application.please_complete_this_section' })}
          </div>
        )}
      </Typography>
    </AccordionSummary>
  );
});
