import UserEdit from './user-edit.container';
import * as Navigations from '../../constants/navigations';
const { path } = Navigations.USER_EDIT_PAGE;
const route = [
  {
    path,
    name: 'UserEdit',
    component: UserEdit,
    exact: true,
  },
];
export default route;
