import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, TextField, Tooltip } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import WarningIcon from '@material-ui/icons/Warning';
import { UseFormMethods } from 'react-hook-form';
import VaultDatePicker from '../vault-date-picker';
import { useForm } from 'react-hook-form';
import { DOCUMENT_OVERRIDE_FORM_FIELD_TYPES } from '../../../constants/document';
import { useFormContext } from 'react-hook-form';
import { translateLabel } from '../../../utils/translateLabel';

type ApplicationDocumentOverrideFormFieldPropsType = {
  index: string;
  hide: boolean;
  field: ApplicationDocumentOverridableFields;
  originalValue: string | number | null | boolean;
  value: string | number | null | boolean;
  onChangeField: (
    path: string,
    value: string | number | boolean | null,
    control?: UseFormMethods['control'],
    name?: string,
    isDateField?: boolean,
  ) => void;
  register: ReturnType<typeof useForm>['register'];
};

export default function ApplicationDocumentOverrideFormField({
  index,
  hide,
  field,
  originalValue,
  value,
  onChangeField,
  register,
}: ApplicationDocumentOverrideFormFieldPropsType) {
  const classes = useStyles();
  const intl = useIntl();
  const { control } = useFormContext();

  const [isDifferent, setIsDifferent] = useState<boolean>(false);

  useEffect(() => {
    setIsDifferent(checkDifferent(value, originalValue));
  });

  const onChange = useCallback(
    (
      path: string,
      newValue: string | number | boolean | null,
      control?: UseFormMethods['control'],
      name?: string,
      isDateField?: boolean,
    ) => {
      setIsDifferent(checkDifferent(newValue, originalValue));
      onChangeField(path, newValue, control, name, isDateField);
    },
    [onChangeField],
  );

  const checkDifferent = (
    value: string | number | boolean | null,
    originalValue: string | number | boolean | null,
  ) => {
    if (value === '' && originalValue === null) {
      return false;
    }

    return value !== originalValue;
  };

  const isStringField = (field: ApplicationDocumentOverridableFields) => {
    return (
      field.type === DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.EMAIL ||
      field.type === DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.PHONE ||
      field.type === DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.STRING
    );
  };

  return (
    <>
      {!hide && isStringField(field) && (
        <div className={classes.fieldGroup}>
          <TextField
            name={field.path}
            label={translateLabel(intl, field.path, field.label)}
            defaultValue={value}
            required={field.required}
            inputRef={register({ required: field.required })}
            fullWidth
            onChange={(e) => onChange(field.path, e.target.value)}
          />
          {isDifferent && (
            <Tooltip
              title={`${intl.formatMessage({
                id: 'application.original_value',
              })}: ${originalValue}`}
              placement="top"
            >
              <WarningIcon className={classes.warningIcon} />
            </Tooltip>
          )}
        </div>
      )}

      {!hide && field.type === DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.NUMBER && (
        <div className={classes.fieldGroup}>
          <TextField
            name={field.path}
            label={translateLabel(intl, field.path, field.label)}
            required={field.required}
            inputRef={register({ required: field.required })}
            defaultValue={value}
            fullWidth
            onChange={(e) => onChange(field.path, e.target.value)}
          />
          {isDifferent && (
            <Tooltip
              title={`${intl.formatMessage({
                id: 'application.original_value',
              })}: ${originalValue}`}
              placement="top"
            >
              <WarningIcon className={classes.warningIcon} />
            </Tooltip>
          )}
        </div>
      )}

      {!hide && field.type === DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.BOOLEAN && (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              value={value}
              onChange={(e) => onChange(field.path, e.target.checked)}
            />
          }
          label={translateLabel(intl, field.path, field.label)}
        />
      )}

      {!hide && field.type === DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.DATE && (
        <VaultDatePicker
          id={`${index}`}
          name={`date-${index}`}
          label={translateLabel(intl, field.path, field.label)}
          required={field.required}
          defaultValue={String(value)}
          onChange={(value) =>
            onChange(field.path, value ? value.toString() : null, control, `date-${index}`, true)
          }
        />
      )}
    </>
  );
}
