import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: UserOnEditStoreType = {
  isLoading: true,
  user: {},
};

const slice = createSlice({
  name: 'userOnEdit',
  initialState,
  reducers: {
    updateUserOnEdit: (state, action: PayloadAction<UserOnEditStoreType['user']>) => {
      state.user = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
