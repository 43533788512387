import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as Navigations from '../../../constants/navigations';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
}));

function PageTitle() {
  const location = useLocation();
  const styles = useStyles();
  const intl = useIntl();
  const currentPage = Navigations.ALL_PAGES.find((x) => x.path === location.pathname);
  const titleId = currentPage?.title || '';
  const showTitle = currentPage?.showTitle || false;

  if (!titleId || !showTitle) {
    return null;
  }

  return (
    <Typography variant="h5" noWrap className={styles.title}>
      {intl.formatMessage({ id: titleId })}
    </Typography>
  );
}

export default PageTitle;
