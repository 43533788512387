import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paperSummary: {
    position: 'relative',
    padding: '14px',
    height: '100%',
  },
  paperTabs: {
    height: '100%',
    '& .MuiPaper-outlined': {
      border: 'none',
    },
  },
  expandIcon: {
    cursor: 'pointer',
    color: '#4153AF',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '10px',
    right: '10px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  detailsHeader: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #E5E5E5',
    '& $expandIcon': {
      position: 'relative',
      marginLeft: 'auto',
    },
  },
  tabsButtonNoSidebar: {
    [theme.breakpoints.only('lg')]: {
      opacity: 'inherit',
    },
  },
  tabsButtonSidebar: {
    '& .MuiTabScrollButton-root.Mui-disabled': {
      [theme.breakpoints.only('lg')]: {
        opacity: 1,
      },
    },
  },
}));

export default useStyles;
