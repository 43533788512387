import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';
import { QuoteEmailType, RECIPIENT_TYPE } from '../components/common/QuoteEmail';

const BACKEND = getBackendUrl() + '/email';
const api = getApi(BACKEND);

export type Recipient = {
  type: RECIPIENT_TYPE;
  isSelected?: boolean;
  email: string;
  name?: string;
};

export type Recipients = Recipient[];

type SendQuoteEmailByTemplateUuidArgumentType = {
  templateUuid: string;
  quoteUuid: string;
};

export const generateQuoteFollowUpEmailByTemplateUuid = async ({
  templateUuid,
  quoteUuid,
}: SendQuoteEmailByTemplateUuidArgumentType) => {
  return await api.post<{ subject: string; body: string }>(
    `/preview-quote-follow-up/${quoteUuid}/template/${templateUuid}`,
  );
};

export const generateQuoteEmailByTemplateUuid = async (
  templateUuid: string,
  generatedQuoteUuid: string,
  selectedPO?: string[],
  emailList?: QuoteEmailType[],
) => {
  return await api.post<{ subject: string; body: string }>(
    `/preview-quote/${generatedQuoteUuid}/template/${templateUuid}`,
    { selectedPO, recipients: emailList },
  );
};

export type SendQuoteDetailsEmail = {
  selectedPO?: string[];
  recipients: Recipient[];
  attachments?: string[];
  email:
    | {
        subject: string;
        body: string;
      }
    | {
        emailTemplateUuid: string;
      };
};

export const sendQuoteDetailsEmail = async (
  emailData: SendQuoteDetailsEmail,
  generatedQuoteUuid: string,
) => {
  return await api.post<EmailType>(`/send-quote/${generatedQuoteUuid}`, emailData);
};

export const sendQuoteDetailsEmailPublic = async (
  emailData: SendQuoteDetailsEmail,
  routeParams: { vendorSiteUrl: string; userSiteUrl?: string },
  generatedQuoteUuid: string,
) => {
  return await api.post<EmailType>(`/public/send-quote/${generatedQuoteUuid}`, emailData, {
    headers: {
      'vendor-site-url': routeParams.vendorSiteUrl,
      'user-site-url': routeParams.userSiteUrl ?? '',
    },
  });
};

export type SendQuoteFollowUpEmailsArgs = {
  quotes: Array<{
    uuid: string;
    recipients?: Recipients; // if omitted then will be taken from last sent quote
    email:
      | {
          subject: string;
          body: string;
        }
      | {
          emailTemplateUuid: string;
        };
  }>;
};

export const sendQuoteFollowUpEmails = async (quotesData: SendQuoteFollowUpEmailsArgs) => {
  return await api.post<{
    isSuccess: 'partially' | 'success';
    failedEmails: string[];
    lastFollowUpDate: string;
  }>('/send-quote-follow-up', quotesData);
};

export const previewEmail = async (templateUuid: string) => {
  return await api.get<{
    subject: string;
    body: string;
  }>(`/preview/${templateUuid}`);
};
