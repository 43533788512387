import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as formValuesApi from '../../apis/formValues';
import * as emailTemplateApi from '../../apis/emailTemplate';
import * as entityApi from '../../apis/entity';
import {
  getVendorProgram,
  getVendorSetting,
  getDefaultVendorProgram as getDefaultVendorProgramRequest,
  getVendorSettingPublic as getVendorSettingPublicRequest,
  getVendorRepPublic as getVendorRepPublicRequest,
  getVendorReps as getVendorRepsRequest,
} from '../../apis/vendorProgram';

const initialState: FormValuesStoreType = {
  isSiteDisabled: false,
  naicsList: [],
  entities: {
    businesses: [],
    individuals: [],
    soleProprietorship: [],
  },
  vendorProgramList: [],
  vendorReps: [],
  emailTemplateList: [],
  vendorSetting: {},
};

const getNaicsList = createAsyncThunk(
  'formValues/getMarketSegmentList',
  async (_, { dispatch }) => {
    let naicsList;
    try {
      naicsList = (await formValuesApi.getNaicsList()).data;
    } catch (e) {
      return;
    }

    dispatch(actions.updateNaicsList(naicsList));
  },
);

const getEntityList = createAsyncThunk('formValues/getEntityList', async (_, { dispatch }) => {
  let entites;
  try {
    entites = (await entityApi.getEntityList()).data;
  } catch (e) {
    return;
  }

  dispatch(actions.updateStoreEntityList(entites));
});

const getVendorProgramList = createAsyncThunk(
  'formValues/getVendorProgramList',
  async (_, { dispatch }) => {
    let vendorPrograms;
    try {
      vendorPrograms = (await getVendorProgram()).data;
    } catch (e) {
      return;
    }

    dispatch(actions.updateVendorProgramList(vendorPrograms));
  },
);

const getDefaultVendorProgram = createAsyncThunk(
  'formValues/getDefaultVendorProgram',
  async (
    { userSiteUrl, vendorSiteUrl }: { vendorSiteUrl: string; userSiteUrl?: string },
    { dispatch },
  ) => {
    let vendorPrograms;
    try {
      vendorPrograms = (await getDefaultVendorProgramRequest(vendorSiteUrl, userSiteUrl)).data;
    } catch (e) {
      const errorData = e.response?.data; // TODO server side translation

      if (errorData.code === 403) {
        dispatch(actions.updateDisabledStatus());
      }

      return;
    }

    dispatch(actions.updateVendorProgramList(vendorPrograms));
  },
);

const getVendorPublicRep = createAsyncThunk(
  'formValues/getVendorPublicRep',
  async (
    { userSiteUrl, vendorSiteUrl }: { vendorSiteUrl: string; userSiteUrl?: string },
    { dispatch },
  ) => {
    let vendorRep;
    if (!userSiteUrl || !vendorSiteUrl) {
      dispatch(actions.updateVendorReps([]));

      return;
    }
    try {
      vendorRep = (await getVendorRepPublicRequest(vendorSiteUrl, userSiteUrl)).data;
    } catch (e) {
      return;
    }

    dispatch(
      actions.updateVendorReps(
        vendorRep.map((rep) => ({
          value: rep.salesforceId,
          label: rep.name,
        })),
      ),
    );

    return;
  },
);

const getVendorReps = createAsyncThunk('formValues/getVendorReps', async (_, { dispatch }) => {
  let vendorReps;
  try {
    vendorReps = (await getVendorRepsRequest()).data;
  } catch (e) {
    return;
  }

  dispatch(
    actions.updateVendorReps(
      vendorReps.map((rep) => ({
        value: rep.salesforceId,
        label: rep.name,
        isDefault: rep.isDefault,
      })),
    ),
  );

  return;
});

const getAllEmailTemplates = createAsyncThunk(
  'formValues/getAllEmailTemplates',
  async (
    { relatedToObj, routeParams }: { relatedToObj: string[]; routeParams?: PublicRouteParams },
    { dispatch },
  ) => {
    let templates;

    try {
      templates = (await emailTemplateApi.getAllEmailTemplate(relatedToObj, routeParams)).data;
    } catch (e) {
      return;
    }

    const emailTemplates = templates.data.map((result) => {
      return {
        value: result.uuid,
        label: result.name,
        relatedTo: result.relatedTo,
        language: result.language,
      };
    });

    dispatch(actions.updateEmailTemplateList(emailTemplates));
  },
);

const getVendorSettings = createAsyncThunk(
  'formValues/getVendorSettings',
  async (_, { dispatch }) => {
    let vendorSettings;
    try {
      vendorSettings = (await getVendorSetting()).data;
    } catch (e) {
      return;
    }

    const mappedVendorSetting = vendorSettings.map((result) => {
      return {
        sendBrokerNotifications: result.getVPNotifications__c,
        vendorEmails: result.Vendor_Notification_Emails__c,
        brokerEmail: result.Vendor__r.Owner.Email,
        brokerName: result.Vendor__r.Owner.Name,
      };
    });
    if (mappedVendorSetting?.[0]) {
      dispatch(actions.updateVendorSettings(mappedVendorSetting[0]));
    }
  },
);

const getVendorSettingsPublic = createAsyncThunk(
  'formValues/getVendorSettingsPublic',
  async (
    { userSiteUrl, vendorSiteUrl }: { userSiteUrl?: string; vendorSiteUrl: string },
    { dispatch },
  ) => {
    let vendorSettings;
    try {
      vendorSettings = (await getVendorSettingPublicRequest(vendorSiteUrl, userSiteUrl)).data;
    } catch (e) {
      const errorData = e.response?.data; // TODO server side translation

      if (errorData.code === 403) {
        dispatch(actions.updateDisabledStatus());
      }

      return;
    }

    const mappedVendorSetting = vendorSettings.map((result) => {
      return {
        sendBrokerNotifications: result.getVPNotifications__c,
        vendorEmails: result.Vendor_Notification_Emails__c,
        brokerEmail: result.Vendor__r.Owner.Email,
        brokerName: result.Vendor__r.Owner.Name,
      };
    });
    if (mappedVendorSetting?.[0]) {
      dispatch(actions.updateVendorSettings(mappedVendorSetting[0]));
    }
  },
);

const slice = createSlice({
  name: 'formValues',
  initialState,
  reducers: {
    updateNaicsList: (
      state,
      action: PayloadAction<
        Array<{ value: string; labelEn?: string; labelFr?: string; id: string }>
      >,
    ) => {
      state.naicsList = action.payload;
    },
    updateStoreEntityList: (state, action: PayloadAction<GetEntityResponse>) => {
      state.entities = action.payload;
    },
    updateVendorProgramList: (
      state,
      action: PayloadAction<Array<{ value: string; label: string; isDefault: boolean }>>,
    ) => {
      state.vendorProgramList = action.payload;
    },
    updateVendorReps: (
      state,
      action: PayloadAction<Array<{ value: string; label: string; isDefault?: boolean }>>,
    ) => {
      state.vendorReps = action.payload;
    },
    updateEmailTemplateList: (
      state,
      action: PayloadAction<
        Array<{
          value: string;
          label: string;
          relatedTo: EmailTemplateRelatedTo;
          language: 'EN' | 'FR';
        }>
      >,
    ) => {
      state.emailTemplateList = action.payload;
    },
    updateVendorSettings: (
      state,
      action: PayloadAction<VendorSettingsType | Record<string, never>>,
    ) => {
      state.vendorSetting = action.payload;
    },
    updateDisabledStatus: (state) => {
      state.isSiteDisabled = true;
    },
  },
});

export const actions = {
  ...slice.actions,
  getNaicsList,
  getEntityList,
  getVendorProgramList,
  getDefaultVendorProgram,
  getAllEmailTemplates,
  getVendorSettings,
  getVendorSettingsPublic,
  getVendorPublicRep,
  getVendorReps,
};

export default slice.reducer;
