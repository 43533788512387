import React from 'react';
import PdfModalBox from '../PdfModal';
import { getBackendUrl } from '../../../utils/getBackendUrl';

export const isPreviewPossible = (fileType?: string) => {
  if (!fileType) {
    return false;
  }
  return ['pdf', 'text', 'img', 'image'].some((type) => fileType.includes(type));
};

export const usePreview = (applicationUuid: string) => {
  const [open, setOpen] = React.useState(false);
  const [uuid, setUuid] = React.useState<null | string>(null);

  const handleClose = () => {
    setOpen(false);
    setUuid(null);
  };

  const handleOpen = (uuid: string) => {
    if (!uuid) {
      return;
    }
    setOpen(true);
    setUuid(uuid);
  };

  const getUrl = () => {
    return `${getBackendUrl()}/application/${applicationUuid}/file/${uuid}`;
  };

  return {
    PreviewModal: function PreviewModel() {
      return <PdfModalBox url={getUrl()} isOpen={open} handleClose={handleClose} />;
    },
    openPreview: handleOpen,
  };
};
