import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  IFloatingFilter,
  IFloatingFilterParams,
} from '@ag-grid-community/core/dist/cjs/filter/floating/floatingFilter';
import { InputAdornment, OutlinedInput } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import useStyles from '../styles';
import { ColDef } from '@ag-grid-community/all-modules';

const SimpleFloatingTextFilter = forwardRef((props: IFloatingFilterParams, ref) => {
  const classes = useStyles();
  const [currentValue, setCurrentValue] = useState<null | string>(null);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          setCurrentValue(null);
        } else {
          setCurrentValue(parentModel.filter);
        }
      },
    } as IFloatingFilter;
  });

  const onInputBoxChanged = (input: React.ChangeEvent<HTMLInputElement>) => {
    if (input.target.value === '') {
      // clear the filter
      props.parentFilterInstance((instance: unknown) => {
        (instance as IFilterComp)?.onFloatingFilterChanged?.(null, null);
      });
      return;
    }

    setCurrentValue(input.target.value);
    props.parentFilterInstance((instance: unknown) => {
      (instance as IFilterComp)?.onFloatingFilterChanged?.('greaterThan', input.target.value);
    });
  };

  const clearFilter = () => {
    onInputBoxChanged({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
  };

  const userProvidedColDef = props?.column?.getUserProvidedColDef?.() as ColDef & {
    ['filter-data-cy']: string;
  };
  const dataCy = `text-filter-${
    userProvidedColDef?.['filter-data-cy'] || props?.column?.getColId?.()
  }`;

  return (
    <OutlinedInput
      data-cy={dataCy}
      value={currentValue ?? ''}
      className={classes.filterInputDefault}
      onChange={onInputBoxChanged}
      inputProps={{
        className: classes.withoutPadding,
      }}
      endAdornment={
        !!currentValue && (
          <InputAdornment position="end">
            <div data-cy="cancel-filter" className={classes.cancelFilter} onClick={clearFilter}>
              <CancelIcon color="disabled" fontSize="inherit" />
            </div>
          </InputAdornment>
        )
      }
    />
  );
});

SimpleFloatingTextFilter.displayName = 'SimpleTextFloatingFilter';

export default SimpleFloatingTextFilter;
