import React from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import {
  getCurrencyByLang,
  getCurrencyByName,
  getPrefixConfigByLang,
} from '../../../utils/currency';

type CurrencyFormatPropsType = {
  value?: string | number;
  currencyType?: string | null;
  dataCy?: string | undefined;
};

function CurrencyFormat(props: CurrencyFormatPropsType) {
  const { value, currencyType, dataCy } = props;
  const userLanguage = useSelector((state: AppStoreType) => state.settings.currentLanguage);
  const userCurrencyConfig = getCurrencyByLang(userLanguage);
  const currencyConfig = getCurrencyByName(currencyType);

  return (
    <NumberFormat
      data-cy={dataCy}
      value={value}
      displayType="text"
      thousandSeparator={userCurrencyConfig?.thousandSeparator}
      decimalSeparator={userCurrencyConfig?.decimalSeparator}
      decimalScale={userCurrencyConfig?.decimalScale}
      isNumericString
      fixedDecimalScale
      {...getPrefixConfigByLang(userLanguage, currencyConfig)}
    />
  );
}

export default CurrencyFormat;
