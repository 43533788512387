import React, { useEffect } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import clsx from 'clsx';
import fileSize from 'filesize';
import { useIntl } from 'react-intl';

import SortingIcon from '../../icons/sorting-icon';
import QuoteAttachmentsFileRow from '../Attachments/QuoteAttachmentsFileRow';
import { AttachTableCell } from '../Attachments/styles';
import { ALLOWED_ATTACHMENTS_SIZE } from '../../../constants/email';
import { setFileType } from '../../../constants/application';

import useStyles from './styles';

type AttachFilesModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleSend: () => void;
  selectedFiles: string[];
  setSelectedFiles: React.Dispatch<string[]>;
  files: FileType[] | [];
};

type FileSizeObject = {
  value: number;
  symbol: string;
  exponent: number;
};

const AttachFilesModal: React.FC<AttachFilesModalProps> = ({
  files,
  isOpen,
  handleClose,
  handleSend,
  selectedFiles,
  setSelectedFiles,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [sorting, setSorting] = React.useState<{ name: string; direction: string }>({
    name: 'file_created_date',
    direction: 'asc',
  });
  const [isWarning, setIsWarning] = React.useState({
    state: false,
    isLimit: false,
  });

  const selectedFilesSize = selectedFiles.reduce((acc, value) => {
    const file = files.find((file) => file.uuid === value);
    if (file) {
      return (acc += +file.size);
    }
    return acc;
  }, 0);

  const filesSizeInMb = fileSize(selectedFilesSize, {
    output: 'object',
    exponent: 2,
  }) as unknown as FileSizeObject;

  const sort = (name: string) => {
    let dir = '';
    if (sorting.direction === 'asc') dir = 'desc';
    if (sorting.direction === '') dir = 'asc';
    if (name !== sorting.name) dir = 'asc';

    setSorting({ name, direction: dir });
  };

  const sortingDirection = (
    arrayForSort: FileType[],
    key: string,
    sorterFunc: (a: string, b: string) => number,
  ) => {
    const { direction } = sorting;

    if (direction == 'asc')
      return arrayForSort.sort((a, b) => {
        const A = a[key as keyof FileType] || '';
        const B = b[key as keyof FileType] || '';
        return sorterFunc(A, B);
      });
    if (direction == 'desc')
      return arrayForSort.sort((a, b) => {
        const A = a[key as keyof FileType] || '';
        const B = b[key as keyof FileType] || '';
        return sorterFunc(B, A);
      });
  };

  const sortFiles = () => {
    const { name } = sorting;
    const arrayForSort = [...files];

    if (name == 'file_name' || name == 'file_type')
      sortingDirection(arrayForSort, 'name', (a, b) => {
        const lowerA = a || '';
        const lowerB = b || '';
        return lowerB.localeCompare(lowerA);
      });
    if (name == 'file_size') sortingDirection(arrayForSort, 'size', (a, b) => (a > b ? 1 : -1));
    return arrayForSort;
  };

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
    if (event.target && event.target.checked && !selectedFiles.includes(uuid))
      return setSelectedFiles([...selectedFiles, uuid]);
    setSelectedFiles(selectedFiles.filter((item) => item !== uuid));
  };

  useEffect(() => {
    setIsWarning({
      state: filesSizeInMb.value > ALLOWED_ATTACHMENTS_SIZE,
      isLimit: filesSizeInMb.value > ALLOWED_ATTACHMENTS_SIZE,
    });
  }, [selectedFiles]);

  return (
    <Dialog
      data-cy="quote-email-attachments-modal"
      open={isOpen}
      onClose={handleClose}
      className={classes.attachmentsModalRoot}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {intl.formatMessage({ id: 'quote.email_attachments' })}
      </DialogTitle>
      <DialogContent
        data-cy="quote-email-attachments-body"
        dividers
        className={classes.contentWrapper}
      >
        <div className={classes.tableTitle}>
          {intl.formatMessage({ id: 'quote.email_attachments_table_title' })}
        </div>
        <TableContainer component={Paper} className={classes.tableWrapper}>
          <Table aria-label="collapsible table" size="small">
            <TableHead data-cy="quote-email-attachments-table-header">
              <TableRow>
                <AttachTableCell padding="checkbox" className={classes.headerCheckbox} />
                <AttachTableCell
                  component="th"
                  scope="row"
                  align="left"
                  className={classes.sortingIcon}
                  onClick={() => sort('file_name')}
                >
                  {intl.formatMessage({ id: 'common.file_name' })}
                  {sorting.name == 'file_name' && <SortingIcon direction={sorting.direction} />}
                </AttachTableCell>
                <AttachTableCell
                  component="th"
                  scope="row"
                  align="left"
                  className={classes.sortingIcon}
                  onClick={() => sort('file_type')}
                >
                  {intl.formatMessage({ id: 'application.file_type' })}
                  {sorting.name == 'file_type' && <SortingIcon direction={sorting.direction} />}
                </AttachTableCell>
                <AttachTableCell
                  component="th"
                  scope="row"
                  align="left"
                  className={clsx(classes.sortingIcon, classes.headerFileSize)}
                  onClick={() => sort('file_size')}
                >
                  {intl.formatMessage({ id: 'application.file_size' })}
                  {sorting.name == 'file_size' && <SortingIcon direction={sorting.direction} />}
                </AttachTableCell>
              </TableRow>
            </TableHead>
            <TableBody data-cy="quote-email-attachments-table-body">
              {sortFiles().map((file) => (
                <QuoteAttachmentsFileRow
                  key={file.uuid}
                  name={file.name}
                  type={setFileType(file.type)}
                  size={fileSize(+file.size)}
                  uuid={file.uuid}
                  isSelected={selectedFiles.includes(file.uuid)}
                  handleCheckboxClick={handleCheckboxClick}
                  className={clsx({ [classes.fileIsSelected]: selectedFiles.includes(file.uuid) })}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={clsx(classes.warningWrapper, { [classes.warningShow]: isWarning.state })}>
          {isWarning.state && (
            <>
              <div className={classes.warningMessageWrapper}>
                <ReportProblemOutlined />
                {intl.formatMessage(
                  { id: 'quote.email_attachments_warning' },
                  { limit: ALLOWED_ATTACHMENTS_SIZE },
                )}
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActionsWrapper}>
        <Button
          data-cy="quote-email-attachments-cancel-button"
          variant="contained"
          onClick={handleClose}
          className={classes.dialogActionsCancel}
          color="default"
        >
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <Button
          data-cy="quote-email-attachments-send-button"
          disabled={isWarning.isLimit}
          color="primary"
          variant="contained"
          onClick={handleSend}
        >
          {intl.formatMessage({ id: 'button.send' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachFilesModal;
