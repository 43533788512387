import React, { ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { CURRENCY_TYPE } from '../../../constants/currency';
import { getCurrencyByLang, getCurrencyByName } from '../../../utils/currency';
import get from 'lodash/get';

type AmountFormatterPropsType = {
  name: ComponentProps<typeof Controller>['name'];
  defaultValue?: ComponentProps<typeof Controller>['defaultValue'];
  value?: string;
  allowZero?: boolean;
  currency: CURRENCY_TYPE;
} & Partial<ComponentProps<typeof NumberFormat>>;

export default function AmountFormatter({
  name,
  onBlur,
  defaultValue,
  allowZero = true,
  currency,
  required,
  ...props
}: AmountFormatterPropsType) {
  const userLanguage = useSelector((state: AppStoreType) => state.settings.currentLanguage);
  const userCurrencyConfig = getCurrencyByLang(userLanguage);
  const currencyConfig = getCurrencyByName(currency);
  const { control, errors } = useFormContext();
  const minValue = allowZero ? undefined : 1;

  return (
    <Controller
      render={({ value, onChange }) => (
        <NumberFormat
          {...props}
          customInput={TextField}
          allowedDecimalSeparators={[',', '.']}
          thousandSeparator={userCurrencyConfig?.thousandSeparator}
          decimalSeparator={userCurrencyConfig?.decimalSeparator}
          decimalScale={userCurrencyConfig?.decimalScale}
          isNumericString
          prefix={`${currencyConfig?.prefix} `}
          value={value}
          name={name}
          onBlur={onBlur}
          required={required}
          error={Boolean(get(errors, name))}
          onValueChange={({ value }) => onChange(value)}
        />
      )}
      name={name}
      control={control}
      rules={{ required, min: minValue }}
      defaultValue={defaultValue ?? ''}
    />
  );
}
