import axios from 'axios';

export const getApi = (backendUrl: string) => {
  const api = axios.create({
    baseURL: backendUrl,
    withCredentials: true,
  });

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response?.status === 401 && location.pathname !== '/sign-in') {
        location.href = '/sign-in'; // reloading page to have clean state and avoid potential bugs
      }

      return Promise.reject(error);
    },
  );

  return api;
};

export default axios.create({
  baseURL: `https://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`,
  headers: {
    'Content-type': 'application/json',
  },
});

export type ErrorDetail = {
  message: string;
  path: Array<string | number>;
  type: string;
  context?: ErrorContext;
};

type ErrorContext = {
  // eslint-disable-next-line
  [key: string]: any;
  key?: string;
  label?: string;
  value?: unknown;
};
