import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  languageTitle: {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 0,
    marginLeft: '10px',
    width: '43px',
    height: '46px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      marginLeft: 0,
      width: '25px',
    },
  },
  languageSwitcher: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    height: '56px',
    borderLeft: '1px solid #ccc',
  },
  radioElement: {
    margin: '0 10px',
  },
}));

export default useStyles;
