import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';

import { UserEditConnectedProps } from './user-edit.container';
import EditUserForm from '../../components/common/edit-user-form';
import Loading from '../../components/common/Loading';
import { updateUser } from '../../store/userOnEdit/actions';
import UserHeaderCard from '../../components/common/user-header-card';
import { changeUserStatus, resetUserPass } from '../../store/userManagement/actions';
import { logInAsVendorUser } from '../../utils/common';

function UserEdit(props: UserEditConnectedProps) {
  const {
    fetchVendorsList,
    fetchUserOnEdit,
    getVendorById,
    userRole,
    userOnEdit,
    vendorName,
    vendorActive,
    isLoading,
    isSidebar,
  } = props;
  const {
    uuid,
    role,
    email,
    firstName,
    lastName,
    active,
    status,
    lastLogin,
    createdAt,
    updatedAt,
    vendorSiteUrl,
    vendorUuid,
  } = userOnEdit;

  const isPortalAdmin = userRole === 'Portal Admin';
  const isBrokerRep = ['Broker Rep', 'Broker Admin'].includes(userRole);
  const isBrokerRepOrPortalAdmin = ['Broker Rep', 'Broker Admin', 'Portal Admin'].includes(
    userRole,
  );
  const isReadOnlyPage = isBrokerRep;
  const dispatch = useDispatch();
  const { userVendorUuid } = useParams<{ userVendorUuid: string }>();
  const formMethods = useForm<UserType>({
    defaultValues: {
      role,
      email: '',
      firstName: '',
      lastName: '',
      phoneAreaCode: '',
      userSiteUrl: '',
      phone: '',
      phoneExt: '',
      mobileAreaCode: '',
      mobilePhone: '',
      siteActive: false,
      defaultLanguage: 'EN',
      replyTo: '',
    },
  });
  const { handleSubmit, getValues, setValue } = formMethods;

  const handleSaveUser = async () => {
    const values = getValues();

    dispatch(
      updateUser(uuid, {
        ...values,
        phoneAreaCode: values.phoneAreaCode || null,
        phone: values.phone || null,
        phoneExt: values.phoneExt || null,
        mobileAreaCode: values.mobileAreaCode || null,
        mobilePhone: values.mobilePhone || null,
        replyTo: values.replyTo || '',
      }),
    );
  };

  const handleLogInAsVendorUser = () => {
    return logInAsVendorUser(uuid);
  };

  const handleChangeUserStatus = () => {
    dispatch(changeUserStatus(userVendorUuid, !active));
  };

  const handleResetUserPassword = () => {
    dispatch(resetUserPass(email, 'vendorRep'));
  };

  useEffect(() => {
    if (isPortalAdmin) {
      fetchVendorsList();
    }
    fetchUserOnEdit(userVendorUuid);
  }, []);

  useEffect(() => {
    if (vendorUuid) {
      getVendorById(vendorUuid);
    }
    Object.entries(userOnEdit).forEach(([key, value]) => setValue(key as keyof UserType, value));
  }, [userOnEdit]);

  return (
    <FormProvider {...formMethods}>
      {isLoading && (
        <Paper>
          <Loading isOpen={isLoading} />
        </Paper>
      )}
      <UserHeaderCard
        firstName={firstName}
        lastName={lastName}
        vendorName={vendorName}
        lastLogin={lastLogin}
        createdAt={createdAt}
        updatedAt={updatedAt as unknown as string}
        handleChangeStatus={handleChangeUserStatus}
        handleResetPass={handleResetUserPassword}
        status={status}
        active={active}
        isSidebar={isSidebar}
        isUserManagement
        isReadOnly={isReadOnlyPage}
        canLogInAs={isBrokerRepOrPortalAdmin}
        handleLogInAs={handleLogInAsVendorUser}
        vendorActive={vendorActive}
      />
      <EditUserForm
        vendorSiteUrl={vendorSiteUrl}
        handleSubmit={handleSubmit}
        handleSaveUser={handleSaveUser}
        isReadOnly={isReadOnlyPage}
        isSidebar={isSidebar}
      />
    </FormProvider>
  );
}

export default UserEdit;
