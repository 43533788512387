import { connect, ConnectedProps } from 'react-redux';
import { actions as quoteListActions } from '../../store/quoteList';
import { actions as formValuesActions } from '../../store/formValues';
import QuoteList from './quote-list.component';
import { actions as instantQuoteActions } from '../../store/instantQuote';

const { getQuoteInfo } = instantQuoteActions;
export const mapStateToProps = (state: AppStoreType) => ({
  vendorUuid: (state.user as UserBasedStoreType).vendorUuid,
  emailTemplateList: state.formValues.emailTemplateList,
});

export const mapDispatchToProps = {
  fetchQuoteList: quoteListActions.fetchQuoteList,
  getAllEmailTemplates: formValuesActions.getAllEmailTemplates,
  sendMassFollowUp: quoteListActions.sendMassFollowUp,
  deleteQuotesList: quoteListActions.deleteQuotesList,
  updateEmailTemplateList: formValuesActions.updateEmailTemplateList,
  getQuoteInfo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type QuoteListConnectedProps = ConnectedProps<typeof connector>;

export default connector(QuoteList);
