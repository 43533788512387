import { GridOptions } from '@ag-grid-community/all-modules';
import { useIntl } from 'react-intl';
import { commonColumnTypes } from './common';
import { EDIT_DRAFT_APPLICATION_PAGE } from '../../../../constants/navigations';
import { getApplicationStatusesMap } from '../../../../constants/language';
import { HeaderComponent } from '../components/HeaderComponent';

// all possible columns in app
// each property must have it's own type

export const useDraftApplicationColumnTypes = () => {
  const intl = useIntl();

  const columnTypes: GridOptions['columnTypes'] = {
    'draftApplication:contractId': {
      ...commonColumnTypes['common:text'],
      field: 'contractId',
      headerName: intl.formatMessage({ id: 'application.contract_id' }),
      minWidth: 150,
    },
    'draftApplication:customer': {
      ...commonColumnTypes['common:text'],
      field: 'companyLegalName',
      headerComponentParams: { name: 'application.customer' },
      headerComponentFramework: HeaderComponent,
      minWidth: 150,
    },
    'draftApplication:idLabelWithLink': {
      ...commonColumnTypes['common:button'],
      field: 'applicationName',
      headerComponentParams: { name: 'application.application_name' },
      headerComponentFramework: HeaderComponent,
      minWidth: 250,
      cellRendererParams: {
        path: EDIT_DRAFT_APPLICATION_PAGE.path,
        pathParams: {
          applicationUuid: 'uuid',
        },
      },
    },
    'draftApplication:status': {
      ...commonColumnTypes['common:status'],
      field: 'statusName',
      headerComponentParams: { name: 'common.status' },
      headerComponentFramework: HeaderComponent,
      minWidth: 240,
      cellRendererParams: {
        statusColor: 'statusColor',
      },
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        statusesMap: getApplicationStatusesMap(intl),
      },
    },
    'draftApplication:equipment': {
      ...commonColumnTypes['common:text'],
      field: 'equipment',
      headerComponentParams: { name: 'application.equipment' },
      headerComponentFramework: HeaderComponent,
      minWidth: 250,
    },
    'draftApplication:equipmentCost': {
      ...commonColumnTypes['common:cost'],
      field: 'totalCost',
      headerComponentParams: { name: 'application.equipment_cost' },
      headerComponentFramework: HeaderComponent,
      minWidth: 150,
      cellRendererParams: {
        currency: 'equipmentCostCurrencyType',
      },
    },
    'draftApplication:customerEmail': {
      ...commonColumnTypes['common:email'],
      field: 'customerEmail',
      minWidth: 200,
      headerComponentParams: { name: 'application.email' },
      headerComponentFramework: HeaderComponent,
    },
    'draftApplication:phoneNumber': {
      ...commonColumnTypes['common:text'],
      field: 'customerPhone',
      minWidth: 200,
      headerComponentParams: { name: 'application.phone_number' },
      headerComponentFramework: HeaderComponent,
    },
    'draftApplication:createdAt': {
      ...commonColumnTypes['common:date'],
      field: 'createdAt',
      minWidth: 200,
      headerComponentParams: { name: 'application.created_on' },
      headerComponentFramework: HeaderComponent,
    },
    'quote:selectAll': {
      ...commonColumnTypes['common:selectAll'],
    },
  };

  return columnTypes;
};
