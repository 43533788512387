import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LoopIcon from '@material-ui/icons/Loop';
import { useStyles } from './styles';
import { actions as fileActions } from '../../../store/file';
import { useVaultDropzone } from '../VaultDropzone';
import { v4 } from 'uuid';
import UploadProgress from '../UploadProgress';

type AmendmentFormPropsType = {
  onClose: () => void;
  onSubmit: (amendment: AmendmentType) => void;
  fileAttachments: FileType[];
  amendment?: AmendmentType;
  isLoading: boolean;
  fileLoading: boolean;
};

function AmendmentForm(props: AmendmentFormPropsType) {
  const { onClose, onSubmit, fileAttachments, amendment, isLoading } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [files, setFiles] = React.useState<FileType[]>(amendment?.files || []);
  const state = useSelector((state: AppStoreType) => state);
  const userUuid = useSelector((state: AppStoreType) => state.user.uuid);
  const fileFor = useSelector(
    (state: AppStoreType) => state.application.application?.application?.idLabel,
  );
  const [stateUploadingFiles, setUploadingFiles] = React.useState<Array<File & { uuid: string }>>(
    [],
  );
  const uploadingFiles = React.useRef<Array<File & { uuid: string }>>([]);

  const formMethods = useForm<AmendmentType>({
    shouldUnregister: false,
    defaultValues: {
      summary: '',
      description: '',
      ...amendment,
    },
  });
  const { register, handleSubmit, errors } = formMethods;

  const onDrop = (acceptedFiles: File[]) => {
    const processingFiles = acceptedFiles.map((f) => {
      const uuid = v4();
      const formData = new FormData();
      formData.append('file', f);
      formData.append('ownedByUuid', userUuid);
      formData.append('fileFor', fileFor!);

      fileActions
        .uploadFiles(formData, {}, (p) => {
          if (p.type !== 'buffer') {
            formMethods.setValue(uuid, p.progress);
          } else {
            formMethods.setValue(uuid + '-buffer', p.progress);
          }
        })(dispatch, () => state)
        .then(() => {
          formMethods.setValue(uuid, undefined);
          uploadingFiles.current = uploadingFiles.current.filter((f) => f.uuid !== uuid);
          setUploadingFiles(uploadingFiles.current);
        });

      return { ...f, uuid };
    });
    setUploadingFiles(processingFiles);
    uploadingFiles.current = processingFiles;
  };
  const { open, getInputProps } = useVaultDropzone({ onDrop });
  React.useEffect(() => {
    return () => {
      dispatch(fileActions.resetFiles());
    };
  }, []);
  React.useEffect(() => {
    const newFiles = fileAttachments
      .filter((file) => files.every((f) => file.uuid !== f.uuid))
      .map((file) => ({ ...file, isNew: true }));
    if (newFiles.length > 0) {
      setFiles([...files, ...newFiles]);
    }
  }, [fileAttachments]);

  const submit = async (amendmentData: AmendmentType) => {
    await onSubmit({
      ...amendment,
      ...amendmentData,
      files,
    });
    onClose();
  };
  const removeFile = (uuid: string) => {
    setFiles(files.filter((file) => file.uuid !== uuid));
    dispatch(fileActions.removeFile(uuid));
  };

  const isNew = !amendment;
  const title = isNew
    ? formatMessage({ id: 'amendment.new_amendment' })
    : formatMessage({ id: 'amendment.edit_amendment' });
  return (
    <FormProvider {...formMethods}>
      <Dialog open onClose={onClose}>
        <DialogTitle data-cy="amendment-title" id="scroll-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <form onSubmit={handleSubmit(submit)}>
            <TextField
              data-cy="amendment-summary-field"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              inputRef={register({ required: true })}
              name="summary"
              label={formatMessage({ id: 'amendment.summary' })}
              id="summary"
              disabled={!isNew}
              error={!!errors.summary}
            />
            <TextField
              data-cy="amendment-description-field"
              variant="outlined"
              margin="normal"
              fullWidth
              inputRef={register}
              name="description"
              label={formatMessage({ id: 'amendment.description' })}
              id="description"
              error={!!errors.description}
              multiline
              rows={3}
            />
            <div className={classes.dropzone}>
              <Typography
                data-cy="amendment-files-title"
                variant="h6"
                className={classes.filesTitle}>
                <FormattedMessage id="amendment.files" />
              </Typography>
              <input {...getInputProps()} />
              <Button
                data-cy="amendment-upload-files-button"
                onClick={open}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CloudUploadIcon />}>
                <FormattedMessage id="button.upload" />
              </Button>
            </div>
            {files.map((file: FileType & { isNew?: boolean }) => (
              <div key={file.uuid} className={classes.fileItem}>
                <Typography className={classes.fileName}>{file.name}</Typography>
                {file.isNew && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => removeFile(file.uuid)}>
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                )}
              </div>
            ))}
            {stateUploadingFiles.map((file) => (
              <UploadProgress key={file.uuid} uuid={file.uuid} />
            ))}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="amendment-submit-button"
            onClick={handleSubmit(submit)}
            variant="contained"
            disabled={isLoading}
            startIcon={isLoading && <LoopIcon className={classes.loadingIcon} />}
            color="primary">
            <FormattedMessage id="button.submit" />
          </Button>
          <Button data-cy="amendment-close-button" onClick={onClose} color="primary">
            <FormattedMessage id="button.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}

export default AmendmentForm;
