import React, { useEffect } from 'react';

import { Typography, Button, Paper, Select, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { UseFormMethods, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import TextInput from '../TextInput';
import {
  ADDRESS_TYPE,
  COUNTRY_TYPE,
  DEFAULT_LANGUAGE,
  getStateOptionsByCode,
} from '../../../constants/formValues';
import VaultMenu from '../VaultMenu';
import AreaCodeFormatter from '../AreaCodeFormatter';
import PhoneFormatter from '../PhoneFormatter';
import ExtensionFormatter from '../ExtensionFormatter';
import { USER_EMAIL_PATTERN } from '../../../constants/regex';

import useStyles from './styles';
import ClipboardButton from '../ClipboardButton';
import BooleanInput from '../BooleanInput';
import LogoSettings from '../LogoSettings';
import clsx from 'clsx';
import ContentCopyIcon from '../../icons/content-copy';

import { GridApi, IDatasource } from '@ag-grid-community/all-modules';
import { AgGridColumn } from '@ag-grid-community/react';
import { getBrokersUsersList } from '../../../apis/broker-rep';
import ServerSideDataGrid from '../VpDataGrid/ServerSideDataGrid';

type EditVendorFormProps = {
  isVendorFormDirty?: boolean;
  displayChangesModal?: string | false;
  setDisplayChangesModal?: (state: string | false) => void;
  handleSubmit?: UseFormMethods['handleSubmit'];
  handleSaveVendor?: (data: VendorCompanyType) => void;
  vendorsList: VendorType[];
  isPoBox: boolean;
  isPortalAdmin: boolean;
  vendorUuid: string;
  currentCompany: string;
  changeLogo?: (
    logo: FormData,
    vendorDocumentsLogo?: boolean,
    companyDocumentsLogo?: boolean,
  ) => Promise<boolean>;
  deleteVendorLogo?: (
    vendorUuid: string,
    vendorDocumentsLogo?: boolean,
    companyDocumentsLogo?: boolean,
  ) => Promise<boolean>;
  vendorDocumentsLogo: boolean;
  companyDocumentsLogo: boolean;
  vendorLogo?: string;
  brokerLogo?: string;
  hideVendorMenu?: boolean;
  siteUrl?: string;
  onVendorChange?: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
};

function EditVendorForm(props: EditVendorFormProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { control, register, errors, reset, watch, setValue, getValues } = useFormContext();

  const [gridApi, setGridApi] = React.useState<GridApi | null>(null);

  const {
    handleSubmit,
    handleSaveVendor,
    vendorsList,
    isPortalAdmin,
    isPoBox,
    vendorUuid,
    currentCompany,
    vendorLogo,
    brokerLogo,
    vendorDocumentsLogo,
    companyDocumentsLogo,
    changeLogo,
    deleteVendorLogo,
    isVendorFormDirty,
    setDisplayChangesModal,
    displayChangesModal,
    hideVendorMenu,
    onVendorChange,
    siteUrl,
  } = props;

  const vendorSiteUrlString = `${document.location.origin}/${siteUrl}/instant-quote`;
  const vendorSiteUrlApplicationString = `${document.location.origin}/${siteUrl}/create-application`;
  const country = watch('country');

  useEffect(() => {
    gridApi?.refreshInfiniteCache();
  }, [vendorUuid]);

  const onSubmit = handleSubmit && handleSaveVendor ? handleSubmit(handleSaveVendor) : undefined;
  const isReadOnly = !onSubmit;

  const dataSource: IDatasource = {
    getRows: async (params) => {
      const queryArray: Array<[string, string | number]> = [
        ['companyName', getValues('companyName')],
      ];
      queryArray.push(['limit', params.endRow - params.startRow]);
      queryArray.push(['offset', params.startRow]);
      queryArray.push(['sortingField', params.sortModel[0]?.colId]);
      queryArray.push(['sortingDirection', params.sortModel[0]?.sort]);

      Reflect.ownKeys(params.filterModel).forEach((field) => {
        const filter = encodeURIComponent(params.filterModel[field]?.filter);
        return queryArray.push([field as string, filter]);
      });

      const queryString = queryArray
        .filter((a) => a[1])
        .map((a) => a.join('='))
        .join('&');

      getBrokersUsersList(queryString)
        .then((res) => {
          const users = res.data;
          params.successCallback(users.rows, users.count);
          setTimeout(() => {
            gridApi?.sizeColumnsToFit();
          });
        })
        .catch(() => {
          params.failCallback();
        });
    },
  };

  return (
    <Grid
      container
      className={clsx({
        [classes.readOnly]: isReadOnly,
      })}
    >
      <Grid item sm={12} md={9}>
        <Paper component="form" aria-disabled onSubmit={onSubmit} className={classes.formWrapper}>
          <Typography data-cy="general-title" variant="h2" gutterBottom className={classes.title}>
            {intl.formatMessage({ id: 'common.general' })}
          </Typography>
          {hideVendorMenu && isPortalAdmin && vendorsList && (
            <Select
              label={intl.formatMessage({ id: 'company.vendor_company' })}
              name="vendorCompany"
              onChange={(value) => onVendorChange && onVendorChange(value)}
              value={currentCompany}
              className={classes.vendorSelect}
            >
              {vendorsList.map((option) => (
                <MenuItem key={option.value?.toString()} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
          {!hideVendorMenu && isPortalAdmin && (
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <VaultMenu
                  required
                  inputRef={register({})}
                  options={vendorsList}
                  className={classes.formControl}
                  label={intl.formatMessage({ id: 'company.vendor_company' })}
                  name="vendorCompany"
                  onChange={(value) => onVendorChange && onVendorChange(value)}
                />
              </Grid>
            </Grid>
          )}
          {isPortalAdmin && !currentCompany ? null : (
            <>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={isPortalAdmin ? 8 : 12}>
                  <TextInput
                    name="companyName"
                    label={intl.formatMessage({ id: 'company.name' })}
                    fullWidth
                    required
                    rules={{
                      required: `${intl.formatMessage({ id: 'company.name_rule' })}`,
                    }}
                  />
                </Grid>
                {isPortalAdmin && (
                  <Grid item xs={12} sm={4}>
                    <BooleanInput
                      label={intl.formatMessage({ id: 'company.vendor_active' })}
                      name="active"
                      size="medium"
                      color="primary"
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    name="email"
                    label={intl.formatMessage({ id: 'common.email' })}
                    fullWidth
                    required
                    rules={{
                      required: `${intl.formatMessage({ id: 'common.input_email' })}`,
                      pattern: USER_EMAIL_PATTERN,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VaultMenu
                    inputRef={register({})}
                    options={DEFAULT_LANGUAGE}
                    disabled={false}
                    className={classes.formControl}
                    label={intl.formatMessage({ id: 'common.default_language' })}
                    name="defaultLanguage"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <AreaCodeFormatter
                    control={control}
                    id="phoneAreaCode"
                    name="phoneAreaCode"
                    label={intl.formatMessage({ id: 'common.area_code' })}
                    fullWidth
                    error={errors.phoneAreaCode}
                    helperText={errors.phoneAreaCode?.message}
                    phoneformatter={{ id: isReadOnly ? '' : 'phone' }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <PhoneFormatter
                    control={control}
                    name="phone"
                    error={errors.phone}
                    helperText={errors.phone?.message}
                    id="phone"
                    label={intl.formatMessage({ id: 'common.phone' })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ExtensionFormatter
                    control={control}
                    id="phoneExt"
                    name="phoneExt"
                    label={intl.formatMessage({ id: 'common.phone_ext' })}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.siteUrl}>
                <Grid item xs={12} sm={12} className={classes.siteurlRow}>
                  <TextInput
                    name="siteUrl"
                    disabled={!isReadOnly}
                    label={`${intl.formatMessage({
                      id: 'common.vendor_site_url_for_quote',
                    })} - ${intl.formatMessage({ id: 'common.english' })}`}
                    fullWidth
                    value={vendorSiteUrlString}
                    className={classes.vendorSite}
                  />
                  {!isReadOnly && (
                    <ClipboardButton
                      data-cy="copy-btn"
                      variant="contained"
                      color="primary"
                      value={vendorSiteUrlString}
                      className={classes.copyButton}
                      startIcon={<ContentCopyIcon />}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} className={classes.siteurlRow}>
                  <TextInput
                    name="siteUrl"
                    disabled={!isReadOnly}
                    label={`${intl.formatMessage({
                      id: 'common.vendor_site_url_for_quote',
                    })} - ${intl.formatMessage({ id: 'common.french' })}`}
                    fullWidth
                    value={`${vendorSiteUrlString}?lang=fr`}
                    className={classes.vendorSite}
                  />
                  {!isReadOnly && (
                    <ClipboardButton
                      data-cy="copy-btn"
                      variant="contained"
                      color="primary"
                      value={`${vendorSiteUrlString}?lang=fr`}
                      className={classes.copyButton}
                      startIcon={<ContentCopyIcon />}
                    />
                  )}
                </Grid>
                <Grid item xs={4} sm={4} className={classes.booleanInputRight}>
                  <BooleanInput
                    data-cy="site-active-checkbox"
                    label={intl.formatMessage({ id: 'common.site_active' })}
                    name="siteActive"
                    size="small"
                    color="primary"
                    labelPosition="after"
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.siteApplicationUrl}>
                <Grid item xs={12} sm={12} className={classes.siteurlRow}>
                  <TextInput
                    name="siteApplicationUrl"
                    disabled={!isReadOnly}
                    label={`${intl.formatMessage({
                      id: 'common.vendor_site_url_for_application',
                    })} - ${intl.formatMessage({ id: 'common.english' })}`}
                    fullWidth
                    value={vendorSiteUrlApplicationString}
                    className={classes.vendorSite}
                  />
                  {!isReadOnly && (
                    <ClipboardButton
                      data-cy="copy-btn"
                      variant="contained"
                      color="primary"
                      value={vendorSiteUrlApplicationString}
                      className={classes.copyButton}
                      startIcon={<ContentCopyIcon />}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} className={classes.siteurlRow}>
                  <TextInput
                    name="siteApplicationUrl"
                    disabled={!isReadOnly}
                    label={`${intl.formatMessage({
                      id: 'common.vendor_site_url_for_application',
                    })} - ${intl.formatMessage({ id: 'common.french' })}`}
                    fullWidth
                    value={`${vendorSiteUrlApplicationString}?lang=fr`}
                    className={classes.vendorSite}
                  />
                  {!isReadOnly && (
                    <ClipboardButton
                      data-cy="copy-btn"
                      variant="contained"
                      color="primary"
                      value={`${vendorSiteUrlApplicationString}?lang=fr`}
                      className={classes.copyButton}
                      startIcon={<ContentCopyIcon />}
                    />
                  )}
                </Grid>
                <Grid item xs={4} sm={4} className={classes.booleanInputRight}>
                  <BooleanInput
                    data-cy="site-application-active-checkbox"
                    label={intl.formatMessage({ id: 'common.site_active' })}
                    name="siteApplicationActive"
                    size="small"
                    color="primary"
                    labelPosition="after"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12}>
                  <TextInput
                    name="notificationsEmail"
                    label={intl.formatMessage({ id: 'common.notification_emails' })}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Typography
                data-cy="address-title"
                variant="h2"
                gutterBottom
                className={clsx(classes.title, classes.marginTop)}
              >
                {intl.formatMessage({ id: 'common.address' })}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <VaultMenu
                    inputRef={register({})}
                    options={ADDRESS_TYPE}
                    disabled={false}
                    className={classes.formControl}
                    label={intl.formatMessage({ id: 'common.address_type' })}
                    name="addressType"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VaultMenu
                    inputRef={register({})}
                    options={COUNTRY_TYPE}
                    disabled={false}
                    className={classes.formControl}
                    label={intl.formatMessage({ id: 'common.country' })}
                    name="country"
                    onChange={(e) => {
                      setValue('country', e.target.value);
                      setValue('province', '');
                    }}
                  />
                </Grid>
              </Grid>
              {isPoBox ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12}>
                    <TextInput
                      name="poBox"
                      label={intl.formatMessage({ id: 'common.po_box' })}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={3}>
                    <TextInput
                      name="streetNumber"
                      label={intl.formatMessage({ id: 'common.street_number' })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      name="streetName"
                      label={intl.formatMessage({ id: 'common.street_name' })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextInput
                      name="unit"
                      label={intl.formatMessage({ id: 'common.unit_number' })}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="city"
                    label={intl.formatMessage({ id: 'common.city' })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <VaultMenu
                    options={getStateOptionsByCode(country, intl.locale.toLowerCase())}
                    className={classes.formControl}
                    label={intl.formatMessage({ id: 'common.province_state' })}
                    name="province"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="postalCode"
                    label={intl.formatMessage({ id: 'common.zip_portal_code' })}
                    fullWidth
                  />
                </Grid>
              </Grid>

              {!isReadOnly && (
                <Grid container justifyContent="center" className={classes.controlButtonsWrap}>
                  <Button
                    data-cy="cancel-btn"
                    onClick={() => reset()}
                    variant="contained"
                    color="default"
                    className={classes.controlButtons}
                  >
                    {intl.formatMessage({ id: 'button.cancel' })}
                  </Button>
                  <Button
                    data-cy="save-btn"
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.controlButtons}
                  >
                    {intl.formatMessage({ id: 'button.save' })}
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Paper>
      </Grid>
      <Grid item sm={12} md={3} className={classes.logoSettingsWrap}>
        <Paper aria-disabled>
          <Grid item sm={12} xs={12}>
            <LogoSettings
              changeVendorLogo={changeLogo}
              deleteVendorLogo={deleteVendorLogo}
              vendorDocumentsLogo={vendorDocumentsLogo}
              companyDocumentsLogo={companyDocumentsLogo}
              vendorUuid={vendorUuid}
              vendorLogo={vendorLogo}
              brokerLogo={brokerLogo}
              isVendorFormDirty={isVendorFormDirty}
              setDisplayChangesModal={setDisplayChangesModal}
              displayChangesModal={displayChangesModal}
            />
          </Grid>
        </Paper>
      </Grid>
      {isPortalAdmin && !isReadOnly && currentCompany.length && (
        <Grid
          item
          sm={12}
          md={12}
          className={clsx({
            [classes.readOnly]: isReadOnly,
          })}
        >
          <Typography variant="h5" noWrap className={classes.secondTitle}>
            {intl.formatMessage({ id: 'common.vendor_reps' })}
          </Typography>
          <div className={`${classes.alpineThemeContainer} ag-theme-alpine`}>
            <ServerSideDataGrid
              onInit={setGridApi}
              api={gridApi}
              datasource={dataSource}
              fitType="fit"
              rowSelection="multiple"
              pagination
              paginationPageSize={10}
              cacheBlockSize={10}
            >
              <AgGridColumn maxWidth={100} suppressSizeToFit type="userDetailsLink" />
              <AgGridColumn floatingFilter sortable type="firstName" />
              <AgGridColumn floatingFilter sortable type="lastName" />
              <AgGridColumn floatingFilter sortable type="vendorRepRole" />
              <AgGridColumn floatingFilter type="formattedPhone" />
              <AgGridColumn floatingFilter sortable type="email" />
              <AgGridColumn type="active" minWidth={150} flex={1} />
              <AgGridColumn
                type="userLoginAsButton"
                flex={1}
                resizable
                cellClass={'ag-custom-text-align-center'}
                headerClass={'ag-custom-text-align-center'}
              />
              <AgGridColumn
                type="userDeleteLink"
                flex={1}
                resizable
                cellClass={'ag-custom-text-align-center'}
                headerClass={'ag-custom-text-align-center'}
              />
            </ServerSideDataGrid>
          </div>
        </Grid>
      )}
    </Grid>
  );
}

export default EditVendorForm;
