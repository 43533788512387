import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import { UseFormMethods } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { DOCUMENT_OVERRIDE_FORM_LANGUAGE_VALUES } from '../../../constants/document';
import VaultMenu from '../VaultMenu';
import { useFormContext } from 'react-hook-form';
import WarningIcon from '@material-ui/icons/Warning';
import { translateLabel } from '../../../utils/translateLabel';

type ApplicationDocumentOverrideFormLanguageFieldPropsType = {
  index: string;
  field: ApplicationDocumentOverridableFields;
  originalValue: string | number | null | boolean;
  value: string | null;
  onChangeField: (
    path: string,
    value: string,
    control?: UseFormMethods['control'],
    name?: string,
    isDateField?: boolean,
  ) => void;
  register: ReturnType<typeof useForm>['register'];
};

export default function ApplicationDocumentOverrideFormLanguageField({
  index,
  field,
  originalValue,
  value,
  onChangeField,
}: ApplicationDocumentOverrideFormLanguageFieldPropsType) {
  const classes = useStyles();
  const intl = useIntl();
  const { setValue } = useFormContext();
  const [isDifferent, setIsDifferent] = useState<boolean>(false);

  useEffect(() => {
    setIsDifferent(checkDifferent(value, originalValue));
    setValue(field.path, value);
  }, []);

  const onChange = useCallback(
    (path: string, newValue: string) => {
      setIsDifferent(checkDifferent(newValue, originalValue));
      onChangeField(path, newValue);
    },
    [onChangeField],
  );

  const checkDifferent = (
    value: string | number | boolean | null,
    originalValue: string | number | boolean | null,
  ) => {
    if (value === '' && originalValue === null) {
      return false;
    }

    return value !== originalValue;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} className={classes.fieldGroup}>
          <VaultMenu
            className={classes.languageSelector}
            id={`${index}`}
            name={field.path}
            label={translateLabel(intl, field.path, field.label)}
            required={field.required}
            value={value ?? undefined}
            options={DOCUMENT_OVERRIDE_FORM_LANGUAGE_VALUES}
            onChange={(e) => {
              setValue(field.path, e.target.value);
              onChange(field.path, e.target.value as string);
            }}
          />
          {isDifferent && (
            <Tooltip
              title={`${intl.formatMessage({
                id: 'application.original_value',
              })}: ${originalValue}`}
              placement="top"
            >
              <WarningIcon className={classes.warningSelectIcon} />
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </>
  );
}
