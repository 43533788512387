import { connect, ConnectedProps } from 'react-redux';

import { actions as vendorSettingsActions } from '../../store/vendorSettings';
import VendorSettings from './vendor-settings.component';

const mapStateToProps = (state: AppStoreType) => ({
  vendorUuid: (state.user as UserBasedStoreType).vendorUuid,
  vendor: state.vendorSettings.vendor,
  brokerLogo: state.contacts.broker?.logo,
  vendorsList: state.vendorSettings.vendorsList,
  userRole: state.user.role,
});

const mapDispatchToProps = {
  fetchVendorsList: vendorSettingsActions.fetchVendorsList,
  getVendorById: vendorSettingsActions.getVendorById,
  changeVendorLogo: vendorSettingsActions.changeVendorLogo,
  deleteVendorLogo: vendorSettingsActions.deleteVendorLogo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VendorSettingsConnectedProps = ConnectedProps<typeof connector>;

export default connector(VendorSettings);
