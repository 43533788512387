import React from 'react';
import VirtualizedMenu from '../VirtualizedMenu';
import { translateLabel } from '../../../utils/translateLabel';
import { useIntl } from 'react-intl';

type EntitySearchPropsType = {
  entities: Entity[];
  label: string;
  getOptionLabel: (entity: Entity) => string;
  selectedUuid: string;
  onChange: (e: React.ChangeEvent<unknown>, entity: Entity | null) => unknown;
  dataCy?: string;
  disabled?: boolean;
};

export default function EntitySearch({
  entities,
  label,
  getOptionLabel,
  onChange,
  selectedUuid,
  disabled,
  dataCy,
}: EntitySearchPropsType) {
  const intl = useIntl();
  return (
    <VirtualizedMenu<Entity>
      freeSolo
      disabled={disabled ?? false}
      onChange={(e, entity) => {
        if (typeof entity === 'string' || !entity) {
          e.preventDefault();
          e.stopPropagation();
          onChange(e, null);
        } else {
          onChange(e, entity as Entity | null);
        }
      }}
      options={entities}
      value={selectedUuid ? entities.find((e) => e.uuid === selectedUuid) ?? null : null}
      label={translateLabel(intl, label as string)}
      dataCy={dataCy}
      getOptionLabel={getOptionLabel}
    />
  );
}
