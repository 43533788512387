import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  searchContainer: {
    position: 'relative',
    zIndex: 1,
    '& span': {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    '& a': {
      color: 'black',
      display: 'flex',
      alignItems: 'center',
      paddingRight: '15px',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',
        height: '56px',
        borderLeft: '1px solid #ccc',
        paddingRight: 0,
      },
    },
  },
  searchMobileLink: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#9c9b9b',
    fontSize: '16px',
    padding: '10px',
    marginRight: '10px',
    zIndex: 1,
    backgroundColor: '#f1efef',
    height: '40px',
    width: '100px',
    borderRadius: '2px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0px',
      width: '30px',
      padding: '10px 0',
      marginRight: '3px',
    },
    '& svg': {
      color: '#9c9b9b',
      [theme.breakpoints.down('xs')]: {
        width: '25px',
      },
    },
  },
  searchInput: {
    padding: '10px',
    zIndex: 1,
    backgroundColor: '#f1efef',
    height: '40px',
    width: '100%',
    borderRadius: '2px',
    [theme.breakpoints.up('lg')]: {
      width: '340px',
    },
    '& svg': {
      color: '#9c9b9b',
    },
  },
  listBox: {
    position: 'absolute',
    top: '-5px',
    left: '-5px',
    width: 'calc(100% + 10px)',
    minWidth: '300px',
    paddingTop: '50px',
    backgroundColor: 'white',
    boxShadow: '0 5px 20px 0 rgb(0 0 0 / 15%)',
    borderRadius: '6px',
  },
  searchListTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingTop: '10px',
    paddingBottom: '8px',
    width: 'calc(100% - 32px)',
    margin: 'auto',
    borderBottom: '1px solid #C4C4C4',
    '& a': {
      fontWeight: 'normal',
      textDecoration: 'underline',
    },
  },
  searchListItem: {
    color: 'black',
    fontSize: '14px',
    paddingLeft: '16px',
    '& span': {
      color: '#828282',
    },
  },
  searchListItemSelected: {
    backgroundColor: '#f1efef',
  },
  textBlock: {
    display: 'flex',
    flexGrow: 1,
  },
  overflowEllipsis: {
    display: 'block',
    textOverflow: 'ellipsis',
  },
  searchListText: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  highlight: {
    '& span': {
      color: 'black',
      paddingLeft: '0',
    },
    '& strong': {
      color: 'black',
      padding: '0',
    },
  },
  searchListTime: {
    width: '15px',
    color: '#DADADA',
    marginRight: '10px',
  },
  searchListClose: {
    width: '13px',
  },
  searchListFooter: {
    display: 'flex',
    paddingTop: '15px',
    paddingLeft: '15px',
    textDecoration: 'underline',
    '& svg': {
      width: '17px',
      color: '#DADADA',
      marginRight: '5px',
    },
  },
  lightText: {
    color: '#828282',
  },
}));
export default useStyles;

export const PopperExpandedInlineStyles = {};
