import React, { useCallback, useEffect, useState, useRef } from 'react';
import { EmailTemplatesConnectedProps } from './email-template.container';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import TextInput from '../../components/common/TextInput';
import VaultMenu from '../../components/common/VaultMenu';
import CustomizedDialog from './confirm-dialog';
import { USER_EMAIL_PATTERN } from '../../constants/regex';

import { Typography, Paper, Select, MenuItem, ListItemText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import { useHistory, useParams } from 'react-router-dom';
import {
  getCustomEmailTemplateRelatedToMap,
  getEmailTemplateRelatedToMap,
} from '../../constants/language';

import { formattedDate } from '../../services/formatter-service';
import { EMAIL_TEMPLATE_PAGE, EMAIL_TEMPLATES_LIST_PAGE } from '../../constants/navigations';
import { generatePath } from 'react-router';
import { LANGUAGES } from '../../constants/formValues';
import ReactRouterPause from '@allpro/react-router-pause';
import { OnLeavePopup } from '../../components/common/on-leave-popup';
import CodeEditor from '../../components/common/CodeEditor';
import EmailPreviewModal from '../../components/common/email-preview-modal';
import StateRenderer from '../../components/common/VpDataGrid/components/StateRenderer';

const RECIPIENTS_LIST = [
  'brokerRep',
  'brokerAdmin',
  'broker',
  'vendor',
  'vendorRep',
  'customer',
  'vendorNotificationEmails',
  'custom',
];
const RECIPIENTS_TYPES_LIST = ['to', 'cc', 'bcc'];

function EmailTemplate({
  vendorsList,
  getEmailTemplate,
  updateEmailTemplate,
  createEmailTemplate,
  deleteEmailTemplate,
  duplicateEmailTemplate,
  emailTemplateEdit,
  fetchVendorsList,
  previewEmailTemplate,
  emailPreviewContent,
}: EmailTemplatesConnectedProps) {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  const emailTemplateUuid = useParams<{ emailTemplateUuid: string }>().emailTemplateUuid;
  const emailTemplateRelatedToMap = getEmailTemplateRelatedToMap(intl);
  const customEmailTemplateRelatedToMap = getCustomEmailTemplateRelatedToMap(intl);

  const [recipientsSelected, setRecipientsSelected] = useState<EmailTemplateListRecipientType[]>(
    [],
  );
  const [previewEmailModal, setPreviewEmailModal] = useState(false);
  const [previewAvailability, setPreviewAvailability] = useState(false);

  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [pageLeaveDialogProps, setPageLeaveDialogProps] = useState<null | {
    save: () => unknown;
    leave: () => unknown;
    stay: () => unknown;
  }>(null);

  const formMethods = useForm<EmailTemplateFormType>({
    defaultValues: {
      name: '',
      relatedTo: '',
      vendorUuid: '',
      language: 'EN',
      type: 'custom',
      description: '',
      subject: '',
      body: '',
      active: false,
      createdAt: '',
      updatedAt: '',
      recipients: [],
    },
    shouldUnregister: false,
  });

  const { handleSubmit, getValues, setValue, reset, register, formState } = formMethods;
  const isFormDirty = useRef(false);
  const isEmailTemplateTypeCustom = getValues().type === 'custom';

  isFormDirty.current = formState.isDirty;

  const handleSubmitEmailTemplate: Parameters<typeof handleSubmit>[0] = useCallback(async () => {
    const values = getValues();

    const templateRequest = {
      active: values.active,
      language: values.language,
      name: values.name,
      vendorUuid: values.vendorUuid,
      subject: values.subject,
      body: values.body,
      description: values.description,
      relatedTo: values.relatedTo,
      type: values.type,
      recipients: recipientsSelected as EmailTemplateListRecipientType[],
    };

    if (!values.body.length) {
      return false;
    }

    if (emailTemplateUuid) {
      await updateEmailTemplate(emailTemplateUuid, templateRequest);
      await getEmailTemplate(emailTemplateUuid);
    } else {
      const createdEmailTemplateUuid = await createEmailTemplate(templateRequest);

      if (createdEmailTemplateUuid) {
        history.push(
          generatePath(EMAIL_TEMPLATE_PAGE.path, { emailTemplateUuid: createdEmailTemplateUuid }),
        );
      }
    }
  }, [getValues, recipientsSelected, history]);

  const handleDelete = async () => {
    if (emailTemplateUuid) {
      const isDeleted = await deleteEmailTemplate(emailTemplateUuid);

      if (isDeleted) {
        history.push(generatePath(EMAIL_TEMPLATES_LIST_PAGE.path));
      }
    }
  };

  const handleDuplicate = async () => {
    if (emailTemplateUuid) {
      const createdEmailTemplateUuid = await duplicateEmailTemplate(emailTemplateUuid);

      if (createdEmailTemplateUuid) {
        history.push(
          generatePath(EMAIL_TEMPLATE_PAGE.path, { emailTemplateUuid: createdEmailTemplateUuid }),
        );
      }
    }
  };

  const isCustomOrNewTemplate = () => {
    if (!emailTemplateUuid || (emailTemplateUuid && emailTemplateEdit.type === 'custom')) {
      return true;
    }

    return false;
  };

  const isCustomAndInactiveTemplate = () => {
    if (emailTemplateUuid && emailTemplateEdit.type === 'custom' && !emailTemplateEdit.active) {
      return true;
    }

    return false;
  };

  function handleNavigationAttempt(navigation: { cancel: () => unknown; resume: () => unknown }) {
    setPageLeaveDialogProps({
      save: async () => {
        await handleSubmit(handleSubmitEmailTemplate)();
        setPageLeaveDialogProps(null);
        if (Object.keys(formState.errors).length !== 0) return;
        navigation.resume();
      },
      stay: () => {
        setPageLeaveDialogProps(null);
        navigation.cancel();
      },
      leave: () => {
        setPageLeaveDialogProps(null);
        navigation.resume();
      },
    });

    return null;
  }

  const beforeUnloadListener = (e: BeforeUnloadEvent) => {
    if (!isFormDirty.current) return;
    e.preventDefault();
    e.returnValue = intl.formatMessage({ id: 'common.unsaved_changes' });
    return e.returnValue;
  };

  const handleClosePreviewEmail = () => {
    setPreviewEmailModal(false);
  };

  const openPreviewEmail = async () => {
    if (emailTemplateEdit) {
      await previewEmailTemplate(emailTemplateEdit.uuid);
    }

    setPreviewEmailModal(true);
  };

  const changeBody = useCallback(
    (value: string | undefined) => {
      setValue('body', value);
      bodyListener();
    },
    [setValue],
  );

  const bodyListener = () => {
    const body = getValues().body;

    if (!emailTemplateUuid || !body || !body.length || body !== emailTemplateEdit.body) {
      setPreviewAvailability(false);
    } else {
      setPreviewAvailability(true);
    }
  };

  const saveHandler = async () => {
    if (isEmailTemplateTypeCustom) return await handleSubmit(handleSubmitEmailTemplate)();
    setShowSaveChanges(true);
  };

  useEffect(() => {
    if (emailTemplateUuid) {
      getEmailTemplate(emailTemplateUuid);
    }

    bodyListener();
  }, [emailTemplateUuid]);

  useEffect(() => {
    if (emailTemplateUuid) {
      Object.entries(emailTemplateEdit).forEach(([key, value]) =>
        setValue(key as keyof EmailTemplateFormType, value),
      );
      if (emailTemplateEdit.recipients) {
        const recipients = emailTemplateEdit.recipients.map(
          (recipient: EmailTemplateListRecipientType) => ({
            type: recipient.type,
            recipientType: recipient.recipientType,
            uuid: recipient.uuid,
            customEmail: recipient.customEmail,
          }),
        );
        setRecipientsSelected(recipients);
      }
    }
    //setting up default form state
    reset(getValues());
    const values = getValues();

    if (!values.recipients.length) {
      setRecipientsSelected([
        {
          type: 'custom',
          recipientType: 'to',
          customEmail: '',
        },
      ]);
    }

    bodyListener();
  }, [emailTemplateEdit]);

  useEffect(() => {
    if (!formState.isDirty && getValues().recipients?.length > recipientsSelected.length)
      setValue('name', `${getValues().name}`, { shouldDirty: true });
  }, [recipientsSelected]);

  useEffect(() => {
    fetchVendorsList();
    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);

  return (
    <div>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        alignItems="center"
        className={classes.headerSpaceBottom}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Typography variant="h1" gutterBottom>
            {emailTemplateUuid
              ? intl.formatMessage({ id: 'email_templates.edit_email_template' })
              : intl.formatMessage({ id: 'email_templates.create_email_template' })}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.buttonsRoot}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            className={classes.spaceLeft}
            onClick={() => history.push(EMAIL_TEMPLATES_LIST_PAGE.path)}
          >
            <FormattedMessage id="email_templates.cancel" />
          </Button>
          {isCustomAndInactiveTemplate() && (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className={classes.spaceLeft}
              onClick={() => setShowDeleteDialog(true)}
            >
              <FormattedMessage id="button.delete" />
            </Button>
          )}
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.spaceLeft}
            onClick={saveHandler}
          >
            <FormattedMessage id="email_templates.save" />
          </Button>
        </Grid>
      </Grid>

      <Grid item sm={12} md={12}>
        <Paper className={classes.paperPadding} variant="outlined">
          <FormProvider {...formMethods}>
            <Grid container>
              <Grid container item spacing={2} xs={12} sm={12} md={6} lg={6}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextInput
                    required
                    name="name"
                    label={intl.formatMessage({ id: 'email_templates.name' })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <VaultMenu
                    required={isCustomOrNewTemplate()}
                    disabled={!isCustomOrNewTemplate()}
                    name="relatedTo"
                    label={intl.formatMessage({ id: 'email_templates.relatedTo' })}
                    options={
                      isCustomOrNewTemplate()
                        ? Object.keys(customEmailTemplateRelatedToMap).map((related: string) => ({
                            value: related,
                            label:
                              customEmailTemplateRelatedToMap[
                                related as keyof typeof customEmailTemplateRelatedToMap
                              ],
                          }))
                        : Object.keys(emailTemplateRelatedToMap).map((related: string) => ({
                            value: related,
                            label:
                              emailTemplateRelatedToMap[
                                related as keyof typeof emailTemplateRelatedToMap
                              ],
                          }))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} />
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <VaultMenu
                    required={isCustomOrNewTemplate()}
                    disabled={!isCustomOrNewTemplate()}
                    options={vendorsList.data}
                    label={intl.formatMessage({ id: 'email_templates.companyName' })}
                    name="vendorUuid"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextInput
                    disabled
                    name="type"
                    label={intl.formatMessage({ id: 'email_templates.type' })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <VaultMenu
                    required={!isCustomOrNewTemplate()}
                    disabled={!isCustomOrNewTemplate()}
                    options={LANGUAGES}
                    label={intl.formatMessage({ id: 'common.language' })}
                    name="language"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <StateRenderer value={getValues().active} />
                </Grid>
                {emailTemplateUuid && (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextInput
                        disabled
                        name="createdAt"
                        label={intl.formatMessage({ id: 'email_templates.createdAt' })}
                        fullWidth
                        value={formattedDate(new Date(emailTemplateEdit.createdAt), 'yyyy-MM-dd')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextInput
                        disabled
                        name="updatedAt"
                        label={intl.formatMessage({ id: 'email_templates.updatedAt' })}
                        fullWidth
                        value={formattedDate(new Date(emailTemplateEdit.updatedAt), 'yyyy-MM-dd')}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextInput
                    required
                    name="subject"
                    label={intl.formatMessage({ id: 'email_templates.subject' })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextInput
                    name="description"
                    label={intl.formatMessage({ id: 'email_templates.description' })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} container>
                  <div className={classes.title}>
                    <small>{intl.formatMessage({ id: 'email_templates.recipients' })}</small>
                    <small>*</small>
                  </div>
                  {recipientsSelected.map((recipient: EmailTemplateListRecipientType, index) => {
                    const typeFieldName = `recipients[${index}].type`;
                    const recipientTypeFieldName = `recipients[${index}].recipientType`;
                    const customFieldName = `recipients[${index}].customEmail`;

                    return (
                      <div
                        key={'recipient_' + index}
                        className={classes.recipientList}
                        {...register(`recipients.${index}`)}
                      >
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Select
                            name={typeFieldName}
                            labelId="recipients-label"
                            id="demo-multiple-checkbox"
                            // multiple
                            value={recipient.type}
                            disabled
                            onChange={(event) => {
                              const newArr: EmailTemplateListRecipientType[] = [
                                ...recipientsSelected,
                              ];
                              const recipientClone = { ...recipient };
                              recipientClone.type = event.target
                                .value as EmailTemplateListRecipientTypeType;

                              if (recipientClone.type !== 'custom') {
                                recipientClone.customEmail = undefined;
                              }

                              newArr[index] = recipientClone;

                              setRecipientsSelected(newArr);
                              setValue('recipients', newArr, { shouldDirty: true });
                              setValue('name', `${getValues().name}`, { shouldDirty: true });
                            }}
                            fullWidth
                            className={classes.recipientsSelect}
                          >
                            {RECIPIENTS_LIST.map((rec) => (
                              <MenuItem key={rec} value={rec}>
                                <ListItemText primary={rec} />
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <Select
                            name={recipientTypeFieldName}
                            labelId="recipients-type-label"
                            id="select-rec-type"
                            value={recipient.recipientType}
                            onChange={(event) => {
                              const newArr: EmailTemplateListRecipientType[] = [
                                ...recipientsSelected,
                              ];
                              const recipientClone = { ...recipient };
                              recipientClone.recipientType = event.target
                                .value as EmailTemplateListRecipientType['recipientType'];
                              newArr[index] = recipientClone;

                              setRecipientsSelected(newArr);
                              setValue('recipients', newArr, { shouldDirty: true });
                              setValue('name', `${getValues().name}`, { shouldDirty: true });
                            }}
                            fullWidth
                            className={classes.recipientsSelect}
                            disabled
                          >
                            {RECIPIENTS_TYPES_LIST.map((rec) => (
                              <MenuItem key={rec} value={rec}>
                                <ListItemText primary={rec} />
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                          {recipient.type === 'custom' && (
                            <TextInput
                              required
                              name={customFieldName}
                              label={intl.formatMessage({ id: 'email_templates.email' })}
                              value={recipient.customEmail || ''}
                              fullWidth
                              rules={{
                                pattern: {
                                  value: USER_EMAIL_PATTERN,
                                  message: intl.formatMessage({ id: 'common.email_rule' }),
                                },
                              }}
                              onChange={(event) => {
                                const newArr: EmailTemplateListRecipientType[] = [
                                  ...recipientsSelected,
                                ];
                                const recipientClone = { ...recipient };
                                recipientClone['customEmail'] = event.target
                                  .value as EmailTemplateListRecipientType['recipientType'];
                                newArr[index] = recipientClone;

                                setRecipientsSelected(newArr);
                                setValue('recipients', newArr, { shouldDirty: true });
                              }}
                            />
                          )}
                        </Grid>
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CodeEditor
                  label={intl.formatMessage({ id: 'email_templates.body' })}
                  language="handlebars"
                  value={emailTemplateUuid ? emailTemplateEdit.body : null}
                  required
                  onChange={changeBody}
                />
                <Button
                  data-cy="preview-email-button"
                  variant="contained"
                  color="primary"
                  className={classes.previewButton}
                  onClick={openPreviewEmail}
                  disabled={!previewAvailability}
                >
                  {intl.formatMessage({ id: 'quote.preview_email' })}
                </Button>
                {!previewAvailability && (
                  <span className={classes.previewMessage}>
                    {intl.formatMessage({ id: 'email_templates.preview_message' })}
                  </span>
                )}
              </Grid>
            </Grid>
          </FormProvider>
        </Paper>
      </Grid>
      {previewEmailModal && emailPreviewContent && (
        <EmailPreviewModal
          header={emailPreviewContent.subject}
          body={emailPreviewContent.body}
          isOpen
          isFollowUp={false}
          handleClose={handleClosePreviewEmail}
          isBodyEditable
        />
      )}
      <CustomizedDialog
        open={showSaveChanges}
        handleClose={() => setShowSaveChanges(false)}
        handleOk={handleSubmit(handleSubmitEmailTemplate)}
        textClose={intl.formatMessage({ id: 'email_templates.cancel' })}
        textOk={intl.formatMessage({ id: 'email_templates.save' })}
        textTitle={intl.formatMessage({ id: 'email_templates.save_changes' })}
        textBody={intl.formatMessage({ id: 'email_templates.save_changes_text' })}
      />
      <CustomizedDialog
        open={showDeleteDialog}
        handleClose={() => setShowDeleteDialog(false)}
        handleOk={handleDelete}
        textClose={intl.formatMessage({ id: 'email_templates.cancel' })}
        textOk={intl.formatMessage({ id: 'button.delete' })}
        textTitle={intl.formatMessage({ id: 'email_templates.delete_email_template' })}
        textBody={intl.formatMessage({
          id: 'email_templates.are_you_sure_to_delete_email_template',
        })}
      />
      <CustomizedDialog
        open={showDuplicateDialog}
        handleClose={() => setShowDuplicateDialog(false)}
        handleOk={handleDuplicate}
        textClose={intl.formatMessage({ id: 'email_templates.cancel' })}
        textOk={intl.formatMessage({ id: 'email_templates.duplicate' })}
        textTitle={intl.formatMessage({ id: 'email_templates.duplicate_email_template' })}
        textBody={intl.formatMessage({
          id: 'email_templates.are_you_sure_to_duplicate_email_template',
        })}
      />
      {emailTemplateUuid && (
        <>
          <ReactRouterPause handler={handleNavigationAttempt} when={isFormDirty.current} />
          <OnLeavePopup
            open={!!pageLeaveDialogProps}
            leave={pageLeaveDialogProps?.leave}
            cancel={pageLeaveDialogProps?.stay}
            saveAndLeave={pageLeaveDialogProps?.save}
            message={
              <>
                <p>
                  <FormattedMessage id="common.unsaved_changes_continue" />
                </p>
                {!isEmailTemplateTypeCustom && (
                  <p>
                    <FormattedMessage id="email_templates.save_changes_text" />
                  </p>
                )}
              </>
            }
          />
        </>
      )}
    </div>
  );
}

export default EmailTemplate;
