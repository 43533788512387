export const AVAILABLE_STATUSES_FOR_GENERATE_DOCUMENT: ApplicationStatusName[] = [
  'applicationApproved',
  'applicationDraftingContract',
  'applicationDocumentsSentOut',
  'applicationDocsBackIncomplete',
  'applicationPOIssued',
  'applicationSubmitForFunding',
  'applicationFundingDeficiency',
  'applicationFundingSecured',
];

export const fileTypeMap = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'document/docx',
};

export const setFileType = (typeName: string) =>
  fileTypeMap[typeName as keyof typeof fileTypeMap] || typeName;

export const REPRESENTATIVE_TYPES = [
  { value: 'brokerRep', label: 'common.broker_representative' },
  { value: 'vendorRep', label: 'common.vendor_representative' },
];

export const FIRST_LOAD = 'first_load';
