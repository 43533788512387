import { generatePath } from 'react-router-dom';
import {
  INSTANT_QUOTE_PAGE,
  APPLICATION_PAGE,
  EDIT_DRAFT_APPLICATION_PAGE,
  USER_EDIT_PAGE,
} from '../constants/navigations';
import { Sections } from '../constants/search';

export function linkToRecord(section: string, uuid: string) {
  switch (section) {
    case Sections.quotes:
      return generatePath(INSTANT_QUOTE_PAGE.path, { id: uuid });
    case Sections.submittedApplications:
      return generatePath(APPLICATION_PAGE.path, { id: uuid });
    case Sections.draftApplications:
      return generatePath(EDIT_DRAFT_APPLICATION_PAGE.path, {
        applicationUuid: uuid,
      });
    case Sections.users:
      return generatePath(USER_EDIT_PAGE.path, { userVendorUuid: uuid });
    default:
      '';
  }
}
