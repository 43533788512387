import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SendIcon from '@material-ui/icons/Send';
import PdfModalBox from '../PdfModal';
import { useForm, FormProvider } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ApplicationDocumentEmail, {
  ApplicationDocumentEmailType,
} from '../ApplicationDocumentEmail';
import { v4 as uuidv4 } from 'uuid';
import { QuoteEmailType, RECIPIENT_TYPE } from '../QuoteEmail';
import { DOCUMENT_OVERRIDE_TYPES, DOCUMENT_SIGNATURE_STATUSES } from '../../../constants/document';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import { CancelOutlined } from '@material-ui/icons';

type ApplicationDocumentContentPropsType = {
  openOverride: (type: string) => void;
  changeSignerAttachments: (id: string, attachments: ApplicationDocumentSignerAttachment[]) => void;
  signerAttachments: Array<ApplicationDocumentSignerAttachment>;
  sendEmailParams: (data: ApplicationDocumentEmailFormData) => void;
  setOpenDeleteModal: (status: boolean) => void;
  cancelSignDocument: () => void;
  docSignAvailability: boolean;
  document: ApplicationDocumentType | null;
  documentFileName: string | null;
  documentSigned: boolean;
  previewUrl: string;
  downloadUrl: string | null;
};

export default function ApplicationDocumentContent(props: ApplicationDocumentContentPropsType) {
  const {
    document,
    documentFileName,
    documentSigned,
    previewUrl,
    downloadUrl,
    sendEmailParams,
    cancelSignDocument,
    setOpenDeleteModal,
    openOverride,
    docSignAvailability,
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);

  const [emailList, setEmailList] = useState<ApplicationDocumentEmailType[]>([]);
  const [emailSubject, setEmailSubject] = useState<string>('');
  const [emailBody, setEmailBody] = useState<string>('');
  const [mainButtonsDisabled, setMainButtonsDisabled] = useState<boolean>(true);
  const [sendEmailButtonsDisabled, setSendEmailButtonsDisabled] = useState<boolean>(true);
  const [signButtonDisabled, setSignButtonDisabled] = useState<boolean>(true);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(true);
  const [cancelButtonVisibility, setCancelButtonVisibility] = useState<boolean>(false);
  const [signButtonTitle, setSignButtonTitle] = useState<string>('');

  const formMethods = useForm<ApplicationDocumentValueTypes>();
  const { control, handleSubmit } = formMethods;

  React.useEffect(() => {
    setButtonStates();
  }, [document, documentSigned, documentFileName, downloadUrl]);

  const setButtonStates = () => {
    if (document && documentFileName) {
      const signButtonStatus =
        docSignAvailability &&
        (document.status === DOCUMENT_SIGNATURE_STATUSES.PENDING ||
          document.status === DOCUMENT_SIGNATURE_STATUSES.DECLINED) &&
        !document.providerId;
      const cancelButtonStatus =
        docSignAvailability &&
        document.status === DOCUMENT_SIGNATURE_STATUSES.PENDING &&
        document.providerId;
      const sendEmailButtonStatus =
        !!document.providerId && document.status === DOCUMENT_SIGNATURE_STATUSES.PENDING;
      const deleteButtonStatus =
        document.status === DOCUMENT_SIGNATURE_STATUSES.PENDING &&
        !document.providerId &&
        !document.emailSent;

      if (document.status === DOCUMENT_SIGNATURE_STATUSES.DECLINED) {
        setSignButtonTitle(intl.formatMessage({ id: 'application.try_e_sign_again' }));
      } else {
        setSignButtonTitle(intl.formatMessage({ id: 'application.start_e_sign' }));
      }

      setMainButtonsDisabled(false);
      setSignButtonDisabled(!signButtonStatus);
      setCancelButtonVisibility(!!cancelButtonStatus);
      setSendEmailButtonsDisabled(sendEmailButtonStatus);
      setDeleteButtonDisabled(!deleteButtonStatus);
    } else {
      setSignButtonDisabled(true);
      setMainButtonsDisabled(true);
      setDeleteButtonDisabled(true);
      setSendEmailButtonsDisabled(true);
      setCancelButtonVisibility(false);
      setSignButtonTitle(intl.formatMessage({ id: 'application.start_e_sign' }));
    }
  };

  const openPreview = () => {
    setOpenPreviewModal(true);
  };

  const closePreviewModal = () => {
    setOpenPreviewModal(false);
  };

  const openEmail = () => {
    defineRecipientsList();
    setOpenEmailModal(true);
  };

  const closeEmailModal = () => {
    setEmailBody('');
    setEmailSubject('');
    setEmailSubject('');
    setOpenEmailModal(false);
  };

  const downloadFile = () => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    }
  };

  const onAddRecipient = () => {
    setEmailList([
      ...emailList,
      {
        uuid: uuidv4(),
        email: '',
        type: RECIPIENT_TYPE.CC,
        isRemovable: true,
      },
    ]);
  };

  const defineRecipientsList = () => {
    setEmailList([
      {
        uuid: uuidv4(),
        email: '',
        type: RECIPIENT_TYPE.TO,
        isRemovable: true,
      },
    ]);
  };

  const onDeleteRecipient = (email: typeof emailList[number]) => {
    setEmailList(emailList.filter((e) => e.uuid !== email.uuid));
  };

  const onEmailPropsChange = (id: string, changes: Partial<QuoteEmailType>) => {
    const updatedList = emailList.map((e) => {
      if (e.uuid === id) {
        return { ...e, ...changes };
      }

      return e;
    });

    return setEmailList(updatedList);
  };

  const sendEmailForm = () => {
    const to = [];
    const bcc = [];
    const cc = [];

    for (const recipient of emailList) {
      if (recipient.type === RECIPIENT_TYPE.TO) {
        to.push(recipient.email);
      } else if (recipient.type === RECIPIENT_TYPE.BCC) {
        bcc.push(recipient.email);
      } else {
        cc.push(recipient.email);
      }
    }

    sendEmailParams({
      to: to,
      bcc: bcc,
      cc: cc,
      subject: emailSubject,
      body: emailBody,
    });

    closeEmailModal();
  };

  return (
    <FormProvider {...formMethods}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {cancelButtonVisibility && (
            <Button
              variant="contained"
              color="default"
              className={clsx(classes.documentButton, classes.firstButton)}
              startIcon={<CancelOutlined />}
              onClick={cancelSignDocument}
            >
              {intl.formatMessage({ id: 'application.cancel_esign' })}
            </Button>
          )}
          {!cancelButtonVisibility && (
            <Button
              data-cy="start-esign"
              variant="contained"
              color="default"
              className={clsx(classes.documentButton, classes.firstButton)}
              startIcon={<PlayCircleFilledIcon />}
              onClick={() => openOverride(DOCUMENT_OVERRIDE_TYPES.SIGNATURE)}
              disabled={signButtonDisabled}
            >
              {signButtonTitle}
            </Button>
          )}
          <Button
            data-cy="doc-preview"
            variant="contained"
            color="default"
            className={classes.documentButton}
            startIcon={<VisibilityIcon />}
            onClick={openPreview}
            disabled={mainButtonsDisabled}
          >
            {intl.formatMessage({ id: 'button.preview' })}
          </Button>
          <Button
            data-cy="doc-download"
            variant="contained"
            color="default"
            className={classes.documentButton}
            startIcon={<CloudDownloadIcon />}
            onClick={downloadFile}
            disabled={mainButtonsDisabled}
          >
            {intl.formatMessage({ id: 'button.download' })}
          </Button>
          <Button
            data-cy="send-by-email"
            variant="contained"
            color="default"
            className={classes.documentButton}
            startIcon={<SendIcon />}
            onClick={openEmail}
            disabled={sendEmailButtonsDisabled}
          >
            {intl.formatMessage({ id: 'button.send_by_email' })}
          </Button>
          <Button
            data-cy="doc-delete"
            variant="contained"
            color="default"
            className={classes.documentButton}
            startIcon={<DeleteIcon />}
            onClick={() => setOpenDeleteModal(true)}
            disabled={deleteButtonDisabled}
          >
            {intl.formatMessage({ id: 'button.delete' })}
          </Button>
        </Grid>
      </Grid>

      <PdfModalBox url={previewUrl} isOpen={openPreviewModal} handleClose={closePreviewModal} />

      {document && documentFileName && (
        <Dialog open={openEmailModal} onClose={closeEmailModal}>
          <DialogTitle className={classes.dialogTitle}>
            {intl.formatMessage({ id: 'button.send_by_email' })}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <form onSubmit={handleSubmit(sendEmailForm)}>
              <ApplicationDocumentEmail
                emailList={emailList}
                onAddRecipient={onAddRecipient}
                onDeleteRecipient={onDeleteRecipient}
                onEmailPropsChange={onEmailPropsChange}
                emailSubject={emailSubject}
                onSubjectPropChange={setEmailSubject}
                closeEmailModal={closeEmailModal}
                emailBody={emailBody}
                onBodyPropChange={setEmailBody}
                openPreview={openPreview}
                documentFileName={documentFileName}
                control={control}
              />
            </form>
          </DialogContent>
        </Dialog>
      )}
    </FormProvider>
  );
}
