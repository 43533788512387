import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ContentCopyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7502 0.833252H0.0834961V14.1666H1.75016V2.49992H11.7502V0.833252ZM15.9168 4.16658H3.41683V19.1666H15.9168V4.16658ZM5.0835 17.4999H14.2502V5.83325H5.0835V17.4999Z"
        fill="#4153AF"
      />
    </SvgIcon>
  );
}
