import React from 'react';
import { Select, FormControl, InputLabel, MenuItem, Grid } from '@material-ui/core';
import AmountFormatter from '../AmountFormatter';
import { useIntl } from 'react-intl';
import { CURRENCY_TYPE, defaultCurrency } from '../../../constants/currency';
import { translateLabel } from '../../../utils/translateLabel';

export type AmountTextFieldPropType = {
  name: string;
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onBlur?: () => void;
  error?: string;
  helperText?: string;
  setCurrency: (c: CURRENCY_TYPE) => void;
  allowZero?: boolean;
  currency?: CURRENCY_TYPE;
  required?: boolean;
  defaultValue?: number;
  fixedDecimalScale?: boolean;
};

export default function AmountTextField(props: AmountTextFieldPropType) {
  const {
    name,
    label,
    disabled,
    onBlur,
    error,
    helperText,
    fullWidth,
    setCurrency,
    allowZero = true,
    required,
    currency,
    defaultValue,
    fixedDecimalScale,
  } = props;
  const intl = useIntl();

  const labelId = `${name}-label`;
  const selectedCurrency = currency || (defaultCurrency.name as CURRENCY_TYPE);

  const CurrencyMenu = () => (
    <FormControl style={{ width: '100%' }}>
      <InputLabel id={labelId} />
      <Select
        labelId={labelId}
        label={translateLabel(intl, label as string)}
        id="demo-simple-select-placeholder-label"
        onChange={(e) => setCurrency(e.target.value as CURRENCY_TYPE)}
        value={selectedCurrency}
        disabled={disabled}
        required={required}
        style={{ width: '100%' }}
      >
        <MenuItem value="CAD">{intl.formatMessage({ id: 'currency.cad' })}</MenuItem>
        <MenuItem value="USD">{intl.formatMessage({ id: 'currency.usd' })}</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={6} sm={8}>
          <AmountFormatter
            label={translateLabel(intl, label as string)}
            name={name}
            onBlur={onBlur}
            disabled={disabled}
            error={error}
            helperText={helperText}
            fullWidth={fullWidth}
            required={required}
            allowNegative={false}
            allowZero={allowZero}
            currency={selectedCurrency}
            defaultValue={defaultValue}
            fixedDecimalScale={fixedDecimalScale}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <CurrencyMenu />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
