import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    width: '100%',
    minWidth: 200,
    height: 230,
    overflow: 'auto',
  },
  listItem: {
    padding: '0px',
    paddingRight: '5px',
  },
  listIcon: {
    minWidth: '36px',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a: GridViewColumnType[], b: GridViewColumnType[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: GridViewColumnType[], b: GridViewColumnType[]) {
  return a.filter((value: GridViewColumnType) => b.indexOf(value) !== -1);
}

type TransferListPropsType = {
  columns: GridViewColumnType[];
};

export default function TransferList(props: TransferListPropsType) {
  const { columns } = props;

  const classes = useStyles();
  const intl = useIntl();
  const [checked, setChecked] = React.useState<GridViewColumnType[]>([]);
  const [left, setLeft] = React.useState<GridViewColumnType[]>([...columns]);
  const [right, setRight] = React.useState<GridViewColumnType[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: GridViewColumnType) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: GridViewColumnType[]) => (
    <Paper variant="outlined" className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((item) => {
          const labelId = `transfer-list-item-${item.field}-label`;

          return (
            <ListItem
              key={item.field}
              role="listitem"
              button
              onClick={handleToggle(item)}
              className={classes.listItem}
            >
              <ListItemIcon className={classes.listIcon}>
                <Checkbox
                  checked={checked.indexOf(item) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.label} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={5}>
        {customList(left)}
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label={intl.formatMessage({ id: 'grid.move_all_right' })}
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label={intl.formatMessage({ id: 'grid.move_selected_right' })}
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label={intl.formatMessage({ id: 'grid.move_selected_left' })}
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label={intl.formatMessage({ id: 'grid.move_all_left' })}
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {customList(right)}
      </Grid>
    </Grid>
  );
}
