import React from 'react';
import { Grid, Grow } from '@material-ui/core';
import { ApplicationEntitySelect, useApplicationInputs } from './inputs';
import { useIntl } from 'react-intl';
import Alert from '@material-ui/lab/Alert';

type CorporationFormType = {
  path: string;
  minCoLesseeWarning: string | null;
};

export default function CorporationForm({ path, minCoLesseeWarning }: CorporationFormType) {
  const intl = useIntl();
  const {
    CompanyLegalNameInput,
    CompanyOperatingNameInput,
    NaicsInput,
    CompanyWebsiteInput,
    CompanyEmailInput,
    InBusinessSinceInput,
  } = useApplicationInputs(path);

  return (
    <Grow in>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ApplicationEntitySelect
            path={path}
            type="corporation"
            label={intl.formatMessage({ id: 'company.search_business' })}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CompanyLegalNameInput required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyOperatingNameInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NaicsInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyEmailInput />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CompanyWebsiteInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InBusinessSinceInput />
          {minCoLesseeWarning && <Alert severity="warning">{minCoLesseeWarning}</Alert>}
        </Grid>
      </Grid>
    </Grow>
  );
}
