import React from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import * as Navigations from '../../../constants/navigations';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  icon: {
    fontSize: 100,
  },
  button: {
    margin: theme.spacing(3),
  },
}));

export default function ApplicaitonError() {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Paper className={classes.root}>
      <AssignmentLateIcon color="primary" className={classes.icon} />
      <Typography variant="h5">{intl.formatMessage({ id: 'application.oops' })}</Typography>
      <Typography variant="h6">
        {intl.formatMessage({ id: 'application.nothing_found' })}
      </Typography>
      <Button
        className={classes.button}
        component={Link}
        to={Navigations.INSTANT_NEW_QUOTE_PAGE.path}
        variant="contained"
        color="primary">
        {intl.formatMessage({ id: 'application.back_home' })}
      </Button>
    </Paper>
  );
}
