import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(0),
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  },
  layout: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  fieldItem: {
    marginBottom: theme.spacing(2),
  },
  actionsBlock: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textRight: {
    textAlign: 'right',
  },
  actionBtn: {
    marginRight: theme.spacing(2),
  },
  label: {
    fontSize: '16px',
  },
}));

export default useStyles;
