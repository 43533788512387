import React from 'react';
import { MyProfileConnectedProps } from './my-broker-rep-profile.container';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useIntl } from 'react-intl';
import useStyles from './styles';

function MyBrokerRepProfile({ user, resetUserPass }: MyProfileConnectedProps) {
  const intl = useIntl();
  const classes = useStyles();

  const handleResetUserPassword = () => {
    resetUserPass(user.email, 'brokerRep');
  };

  if (user.type !== 'brokerRep') {
    return null;
  }

  const { firstName, lastName } = user;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent className={classes.cardContentWrapper}>
            <Grid container className={classes.headerWrapper} direction="row">
              <Grid item xs={12} md={4} lg={6} className={classes.userStatusWrapper}>
                <Typography
                  component="h6"
                  variant="h6"
                  data-cy="title"
                  className={classes.userNameWrap}
                >
                  {`${firstName} ${lastName}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={6} className={classes.controlButtonsWrap}>
                <Button
                  data-cy="reset-password-btn"
                  size="medium"
                  variant="contained"
                  color="default"
                  onClick={handleResetUserPassword}
                  className={classes.resetPassButton}
                >
                  {intl.formatMessage({ id: 'user.reset_password' })}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default MyBrokerRepProfile;
