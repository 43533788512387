export const localStorageService = {
  get: function <T>(key: string): T | null {
    try {
      const json = localStorage.getItem(key);
      return JSON.parse(json as string);
    } catch {
      return null;
    }
  },
  set: (key: string, data: unknown): boolean => {
    try {
      const json = JSON.stringify(data);
      localStorage.setItem(key, json);

      return true;
    } catch {
      return false;
    }
  },
  upsert: (key: string, data: unknown): boolean => {
    try {
      const json = localStorage.getItem(key);
      const existingData = json ? JSON.parse(json) : null;
      if (existingData) {
        localStorage.setItem(
          key,
          JSON.stringify(
            Array.isArray(data)
              ? [...existingData, ...data]
              : { ...existingData, ...(data as Record<string, unknown>) },
          ),
        );
      } else {
        localStorage.setItem(key, JSON.stringify(data));
      }

      return true;
    } catch {
      return false;
    }
  },
};

const LS_APPLICATION_ACCESS_TOKEN_KEY = 'applicationAccessTokens';

export const lsSetApplicationAccessToken = (applicationUuid: string, token: string) => {
  localStorageService.upsert(LS_APPLICATION_ACCESS_TOKEN_KEY, { [applicationUuid]: token });
};

export const lsGetApplicationAccessToken = (applicationUuid: string): string | null => {
  const tokenMap = localStorageService.get<Record<string, string>>(LS_APPLICATION_ACCESS_TOKEN_KEY);

  return tokenMap ? tokenMap[applicationUuid] : null;
};
