import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

type BasicTablePropsType = {
  rows: GridViewFilterListType;
  columns: GridViewColumnType[];
};

export default function BasicTable(props: BasicTablePropsType) {
  const { rows, columns } = props;

  const classes = useStyles();
  const intl = useIntl();
  const keys = Object.keys(rows);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label={intl.formatMessage({ id: 'grid.simple_table' })}>
        <TableHead>
          <TableRow>
            <TableCell>{intl.formatMessage({ id: 'grid.column_name' })}</TableCell>
            <TableCell align="center"> {intl.formatMessage({ id: 'grid.filter_type' })}</TableCell>
            <TableCell align="center"> {intl.formatMessage({ id: 'grid.filter_text' })}</TableCell>
            <TableCell align="center">{intl.formatMessage({ id: 'button.delete' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="rows[key]">
                {columns.find((x) => x.field === key)?.label}
              </TableCell>
              <TableCell align="center">{rows[key]}</TableCell>
              <TableCell align="center">{rows[key]}</TableCell>
              <TableCell>
                <DeleteIcon fontSize="small" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
