import { AxiosResponse } from 'axios';
import { getBackendUrl } from '../utils/getBackendUrl';
import { buildAGPaginationFilterQuery } from '../utils/common';
import { getApi } from './httpCommon';
import { IoProgress, ProgressSubscribeParams, subscribe } from '../services/websockets';
import { v4 } from 'uuid';
import { formatBufferProgress } from '../utils/common';
import { IGetRowsParams } from '@ag-grid-community/all-modules';

const BACKEND = getBackendUrl() + '/quote';
const api = getApi(BACKEND);

export const createInstantQuote = async (customerInfo: CustomerInfo) => {
  return await api.post<unknown, AxiosResponse<{ uuid: string }>>('/', { customerInfo });
};

export const createInstantQuotePublic = async (
  quoteData: PublicGenerateQuoteRequest,
  vendorSiteUrl: string,
  userSiteUrl?: string,
) => {
  return await api.post<unknown, AxiosResponse<GetInstantQuoteResponse>>('/public', quoteData, {
    headers: {
      'vendor-site-url': vendorSiteUrl,
      'user-site-url': userSiteUrl ?? '',
    },
  });
};

export const getQuote = async (quoteId: string) => {
  return await api.get<GetInstantQuoteResponse>(`/${quoteId}`);
};

export const updateInstantQuote = async (customerInfo: CustomerInfo, quoteUuid: string) => {
  return await api.put('/' + quoteUuid, { customerInfo });
};

export const addFileToQuote = async (
  quoteUuid: string,
  files: FormData,
  onProgress: ProgressSubscribeParams['handler'],
) => {
  const uuid = v4();
  const unsubscribe = subscribe<IoProgress>({
    entity: 'progress',
    filter: uuid,
    handler: onProgress,
  });

  return await api
    .post<FileType[]>('/' + quoteUuid + '/files', files, {
      headers: { 'action-uuid': uuid },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        onProgress(formatBufferProgress(loaded, total));
      },
    })
    .then((res) => {
      unsubscribe();

      return res;
    });
};

export const addFileToQuotePublic = async (
  files: FormData,
  {
    quoteUuid,
    userSiteUrl,
    vendorSiteUrl,
  }: { quoteUuid: string; vendorSiteUrl: string; userSiteUrl?: string },
  onProgress: ProgressSubscribeParams['handler'],
) => {
  const uuid = v4();
  const unsubscribe = subscribe<IoProgress>({
    entity: 'progress',
    filter: uuid,
    handler: onProgress,
  });

  return await api
    .post<FileType[]>('public/' + quoteUuid + '/files', files, {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        onProgress(formatBufferProgress(loaded, total));
      },
      headers: {
        'vendor-site-url': vendorSiteUrl,
        'user-site-url': userSiteUrl ?? '',
        'action-uuid': uuid,
      },
    })
    .then((res) => {
      unsubscribe();

      return res;
    });
};

export const selectPaymentOption = (paymentOptionUuid: string) => {
  return api.post<GetInstantQuoteResponse | { code: number }>(
    `/select-payment-option/${paymentOptionUuid}`,
  );
};

export const deleteFileFromQuote = async (quoteUuid: string, fileUuid: string) => {
  return await api.delete('/' + quoteUuid + '/file/' + fileUuid);
};

export const deleteFileFromQuotePublic = async ({
  fileUuid,
  quoteUuid,
  userSiteUrl,
  vendorSiteUrl,
}: {
  fileUuid: string;
  quoteUuid: string;
  vendorSiteUrl: string;
  userSiteUrl?: string;
}) => {
  return await api.delete('/public/' + quoteUuid + '/file/' + fileUuid, {
    headers: {
      'vendor-site-url': vendorSiteUrl,
      'user-site-url': userSiteUrl ?? '',
    },
  });
};

export const getSelectedPaymentInfo = async (paymentOptionUuid: string) => {
  return await api.get<PaymentQuoteOptionBE>(`/payment-option/${paymentOptionUuid}`);
};

export const getAllQuote = async (params: IGetRowsParams) => {
  return await api.get<PaginationQuoteType>(buildAGPaginationFilterQuery(params));
};

export const declineQuote = async (generatedQuoteUuid: string) => {
  return await api.post<{ vendorEmail: string; vendorName: string } | null>(
    '/decline/' + generatedQuoteUuid,
  );
};

export const unsubscribeQuote = async (quoteUuid: string) => {
  return await api.post<{ vendorEmail: string; vendorName: string } | null>(
    '/unsubscribe/' + quoteUuid,
  );
};

export const deleteQuotesList = async (uuidList: string[]) => {
  return await api.delete('/', { params: { uuids: uuidList } });
};
