import React, { ComponentProps } from 'react';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';

type ExtensionFormatterPropsType = Omit<ComponentProps<typeof Controller>, 'as'>;

export default function ExtensionFormatter({
  name,
  defaultValue,
  disabled,
  ...props
}: ExtensionFormatterPropsType) {
  const { control } = useFormContext();
  return (
    <Controller
      {...props}
      as={<NumberFormat customInput={TextField} disabled={disabled} />}
      name={name}
      id={name}
      control={control}
      defaultValue={defaultValue || ''}
    />
  );
}
