import React from 'react';
import { UseFormMethods } from 'react-hook-form/dist/types/form';
import { CURRENCY_TYPE } from '../../constants/currency';
import { getFormattedDate } from '../../services/formatter-service';

export type SectionType = {
  orderNo: number;
  titleId: string;
  name: string;
  isOpened: boolean;
  validationState: 'success' | 'failed' | null;
  errors: string[];
};

const initialSections: () => SectionType[] = () => [
  {
    orderNo: 1,
    titleId: 'applicant.applicant_information',
    name: 'applicant',
    validationState: null,
    isOpened: true,
    errors: [],
  },
  {
    orderNo: 2,
    titleId: 'applicant.co_lessees',
    name: 'parties',
    validationState: null,
    isOpened: false,
    errors: [],
  },
  {
    orderNo: 3,
    name: 'equipments',
    titleId: 'equipment.equipment',
    validationState: null,
    isOpened: false,
    errors: [],
  },
  {
    orderNo: 4,
    name: 'notes',
    titleId: 'common.notes',
    validationState: null,
    isOpened: false,
    errors: [],
  },
  {
    orderNo: 5,
    name: 'attachments',
    titleId: 'common.attachments',
    validationState: null,
    isOpened: false,
    errors: [],
  },
  {
    orderNo: 6,
    name: 'consent',
    titleId: 'consent.consent',
    validationState: 'success',
    isOpened: false,
    errors: [],
  },
];

export const useApplicationSections = (formErrors: UseFormMethods['errors']) => {
  const [sections, setSections] = React.useState<SectionType[]>(initialSections());

  React.useEffect(() => {
    const isSectionFailed = (section: string) => {
      if (section in formErrors) {
        return true;
      } else if (
        (formErrors?.application?.term || formErrors?.application?.frequency) &&
        section === 'equipments'
      ) {
        return true;
      } else if (formErrors?.customValidation?.parties && section === 'parties') {
        return true;
      }

      return false;
    };
    setSections(
      sections.map((s) => ({
        ...s,
        validationState: isSectionFailed(s.name) ? 'failed' : 'success',
      })),
    );
  }, [formErrors]);

  const resetSections = () => {
    setSections(initialSections());
  };

  const handleSectionToggle = (stepOrderNo: number) => {
    setSections(
      sections.map((s) => ({
        ...s,
        isOpened: stepOrderNo === s.orderNo ? !s.isOpened : s.isOpened,
      })),
    );
  };

  const handleSectionOpen = (stepOrderNo: number) => {
    setSections(
      sections.map((s) => ({
        ...s,
        isOpened: stepOrderNo === s.orderNo ? true : s.isOpened,
      })),
    );
  };

  const handleSectionClose = (stepOrderNo: number) => {
    setSections(
      sections.map((s) => ({
        ...s,
        isOpened: stepOrderNo === s.orderNo ? false : s.isOpened,
      })),
    );
  };

  return {
    sections,
    setSections,
    resetSections,
    handleSectionToggle,
    handleSectionOpen,
    handleSectionClose,
  };
};

export const formatEntityToBE = (entity: Partial<EntityToBE>) => ({
  uuid: entity.uuid,
  sameAs: entity.sameAs ? entity.sameAs.slice(0, -1) : undefined, // slice dot at the end
  isBusiness: entity.isBusiness,
  businessType: entity.businessType || null,
  firstName: entity.firstName || undefined,
  lastName: entity.lastName || undefined,
  middleName: entity.middleName || undefined,
  country: entity.country || undefined,
  province: entity.province || undefined,
  streetName: entity.streetName || undefined,
  streetNumber: entity.streetNumber || undefined,
  postalCode: entity.postalCode || undefined,
  city: entity.city || undefined,
  email: entity.email || undefined,
  sin: entity.sin || undefined,
  companyEmail: entity.companyEmail || undefined,
  operatingName: entity.operatingName || undefined,
  companyLegalName: entity.companyLegalName || undefined,
  companyWebsite: entity.companyWebsite || undefined,
  inBusinessSince: entity.inBusinessSince || undefined,
  dateOfBirth: entity.dateOfBirth || undefined,
  naicsCode: entity.naicsCode || undefined,
  naicsSfId: entity.naicsSfId || undefined,
  naicsDescription: entity.naicsDescription || undefined,
  mobilePhone: entity.mobilePhone || undefined,
  mobilePhoneAreaCode: entity.mobilePhoneAreaCode || undefined,
  phone: entity.phone || undefined,
  phoneAreaCode: entity.phoneAreaCode || undefined,
  phoneExt: entity.phoneExt || undefined,
  unit: entity.unit || undefined,
});

export const hasContact = (isBusiness: boolean, businessType: Entity['businessType']) =>
  isBusiness && businessType !== 'Sole Proprietorship';

export const prepareApplicationDataToSend = (
  data: ApplicationToBEType,
  currency: CURRENCY_TYPE,
  applicationName?: string,
  applicationApplicantRoleUuid?: string,
): ApplicationToBEType => {
  const dataToSend: ApplicationToBEType = {
    application: {
      uuid: data.application.uuid || undefined,
      currency,
      userUuid: data.application.userUuid || undefined,
      endOfTermOption: data.application.endOfTermOption || undefined,
      frequency: data.application.frequency || undefined,
      term: data.application.term || undefined,
      applicationName:
        applicationName || `New Application-${getFormattedDate(new Date().toISOString())}`,
    },
    applicant: {
      role: 'Applicant',
      uuid: applicationApplicantRoleUuid || data?.applicant?.uuid,
      entity: {
        ...formatEntityToBE(data.applicant.entity),
        contact: hasContact(
          data.applicant.entity.isBusiness ?? false,
          data.applicant.entity.businessType,
        )
          ? formatEntityToBE(data.applicant.entity.contact ?? {})
          : undefined,
      },
    },
    parties: data.parties.map((party) => ({
      role: party.role,
      uuid: party.uuid,
      entity: {
        ...formatEntityToBE(party.entity),
        contact: hasContact(party.entity.isBusiness ?? false, party.entity.businessType)
          ? formatEntityToBE(party.entity.contact ?? {})
          : undefined,
      },
    })),
    equipments: data.equipments.map((eq) => ({
      uuid: eq.uuid || undefined,
      currency,
      quantity: eq.quantity || 0,
      cost: eq.cost || 0,
      description: eq.description || undefined,
    })),
    notes: data.notes.map((n) => ({
      uuid: n.uuid,
      note: n.note,
    })),
  };

  return dataToSend;
};
