const defaultColumns = [
  {
    field: '',
    label: '',
    sortable: false,
    filter: false,
    width: 50,
    checkboxSelection: true,
    hidden: false,
    suppressMovable: true,
    suppressMenu: true,
    lockPosition: true,
  },
  {
    field: 'contractId',
    label: 'Contract Id',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    width: 150,
  },
  {
    field: 'companyLegalName',
    label: 'Customer',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    width: 150,
  },
  {
    field: 'applicationName',
    label: 'Application Name',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    width: 300,
  },
  {
    field: 'status',
    label: 'Status',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    width: 200,
  },
  {
    field: 'equipment',
    label: 'Equipment',
    sortable: false,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    width: 200,
  },
  {
    field: 'equipmentCost',
    label: 'Equipment Cost',
    sortable: true,
    filter: true,
    width: 170,
    checkboxSelection: false,
    hidden: false,
  },
  {
    field: 'createdBy',
    label: 'Vendor rep',
    sortable: true,
    filter: true,
    width: 170,
    checkboxSelection: false,
    hidden: false,
  },
  {
    field: 'email',
    label: 'Email',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    width: 200,
  },
  {
    field: 'phone',
    label: 'Phone Number',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    width: 200,
  },
  {
    field: 'createdAt',
    label: 'Created On',
    sortable: true,
    filter: true,
    checkboxSelection: false,
    hidden: false,
    width: 170,
  },
];
const defaultGridView = {
  label: 'All records',
  columns: [...defaultColumns],
  filterList: {
    email: {
      condition1: {
        filter: '.com',
        filterType: 'text',
        type: 'contains',
      },
      condition2: {
        filter: 'xyz',
        filterType: 'text',
        type: 'contains',
      },
      filterType: 'text',
      operator: 'OR',
    },
  },
};

export const initialState: SubmittedApplicationsStoreType = {
  selectedGridView: 'All records',
  gridViews: [defaultGridView],
  columns: [...defaultColumns],
  filterList: {},
  submittedApplicationList: [],
};
