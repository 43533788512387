import * as quoteApi from '../../apis/quote';
import { actions as toastActions } from '../../store/toast';
import { Dispatch } from 'react';
import { sendQuoteFollowUpEmails } from '../../apis/email';
import { getIntl } from '../../utils/language';
import { IGetRowsParams } from '@ag-grid-community/all-modules';

export const fetchQuoteList =
  (params: IGetRowsParams) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let allQuotes;

    try {
      allQuotes = (await quoteApi.getAllQuote(params)).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    return allQuotes;
  };

export const sendMassFollowUp =
  (masterQuoteIds: string[], templateUuid: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      const res = await sendQuoteFollowUpEmails({
        quotes: masterQuoteIds.map((uuid) => ({
          uuid,
          email: {
            emailTemplateUuid: templateUuid,
          },
        })),
      });
      if (res.data.isSuccess === 'partially') {
        const failedEmailsLength = res.data.failedEmails.length;

        if (failedEmailsLength === masterQuoteIds.length) {
          const toastOptions = {
            toastType: 'error' as const,
            message: intl.formatMessage({ id: 'email.quote_followup_not_sent' }),
          };
          dispatch(toastActions.open(toastOptions));
        } else {
          const toastOptions = {
            toastType: 'warning' as const,
            message: intl.formatMessage(
              { id: 'email.some_quote_followup_not_sent' },
              {
                successCount: masterQuoteIds.length - failedEmailsLength,
                failedCount: failedEmailsLength,
              },
            ),
          };
          dispatch(toastActions.open(toastOptions));
        }

        return;
      }
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'email.quote_followup_not_sent' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'emails.sent' }),
    };
    dispatch(toastActions.open(toastOptions));
  };

export const deleteQuotesList =
  (uuidList: string[]) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);

    try {
      const res = await quoteApi.deleteQuotesList(uuidList);

      if (res.data.failed.length) {
        if (res.data.failed.length === uuidList.length) {
          const toastOptions = {
            toastType: 'error' as const,
            message: intl.formatMessage({ id: 'quote.quote_delete_not_sent' }),
          };
          dispatch(toastActions.open(toastOptions));
        } else {
          const toastOptions = {
            toastType: 'warning' as const,
            message: intl.formatMessage(
              { id: 'quote.some_quote_delete_not_sent' },
              {
                successCount: uuidList.length - res.data.failed.length,
                failedCount: res.data.failed.length,
              },
            ),
          };
          dispatch(toastActions.open(toastOptions));
        }
      } else {
        const toastOptions = {
          toastType: 'success' as const,
          message: intl.formatMessage({ id: 'quote.quotes_deleted' }),
        };
        dispatch(toastActions.open(toastOptions));
      }
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));
    }
  };
