import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';

const BACKEND = getBackendUrl() + '/vendor';
const api = getApi(BACKEND);

export const getAllVendors = async () => {
  //increase in case of more vendors
  return await api.get('/?limit=9999');
};

export const getVendorByUuid = async (vendorUuid: string) => {
  return await api.get(`/${vendorUuid}`);
};

export const getVendorBySiteUrl = async (vendorSiteUrl: string, userSiteUrl?: string) => {
  return await api.get(`/${vendorSiteUrl}/site-url`, {
    headers: {
      'vendor-site-url': vendorSiteUrl,
      'user-site-url': userSiteUrl ?? '',
    },
  });
};

export const updateVendorById = async (
  vendorUuid: string,
  vendorObj: Omit<VendorCompanyType, 'vendorCompany'>,
) => {
  return await api.put(`/${vendorUuid}`, vendorObj);
};

export const changeLogo = async (vendorUuid: string, logo: FormData) => {
  return await api.post<{ logoImage: string }>(`/${vendorUuid}/logo`, logo);
};

export const removeVendorLogo = async (vendorUuid: string) => {
  return await api.delete(`/${vendorUuid}/logo`);
};

export const addFileToVendor = async (vendorUuid: string, files: FormData) => {
  return await api.post(`/${vendorUuid}/files`, files);
};

export const deleteFileFromVendor = async (vendorUuid: string, fileUuid: string) => {
  return await api.delete(`/${vendorUuid}/files/${fileUuid}`);
};

export const getContactsInfo = async (vendorUuid: string) => {
  return await api.get<ContactsStoreType>(`/${vendorUuid}/contacts`);
};

export const getSiteContactsBySiteUrls = async (vendorSiteUrl: string, userSiteUrl?: string) => {
  return await api.get<ContactsStoreType>('/contacts', {
    headers: {
      'vendor-site-url': vendorSiteUrl,
      'user-site-url': userSiteUrl ?? '',
    },
  });
};

export const deleteVendor = async (vendorUuid: string) => {
  return await api.delete(`/${vendorUuid}`);
};
