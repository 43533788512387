import React from 'react';
import { useStyles } from './styles';
import Alert from '@material-ui/lab/Alert';


function VpFormAlert(props: React.ComponentProps<typeof Alert>) {
  const classes = useStyles();

  return <Alert className={classes.alert} {...props}>
    {props.children}
  </Alert>;
}

export default VpFormAlert;
