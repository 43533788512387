import TextInput from '../TextInput';
import React from 'react';
import { useIntl } from 'react-intl';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { naicsSelector } from '../../../store/formValues/selectors';
import {
  USER_EMAIL_PATTERN,
  US_CANADA_ZIP_PATTERN,
  WEBSITE_PATTERN,
} from '../../../constants/regex';
import VaultDatePicker from '../vault-date-picker';
import AreaCodeFormatter from '../AreaCodeFormatter';
import PhoneFormatter from '../PhoneFormatter';
import ExtensionFormatter from '../ExtensionFormatter';
import SinFormatter from '../sin-formatter';
import { buildStringPath, DEFAULT_ADULT_AGE, SIN_TOTAL_LENGTH } from '../../../utils/common';
import { getAge } from '../../../services/validation-service';
import get from 'lodash/get';
import {
  BUSINESS_TYPE,
  CO_LESSEE_ROLE,
  COUNTRY_TYPE,
  ENTITY_ROLE,
  ENTITY_TYPE,
  getStateOptionsByCode,
} from '../../../constants/formValues';
import VaultMenu from '../VaultMenu';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import VpFormAlert from '../VpFormAlert';
import {
  getBusinessEntityLabelFactory,
  getIndividualEntityLabelFactory,
} from '../../../utils/entity';
import EntitySearch from '../EntitySearch';
import { getFormattedDate, isValidDate } from '../../../services/formatter-service';
import NaicsSelector from '../NaicsSelector';
import uniqBy from 'lodash/uniqBy';
import { APPLICATION_DATE_FORMAT } from '../../../constants/date';
import VendorRepSelection from '../VendorRepSelection';
import BooleanInput from '../BooleanInput';
import { toFlatPropertyMap } from '../../../utils/common';
import { getDatesDifferenceInYears } from '../../../utils/date';
import { translateLabel } from '../../../utils/translateLabel';

export type OwnEntityProperties = keyof Entity & { sameAs: string };
export type EntityRoleProperties = 'role';
export type ApplicationProperties = 'userUuid';
export type EntityTemporaryProperties = 'naics' | 'age' | 'addContactAsParty';
export type FormTemporaryProperties = 'consentConfirmed';
export type EntityManuallyHandledProperties =
  | Entity['naicsDescription']
  | Entity['naicsSfId']
  | Entity['naicsCode']
  | Entity['uuid'];
export type EntityProperties =
  | OwnEntityProperties
  | EntityRoleProperties
  | EntityTemporaryProperties
  | EntityManuallyHandledProperties;
export type EntityPropertyPath = string; // '' or 'contact.'

export const ownPropertiesMap = {
  companyLegalName: 'companyLegalName' as OwnEntityProperties,
  operatingName: 'operatingName' as OwnEntityProperties,
  companyEmail: 'companyEmail' as OwnEntityProperties,
  email: 'email' as OwnEntityProperties,
  companyWebsite: 'companyWebsite' as OwnEntityProperties,
  firstName: 'firstName' as OwnEntityProperties,
  middleName: 'middleName' as OwnEntityProperties,
  lastName: 'lastName' as OwnEntityProperties,
  inBusinessSince: 'inBusinessSince' as OwnEntityProperties,
  phone: 'phone' as OwnEntityProperties,
  phoneAreaCode: 'phoneAreaCode' as OwnEntityProperties,
  phoneExt: 'phoneExt' as OwnEntityProperties,
  mobilePhone: 'mobilePhone' as OwnEntityProperties,
  mobilePhoneAreaCode: 'mobilePhoneAreaCode' as OwnEntityProperties,
  businessType: 'businessType' as OwnEntityProperties,
  isBusiness: 'isBusiness' as OwnEntityProperties,
  city: 'city' as OwnEntityProperties,
  country: 'country' as OwnEntityProperties,
  province: 'province' as OwnEntityProperties,
  postalCode: 'postalCode' as OwnEntityProperties,
  unit: 'unit' as OwnEntityProperties,
  streetName: 'streetName' as OwnEntityProperties,
  streetNumber: 'streetNumber' as OwnEntityProperties,
  dateOfBirth: 'dateOfBirth' as OwnEntityProperties,
  sin: 'sin' as OwnEntityProperties,
  sameAs: 'sameAs' as OwnEntityProperties,
};

const manuallyHandledProperties = {
  naicsDescription: 'naicsDescription' as EntityManuallyHandledProperties,
  naicsSfId: 'naicsSfId' as EntityManuallyHandledProperties,
  naicsCode: 'naicsCode' as EntityManuallyHandledProperties,
  uuid: 'uuid' as EntityManuallyHandledProperties,
};

const rolePropertiesMap = {
  role: 'role' as EntityRoleProperties,
};

const applicationPropertiesMap = {
  userUuid: 'userUuid' as ApplicationProperties,
};

const formPropertiesMap = {
  consentConfirmed: 'consentConfirmed' as FormTemporaryProperties,
};

const temporaryPropertiesMap = {
  age: 'age' as EntityTemporaryProperties,
  naics: 'naics' as EntityTemporaryProperties,
  addContactAsParty: 'addContactAsParty' as EntityTemporaryProperties,
};

export const entityPropertiesMap = {
  ...ownPropertiesMap,
  ...rolePropertiesMap,
  ...temporaryPropertiesMap,
  ...manuallyHandledProperties,
  ...applicationPropertiesMap,
  ...formPropertiesMap,
};

const getCompanyLegalNameInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function CompanyLegalNameInput({ required }: { required: boolean }) {
    useSameAs(path, entityPropertiesMap.companyLegalName, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        required={required}
        name={path + entityPropertiesMap.companyLegalName}
        label={intl.formatMessage({ id: 'applicant.company_legal_name' })}
        fullWidth
      />
    );
  };

const getCompanyOperatingNameInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function CompanyOperatingNameInput() {
    useSameAs(path, entityPropertiesMap.companyEmail, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        name={path + entityPropertiesMap.operatingName}
        label={intl.formatMessage({ id: 'applicant.operating_name' })}
        fullWidth
      />
    );
  };

const getCompanyEmailInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function CompanyEmailInput() {
    useSameAs(path, entityPropertiesMap.companyEmail, sameAs);
    const intl = useIntl();

    const {
      formState: { errors },
    } = useFormContext();
    const name = path + entityPropertiesMap.companyEmail;
    const errorForm = () => toFlatPropertyMap(errors)[name + '.message'];
    const [isErrorMessage, setIsErrorMessage] = React.useState<string | undefined>(undefined);

    const onTextInputBlur = () => setIsErrorMessage(errorForm() as string | undefined);
    const onTextInputChange = () => !errorForm() && setIsErrorMessage(undefined);

    return (
      <TextInput
        rules={{
          pattern: {
            value: USER_EMAIL_PATTERN,
            message: intl.formatMessage({ id: 'common.email_rule' }),
          },
        }}
        name={name}
        label={intl.formatMessage({ id: 'applicant.company_email' })}
        fullWidth
        required
        onBlur={onTextInputBlur}
        onKeyDown={onTextInputChange}
        error={isErrorMessage ? true : false}
        helperText={isErrorMessage && intl.formatMessage({ id: 'common.email_rule' })}
      />
    );
  };

const getEmailInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function EmailInput() {
    useSameAs(path, entityPropertiesMap.email, sameAs);
    const intl = useIntl();

    const {
      formState: { errors },
    } = useFormContext();
    const name = path + entityPropertiesMap.email;
    const errorForm = () => toFlatPropertyMap(errors)[name + '.message'];
    const [isErrorMessage, setIsErrorMessage] = React.useState<string | undefined>(undefined);

    const onTextInputBlur = () => setIsErrorMessage(errorForm() as string | undefined);
    const onTextInputChange = () => !errorForm() && setIsErrorMessage(undefined);

    return (
      <TextInput
        required
        rules={{
          pattern: {
            value: USER_EMAIL_PATTERN,
            message: intl.formatMessage({ id: 'common.email_rule' }),
          },
        }}
        name={name}
        label={intl.formatMessage({ id: 'common.email' })}
        fullWidth
        onBlur={onTextInputBlur}
        onKeyDown={onTextInputChange}
        error={isErrorMessage ? true : false}
        helperText={isErrorMessage && intl.formatMessage({ id: 'common.email_rule' })}
      />
    );
  };

const getCompanyWebsiteInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function CompanyWebsiteInput() {
    useSameAs(path, entityPropertiesMap.companyWebsite, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        name={path + entityPropertiesMap.companyWebsite}
        label={intl.formatMessage({ id: 'applicant.company_website' })}
        rules={{
          pattern: {
            value: WEBSITE_PATTERN,
            message: intl.formatMessage({ id: 'applicant.invalid_website' }),
          },
        }}
        fullWidth
      />
    );
  };

const getFirstNameInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function FirstNameInput() {
    useSameAs(path, entityPropertiesMap.firstName, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        required
        name={path + entityPropertiesMap.firstName}
        label={intl.formatMessage({ id: 'common.first_name' })}
        fullWidth
      />
    );
  };

const getMiddleNameInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function MiddleNameInput() {
    useSameAs(path, entityPropertiesMap.middleName, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        name={path + entityPropertiesMap.middleName}
        label={intl.formatMessage({ id: 'common.middle_name' })}
        fullWidth
      />
    );
  };

const getLastNameInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function LastNameInput() {
    useSameAs(path, entityPropertiesMap.lastName, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        required
        name={path + entityPropertiesMap.lastName}
        label={intl.formatMessage({ id: 'common.last_name' })}
        fullWidth
      />
    );
  };

const getInBusinessSinceInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function InBusinessSinceInput() {
    useSameAs(path, entityPropertiesMap.inBusinessSince, sameAs);
    const intl = useIntl();

    return (
      <VaultDatePicker
        name={path + entityPropertiesMap.inBusinessSince}
        required
        maxDate={getFormattedDate(new Date(), null, APPLICATION_DATE_FORMAT)}
        label={intl.formatMessage({ id: 'applicant.in_business_since' })}
      />
    );
  };

const getDateOfBirthInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function DateOfBirthInput() {
    const intl = useIntl();
    useSameAs(path, entityPropertiesMap.age, sameAs);
    useSameAs(path, entityPropertiesMap.dateOfBirth, sameAs);
    const { register, setValue } = useFormContext();

    const onDateOfBirthChange = (date: string | null) => {
      const age = getAge(date);
      setValue(path + entityPropertiesMap.age, +age);
      setValue(path + entityPropertiesMap.dateOfBirth, date);
    };

    return (
      <>
        <VaultDatePicker
          onChange={onDateOfBirthChange}
          name={path + entityPropertiesMap.dateOfBirth}
          maxDate={getFormattedDate(new Date(), null, APPLICATION_DATE_FORMAT)}
          label={intl.formatMessage({ id: 'common.dob' })}
        />
        <input
          type="number"
          hidden
          name={buildStringPath([path, entityPropertiesMap.age])}
          ref={register({
            required: {
              value: true,
              message: intl.formatMessage({ id: 'common.required' }),
            },
            min: { value: 18, message: intl.formatMessage({ id: 'common.18plus' }) },
          })}
        />
      </>
    );
  };

const getNaicsInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function NaicsInput() {
    useSameAs(path, entityPropertiesMap.naics, sameAs);
    useSameAs(path, entityPropertiesMap.naicsSfId, sameAs);
    useSameAs(path, entityPropertiesMap.naicsCode, sameAs);
    useSameAs(path, entityPropertiesMap.naicsDescription, sameAs);
    const intl = useIntl();
    const naicsList = useSelector(naicsSelector);
    const { setValue, setError } = useFormContext();
    const name = buildStringPath([path, entityPropertiesMap.naics]);

    if (!naicsList.length) {
      return null;
    }

    return (
      <NaicsSelector
        name={name}
        label={intl.formatMessage({ id: 'applicant.nature_of_business' })}
        options={naicsList}
        getOptionSelected={(option, value) => {
          return (option as Naics)?.id === (value as Naics)?.id;
        }}
        onChange={(_, selectedNaics) => {
          setValue(name, selectedNaics);
          setValue(path + manuallyHandledProperties.naicsSfId, selectedNaics?.id);
          setValue(path + manuallyHandledProperties.naicsCode, selectedNaics?.value);
          setValue(path + manuallyHandledProperties.naicsDescription, selectedNaics?.label);
          if (selectedNaics) {
            setError(name, {});
          }
        }}
        required
      />
    );
  };

const getPhoneAreaCodeInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function PhoneAreaCodeInput() {
    useSameAs(path, entityPropertiesMap.phoneAreaCode, sameAs);
    const intl = useIntl();

    return (
      <AreaCodeFormatter
        id={path + entityPropertiesMap.phoneAreaCode}
        name={path + entityPropertiesMap.phoneAreaCode}
        label={intl.formatMessage({ id: 'common.area_code' })}
        fullWidth
        rules={{
          required: true,
          validate: (value: string) => {
            if (value.replace(/\D/gm, '').length < 3) {
              return false;
            }
          },
        }}
        required
        phoneformatter={{ id: path + entityPropertiesMap.phone }}
      />
    );
  };

const getMobilePhoneAreaCodeInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function MobilePhoneAreaCodeInput() {
    useSameAs(path, entityPropertiesMap.mobilePhoneAreaCode, sameAs);
    const intl = useIntl();

    return (
      <AreaCodeFormatter
        id={path + entityPropertiesMap.mobilePhoneAreaCode}
        name={path + entityPropertiesMap.mobilePhoneAreaCode}
        label={intl.formatMessage({ id: 'common.area_code' })}
        fullWidth
        phoneformatter={{ id: path + entityPropertiesMap.mobilePhone }}
      />
    );
  };

const getMobilePhoneInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function MobilePhoneInput() {
    useSameAs(path, entityPropertiesMap.mobilePhone, sameAs);
    const intl = useIntl();

    return (
      <PhoneFormatter
        id={path + entityPropertiesMap.mobilePhone}
        name={path + entityPropertiesMap.mobilePhone}
        label={intl.formatMessage({ id: 'common.mobile_phone' })}
        fullWidth
      />
    );
  };

const getVendorRepInput = (path: EntityPropertyPath) =>
  function VendorRepInput({
    vendorReps,
    className,
    defaultUser,
  }: {
    className: string;
    vendorReps: VendorRepType[];
    defaultUser?: string | null;
  }) {
    const fullPath = buildStringPath([path, applicationPropertiesMap.userUuid]);

    return (
      <VendorRepSelection
        name={fullPath}
        required
        className={className}
        vendorReps={vendorReps}
        defaultValue={defaultUser ?? undefined}
        disabled={vendorReps.length <= 1}
      />
    );
  };

const getConsentConfirmationInput = (path: EntityPropertyPath) =>
  function ConsentConfirmitionInput() {
    const intl = useIntl();
    const fullPath = buildStringPath([path, formPropertiesMap.consentConfirmed]);

    return (
      <BooleanInput
        color="default"
        rules={{ required: true }}
        labelPosition="after"
        label={intl.formatMessage({ id: 'consent.accept_message' })}
        name={fullPath}
        shouldTrigger
      />
    );
  };

const useSameAs = (
  path: EntityPropertyPath,
  property: EntityProperties,
  sameAs?: EntityPropertyPath,
) => {
  const { setValue, getValues, control } = useFormContext();
  const fullPath = buildStringPath([path, property as string]);
  const sameAsPath = sameAs ? buildStringPath([sameAs, property as string]) : '';
  const thisValue = sameAs
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useWatch({ name: fullPath, control, defaultValue: getValues(sameAsPath) })
    : '';
  const sameAsValue = sameAs
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useWatch({ name: sameAsPath, control, defaultValue: getValues(sameAsPath) })
    : '';

  React.useEffect(() => {
    if (sameAs && getValues(fullPath) !== getValues(sameAsPath)) {
      setValue(sameAsPath, thisValue);
    }
  }, [thisValue, fullPath, getValues, sameAsPath]);
  React.useEffect(() => {
    if (sameAs && getValues(fullPath) !== getValues(sameAsPath)) {
      setValue(fullPath, sameAsValue);
    }
  }, [sameAsValue, fullPath, getValues, sameAsPath]);
};

const getPhoneInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function PhoneInput() {
    useSameAs(path, entityPropertiesMap.phone, sameAs);
    const intl = useIntl();
    const fullPath = buildStringPath([path, entityPropertiesMap.phone]);

    return (
      <PhoneFormatter
        name={fullPath}
        required
        label={intl.formatMessage({ id: 'common.phone' })}
        fullWidth
      />
    );
  };

const getExtensionInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function ExtensionInput() {
    useSameAs(path, entityPropertiesMap.phoneExt, sameAs);
    const intl = useIntl();

    return (
      <ExtensionFormatter
        id={path + entityPropertiesMap.phoneExt}
        name={path + entityPropertiesMap.phoneExt}
        label={intl.formatMessage({ id: 'common.phone_ext' })}
        fullWidth
      />
    );
  };

const getUnitInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function UnitInput() {
    useSameAs(path, entityPropertiesMap.unit, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        name={path + entityPropertiesMap.unit}
        label={intl.formatMessage({ id: 'common.unit_suite' })}
        fullWidth
      />
    );
  };

const getStreetNumberInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function StreetNumberInput() {
    useSameAs(path, entityPropertiesMap.streetNumber, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        required
        name={path + entityPropertiesMap.streetNumber}
        label={intl.formatMessage({ id: 'common.street_number' })}
        fullWidth
      />
    );
  };

const getStreetNameInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function StreetNameInput() {
    useSameAs(path, entityPropertiesMap.streetName, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        required
        name={path + entityPropertiesMap.streetName}
        label={intl.formatMessage({ id: 'common.street_name' })}
        fullWidth
      />
    );
  };

const getCityInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function CityInput() {
    useSameAs(path, entityPropertiesMap.city, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        name={path + entityPropertiesMap.city}
        required
        label={intl.formatMessage({ id: 'common.city' })}
        fullWidth
      />
    );
  };

const getPostalCodeInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function PostalCodeInput() {
    useSameAs(path, entityPropertiesMap.postalCode, sameAs);
    const intl = useIntl();

    return (
      <TextInput
        required
        name={path + entityPropertiesMap.postalCode}
        label={intl.formatMessage({ id: 'common.zip_portal_code' })}
        rules={{
          pattern: {
            value: US_CANADA_ZIP_PATTERN,
            message: intl.formatMessage({ id: 'common.zip_portal_code_validation' }),
          },
        }}
        fullWidth
      />
    );
  };

const getProvinceInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function ProvinceInput() {
    useSameAs(path, entityPropertiesMap.province, sameAs);
    const intl = useIntl();
    const context = useFormContext();
    const country = context.watch(path + entityPropertiesMap.country);

    return (
      <VaultMenu
        required
        name={path + entityPropertiesMap.province}
        label={intl.formatMessage({ id: 'common.province_state' })}
        options={getStateOptionsByCode(country, intl.locale.toLowerCase())}
        fullWidth
      />
    );
  };

const getCountryInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function CountryInput() {
    useSameAs(path, entityPropertiesMap.country, sameAs);
    useSameAs(path, entityPropertiesMap.province, sameAs);
    const intl = useIntl();
    const { setValue } = useFormContext();

    const handleCountryChange: SelectInputProps['onChange'] = (event) => {
      setValue(path + entityPropertiesMap.country, event.target.value);
      setValue(path + entityPropertiesMap.province, '');
    };

    return (
      <VaultMenu
        required
        name={path + entityPropertiesMap.country}
        label={intl.formatMessage({ id: 'common.country' })}
        options={COUNTRY_TYPE}
        defaultValue="CA"
        fullWidth
        onChange={handleCountryChange}
      />
    );
  };

const getSameAsInput = (path: EntityPropertyPath) =>
  function SameAsInput({
    sameAsValues,
  }: {
    sameAsValues: Array<{ value: string; label: string }>;
  }) {
    const intl = useIntl();
    const fullPath = buildStringPath([path, entityPropertiesMap.sameAs]);
    const { setValue, getValues } = useFormContext();

    const handleCountryChange: SelectInputProps['onChange'] = (event) => {
      setValue(fullPath, event.target.value);
    };

    return (
      <VaultMenu
        name={fullPath}
        label={intl.formatMessage({ id: 'common.same_as' })}
        options={[...sameAsValues, { label: intl.formatMessage({ id: 'common.none' }), value: '' }]}
        defaultValue={getValues(fullPath)}
        fullWidth
        onChange={handleCountryChange}
      />
    );
  };

const getBusinessTypeInput = (path: EntityPropertyPath) =>
  function BusinessTypeInput() {
    const intl = useIntl();

    return (
      <VaultMenu
        name={path + entityPropertiesMap.businessType}
        label={intl.formatMessage({ id: 'applicant.business_type' })}
        options={BUSINESS_TYPE}
        defaultValue="Corporation"
        required
      />
    );
  };

const getEntityTypeInput = (path: EntityPropertyPath) =>
  function EntityTypeInput() {
    const intl = useIntl();

    return (
      <VaultMenu
        options={ENTITY_TYPE}
        name={path + entityPropertiesMap.isBusiness}
        label={intl.formatMessage({ id: 'applicant.type' })}
      />
    );
  };

const getApplicationRoleInput = (path: EntityPropertyPath) =>
  function ApplicationRoleInput() {
    const intl = useIntl();

    return (
      <VaultMenu
        options={ENTITY_ROLE}
        label={intl.formatMessage({ id: 'common.role' })}
        name={path + entityPropertiesMap.role}
        defaultValue={CO_LESSEE_ROLE}
        required
      />
    );
  };

const getSINInput = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) =>
  function SINInput() {
    useSameAs(path, entityPropertiesMap.sin, sameAs);
    useSameAs(path, entityPropertiesMap.age, sameAs);
    const intl = useIntl();
    const { setValue, getValues } = useFormContext();
    const dateOfBirth = getValues(path + entityPropertiesMap.dateOfBirth);

    const onSinBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      // set age for disable dateOfBirth validation.
      const sin = event.target.value;
      if (sin.length === SIN_TOTAL_LENGTH) {
        setValue(path + entityPropertiesMap.age, DEFAULT_ADULT_AGE);
        setValue(path + entityPropertiesMap.sin, sin);
      } else if (!sin) {
        if (dateOfBirth) {
          setValue(path + entityPropertiesMap.age, getAge(dateOfBirth));
        }
        setValue(path + entityPropertiesMap.sin, sin);
      } else {
        setValue(path + entityPropertiesMap.sin, sin);
      }
    };

    return (
      <SinFormatter
        required={!dateOfBirth}
        id={path + entityPropertiesMap.sin}
        name={path + entityPropertiesMap.sin}
        label={intl.formatMessage({ id: 'common.social_insurance_number' })}
        fullWidth
        onBlur={onSinBlur}
      />
    );
  };

export const useHandleEntityChange = () => {
  const { setValue } = useFormContext();
  const naics = useSelector(naicsSelector);

  return React.useCallback(
    (entity: Entity | null, path: EntityPropertyPath, auto = true) => {
      const setValueConfig = auto
        ? {
            shouldDirty: false,
            shouldValidate: false,
          }
        : {
            shouldDirty: true,
          };
      Object.values(ownPropertiesMap).forEach((entityProp) => {
        const entityPath = buildStringPath([path, entityProp]);
        const val = get(entity, entityProp);
        if (
          [entityPropertiesMap.dateOfBirth, entityPropertiesMap.inBusinessSince].includes(
            entityProp,
          )
        ) {
          // date type of inputs
          const newVal = isValidDate(val) ? val : null;
          setValue(entityPath, newVal, setValueConfig);
        } else if (
          [entityPropertiesMap.country, entityPropertiesMap.province].includes(entityProp)
        ) {
          // properties with default values
          if (entityPropertiesMap.country === entityProp) {
            setValue(entityPath, val || 'CA', setValueConfig);
          }
          if (entityPropertiesMap.province === entityProp) {
            setValue(entityPath, val || null, setValueConfig);
          }
        } else {
          setValue(entityPath, val ?? '', setValueConfig);
        }
      });
      Object.values(temporaryPropertiesMap).forEach((tempProp) => {
        switch (tempProp) {
          case 'age':
            setValue(
              buildStringPath([path, temporaryPropertiesMap.age]),
              +getAge(entity?.dateOfBirth ?? null, entity?.sin ?? undefined),
              setValueConfig,
            );
            break;
          case 'addContactAsParty':
            break;
          case 'naics':
            const entityNaics = naics.find((n) => n.id === entity?.naicsSfId);
            setValue(
              buildStringPath([path, entityPropertiesMap.naicsCode as string]),
              entityNaics?.value ?? null,
            );
            setValue(
              buildStringPath([path, entityPropertiesMap.naicsDescription as string]),
              entityNaics?.label ?? null,
            );
            setValue(
              buildStringPath([path, entityPropertiesMap.naicsSfId as string]),
              entityNaics?.id ?? null,
            );
            setValue(
              buildStringPath([path, entityPropertiesMap.naics]),
              entityNaics ?? {},
              setValueConfig,
            );
            break;
          default:
            throw new Error('unhandled temporary property');
        }
      });
      // entity uuid value
      setValue(
        buildStringPath([path, manuallyHandledProperties.uuid as string]),
        entity?.uuid ?? undefined,
        setValueConfig,
      );
    },
    [naics, setValue],
  );
};

export const defaultEntity = { isBusiness: true, businessType: 'Corporation' } as Entity;
export const defaultEntityContact = { isBusiness: false, businessType: null } as Entity;

export const ApplicationEntitySelect = ({
  path,
  type,
  label,
  disabled,
}: {
  path: EntityPropertyPath;
  type: 'individual' | 'corporation' | 'soleProprietorship';
  label: string;
  disabled?: boolean;
}) => {
  const { watch } = useFormContext();
  const allEntities = useSelector((state: AppStoreType) => state.formValues.entities);
  const selectedUuid = watch(buildStringPath([path, entityPropertiesMap.uuid as string]));
  const getIndividualEntityLabel = React.useMemo(() => getIndividualEntityLabelFactory(), []);
  const getBusinessEntityLabel = React.useMemo(() => getBusinessEntityLabelFactory(), []);
  const entityChangeHandler = useHandleEntityChange();
  const intl = useIntl();
  let entities: Entity[] = [];
  switch (type) {
    case 'corporation':
      entities = uniqBy(entities.concat(allEntities.businesses), 'uuid');
      break;
    case 'soleProprietorship':
      entities = uniqBy(entities.concat(allEntities.soleProprietorship), 'uuid');
      break;
    case 'individual':
      entities = uniqBy(
        entities.concat(allEntities.individuals, allEntities.soleProprietorship),
        'uuid',
      );
      break;
  }

  const handleEntityChangeWithDefaultContact = (entity: Entity | null) => {
    const retrievedEntity = entity;
    entityChangeHandler(retrievedEntity ?? defaultEntity, path, false);
    if (retrievedEntity?.defaultContactUuid && type === 'corporation') {
      const contactEntity = [...allEntities.individuals, ...allEntities.soleProprietorship].find(
        (e) => e.uuid === retrievedEntity.defaultContactUuid,
      );
      entityChangeHandler(
        contactEntity ?? defaultEntityContact,
        buildStringPath([path, 'contact.']),
        false,
      );
    }
  };

  return entities.length ? (
    <EntitySearch
      disabled={disabled}
      selectedUuid={selectedUuid}
      dataCy={path}
      onChange={(_, entity) => handleEntityChangeWithDefaultContact(entity)}
      getOptionLabel={type === 'corporation' ? getBusinessEntityLabel : getIndividualEntityLabel}
      entities={entities}
      label={translateLabel(intl, label as string)}
    />
  ) : null;
};

export const AgeWarnings = ({ path }: { path: EntityPropertyPath }) => {
  const { watch } = useFormContext();
  const intl = useIntl();
  const dob = watch(path + entityPropertiesMap.dateOfBirth);
  const sin = watch(path + entityPropertiesMap.sin);
  let age = 0;

  if (dob) {
    age = getDatesDifferenceInYears(new Date(), new Date(dob));
  }

  return (
    <>
      {!sin && !dob && (
        <VpFormAlert variant="filled" icon={false} color="warning">
          * {intl.formatMessage({ id: 'common.social_and_dob_required' })}
        </VpFormAlert>
      )}
      {dob && age < 18 ? (
        <VpFormAlert variant="filled" icon={false} color="error">
          {intl.formatMessage({ id: 'common.18plus' })}
        </VpFormAlert>
      ) : null}
    </>
  );
};

export const useApplicationInputs = (path: EntityPropertyPath, sameAs?: EntityPropertyPath) => {
  return React.useMemo(
    () => ({
      PhoneInput: getPhoneInput(path, sameAs),
      PhoneAreaCodeInput: getPhoneAreaCodeInput(path, sameAs),
      ExtensionInput: getExtensionInput(path, sameAs),
      MobilePhoneInput: getMobilePhoneInput(path, sameAs),
      MobilePhoneAreaCodeInput: getMobilePhoneAreaCodeInput(path, sameAs),
      InBusinessSinceInput: getInBusinessSinceInput(path, sameAs),
      DateOfBirthInput: getDateOfBirthInput(path, sameAs),
      FirstNameInput: getFirstNameInput(path, sameAs),
      MiddleNameInput: getMiddleNameInput(path, sameAs),
      LastNameInput: getLastNameInput(path, sameAs),
      CompanyWebsiteInput: getCompanyWebsiteInput(path, sameAs),
      CompanyOperatingNameInput: getCompanyOperatingNameInput(path, sameAs),
      CompanyLegalNameInput: getCompanyLegalNameInput(path, sameAs),
      EmailInput: getEmailInput(path, sameAs),
      CompanyEmailInput: getCompanyEmailInput(path, sameAs),
      ProvinceInput: getProvinceInput(path, sameAs),
      CountryInput: getCountryInput(path, sameAs),
      SameAsInput: getSameAsInput(path),
      SINInput: getSINInput(path, sameAs),
      PostalCodeInput: getPostalCodeInput(path, sameAs),
      UnitInput: getUnitInput(path, sameAs),
      StreetNameInput: getStreetNameInput(path, sameAs),
      StreetNumberInput: getStreetNumberInput(path, sameAs),
      NaicsInput: getNaicsInput(path, sameAs),
      CityInput: getCityInput(path, sameAs),
      BusinessTypeInput: getBusinessTypeInput(path),
      ApplicationRoleInput: getApplicationRoleInput(path),
      EntityTypeInput: getEntityTypeInput(path),
      VendorRepInput: getVendorRepInput(path),
      ConsentConfirmationInput: getConsentConfirmationInput(path),
    }),
    [path, sameAs],
  );
};
