import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    width: 500,
    maxWidth: '100%',
  },
  dropzone: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
  filesTitle: { display: 'inline-flex', marginRight: theme.spacing(3) },
  fileItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileName: {
    marginRight: theme.spacing(1),
  },
  '@keyframes loadingSpin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  loadingIcon: {
    animationName: '$loadingSpin',
    animation: '2s linear infinite',
  },
}));

export { useStyles };
