import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  pickerToolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : theme.palette.background.default,
  },
  pickerToolbarLandscape: {
    height: 'auto',
    maxWidth: 150,
    padding: 8,
    justifyContent: 'flex-start',
  },
  toolbar: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  toolbarLandscape: {
    padding: 16,
  },
  dateLandscape: {
    marginRight: 16,
  },
  yearArrowDown: {
    color: 'white',
    transition: 'transform .2s',
  },
  yearArrowDownActive: {
    transform: 'rotate(180deg)',
  },
  toolbarBtn: {
    padding: 0,
    minWidth: '16px',
    textTransform: 'none',
  },
}));
