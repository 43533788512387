import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import useStyles from './styles';

type LoadingProps = {
  isOpen: boolean;
  inline?: boolean;
};

const Loading = ({ isOpen, inline }: LoadingProps) => {
  const classes = useStyles();

  return (
    <div>
      {inline && isOpen ? (
        <CircularProgress color="inherit" />
      ) : (
        <Backdrop className={classes.backdrop} open={isOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

export default Loading;
