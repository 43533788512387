import CreateUser from './create-user.container';
import * as Navigations from '../../constants/navigations';
const { path } = Navigations.CREATE_USER;
const route = [
  {
    path,
    name: 'CreateUser',
    component: CreateUser,
    exact: true,
  },
];
export default route;
