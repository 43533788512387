import { darken, makeStyles, withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#edeff1',
  },
  body: {
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))(TableCell);

const AttachTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#edeff1',
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const StyledTableBody = withStyles(() => ({
  root: {
    '& tr:nth-of-type(odd)': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}))(TableBody);

const tableOddBg = '#f5f5f5';

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: 'center',
  },
  sortingIcon: {
    '& svg': {
      fontSize: '0.9rem',
      verticalAlign: 'middle',
    },
  },
  buttonGroup: {
    paddingTop: theme.spacing(1),
    '& $button': {
      marginRight: '1vw',
      marginBottom: '15px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '10px',
        marginBottom: '10px',
      },
    },
  },
  container: {
    width: '100%' + theme.spacing(3),
    margin: theme.spacing(-3, -3, -3),
    padding: '15px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '10px',
  },
  headerTitle: {
    color: theme.palette.text.primary,
  },
  file: {
    display: 'flex',
    wordBreak: 'break-all',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileName: {
    marginLeft: '5px',
    marginRight: '10px',
    color: theme.palette.primary.dark,
    flexGrow: 1,
  },
  fileType: {
    minWidth: '100px',
  },
  fileSize: {
    minWidth: '70px',
  },
  attachmentsWrapper: {
    padding: '8px 13px',
    border: '3px dashed #eee',
    backgroundColor: '#fafafa',
  },
  tableWrapper: {
    width: 'calc(100% + 16px + 16px)',
    marginLeft: '-16px',
    '& > *': {
      padding: '0 16px',
      transition: 'background 0.05s',
    },
    '& > :nth-child(odd)': {
      background: '#f5f5f5',
      '&:hover': {
        background: darken(tableOddBg, 0.05),
      },
    },
    '& > :nth-child(even)': {
      background: theme.palette.common.white,
      '&:hover': {
        background: darken(theme.palette.common.white, 0.05),
      },
    },
  },
  loader: {
    width: '38px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notAvailableContainer: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  notAvailableIcon: {
    width: '80px',
    height: '80px',
  },
  fileIsSelected: {
    backgroundColor: 'rgba(65, 83, 175, 0.15) !important',
  },
}));

export { useStyles as default, StyledTableCell, StyledTableRow, AttachTableCell, StyledTableBody };
