import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  IFloatingFilter,
  IFloatingFilterParams,
} from '@ag-grid-community/core/dist/cjs/filter/floating/floatingFilter';
import { MenuItem, Select } from '@material-ui/core';
import useStyles from '../styles';
import { allStatusesMap } from '../../../../constants/language';
import { ColDef } from '@ag-grid-community/all-modules';
import { useIntl } from 'react-intl';

type StatusesMap = ReturnType<typeof allStatusesMap>;

const StatusFloatingFilter = forwardRef(
  (props: IFloatingFilterParams & { statusesMap: StatusesMap }, ref) => {
    const classes = useStyles();
    const [currentValue, setCurrentValue] = useState<null | string>(null);
    const intl = useIntl();

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          // When the filter is empty we will receive a null value here
          if (!parentModel) {
            setCurrentValue(null);
          } else {
            setCurrentValue(parentModel.filter);
          }
        },
      } as IFloatingFilter;
    });

    const onInputBoxChanged = (input: React.ChangeEvent<{ mane?: string; value: unknown }>) => {
      if (input.target.value === '') {
        // clear the filter
        props.parentFilterInstance((instance: unknown) => {
          (instance as IFilterComp)?.onFloatingFilterChanged?.(null, null);
        });
        return;
      }

      setCurrentValue(input.target.value as string);
      props.parentFilterInstance((instance: unknown) => {
        (instance as IFilterComp)?.onFloatingFilterChanged?.('equals', input.target.value);
      });
    };

    const userProvidedColDef = props?.column?.getUserProvidedColDef?.() as ColDef & {
      ['filter-data-cy']: string;
    };
    const dataCy = `status-filter-${
      userProvidedColDef?.['filter-data-cy'] || props?.column?.getColId?.()
    }`;

    return (
      <Select
        data-cy={dataCy}
        className={classes.filterSelectDefault}
        value={currentValue ?? ''}
        disableUnderline
        onChange={(e) => onInputBoxChanged(e)}
      >
        <MenuItem value="">{intl.formatMessage({ id: 'common.none' })}</MenuItem>
        {Object.keys(props.statusesMap).map((statusId) => (
          <MenuItem key={statusId} value={statusId}>
            {props.statusesMap[statusId as keyof StatusesMap]}
          </MenuItem>
        ))}
      </Select>
    );
  },
);

StatusFloatingFilter.displayName = 'StatusFloatingFilter';

export default StatusFloatingFilter;
