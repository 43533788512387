import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useWatch } from 'react-hook-form';
import { getBackendUrl } from '../../../utils/getBackendUrl';
import BooleanInput from '../BooleanInput';
import NoAvailableLogoIcon from '../../icons/no-available-logo';

import useStyles from './styles';

type CompanyLogoPropsType = {
  handleLogoDocumentsStates: () => void;
  companyDocumentsLogoState: boolean;
  brokerLogo?: string; // file name in static folder
};

export default function CompanyLogo({
  handleLogoDocumentsStates,
  companyDocumentsLogoState,
  brokerLogo,
}: CompanyLogoPropsType) {
  const classes = useStyles();
  const intl = useIntl();
  const companyDocumentsLogo = useWatch({
    name: 'companyDocumentsLogo',
    defaultValue: companyDocumentsLogoState,
  });
  const random = React.useMemo(() => Date.now(), []);
  React.useEffect(() => {
    handleLogoDocumentsStates();
  }, [companyDocumentsLogo]);

  return (
    <>
      <div className={classes.container}>
        <Typography data-cy="comp-logo-header" className={classes.header} variant="h2">
          <span>{intl.formatMessage({ id: 'company.company_logo' })}</span>
        </Typography>
        <div className={classes.previewSection}>
          {brokerLogo ? (
            <div
              data-cy="comp-logo-img"
              className={classes.preview}
              style={{
                backgroundImage: `url(${getBackendUrl()}/static/${encodeURI(
                  brokerLogo,
                )}?random=${random})`,
              }}
            />
          ) : (
            <div data-cy="comp-logo-img" className={classes.preview}>
              <NoAvailableLogoIcon viewBox="0 0 56 56" className={classes.noPhotoIcon} />
            </div>
          )}
        </div>
        <Typography className={classes.infoText} variant="subtitle1">
          <span data-cy="comp-logo-disclaimer">
            {intl.formatMessage({ id: 'common.logo_text_info' })}
          </span>
        </Typography>
        <BooleanInput
          data-cy="add-comp-logo-to-document"
          label={intl.formatMessage({ id: 'common.add_logo_to_document' }, { name: 'Company' })}
          name="companyDocumentsLogo"
          disabled={!brokerLogo}
          labelPosition="after"
          size="small"
          color="primary"
          className={classes.checkboxSpan}
        />
      </div>
    </>
  );
}
