import { connect, ConnectedProps } from 'react-redux';

import { actions as userManagementActions } from '../../store/userManagement';
import MyBrokerRepProfile from './my-broker-rep-profile.component';

const mapStateToProps = (state: AppStoreType) => ({
  user: state.user,
});

const mapDispatchToProps = {
  resetUserPass: userManagementActions.resetUserPass,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MyProfileConnectedProps = ConnectedProps<typeof connector>;

export default connector(MyBrokerRepProfile);
