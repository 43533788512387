import * as React from 'react';
import clsx from 'clsx';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { DatePickerView, useUtils } from '@material-ui/pickers';
import { isYearAndMonthViews, isYearOnlyView } from '@material-ui/pickers/_helpers/date-utils';
import PickerToolbar from './PickerToolbar';
import ToolbarButton from './ToobarButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useStyles } from './styles';

export const DatePickerToolbar: React.FC<ToolbarComponentProps> = ({
  date,
  views,
  setOpenView,
  isLandscape,
  openView,
}) => {
  const utils = useUtils();
  const classes = useStyles();

  const isYearOnly = React.useMemo(() => isYearOnlyView(views as DatePickerView[]), [views]);
  const isYearAndMonth = React.useMemo(() => isYearAndMonthViews(views as DatePickerView[]), [
    views,
  ]);

  return (
    <PickerToolbar
      isLandscape={isLandscape}
      className={clsx({
        [classes.toolbar]: !isYearOnly,
        [classes.toolbarLandscape]: isLandscape,
      })}>
      <ToolbarButton
        variant="h4"
        onClick={() => setOpenView('year')}
        selected={openView === 'year'}
        label={`
          ${utils.getYearText(date)}`}>
        <KeyboardArrowDownIcon
          fontSize="large"
          className={clsx(classes.yearArrowDown, {
            [classes.yearArrowDownActive]: openView === 'year',
          })}
        />
      </ToolbarButton>

      {!isYearOnly && !isYearAndMonth && (
        <ToolbarButton
          variant="h4"
          selected={openView === 'date'}
          onClick={() => setOpenView('date')}
          align={isLandscape ? 'left' : 'center'}
          label={utils.getDatePickerHeaderText(date)}
          className={clsx({ [classes.dateLandscape]: isLandscape })}
        />
      )}

      {isYearAndMonth && (
        <ToolbarButton
          variant="h4"
          onClick={() => setOpenView('month')}
          selected={openView === 'month'}
          label={utils.getMonthText(date)}
        />
      )}
    </PickerToolbar>
  );
};
