import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbarWrapper: {
    paddingLeft: '60px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '40px',
    },
    '& .langSwitcherSignIn': {
      marginRight: '10px',
      borderLeft: 'none',
    },
  },
  languageHolder: {
    languageSwitcher: {
      borderLeft: 0,
    },
  },
}));
