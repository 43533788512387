import React from 'react';
import useStyles from './styles';
import Cancel from '@material-ui/icons/Cancel';
import AddCircle from '@material-ui/icons/AddCircle';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select,
  TextField,
  Grid,
  Divider,
} from '@material-ui/core';
import { EMAIL_OPTIONS } from '../../../constants/formValues';
import { useIntl } from 'react-intl';
import { UseFormMethods } from 'react-hook-form';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { RECIPIENT_TYPE } from '../QuoteEmail';
import clsx from 'clsx';

export type ApplicationDocumentEmailType = {
  uuid: string;
  email: string;
  type: RECIPIENT_TYPE;
  isRemovable: boolean;
};

type ApplicationDocumentEmailPropType = {
  title?: string;
  emailList: ApplicationDocumentEmailType[];
  onAddRecipient: () => unknown;
  onDeleteRecipient: (email: ApplicationDocumentEmailType) => unknown;
  onEmailPropsChange: (
    id: ApplicationDocumentEmailType['uuid'],
    props: Partial<ApplicationDocumentEmailType>,
  ) => unknown;
  emailSubject: string;
  onSubjectPropChange: (value: string) => unknown;
  closeEmailModal: () => unknown;
  emailBody: string;
  onBodyPropChange: (value: string) => unknown;
  openPreview: () => unknown;
  control: UseFormMethods['control'];
  documentFileName: string;
};

export default function ApplicationDocumentEmail({
  emailList,
  onAddRecipient,
  onDeleteRecipient,
  onEmailPropsChange,
  emailSubject,
  onSubjectPropChange,
  closeEmailModal,
  emailBody,
  onBodyPropChange,
  openPreview,
  documentFileName,
}: //control,
ApplicationDocumentEmailPropType) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
      <div className={classes.layout}>
        <Typography variant="h6" data-cy="send-quote-title" gutterBottom className={classes.label}>
          {intl.formatMessage({ id: 'application.document_send_to' })}
        </Typography>
        <Grid container alignItems="flex-start">
          <Table aria-label="customized table" size="small">
            <thead>
              <tr>
                <td style={{ width: '20px' }} />
                <td style={{ width: '400px' }} />
                <td style={{ width: '20px' }} />
              </tr>
            </thead>
            <TableBody>
              {emailList.map((email) => (
                <TableRow key={email.uuid}>
                  <TableCell size="small" padding="none">
                    <Select
                      name={`recipientType-${email.uuid}`}
                      id={`recipientType-${email.uuid}`}
                      value={email.type}
                      style={{ top: '-2px' }}
                      required
                      onChange={(e) =>
                        onEmailPropsChange(email.uuid, {
                          type: e.target.value as RECIPIENT_TYPE,
                        })
                      }
                    >
                      {EMAIL_OPTIONS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell size="medium" padding="none">
                    <TextField
                      fullWidth
                      id={`emailId-${email.uuid}`}
                      name={`emailId-${email.uuid}`}
                      defaultValue={email.email}
                      disabled={!email.isRemovable}
                      size="small"
                      type="email"
                      required
                      onBlur={(e) => onEmailPropsChange(email.uuid, { email: e.target.value })}
                    />
                  </TableCell>
                  <TableCell size="small" padding="none">
                    {email.isRemovable && (
                      <IconButton
                        color="primary"
                        aria-label={intl.formatMessage({ id: 'common.remove_recipient' })}
                        component="span"
                        disabled={emailList.length === 1}
                        onClick={() => onDeleteRecipient(email)}
                      >
                        <Cancel />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid className={classes.actionsBlock} container alignItems="flex-start">
          <Grid item xs={12} sm={6}>
            <Button
              data-cy="add-recipient-button"
              type="submit"
              variant="outlined"
              color="primary"
              size="small"
              onClick={onAddRecipient}
              startIcon={<AddCircle />}
            >
              {intl.formatMessage({ id: 'email.add_recipient' })}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.fieldItem}>
            <TextField
              id="subject"
              name="subject"
              label={intl.formatMessage({ id: 'email.subject' })}
              value={emailSubject}
              fullWidth
              required
              variant="outlined"
              onChange={(e) => onSubjectPropChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldItem}>
            <TextField
              id="message"
              name="message"
              label={intl.formatMessage({ id: 'email.message' })}
              value={emailBody}
              fullWidth
              required
              multiline
              rows={4}
              variant="outlined"
              onChange={(e) => onBodyPropChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldItem}>
            <Typography variant="h6" gutterBottom className={classes.label}>
              {intl.formatMessage({ id: 'common.attachments' })}
            </Typography>
            <PostAddIcon style={{ verticalAlign: 'middle' }} />
            <a href="#" onClick={openPreview}>
              {documentFileName}
            </a>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12} className={clsx(classes.actionsBlock, classes.textRight)}>
          <Button type="submit" color="primary" variant="contained" className={classes.actionBtn}>
            {intl.formatMessage({ id: 'button.send' })}
          </Button>
          <Button
            onClick={closeEmailModal}
            color="default"
            variant="contained"
            className={classes.actionBtn}
          >
            {intl.formatMessage({ id: 'button.close' })}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
