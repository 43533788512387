import React, { useState, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useStyles from './styles';
import { Paper, Grid, Button } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import InstantQuoteForm from '../../components/common/InstantQuoteForm';
import QuoteTable from '../../components/common/QuoteTable';
import QuoteHistory from '../../components/common/QuoteHistory';
import Loading from '../../components/common/Loading';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import QuoteEmail, { QuoteEmailType, RECIPIENT_TYPE } from '../../components/common/QuoteEmail';
import { v4 as uuidv4 } from 'uuid';
import ModalBox from '../../components/common/modal';
import EmailPreviewModal from '../../components/common/email-preview-modal';
import EmailTemplateDialog from '../../components/common/email-template-dialog';
import clsx from 'clsx';
import { InstantQuoteConnectedPropsType } from './instant-quote.container';
import {
  QUOTE_TO_APPLICATION_PAGE,
  INSTANT_QUOTE_PAGE,
  EDIT_DRAFT_APPLICATION_PAGE,
  APPLICATION_PAGE,
} from '../../constants/navigations';
import DropDown from '../../components/common/dropdown';
import { Dropzone } from '../../components/common/VaultDropzone';
import { useIntl } from 'react-intl';
import { Recipients } from '../../apis/email';
import { getQuoteStatusesMap } from '../../constants/language';
import { CURRENCY_TYPE, defaultCurrency } from '../../constants/currency';
import Link from '../../components/common/link';
import CurrencyFormat from '../../components/common/currency-format';
import Attachments from '../../components/common/Attachments';
import getDefaultRenderer from '../../components/common/Attachments/defaultRenderer';
import AttachFilesModal from '../../components/common/attach-files-modal';

export type InstantQuoteFormType = {
  [key in keyof QuoteType | 'emailTemplate']: string;
};

type EmailPreview = {
  previewEmailSubject: string;
  previewEmailBody: string;
};

function InstantQuote({
  vendorId,
  vendorUuid,
  loading,
  vendorProgramList,
  quoteInfo,
  emailPreview,
  emailTemplates,
  vendorReps,
  getVendorReps,
  generateInstantQuote,
  updateInstantQuote,
  getVendorProgramList,
  getQuoteInfo,
  getAllEmailTemplates,
  getVendorSettings,
  generatedQuotes,
  lastFollowUpDate,
  openToast,
  cleanState,
  files,
  application,
  selectedPaymentOption,
  addFilesToQuote,
  removeFileFromQuote,
  sendQuoteDetailsEmail,
  sendQuoteFollowUps,
  previewQuoteDetailsEmail,
  previewQuoteFollowUp,
  updateEmailTemplateList,
  isAdmin,
}: InstantQuoteConnectedPropsType) {
  const { id: quoteUuid } = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const [emailList, setEmailList] = useState<QuoteEmailType[]>([]);
  const [selectedFiles, setSelectedFiles] = React.useState<string[]>([]);
  const quoteHtmlData = useRef();
  const [openPOSelection, setOpenPOSelection] = useState(false);
  const [poSelectionList, setPoSelectionList] = React.useState<QuotePaymentOption[]>([]);
  const [previewEmail, setPreviewEmail] = useState(false);
  const [currency, setCurrency] = useState<CURRENCY_TYPE>(defaultCurrency.name as CURRENCY_TYPE);
  const [lastGeneratedQuote, setLastGeneratedQuote] = React.useState<
    GetInstantQuoteResponse['generatedQuotes'][number] | null
  >(null);
  const [attachFilesModal, setAttachFilesModal] = useState({
    state: false,
    context: '',
    emailPreview: {
      previewEmailSubject: '',
      previewEmailBody: '',
    },
  });

  //Follow up related states
  const [openFollowUpModal, setOpenFollowUpModal] = useState(false);
  const [followUpTemplateId, setFollowUpTemplateId] = useState('');
  const [openTemplateSelection, setOpenTemplateSelection] = React.useState(false);
  const [followUpEmails, setFollowUpEmails] = useState<Recipients>([]);
  const attachmentRenderer = React.useCallback(
    getDefaultRenderer({
      title: intl.formatMessage({ id: 'quote.attachments' }),
    }),
    [],
  );

  const formMethods = useForm<InstantQuoteFormType & { equipmentCost: number }>({
    defaultValues: {
      vendorRep: '',
    },
  });
  const { handleSubmit, setValue, getValues, reset } = formMethods;

  const quoteStatusMap = getQuoteStatusesMap(intl);

  const instantQuoteState = {
    // when app does not exists and quote is not unsubscribed
    isQuoteInfoEditable: () =>
      !application?.id && !['quoteCustomerUnsubscribed'].includes(quoteInfo.status),
    // when quote emailed and there is no linked application
    isSendFollowUpPossible: () => !application?.id && ['quoteEmailed'].includes(quoteInfo.status),
    // when generated or emailed and there is no application or it has a draft status
    isPOTableEditable: () =>
      ['quoteGenerated', 'quoteEmailed'].includes(quoteInfo.status) &&
      (!application?.status || application.status === 'applicationDraft'),
    // when there is no application and quote is in generated or emailed statuses
    isEmailingPossible: () =>
      !application?.id && ['quoteGenerated', 'quoteEmailed'].includes(quoteInfo.status),
    // when there is no application and quote is in generated or emailed statuses
    isFilesEditable: () =>
      !application?.id && ['quoteGenerated', 'quoteEmailed'].includes(quoteInfo.status),
    // always visible
    isQuoteHistoryVisible: () => true,
  };

  const followUpTemplates = React.useMemo(() => {
    return emailTemplates.filter((t: EmailTemplateListType) => t.relatedTo === 'quoteFollowup');
  }, [emailTemplates]);

  const quoteDetailsTemplates = React.useMemo(() => {
    return emailTemplates.filter((t: EmailTemplateListType) => t.relatedTo === 'quoteDetails');
  }, [emailTemplates]);

  const resetQuote = () => {
    reset();
    if (vendorProgramList?.length) {
      setTimeout(() => {
        setValue(
          'vendorProgram',
          vendorProgramList.find(
            (p: { value: string; label: string; isDefault: boolean }) => p.isDefault,
          )?.value ?? '',
        );
        setValue(
          'vendorRep',
          vendorReps.find((p: { value: string; label: string; isDefault?: boolean }) => p.isDefault)
            ?.value ??
            vendorReps[0].value ??
            '',
        );
      });
    }
  };

  useEffect(() => {
    if (quoteUuid) {
      getQuoteInfo(quoteUuid);
    } else {
      cleanState();
      resetQuote();
    }
  }, [quoteUuid]);

  useEffect(() => {
    if (!quoteInfo.vendorProgram && Array.isArray(vendorProgramList)) {
      setValue(
        'vendorProgram',
        vendorProgramList.find(
          (p: { value: string; label: string; isDefault: boolean }) => p.isDefault,
        )?.value ?? '',
      );
    }
  }, [vendorProgramList, quoteInfo.vendorProgram]);

  useEffect(() => {
    if (!quoteInfo.vendorRep) {
      setValue(
        'vendorRep',
        vendorReps?.find((p: { value: string; label: string; isDefault?: boolean }) => p.isDefault)
          ?.value ??
          vendorReps[0]?.value ??
          '',
      );
    }
  }, [vendorReps, quoteInfo.vendorRep]);

  useEffect(() => {
    getAllEmailTemplates({ relatedToObj: ['quoteFollowup', 'quoteDetails'] });
    getVendorReps();
  }, []);

  useEffect(() => {
    getVendorSettings();
    getVendorProgramList();
  }, [vendorId]);

  useEffect(
    () => () => {
      cleanState();
      updateEmailTemplateList([]);
    },
    [],
  );

  useEffect(() => {
    const lastGeneratedQuoteFromList = generatedQuotes[0];
    if (lastGeneratedQuoteFromList && instantQuoteState.isPOTableEditable()) {
      setLastGeneratedQuote(lastGeneratedQuoteFromList);
    } else {
      setLastGeneratedQuote(null);
    }
    if (quoteInfo?.equipmentCostCurrencyType) {
      setCurrency(quoteInfo.equipmentCostCurrencyType as CURRENCY_TYPE);
    }
  }, [quoteInfo, generatedQuotes]);

  useEffect(() => {
    const keys = Object.keys(quoteInfo ?? {}) as Array<keyof QuoteType>;
    keys.forEach((key) => {
      setValue(key, (quoteInfo as QuoteType)[key]);
    });
  }, [quoteInfo, vendorProgramList, setValue]);

  useEffect(() => {
    //Populate email list with customer's email
    if (quoteInfo && instantQuoteState.isEmailingPossible()) {
      const emails = [];

      if (generatedQuotes[0].emails.length && generatedQuotes[0].emails[0].recipients) {
        for (const recipient of generatedQuotes[0].emails[0].recipients) {
          emails.push({
            uuid: uuidv4(),
            email: recipient.email,
            name: recipient.fullName ?? '',
            isSelected: true,
            type: recipient.type,
            isRemovable: true,
          });
        }
      } else {
        emails.push({
          uuid: uuidv4(),
          email: '',
          name: '',
          isSelected: true,
          type: RECIPIENT_TYPE.TO,
          isRemovable: true,
        });
      }

      setEmailList(emails);
    }
  }, [quoteInfo]);

  const getStatusLabel = (statusId: string): string => {
    if (statusId in quoteStatusMap) {
      return quoteStatusMap[statusId as keyof typeof quoteStatusMap];
    }

    return '';
  };

  const handleOpenPOSelection = () => {
    if (!getValues('emailTemplate')) {
      openToast({
        message: intl.formatMessage({ id: 'user.select_email_template_first"' }),
        toastType: 'error',
      });
      return;
    }
    if (lastGeneratedQuote?.quotePaymentOptions) {
      setOpenPOSelection(true);
    }
  };

  const togglePaymentOptionSelection = (paymentOption: QuotePaymentOption) => {
    if (poSelectionList.find((po) => po.uuid === paymentOption.uuid)) {
      setPoSelectionList(poSelectionList.filter((po) => po.uuid !== paymentOption.uuid));
    } else {
      setPoSelectionList([...poSelectionList, paymentOption]);
    }
  };

  const handleClosePOSelection = () => {
    setOpenPOSelection(false);
  };

  const handleCloseEmailPreview = () => {
    setPreviewEmail(false);
  };

  const handlePaymentOptionSelection = async (selectedPaymentOption: { uuid: string }) => {
    history.push(
      generatePath(QUOTE_TO_APPLICATION_PAGE.path, {
        paymentOptionUuid: selectedPaymentOption.uuid,
      }),
    );
  };

  const handleSubmitQuote: Parameters<typeof handleSubmit>[0] = async (values) => {
    const { vendorProgram, vendorRep } = values;

    const vProgramId = vendorProgram || quoteInfo?.vendorProgram || vendorProgramList[0].value;

    const newQuoteInfo = {
      ...values,
      equipmentCost: values.equipmentCost,
      vendorRep: vendorRep ?? quoteInfo?.vendorRep,
      vendorProgram: vProgramId,
      equipmentCostCurrencyType: currency,
    };
    const quoteRequest: CustomerInfo = {
      vendorProgram: vProgramId,
      vendorId: vendorUuid,
      quoteInfo: newQuoteInfo,
    };

    if (quoteUuid) {
      // regenerating existing one
      await updateInstantQuote(quoteRequest, quoteUuid);
      await getQuoteInfo(quoteUuid);
    } else {
      // creating new quote
      const createdQuoteUuid = await generateInstantQuote(quoteRequest);

      if (createdQuoteUuid) {
        history.push(generatePath(INSTANT_QUOTE_PAGE.path, { id: createdQuoteUuid }));
      }
    }
  };

  const onAddRecipient = () => {
    setEmailList([
      ...emailList,
      {
        uuid: uuidv4(),
        email: '',
        name: '',
        isSelected: true,
        type: RECIPIENT_TYPE.CC,
        isRemovable: true,
      },
    ]);
  };

  const onDeleteRecipient = (email: typeof emailList[number]) => {
    setEmailList(emailList.filter((e) => e.uuid !== email.uuid));
  };

  const onEmailPropsChange = (id: string, changes: Partial<QuoteEmailType>) => {
    const updatedList = emailList.map((e) => {
      if (e.uuid === id) {
        return { ...e, ...changes };
      }

      return e;
    });

    return setEmailList(updatedList);
  };

  //Send quick email with quote details without preview
  const handleSendQuoteEmail = async () => {
    if (!getValues('emailTemplate')) {
      openToast({
        message: intl.formatMessage({ id: 'user.please_select_email_template' }),
        toastType: 'error',
      });
      return;
    }
    if (!attachFilesModal.state && files.length) {
      return handleOpenAttachFilesModal('quoteEmail');
    }
    if (lastGeneratedQuote) {
      const res = await sendQuoteDetailsEmail(
        {
          recipients: getSelectedEmailList(),
          attachments: selectedFiles,
          email: {
            emailTemplateUuid: getValues('emailTemplate'),
          },
        },
        lastGeneratedQuote.uuid,
      );

      if (res) {
        await getQuoteInfo(quoteUuid);
      }
    }
  };

  const getSelectedEmailList = () => emailList.filter((e) => e.isSelected);

  // To preview the Email with quote details
  const openPreviewQuoteEmail = async () => {
    if (!getValues('emailTemplate')) {
      openToast({
        message: intl.formatMessage({ id: 'user.select_email_template_first' }),
        toastType: 'error',
      });
      return;
    }
    if (lastGeneratedQuote) {
      await previewQuoteDetailsEmail(
        lastGeneratedQuote.uuid,
        getValues('emailTemplate'),
        undefined,
        emailList,
      );
    }

    setPreviewEmail(true);
  };

  //TO send email from the email preview modal
  const handleSendEmailFromPreview = async (
    previewEmailSubject: string,
    previewEmailBody: string,
    isFollowUp = false,
  ) => {
    if (!attachFilesModal.state && files.length && !isFollowUp) {
      setPreviewEmail(false);
      return handleOpenAttachFilesModal('emailPreview', { previewEmailSubject, previewEmailBody });
    }
    if (emailPreview!.relatedTo === 'quoteDetails') {
      const res = await sendQuoteDetailsEmail(
        {
          email: {
            subject: previewEmailSubject,
            body: previewEmailBody,
          },
          recipients: getSelectedEmailList(),
          attachments: selectedFiles,
          selectedPO: poSelectionList.map((po) => po.uuid),
        },
        lastGeneratedQuote!.uuid,
      );
      if (res) {
        setPreviewEmail(false);
        await getQuoteInfo(quoteUuid);
      }
    } else if (emailPreview!.relatedTo === 'quoteFollowup') {
      const res = await sendQuoteFollowUps({
        quotes: [
          {
            uuid: quoteUuid,
            email: {
              subject: previewEmailSubject,
              body: previewEmailBody,
            },
            recipients: followUpEmails,
          },
        ],
      });

      if (res) {
        closeFollowUpModal();
        await getQuoteInfo(quoteUuid);
      }
    }
  };

  //Method to preview quote details with selected POs.
  const previewSelectedPOEmail = () => {
    if (!poSelectionList.length) {
      openToast({
        message: intl.formatMessage({ id: 'user.select_email_po_first' }),
        toastType: 'error',
      });

      return;
    }
    if (lastGeneratedQuote) {
      const recipients = getSelectedEmailList();
      previewQuoteDetailsEmail(
        lastGeneratedQuote.uuid,
        getValues('emailTemplate'),
        poSelectionList.map((po) => po.uuid),
        recipients,
      );
      //Close Po selection modal.
      setOpenPOSelection(false);
      if (!loading) {
        setPreviewEmail(true); //Open Preview modal
      }
    }
  };

  //Method to send selected POs.
  const sendSelectedPOEmail = async () => {
    if (!poSelectionList.length) {
      openToast({
        message: intl.formatMessage({ id: 'user.select_email_po_first' }),
        toastType: 'error',
      });

      return;
    }
    if (!getValues('emailTemplate')) {
      openToast({
        message: intl.formatMessage({ id: 'user.select_email_template_first' }),
        toastType: 'error',
      });
      return;
    }
    if (!attachFilesModal.state && files.length) {
      setOpenPOSelection(false);
      return handleOpenAttachFilesModal('paymentOptions');
    }
    if (lastGeneratedQuote) {
      const res = await sendQuoteDetailsEmail(
        {
          recipients: getSelectedEmailList(),
          email: {
            emailTemplateUuid: getValues('emailTemplate'),
          },
          attachments: selectedFiles,
          selectedPO: poSelectionList.map((po) => po.uuid),
        },
        lastGeneratedQuote.uuid,
      );

      if (res) {
        setOpenPOSelection(false);
        await getQuoteInfo(quoteUuid);
      }
    }
  };

  const handleQuickSendFollowUp = async () => {
    const defaultQuote = generatedQuotes.find((quote) => {
      if (quote.isEmailed) {
        return true;
      }
    });
    const defaultTemplate = followUpTemplates.find((template: EmailTemplateListType) => {
      if (template.language === defaultQuote?.quoteLanguage) {
        return true;
      }
    });

    if (!defaultQuote || !defaultTemplate?.value) {
      return;
    }

    await sendQuoteFollowUps({
      quotes: [
        {
          uuid: quoteUuid,
          email: {
            emailTemplateUuid: defaultTemplate.value,
          },
          recipients: emailList,
        },
      ],
    });
    getQuoteInfo(quoteUuid);
  };

  const closeFollowUpModal = () => {
    setOpenFollowUpModal(false);
  };

  const handleOpenAttachFilesModal = (context: string, emailPreview?: EmailPreview) => {
    const emailPreviewData: EmailPreview = {
      previewEmailSubject: emailPreview?.previewEmailSubject || '',
      previewEmailBody: emailPreview?.previewEmailBody || '',
    };
    return setAttachFilesModal({
      state: true,
      context: context,
      emailPreview: emailPreviewData,
    });
  };

  const handleSendAttachFilesModal = () => {
    handleCloseAttachmentsFiles();
    switch (attachFilesModal.context) {
      case 'quoteEmail':
        return handleSendQuoteEmail();
      case 'paymentOptions':
        return sendSelectedPOEmail();
      case 'emailPreview':
        const { previewEmailBody, previewEmailSubject } = attachFilesModal.emailPreview;
        return handleSendEmailFromPreview(previewEmailSubject, previewEmailBody);
      default:
        return;
    }
  };

  const handleCloseAttachmentsFiles = () => {
    setAttachFilesModal({
      state: false,
      context: '',
      emailPreview: { previewEmailSubject: '', previewEmailBody: '' },
    });
    setSelectedFiles([]);
  };

  const handleTemplateClickOpen = () => {
    setOpenTemplateSelection(true);
  };

  // follow up template choose
  const handleTemplateChoose = async (template?: { value: string }) => {
    setOpenTemplateSelection(false);

    if (template && template.value) {
      const quoteHistory = generatedQuotes.find((generatedQuote) => {
        return generatedQuote.isEmailed;
      });
      if (!quoteHistory || !quoteHistory.emails.length) {
        return;
      }
      setFollowUpTemplateId(template.value);
      const emailList = [];
      emailList.push({
        email: quoteHistory.emails[0].recipients[0].email ?? '',
        type: RECIPIENT_TYPE.TO,
      });

      await previewQuoteFollowUp(quoteUuid, template.value);

      setFollowUpEmails(emailList);
      setOpenFollowUpModal(true);
    }
  };

  const handleFollowUpEmailChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newFollowUpEmails = followUpEmails.map((email) => {
      if (email.type === evt.target.id) {
        email.email = evt.target.value;
      }
      return email;
    });
    setFollowUpEmails(newFollowUpEmails);
  };

  const applicationLink = application?.uuid
    ? generatePath(
        (quoteInfo?.status === 'quoteApplicationSubmitted'
          ? APPLICATION_PAGE
          : EDIT_DRAFT_APPLICATION_PAGE
        ).path,
        {
          id: application.uuid,
          applicationUuid: application.uuid,
        },
      )
    : '';

  return (
    <FormProvider {...formMethods}>
      <Dropzone>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Paper className={classes.paper} variant="outlined">
              <React.Fragment>
                {!isEmpty(quoteInfo) && (
                  <React.Fragment>
                    <Grid container spacing={2}>
                      {quoteInfo?.status ? (
                        <Grid item xs={12} sm={6}>
                          <h3 data-cy="quote-status-header" className={classes.textAlignLeft}>
                            {intl.formatMessage({ id: 'common.status' })}:{' '}
                            {getStatusLabel(quoteInfo.status)}
                          </h3>
                          {lastFollowUpDate && (
                            <h3 data-cy="quote-id-header" className={classes.dateLabel}>
                              {intl.formatMessage({ id: 'quote.last_follow_up_date' })}:{' '}
                              {lastFollowUpDate}
                            </h3>
                          )}
                          <h3 data-cy="quote-id-header" className={classes.textAlignLeft}>
                            Id: {quoteInfo.idLabel}
                          </h3>
                          {application?.id ? (
                            <h3 data-cy="application-id-header" className={classes.textAlignLeft}>
                              {intl.formatMessage({ id: 'quote.application_name' })}:{' '}
                              <Link to={applicationLink}>{application.name}</Link>
                            </h3>
                          ) : null}
                        </Grid>
                      ) : null}
                      {quoteInfo?.status === 'quoteEmailed' && (
                        <Grid item xs={12} sm={6} className={classes.followUpButtonContainer}>
                          <DropDown
                            options={[
                              {
                                label: intl.formatMessage({ id: 'user.quick_follow_up' }),
                                handler: handleQuickSendFollowUp,
                              },
                              {
                                label: intl.formatMessage({ id: 'user.preview_follow_up' }),
                                handler: handleTemplateClickOpen,
                              },
                            ]}
                          >
                            <Button
                              data-cy="follow-up-button"
                              variant="outlined"
                              color="primary"
                              className={classes.followUpButton}
                              aria-controls="menu-list-grow"
                              aria-haspopup="true"
                              endIcon={<Send />}
                            >
                              {intl.formatMessage({ id: 'button.follow_up' })}
                            </Button>
                          </DropDown>
                        </Grid>
                      )}
                    </Grid>
                    {selectedPaymentOption && (
                      <Grid container spacing={0} alignItems="flex-start">
                        <Grid item xs={12} sm={12}>
                          <h3>{intl.formatMessage({ id: 'quote.selected_option' })}:</h3>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {intl.formatMessage({ id: 'common.amount' })}:&nbsp;
                          <CurrencyFormat
                            value={selectedPaymentOption.amount}
                            currencyType={currency}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {intl.formatMessage({ id: 'application.term' })}:{' '}
                          {selectedPaymentOption.term}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {intl.formatMessage({ id: 'quote.purchase_option' })}:{' '}
                          {selectedPaymentOption.purchaseOption}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {intl.formatMessage({ id: 'application.purchase_option_amount' })}:&nbsp;
                          <CurrencyFormat
                            value={selectedPaymentOption.purchaseOptionAmount}
                            currencyType={currency}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </React.Fragment>
                )}

                <form
                  noValidate
                  onSubmit={handleSubmit(handleSubmitQuote)}
                  className={classes.formContainer}
                >
                  <InstantQuoteForm
                    isAdmin={isAdmin}
                    vendorReps={vendorReps}
                    vendorProgramList={vendorProgramList}
                    currency={currency}
                    setCurrency={setCurrency}
                    reset={resetQuote}
                    isReadOnly={!instantQuoteState.isQuoteInfoEditable()}
                    isPublic={false}
                  />
                </form>
              </React.Fragment>
            </Paper>

            {loading && (
              <Paper className={clsx(classes.paper, classes.smallMarginTop)}>
                <Loading isOpen={loading} />
              </Paper>
            )}

            {lastGeneratedQuote && instantQuoteState.isPOTableEditable() && (
              <Paper
                data-cy="quote-table"
                className={clsx(classes.paper, classes.smallMarginTop)}
                ref={quoteHtmlData}
              >
                <>
                  <QuoteTable
                    title={intl.formatMessage({ id: 'quote.quote_table' })}
                    terms={JSON.parse(lastGeneratedQuote.terms)}
                    purchaseOptions={JSON.parse(lastGeneratedQuote.purchaseOptions)}
                    skipPayment={lastGeneratedQuote.skipAmount}
                    skipMonth={lastGeneratedQuote.skipMonth}
                    quotePaymentOptions={lastGeneratedQuote.quotePaymentOptions}
                    currency={lastGeneratedQuote.leaseAmountCurrencyType}
                    selectedPaymentOptions={[selectedPaymentOption?.uuid ?? '']}
                    handleQuoteDataUpdate={handlePaymentOptionSelection}
                  />
                  {!!lastGeneratedQuote.quoteRateCardConditions?.length && (
                    <ul className={classes.conditionsList}>
                      {lastGeneratedQuote.quoteRateCardConditions.map((condition) => (
                        <li key={condition.id}>
                          <span className={classes.conditionName}>
                            {intl.locale.toLowerCase() === 'en' ? condition.name : condition.nameFr}
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.rightBlockPadding}>
            {lastGeneratedQuote && instantQuoteState.isEmailingPossible() && (
              <>
                <Paper data-cy="send-quote">
                  <QuoteEmail
                    emailList={emailList}
                    onAddRecipient={onAddRecipient}
                    onDeleteRecipient={onDeleteRecipient}
                    onEmailPropsChange={onEmailPropsChange}
                    emailTemplateList={quoteDetailsTemplates}
                  />
                </Paper>
                <Attachments
                  addFiles={(files, onProgress) => addFilesToQuote(quoteUuid, files, onProgress)}
                  removeFile={(file) => removeFileFromQuote(quoteUuid, file)}
                  files={files}
                  renderer={attachmentRenderer}
                />
                <div>
                  <DropDown
                    options={[
                      {
                        label: intl.formatMessage({ id: 'quote.include_all_payment_options' }),
                        handler: handleSendQuoteEmail,
                      },
                      {
                        label: intl.formatMessage({ id: 'quote.include_specific_payment_options' }),
                        handler: handleOpenPOSelection,
                      },
                    ]}
                  >
                    <Button
                      data-cy="send-quote-button"
                      variant="outlined"
                      color="primary"
                      className={clsx(classes.button, classes.smallMarginRight)}
                      aria-controls="menu-list-grow"
                      aria-haspopup="true"
                      size="small"
                      endIcon={<Send />}
                    >
                      {intl.formatMessage({ id: 'quote.send_quote' })}
                    </Button>
                  </DropDown>
                  <Button
                    data-cy="preview-email-button"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    size="small"
                    onClick={openPreviewQuoteEmail}
                  >
                    {intl.formatMessage({ id: 'quote.preview_email' })}
                  </Button>
                </div>
              </>
            )}

            {generatedQuotes.length > 0 && instantQuoteState.isQuoteHistoryVisible() && (
              <Paper data-cy="quote-history" className={clsx(classes.paper, classes.marginLeftSm)}>
                <QuoteHistory
                  title={intl.formatMessage({ id: 'quote.quote_history' })}
                  selectedGeneratedQuote={selectedPaymentOption?.generatedQuoteUuid}
                  vendorProgramList={vendorProgramList}
                  quoteHistory={generatedQuotes}
                  selectedPaymentOption={selectedPaymentOption?.uuid}
                />
              </Paper>
            )}
          </Grid>
        </Grid>

        <ModalBox
          isOpen={openPOSelection}
          header={intl.formatMessage({ id: 'quote.select_payment_options' })}
          body={
            openPOSelection ? (
              <QuoteTable
                title={intl.formatMessage({ id: 'quote.quote_table' })}
                terms={JSON.parse(lastGeneratedQuote?.terms ?? '{}')}
                purchaseOptions={JSON.parse(lastGeneratedQuote?.purchaseOptions ?? '{}')}
                skipPayment={lastGeneratedQuote?.skipAmount}
                skipMonth={lastGeneratedQuote?.skipMonth}
                currency={lastGeneratedQuote?.leaseAmountCurrencyType}
                quotePaymentOptions={lastGeneratedQuote?.quotePaymentOptions ?? []}
                selectedPaymentOptions={poSelectionList.map((p) => p.uuid)}
                handleQuoteDataUpdate={togglePaymentOptionSelection}
              />
            ) : null
          }
          handleClose={handleClosePOSelection}
          actions={
            <Grid>
              <Button
                data-cy="send-button"
                color="primary"
                className={classes.button}
                onClick={sendSelectedPOEmail}
              >
                {intl.formatMessage({ id: 'button.send' })}
              </Button>
              <Button
                data-cy="preview-button"
                color="primary"
                className={classes.button}
                onClick={previewSelectedPOEmail}
              >
                {intl.formatMessage({ id: 'button.preview' })}
              </Button>
            </Grid>
          }
          dataCy="select-payment-options-modal"
        />

        {previewEmail && emailPreview && (
          <EmailPreviewModal
            header={emailPreview.subject}
            body={emailPreview.body}
            isOpen
            isFollowUp={false}
            handleClose={handleCloseEmailPreview}
            isBodyEditable
            onSendClick={handleSendEmailFromPreview}
          />
        )}

        {followUpTemplates.length > 0 && (
          <EmailTemplateDialog
            templateList={followUpTemplates}
            selectedValue={followUpTemplateId}
            open={openTemplateSelection}
            onClose={handleTemplateChoose}
          />
        )}

        {openFollowUpModal && emailPreview && (
          <EmailPreviewModal
            header={emailPreview.subject}
            body={emailPreview.body}
            isOpen
            handleClose={closeFollowUpModal}
            isFollowUp
            isBodyEditable
            onSendClick={handleSendEmailFromPreview}
            emailList={followUpEmails}
            handleEmailChange={handleFollowUpEmailChange}
          />
        )}

        {attachFilesModal.state && (
          <AttachFilesModal
            files={files}
            isOpen
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            handleSend={handleSendAttachFilesModal}
            handleClose={handleCloseAttachmentsFiles}
          />
        )}
      </Dropzone>
    </FormProvider>
  );
}

export default InstantQuote;
