import React from 'react';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  SelectProps,
  FormHelperText,
} from '@material-ui/core';
import { Controller, UseControllerOptions, useFormContext } from 'react-hook-form';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { translateLabel } from '../../../utils/translateLabel';

type VaultMenuPropsType = {
  name: string;
  label: string;
  options: Array<{ value: string | number | boolean; label: string | number }>;
  rules?: UseControllerOptions['rules'];
  defaultValue?: string;
  required?: boolean;
  inputRef?: React.Ref<unknown>;
  onChange?: SelectProps['onChange'];
  value?: string;
  disabled?: boolean;
  helperText?: string;
} & Partial<SelectProps>;

const VaultMenu = ({
  name,
  label,
  options,
  defaultValue,
  required,
  inputRef,
  onChange,
  value,
  disabled,
  rules,
  helperText,
  ...props
}: VaultMenuPropsType) => {
  const labelId = `${name}-label`;
  const classes = useStyles();
  const intl = useIntl();
  const { control, errors } = useFormContext();
  const error = get(errors, name);

  return (
    <FormControl variant="standard" className={classes.dropdown} error={Boolean(error)}>
      <InputLabel required={required} id={labelId}>
        {label}
      </InputLabel>
      {control ? (
        <Controller
          render={({ onChange: controllerOnChange, value: controlledValue }) => (
            <Select
              name={name}
              required={required}
              labelId={labelId}
              label={translateLabel(intl, label as string)}
              value={controlledValue ?? ''}
              className={classes.dropdown}
              onChange={onChange ?? controllerOnChange}
              defaultValue={defaultValue !== undefined ? defaultValue : ''}
              disabled={disabled}
              {...props}
            >
              {options ? (
                options.map((option) => (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore used to handle case with boolean value
                  <MenuItem key={option.value?.toString()} value={option.value}>
                    {translateLabel(intl, option.label as string)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key="none" value="none">
                  {intl.formatMessage({ id: 'common.none' })}
                </MenuItem>
              )}
            </Select>
          )}
          name={name}
          control={control}
          defaultValue={defaultValue !== undefined ? defaultValue : ''}
          rules={{ required, ...rules }}
        />
      ) : (
        <Select
          ref={inputRef}
          label={translateLabel(intl, label as string)}
          labelId={labelId}
          value={value}
          required={required}
          defaultValue={defaultValue}
          className={classes.dropdown}
          name={name}
          disabled={disabled}
          onChange={onChange}
          {...props}
        >
          {options ? (
            options.map((option) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore used to handle case with boolean value
              <MenuItem key={option.value?.toString()} value={option.value}>
                {translateLabel(intl, option.label as string)}
              </MenuItem>
            ))
          ) : (
            <MenuItem key="none" value="none">
              {intl.formatMessage({ id: 'common.none' })}
            </MenuItem>
          )}
        </Select>
      )}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default VaultMenu;
