import React from 'react';
import useStyles from './styles';
import Cancel from '@material-ui/icons/Cancel';
import AddCircle from '@material-ui/icons/AddCircle';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select,
  TextField,
  Grid,
} from '@material-ui/core';
import { EMAIL_OPTIONS } from '../../../constants/formValues';
import VaultMenu from '../../common/VaultMenu';
import { useIntl } from 'react-intl';

export enum RECIPIENT_TYPE {
  TO = 'to',
  CC = 'cc',
  BCC = 'bcc',
}

export type QuoteEmailType = {
  uuid: string;
  email: string;
  name?: string;
  isSelected: boolean;
  type: RECIPIENT_TYPE;
  isRemovable: boolean;
};

type QuoteEmailPropType = {
  title?: string;
  emailList: QuoteEmailType[];
  onAddRecipient: () => unknown;
  onDeleteRecipient: (email: QuoteEmailType) => unknown;
  onEmailPropsChange: (id: QuoteEmailType['uuid'], props: Partial<QuoteEmailType>) => unknown;
  emailTemplateList: FormValuesStoreType['emailTemplateList'];
};

export default function QuoteEmail({
  emailList,
  onAddRecipient,
  onDeleteRecipient,
  onEmailPropsChange,
  emailTemplateList,
}: QuoteEmailPropType) {
  const classes = useStyles();
  const intl = useIntl();

  const defaultTemplate = React.useMemo(() => {
    return emailTemplateList.find((template) => template.language === intl.locale)?.value;
  }, [emailTemplateList.length]);

  return (
    <>
      <div className={classes.layout}>
        <Typography variant="h2" data-cy="send-quote-title" gutterBottom>
          {intl.formatMessage({ id: 'quote.send_quote' })}
        </Typography>
        <Grid container alignItems="flex-start">
          <Table aria-label="customized table" size="small">
            <thead>
              <tr>
                <td style={{ width: '20px' }} />
                <td style={{ width: '60px' }} />
                <td />
                <td />
                <td style={{ width: '20px' }} />
              </tr>
            </thead>
            <TableBody>
              {emailList.map((email) => (
                <TableRow key={email.uuid}>
                  <TableCell size="medium" padding="none">
                    <Select
                      className={classes.optionField}
                      data-cy="send-quote-table-sendto-options"
                      name={`recipientType-${email.uuid}`}
                      id={`recipientType-${email.uuid}`}
                      value={email.type}
                      required
                      onChange={(e) =>
                        onEmailPropsChange(email.uuid, {
                          type: e.target.value as RECIPIENT_TYPE,
                        })
                      }
                    >
                      {EMAIL_OPTIONS.map((option) => (
                        <MenuItem
                          data-cy={`send-quote-table-send-${option.value}`}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>

                  <TableCell size="medium" width="50%" padding="none">
                    <TextField
                      data-cy="send-quote-table-name-input"
                      fullWidth
                      id={`name-${email.uuid}`}
                      name={`name-${email.uuid}`}
                      defaultValue={email.name?.trim()}
                      style={{ paddingRight: '5px' }}
                      placeholder={intl.formatMessage({ id: 'quote.full_name' })}
                      size="small"
                      disabled={!email.isRemovable}
                      onBlur={(e) => onEmailPropsChange(email.uuid, { name: e.target.value })}
                    />
                  </TableCell>
                  <TableCell size="medium" width="50%" padding="none">
                    <TextField
                      data-cy="send-quote-table-email-input"
                      fullWidth
                      id={`emailId-${email.uuid}`}
                      name={`emailId-${email.uuid}`}
                      defaultValue={email.email?.trim()}
                      disabled={!email.isRemovable}
                      placeholder={intl.formatMessage({ id: 'quote.email' })}
                      size="small"
                      onBlur={(e) => onEmailPropsChange(email.uuid, { email: e.target.value })}
                    />
                  </TableCell>
                  <TableCell size="small" padding="none">
                    {email.isRemovable && (
                      <IconButton
                        data-cy="send-quote-table-row-remove-icon"
                        color="primary"
                        aria-label={intl.formatMessage({ id: 'common.remove_recipient' })}
                        component="span"
                        disabled={emailList.length === 1}
                        onClick={() => onDeleteRecipient(email)}
                      >
                        <Cancel />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid className={classes.actionsBlock} container alignItems="flex-start">
          <Grid item xs={12} sm={6} className={classes.paddingTop}>
            <Button
              data-cy="add-recipient-button"
              type="submit"
              variant="outlined"
              color="primary"
              size="small"
              onClick={onAddRecipient}
              startIcon={<AddCircle />}
            >
              {intl.formatMessage({ id: 'email.add_recipient' })}
            </Button>
          </Grid>
          {emailTemplateList.length > 0 && (
            <Grid item xs={12} sm={6}>
              <VaultMenu
                data-cy="email-template-dropdown"
                required
                defaultValue={defaultTemplate}
                options={emailTemplateList}
                label={intl.formatMessage({ id: 'common.email_template' })}
                name="emailTemplate"
              />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}
