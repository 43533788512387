import React, { useEffect } from 'react';
import { MenuItem } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Sections } from '../../../constants/search';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { setSearchResult as setSearchHistory } from './index';
import { linkToRecord } from '../../../utils/search';
import SectionHeader from './SectionHeader';

const SectionComponents = (props: {
  globalSearchList: GlobalSearchType;
  searchQuery: string;
  headers?: boolean;
  foundIn?: boolean;
  sectionsToShow?: string[];
  sectionNames?: boolean;
  cutResults?: boolean;
  selectedItem?: string;
  isNavigate?: boolean;
}) => {
  const {
    globalSearchList,
    searchQuery,
    headers,
    foundIn,
    sectionsToShow,
    sectionNames,
    cutResults,
    selectedItem,
    isNavigate,
  } = props;

  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const RESULTS_COUNT = 5;

  const rowsToShow = (section: SearchSections) =>
    cutResults ? section.rows.slice(0, RESULTS_COUNT) : section.rows;

  const getMatchedString = (rows: string[]) => {
    const flatRows = rows.flat();
    const queryLowercase = searchQuery.toLowerCase();
    const entry = flatRows.find((el) => {
      if (!el) return;
      return (el as string).toString().toLowerCase().includes(queryLowercase);
    });
    if (entry) {
      return (
        <Highlighter
          highlightTag="strong"
          className={classes.highlight}
          searchWords={[queryLowercase]}
          textToHighlight={entry}
        />
      );
    }
    return searchQuery;
  };

  const handleResultClick = (section: string, uuid: string) => {
    const path = linkToRecord(section, uuid) || '';
    setSearchHistory({ section, uuid: path, query: searchQuery });
    history.push(path);
  };

  const SectionSubmittedApplications = () => {
    const section = globalSearchList.submittedApplications;
    return section.rows.length ? (
      <>
        {headers && (
          <SectionHeader
            sectionName={Sections.submittedApplications}
            count={section.count}
            searchQuery={searchQuery}
          />
        )}
        {(rowsToShow(section) as SearchItemApplicationsRows[]).map(
          (item: SearchItemApplicationsRows, index: number) => {
            const rows = Object.values(section.rows[index]);
            //retrieve equipments strings
            section.rows[index].equipments.forEach((eq) =>
              rows.push(...(Object.values(eq).map(String) as string[])),
            );

            return (
              <MenuItem
                onClick={() => handleResultClick(Sections.submittedApplications, item.uuid)}
                key={`global_${index}`}
                className={clsx(classes.searchListItem, classes.overflowEllipsis, {
                  [classes.searchListItemSelected]: item.uuid === selectedItem,
                })}
              >
                {getMatchedString(rows as string[])}
                {foundIn && ` in ${item.applicationName}`}
                {sectionNames && (
                  <span className={classes.lightText}>
                    &nbsp;in {intl.formatMessage({ id: 'common.search_submittedApplications' })}
                  </span>
                )}
              </MenuItem>
            );
          },
        )}
      </>
    ) : (
      <></>
    );
  };

  const SectionDraftApplications = () => {
    const section = globalSearchList.draftApplications;
    return section.rows.length ? (
      <>
        {headers && (
          <SectionHeader
            sectionName={Sections.draftApplications}
            count={section.count}
            searchQuery={searchQuery}
          />
        )}
        {(rowsToShow(section) as SearchItemApplicationsRows[]).map(
          (item: SearchItemApplicationsRows, index: number) => {
            const rows = Object.values(section.rows[index]);
            //retrieve equipments strings
            section.rows[index].equipments.forEach((eq) =>
              rows.push(...(Object.values(eq).map(String) as string[])),
            );

            return (
              <MenuItem
                onClick={() => handleResultClick(Sections.draftApplications, item.uuid)}
                key={`global_${index}`}
                className={clsx(classes.searchListItem, classes.overflowEllipsis, {
                  [classes.searchListItemSelected]: item.uuid === selectedItem,
                })}
              >
                {getMatchedString(rows as string[])}
                {foundIn && ` in ${item.applicationName}`}
                {sectionNames && (
                  <span className={classes.lightText}>
                    &nbsp;in {intl.formatMessage({ id: 'common.search_draftApplications' })}
                  </span>
                )}
              </MenuItem>
            );
          },
        )}
      </>
    ) : (
      <></>
    );
  };

  const SectionQuotes = () => {
    const section = globalSearchList.quotes;
    return section.rows.length ? (
      <>
        {headers && (
          <SectionHeader
            sectionName={Sections.quotes}
            count={section.count}
            searchQuery={searchQuery}
          />
        )}
        {(rowsToShow(section) as SearchItemQuotesRows[]).map(
          (item: SearchItemQuotesRows, index: number) => (
            <MenuItem
              onClick={() => handleResultClick(Sections.quotes, item.uuid)}
              key={`global_${index}`}
              className={clsx(classes.searchListItem, classes.overflowEllipsis, {
                [classes.searchListItemSelected]: item.uuid === selectedItem,
              })}
            >
              {getMatchedString(Object.values(section.rows[index]).map(String) as string[])}
              {foundIn && ` in ${item.idLabel}`}
              {sectionNames && (
                <span className={classes.lightText}>
                  &nbsp;in {intl.formatMessage({ id: 'common.search_quotes' })}
                </span>
              )}
            </MenuItem>
          ),
        )}
      </>
    ) : (
      <></>
    );
  };

  const SectionUsers = () => {
    const section = globalSearchList.users;

    return section.rows.length ? (
      <>
        {headers && (
          <SectionHeader
            sectionName={Sections.users}
            count={section.count}
            searchQuery={searchQuery}
          />
        )}
        {(rowsToShow(section) as SearchItemUsersRows[]).map(
          (item: SearchItemUsersRows, index: number) => (
            <MenuItem
              onClick={() => handleResultClick(Sections.users, item.uuid)}
              key={`global_${index}`}
              className={clsx(classes.searchListItem, classes.overflowEllipsis, {
                [classes.searchListItemSelected]: item.uuid === selectedItem,
              })}
            >
              {getMatchedString(Object.values(section.rows[index]).map(String) as string[])}
              {foundIn && ` in ${item.firstName + ' ' + item.lastName}`}
              {sectionNames && (
                <span className={classes.lightText}>
                  &nbsp;in {intl.formatMessage({ id: 'common.search_users' })}
                </span>
              )}
            </MenuItem>
          ),
        )}
      </>
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (isNavigate && selectedItem) {
      Object.keys(globalSearchList).some((key) => {
        globalSearchList[key as keyof GlobalSearchType].rows.some(
          ({ uuid }) => uuid === selectedItem,
        ) && handleResultClick(Sections[key as Sections], selectedItem);
      });
    }
  }, [isNavigate]);

  if (sectionsToShow?.length) {
    return (
      <>
        {sectionsToShow.includes(Sections.quotes) && <SectionQuotes />}
        {sectionsToShow.includes(Sections.submittedApplications) && (
          <SectionSubmittedApplications />
        )}
        {sectionsToShow.includes(Sections.draftApplications) && <SectionDraftApplications />}
        {sectionsToShow.includes(Sections.users) && <SectionUsers />}
      </>
    );
  }

  return (
    <>
      <SectionQuotes />
      <SectionSubmittedApplications />
      <SectionDraftApplications />
      <SectionUsers />
    </>
  );
};

export default SectionComponents;
