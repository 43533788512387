import { AttachmentsRendererType } from './index';
import {
  Button,
  IconButton,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import fileSize from 'filesize';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import UploadProgress from '../UploadProgress';
import React from 'react';
import useStyles from './styles';
import { StyledTableCell, StyledTableRow } from './styles';
import { useIntl } from 'react-intl';
import { setFileType } from '../../../constants/application';

function FileRow({
  name,
  type,
  size,
  uuid,
  removeFile,
}: {
  name: string;
  type: string;
  size: string;
  uuid: string;
  removeFile: (fileUuid: string) => Promise<unknown>;
}) {
  return (
    <React.Fragment>
      <StyledTableRow>
        <TableCell align="left">{name}</TableCell>
        <TableCell data-cy="eq-quantity" align="center">
          {type}
        </TableCell>
        <TableCell data-cy="eq-cost" align="center">
          {size}
        </TableCell>
        <TableCell data-cy="eq-sub-total" align="right">
          <IconButton onClick={() => removeFile(uuid)} color="primary" component="span">
            <DeleteOutlineIcon color="secondary" />
          </IconButton>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function getDefaultRenderer({ title }: { title: string }): AttachmentsRendererType {
  return function DefaultAttachmentsRenderer({ files, removeFile, uploadingFiles, openSelection }) {
    const classes = useStyles();
    const intl = useIntl();

    return (
      <div className={classes.attachmentsWrapper}>
        <Typography data-cy="files-title" className={classes.header} variant="h6">
          <span className={classes.headerTitle}>{title}</span>
        </Typography>
        <TableContainer component={Paper} style={files.length > 0 ? { overflowX: 'scroll' } : {}}>
          {files.length > 0 && (
            <Table aria-label="collapsible table" size="small">
              <TableHead data-cy="equipment-summary-header">
                <TableRow>
                  <StyledTableCell component="th" scope="row" align="left">
                    {intl.formatMessage({ id: 'common.file_name' })}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {intl.formatMessage({ id: 'common.file_type' })}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {intl.formatMessage({ id: 'common.file_size' })}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="right">
                    {intl.formatMessage({ id: 'button.delete' })}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody data-cy="equipment-summary-table-body">
                {files.map((file) => (
                  <FileRow
                    removeFile={removeFile}
                    key={file.uuid}
                    name={file.name}
                    type={setFileType(file.type)}
                    size={fileSize(+file.size)}
                    uuid={file.uuid}
                  />
                ))}
              </TableBody>
            </Table>
          )}
          {uploadingFiles.map((file) => (
            <UploadProgress key={file.uuid} uuid={file.uuid} />
          ))}
        </TableContainer>
        <Box p={2} justifyContent="center" display="flex">
          <Button
            data-cy="add-file-button"
            size="small"
            onClick={openSelection}
            variant="outlined"
            color="primary"
            className={classes.button}
            startIcon={<CloudUploadIcon />}
          >
            {intl.formatMessage({ id: 'common.drag_upload_file' })}
          </Button>
        </Box>
      </div>
    );
  };
}
