import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { getMessages } from '../../utils/language';

type LanguageProviderPropsType = {
  children: React.ReactNode;
};

export default function LanguageProvider(props: LanguageProviderPropsType) {
  const userLanguage = useSelector((state: AppStoreType) => state.settings.currentLanguage);
  const { children } = props;

  const messages = getMessages(userLanguage);

  return (
    <IntlProvider messages={messages} locale={userLanguage} onError={() => true}>
      {children}
    </IntlProvider>
  );
}
