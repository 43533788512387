import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  header: {
    display: 'flex',
    color: theme.palette.text.secondary,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '6px',
    paddingBottom: '10px',
    '& > span': {
      fontSize: '20px',
      fontWeight: 500,
      color: '#000000',
      textAlign: 'left',
      position: 'relative',
      '&::after': {
        content: '"*"',
        opacity: '0.5',
        paddingLeft: '5px',
        fontSize: '14px',
        position: 'absolute',
      },
    },
  },
  infoText: {
    display: 'flex',
    color: theme.palette.text.hint,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '4px',
    paddingLeft: '7px',
    '& > span': {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.54)',
      textAlign: 'left',
    },
  },
  preview: {
    width: '256px',
    height: '160px',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    imageRendering: '-webkit-optimize-contrast',
  },
  previewSection: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  checkboxSpan: {
    paddingLeft: '5px',
  },
  noPhotoIcon: {
    width: '56px',
    height: '56px',
  },
}));

export default useStyles;
