import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NewApplicationContext, { NewApplicationContextProps } from './new-application.context';
import ApplicationEntityForm from '../../components/common/ApplicationEntityForm';
import { toFlatPropertyMap } from '../../utils/common';
import get from 'lodash/get';

type ApplicationApplicantSectionPropsType = {
  defaultData: EntityApplicationRoleType | null;
};

export default function ApplicationApplicantSection({
  defaultData,
}: ApplicationApplicantSectionPropsType) {
  const intl = useIntl();
  const form = useFormContext();
  const {
    getValues,
    formState: { dirtyFields },
  } = form;
  const { subscribeOnPageLeave, unSubscribeOnPageLeave } =
    useContext<NewApplicationContextProps>(NewApplicationContext);

  const leaveListener = React.useCallback(() => {
    const flatKeys = [
      ...Reflect.ownKeys(toFlatPropertyMap(dirtyFields?.applicant ?? {})),
      'entity.naicsSfId',
      'entity.country',
      'entity.sin',
    ];
    const data = getValues('applicant');
    const diff = flatKeys
      .filter((key) => !['entity.naics'].includes(key as string))
      .map((key) => {
        let prevVal = get(defaultData, key);
        if (!prevVal) {
          prevVal = null;
        }
        let currentVal = get(data, key);
        if (!currentVal) {
          currentVal = null;
        }
        if (key === 'entity.country') {
          return prevVal ? prevVal != currentVal : null;
        }

        return prevVal != currentVal ? key : null;
      })
      .filter(Boolean);

    return !!diff.length;
  }, [dirtyFields, defaultData, getValues]);

  React.useEffect(() => {
    subscribeOnPageLeave(leaveListener);
    return () => {
      unSubscribeOnPageLeave(leaveListener);
    };
  }, [leaveListener]);

  return (
    <ApplicationEntityForm
      sameAsName={intl.formatMessage({ id: 'applicant.applicant' })}
      path="applicant"
      role="Applicant"
      defaultEntity={defaultData?.entity ?? null}
      title={intl.formatMessage({ id: 'applicant.applicant_information' })}
    />
  );
}
