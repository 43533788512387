import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { CreateUserConnectedProps } from './create-user.container';
import CreateUserForm from '../../components/common/create-user-form';
import { createNewUser } from '../../store/userManagement/actions';
import { useIntl } from 'react-intl';

function CreateUser(props: CreateUserConnectedProps) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const formMethods = useForm<CreateUserType>({
    defaultValues: {
      vendorUuid: '',
      role: intl.formatMessage({ id: 'role.vendor_user' }),
      email: '',
      firstName: '',
      lastName: '',
      phoneAreaCode: '',
      phone: '',
      phoneExt: '',
      mobileAreaCode: null,
      mobilePhone: null,
      replyTo: null,
      defaultLanguage: 'EN',
      active: false,
      resetPass: false,
    },
  });
  const { handleSubmit, watch, setValue, getValues } = formMethods;

  const { fetchVendorsList, vendorsList, userRole } = props;
  const isPortalAdmin = userRole === 'Portal Admin';
  const checkboxValues = watch(['active', 'resetPass']);

  const handleSaveUser = () => {
    const values = getValues();

    dispatch(
      createNewUser({
        ...values,
        replyTo: values.replyTo || undefined,
      }),
    );
  };

  useEffect(() => {
    if (isPortalAdmin) {
      fetchVendorsList();
    }
  }, []);

  useEffect(() => {
    if (!checkboxValues.active) {
      setValue('resetPass', false);
    }
  }, [checkboxValues.active]);

  return (
    <FormProvider {...formMethods}>
      <CreateUserForm
        handleSubmit={handleSubmit}
        handleSaveUser={handleSaveUser}
        vendorsList={vendorsList.data}
        isPortalAdmin={isPortalAdmin}
        isActivate={checkboxValues.active}
      />
    </FormProvider>
  );
}

export default CreateUser;
