import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';
import { isPublicRoute } from '../utils/common';
import { getHeaders, getUrl } from '../utils/apiUtils';

const BACKEND = getBackendUrl() + '/email-template';
const api = getApi(BACKEND);

export const getAllEmailTemplate = async (context: string[], routeParams?: PublicRouteParams) => {
  const filters: Array<string> = [];
  for (const filter of context) {
    filters.push(`{"field":"relatedTo","type":"equals","value":"${filter}"}`);
  }

  filters.push(`{"field":"active","type":"equals","value":"${true}"}`);

  const isPublic = isPublicRoute(routeParams ?? {});
  const headers = getHeaders(routeParams ?? {});
  const url = getUrl(`/list?filters=[${filters.join(',')}]`, isPublic);

  return await api.get<PaginationQuoteEmailTemplateBEResponse>(url, {
    headers,
  });
};
