import { IntlShape } from 'react-intl';

export const defaultLanguage: Languages = 'EN' as Languages;

export const getUserRoleMap = (intl: IntlShape) => {
  return {
    'Portal Admin': intl.formatMessage({ id: 'user_managment.portal_admin' }),
    'Vendor Admin': intl.formatMessage({ id: 'user_managment.vendor_admin' }),
    'Vendor User': intl.formatMessage({ id: 'user_managment.vendor_user' }),
  };
};

export const getAdminRoleMap = (intl: IntlShape) => {
  return {
    'Portal Admin': intl.formatMessage({ id: 'user_managment.portal_admin' }),
    'Broker Rep': intl.formatMessage({ id: 'user_managment.broker_rep' }),
  };
};

export const getUserStatusesMap = (intl: IntlShape) => {
  return {
    Active: intl.formatMessage({ id: 'user.active' }),
    Inactive: intl.formatMessage({ id: 'user.inactive' }),
  };
};

export const getEmailTemplateTypesMap = (intl: IntlShape) => {
  return {
    default: intl.formatMessage({ id: 'email_templates.type_default' }),
    custom: intl.formatMessage({ id: 'email_templates.type_custom' }),
  };
};
export const getEmailTemplateRelatedToMap = (intl: IntlShape) => {
  return {
    quoteFollowup: intl.formatMessage({ id: 'email_templates.quoteFollowup' }),
    quoteDetails: intl.formatMessage({ id: 'email_templates.quoteDetails' }),
    quoteAcceptance: intl.formatMessage({ id: 'email_templates.quoteAcceptance' }),
    applicationStatusPOIssued: intl.formatMessage({
      id: 'email_templates.applicationStatusPOIssued',
    }),
    applicationStatusApproved: intl.formatMessage({
      id: 'email_templates.applicationStatusApproved',
    }),
    applicationStatusDeclined: intl.formatMessage({
      id: 'email_templates.applicationStatusDeclined',
    }),
    applicationStatusMIR: intl.formatMessage({ id: 'email_templates.applicationStatusMIR' }),
    applicationStatusFundingSecured: intl.formatMessage({
      id: 'email_templates.applicationStatusFundingSecured',
    }),
    applicationSubmission: intl.formatMessage({ id: 'email_templates.applicationSubmission' }),
    applicationStatusUpdate: intl.formatMessage({ id: 'email_templates.applicationStatusUpdate' }),
    amendmentRequest: intl.formatMessage({ id: 'email_templates.amendmentRequest' }),
    amendmentUpdate: intl.formatMessage({ id: 'email_templates.amendmentUpdate' }),
  };
};

export const getCustomEmailTemplateRelatedToMap = (intl: IntlShape) => {
  return {
    quoteFollowup: intl.formatMessage({ id: 'email_templates.quoteFollowup' }),
    quoteDetails: intl.formatMessage({ id: 'email_templates.quoteDetails' }),
    quoteAcceptance: intl.formatMessage({ id: 'email_templates.quoteAcceptance' }),
    applicationStatusPOIssued: intl.formatMessage({
      id: 'email_templates.applicationStatusPOIssued',
    }),
    applicationStatusApproved: intl.formatMessage({
      id: 'email_templates.applicationStatusApproved',
    }),
    applicationStatusDeclined: intl.formatMessage({
      id: 'email_templates.applicationStatusDeclined',
    }),
    applicationStatusMIR: intl.formatMessage({ id: 'email_templates.applicationStatusMIR' }),
    applicationStatusFundingSecured: intl.formatMessage({
      id: 'email_templates.applicationStatusFundingSecured',
    }),
    applicationSubmission: intl.formatMessage({ id: 'email_templates.applicationSubmission' }),
    applicationStatusUpdate: intl.formatMessage({ id: 'email_templates.applicationStatusUpdate' }),
    amendmentRequest: intl.formatMessage({ id: 'email_templates.amendmentRequest' }),
    amendmentUpdate: intl.formatMessage({ id: 'email_templates.amendmentUpdate' }),
  };
};

export const getQuoteStatusesMap = (intl: IntlShape) => {
  return {
    quoteGenerated: intl.formatMessage({ id: 'quote.quote_generated' }),
    quoteEmailed: intl.formatMessage({ id: 'quote.quote_emailed' }),
    quoteApplicationSubmitted: intl.formatMessage({ id: 'quote.application_submitted' }),
    quoteApplicationDeclined: intl.formatMessage({ id: 'quote.application_declined' }),
    quoteCustomerDeclined: intl.formatMessage({ id: 'quote.customer_declined' }),
    quoteCustomerUnsubscribed: intl.formatMessage({ id: 'quote.unsubscribed' }),
  };
};

export const getAmendmentStatusesMap = (intl: IntlShape) => {
  return {
    amendmentApproved: intl.formatMessage({ id: 'amendment.approved' }),
    amendmentRejected: intl.formatMessage({ id: 'amendment.rejected' }),
    amendmentPending: intl.formatMessage({ id: 'amendment.pending' }),
    amendmentRevisionRequired: intl.formatMessage({ id: 'amendment.revision_required' }),
  };
};

export const getApplicationStatusesMap = (
  intl: IntlShape,
): { [name in ApplicationStatusName]: string } => {
  return {
    applicationDraft: intl.formatMessage({ id: 'application.statusDraft' }),
    applicationSubmittedToBroker: intl.formatMessage({ id: 'application.statusSubmitted' }),
    applicationQualification: intl.formatMessage({ id: 'application.statusQualification' }),
    applicationCreditReview: intl.formatMessage({ id: 'application.statusCreditReview' }),
    applicationApproved: intl.formatMessage({ id: 'application.statusApproved' }),
    applicationMoreInformation: intl.formatMessage({ id: 'application.statusMoreInformation' }),
    applicationManualReview: intl.formatMessage({ id: 'application.statusManualReview' }),
    applicationDeclined: intl.formatMessage({ id: 'application.statusDeclined' }),
    applicationDocsBackIncomplete: intl.formatMessage({
      id: 'application.statusDocsBackIncomplete',
    }),
    applicationPOIssued: intl.formatMessage({ id: 'application.statusPOIssued' }),
    applicationSubmitForFunding: intl.formatMessage({ id: 'application.statusSubmitForFunding' }),
    applicationFundingDeficiency: intl.formatMessage({ id: 'application.statusFundingDeficiency' }),
    applicationClosedLost: intl.formatMessage({ id: 'application.statusClosedLost' }),
    applicationClosedQualifiedOut: intl.formatMessage({
      id: 'application.statusClosedQualifiedOut',
    }),
    applicationDraftingContract: intl.formatMessage({ id: 'application.statusDraftingContract' }),
    applicationDocumentsSentOut: intl.formatMessage({ id: 'application.statusDocumentsSentOut' }),
    applicationFundingSecured: intl.formatMessage({ id: 'application.applicationFundingSecured' }),
    applicationSubmittedToFunder: intl.formatMessage({
      id: 'application.applicationSubmittedToFunder',
    }),
  };
};

export const getApplicationMirStatusesMap = (
  intl: IntlShape,
): { [name in MirItem['status']]: string } => {
  return {
    Incomplete: intl.formatMessage({ id: 'application.mir.incomplete' }),
    Pending: intl.formatMessage({ id: 'application.mir.pending' }),
    Completed: intl.formatMessage({ id: 'application.mir.completed' }),
  };
};

export const allStatusesMap = (intl: IntlShape) => ({
  ...getEmailTemplateRelatedToMap(intl),
  ...getQuoteStatusesMap(intl),
  ...getAmendmentStatusesMap(intl),
  ...getApplicationStatusesMap(intl),
  ...getApplicationMirStatusesMap(intl),
  ...getEmailTemplateTypesMap(intl),
  ...getUserRoleMap(intl),
});
