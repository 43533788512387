import React from 'react';
import { CURRENCY_TYPE } from '../../constants/currency';
import { useApplicationSections } from './applicationUtils';

export type NewApplicationLeaveListener = () => boolean;
export type OnSaveListener = (
  applicationUuid: string,
) => Promise<{ success: boolean; message?: string }>;

export type NewApplicationContextProps = {
  isNewApplicationPageReady: boolean;
  applicationUuid?: string | null;
  userUuid?: string | null;
  vendorReps: VendorRepType[];
  routeParams: PublicRouteParams;
  loadApplication: (applicationUuid: string) => Promise<unknown>;

  customValidation: (
    partialApplication: Partial<ApplicationToBEType>,
  ) => { valid: boolean; message?: string };

  sections: ReturnType<typeof useApplicationSections>['sections'];
  handleSectionOpen: ReturnType<typeof useApplicationSections>['handleSectionOpen'];
  handleSectionClose: ReturnType<typeof useApplicationSections>['handleSectionClose'];

  currency: CURRENCY_TYPE;
  changeCurrency: (c: CURRENCY_TYPE) => unknown;

  hasChanges: () => boolean;

  subscribeOnPageLeave: (fn: NewApplicationLeaveListener) => unknown;
  unSubscribeOnPageLeave: (fn: NewApplicationLeaveListener) => unknown;

  subscribeOnSave: (fn: OnSaveListener) => () => unknown;

  save: () => Promise<string | null>;
  submit: () => Promise<boolean>;
};

const NewApplicationContext = React.createContext<NewApplicationContextProps>({
  currency: CURRENCY_TYPE.CAD,
  changeCurrency: () => null,
  isNewApplicationPageReady: false,
  customValidation: () => ({ valid: true }),
  sections: [],
  loadApplication: async () => null,
  handleSectionClose: () => null,
  handleSectionOpen: () => null,
  hasChanges: () => false,
  save: async () => null,
  submit: async () => false,
  vendorReps: [],
  userUuid: null,
  applicationUuid: null,
  routeParams: {} as PublicRouteParams,
  subscribeOnSave: () => () => null,
  subscribeOnPageLeave: () => null,
  unSubscribeOnPageLeave: () => null,
});

export default NewApplicationContext;
