import * as React from 'react';
import clsx from 'clsx';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { TypographyProps } from '@material-ui/core/Typography';
import ToolbarText from '@material-ui/pickers/_shared/ToolbarText';
import { useStyles } from './styles';
import { translateLabel } from '../../../utils/translateLabel';
import { useIntl } from 'react-intl';

export type ToolbarButtonProps = Omit<ButtonProps, 'variant'> & {
  variant: TypographyProps['variant'];
  selected: boolean;
  label: string;
  align?: TypographyProps['align'];
  typographyClassName?: string;
  className?: Parameters<typeof clsx>[0];
};

const ToolbarButton: React.FunctionComponent<ToolbarButtonProps> = ({
  className,
  label,
  selected,
  variant,
  align,
  typographyClassName,
  children,
  ...other
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Button variant="text" className={clsx(classes.toolbarBtn, className)} {...other}>
      <ToolbarText
        align={align}
        className={typographyClassName}
        variant={variant}
        label={translateLabel(intl, label as string)}
        selected={selected}
      />
      {children}
    </Button>
  );
};

export default ToolbarButton;
