import LoginAsVendorRep from './login-as-vendor-rep';
import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AUDIT_TRAIL, AUDIT_TRAIL_LOGIN_AS } from '../../constants/navigations';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles';

interface LinkTabProps {
  label: string;
  href: string;
  disabled?: boolean;
}

function LinkTab(props: LinkTabProps) {
  const history = useHistory();

  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.push(props.href);
      }}
      {...props}
    />
  );
}
const AuditTrail = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper variant="outlined">
      <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange}>
        <LinkTab label="LOG IN AS" href={AUDIT_TRAIL_LOGIN_AS.path} />
        <LinkTab label="QUOTES" disabled href={AUDIT_TRAIL.path} />
        <LinkTab label="APPLICATIONS" disabled href={AUDIT_TRAIL.path} />
      </Tabs>
      <Container className={classes.container}>
        <Switch>
          <Route path={AUDIT_TRAIL_LOGIN_AS.path} component={LoginAsVendorRep} />
          <Redirect from={AUDIT_TRAIL.path} to={AUDIT_TRAIL_LOGIN_AS.path} />
        </Switch>
      </Container>
    </Paper>
  );
};

export default AuditTrail;
