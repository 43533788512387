import React, { useState } from 'react';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import {
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import DateFormatter from '../date-formatter';
import ModalBox from '../modal';
import QuoteTable from '../QuoteTable';
import SentEmail from '../sent-email';
import { useIntl } from 'react-intl';
import CurrencyFormat from '../currency-format';

type QuoteHistoryPropsType = {
  title: string;
  quoteHistory: InstantQuoteStoreType['generatedQuotes'];
  vendorProgramList: FormValuesStoreType['vendorProgramList'];
  selectedGeneratedQuote?: string;
  selectedPaymentOption?: string;
};

export default function QuoteHistory({
  title,
  quoteHistory,
  vendorProgramList,
  selectedGeneratedQuote,
  selectedPaymentOption,
}: QuoteHistoryPropsType) {
  const classes = useStyles();
  const intl = useIntl();
  const [openQuoteModal, setOpenQuoteModal] = useState<
    InstantQuoteStoreType['generatedQuotes'][number] | null
  >(null);
  const [openEmailModal, setOpenEmailModal] = useState<
    InstantQuoteStoreType['generatedQuotes'][number] | null
  >(null);

  const getVendorProgram = (quote: InstantQuoteStoreType['generatedQuotes'][number]) => {
    return vendorProgramList.find((program) => String(program.value) === quote.vendorProgram)
      ?.label;
  };

  const openQuote = (quote: InstantQuoteStoreType['generatedQuotes'][number]) => {
    setOpenQuoteModal(quote);
  };

  const closeModal = () => {
    setOpenQuoteModal(null);
    setOpenEmailModal(null);
  };

  const openEmails = (quote: InstantQuoteStoreType['generatedQuotes'][number]) => {
    setOpenEmailModal(quote);
  };

  return (
    <>
      {title && (
        <Typography
          data-cy="quote-history-title"
          className={classes.title}
          variant="h2"
          gutterBottom
        >
          {title}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>{intl.formatMessage({ id: 'common.date' })}</StyledTableCell>
              <StyledTableCell>{intl.formatMessage({ id: 'quote.lease_amount' })}</StyledTableCell>
              <StyledTableCell>{intl.formatMessage({ id: 'quote.emails' })}</StyledTableCell>
              <StyledTableCell>
                {intl.formatMessage({ id: 'quote.vendor_program' })}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quoteHistory.map((quote) => (
              <StyledTableRow key={quote.uuid}>
                <StyledTableCell component="th" scope="row">
                  {quote.uuid === selectedGeneratedQuote && (
                    <CheckCircleIcon style={{ color: green[500] }} />
                  )}

                  <DateFormatter date={quote.createdAt} />
                </StyledTableCell>

                <StyledTableCell component="th" scope="row">
                  <Link href="#" onClick={() => openQuote(quote)}>
                    <CurrencyFormat
                      value={quote.leaseAmount}
                      currencyType={quote.leaseAmountCurrencyType}
                    />
                  </Link>
                </StyledTableCell>

                <StyledTableCell component="th" scope="row">
                  {quote.emails?.length > 0 && (
                    <Link href="#" onClick={() => openEmails(quote)}>
                      {intl.formatMessage({ id: 'quote.see_emails' })}
                    </Link>
                  )}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {getVendorProgram(quote) ?? quote.vendorProgram}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openQuoteModal ? (
        <ModalBox
          header={intl.formatMessage({ id: 'quote.quote' })}
          body={
            <QuoteTable
              title={intl.formatMessage({ id: 'quote.quote_table' })}
              terms={JSON.parse(openQuoteModal.terms)}
              purchaseOptions={JSON.parse(openQuoteModal.purchaseOptions)}
              skipPayment={openQuoteModal.skipAmount}
              skipMonth={openQuoteModal.skipMonth}
              currency={openQuoteModal.leaseAmountCurrencyType}
              quotePaymentOptions={openQuoteModal.quotePaymentOptions ?? []}
              selectedPaymentOptions={[selectedPaymentOption ?? '']}
            />
          }
          isOpen={!!openQuoteModal}
          handleClose={closeModal}
          dataCy="quote-table-history-modal"
          isQuoteTable
        />
      ) : null}

      <ModalBox
        header={intl.formatMessage({ id: 'quote.quote' })}
        body={
          <Paper variant="outlined">
            <SentEmail
              title={intl.formatMessage({ id: 'quote.quote_email_history' })}
              emails={openEmailModal?.emails ?? []}
            />
          </Paper>
        }
        isOpen={!!openEmailModal}
        handleClose={closeModal}
        dataCy="quote-email-history-modal"
        isEmailPreview
      />
    </>
  );
}
