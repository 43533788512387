import React, { Dispatch, SetStateAction } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

type ApplicationDocumentOverrideFormCustomerInvoiceSwitcherPropsType = {
  defaultValue: boolean;
  setIsCustomerSwitcherDisabled: Dispatch<SetStateAction<boolean>>;
};

export default function ApplicationDocumentOverrideFormCustomerInvoiceSwitcher({
  defaultValue,
  setIsCustomerSwitcherDisabled,
}: ApplicationDocumentOverrideFormCustomerInvoiceSwitcherPropsType) {
  const intl = useIntl();

  const onChange = (checked: boolean) => {
    setIsCustomerSwitcherDisabled(checked);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            key="dont-forget-label"
            label={intl.formatMessage({ id: 'document.dont_generate_customer_invoice' })}
            control={
              <Checkbox
                name="customer_invoice_switcher"
                size="small"
                color="primary"
                checked={defaultValue}
                onChange={(e) => onChange(e.target.checked)}
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
