import * as React from 'react';
import clsx from 'clsx';
import Toolbar, { ToolbarProps } from '@material-ui/core/Toolbar';
import { ExtendMui } from '@material-ui/pickers/typings/extendMui';
import { useStyles } from './styles';

type PickerToolbarProps = ExtendMui<ToolbarProps> & {
  isLandscape: boolean;
};

const PickerToolbar: React.SFC<PickerToolbarProps> = ({
  children,
  isLandscape,
  className = null,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Toolbar
      className={clsx(
        classes.pickerToolbar,
        { [classes.pickerToolbarLandscape]: isLandscape },
        className,
      )}
      {...other}>
      {children}
    </Toolbar>
  );
};

export default PickerToolbar;
