import { IntlShape } from 'react-intl';

export const getValidationArray = (intl: IntlShape) => [
  {
    text: intl.formatMessage({ id: 'common.at_least_8_chars' }),
    isInvalid: (input: string) => {
      return !input || input?.length < 8;
    },
  },
  {
    text: intl.formatMessage({ id: 'common.at_least_1_lowercase' }),
    isInvalid: (input: string) => {
      return !/[a-z]/.test(input);
    },
  },
  {
    text: intl.formatMessage({ id: 'common.at_least_1_uppercase' }),
    isInvalid: (input: string) => {
      return !/[A-Z]/.test(input);
    },
  },
  {
    text: intl.formatMessage({ id: 'common.at_least_1_number' }),
    isInvalid: (input: string) => {
      return !/[0-9]/.test(input);
    },
  },
  {
    text: intl.formatMessage({ id: 'common.at_least_1_special' }),
    isInvalid: (input: string) => {
      return ![
        '!',
        '(',
        ')',
        '-',
        '.',
        '?',
        '[',
        ']',
        '_',
        '`',
        '~',
        ';',
        ':',
        '&',
        '@',
        '#',
        '$',
        '%',
        '^',
        '*',
        '+',
        '=',
      ].some((char) => input.includes(char));
    },
  },
];
