import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import DataGrid from '../DataGrid';
import AmendmentFormModal from '../AmendmentFormModal';
import { ApplicationAmendmentsConnectedProps } from './application-amendments.containter';
import { Paper } from '@material-ui/core';
import { useStyles } from '../../../pages/application/styles';
import { getAmendmentStatusesMap } from '../../../constants/language';
import AmendmentNotAvailableIcon from '../../icons/amendment-not-available';
import Typography from '@material-ui/core/Typography';
import { subscribe } from '../../../services/websockets';

type ApplicationAmendmentsPropsType = {
  applicationUuid: string;
  disableEdit?: boolean;
};

function ApplicationAmendments(
  props: ApplicationAmendmentsConnectedProps & ApplicationAmendmentsPropsType,
) {
  const {
    gridViews,
    selectedGridView,
    filterList,
    columns,
    amendments,
    applicationUuid,
    isLoading,
    fileAttachments,
    fileLoading,
    disableEdit = false,
  } = props;

  const [activeAmendmentId, setActiveAmendmentId] = React.useState<string | null>(null);
  const intl = useIntl();
  const classes = useStyles();
  const statusesMap = getAmendmentStatusesMap(intl);
  const availableToEditStatuses = ['amendmentPending', 'amendmentRevisionRequired'];

  React.useEffect(() => {
    if (!isLoading && applicationUuid) {
      props.getApplicationAmendments(applicationUuid);

      return subscribe({
        entity: 'amendment',
        filter: 'list',
        handler: () => {
          props.getApplicationAmendments(applicationUuid);
        },
      });
    }
  }, []);

  const submit = (amendmentData: AmendmentType) => {
    const isNew = activeAmendmentId === 'new';
    if (isNew) {
      return props.postApplicationAmendment(applicationUuid, amendmentData);
    }
    return props.putApplicationAmendment(applicationUuid, amendmentData);
  };
  const handleEdit = (id: string) => setActiveAmendmentId(id);

  const CustomButtons = () => (
    <div className={classes.actions}>
      <Button
        data-cy="custom-button"
        size="medium"
        variant="contained"
        color="primary"
        onClick={() => setActiveAmendmentId('new')}>
        {intl.formatMessage({ id: 'button.add' })}
      </Button>
    </div>
  );

  return (
    <React.Fragment>
      <Paper variant="outlined" className={classes.documentRoot}>
        {amendments.length > 0 ? (
          <DataGrid
            title={intl.formatMessage({ id: 'amendment.application_amendments' })}
            titleVariant="h6"
            rows={amendments.map((a) => ({
              ...a,
              disableEdit: !availableToEditStatuses.includes(a.status.status),
            }))}
            gridViews={gridViews}
            selectedGridView={selectedGridView}
            filterList={filterList}
            columns={columns}
            searchLabel={intl.formatMessage({ id: 'common.type_to_search' })}
            CustomButtons={disableEdit ? null : CustomButtons}
            hideSearchBar={amendments?.length === 0}
            hideTable={amendments?.length === 0}
            editButtonLabel={intl.formatMessage({ id: 'button.edit' })}
            handleEdit1={!disableEdit && handleEdit}
            statusesMap={statusesMap}
          />
        ) : (
          <div className={classes.notAvailableContainer}>
            <Typography
              data-cy="application-amendment-title"
              variant="h2"
              className={classes.paperTitle}>
              {intl.formatMessage({ id: 'amendment.application_amendments' })}
            </Typography>
            <div className={classes.paperActions}>{!disableEdit ? <CustomButtons /> : ''}</div>
            <AmendmentNotAvailableIcon viewBox="0 0 80 80" className={classes.notAvailableIcon} />
            <p data-cy="amendment-na-texting">
              {intl.formatMessage({ id: 'application.amendment_not_available' })}
            </p>
          </div>
        )}

        {!!activeAmendmentId && (
          <AmendmentFormModal
            onClose={() => setActiveAmendmentId(null)}
            onSubmit={submit}
            amendment={amendments.find((a) => a.uuid === activeAmendmentId)}
            fileAttachments={fileAttachments}
            fileLoading={fileLoading}
            isLoading={isLoading}
          />
        )}
      </Paper>
    </React.Fragment>
  );
}
export default ApplicationAmendments;
