import EmailTemplatesList from './email-templates-list.container';
import { EMAIL_TEMPLATES_LIST_PAGE } from '../../constants/navigations';

export default [
  {
    path: EMAIL_TEMPLATES_LIST_PAGE.path,
    name: 'EmailTemplatesList',
    component: EmailTemplatesList,
    exact: true,
  },
];
