import Button from '@material-ui/core/Button';
import React from 'react';
import { useIntl } from 'react-intl';
import { logInAsVendorUser } from '../../../../utils/common';

export default function LoginAsButton(props: {
  value: string;
  data?: { [key: string]: string | number | boolean };
}) {
  const intl = useIntl();

  return (
    <Button
      color="primary"
      disabled={!props.value || props.data?.active === false || props.data?.companyActive === false}
      onClick={() => {
        logInAsVendorUser(props.value);
      }}>
      {intl.formatMessage({ id: 'button.login_as' })}
    </Button>
  );
}
