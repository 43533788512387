import React from 'react';
import { Select, MenuItem, InputLabel, SelectProps } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { translateLabel } from '../../../utils/translateLabel';
import { useIntl } from 'react-intl';

type VendorRepSelectionPropsType = {
  vendorReps: VendorRepType[];
  defaultValue?: string;
  required?: boolean;
  onChange?: (rep: string) => unknown;
  value?: string | null;
  className?: string | undefined;
  disabled?: boolean;
  label?: string;
} & Partial<SelectProps>;

const VendorRepSelection = ({
  vendorReps,
  defaultValue,
  required,
  onChange,
  value,
  disabled,
  label,
  className,
  name,
  ...props
}: VendorRepSelectionPropsType) => {
  const classes = useStyles();
  const options = React.useMemo(
    () => vendorReps.map((rep) => ({ value: rep.uuid, label: rep.name })),
    [vendorReps],
  );
  const { control } = useFormContext();
  const intl = useIntl();

  return (
    <>
      {label && (
        <InputLabel required={required} className={classes.label}>
          {label}
        </InputLabel>
      )}
      {control ? (
        <Controller
          render={({ onChange: controllerOnChange, value: controlledValue }) => (
            <Select
              name={name}
              required={required}
              label={translateLabel(intl, label as string)}
              value={controlledValue ?? ''}
              className={classes.dropdown}
              onChange={onChange ?? controllerOnChange}
              defaultValue={defaultValue !== undefined ? defaultValue : ''}
              disabled={disabled}
              {...props}
            >
              {!!options?.length &&
                options.map((option) => (
                  <MenuItem key={option.value?.toString()} value={option.value}>
                    {translateLabel(intl, option.label as string)}
                  </MenuItem>
                ))}
            </Select>
          )}
          name={name!}
          control={control}
          defaultValue={defaultValue !== undefined ? defaultValue : ''}
          rules={{ required }}
        />
      ) : (
        <Select
          data-cy="vendor-rep-selection"
          value={value ?? ''}
          required={required}
          defaultValue={defaultValue}
          className={clsx(classes.dropdown, className)}
          disabled={disabled}
          onChange={(e) => onChange?.(e.target.value as string)}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};
export default VendorRepSelection;
