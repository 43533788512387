import { Dispatch } from 'react';

import * as userApi from '../../apis/users';
import { actions as toastActions } from '../toast';
import { actions } from './reducer';
import { getIntl } from '../../utils/language';

export const fetchUserOnEdit = (uuid: string) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStoreType,
) => {
  let user;
  const intl = getIntl(getState);

  try {
    user = (await userApi.getUserOnEdit(uuid)).data;
  } catch (e) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'user.user_fetch_error' }),
    };
    dispatch(toastActions.open(toastOptions));
    dispatch(actions.setLoading(false));

    return;
  }
  const toastOptions = {
    toastType: 'success' as const,
    message: intl.formatMessage({ id: 'user.loaded' }),
  };

  dispatch(actions.updateUserOnEdit(user));
  dispatch(toastActions.open(toastOptions));
  dispatch(actions.setLoading(false));
};

export const updateUser = (uuid: string, userObj: EditUserType) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStoreType,
) => {
  const intl = getIntl(getState);

  try {
    await userApi.updateUser(uuid, userObj);
  } catch (error) {
    const errorData = error.response?.data;
    let errorMessage = intl.formatMessage({ id: 'user.user_update_error' });

    if (errorData?.code === 617) {
      errorMessage = intl.formatMessage({ id: 'user.user_or_vendor_not_active_error' });
    }

    const toastOptions = {
      toastType: 'error' as const,
      message: errorMessage,
    };
    dispatch(toastActions.open(toastOptions));

    return;
  }
  const toastOptions = {
    toastType: 'success' as const,
    message: intl.formatMessage({ id: 'user.user_update_success' }),
  };

  dispatch(toastActions.open(toastOptions));
  dispatch(fetchUserOnEdit(uuid));
};
