import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
}));
export default useStyles;
