import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import { UserManagementConnectedProps } from './user-management.container';
import { CREATE_USER } from '../../constants/navigations';

import useStyles from './styles';
import { useSelector } from 'react-redux';

import ServerSideDataGrid from '../../components/common/VpDataGrid/ServerSideDataGrid';
import { GridApi, IDatasource } from '@ag-grid-community/all-modules';
import { AgGridColumn } from '@ag-grid-community/react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

function UserManagement(props: UserManagementConnectedProps) {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();

  const userRole = useSelector<AppStoreType, AppStoreType['user']['role']>(
    (state) => state.user.role,
  );
  const isPortalAdmin = userRole === 'Portal Admin';
  const [gridApi, setGridApi] = useState<null | GridApi>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const { fetchUsersList, activateUsers, deactivateUsers } = props;

  const datasource: IDatasource = {
    getRows: async (params) => {
      Object.keys(params.filterModel).forEach((key) => {
        if (key == 'active') {
          const newkey = 'status';
          params.filterModel[newkey] = params.filterModel[key];
          delete params.filterModel[key];
        }
      });
      const users = await fetchUsersList(params);

      params.successCallback(users?.data ?? [], users?.total);
    },
  };
  const checkSelectedNodes = () => {
    const selectedNodes = gridApi?.getSelectedNodes();
    if (selectedNodes) {
      setSelectedRows(selectedNodes?.map((node) => (node.data as UserType).uuid));
    } else {
      setSelectedRows([]);
    }
  };

  const handleActionButton = async (users: string[], action: 'activate' | 'deactivate') => {
    switch (action) {
      case 'activate':
        await activateUsers(users);
        break;
      case 'deactivate':
      default:
        await deactivateUsers(users);
    }
    gridApi?.refreshInfiniteCache();
  };

  const UserManagementButtons = () => {
    const isButtonDisabled = !selectedRows.length;

    return (
      <div className={classes.root}>
        <Button
          size="medium"
          variant="outlined"
          color="primary"
          disabled={isButtonDisabled}
          onClick={() => handleActionButton(selectedRows, 'activate')}
          className={classes.controlButton}
        >
          {intl.formatMessage({ id: 'button.activate' })}
        </Button>
        <Button
          size="medium"
          variant="outlined"
          color="primary"
          disabled={isButtonDisabled}
          onClick={() => handleActionButton(selectedRows, 'deactivate')}
          className={classes.controlButton}
        >
          {intl.formatMessage({ id: 'button.deactivate' })}
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          className={clsx(classes.controlButton, classes.newButton)}
          onClick={() => history.push(CREATE_USER.path)}
        >
          {intl.formatMessage({ id: 'user.new_user' })}
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        alignItems="center"
        className={classes.headerSpaceBottom}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Hidden smDown>
            <Typography data-cy="quotes-header" variant="h1" gutterBottom>
              {intl.formatMessage({ id: 'user.user_management' })}
            </Typography>
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.buttonsRoot}>
          <UserManagementButtons />
        </Grid>
      </Grid>

      <ServerSideDataGrid
        onInit={setGridApi}
        api={gridApi}
        onSelectionChanged={checkSelectedNodes}
        datasource={datasource}
        fitType="fit"
        rowSelection="multiple"
      >
        <AgGridColumn type="quote:selectAll" />
        <AgGridColumn floatingFilter type="userManagment:id" />
        <AgGridColumn floatingFilter type="userManagment:firstName" />
        <AgGridColumn floatingFilter type="userManagment:lastName" />
        <AgGridColumn floatingFilter type="userManagment:company" />
        <AgGridColumn floatingFilter type="userManagment:email" />
        <AgGridColumn floatingFilter type="userManagment:phone" />
        <AgGridColumn floatingFilter type="userManagment:mobilePhone" />
        <AgGridColumn type="userManagment:role" />
        <AgGridColumn floatingFilter type="userManagment:active" />
        <AgGridColumn floatingFilter type="userManagment:createdAt" />
        <AgGridColumn floatingFilter type="userManagment:lastLogin" />
        <AgGridColumn type="userManagment:userLoginAs" hide={!isPortalAdmin} />
        <AgGridColumn type="userDeleteLink" hide={!isPortalAdmin} />
      </ServerSideDataGrid>
    </div>
  );
}
export default UserManagement;
