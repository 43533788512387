import { connect, ConnectedProps } from 'react-redux';
import { actions } from '../../store/application';
import { actions as amendmentsActions } from '../../store/applicationAmendments';
import { actions as documentsActions } from '../../store/applicationDocuments';

import Application from './application.component';

const mapStateToProps = (state: AppStoreType) => ({
  vendorId: (state.user as UserBasedStoreType).vendorId,
  applicationInfo: state.application.application,
  brokerApplicationInfo: state.application.brokerApplication,
  getGeneratedDocuments: state.applicationDocuments,
  loading: state.application.loading,
  error: state.application.error,
});

const mapDispatchToProps = {
  cleanApplication: actions.cleanState,
  getApplicationInfo: actions.getApplicationInfo,
  getBrokerApplicationInfo: actions.getBrokerApplicationInfo,
  addFiles: actions.addFilesToApplication,
  deleteFile: actions.removeFileFromApplication,
  resetApplicationAmendments: amendmentsActions.resetApplicationAmendments,
  resetApplicationDocuments: documentsActions.resetDocumentsList,
  sendApplicationDocuments: actions.sendApplicationDocuments,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ApplicationConnectedPropsType = ConnectedProps<typeof connector>;

export default connector(Application);
