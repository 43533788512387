import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import VpButton from '../../components/common/VpButton';
import { FormattedMessage } from 'react-intl';
import React from 'react';

type OnLeavePopupPropsType = {
  open: boolean;
  cancel?: () => unknown;
  leave?: () => unknown;
  saveAndLeave?: () => unknown;
};

export const OnLeavePopup = ({ leave, saveAndLeave, cancel, open }: OnLeavePopupPropsType) => {
  return (
    <Dialog open={open} onClose={cancel}>
      <DialogTitle>
        <FormattedMessage id="common.unsaved_changes" />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage id="common.unsaved_changes_message" />
      </DialogContent>
      <DialogActions>
        <VpButton onClick={saveAndLeave} color="primary" variant="contained">
          <FormattedMessage id="button.save" />
        </VpButton>
        <Button onClick={leave} color="primary">
          <FormattedMessage id="common.dont_save" />
        </Button>
        <Button onClick={cancel} color="primary">
          <FormattedMessage id="common.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
