import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';

const BACKEND = getBackendUrl() + '/entity';
const api = getApi(BACKEND);

export const getEntityList = async () => {
  return await api.get<GetEntityResponse>('/');
};

export const getEntityListByVendorSiteUrl = async (vendorSiteUrl: string) => {
  return await api.get<GetEntityResponse>(`/${vendorSiteUrl}`);
};
