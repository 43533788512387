import SubmittedApplications from './submitted-applications.container';
import * as Navigations from '../../constants/navigations';
const { path } = Navigations.SUBMITTED_APPLICATION_PAGE;
const route = [
  {
    path,
    name: 'SubmittedApplications',
    component: SubmittedApplications,
    exact: true,
  },
];
export default route;
