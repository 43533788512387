import { connect } from 'react-redux';
import Router from './router.component';
import Health from '../pages/health/health.routes';
import Signin from '../pages/sign-in/sign-in.routes';
import Signup from '../pages/sign-up/sign-up.routes';
import Application from '../pages/application/application.routes';
import {
  authRoute as InstantQuote,
  publicRoute as PublicInstantQuote,
} from '../pages/instant-quote/instant-quote.routes';
import DraftApplications from '../pages/draft-applications/draft-applications.routes';
import UserManagement from '../pages/user-management/user-management.routes';
import CreateUser from '../pages/create-user/create-user.routes';
import UserEdit from '../pages/user-edit/user-edit.routes';
import {
  routeWithAuth as NewApplication,
  publicRoute as PublicNewApplication,
} from '../pages/new-application/new-application.routes';
import SubmittedApplications from '../pages/submitted-applications/submitted-applications.routes';
import CreateNewQuote from '../pages/instant-quote/create-instant-quote.routes';
import EmailAction from '../pages/email-action/email-action.routes';
import QuoteList from '../pages/quote-list/quote-list.routes';
import ForgotPassword from '../pages/forgot-password/forgot-password.routes';
import ChangePassword from '../pages/change-password/change-password.routes';
import {
  publicRoute as QuoteToApplicationPublic,
  routeWithAuth as QuoteToApplication,
} from '../pages/quote-to-application/quote-to-application.routes';
import ChangeEmail from '../pages/change-email/change-email.routes';
import VendorSettings from '../pages/vendor-settings/vendor-settings.routes';
import VendorSettingsEdit from '../pages/vendor-settings-edit/vendor-settings-edit.routes';
import MyProfile from '../pages/my-profile/my-profile.routes';
import SearchResult from '../pages/search-result/search-result.routes';
import VendorList from '../pages/broker-vendors-list/vendors-list.routes';
import VendorWithUsersList from '../pages/broker-vendor-with-users-list/vendor-with-users-list.routes';
import UserList from '../pages/broker-users-list/users-list.routes';
import AuditTrail from '../pages/audit-trail/audit-trail.routes';
import EmailTemplatesList from '../pages/email-templates-list/email-templates-list.routes';
import EmailTemplate from '../pages/email-template/email-template.routes';
import { actions as toastActions } from '../store/toast';

const mapStateToProps = () => ({
  routes: [
    ...Signin,
    ...Signup,
    ...Health,
    ...ForgotPassword,
    ...ChangePassword,
    ...EmailAction,
    ...ChangeEmail,
  ],
  publicRoutes: [...QuoteToApplicationPublic, ...PublicInstantQuote, ...PublicNewApplication],
  routesRequireLogin: [
    ...QuoteToApplication,
    ...NewApplication,
    ...DraftApplications,
    ...SubmittedApplications,
    ...InstantQuote,
    ...Application,
    ...CreateNewQuote,
    ...QuoteList,
    ...UserManagement,
    ...CreateUser,
    ...UserEdit,
    ...VendorSettings,
    ...VendorSettingsEdit,
    ...MyProfile,
    ...SearchResult,
    ...AuditTrail,
    ...EmailTemplatesList,
    ...EmailTemplate,
    ...VendorList,
    ...VendorWithUsersList,
  ],
  brokerRepRoutes: [...UserEdit, ...MyProfile, ...VendorList, ...UserList, ...VendorWithUsersList],
});

const mapDispatchToProps = {
  closeToast: () =>
    toastActions.close() /* need to pass nothing as we may have a warnings about non-serializable action value even if we dont handle it*/,
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
