import { isString } from 'lodash';
import { getBackendUrl } from './getBackendUrl';
import { IoProgress } from '../services/websockets';
import { useLocation } from 'react-router-dom';
import { loginAsVendorUser as loginAsVendorUserRequest } from '../apis/broker-rep';
import { logoutAsVendorUser as logoutAsVendorUserRequest } from '../apis/users';
import { IGetRowsParams } from '@ag-grid-community/all-modules';

const defaultSort = (a: number, b: number) => (a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN); // default aggrid sort

export const ascendingSort = (a: number | string, b: number | string) => {
  const isStrings = isString(a) && isString(b);
  if (!isStrings) {
    return defaultSort(a as number, b as number);
  }

  const lowerA = (a as string).toLowerCase();
  const lowerB = (b as string).toLowerCase();

  if (lowerA < lowerB) {
    return -1;
  } else if (lowerA > lowerB) {
    return 1;
  } else {
    return lowerA === lowerB ? 0 : NaN;
  }
};

export const localeCompareSort = (a: string, b: string) => {
  const lowerA = a || '';
  const lowerB = b || '';
  return lowerB.localeCompare(lowerA);
};

const changeAttributesValues = (imgElement: HTMLImageElement, fromAttr: string, toAttr: string) => {
  let fromValue = imgElement.getAttribute(fromAttr);
  let toValue = imgElement.getAttribute(toAttr);

  if (!fromValue?.startsWith('cid:') && !fromValue?.startsWith('http')) {
    fromValue = `${getBackendUrl()}/static/${fromValue}`;
  }
  if (!toValue?.startsWith('cid:') && !toValue?.startsWith('http')) {
    toValue = `${getBackendUrl()}/static/${toValue}`;
  }

  imgElement.setAttribute(toAttr, fromValue);
  imgElement.setAttribute(fromAttr, toValue);
};

export const swapImgAttr = (html: string, fromAttr: string, toAttr: string) => {
  const element = document.createElement('div');
  element.innerHTML = html;
  const imgElements = element.querySelectorAll(`[${fromAttr}]`) as NodeListOf<HTMLImageElement>;

  if (!imgElements.length) {
    return html;
  }

  imgElements.forEach((element) => changeAttributesValues(element, fromAttr, toAttr));

  return element.innerHTML;
};

export const isSinValid = (sin: string) => {
  if (['8', '9', '0'].includes(sin[0])) {
    return false;
  }

  const sumOfDigits = (num: number) => {
    return num
      .toString()
      .split('')
      .reduce((acc, singleNum) => {
        return acc + parseInt(singleNum);
      }, 0);
  };

  const constantChars = '121212121'.split('');
  const sinChars = sin.trim().split('');

  const total = sinChars.reduce((acc, sinChar, i) => {
    const product = parseInt(sinChar) * parseInt(constantChars[i]);

    return acc + (product >= 10 ? sumOfDigits(product) : product);
  }, 0);

  return total % 10 === 0;
};

export const isPublicRoute = (routeParams: Record<string, unknown>) => {
  return !!routeParams.vendorSiteUrl;
};

export const getPublicRoute = (route: string, routeParams: Record<string, unknown>) => {
  return `/${routeParams.vendorSiteUrl}${
    routeParams.userSiteUrl ? '/' + routeParams.userSiteUrl : ''
  }${route}`;
};

export const buildStringPath = (parts: string[]) => {
  return parts.reduce((acc, part) => {
    if (!part.length) {
      return acc;
    } else {
      part = part[0] === '.' ? part.slice(1) : part;
      return acc[acc.length - 1] === '.' ? acc + part : acc + (acc.length ? '.' : '') + part;
    }
  }, '');
};

export const SIN_NUMBERS_LENGTH = 9;
export const SIN_TOTAL_LENGTH = 11;
export const DEFAULT_ADULT_AGE = 18;
export const DEFAULT_CHILD_AGE = 0;

export const formatBufferProgress = (loaded: number, total: number): IoProgress => {
  return {
    type: 'buffer',
    uuid: '',
    bytesRead: loaded,
    bytesLeft: total - loaded,
    progress: (loaded / total) * 100,
  };
};

export const useQuery = () => {
  const loc = useLocation();

  return new URLSearchParams(loc.search);
};

export const getFullUserName = (firstName?: string, lastName?: string) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return firstName || lastName || '';
};

export const logInAsVendorUser = async (userUuid: string) => {
  await loginAsVendorUserRequest(userUuid);

  location.replace('/');
};

export const logoutAsVendorUser = async () => {
  await logoutAsVendorUserRequest();

  location.replace('/');
};

export const buildPaginationFilterQuery = (params: FetchSortRequestListType) => {
  const limit = params.limit;
  const offset = params.offset || 0;
  const sortKey = params.sortKey ? `&sortKey=${params.sortKey}` : '';
  const sortDir = params.sortDir && sortKey ? `&sortDir=${params.sortDir.toUpperCase()}` : '';
  const filters = params.filters?.length
    ? `&filters=${encodeURIComponent(JSON.stringify(params.filters))}`
    : '';
  return `?limit=${limit}&offset=${offset * limit}${sortKey}${sortDir}${filters}`;
};

export const buildAGPaginationFilterQuery = (params: IGetRowsParams) => {
  const { colId: sortKey, sort: sortDir } = params.sortModel?.[0] ?? {};
  const limit = params.endRow - params.startRow;
  const offset = params.startRow;
  const sortKeyQuery = sortKey ? `&sortKey=${sortKey}` : '';
  const sortDirQuery = sortDir && sortKey ? `&sortDir=${sortDir.toUpperCase()}` : '';
  const filters = Reflect.ownKeys(params.filterModel).reduce<FetchSortRequestListFilterType[]>(
    (acc, field) => {
      const value = params.filterModel[field];
      if (value.type === 'inRange') {
        if (value.dateTo) {
          const date = new Date(value.dateTo);
          date.setHours(23, 59, 59);
          acc.push({
            field: field as string,
            type: 'lessThen',
            value: date.toISOString(),
          });
        }
        if (value.dateFrom) {
          const date = new Date(value.dateFrom);
          acc.push({
            field: field as string,
            type: 'greaterThen',
            value: date.toISOString(),
          });
        }
      } else {
        acc.push({
          field: field as string,
          type: value.type || 'contains',
          value: value.filter,
        });
      }

      return acc;
    },
    [],
  );

  const filterQuerry = filters.length
    ? `&filters=${encodeURIComponent(JSON.stringify(filters))}`
    : '';

  return `?limit=${limit}&offset=${offset}${sortKeyQuery}${sortDirQuery}${filterQuerry}`;
};

export function toFlatPropertyMap(
  obj: Record<string, unknown>,
  exclude?: string[],
  keySeparator = '.',
) {
  const flattenRecursive = (
    obj: Record<string, unknown>,
    parentProperty?: string,
    propertyMap: Record<string, unknown> = {},
  ) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key;
      const isExcluded = exclude?.some((prop) => parentProperty?.endsWith(prop));
      if (value && typeof value === 'object' && !isExcluded) {
        flattenRecursive(value as Record<string, unknown>, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
}
