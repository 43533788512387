export const SOLE_BUSINESS_TYPE = {
  value: 'Sole Proprietorship',
  label: 'constants.sole_proprietorship', // "Sole Proprietorship"
};
export const BUSINESS_TYPE = [
  {
    value: 'Corporation',
    label: 'constants.corporation', // "Corporation"
  },
  {
    value: 'Partnership',
    label: 'constants.partnership', // "Partnership"
  },
  SOLE_BUSINESS_TYPE,
];
export const BUSINESS_APPLICANT_TYPE = {
  value: true,
  label: 'applicant.business', // "Business"
};

export const INDIVIDUAL_APPLICANT_TYPE = {
  value: false,
  label: 'applicant.individual', // "Individual"
};

export const ENTITY_TYPE = [BUSINESS_APPLICANT_TYPE, INDIVIDUAL_APPLICANT_TYPE];

export const LANGUAGES = [
  { value: 'EN', label: 'English' },
  { value: 'FR', label: 'French' },
];

export const DOCUMENT_LANGUAGES = [{ value: 'Lease Contract', label: 'English', key: 'EN' }];

export const CO_LESSEE_ROLE = 'Co-lessee';
export const ATTENTION_TO_ROLE = 'Attention To';
export const GUARANTOR_ROLE = 'Guarantor';

export const ENTITY_ROLE = [
  { value: CO_LESSEE_ROLE, label: 'applicant.co_lessees' }, // "Co-Lessee"
  { value: GUARANTOR_ROLE, label: 'constants.guarantor' }, // "Guarantor"
];

export const COUNTRY_TYPE = [
  { value: 'CA', label: 'Canada' },
  { value: 'US', label: 'United States' },
];

export const ADDRESS_TYPE = [
  { value: 'poBox', label: 'common.po_box' }, // "PO Box"
  { value: 'streetAddress', label: 'constants.street_address' }, //"Street Address"
];

export const USER_ROLE = [
  { value: 'Vendor User', label: 'user_managment.vendor_user' }, // "Vendor User"
  { value: 'Vendor Admin', label: 'user_managment.vendor_admin' }, // "Vendor Admin"
];

export const DEFAULT_LANGUAGE = [
  { value: 'EN', label: 'English' },
  { value: 'FR', label: 'French' },
];

export const EMAIL_OPTIONS = [
  { value: 'to', label: 'To' },
  { value: 'cc', label: 'Cc' },
  { value: 'bcc', label: 'Bcc' },
];

export const FILTER_OPTIONS = [
  { value: 'Contains', label: 'constants.contains' }, // ""
  { value: 'Not contains', label: 'constants.not_contains' }, // Not contains
  { value: 'Equals', label: 'constants.equals' }, // Equals
  { value: 'Not equal', label: 'constants.not_equal' }, // Not equal
  { value: 'Starts with', label: 'constants.starts_with' }, // Starts with
  { value: 'Ends with', label: 'constants.ends_with' }, // Ends with
];

export const QUOTE_GENERATED = 'Quote Generated';
export const QUOTE_EMAILED = 'Quote Emailed';
export const CUSTOMER_DECLINED = 'Customer Declined';
export const UNSUBSCRIBED = 'Unsubscribed';
export const SUBMITTED = 'Submitted';
export const IN_PROGRESS = 'In Progress';

export const US_STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const CA_STATES: { [key: string]: { label: string; value: string }[] } = {
  en: [
    { label: 'Alberta', value: 'AB' },
    { label: 'British Columbia', value: 'BC' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'New Brunswick', value: 'NB' },
    { label: 'Newfoundland and Labrador', value: 'NL' },
    { label: 'Nova Scotia', value: 'NS' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Prince Edward Island', value: 'PE' },
    { label: 'Quebec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
  ],
  fr: [
    { label: 'Alberta', value: 'AB' },
    { label: 'Colombie-Britannique', value: 'BC' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'Nouveau-Brunswick', value: 'NB' },
    { label: 'Terre-Neuve-et-Labrador', value: 'NL' },
    { label: 'Nouvelle-Écosse', value: 'NS' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Île-du-Prince-Édouard', value: 'PE' },
    { label: 'Québec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
  ],
};

export const getStateOptionsByCode = (code: string, lang = 'en') => {
  switch (code) {
    case 'US':
      return US_STATES;
    case 'CA':
    default:
      return CA_STATES[lang];
  }
};

export const paymentOptions = [
  { label: '$10', value: '$10' },
  { label: '10%', value: '10%' },
  { label: 'FMV', value: 'FMV' },
  { label: 'TRAC', value: 'TRAC' },
  { value: 'Rental', label: 'constants.rental' }, // "Rental"
  { value: 'Straight', label: 'constants.straight' }, // "Straight"
];

export const frequencies = [
  { value: 'Monthly', label: 'document.payment_monthly' }, // "Monthly"
  { value: 'Quarterly', label: 'document.payment_quarterly' }, // "Quarterly"
  { value: 'Semi-Annually', label: 'document.payment_semi_annually' }, // "Semi-Annually"
  { value: 'Annually', label: 'document.payment_annually' }, // "Annually"
  { value: 'Seasonally', label: 'document.payment_seasonally' }, // "Seasonally"
];

export const EMAIL_TEMPLATE_ALL_TYPES = [
  { label: 'Custom', value: 'email_templates.type_custom' }, // "custom"
  { label: 'Default', value: 'email_templates.type_default' }, // "default"
  { label: 'System', value: 'email_templates.type_system' }, // "system"
];

export const EMAIL_TEMPLATE_AVAILABLE_TYPES = [
  { label: 'Custom', value: 'custemail_templates.type_customom' }, // "custom"
  { label: 'Default', value: 'email_templates.type_default' }, // "default"
  { label: 'System', value: 'email_templates.type_system' }, // "system"
];
