import { connect, ConnectedProps } from 'react-redux';

import UserEdit from './user-edit.component';
import { actions as vendorSettingsActions } from '../../store/vendorSettings';
import { actions as userOnEditActions } from '../../store/userOnEdit';

const mapStateToProps = (state: AppStoreType) => ({
  userRole: state.user.role,
  userOnEdit: state.userOnEdit.user,
  vendorName: state.vendorSettings.vendor.companyName,
  vendorActive: state.vendorSettings.vendor.active,
  isLoading: state.userOnEdit.isLoading,
  isSidebar: state.settings.isSidebarOpened,
});

const mapDispatchToProps = {
  getVendorById: vendorSettingsActions.getVendorById,
  fetchVendorsList: vendorSettingsActions.fetchVendorsList,
  fetchUserOnEdit: userOnEditActions.fetchUserOnEdit,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type UserEditConnectedProps = ConnectedProps<typeof connector>;

export default connector(UserEdit);
