import ChangePassword from './change-password.component';
import ChangeExpiredPassword from './change-expired-password.component';

export default [
  {
    path: '/change-password/:token',
    name: 'change-password',
    component: ChangePassword,
    exact: true,
  },
  {
    path: '/change-expired-password',
    name: 'change-expired-password',
    component: ChangeExpiredPassword,
    exact: true,
  },
];
