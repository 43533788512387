import { GridOptions } from '@ag-grid-community/all-modules';
import { useIntl } from 'react-intl';
import { commonColumnTypes } from './common';
import { INSTANT_QUOTE_PAGE } from '../../../../constants/navigations';
import { getQuoteStatusesMap } from '../../../../constants/language';
import { HeaderComponent } from '../components/HeaderComponent';

// all possible columns in app
// each property must have it's own type

export const useQuoteColumnTypes = () => {
  const intl = useIntl();

  const columnTypes: GridOptions['columnTypes'] = {
    'quote:idLabelWithLink': {
      ...commonColumnTypes['common:button'],
      field: 'idLabel',
      headerName: 'ID',
      cellRendererParams: {
        path: INSTANT_QUOTE_PAGE.path,
        pathParams: {
          id: 'uuid',
        },
      },
    },
    'quote:equipmentCost': {
      ...commonColumnTypes['common:cost'],
      field: 'equipmentCost',
      headerComponentParams: { name: 'application.equipment_cost' },
      headerComponentFramework: HeaderComponent,
      width: 150,
      cellRendererParams: {
        currency: 'equipmentCostCurrencyType',
      },
    },
    'quote:status': {
      ...commonColumnTypes['common:status'],
      field: 'statusName',
      headerComponentParams: { name: 'common.status' },
      headerComponentFramework: HeaderComponent,
      minWidth: 240,
      cellRendererParams: {
        statusColor: 'statusColor',
      },
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        statusesMap: getQuoteStatusesMap(intl),
      },
    },
    'quote:companyName': {
      ...commonColumnTypes['common:text'],
      field: 'companyName',
      width: 150,
      headerComponentParams: { name: 'company.name' },
      headerComponentFramework: HeaderComponent,
    },
    'quote:customerEmail': {
      ...commonColumnTypes['common:text'],
      field: 'customerEmail',
      width: 150,
      headerComponentParams: { name: 'application.customer_email' },
      headerComponentFramework: HeaderComponent,
    },
    'quote:createdAt': {
      ...commonColumnTypes['common:date'],
      field: 'createdAt',
      width: 250,
      headerComponentParams: { name: 'application.created_on' },
      headerComponentFramework: HeaderComponent,
    },
    'quote:updatedAt': {
      ...commonColumnTypes['common:date'],
      field: 'updatedAt',
      width: 250,
      headerName: 'Updated On',
      headerComponentParams: { name: 'common.updated_on' },
      headerComponentFramework: HeaderComponent,
    },
    'quote:selectAll': {
      ...commonColumnTypes['common:selectAll'],
    },
  };

  return columnTypes;
};
