import { connect, ConnectedProps } from 'react-redux';

import { actions as userManagementActions } from '../../store/userManagement';
import UserManagement from './user-management.component';

const mapStateToProps = (state: AppStoreType) => ({
  vendorUuid: (state.user as UserBasedStoreType).vendorUuid,
});

const mapDispatchToProps = {
  fetchUsersList: userManagementActions.fetchUsersList,
  activateUsers: userManagementActions.activateUsers,
  deactivateUsers: userManagementActions.deactivateUsers,
  resetUsersPassword: userManagementActions.resetUsersPassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type UserManagementConnectedProps = ConnectedProps<typeof connector>;

export default connector(UserManagement);
