import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    textAlign: 'left',
  },
  button: {
    margin: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(0),
  },
  pullLeft: {
    float: 'left',
  },
  pullRight: {
    float: 'right',
  },
  groupTitle: {
    width: '100%',
    boxShadow: 'none',
    marginTop: theme.spacing(1),
    '&.Mui-expanded': {
      marginTop: theme.spacing(1),
      padding: 0,
    },
  },
  defaultGroupTitle: {
    display: 'none',
  },
  mainGroupTitle: {
    fontSize: '14px',
    backgroundColor: '#F2F2F2',
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
    '&.Mui-expanded > .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  fieldGroup: {
    position: 'relative',
  },
  warningIcon: {
    position: 'absolute',
    right: 0,
    top: '20px',
    color: '#ff9800',
  },
}));

export default useStyles;
