import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

import { getFormattedDate } from '../../../services/formatter-service';

import useStyles from './styles';
import { UserStatus } from './user-status';

type UserHeaderCardProps = {
  firstName: string;
  lastName: string;
  vendorName: string;
  lastLogin: Date;
  createdAt?: Date;
  active?: boolean;
  status?: {
    status: string;
    color: string;
  };
  statusColor?: string;
  isSidebar?: boolean;
  handleChangeStatus?: () => void;
  handleResetPass: () => void;
  handleLogInAs?: () => void;
  canLogInAs?: boolean;
  isUserManagement?: boolean;
  isReadOnly?: boolean;
  vendorActive?: boolean;
  updatedAt?: string;
};

const UserHeaderCard = (props: UserHeaderCardProps) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    firstName,
    lastName,
    vendorName,
    lastLogin,
    handleResetPass,
    handleChangeStatus,
    isUserManagement,
    status,
    active,
    isReadOnly,
    handleLogInAs,
    canLogInAs,
    isSidebar,
    vendorActive,
    updatedAt,
  } = props;
  const lastLoginDate = getFormattedDate(lastLogin);
  const updatedAtDate = getFormattedDate(updatedAt);
  const gridLgWidth = isSidebar ? 7 : 6;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={11} md={9} lg={gridLgWidth}>
        <Card>
          <CardContent className={classes.cardContentWrapper}>
            <Grid container className={classes.headerWrapper} direction="row">
              <Grid item sm={12} className={classes.userStatusWrapper}>
                <Typography
                  component="h6"
                  variant="h6"
                  data-cy="title"
                  className={classes.userNameWrap}
                >
                  {`${firstName} ${lastName}`}
                </Typography>
                <UserStatus status={status?.status} color={status?.color} />
              </Grid>
              <Grid item sm={12} className={classes.controlButtonsWrap}>
                {!isReadOnly && (
                  <Button
                    data-cy="reset-password-btn"
                    size="medium"
                    variant="contained"
                    color="default"
                    disabled={!active || isReadOnly}
                    onClick={handleResetPass}
                    className={clsx(classes.resetPassButton, {
                      [classes.resetPassButtonAligned]: !isUserManagement,
                    })}
                  >
                    {intl.formatMessage({ id: 'user.reset_password' })}
                  </Button>
                )}
                {isUserManagement && !isReadOnly && (
                  <Button
                    data-cy="deactivate-btn"
                    size="medium"
                    variant="contained"
                    color="default"
                    disabled={isReadOnly}
                    onClick={handleChangeStatus}
                    className={classes.changeStatusButton}
                  >
                    {intl.formatMessage({ id: active ? 'button.deactivate' : 'button.activate' })}
                  </Button>
                )}
                {canLogInAs && !!handleLogInAs && (
                  <Button
                    data-cy="login-as-btn"
                    size="medium"
                    variant="contained"
                    color="primary"
                    disabled={!vendorActive || !active}
                    onClick={handleLogInAs}
                    className={classes.loginAsButton}
                  >
                    {intl.formatMessage({ id: 'button.login_as_this_user' })}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} lg={10} className={classes.userInfoWrapper}>
                <div>
                  <Typography
                    data-cy="company-label"
                    component="h6"
                    variant="caption"
                    className={classes.userInfoTextWrapper}
                  >
                    {intl.formatMessage({ id: 'common.company' })}:
                  </Typography>
                  <span data-cy="company-name" className={classes.userInfoText}>
                    {vendorName}
                  </span>
                </div>
                {lastLoginDate && (
                  <div>
                    <Typography
                      data-cy="last-login-label"
                      component="h6"
                      variant="caption"
                      className={classes.userInfoTextWrapper}
                    >
                      {intl.formatMessage({ id: 'common.last_login' })}:
                    </Typography>
                    <span data-cy="last-login" className={classes.userInfoText}>
                      {lastLoginDate}
                    </span>
                  </div>
                )}
                <div>
                  <Typography
                    data-cy="user-created-label"
                    component="h6"
                    variant="caption"
                    className={classes.userInfoTextWrapper}
                  >
                    {intl.formatMessage({ id: 'common.last_update' })}:
                  </Typography>
                  <span data-cy="user-created" className={classes.userInfoText}>
                    {updatedAtDate}
                  </span>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserHeaderCard;
