import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#edeff1',
    fontWeight: 500,
    //color: '#6d6d6d',
  },
  body: {
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))(TableCell);

const StyledMainTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F8F8F8',
    fontWeight: 'bolder',
    color: '#000000',
  },
  body: {
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  flexPaddingRight: {
    display: 'flex',
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0',
    },
  },
  detailsLabel: {
    marginTop: '0.3rem',
    fontWeight: 'bolder',
  },
  expandCell: {
    width: '5%',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
  },
  partyDetailsSection: {
    width: '100%',
    marginTop: '0.2rem',
    marginBottom: '0.5rem',
    padding: '0.5rem',
  },
  detailsSectionLabel: {
    fontSize: '0.9rem',
    fontWeight: 'bolder',
    marginTop: '0.1rem',
    marginBottom: '0.1rem',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  documentRoot: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    width: '100%' + theme.spacing(3),
    margin: theme.spacing(-3, -3, -3),
    padding: '15px',
    '& hr': {
      opacity: '.3',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperTitle: {
    position: 'relative',
    top: '-15px',
    left: '-15px',
    marginBottom: theme.spacing(3),
    fontSize: '20px',
    fontWeight: 500,
    color: '#000000',
    textAlign: 'left',
  },
  paperActions: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  generateButton: {
    float: 'right',
  },
  documentButton: {
    margin: theme.spacing(1),
  },
  applicationHeader: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& $applicationTitle:first-child': {
      width: '70%',
      paddingRight: '20px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingRight: '0',
      },
    },
  },
  applicationTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  applicationTitleLabel: {
    fontSize: '0.7rem',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
  },
  vendorRep: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  vendorRepLabel: {
    fontSize: '0.7rem',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
  },
  summarySection: {
    fontSize: '0.9rem',
    fontWeight: 'bolder',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  equipmentTotalRow: {
    borderTop: '1px solid #777777',
  },
  headerRow: {
    borderBottom: '1px solid #777777',
  },
  smallMarginTop: {
    marginTop: theme.spacing(3),
  },
  smallMarginRight: {
    marginRight: theme.spacing(3),
  },
  tabPanelBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  tabPanelCaption: {
    fontSize: '20px',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: '#000000',
  },
  tabPanelSecondCaption: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  mirRow: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  role: {
    textTransform: 'uppercase',
    fontSize: 12,
  },
  applicantEmail: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  statusAdditionalInfo: {
    width: '100%',
    fontSize: '0.9rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  notAvailableContainer: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  notAvailableIcon: {
    width: '80px',
    height: '80px',
  },
  overrideDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '800px',
    },
  },
  dialogContent: {
    '&.MuiDialogContent-root': {
      padding: 0,
      overflowY: 'initial',
    },
  },
  dialogTitle: {
    padding: theme.spacing(2),
  },
  dialogSteps: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderBottom: '1px solid #E0E0E0',
    borderTop: '1px solid #E0E0E0',
    textAlign: 'center',
  },
  dialogStepNumber: {
    display: 'inline-block',
    verticalAlign: 'middle',
    background: '#4153AF',
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    fontSize: '14px',
    textAlign: 'center',
    borderRadius: '50%',
    color: '#ffffff',
    '& > svg': {
      marginTop: '2px',
    },
  },
  dialogStepTitle: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    color: 'rgba(158, 158, 158, 1)',
  },
  activeDialogStepTitle: {
    color: '#000000',
  },
  dialogActions: {
    width: '800px',
    textAlign: 'center',
    '& button': {
      margin: theme.spacing(2),
    },
  },
  iframePreview: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    border: 0,
    width: '800px',
    minHeight: '600px',
    maxWidth: '100%',
  },
  closeModalBtn: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  operatingName: {
    padding: '10px',
  },
  operatingNameTitle: {
    fontSize: '0.7rem',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
  },
  operatingNameData: {
    fontSize: '0.9rem',
    fontWeight: 'bolder',
  },
}));

export { useStyles, useRowStyles, StyledTableCell, StyledTableRow, StyledMainTableCell };
