import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    textAlign: 'left',
    margin: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
      minWidth: 120,
    },
    '& label': {
      maxWidth: 'calc(100% - 40px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  smallMarginTop: {
    marginTop: theme.spacing(3),
  },
  smallMarginRight: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  btnContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
