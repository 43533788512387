import React from 'react';
import CurrencyFormat from '../../currency-format';

export default function CostRenderer(props: {
  value: string;
  currency: string;
  data?: { [key: string]: string | number };
}) {
  const currency = (props.data?.[props.currency] as string | undefined) ?? 'CAD';

  return <CurrencyFormat value={props.value} currencyType={currency} />;
}
