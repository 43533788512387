import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { UseFormMethods, useFormContext } from 'react-hook-form';
import useStyles from './styles';
import VaultMenu from '../VaultMenu';
import PhoneFormatter from '../PhoneFormatter';
import AreaCodeFormatter from '../AreaCodeFormatter';
import AmountTextField from '../amount-text-field';
import clsx from 'clsx';
import TextInput from '../TextInput';
import { useIntl } from 'react-intl';
import { CURRENCY_TYPE } from '../../../constants/currency';

type InstantQuoteFormPropsType = {
  isAdmin: boolean;
  vendorProgramList: Array<{ value: string; label: string; isDefault: boolean }>;
  vendorReps: Array<{ value: string; label: string }>;
  reset: UseFormMethods['reset'];
  currency: CURRENCY_TYPE;
  isReadOnly: boolean;
  setCurrency: (c: CURRENCY_TYPE) => void;
  isPublic: boolean;
};

export default function InstantQuoteForm({
  vendorProgramList,
  isReadOnly,
  currency,
  setCurrency,
  vendorReps,
  isAdmin,
  isPublic,
  reset,
}: InstantQuoteFormPropsType) {
  const classes = useStyles();
  const intl = useIntl();
  const { getValues } = useFormContext();
  const isDefaultProgram = vendorProgramList.length
    ? vendorProgramList[0].isDefault && vendorProgramList.length === 1
    : false;
  const isVendorProgram = isPublic
    ? vendorProgramList?.length > 0 && !isDefaultProgram
    : vendorProgramList?.length > 0;

  const handleResetButton = () => {
    const values = getValues();

    return reset({ vendorRep: values.vendorRep });
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {intl.formatMessage({ id: 'company.customer_information' })}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextInput
            disabled={isReadOnly}
            name="companyName"
            label={intl.formatMessage({ id: 'company.name' })}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <TextInput
            name="firstName"
            label={intl.formatMessage({ id: 'common.first_name' })}
            fullWidth
            disabled={isReadOnly}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <TextInput
            name="middleName"
            label={intl.formatMessage({ id: 'common.middle_name' })}
            fullWidth
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            name="lastName"
            label={intl.formatMessage({ id: 'common.last_name' })}
            disabled={isReadOnly}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextInput
            name="customerEmail"
            label={intl.formatMessage({ id: 'common.email' })}
            disabled={isReadOnly}
            rules={{
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: intl.formatMessage({ id: 'common.email_rule' }),
              },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4}>
              <AreaCodeFormatter
                name="phoneAreaCode"
                label={intl.formatMessage({ id: 'common.area_code' })}
                fullWidth
                disabled={isReadOnly}
                phoneformatter={{ id: 'phoneNumber' }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <PhoneFormatter
                name="phoneNumber"
                disabled={isReadOnly}
                label={intl.formatMessage({ id: 'common.phone' })}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5}>
          <AmountTextField
            name="equipmentCost"
            required
            disabled={isReadOnly}
            currency={currency}
            allowZero={false}
            setCurrency={setCurrency}
            label={intl.formatMessage({ id: 'company.equipment_cost' })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={7}>
          <TextInput
            required
            name="equipmentDescription"
            label={intl.formatMessage({ id: 'company.equipment_description' })}
            disabled={isReadOnly}
            fullWidth
            rules={{
              required: intl.formatMessage({ id: 'company.equipment_description_rule' }),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {isVendorProgram && (
          <Grid item xs={12} sm={6}>
            <VaultMenu
              required
              disabled={isReadOnly || isDefaultProgram}
              options={vendorProgramList}
              className={classes.formControl}
              label={intl.formatMessage({ id: 'company.vendor_program' })}
              name="vendorProgram"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          {!!vendorReps?.length && (
            <VaultMenu
              required
              options={vendorReps}
              disabled={isReadOnly || vendorReps.length === 1 || !isAdmin}
              className={classes.formControl}
              label={intl.formatMessage({ id: 'company.vendor_rep' })}
              name="vendorRep"
            />
          )}
        </Grid>
      </Grid>
      <div className={classes.btnContainer}>
        <Button
          onClick={handleResetButton}
          variant="contained"
          color="default"
          disabled={isReadOnly}
          className={clsx(classes.button, classes.smallMarginRight)}
        >
          {intl.formatMessage({ id: 'button.reset' })}
        </Button>
        <Button
          disabled={isReadOnly || (!vendorProgramList?.length && !isPublic)}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          {intl.formatMessage({ id: 'company.get_instant_quote' })}
        </Button>
      </div>
    </>
  );
}
