import { createSelector } from 'reselect';

export const naicsSelector = createSelector(
  (state: AppStoreType) => state.settings.currentLanguage,
  (state: AppStoreType) => state.formValues.naicsList,
  (lang, naicsList) => {
    return naicsList
      .map((naics) => ({
        ...naics,
        label: lang === 'EN' ? naics.labelEn : naics.labelFr,
      }))
      .filter((naic) => naic.label) as Array<Naics & { label: string }>;
  },
);
