import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function AmendmentNotAvailableIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M77.2094 74.2093C77.2094 75.8661 75.8662 77.2093 74.2094 77.2093H5.79077C4.13392 77.2093 2.79077 75.8661 2.79077 74.2093V5.79072C2.79077 4.13386 4.13392 2.79071 5.79077 2.79071H74.2094C75.8662 2.79071 77.2094 4.13386 77.2094 5.79071V74.2093Z"
          fill="white"
        />
        <path
          d="M79.875 77C79.875 78.5878 78.5878 79.875 77 79.875H3C1.41218 79.875 0.125 78.5878 0.125 77V3C0.125 1.41218 1.41218 0.125 3 0.125H77C78.5878 0.125 79.875 1.41218 79.875 3V77ZM3.76522 73.1097C3.76522 74.8356 5.16433 76.2347 6.89022 76.2347H73.1098C74.8357 76.2347 76.2348 74.8356 76.2348 73.1097V6.89022C76.2348 5.16433 74.8357 3.76522 73.1098 3.76522H6.89023C5.16434 3.76522 3.76522 5.16433 3.76522 6.89022V73.1097Z"
          fill="white"
          stroke="black"
        />
        <path
          d="M65.0196 34.7823H18.9774C18.5122 34.7823 18.135 35.1594 18.135 35.6247C18.135 36.0899 18.5122 36.4671 18.9774 36.4671H65.0196C65.4848 36.4671 65.862 36.0899 65.862 35.6247C65.862 35.1594 65.4849 34.7823 65.0196 34.7823Z"
          fill="#4153AF"
        />
        <path
          d="M65.0196 39.1627H18.9774C18.5122 39.1627 18.135 39.5399 18.135 40.0051C18.135 40.4704 18.5122 40.8475 18.9774 40.8475H65.0196C65.4848 40.8475 65.862 40.4704 65.862 40.0051C65.862 39.5399 65.4849 39.1627 65.0196 39.1627Z"
          fill="#4153AF"
        />
        <path
          d="M65.0196 43.5432H18.9774C18.5122 43.5432 18.135 43.9204 18.135 44.3856C18.135 44.8508 18.5122 45.228 18.9774 45.228H65.0196C65.4848 45.228 65.862 44.8508 65.862 44.3856C65.862 43.9204 65.4849 43.5432 65.0196 43.5432Z"
          fill="#4153AF"
        />
        <path
          d="M15.238 36.971C15.8452 36.971 16.3375 36.4788 16.3375 35.8715C16.3375 35.2642 15.8452 34.772 15.238 34.772C14.6307 34.772 14.1384 35.2642 14.1384 35.8715C14.1384 36.4788 14.6307 36.971 15.238 36.971Z"
          fill="#4153AF"
        />
        <path
          d="M65.0196 15.907H18.9774C18.5122 15.907 18.135 16.2842 18.135 16.7494C18.135 17.2147 18.5122 17.5918 18.9774 17.5918H65.0196C65.4848 17.5918 65.862 17.2147 65.862 16.7494C65.862 16.2842 65.4848 15.907 65.0196 15.907Z"
          fill="#E5E5E5"
        />
        <path
          d="M65.0196 20.2875H18.9774C18.5122 20.2875 18.135 20.6647 18.135 21.1299C18.135 21.5952 18.5122 21.9723 18.9774 21.9723H65.0196C65.4848 21.9723 65.862 21.5952 65.862 21.1299C65.862 20.6647 65.4849 20.2875 65.0196 20.2875Z"
          fill="#E5E5E5"
        />
        <path
          d="M65.0196 24.668H18.9774C18.5122 24.668 18.135 25.0451 18.135 25.5104C18.135 25.9756 18.5122 26.3528 18.9774 26.3528H65.0196C65.4848 26.3528 65.862 25.9756 65.862 25.5104C65.862 25.0451 65.4848 24.668 65.0196 24.668Z"
          fill="#E5E5E5"
        />
        <path
          d="M15.238 17.9126C15.8452 17.9126 16.3375 17.4203 16.3375 16.813C16.3375 16.2058 15.8452 15.7135 15.238 15.7135C14.6307 15.7135 14.1384 16.2058 14.1384 16.813C14.1384 17.4203 14.6307 17.9126 15.238 17.9126Z"
          fill="#E5E5E5"
        />
        <path
          d="M65.0196 53.8408H18.9774C18.5122 53.8408 18.135 54.2179 18.135 54.6832C18.135 55.1484 18.5122 55.5255 18.9774 55.5255H65.0196C65.4848 55.5255 65.862 55.1484 65.862 54.6832C65.862 54.2179 65.4849 53.8408 65.0196 53.8408Z"
          fill="#E5E5E5"
        />
        <path
          d="M65.0196 58.2212H18.9774C18.5122 58.2212 18.135 58.5983 18.135 59.0636C18.135 59.5288 18.5122 59.906 18.9774 59.906H65.0196C65.4848 59.906 65.862 59.5288 65.862 59.0636C65.862 58.5983 65.4849 58.2212 65.0196 58.2212Z"
          fill="#E5E5E5"
        />
        <path
          d="M65.0196 62.6017H18.9774C18.5122 62.6017 18.135 62.9788 18.135 63.4441C18.135 63.9093 18.5122 64.2865 18.9774 64.2865H65.0196C65.4848 64.2865 65.862 63.9093 65.862 63.4441C65.862 62.9788 65.4849 62.6017 65.0196 62.6017Z"
          fill="#E5E5E5"
        />
        <path
          d="M15.238 55.8463C15.8452 55.8463 16.3375 55.354 16.3375 54.7467C16.3375 54.1395 15.8452 53.6472 15.238 53.6472C14.6307 53.6472 14.1384 54.1395 14.1384 54.7467C14.1384 55.354 14.6307 55.8463 15.238 55.8463Z"
          fill="#E5E5E5"
        />
      </g>
    </SvgIcon>
  );
}
