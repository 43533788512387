import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import useStyles from './styles';
import { useForm, UseFormMethods } from 'react-hook-form';
import Loading from '../Loading';
import {
  DOCUMENT_OVERRIDE_FORM_FIELD_TYPES,
  DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST,
  DOCUMENT_OVERRIDE_TYPES,
} from '../../../constants/document';
import get from 'lodash/get';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ApplicationDocumentOverrideFormField from '../ApplicationDocumentOverrideFormField';
import ApplicationDocumentOverrideFormTitleField from '../ApplicationDocumentOverrideFormTitleField';
import ApplicationDocumentOverrideFormPurchaseOptionField from '../ApplicationDocumentOverrideFormPurchaseOptionField';
import ApplicationDocumentOverrideFormLanguageField from '../ApplicationDocumentOverrideFormLanguageField';
import ApplicationDocumentOverrideFormPaymentFrequencyField from '../ApplicationDocumentOverrideFormPaymentFrequencyField';
import ApplicationDocumentOverrideFormCustomerInvoiceSwitcher from '../ApplicationDocumentOverrideFormCustomerInvoiceSwitcher';
import { translateLabel } from '../../../utils/translateLabel';

type ApplicationDocumentFormPropsType = {
  brokerApplication: ApplicationStoreType['brokerApplication'];
  setFormValues: Dispatch<SetStateAction<ApplicationDocumentValueTypes[]>>;
  setResetAvailability: (availability: boolean) => void;
  templates: ApplicationDocumentTemplate[];
  type: string;
  isReadOnly: boolean;
  savedFields?: Array<ApplicationDocumentOverridableFields>;
  register: ReturnType<typeof useForm>['register'];
  isCustomerSwitcherDisabled: boolean;
  setIsCustomerSwitcherDisabled: Dispatch<SetStateAction<boolean>>;
};

export default function ApplicationDocumentOverrideForm({
  brokerApplication,
  savedFields,
  setFormValues,
  setResetAvailability,
  templates,
  type,
  register,
  isCustomerSwitcherDisabled,
  setIsCustomerSwitcherDisabled,
}: ApplicationDocumentFormPropsType) {
  const classes = useStyles();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(true);
  const [documentValues, setDocumentValues] = useState<
    Array<ApplicationDocumentOverridableFieldGroup> | false
  >(false);
  const [actualValues, setActualValues] = useState<
    Array<ApplicationDocumentOverridableFieldGroup> | false
  >(false);
  const [documentLanguage, setDocumentLanguage] = useState<DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST>(
    DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST.EN,
  );

  const groupRestrictionsByTemplate = [
    {
      template: 'Catalyst_IPA',
      groups: ['catalystIpa.paymentInformation', 'catalystIpa.licenseAndFees'],
    },
    {
      template: 'Lease_Contract',
      groups: [
        'lease.eLocation',
        'lease.e1',
        'lease.e2',
        'lease.paymentInformation',
        'customerInvoice_payment',
      ],
    },
    {
      template: 'Catalyst_Rental_Contract',
      groups: [
        'catalystRental.eLocation',
        'catalystRental.e1',
        'catalystRental.e2',
        'catalystRental.paymentInformation',
      ],
    },
  ];

  useEffect(() => {
    setIsLoading(true);

    if (brokerApplication.data.common_overridable_fields) {
      setDocumentValues(getFieldsByGroups(brokerApplication.data.common_overridable_fields));
    } else {
      setDocumentValues([]);
    }
  }, [savedFields]);

  useEffect(() => {
    let data: ApplicationDocumentOverridableFieldGroup[] = [];

    if (savedFields && savedFields.length && type === DOCUMENT_OVERRIDE_TYPES.SIGNATURE) {
      setResetAvailability(true);
      data = getFieldsByGroups(savedFields);
    } else if (documentValues && documentValues.length) {
      data = documentValues;
      setResetAvailability(false);
    }

    setActualValues(data);
    const initialFormValues: ApplicationDocumentValueTypes[] = [];

    data.forEach((group: ApplicationDocumentOverridableFieldGroup) => {
      group.fields.forEach((field: ApplicationDocumentOverridableFields) => {
        initialFormValues.push({
          path: field.path,
          value: getActualValue(field),
        });
      });
    });

    setIsLoading(false);
    setFormValues(initialFormValues);
  }, [documentValues]);

  const getActualValue = (field: ApplicationDocumentOverridableFields) => {
    return field.value ?? convertPropertyValue(field.path);
  };

  const convertPropertyValue = (propertyName: string) => {
    if (propertyName.charAt(0) === '$') {
      propertyName = propertyName.substring(2);
    }

    return get(brokerApplication.data, propertyName, false);
  };

  const isCustomerInvoiceSwitcher = (
    overridableGroup: ApplicationDocumentOverridableFieldGroup,
  ) => {
    if (isLeaseContractTemplate() && overridableGroup.id === 'customerInvoice_payment') {
      return true;
    }

    return false;
  };

  const isCustomerInvoiceField = (overridableGroup: ApplicationDocumentOverridableFieldGroup) => {
    if (overridableGroup.id === 'customerInvoice_payment') {
      return true;
    }

    return false;
  };

  const isLeaseContractTemplate = () => {
    return templates.find((t) => t.alias === 'Lease_Contract');
  };

  /**
   * Convert fields by Group
   */
  const getFieldsByGroups = (overridableFields: Array<ApplicationDocumentOverridableFields>) => {
    let restrictedGroups: Array<string> = [];
    let chosenRestrictedDuplicates: Array<string> = [];

    for (const templateGroup of groupRestrictionsByTemplate) {
      const templateChosen = templates.find((t) => t.alias === templateGroup.template);

      if (templateChosen) {
        chosenRestrictedDuplicates = [...chosenRestrictedDuplicates, ...templateGroup.groups];
        continue;
      }

      restrictedGroups = [...restrictedGroups, ...templateGroup.groups];
    }

    // restrict duplicates
    restrictedGroups = restrictedGroups.filter(
      (value) => !chosenRestrictedDuplicates.includes(value),
    );

    const groups: Array<ApplicationDocumentOverridableFieldGroup> = [];
    const overridableFieldGroupings =
      brokerApplication?.data.common_overridable_field_groupings ?? [];

    for (const overridableField of overridableFields) {
      let isSignerInfo = false;
      const opened = overridableField.required;
      const field = Object.assign({}, overridableField);

      if (
        (field.path.match(/^\$\.signors\[[0-9]\]\.title$/) ||
          field.path.match(/^\$\.signors\[[0-9]\]\.phone$/)) &&
        type !== DOCUMENT_OVERRIDE_TYPES.SIGNATURE
      ) {
        field.required = false;
      }

      if (field.path.match(/^\$\.signors\[[0-9]\]\.title$/)) {
        field.type = DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.SIGNOR_TITLE;
      }

      if (field.path.match(/^\$\.signors\[[0-9]\]\./)) {
        isSignerInfo = true;
      }

      if (field.path === '$.language') {
        if (
          field.value &&
          Object.values(DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST).includes(
            field.value as DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST,
          )
        ) {
          setDocumentLanguage(field.value as DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST);
        } else {
          const userLanguage = intl.locale;
          field.value = userLanguage;
          setDocumentLanguage(userLanguage as DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST);
        }
      }

      if (field.grouping_id) {
        const groupIndex = groups.findIndex((group) => group.id === field.grouping_id);

        if (groupIndex !== -1 && groups[groupIndex]) {
          if (!groups[groupIndex].opened && opened) {
            groups[groupIndex].opened = true;
          }

          if (isSignerInfo) {
            groups[groupIndex].isSignerGroup = true;
          }

          // special for temporary hidden group fields
          if (groups[groupIndex].isHidden) {
            field.required = false;
          }

          groups[groupIndex].fields.push(field);
        } else {
          const overridableFieldGroupIndex = overridableFieldGroupings.findIndex(
            (group) => group.id === field.grouping_id,
          );

          if (overridableFieldGroupIndex === -1) {
            continue;
          }

          groups.push({
            id: overridableFieldGroupings[overridableFieldGroupIndex].id,
            label: overridableFieldGroupings[overridableFieldGroupIndex].label,
            fields: [field],
            isDefault: false,
            isHidden:
              restrictedGroups.indexOf(overridableFieldGroupings[overridableFieldGroupIndex].id) >
              -1,
            opened: opened ?? false,
            isSignerGroup: isSignerInfo,
          });
        }
      } else {
        const groupIndex = groups.findIndex((group) => group.isDefault);

        if (groupIndex !== -1 && groups[groupIndex]) {
          groups[groupIndex].fields.push(field);
        } else {
          groups.push({
            id: '',
            label: '',
            fields: [field],
            isDefault: true,
            isHidden: false,
            opened: true,
            isSignerGroup: false,
          });
        }
      }
    }

    const otherGroups = [];
    const signerGroups = [];
    let defaultGroup: ApplicationDocumentOverridableFieldGroup | null = null;

    for (const group of groups) {
      if (group.isSignerGroup) {
        signerGroups.push(group);
      } else if (group.id === '') {
        defaultGroup = group;
      } else {
        otherGroups.push(group);
      }
    }

    let sortedGroups = [];

    if (defaultGroup) {
      sortedGroups = [...signerGroups, ...otherGroups, ...[defaultGroup]];
    } else {
      sortedGroups = [...signerGroups, ...otherGroups];
    }

    // move language field to the second position and open accordion section
    const langIdx = sortedGroups.findIndex((group) => group.id === 'language');
    if (langIdx) {
      sortedGroups[langIdx].opened = true;
      sortedGroups.splice(0, 0, sortedGroups.splice(langIdx, 1)[0]);
    }

    return sortedGroups;
  };

  const onChangeField = useCallback(
    (
      path: string,
      value: string | number | boolean | DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST | null,
      control?: UseFormMethods['control'],
      name?: string,
      isDateField?: boolean,
    ) => {
      // special listener for change document language
      if (
        path === '$.language' &&
        value &&
        Object.values(DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST).includes(
          value as DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST,
        )
      ) {
        setDocumentLanguage(value as DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST);
      }

      if (isDateField && control && name) {
        control.setValue(name, value);
      }

      setFormValues((prevState: ApplicationDocumentValueTypes[]) => {
        const updatedState: ApplicationDocumentValueTypes[] = [...prevState];

        const changedFormIndex: number = updatedState.findIndex(
          (field: ApplicationDocumentValueTypes) => {
            return field.path === path;
          },
        );

        if (changedFormIndex >= 0) {
          updatedState[changedFormIndex] = {
            ...updatedState[changedFormIndex],
            value: value,
          };
        }

        return updatedState;
      });
    },
    [setFormValues],
  );

  return (
    <>
      {isLoading && (
        <Paper>
          <Loading isOpen={isLoading} />
        </Paper>
      )}

      {!isLoading &&
        actualValues &&
        actualValues.map(
          (overridableGroup: ApplicationDocumentOverridableFieldGroup, groupIndex) =>
            overridableGroup &&
            !overridableGroup.isHidden && (
              <Accordion
                data-cy={`form-group-${overridableGroup.id}`}
                className={classes.groupTitle}
                defaultExpanded={overridableGroup.isDefault || overridableGroup.opened}
                key={`panel-${groupIndex}`}
              >
                <AccordionSummary
                  expandIcon={
                    overridableGroup.isDefault ? (
                      false
                    ) : (
                      <ExpandMoreIcon data-cy="form-group-expand-btn" />
                    )
                  }
                  disabled={overridableGroup.isDefault}
                  aria-controls={`panel-${groupIndex}-content`}
                  id={`panel-${groupIndex}`}
                  key={`panel-${groupIndex}-summary`}
                  className={
                    overridableGroup.isDefault ? classes.defaultGroupTitle : classes.mainGroupTitle
                  }
                >
                  <Typography>{translateLabel(intl, overridableGroup.label)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} key={`panel-${groupIndex}-details`}>
                    <Grid container spacing={3}>
                      {isCustomerInvoiceSwitcher(overridableGroup) && (
                        <Grid item xs={12}>
                          <ApplicationDocumentOverrideFormCustomerInvoiceSwitcher
                            defaultValue={isCustomerSwitcherDisabled}
                            setIsCustomerSwitcherDisabled={setIsCustomerSwitcherDisabled}
                          />
                        </Grid>
                      )}

                      {overridableGroup.fields.map((overridableField, index) => (
                        <Grid
                          hidden={
                            isCustomerInvoiceField(overridableGroup)
                              ? isCustomerSwitcherDisabled
                              : false
                          }
                          item
                          sm={
                            overridableField.type === DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.EMAIL ||
                            overridableField.type ===
                              DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.SIGNOR_TITLE ||
                            overridableField.type ===
                              DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.PURCHASE_OPTION
                              ? 12
                              : 6
                          }
                          xs={12}
                          key={index}
                        >
                          {overridableField.type ===
                            DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.SIGNOR_TITLE && (
                            <ApplicationDocumentOverrideFormTitleField
                              index={`${groupIndex}-${index}`}
                              field={overridableField}
                              originalValue={convertPropertyValue(overridableField.path)}
                              value={getActualValue(overridableField)}
                              onChangeField={onChangeField}
                              register={register}
                              documentLanguage={documentLanguage}
                            />
                          )}

                          {overridableField.type ===
                            DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.PAYMENT_FREQUENCY && (
                            <ApplicationDocumentOverrideFormPaymentFrequencyField
                              index={`${groupIndex}-${index}`}
                              field={overridableField}
                              originalValue={convertPropertyValue(overridableField.path)}
                              value={getActualValue(overridableField)}
                              onChangeField={onChangeField}
                              documentLanguage={documentLanguage}
                            />
                          )}

                          {overridableField.type ===
                            DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.PURCHASE_OPTION && (
                            <ApplicationDocumentOverrideFormPurchaseOptionField
                              index={`${groupIndex}-${index}`}
                              field={overridableField}
                              originalValue={convertPropertyValue(overridableField.path)}
                              value={getActualValue(overridableField)}
                              onChangeField={onChangeField}
                              register={register}
                            />
                          )}

                          {overridableField.type ===
                            DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.LANGUAGE && (
                            <ApplicationDocumentOverrideFormLanguageField
                              index={`${groupIndex}-${index}`}
                              field={overridableField}
                              originalValue={convertPropertyValue(overridableField.path)}
                              value={getActualValue(overridableField)}
                              onChangeField={onChangeField}
                              register={register}
                            />
                          )}

                          {overridableField.type !==
                            DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.SIGNOR_TITLE &&
                            overridableField.type !==
                              DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.PAYMENT_FREQUENCY &&
                            overridableField.type !== DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.LANGUAGE &&
                            overridableField.type !==
                              DOCUMENT_OVERRIDE_FORM_FIELD_TYPES.PURCHASE_OPTION && (
                              <ApplicationDocumentOverrideFormField
                                index={`${groupIndex}-${index}`}
                                field={overridableField}
                                originalValue={convertPropertyValue(overridableField.path)}
                                value={getActualValue(overridableField) ?? ''}
                                onChangeField={onChangeField}
                                register={register}
                                hide={
                                  isCustomerInvoiceField(overridableGroup)
                                    ? isCustomerSwitcherDisabled
                                    : false
                                }
                              />
                            )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ),
        )}
    </>
  );
}
