import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 3, 0, 3),
      flexGrow: 1,
      backgroundColor: '#ffffff',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 3, 0, 0),
      },
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperContainer: {
      padding: theme.spacing(2),
    },
    content: {
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        maxWidth: '75%',
      },
    },
    container: {
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(2),
    },
    vendorRepSection: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(2),
      },
    },
    vendorRepSectionLabel: {
      margin: 0,
    },
    vendorRepSectionFont: {
      fontSize: '0.875rem',
    },
    headerPlate: {
      width: '100%',
      backgroundColor: '#F2F2F2',
      fontSize: '0.8rem',
      padding: '15px 27px',
      paddingRight: '15px',
      marginBottom: '20px',
      fontWeight: 'bold',
      '& .MuiButtonBase-root ': {
        float: 'right',
        padding: '0',
        marginTop: '-5px',
        color: '#eb5757',
      },
    },
  }),
);

export { useStyles };
