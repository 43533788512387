import { ColDef, GridOptions } from '@ag-grid-community/all-modules';
import { useIntl } from 'react-intl';
import {
  BROKER_VENDOR_INFO_PAGE,
  BROKER_VENDOR_REPS_LIST_PAGE,
  USER_EDIT_PAGE,
} from '../../../constants/navigations';
import { getAdminRoleMap } from '../../../constants/language';
import { HeaderComponent } from './components/HeaderComponent';

export const defaultColDef: ColDef = {
  // set default width values
  width: 100,
  minWidth: 100,
  maxWidth: 400,
  // by default make every column not editable and not sortable
  editable: false,
  sortable: false,
  // rest default settings
  autoHeight: true,
  resizable: true,
  suppressMenu: true,
};

// all possible columns in app
// each property must have it's own type

export const useColumnTypes = () => {
  const intl = useIntl();

  const columnTypes: GridOptions['columnTypes'] = {
    vendorCompanyName: {
      sortable: true,
      filter: 'agTextColumnFilter',
      field: 'companyName',
      cellRenderer: 'buttonLink',
      suppressMenu: true,
      headerComponentParams: { name: 'company.name' },
      headerComponentFramework: HeaderComponent,
      floatingFilter: true,
      filterParams: {
        filterOptions: ['contains'],
      },
      cellRendererParams: {
        path: BROKER_VENDOR_INFO_PAGE.path,
        pathParams: {
          vendorUuid: 'uuid',
        },
      },
      floatingFilterComponent: 'simpleFloatingTextFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    email: {
      headerComponentParams: { name: 'company.email' },
      headerComponentFramework: HeaderComponent,
      filter: 'agTextColumnFilter',
      field: 'email',
      floatingFilter: true,
      sortable: true,
      filterParams: {
        filterOptions: ['contains'],
      },
      floatingFilterComponent: 'simpleFloatingTextFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    createdAt: {
      headerComponentParams: { name: 'company.created_at' },
      headerComponentFramework: HeaderComponent,
      field: 'createdAt',
      cellRenderer: 'dateRenderer',
      sortable: true,
    },
    companyWebsite: {
      headerName: intl.formatMessage({ id: 'common.website' }),
      headerComponentParams: { name: 'common.website' },
      headerComponentFramework: HeaderComponent,
      filter: 'agTextColumnFilter',
      field: 'website',
      floatingFilter: true,
      filterParams: {
        filterOptions: ['contains'],
      },
      sortable: true,
      floatingFilterComponent: 'simpleFloatingTextFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    firstName: {
      headerComponentParams: { name: 'common.first_name' },
      headerComponentFramework: HeaderComponent,
      filter: 'agTextColumnFilter',
      field: 'firstName',
      floatingFilter: true,
      filterParams: {
        filterOptions: ['contains'],
      },
      floatingFilterComponent: 'simpleFloatingTextFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    lastName: {
      headerComponentParams: { name: 'common.last_name' },
      headerComponentFramework: HeaderComponent,
      filter: 'agTextColumnFilter',
      field: 'lastName',
      floatingFilter: true,
      filterParams: {
        filterOptions: ['contains'],
      },
      floatingFilterComponent: 'simpleFloatingTextFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    formattedPhone: {
      headerComponentParams: { name: 'common.phone' },
      headerComponentFramework: HeaderComponent,
      filter: 'agTextColumnFilter',
      field: 'formattedPhone',
      floatingFilter: true,
      sortable: true,
      filterParams: {
        filterOptions: ['contains'],
      },
      floatingFilterComponent: 'simpleFloatingTextFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    userDetailsLink: {
      headerName: 'ID',
      cellRenderer: 'buttonLink',
      field: 'idLabel',
      cellRendererParams: {
        path: USER_EDIT_PAGE.path,
        pathParams: {
          userVendorUuid: 'uuid',
        },
      },
    },
    userLoginAsButton: {
      headerName: ' ',
      cellRenderer: 'loginAsButton',
      field: 'uuid',
    },
    vendorUsersLink: {
      headerName: '',
      cellRenderer: 'buttonLink',
      field: 'uuid',
      cellRendererParams: {
        path: BROKER_VENDOR_REPS_LIST_PAGE.path,
        queryParams: {
          companyName: 'companyName',
        },
        title: intl.formatMessage({ id: 'common.view_users' }),
      },
    },
    vendorDeleteLink: {
      headerName: '',
      cellRenderer: 'buttonConfirmation',
      field: 'uuid',
      cellRendererParams: {
        variant: 'vendor',
      },
    },
    userDeleteLink: {
      headerName: '',
      cellRenderer: 'buttonConfirmation',
      field: 'uuid',
      cellRendererParams: {
        variant: 'user',
      },
    },
    applicationDeleteLink: {
      headerName: '',
      cellRenderer: 'buttonConfirmation',
      field: 'uuid',
      cellRendererParams: {
        variant: 'application',
      },
    },
    active: {
      headerComponentParams: { name: 'common.active' },
      headerComponentFramework: HeaderComponent,
      field: 'active',
      cellRenderer: 'stateRenderer',
    },
    vendorRepRole: {
      headerComponentParams: { name: 'common.role' },
      headerComponentFramework: HeaderComponent,
      field: 'role',
      sortable: true,
      floatingFilter: true,
    },

    // log in as vendor rep
    logInAsVendorRepAdminFullName: {
      headerComponentParams: { name: 'common.logging_user' },
      headerComponentFramework: HeaderComponent,
      filter: true,
      field: 'adminFullName',
      floatingFilter: true,
      sortable: true,
      filterParams: {
        filterOptions: ['contains'],
      },
      floatingFilterComponent: 'simpleFloatingTextFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    logInAsVendorRepUserFullName: {
      headerComponentParams: { name: 'common.logged_by_user' },
      headerComponentFramework: HeaderComponent,
      filter: true,
      field: 'userFullName',
      floatingFilter: true,
      floatingFilterComponent: 'simpleFloatingTextFilter',
      sortable: true,
      filterParams: {
        filterOptions: ['contains'],
      },
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    logInAsVendorRepRole: {
      headerComponentParams: { name: 'user.user_role' },
      headerComponentFramework: HeaderComponent,
      filter: 'agTextColumnFilter',
      field: 'role',
      floatingFilter: true,
      floatingFilterComponent: 'statusFloatingFilter',
      sortable: true,
      filterParams: {
        filterOptions: ['equals'],
      },
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        statusesMap: getAdminRoleMap(intl),
      },
    },
    logInAsVendorRepStartTime: {
      headerComponentParams: { name: 'common.log_in' },
      headerComponentFramework: HeaderComponent,
      field: 'startTime',
      cellRenderer: 'dateRenderer',
      cellRendererParams: {
        format: 'd MMM yyyy d h:mm a',
      },
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      floatingFilterComponent: 'simpleFloatingDateFilter',
      filterParams: {
        filterOptions: ['inRange'],
      },
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      sortable: true,
    },
    logInAsVendorRepEndTime: {
      headerComponentParams: { name: 'common.log_out' },
      headerComponentFramework: HeaderComponent,
      field: 'endTime',
      cellRenderer: 'dateRenderer',
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      floatingFilterComponent: 'simpleFloatingDateFilter',
      filterParams: {
        filterOptions: ['inRange'],
      },
      cellRendererParams: {
        format: 'd MMM yyyy d h:mm a',
      },
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      sortable: true,
    },
  };

  return columnTypes;
};
