import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonsRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    margin: '0 15px 0 5px',
    [theme.breakpoints.down('lg')]: {
      margin: 0,
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  spaceLeft: {
    marginLeft: theme.spacing(3),
  },
  headerSpaceBottom: {
    marginBottom: theme.spacing(4),
  },
}));

export default useStyles;
