import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  siteGroup: {
    display: 'flex',
  },
  title: {
    marginBottom: theme.spacing(3),
    lineHeight: '32px',
    borderBottom: '1px solid #E0E0E0',
    fontSize: theme.typography.h5.fontSize,
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  vendorSite: {
    '& input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '25px',
    },
  },
  vendorSelect: {
    width: '100%',
    marginBottom: '25px',
  },
  formControl: {
    textAlign: 'left',
    margin: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
      minWidth: 120,
    },
    '& label': {
      maxWidth: 'calc(100% - 40px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    width: '100%',
  },
  formWrapper: {
    padding: '15px',
  },
  controlButtonsWrap: {
    margin: '15px 0 5px 0',
  },
  controlButtons: {
    margin: '0 10px',
  },
  logoSettingsWrap: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  siteUrl: {
    position: 'relative',
    paddingTop: '24px',
  },
  siteApplicationUrl: {
    position: 'relative',
  },
  booleanInputRight: {
    marginBottom: '10px',
    '& .MuiCheckbox-root': {
      paddingLeft: '0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiFormLabel-root': {
      textAlign: 'start',
    },
    '& label': {
      color: '#000000',
      fontSize: '17px',
      fontWeight: 'normal',
    },
  },
  readOnly: {
    pointerEvents: 'none',
    '& .MuiSelect-icon, & .MuiFormLabel-asterisk': {
      display: 'none',
    },
  },
  siteurlRow: {
    position: 'relative',
  },
  copyButton: {
    position: 'absolute',
    top: '25px',
    right: '-20px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '16px',
    height: '15px',
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
  },
  alpineThemeContainer: {
    height: 570,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .ag-header-cell-resize::after': {
      backgroundColor: 'transparent',
    },
    '& .ag-header-row:not(:first-child) .ag-header-cell': {
      borderTop: 'none',
    },
  },
  secondTitle: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
