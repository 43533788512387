import { connect, ConnectedProps } from 'react-redux';
import { actions as applicationAction } from '../../store/newApplication';
import { actions as toastActions } from '../../store/toast';
import NewApplication from './new-application';
import { naicsSelector } from '../../store/formValues/selectors';

const mapStateToProps = (state: AppStoreType) => ({
  naicsList: naicsSelector(state),
  application: state.newApplication.application,
  loading: state.newApplication.loading,
  actionInProgress: state.newApplication.actionInProgress,
  loaded: state.newApplication.loaded,
  currentLanguage: state.settings.currentLanguage,
  vendorReps: state.vendorSettings.vendorReps,
  isSiteApplicationDisabled: state.vendorSettings.isSiteApplicationDisabled,
  userUuid: state.user.uuid,
});

const mapDispatchToProps = {
  openToast: toastActions.open,
  prepareApplicationForm: applicationAction.prepareApplicationForm,
  preparePublicApplicationForm: applicationAction.preparePublicApplicationForm,
  getDraftApplication: applicationAction.getDraftApplication,
  resetApplication: applicationAction.resetApplication,
  setLoading: applicationAction.setLoading,
  createApplication: applicationAction.createApplication,
  updateApplication: applicationAction.updateApplication,
  submitApplication: applicationAction.submitApplication,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type NewApplicationConnectedProps = ConnectedProps<typeof connector>;

export default connector(NewApplication);
