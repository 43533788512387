import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  IFloatingFilter,
  IFloatingFilterParams,
} from '@ag-grid-community/core/dist/cjs/filter/floating/floatingFilter';
import { Button, InputAdornment, OutlinedInput, Popover } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import useStyles from '../styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import BaseDatePicker from '../../vault-date-picker/BaseDatePicker';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { formattedDate } from '../../../../services/formatter-service';
import { ColDef } from '@ag-grid-community/all-modules';

const SimpleFloatingDateFilter = forwardRef((props: IFloatingFilterParams, ref) => {
  const classes = useStyles();
  const intl = useIntl();
  const [dateShow, setDateShow] = useState<HTMLInputElement | null>(null);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const dateDisplayValue = dateFrom ? `${dateFrom}${dateTo ? `-${dateTo}` : ''}` : '';

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged() {
        return null;
      },
    } as IFloatingFilter;
  });

  const handleDateClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setDateShow(event.currentTarget);
  };

  const handleDateClose = () => {
    setDateShow(null);
  };

  const clearDate = (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    setDateShow(null);
    setDateFrom(null);
    setDateTo(null);
    handleDateApply(null, null);
  };

  const handleDateApply = (from: string | null = dateFrom, to: string | null = dateTo) => {
    props.parentFilterInstance((instance) => {
      const toDate = from ? formattedDate(to ? new Date(to) : new Date(), 'yyyy-MM-dd') : null;
      const fromDate = from ? formattedDate(new Date(from), 'yyyy-MM-dd') : null;
      instance.setModel({
        type: 'inRange',
        dateFrom: fromDate,
        dateTo: toDate,
      });
      props.api.onFilterChanged();
      setDateShow(null);
    });
  };
  const userProvidedColDef = props?.column?.getUserProvidedColDef?.() as ColDef & {
    ['filter-data-cy']: string;
  };
  const dataCy = `date-filter-${
    userProvidedColDef?.['filter-data-cy'] || props?.column?.getColId?.()
  }`;

  return (
    <>
      <OutlinedInput
        data-cy={dataCy}
        readOnly
        className={clsx(classes.filterInputDefault, classes.pointer)}
        onClick={handleDateClick}
        value={dateDisplayValue}
        inputProps={{
          className: clsx(classes.withoutPadding, classes.pointer),
        }}
        endAdornment={
          dateFrom ? (
            <InputAdornment position="end">
              <div data-cy="cancel-filter" className={classes.cancelFilter}>
                <CancelIcon color="disabled" fontSize="inherit" onClick={clearDate} />
              </div>
            </InputAdornment>
          ) : (
            <InputAdornment position="end">
              <ArrowDropDown />
            </InputAdornment>
          )
        }
      />
      <Popover
        className={classes.popover}
        open={!!dateShow}
        anchorEl={dateShow}
        onClose={handleDateClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div data-cy="date-popup" className={classes.dateRoot}>
          <div className={classes.dateContainer}>
            <label className={classes.dateLabel} htmlFor="from">
              {intl.formatMessage({ id: 'grid.filters_from' })}
            </label>
            <BaseDatePicker
              data-cy="date-from"
              value={dateFrom}
              maxDate={dateTo ?? undefined}
              onChange={setDateFrom}
              name="from"
            />
          </div>
          <div className={classes.dateContainer}>
            <label className={classes.dateLabel} htmlFor="to">
              {intl.formatMessage({ id: 'grid.filters_to' })}
            </label>
            <BaseDatePicker
              data-cy="date-to"
              value={dateTo}
              disabled={!dateFrom}
              minDate={dateFrom ?? undefined}
              onChange={(date: string | null) => setDateTo(date)}
              name="to"
            />
          </div>
          <div className={classes.applyContainer}>
            <Button
              data-cy="aplply-btn"
              className={classes.fullWidth}
              disabled={!dateFrom}
              variant="text"
              color="primary"
              onClick={() => handleDateApply()}
            >
              {intl.formatMessage({ id: 'grid.filters_apply' })}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
});

SimpleFloatingDateFilter.displayName = 'SimpleDateFloatingFilter';

export default SimpleFloatingDateFilter;
