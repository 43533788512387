import { StyledTableCell } from './styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import React from 'react';
import CurrencyFormat from '../currency-format';

type QuoteRowPropsType = {
  quotePaymentOptions: QuotePaymentOption[];
  selectedPaymentOptions: string[];
  termValue: number;
  purchaseOptionValue: string;
  handlePaymentSelection?: (q: QuotePaymentOption) => unknown;
  currency?: string;
};

export function QuoteRow({
  quotePaymentOptions,
  selectedPaymentOptions,
  termValue,
  handlePaymentSelection,
  purchaseOptionValue,
  currency,
}: QuoteRowPropsType) {
  return (
    <>
      {quotePaymentOptions.map((po) => {
        if (po.term == termValue && po.purchaseOption == purchaseOptionValue) {
          return (
            <StyledTableCell
              className={'active'}
              component="th"
              scope="row"
              key={po.amount + po.purchaseOption + po.term}
              onClick={() => handlePaymentSelection?.(po)}>
              {selectedPaymentOptions.includes(po.uuid) && <CheckCircle />}
              <CurrencyFormat value={po.amount} currencyType={currency} />
            </StyledTableCell>
          );
        }
      })}
    </>
  );
}
