import React, { ComponentProps } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@material-ui/core';
import get from 'lodash/get';

type NumberInputPropsType = {
  name: ComponentProps<typeof Controller>['name'];
  rules?: ComponentProps<typeof Controller>['rules'];
  defaultValue?: ComponentProps<typeof Controller>['defaultValue'];
  rowsMin?: number;
  inputProps?: Partial<{
    min: string;
  }>;
  allowNegative?: boolean;
} & Partial<TextFieldProps>;

export default function NumberInput({
  name,
  defaultValue,
  rules,
  required,
  inputProps,
  allowNegative = true,
  ...props
}: NumberInputPropsType) {
  const { control, errors } = useFormContext();
  const error = get(errors, name);

  return (
    <Controller
      render={({ value, onBlur, onChange, ref }) => (
        <TextField
          value={value ?? ''}
          name={name}
          id={name}
          type="number"
          onBlur={onBlur}
          onChange={(e) => {
            const value = e.target.value;
            if (!allowNegative && value && +value <= 0) {
              return;
            }
            return onChange(e);
          }}
          onKeyPress={(e) => {
            const isValue = !!value;
            const isBlockedKey = e.key === '-' || e.key === '0';

            if (!allowNegative && !isValue && isBlockedKey) {
              return e.preventDefault();
            }
          }}
          inputRef={ref}
          error={Boolean(error)}
          helperText={error?.message}
          required={required}
          inputProps={inputProps}
          {...props}
        />
      )}
      name={name}
      control={control}
      rules={{ required, ...rules }}
      defaultValue={defaultValue ?? ''}
    />
  );
}
