import { format, parse } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';
import { DEFAULT_DATE_FORMAT } from '../constants/date';

const locales = { en: enCA, fr: frCA };
export const isValidDateString = (date: string, inputFormat: string) => {
  return !isNaN(parse(date, inputFormat, new Date()).getTime());
};

export const isValidDate = (date: unknown) => {
  if (!date) {
    return false;
  }
  try {
    const dateObject = new Date(date as string | number);

    return dateObject.toString() !== 'Invalid Date';
  } catch {
    return false;
  }
};

export const parseDate = (date: string | null, inputFormat: string) => {
  if (!date || !isValidDateString(date, inputFormat)) {
    return null;
  }

  return parse(date, inputFormat, new Date());
};

export const formattedDate = (
  date: string | Parameters<typeof format>[0],
  dateFormat: string,
  lang = 'en',
) => {
  try {
    const locale = locales[lang as keyof typeof locales];
    return format(date as number | Date, dateFormat, { locale });
  } catch (err) {
    return '';
  }
};

export const getFormattedDate = (
  date?: string | Parameters<typeof format>[0],
  inputFormat?: string | null,
  dateFormat = DEFAULT_DATE_FORMAT,
) => {
  try {
    if (typeof date === 'string' && inputFormat) {
      return format(parse(date, inputFormat, new Date()), dateFormat);
    } else if (typeof date === 'string' && date) {
      return format(new Date(date), dateFormat);
    } else if (!date) {
      return '';
    }

    return format(date as number | Date, dateFormat);
  } catch (err) {
    return '';
  }
};

export const getFormattedDateTime = (date?: string | Parameters<typeof format>[0], lang = 'en') => {
  const dateFormat = 'PPPP pppp';
  const locale = locales[lang as keyof typeof locales];
  try {
    if (typeof date === 'string') {
      return format(new Date(date), dateFormat, { locale });
    } else if (date === undefined) {
      return '';
    }
    return format(date, dateFormat, { locale });
  } catch (err) {
    return '';
  }
};

export const getFormattedDateTimeUs = (date?: string | Parameters<typeof format>[0]) => {
  const dateFormat = 'yyyy-MM-d hh:mm a';
  try {
    if (typeof date === 'string') {
      return format(new Date(date), dateFormat);
    } else if (date === undefined) {
      return '';
    }
    return format(new Date(date), dateFormat);
  } catch (err) {
    return '';
  }
};
