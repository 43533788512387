import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';

import BaseDataGrid from './BaseDataGrid';
import {
  AllCommunityModules,
  ColumnApi,
  GridApi,
  IDatasource,
} from '@ag-grid-community/all-modules';
import React, { ComponentProps } from 'react';
import useStyles from './styles';

export type ServerSideDataGridPropsType = {
  onInit: (api: GridApi) => unknown;
  children: React.ReactNode;
  api?: GridApi | null;
  datasource: IDatasource; // required
  fitType: 'fit' | 'auto';
} & Partial<ComponentProps<typeof BaseDataGrid>>;

const ServerSideDataGrid = ({
  onInit,
  children,
  api,
  fitType,
  ...props
}: ServerSideDataGridPropsType) => {
  const classes = useStyles();
  const [columnApi, setColumnApi] = React.useState<null | ColumnApi>(null);

  const sizeToFit = () => {
    api?.sizeColumnsToFit();
  };

  const autoSizeAll = () => {
    const allColumnIds: string[] = [];
    columnApi?.getAllColumns().forEach((column) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore that snippet from official doc but in definitions colId is a private property
      allColumnIds.push(column.colId);
    });
    columnApi?.autoSizeColumns(allColumnIds);
  };

  const onViewportChanged = () => {
    if (fitType === 'auto') {
      autoSizeAll();
    } else {
      sizeToFit();
    }
  };

  const onGridSizeChanged = ({
    clientWidth,
    columnApi,
  }: {
    clientWidth: number;
    columnApi: ColumnApi;
  }) => {
    const preferredWidth = columnApi.getAllDisplayedColumns().reduce((sum, acc) => {
      const { width, minWidth } = acc.getColDef();
      return (width || minWidth || acc.getActualWidth()) + sum;
    }, 0);

    const borderOffset = 5;
    const newWidth = clientWidth - borderOffset;
    if (newWidth > preferredWidth) {
      columnApi.sizeColumnsToFit(newWidth);
    } else {
      columnApi.sizeColumnsToFit(preferredWidth);
    }
  };

  return (
    <div className={`${classes.alpineThemeContainer} ag-theme-alpine`}>
      <BaseDataGrid
        modules={AllCommunityModules}
        rowModelType="infinite"
        onGridSizeChanged={onGridSizeChanged}
        onGridReady={(param) => {
          setColumnApi(param.columnApi);
          onInit(param.api);
        }}
        pagination
        paginationPageSize={10}
        cacheBlockSize={10}
        onViewportChanged={onViewportChanged}
        {...props}>
        {children}
      </BaseDataGrid>
    </div>
  );
};

export default ServerSideDataGrid;
