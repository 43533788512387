import React from 'react';

import useStyles from './styles';
import Button from '@material-ui/core/Button';

export default function VaultButton(props) {
  const classes = useStyles();
  const { label, onClick, disabled, dataCy } = props;

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Button
          data-cy={dataCy ?? 'button'}
          variant={props.type || 'contained'}
          color={props.color || 'primary'}
          onClick={onClick}
          disabled={disabled}
          className={classes.button}>
          {label}
        </Button>
      </div>
    </React.Fragment>
  );
}
