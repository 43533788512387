import React from 'react';
import { BaseDataGrid } from '../../components/common/VpDataGrid';
import { AgGridColumn } from '@ag-grid-community/react';
import { getBrokersVendorsList } from '../../apis/broker-rep';
import { VendorSettingsConnectedProps } from './vendor-settings.container';

import { AllCommunityModules, GridApi, IDatasource } from '@ag-grid-community/all-modules';
import useStyles from '../../components/common/VpDataGrid/styles';
import { useHistory } from 'react-router-dom';
import { VENDOR_SETTINGS_EDIT_PAGE } from '../../constants/navigations';

const VendorSettings = (props: VendorSettingsConnectedProps) => {
  const { vendorUuid, userRole } = props;
  const gridApi = React.useRef<GridApi | null>(null);
  const classes = useStyles();
  const history = useHistory();

  const editVendorLink = {
    path: VENDOR_SETTINGS_EDIT_PAGE.path,
    pathParams: {
      vendorUuid: 'uuid',
    },
  };

  const dataSource: IDatasource = {
    getRows: async (params) => {
      if (userRole === 'Vendor Admin') return history.push(`vendor-settings/${vendorUuid}`);

      const queryArray: Array<[string, string | number]> = [];
      queryArray.push(['limit', params.endRow - params.startRow]);
      queryArray.push(['offset', params.startRow]);
      queryArray.push(['sortingField', params.sortModel[0]?.colId]);
      queryArray.push(['sortingDirection', params.sortModel[0]?.sort]);

      Reflect.ownKeys(params.filterModel).forEach((field) => {
        const filter = encodeURIComponent(params.filterModel[field]?.filter);
        return queryArray.push([field as string, filter]);
      });

      const queryString = queryArray
        .filter((a) => a[1])
        .map((a) => a.join('='))
        .join('&');

      getBrokersVendorsList(queryString)
        .then((res) => {
          const vendors = res.data;
          params.successCallback(vendors.rows, vendors.count);
          setTimeout(() => {
            gridApi.current?.sizeColumnsToFit();
          });
        })
        .catch(() => {
          params.failCallback();
        });
    },
  };

  return (
    <div className={`${classes.alpineThemeContainer} ag-theme-alpine`}>
      <BaseDataGrid
        modules={AllCommunityModules}
        rowModelType="infinite"
        datasource={dataSource}
        onInit={(api) => (gridApi.current = api)}
        onViewportChanged={() => gridApi.current?.sizeColumnsToFit()}
        pagination
        paginationPageSize={10}
        cacheBlockSize={10}
        onGridSizeChanged={(params) => params.api.sizeColumnsToFit()}
      >
        <AgGridColumn
          floatingFilter
          minWidth={200}
          flex={1}
          type="vendorCompanyName"
          cellRendererParams={editVendorLink}
        />
        <AgGridColumn floatingFilter minWidth={200} flex={1} type="email" />
        <AgGridColumn floatingFilter minWidth={200} flex={1} type="companyWebsite" />
        <AgGridColumn width={150} maxWidth={150} suppressSizeToFit type="active" sortable />
        <AgGridColumn
          type="createdAt"
          flex={1}
          resizable
          cellClass={'ag-custom-text-align-center'}
          headerClass={'ag-custom-text-align-center'}
        />
        <AgGridColumn
          type="vendorDeleteLink"
          flex={1}
          resizable
          cellClass={'ag-custom-text-align-center'}
          headerClass={'ag-custom-text-align-center'}
        />
      </BaseDataGrid>
    </div>
  );
};

export default VendorSettings;
