import * as fileApi from '../../apis/file';
import { actions as toastActions } from '../../store/toast';
import { Dispatch } from 'react';
import { actions as fileActions } from './reduces';
import { getIntl } from '../../utils/language';
import { ProgressHandler } from '../../services/websockets';

export const uploadFiles =
  (attachments: FormData, routeParams: PublicRouteParams, onProgress?: ProgressHandler) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let result;
    try {
      dispatch(fileActions.setLoading(true));
      result = routeParams.vendorSiteUrl
        ? (await fileApi.uploadFilesPublic(attachments, routeParams, onProgress)).data
        : (await fileApi.uploadFiles(attachments, onProgress)).data;
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.upload_file_hint' }),
      };
      dispatch(fileActions.setLoading(false));
      return dispatch(toastActions.open(toastOptions));
    }
    const fileState = getState().file;
    dispatch(fileActions.fetchFiles([...fileState.attachments, ...result]));
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'common.file_upload_successfull' }),
    };
    dispatch(toastActions.open(toastOptions));
    dispatch(fileActions.setLoading(false));
  };

export const deleteFile =
  (fileUuid: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      dispatch(fileActions.setLoading(true));
      await fileApi.deleteFile(fileUuid);
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.application.application.something_wrong' }),
      };
      dispatch(fileActions.setLoading(false));
      return dispatch(toastActions.open(toastOptions));
    }
    const { attachments } = getState().file;
    const newAttachments = attachments.filter(
      (attachment: FileType) => attachment.uuid !== fileUuid,
    );
    dispatch(fileActions.fetchFiles(newAttachments));
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'common.file_delete_success' }),
    };
    dispatch(toastActions.open(toastOptions));
    dispatch(fileActions.setLoading(false));
  };
