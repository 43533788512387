import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NewApplicationContext, { NewApplicationContextProps } from './new-application.context';
import Container from '@material-ui/core/Container';
import TextInput from '../../components/common/TextInput';
import useStyles from './styles';

export default function NoteSection({ defaultData }: { defaultData: ApplicationNote | null }) {
  const intl = useIntl();
  const classes = useStyles();

  const { getValues } = useFormContext();
  const { subscribeOnPageLeave, unSubscribeOnPageLeave } = useContext<NewApplicationContextProps>(
    NewApplicationContext,
  );

  const leaveListener = React.useCallback(() => {
    return (defaultData?.note || '') !== (getValues('notes.0.note') || '');
  }, [defaultData, getValues]);

  React.useEffect(() => {
    subscribeOnPageLeave(leaveListener);
    return () => {
      unSubscribeOnPageLeave(leaveListener);
    };
  }, [leaveListener]);

  return (
    <Container className={classes.container}>
      <TextInput
        name="notes.0.note"
        placeholder={intl.formatMessage({ id: 'application.type_notes' })}
        defaultValue={defaultData?.note}
        fullWidth
        multiline
        rows={3}
      />
    </Container>
  );
}
