import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';

const BACKEND = getBackendUrl() + '/search';
const api = getApi(BACKEND);

export const getSearch = async (searchQuery: string) => {
  const response = await api.get<GlobalSearchType>(`?searchString=${searchQuery}`);
  return response.data;
};
