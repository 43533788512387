import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getSentryDsn } from './utils/getBackendUrl';

Sentry.init({
  dsn: getSentryDsn(),
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const redux = configureStore();

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={redux.store}>
    <App />
  </Provider>,
  rootElement,
);
