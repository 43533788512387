import React, { useCallback } from 'react';
import { Checkbox, Chip, Divider, FormControlLabel, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import {
  DOCUMENT_SIGNATURE_ATTACHMENTS,
  DOCUMENT_SIGNATURE_STATUSES,
  DOCUMENT_SIGNER_ATTACHMENT_STATUSES,
  ATTENTION_TO,
} from '../../../constants/document';
import CheckIcon from '@material-ui/icons/Check';

type ApplicationDocumentContentPropsType = {
  changeSignerAttachments: (id: string, attachments: ApplicationDocumentSignerAttachment[]) => void;
  allAttachments: { [uuid: string]: ApplicationDocumentSignerAttachment[] } | false;
  docSignAvailability: boolean;
  document: ApplicationDocumentType;
  documentSigned: boolean;
  fullWidth: boolean;
  readonly: boolean;
  isModalContent: boolean;
};

export default function ApplicationDocumentSigners(props: ApplicationDocumentContentPropsType) {
  const {
    changeSignerAttachments,
    allAttachments,
    document,
    documentSigned,
    docSignAvailability,
    fullWidth,
    readonly,
    isModalContent,
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [signerAttachments, setSignerAttachments] = React.useState<
    Array<ApplicationDocumentSignerAttachment>
  >([]);

  React.useEffect(() => {
    setSignerAttachments(
      allAttachments && allAttachments[document.id] ? allAttachments[document.id] : [],
    );
  }, [document, allAttachments]);

  const onCheckAttachment = (signerUuid: string, attachmentType: string, checked: boolean) => {
    let existItem = false;
    for (const signerAttachment of signerAttachments) {
      if (
        signerAttachment.signerUuid === signerUuid &&
        signerAttachment.attachmentType === attachmentType
      ) {
        signerAttachment.checked = checked;
        existItem = true;
      }
    }

    if (existItem) {
      const attachment = signerAttachments.filter((signerAttachment) => {
        if (
          signerAttachment.signerUuid === signerUuid &&
          signerAttachment.attachmentType === attachmentType
        ) {
          return false;
        }

        return true;
      });
      changeSignerAttachments(document.id, attachment);
    } else {
      changeSignerAttachments(document.id, [
        ...signerAttachments,
        {
          signerUuid,
          attachmentType,
          checked,
          status: DOCUMENT_SIGNER_ATTACHMENT_STATUSES.INCOMPLETE,
        },
      ]);
    }
  };

  const isChecked = useCallback(
    (
      attachmentType: string,
      attachments: Array<ApplicationDocumentSignerAttachment> | undefined,
      signerUuid: string,
    ) => {
      if (attachments) {
        return !!attachments.find(
          (a) => attachmentType === a.attachmentType && a.signerUuid === signerUuid && a.checked,
        );
      }

      return false;
    },
    [allAttachments],
  );

  const isCompleted = useCallback(
    (
      attachmentType: string,
      attachments: Array<ApplicationDocumentSignerAttachment> | undefined,
      signerUuid: string,
    ) => {
      if (attachments) {
        return !!attachments.find(
          (a) =>
            attachmentType === a.attachmentType &&
            a.signerUuid === signerUuid &&
            a.status === DOCUMENT_SIGNER_ATTACHMENT_STATUSES.COMPLETE,
        );
      }

      return false;
    },
    [allAttachments],
  );

  const signerRole = (role: string) =>
    role.toLowerCase() === ATTENTION_TO ? intl.formatMessage({ id: 'applicant.co_lessee' }) : role;

  return (
    <div className={fullWidth ? '' : classes.signerContent}>
      {document.signers?.map(
        (
          signer: ApplicationDocumentSignerType,
          index: number,
          signers: Array<ApplicationDocumentSignerType>,
        ) => (
          <div key={index} className={classes.signerItem}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5}>
                <div className={classes.role}>{signerRole(signer.role)}</div>
                <Typography variant="h6" className={classes.applicantName}>
                  {`${signer.firstName} ${signer.lastName}`}
                </Typography>
                <div className={classes.email}>
                  {intl.formatMessage({ id: 'common.email_address' })}
                </div>
                <Typography variant="h6" className={classes.applicantEmail}>
                  {signer.email}
                </Typography>
                {documentSigned && (
                  <Chip
                    color="default"
                    size="small"
                    className={
                      signer.status === DOCUMENT_SIGNATURE_STATUSES.PENDING
                        ? classes.labelPending
                        : signer.status === DOCUMENT_SIGNATURE_STATUSES.SIGNED
                        ? classes.labelSuccess
                        : classes.labelError
                    }
                    variant="default"
                    label={
                      signer.status === DOCUMENT_SIGNATURE_STATUSES.PENDING
                        ? intl.formatMessage({ id: 'application.waiting_for_signature' })
                        : signer.status === DOCUMENT_SIGNATURE_STATUSES.SIGNED
                        ? intl.formatMessage({ id: 'application.signer_status_signed' })
                        : intl.formatMessage({ id: 'application.signer_status_declined' })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={7}>
                {documentSigned && (
                  <>
                    {isChecked(
                      DOCUMENT_SIGNATURE_ATTACHMENTS.VOID_CHEQUE,
                      signerAttachments,
                      signer.uuid,
                    ) && (
                      <div className={classes.attachmentContainer}>
                        <span>
                          {intl.formatMessage({ id: 'application.void_cheque_was_requested' })}
                        </span>
                        <span>
                          {isCompleted(
                            DOCUMENT_SIGNATURE_ATTACHMENTS.VOID_CHEQUE,
                            signerAttachments,
                            signer.uuid,
                          ) ? (
                            <>
                              <span>{intl.formatMessage({ id: 'application.received' })}</span>
                              <CheckIcon />
                            </>
                          ) : (
                            intl.formatMessage({ id: 'application.waiting_for_upload' })
                          )}
                        </span>
                      </div>
                    )}
                    {isChecked(
                      DOCUMENT_SIGNATURE_ATTACHMENTS.DRIVER_LICENSE,
                      signerAttachments,
                      signer.uuid,
                    ) && (
                      <div className={classes.attachmentContainer}>
                        <span>
                          {intl.formatMessage({ id: 'application.driver_license_was_requested' })}
                        </span>
                        <span>
                          {isCompleted(
                            DOCUMENT_SIGNATURE_ATTACHMENTS.DRIVER_LICENSE,
                            signerAttachments,
                            signer.uuid,
                          ) ? (
                            <>
                              <span>{intl.formatMessage({ id: 'application.received' })}</span>
                              <CheckIcon />
                            </>
                          ) : (
                            intl.formatMessage({ id: 'application.waiting_for_upload' })
                          )}
                        </span>
                      </div>
                    )}
                  </>
                )}

                {!documentSigned && isModalContent && (
                  <>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={`attachment[${DOCUMENT_SIGNATURE_ATTACHMENTS.VOID_CHEQUE}]`}
                      label={intl.formatMessage({
                        id: 'application.ask_signer_upload_void_cheque',
                      })}
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={isChecked(
                            DOCUMENT_SIGNATURE_ATTACHMENTS.VOID_CHEQUE,
                            signerAttachments,
                            signer.uuid,
                          )}
                          disabled={readonly || documentSigned}
                          onChange={(e) =>
                            onCheckAttachment(
                              signer.uuid,
                              DOCUMENT_SIGNATURE_ATTACHMENTS.VOID_CHEQUE,
                              e.target.checked,
                            )
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={`attachment[${DOCUMENT_SIGNATURE_ATTACHMENTS.DRIVER_LICENSE}]`}
                      label={intl.formatMessage({
                        id: 'application.ask_signer_upload_driver_license',
                      })}
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={isChecked(
                            DOCUMENT_SIGNATURE_ATTACHMENTS.DRIVER_LICENSE,
                            signerAttachments,
                            signer.uuid,
                          )}
                          disabled={readonly || documentSigned}
                          onChange={(e) =>
                            onCheckAttachment(
                              signer.uuid,
                              DOCUMENT_SIGNATURE_ATTACHMENTS.DRIVER_LICENSE,
                              e.target.checked,
                            )
                          }
                        />
                      }
                    />
                  </>
                )}
              </Grid>
            </Grid>
            {index < signers.length - 1 && !docSignAvailability && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>
            )}
          </div>
        ),
      )}
    </div>
  );
}
