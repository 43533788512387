import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSelector } from 'react-redux';
import { getFullUserName } from '../../../utils/common';
import useStyles from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LockIcon from '@material-ui/icons/Lock';
import UserProfile from '../UserProfile';
import DropDown from '../dropdown';
import Link from '../link';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { generatePath } from 'react-router';
import { MY_BROKER_REP_PROFILE_PAGE, MY_PROFILE_PAGE } from '../../../constants/navigations';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { useIntl } from 'react-intl';

export default function NotificationBar({
  logout,
  isLoggedIn,
  isBrokerRep,
  logoutAsVendorUser,
}: {
  logout: () => unknown;
  isBrokerRep: boolean;
  isLoggedIn: boolean;
  logoutAsVendorUser?: () => unknown;
}) {
  const intl = useIntl();
  const classes = useStyles();
  const smallMenu = useMediaQuery('(min-width:900px)');
  const noMenu = useMediaQuery('(max-width:900px)');
  const user = useSelector((state: AppStoreType) => state.user);
  const userName = getFullUserName(user.firstName, user.lastName);

  if (noMenu) {
    return null;
  }

  if (!isLoggedIn) {
    const locale = intl.locale.toLowerCase() !== 'en' && `?lang=${intl.locale.toLowerCase()}`;
    return (
      <IconButton onClick={() => (location.href = `/sign-in${locale}`)}>
        <LockIcon />
      </IconButton>
    );
  }

  return (
    <div className={classes.dropdownWrapper}>
      {isBrokerRep && (
        <DropDown
          options={[
            {
              label: (
                <Link
                  className={classes.menuLink}
                  to={generatePath(MY_BROKER_REP_PROFILE_PAGE.path)}
                >
                  <>
                    <PersonOutlineIcon color="primary" />
                    <span className={classes.labelText}>
                      {intl.formatMessage({ id: MY_PROFILE_PAGE.title })}
                    </span>
                  </>
                </Link>
              ),
              handler: () => null,
            },
            {
              label: (
                <>
                  <ExitToApp color="primary" />
                  <span className={classes.labelText}>
                    {intl.formatMessage({ id: 'common.logout' })}
                  </span>
                </>
              ),
              handler: logout,
            },
          ]}
        >
          <IconButton data-cy="logout-btn" className={classes.smallText} color="inherit">
            {smallMenu ? (
              <>
                {userName}
                <ArrowDropDownIcon />
              </>
            ) : (
              <MoreIcon />
            )}
          </IconButton>
        </DropDown>
      )}

      {!isBrokerRep && (
        <UserProfile logout={logout} logoutAsVendorUser={logoutAsVendorUser} user={user}>
          <IconButton data-cy="logout-btn" className={classes.smallText} color="inherit">
            {smallMenu ? (
              <>
                {userName}
                <ArrowDropDownIcon />
              </>
            ) : (
              <MoreIcon />
            )}
          </IconButton>
        </UserProfile>
      )}
    </div>
  );
}
