import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Tooltip } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import { UseFormMethods } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import {
  DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST,
  DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_VALUES,
} from '../../../constants/document';
import VaultMenu from '../VaultMenu';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import WarningIcon from '@material-ui/icons/Warning';
import { translateLabel } from '../../../utils/translateLabel';

type ApplicationDocumentOverrideFormPurchaseOptionFieldPropsType = {
  index: string;
  field: ApplicationDocumentOverridableFields;
  originalValue: string | number | null | boolean;
  value: string | null;
  onChangeField: (
    path: string,
    value: string,
    control?: UseFormMethods['control'],
    name?: string,
    isDateField?: boolean,
  ) => void;
  register: ReturnType<typeof useForm>['register'];
};

export default function ApplicationDocumentOverrideFormPurchaseOptionField({
  index,
  field,
  originalValue,
  value,
  onChangeField,
  register,
}: ApplicationDocumentOverrideFormPurchaseOptionFieldPropsType) {
  const classes = useStyles();
  const intl = useIntl();
  const { setValue, errors } = useFormContext();
  const error = get(errors, field.path);
  const [isDifferent, setIsDifferent] = useState<boolean>(false);
  const [hideCustomField, setHideCustomField] = useState<boolean>(true);
  const [customFieldValue, setCustomFieldValue] = useState<string>('');
  const [helperText, setHelperText] = useState<string>('');

  useEffect(() => {
    setIsDifferent(checkDifferent(value, originalValue));

    if (value) {
      if (Object.values<string>(DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST).includes(value)) {
        setValue(field.path, value);
        setCustomFieldValue('');
        setHideCustomField(true);
      } else {
        setValue(field.path, DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.OTHER);
        setCustomFieldValue(value);
        setHideCustomField(false);
      }
    } else {
      setValue(field.path, undefined);
      setCustomFieldValue('');
      setHideCustomField(true);
    }
  }, []);

  const onChange = useCallback(
    (path: string, newValue: string) => {
      if (newValue === DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.OTHER) {
        setIsDifferent(checkDifferent(customFieldValue, originalValue));
      } else {
        setIsDifferent(checkDifferent(newValue, originalValue));
      }

      onChangeField(path, newValue);
      setHelperText('');

      if (newValue === DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.OTHER) {
        setCustomFieldValue('');
        setHideCustomField(false);
      } else {
        setHideCustomField(true);
      }
    },
    [onChangeField],
  );

  const onChangeCustomField = useCallback(
    (path: string, newValue: string) => {
      if (newValue === '') {
        setHelperText('');
      }

      setIsDifferent(checkDifferent(newValue, originalValue));
      setCustomFieldValue(newValue);
      onChangeField(path, newValue);
    },
    [onChangeField],
  );

  const checkDifferent = (
    value: string | number | boolean | null,
    originalValue: string | number | boolean | null,
  ) => {
    if (value === '' && originalValue === null) {
      return false;
    }

    return value !== originalValue;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={hideCustomField ? 12 : 6} className={classes.fieldGroup}>
          <VaultMenu
            className={classes.poSelector}
            id={`${index}`}
            name={field.path}
            label={translateLabel(intl, field.path, field.label)}
            required={field.required}
            value={value ?? undefined}
            options={DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_VALUES}
            onChange={(e) => {
              setValue(field.path, e.target.value);
              onChange(field.path, e.target.value as string);
            }}
          />
          {hideCustomField && isDifferent && (
            <Tooltip
              title={`${intl.formatMessage({
                id: 'application.original_value',
              })}: ${originalValue}`}
              placement="top"
            >
              <WarningIcon className={classes.warningSelectIcon} />
            </Tooltip>
          )}
        </Grid>
        {!hideCustomField && (
          <Grid item xs={12} sm={6} className={classes.fieldGroup}>
            <TextField
              className={classes.poElement}
              name={`purchase-option-${index}`}
              defaultValue={customFieldValue}
              label={intl.formatMessage({ id: 'application.custom_purchase_option_price_type' })}
              error={Boolean(error)}
              helperText={error?.message}
              required={field.required}
              inputRef={register({
                required: true,
                validate: (titleValue: string) => {
                  if (!titleValue) {
                    return false;
                  }

                  return undefined;
                },
              })}
              onChange={(e) => onChangeCustomField(field.path, e.target.value as string)}
              fullWidth
            />
            {isDifferent && (
              <Tooltip
                title={`${intl.formatMessage({
                  id: 'application.original_value',
                })}: ${originalValue}`}
                placement="top"
              >
                <WarningIcon className={classes.warningIcon} />
              </Tooltip>
            )}
            <span className={classes.helperText}>{helperText}</span>
          </Grid>
        )}
      </Grid>
    </>
  );
}
