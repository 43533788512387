import { Dispatch } from 'react';

import * as userApi from '../../apis/users';
import { actions as toastActions } from '../../store/toast';
import { actions as userEditActions } from '../../store/userOnEdit';
import { getIntl } from '../../utils/language';
import { IGetRowsParams } from '@ag-grid-community/all-modules';

export const fetchUsersList =
  (params: IGetRowsParams) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let userList;
    try {
      userList = (await userApi.getAllUsers(params)).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }
    userList.data = userList.data.map((user: UserType) => ({
      ...user,
      phone: user.formattedPhone,
      mobilePhone: user.formattedMobilePhone,
      companyName: user.vendor?.companyName,
    }));

    return userList;
  };

export const createNewUser =
  (user: CreateUserType) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let createdUser;
    try {
      createdUser = (await userApi.createUser(user)).data;
    } catch (error) {
      const errorData = error.response?.data;
      let errorMessage = intl.formatMessage({ id: 'user.user_create_error' });

      if (errorData?.code === 610) {
        errorMessage = intl.formatMessage({ id: 'user.user_create_same_error' });
      }

      const toastOptions = {
        toastType: 'error' as const,
        message: errorMessage,
      };
      return dispatch(toastActions.open(toastOptions));
    }

    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'common.created_success' }),
    };
    dispatch(toastActions.open(toastOptions));
    return (location.href = `/user-edit/${createdUser.uuid}`);
  };

export const resetUserPass =
  (email: string, type: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await userApi.resetPassword(email, type);
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'user.error_reset_password' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'user.email_success_sent' }),
    };

    dispatch(toastActions.open(toastOptions));
  };

export const changeUserStatus =
  (uuid: string, status: boolean) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    dispatch(userEditActions.setLoading(true));
    let updatedUser;
    try {
      updatedUser = (await userApi.changeUserStatus(uuid, status)).data;
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'user.error_change_user_status' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'user.success_change_user_status' }),
    };
    dispatch(userEditActions.updateUserOnEdit(updatedUser));
    dispatch(userEditActions.setLoading(false));
    dispatch(toastActions.open(toastOptions));
  };

export const deactivateUsers =
  (users: string[]) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);

    try {
      await userApi.bulkChangeUserStatus(users, false);
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'user.users_deactivation_failed' }),
      };
      return dispatch(toastActions.open(toastOptions));
    }

    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'user.users_deactivation_success' }),
    };
    dispatch(toastActions.open(toastOptions));
  };

export const activateUsers =
  (users: string[]) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);

    try {
      await userApi.bulkChangeUserStatus(users, true);
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'user.users_activation_failed' }),
      };
      return dispatch(toastActions.open(toastOptions));
    }

    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'user.users_activation_success' }),
    };
    dispatch(toastActions.open(toastOptions));
  };

export const resetUsersPassword =
  (users: string[]) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      const response = (await userApi.bulkPasswordReset(users)).data;

      if (response.status === 'partially') {
        const failedEmailsLength = response.failedToSend.length;

        if (failedEmailsLength === users.length) {
          const toastOptions = {
            toastType: 'error' as const,
            message: intl.formatMessage({ id: 'user.bulk_reset_password_fail' }),
          };
          return dispatch(toastActions.open(toastOptions));
        } else {
          const toastOptions = {
            toastType: 'warning' as const,
            message: intl.formatMessage(
              { id: 'user.bulk_reset_password_partially' },
              {
                successCount: users.length - failedEmailsLength,
                failedCount: failedEmailsLength,
              },
            ),
          };
          return dispatch(toastActions.open(toastOptions));
        }
      }
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'user.bulk_reset_password_fail' }),
      };
      return dispatch(toastActions.open(toastOptions));
    }

    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'user.reset_password_success' }),
    };
    dispatch(toastActions.open(toastOptions));
  };
