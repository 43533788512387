import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';

export const getLogInActivityList = async (queryString: string) => {
  const BACKEND = getBackendUrl() + '/activity';
  const api = getApi(BACKEND);

  return await api.get<{ rows: ActivityLogInAsVendorRepListItem[]; count: number }>(
    `/login-as-vendor-rep${queryString ? '?' : ''}${queryString}`,
  );
};
