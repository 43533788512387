import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { MenuItem } from '@material-ui/core';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { validateResetPassword } from '../../store/user';
import { Copyright } from '../../components/common/Copyright';
import Link from '../../components/common/link';
import { validateEmail } from '../../utils/emailActions';
import { actions } from '../../store/user/index';
import { REPRESENTATIVE_TYPES } from '../../constants/application';
import { SignInToolbar } from '../../components/common/SignInToolbar';

type ForgotPasswordFormValues = {
  email: string;
  type?: string | undefined;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  selectAcc: {
    display: 'inline-block',
    marginTop: '20px',
    textAlign: 'center',
  },
}));

const userErrorSelector = (state: AppStoreType) => state.user;

export default function ForgotPassword() {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const { error } = useSelector<AppStoreType, ReturnType<typeof userErrorSelector>>(
    userErrorSelector,
  );
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const dispatch = useDispatch();
  const formMethods = useForm<ForgotPasswordFormValues>({
    defaultValues: {
      email: '',
      type: undefined,
    },
  });
  const [isTypeSelection, setIsTypeSelection] = React.useState(false);
  const [isTypeError, setIsTypeError] = React.useState(false);

  const { register, handleSubmit, errors, setError, setValue } = formMethods;

  const submit = async (data: ForgotPasswordFormValues) => {
    const { email, type } = data;
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
    }
    if (isTypeSelection && !type) {
      setIsTypeError(true);
      return false;
    }
    dispatch(actions.updateUserError(''));
    try {
      await dispatch(validateResetPassword(email, type && type));
      setShowSuccessMessage(true);
      return history.push('/sign-in');
    } catch (e) {
      if (e?.code === 409) setIsTypeSelection(true);
      setShowSuccessMessage(false);
      return;
    }
  };

  const unmountAction = () => {
    return dispatch(actions.updateUserError(''));
  };

  useEffect(() => {
    if (error) {
      setError('email', { message: error });
    }

    return () => {
      unmountAction();
    };
  }, [error]);

  return (
    <>
      <SignInToolbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SettingsBackupRestoreIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {intl.formatMessage({ id: 'user.reset_your_password' })}
          </Typography>
          {showSuccessMessage && (
            <Alert severity="success">
              {intl.formatMessage({ id: 'user.please_check_your_email' })}
            </Alert>
          )}
          <FormProvider {...formMethods}>
            <form className={classes.form} onSubmit={handleSubmit(submit)} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={register({
                  required: true,
                  validate: (value) =>
                    validateEmail(value) || intl.formatMessage({ id: 'user.invalid_email' }),
                })}
                id="email"
                label={intl.formatMessage({ id: 'common.email_address' })}
                name="email"
                type="email"
                autoComplete="email"
                autoFocus
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              {isTypeSelection && (
                <TextField
                  {...register({
                    name: 'type',
                    required: true,
                  })}
                  variant="outlined"
                  required
                  fullWidth
                  select
                  label={intl.formatMessage({ id: 'common.select_representative' })}
                  name="type"
                  id="type"
                  data-cy="type-field"
                  defaultValue=""
                  error={isTypeError}
                  helperText={
                    isTypeError && intl.formatMessage({ id: 'common.representative_required' })
                  }
                  onChange={(e) => {
                    setIsTypeError(false);
                    setValue('type', e.target.value, { shouldDirty: true, shouldValidate: true });
                  }}
                >
                  {REPRESENTATIVE_TYPES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {intl.formatMessage({ id: option.label })}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit(submit)}
              >
                {intl.formatMessage({ id: 'user.reset_password' })}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/sign-in">{intl.formatMessage({ id: 'common.sign_in' })}</Link>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
