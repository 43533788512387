import { getBackendUrl } from '../utils/getBackendUrl';
import { buildPaginationFilterQuery } from '../utils/common';
import { getApi } from './httpCommon';
import { lsGetApplicationAccessToken } from '../utils/localStorage';
import { isPublicRoute } from '../utils/common';
import { CURRENCY_TYPE } from '../constants/currency';
import { v4 } from 'uuid';
import { IoProgress, ProgressHandler, subscribe } from '../services/websockets';
import { formatBufferProgress } from '../utils/common';
import { getHeaders, getUrl } from '../utils/apiUtils';
import { IGetRowsParams } from '@ag-grid-community/all-modules';
import { buildAGPaginationFilterQuery } from '../utils/common';

const BACKEND = getBackendUrl() + '/application';
const api = getApi(BACKEND);

export const submitApplication = async (
  applicationUuid: string,
  routeParams: PublicRouteParams,
) => {
  const isPublic = isPublicRoute(routeParams);
  const headers = getHeaders(routeParams, applicationUuid);
  const url = getUrl(`/${applicationUuid}/submit`, isPublic);

  return await api.post<NewApplicationStoreTypes['application']>(
    url,
    {},
    {
      headers,
    },
  );
};

export const createApplication = async (application: Partial<ApplicationToBEType>) => {
  return await api.post<NewApplicationStoreTypes['application']>('', application);
};

export const updateApplication = async (
  applicationUuid: string,
  application: Partial<ApplicationToBEType>,
) => {
  return await api.put<NewApplicationStoreTypes['application']>('/' + applicationUuid, application);
};

export const updateApplicationPublic = async (
  applicationUuid: string,
  application: Partial<ApplicationToBEType>,
  routeParams: PublicRouteParams,
) => {
  const headers = getHeaders(routeParams, applicationUuid);
  return await api.put<NewApplicationStoreTypes['application']>(
    '/public/' + applicationUuid,
    application,
    {
      headers,
    },
  );
};

export const createApplicationPublic = async (
  application: Partial<ApplicationToBEType>,
  routeParams: PublicRouteParams,
) => {
  const headers = getHeaders(routeParams, application?.application?.uuid);
  return await api.post<NewApplicationStoreTypes['application']>('/public', application, {
    headers,
  });
};

export type UpdateApplicationApplicationPayload = {
  currency?: CURRENCY_TYPE;
  endOfTermOption?: string | null;
  term?: number | null;
  frequency?: Frequency;
  userUuid?: string;
};

export const updateApplicationApplication = async (
  application: UpdateApplicationApplicationPayload,
  applicationUuid: string,
  routeParams: PublicRouteParams,
) => {
  const isPublic = isPublicRoute(routeParams);
  const headers = getHeaders(routeParams, applicationUuid);
  const url = getUrl(`/${applicationUuid}`, isPublic);
  return await api.put<NewApplicationStoreTypes['application']>(url, application, {
    headers,
  });
};

export const createApplicationEquipment = async (
  applicationUuid: string,
  equipments: Equipment[],
  routeParams: PublicRouteParams,
) => {
  const isPublic = isPublicRoute(routeParams);
  const headers = getHeaders(routeParams, applicationUuid);
  const url = getUrl(`/${applicationUuid}/equipment`, isPublic);
  return await api.post<VpCompleteApplicationType>(url, equipments, { headers });
};

export const updateApplicationEquipment = async (
  applicationUuid: string,
  equipment: Equipment,
  routeParams: PublicRouteParams,
) => {
  const isPublic = isPublicRoute(routeParams);
  const headers = getHeaders(routeParams, applicationUuid);
  const url = getUrl(`/${applicationUuid}/equipment/${equipment.uuid}`, isPublic);
  return await api.put<VpCompleteApplicationType>(url, equipment, { headers });
};

export const deleteApplicationEquipment = async (
  applicationUuid: string,
  equipmentUuid: string,
  routeParams: PublicRouteParams,
) => {
  const isPublic = isPublicRoute(routeParams);
  const headers = getHeaders(routeParams, applicationUuid);
  const url = getUrl(`/${applicationUuid}/equipment/${equipmentUuid}`, isPublic);
  return api.delete<VpCompleteApplicationType>(url, { headers });
};

export const createApplicationNote = async (
  applicationUuid: string,
  note: ApplicationNote,
  routeParams: PublicRouteParams,
) => {
  const isPublic = isPublicRoute(routeParams);
  const headers = getHeaders(routeParams, applicationUuid);
  const url = getUrl(`/${applicationUuid}/note`, isPublic);
  return api.post<NewApplicationStoreTypes['application']>(url, [note], { headers });
};

export const updateApplicationNote = async (
  applicationUuid: string,
  note: ApplicationNote,
  routeParams: PublicRouteParams,
) => {
  const isPublic = isPublicRoute(routeParams);
  const headers = getHeaders(routeParams, applicationUuid);
  const url = getUrl(`/${applicationUuid}/note/${note.uuid}`, isPublic);
  return api.put<NewApplicationStoreTypes['application']>(url, note, { headers });
};

export const deleteApplicationNote = async (
  applicationUuid: string,
  noteUuid: string,
  routeParams: PublicRouteParams,
) => {
  const isPublic = isPublicRoute(routeParams);
  const headers = getHeaders(routeParams, applicationUuid);
  const url = getUrl(`/${applicationUuid}/note/${noteUuid}`, isPublic);
  return api.delete<NewApplicationStoreTypes['application']>(url, { headers });
};

export const createApplicationParty = async (
  applicationUuid: string,
  parties: EntityApplicationRoleType[],
  routeParams: PublicRouteParams,
) => {
  const isPublic = isPublicRoute(routeParams);
  const headers = getHeaders(routeParams, applicationUuid);
  const url = getUrl(`/${applicationUuid}/party`, isPublic);
  return api.post<NewApplicationStoreTypes['application']>(url, parties, { headers });
};

export const updateApplicationParty = async (
  applicationUuid: string,
  party: EntityApplicationRoleType,
  routeParam: PublicRouteParams,
  addContactAsParty?: boolean,
) => {
  const isPublic = isPublicRoute(routeParam);
  const headers = getHeaders(routeParam, applicationUuid);
  const url = getUrl(`/${applicationUuid}/party/${party.uuid}`, isPublic);
  return api.put<NewApplicationStoreTypes['application']>(url, party, {
    params: { addContactAsParty },
    headers,
  });
};

export const deleteApplicationParty = async (
  applicationUuid: string,
  partyUuid: string,
  routeParams: PublicRouteParams,
) => {
  const isPublic = isPublicRoute(routeParams);
  const headers = getHeaders(routeParams, applicationUuid);
  const url = getUrl(`/${applicationUuid}/party/${partyUuid}`, isPublic);
  return api.delete<NewApplicationStoreTypes['application']>(url, { headers });
};

export const getApplicationList = async (params: FetchSortRequestListType) => {
  return await api.get<PaginationApplicationResponseType>(
    `/list${buildPaginationFilterQuery(params)}`,
  );
};

export const getAllDraftApplication = async (params: IGetRowsParams) => {
  return await api.get<PaginationQuoteType>(`draft-list${buildAGPaginationFilterQuery(params)}`);
};
export const getAllApplication = async (params: IGetRowsParams) => {
  return await api.get<PaginationQuoteType>(`list${buildAGPaginationFilterQuery(params)}`);
};

export const getDraftApplicationList = async (params: FetchSortRequestListType) => {
  return await api.get<PaginationApplicationResponseType>(
    `draft-list${buildPaginationFilterQuery(params)}`,
  );
};

export const getSubmittedApplicationList = async () => {
  return await api.get<ApplicationListItemBEResponseType[]>('draftList');
};

export const getBrokerApplication = async (applicationId: string) => {
  return await api.get<ApplicationStoreType['brokerApplication']>(`/broker/${applicationId}`);
};

export const getApplication = async (applicationId: string, getRelated: boolean) => {
  return await api.get<ApplicationStoreType['application']>(`/${applicationId}`, {
    params: { getRelated },
  });
};

export const addFileToApplication = async (
  applicationUuid: string,
  files: FormData,
  onProgress: ProgressHandler,
) => {
  const uuid = v4();
  const unsubscribe = subscribe<IoProgress>({
    entity: 'progress',
    filter: uuid,
    handler: onProgress,
  });

  return await api
    .post<FileType[]>('/' + applicationUuid + '/files', files, {
      headers: { 'action-uuid': uuid },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        onProgress(formatBufferProgress(loaded, total));
      },
    })
    .then((res) => {
      unsubscribe();

      return res;
    });
};

export const addFileToApplicationPublic = async ({
  applicationUuid,
  files,
  onProgress,
  routeParams,
}: {
  applicationUuid: string;
  files: FormData;
  onProgress: ProgressHandler;
  routeParams: PublicRouteParams;
}) => {
  const uuid = v4();
  const unsubscribe = subscribe<IoProgress>({
    entity: 'progress',
    filter: uuid,
    handler: onProgress,
  });

  return await api
    .post<FileType[]>('/public/' + applicationUuid + '/files', files, {
      headers: {
        'action-uuid': uuid,
        'vendor-site-url': routeParams.vendorSiteUrl,
        'user-site-url': routeParams.userSiteUrl ?? '',
        'access-token': lsGetApplicationAccessToken(applicationUuid),
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        onProgress(formatBufferProgress(loaded, total));
      },
    })
    .then((res) => {
      unsubscribe();

      return res;
    });
};

export const addFileToAmendment = async ({
  files,
  applicationUuid,
  amendmentUuid,
  onProgress,
}: {
  applicationUuid: string;
  amendmentUuid: string;
  files: FormData;
  onProgress: ProgressHandler;
}) => {
  const uuid = v4();
  const unsubscribe = subscribe<IoProgress>({
    entity: 'progress',
    filter: uuid,
    handler: onProgress,
  });

  return await api
    .post<FileType[]>('/' + applicationUuid + '/amendment/' + amendmentUuid + '/files', files, {
      headers: { 'action-uuid': uuid },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        onProgress(formatBufferProgress(loaded, total));
      },
    })
    .then((res) => {
      unsubscribe();

      return res;
    });
};

export const deleteFileFromApplication = async (applicationUuid: string, fileUuid: string) => {
  return await api.delete('/' + applicationUuid + '/file/' + fileUuid);
};

export const deleteFileFromApplicationPublic = async (
  applicationUuid: string,
  fileUuid: string,
  routeParams: PublicRouteParams,
) => {
  return await api.delete('/public/' + applicationUuid + '/file/' + fileUuid, {
    headers: {
      'vendor-site-url': routeParams.vendorSiteUrl,
      'user-site-url': routeParams.userSiteUrl ?? '',
      'access-token': lsGetApplicationAccessToken(applicationUuid),
    },
  });
};

export const deleteFileFromAmendment = async (
  applicationUuid: string,
  amendmentUuid: string,
  fileUuid: string,
) => {
  return await api.delete(
    '/' + applicationUuid + '/amendment/' + amendmentUuid + '/files' + fileUuid,
  );
};

export const getDraftApplication = async (applicationId: string) => {
  return await api.get<NewApplicationStoreTypes['application']>(`/draft/${applicationId}`);
};

export const getDraftApplicationPublic = async (
  applicationUuid: string,
  routeParams: PublicRouteParams,
) => {
  return await api.get<NewApplicationStoreTypes['application']>(
    `/public/draft/${applicationUuid}`,
    {
      headers: {
        'vendor-site-url': routeParams.vendorSiteUrl,
        'user-site-url': routeParams.userSiteUrl ?? '',
        'access-token': lsGetApplicationAccessToken(applicationUuid),
      },
    },
  );
};

export const createApplicationFromQuote = async (quoteUuid: string) => {
  return await api.post<NewApplicationStoreTypes['application']>(`/quote/${quoteUuid}`);
};

export const createApplicationFromQuotePublic = async (
  quoteUuid: string,
  routeParams: PublicRouteParams,
  language?: string,
) => {
  return await api.post<NewApplicationStoreTypes['application']>(
    `/public/quote/${quoteUuid}${language ? '?lang=' + language : ''}`,
    {},
    {
      headers: {
        'vendor-site-url': routeParams.vendorSiteUrl,
        'user-site-url': routeParams.userSiteUrl ?? '',
      },
    },
  );
};

export const getApplicationAmendments = async (applicationUuid: string) => {
  return await api.get<AmendmentType[]>(`/${applicationUuid}/amendments`);
};

export const deleteDraftApplications = async (uuidList: string[]) => {
  return await api.delete('/draft', { params: { uuids: uuidList } });
};

export const postApplicationAmendments = async (
  applicationUuid: string,
  amendmentData: AmendmentType,
) => {
  return await api.post<AmendmentType>(`/${applicationUuid}/amendments`, amendmentData);
};

export const putApplicationAmendments = async (
  applicationUuid: string,
  amendmentData: AmendmentType,
) => {
  return await api.put<AmendmentType>(
    `/${applicationUuid}/amendments/${amendmentData.uuid}`,
    amendmentData,
  );
};

export const deletePartyFromApplication = async (applicationUuid: string, partyUuid: string) => {
  return await api.delete('/' + applicationUuid + '/party/' + partyUuid);
};

export const deletePartyFromApplicationPublic = async (
  applicationUuid: string,
  partyUuid: string,
  routeParams: PublicRouteParams,
) => {
  return await api.delete('/public/' + applicationUuid + '/party/' + partyUuid, {
    headers: {
      'vendor-site-url': routeParams.vendorSiteUrl,
      'user-site-url': routeParams.userSiteUrl ?? '',
      'access-token': lsGetApplicationAccessToken(applicationUuid),
    },
  });
};
export const deleteEquipmentFromApplication = async (
  applicationUuid: string,
  equipmentUuid: string,
) => {
  return await api.delete('/' + applicationUuid + '/equipment/' + equipmentUuid);
};

export const deleteEquipmentFromApplicationPublic = async (
  applicationUuid: string,
  equipmentUuid: string,
  routeParams: PublicRouteParams,
) => {
  return await api.delete('/public/' + applicationUuid + '/equipment/' + equipmentUuid, {
    headers: {
      'vendor-site-url': routeParams.vendorSiteUrl,
      'user-site-url': routeParams.userSiteUrl ?? '',
      'access-token': lsGetApplicationAccessToken(applicationUuid),
    },
  });
};

export const getApplicationDocument = async (applicationUuid: string, documentUuid: string) => {
  return await api.get<ApplicationDocumentType>(`/${applicationUuid}/documents/${documentUuid}`);
};

export const getApplicationDocuments = async (applicationUuid: string) => {
  return await api.get<ApplicationDocumentType[]>(`/${applicationUuid}/documents`);
};

export const generateApplicationDocument = async (
  applicationUuid: string,
  data: ApplicationDocumentData,
) => {
  return await api.post<ApplicationGeneratedDocumentType>(`/${applicationUuid}/documents`, data);
};

export const getApplicationDocumentFile = async (applicationUuid: string, documentUuid: string) => {
  return await api.get<string>(`/${applicationUuid}/documents/${documentUuid}/file`);
};

export const signApplicationDocument = async (
  applicationUuid: string,
  documentUuid: string,
  attachments: Array<ApplicationDocumentSignerAttachment>,
) => {
  return await api.post<ApplicationSignedDocumentType>(
    `/${applicationUuid}/documents/${documentUuid}/sign`,
    { attachments },
  );
};

export const sendApplicationDocument = async (
  applicationUuid: string,
  documentUuid: string,
  data: ApplicationDocumentEmailFormData,
) => {
  return await api.post<ApplicationDocumentType>(
    `/${applicationUuid}/documents/${documentUuid}/email`,
    data,
  );
};

export const cancelSignApplicationDocument = async (
  applicationUuid: string,
  documentUuid: string,
) => {
  return await api.put<ApplicationDocumentType>(
    `/${applicationUuid}/documents/${documentUuid}/cancel`,
  );
};

export const deleteApplicationDocument = async (applicationUuid: string, documentUuid: string) => {
  return await api.delete<ApplicationDocumentType>(`/${applicationUuid}/documents/${documentUuid}`);
};

export const submitApplicationDocuments = async (applicationUuid: string, files: string[]) => {
  return await api.post(`/${applicationUuid}/submit-documents`, { files });
};

export const deleteSubmittedApplication = async (applicationUuid: string) => {
  return await api.delete(`/${applicationUuid}`);
};
