import React from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationConnectedPropsType } from './application.container';
import ApplicationTabs from '../../components/common/ApplicationTabs';
import { subscribe } from '../../services/websockets';
import ApplicationError from '../../components/common/ApplicationError';

function Application(props: ApplicationConnectedPropsType) {
  const { id } = useParams<{ id: string }>();
  const [showApplicationError, setShowApplicationError] = React.useState(false);
  const {
    applicationInfo,
    getApplicationInfo,
    getBrokerApplicationInfo,
    addFiles,
    deleteFile,
    resetApplicationAmendments,
    resetApplicationDocuments,
    cleanApplication,
    sendApplicationDocuments,
  } = props;
  const isApplicationApproved = !!applicationInfo?.application.salesforceOpportunityUuid;

  React.useEffect(() => {
    if (!id) {
      return;
    } else {
      getApplicationInfo(id).then((gotApplication) => {
        if (!gotApplication) {
          setShowApplicationError(true);
        }
      });
      getBrokerApplicationInfo(id);
    }

    const unsubscribe = subscribe({
      entity: 'application',
      filter: id,
      handler: () => {
        getApplicationInfo(id);
        getBrokerApplicationInfo(id);
      },
    });

    return () => {
      resetApplicationAmendments();
      resetApplicationDocuments();
      cleanApplication();
      unsubscribe();
    };
  }, [id]);

  if (showApplicationError) {
    return <ApplicationError />;
  }

  return (
    <ApplicationTabs
      loading={props.loading}
      applicationInfo={props.applicationInfo}
      brokerApplicationInfo={props.brokerApplicationInfo}
      sendApplicationDocuments={sendApplicationDocuments}
      applicationFiles={applicationInfo?.attachments ?? []}
      isApproved={isApplicationApproved}
      addFiles={addFiles}
      deleteFile={deleteFile}
    />
  );
}

export default Application;
