export type envProps = {
  REACT_APP_PORT: string;
  REACT_APP_BACKEND_URL: string;
  REACT_APP_HOST: string;
  REACT_APP_GOOGLE_MAP_API: string;
  REACT_APP_SENTRY_URL: string;
  REACT_APP_GOOGLE_TAG_ID: string;
};

export type Env = Window & {
  env?: envProps;
};

export const getBackendUrl = () => {
  const windowEnv = (window as Env).env;
  const appEnvironment = process.env.REACT_APP_ENV || process.env.NODE_ENV;
  const isProduction = appEnvironment === 'production';

  if (windowEnv?.REACT_APP_PORT) {
    const prodUrl = windowEnv.REACT_APP_BACKEND_URL;

    return prodUrl;
  }

  const url = isProduction
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.REACT_APP_BACKEND_DEV_URL;

  return url;
};

export const getSentryDsn = () => {
  const windowEnv = (window as Env).env;

  if (windowEnv?.REACT_APP_SENTRY_URL) return windowEnv.REACT_APP_SENTRY_URL;

  return process.env.REACT_APP_SENTRY_URL;
};

export const getGoogleMapApiKey = () => {
  const windowEnv = (window as Env).env;

  if (windowEnv?.REACT_APP_GOOGLE_MAP_API) return windowEnv.REACT_APP_GOOGLE_MAP_API;

  return process.env.REACT_APP_GOOGLE_MAP_API;
};
