import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  attachmentsModalRoot: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '300px',
      width: '100%',
      maxWidth: '620px',
    },
  },
  sortingIcon: {
    '& svg': {
      fontSize: '0.9rem',
      verticalAlign: 'middle',
    },
  },
  fileIsSelected: {
    backgroundColor: 'rgba(65, 83, 175, 0.15);',
  },
  contentWrapper: {
    padding: 0,
    borderBottom: 'none',
  },
  warningWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '40px',
    paddingLeft: '17px',
    paddingRight: '14px',
    marginBottom: '16px',
  },
  warningShow: {
    backgroundColor: '#FFF0D9',
  },
  warningMessageWrapper: {
    display: 'flex',
    alignItems: 'end',
    '& svg': {
      color: '#F2994A',
      marginRight: '9px',
    },
  },
  tableTitle: {
    color: '#00000099',
    paddingLeft: '23px',
    fontSize: '14px',
    textTransform: 'uppercase',
    marginTop: '40px',
  },
  headerCheckbox: {
    borderRadius: '10px 0 0 0',
  },
  headerFileSize: {
    borderRadius: '0 10px 0 0',
  },
  tableWrapper: {
    padding: '15px 23px',
    boxShadow: 'none',
    '& table': {
      border: '1px solid #DEE2EA',
      borderCollapse: 'unset',
      borderRadius: '10px',
      '& tr:last-child td': {
        borderBottom: 'none',
      },
    },
  },
  dialogActionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    boxShadow: '0px -2px 5px rgb(0 0 0 / 12%)',
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor: '#4153AF',
      opacity: 0.4,
      color: '#FFFF',
    },
  },
  dialogActionsCancel: {
    marginRight: '12px',
  },
}));

export default useStyles;
