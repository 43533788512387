import React from 'react';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import { getFormattedDateTimeUs } from '../../../services/formatter-service';

export default function AppAttachmentsFileRow({
  name,
  type,
  size,
  uuid,
  createdAt,
  isSelected,
  handleCheckboxClick,
  className,
}: {
  name: string;
  type: string;
  size: string;
  uuid: string;
  createdAt: string;
  isSelected: boolean;
  handleCheckboxClick: (event: React.ChangeEvent<HTMLInputElement>, fileUuid: string) => void;
  className: string;
}) {
  return (
    <React.Fragment>
      <TableRow className={className}>
        <TableCell data-cy="file-checkbox" padding="checkbox">
          <Checkbox
            color="primary"
            checked={isSelected}
            onChange={(event) => handleCheckboxClick(event, uuid)}
          />
        </TableCell>
        <TableCell data-cy="file-name" align="left">
          {name.substring(0, name.lastIndexOf('.'))}
        </TableCell>
        <TableCell data-cy="file-type" align="left">
          {type}
        </TableCell>
        <TableCell data-cy="file-size" align="left">
          {size}
        </TableCell>
        <TableCell data-cy="file-date" align="left">
          {getFormattedDateTimeUs(createdAt)}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
