import React, { ComponentProps, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import LinkIcon from '@material-ui/icons/Link';
import { Button, Tooltip } from '@material-ui/core';
import useStyles from './styles';
import { actions as toastActions } from '../../../store/toast';

type ClipboardButtonPropsType = {
  value: string;
} & Partial<ComponentProps<typeof Button>>;

export default function ClipboardButton({ ...props }: ClipboardButtonPropsType) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { children, startIcon, className, value } = props;

  const copyToClipboard = () => {
    if (textareaRef.current) {
      textareaRef.current.value = value;
      textareaRef.current.hidden = false;
      textareaRef.current.select();
      document.execCommand('copy');
      textareaRef.current.hidden = true;
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'common.link_is_copied' }),
      };
      setTimeout(() => dispatch(toastActions.open(toastOptions)), 100);
    }
  };

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: 'common.copy_link',
        })}
        placement="top">
        <Button
          {...props}
          startIcon={startIcon || <LinkIcon />}
          className={className || classes.defaultClipboardButton}
          onClick={copyToClipboard}>
          {children}
        </Button>
      </Tooltip>
      <textarea readOnly hidden ref={textareaRef} defaultValue={value} />
    </>
  );
}
