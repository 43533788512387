import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ol: {
    padding: '0',
    listStyle: 'decimal inside',
  },
  ul: {
    padding: '0',
    listStyle: 'none',
  },
  listItem: {
    marginTop: theme.spacing(2),
    marginBottomBottom: theme.spacing(2),
  },
}));

export { useStyles };
