import Signup from './sign-up.component';

export default [
  {
    path: '/sign-up',
    name: 'sign-up',
    component: Signup,
    exact: true,
  },
];
