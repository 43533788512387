import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getApplication,
  getBrokerApplication,
  getApplicationList,
  getAllApplication,
  submitApplicationDocuments,
} from '../../apis/application';
import { actions as toastActions } from '../toast';
import { Dispatch } from 'react';
import {
  addFileToApplicationPublic as addFileToApplicationPublicRequest,
  addFileToApplication as addFileToApplicationRequest,
  deleteFileFromApplication as deleteFileFromApplicationRequest,
} from '../../apis/application';
import { getIntl } from '../../utils/language';
import { ProgressHandler } from '../../services/websockets';
import { IGetRowsParams } from '@ag-grid-community/all-modules';

const initialState: ApplicationStoreType = {
  loading: false,
  application: undefined,
  applicationList: {
    data: [],
    total: 0,
  },
  brokerApplication: {},
  error: '',
};

const slice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    cleanState: () => initialState,
    updateApplication: (state, action: PayloadAction<ApplicationStoreType['application']>) => {
      state.application = action.payload;
    },
    addApplicationFiles: (state, action: PayloadAction<FileType[]>) => {
      if (state.application) {
        state.application.attachments = [...state.application.attachments, ...action.payload];
      }
    },
    setDocumentsSent: (state, action: PayloadAction<boolean>) => {
      state.application!.application.documentsSubmittingInProgress = action.payload ?? true;
    },
    removeApplicationFile: (state, action: PayloadAction<string>) => {
      if (state.application) {
        state.application.attachments = state.application.attachments.filter(
          (f) => f.uuid !== action.payload,
        );
      }
    },
    updateBrokerApplication: (
      state,
      action: PayloadAction<ApplicationStoreType['brokerApplication']>,
    ) => {
      state.brokerApplication = action.payload;
    },
    updateApplicationList: (state, action: PayloadAction<PaginationApplicationListType>) => {
      state.applicationList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    fetchApplicationError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

const sendApplicationDocuments =
  (applicationUuid: string, files: string[]) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      dispatch(slice.actions.setDocumentsSent(true));
      await submitApplicationDocuments(applicationUuid, files);
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'application.application_documents_sent_successfully' }),
      };
      dispatch(toastActions.open(toastOptions));

      return true;
    } catch {
      dispatch(slice.actions.setDocumentsSent(false));
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.application.application.something_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));

      return false;
    }
  };

const fileTypeMap = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'document/docx',
};
const getApplicationInfo =
  (applicationId: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    if (!applicationId || !applicationId.length) {
      return dispatch(slice.actions.updateApplication({}));
    }
    dispatch(slice.actions.setLoading(true));
    let applicationInfo;
    try {
      applicationInfo = (await getApplication(applicationId, true)).data;
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.error_fetch' }),
      };
      dispatch(toastActions.open(toastOptions));
      return false;
    }
    const toastOptions: Pick<ToastStoreTypes, 'message' | 'toastType'> = {
      toastType: 'success',
      message: intl.formatMessage({ id: 'application.loaded' }),
    };

    applicationInfo?.attachments.map(
      (file) => (file.type = fileTypeMap[file.type as keyof typeof fileTypeMap] || file.type),
    );

    dispatch(slice.actions.setLoading(false));
    dispatch(toastActions.open(toastOptions));
    dispatch(slice.actions.updateApplication(applicationInfo));
    return true;
  };

const getBrokerApplicationInfo =
  (applicationId: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    if (!applicationId || !applicationId.length) {
      return dispatch(slice.actions.updateBrokerApplication({}));
    }
    dispatch(slice.actions.setLoading(true));
    let brokerApplicationInfo;
    try {
      brokerApplicationInfo = (await getBrokerApplication(applicationId)).data;
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.error_fetch' }),
      };
      return dispatch(toastActions.open(toastOptions));
    }
    const toastOptions: Pick<ToastStoreTypes, 'message' | 'toastType'> = {
      toastType: 'info',
      message: '',
    };

    if (brokerApplicationInfo === undefined || Object.keys(brokerApplicationInfo).length === 0) {
      toastOptions.toastType = 'error';
      toastOptions.message = intl.formatMessage({ id: 'application.no_record_found' });
      dispatch(slice.actions.setLoading(false));
      dispatch(toastActions.open(toastOptions));
    } else {
      toastOptions.toastType = 'success';
      toastOptions.message = intl.formatMessage({ id: 'application.loaded.successfully' });
      dispatch(slice.actions.setLoading(false));
      dispatch(toastActions.open(toastOptions));
      dispatch(slice.actions.updateBrokerApplication(brokerApplicationInfo));
    }
  };

const updateApplicationListById =
  (params: FetchSortRequestListType) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let applicationList;
    try {
      applicationList = (await getApplicationList(params)).data;
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.application.application.something_wrong' }),
      };
      return dispatch(toastActions.open(toastOptions));
    }

    // customize list for grid component
    const formattedApplicationList = applicationList.data.map(
      (application: ApplicationListItemBEResponseType) => {
        return {
          uuid: application.uuid,
          table: 'Application',
          id: '0',
          updatedAt: '',
          contractId: application.contractId,
          applicationName: application.applicationName,
          equipment: application.description,
          equipmentCost: application.totalCost,
          status: {
            status: application.statusName,
            color: application.statusColor,
          },
          email: application.customerEmail,
          createdAt: application.createdAt,
          createdBy: application.userName,
          phone: application.customerPhone,
          companyLegalName: application.companyLegalName,
        } as ApplicationListItemType;
      },
    );

    dispatch(
      slice.actions.updateApplicationList({
        data: formattedApplicationList,
        total: applicationList.total,
      }),
    );
  };

const addFilesToApplication =
  (
    applicationUuid: string,
    files: File[],
    onProgress: ProgressHandler,
    routeParams: PublicRouteParams,
  ) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    const formData = new FormData();
    files.map((file) => {
      formData.append('file', file);
    });
    let savedFiles;
    try {
      savedFiles = routeParams.vendorSiteUrl
        ? (
            await addFileToApplicationPublicRequest({
              applicationUuid,
              files: formData,
              routeParams,
              onProgress,
            })
          ).data
        : (await addFileToApplicationRequest(applicationUuid, formData, onProgress)).data;
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'quote.attachments_file_save_error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.addApplicationFiles(savedFiles));
  };

const removeFileFromApplication =
  (applicationUuid: string, fileUuid: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await deleteFileFromApplicationRequest(applicationUuid, fileUuid);
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.error_removing_file' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.removeApplicationFile(fileUuid));
  };

const fetchApplicationList =
  (params: IGetRowsParams) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let applicationList;

    try {
      applicationList = (await getAllApplication(params)).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    return applicationList;
  };

export const actions = {
  ...slice.actions,
  fetchApplicationList,
  getApplicationInfo,
  getBrokerApplicationInfo,
  updateApplicationListById,
  removeFileFromApplication,
  addFilesToApplication,
  sendApplicationDocuments,
};

export default slice.reducer;
