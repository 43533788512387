import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { navigationLinks } from '../../constants/navigations';
import { useIntl } from 'react-intl';

type AppBarLinkPropsType = {
  link: typeof navigationLinks[number];
  onClick: () => void;
};

export default function AppBarLink({ link, onClick }: AppBarLinkPropsType) {
  const match = useRouteMatch(link.selectedRoutes.map((r) => r.path));
  const intl = useIntl();
  const titleId = link.route.title || '';

  return (
    <ListItem
      data-cy="app-bar-link"
      button
      component={Link}
      to={link.route.path}
      selected={match?.isExact}
      onClick={onClick}
      key={link.route.path}
    >
      <ListItemIcon>
        <link.Icon color={match?.isExact ? 'primary' : 'inherit'} />
      </ListItemIcon>
      {/* <ListItemText primary={link.route.title} /> */}
      <ListItemText primary={intl.formatMessage({ id: titleId })} />
    </ListItem>
  );
}
