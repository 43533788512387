import { currencyTypes, defaultCurrency } from '../constants/currency';

export const getCurrencyByName = (name?: string | null) => {
  return currencyTypes.find((c) => c.name === name) || defaultCurrency;
};

export const getCurrencyByLang = (language?: string) => {
  return currencyTypes.find((c) => c.language === language) || defaultCurrency;
};

export const getPrefixConfigByLang = (
  lang: string,
  currency: typeof currencyTypes[number],
): { [key: string]: string } => {
  switch (lang) {
    case 'FR':
      return { suffix: ` ${currency.prefix} ${currency.extendedPrefix}` };
    case 'EN':
    default:
      return { prefix: `${currency.extendedPrefix}${currency.prefix} ` };
  }
};
