import React from 'react';
import MaterialChip from '@material-ui/core/Chip';
import { useIntl } from 'react-intl';
import { useStyles } from './styles';
import { translateLabel } from '../../../utils/translateLabel';

type ChipPropsType = {
  label?: string;
  backgroundColor?: string;
  dataCy?: string;
};

function Chip(props: ChipPropsType) {
  const { label, backgroundColor, dataCy } = props;
  const classes = useStyles();
  const intl = useIntl();
  if (!label) {
    return null;
  }
  return (
    <MaterialChip
      {...(dataCy ? { 'data-cy': dataCy } : {})}
      label={translateLabel(intl, label as string)}
      className={classes.chip}
      style={{ backgroundColor }}
    />
  );
}

export default Chip;
