import React from 'react';
import Container from '@material-ui/core/Container';
import { useIntl } from 'react-intl';

export default function Health() {
  const intl = useIntl();

  return (
    <Container component="main" maxWidth="xs">
      <h3>{intl.formatMessage({ id: 'common.app_is_healthy' })}</h3>
      <div>release 62</div>
    </Container>
  );
}
