import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: 'center',
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  delete: {
    color: '#eb5757',
  },
  iconContainer: {
    maxWidth: '44px',
  },
}));

export default useStyles;
