import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  textAlignLeft: {
    textAlign: 'left',
    marginBottom: 0,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  dateLabel: {
    textAlign: 'left',
    fontWeight: 500,
    fontSize: '0.875rem',
    marginBottom: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    //color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  smallMarginTop: {
    marginTop: theme.spacing(3),
  },
  smallMarginRight: {
    marginRight: theme.spacing(3),
  },
  headerTitle: {
    marginBottom: theme.spacing(3),
  },
  conditionsList: {
    listStyleType: '"*"',
    width: '100%',
    textAlign: 'left',
    paddingLeft: '6px',
    fontSize: '16px',
  },
  conditionName: {
    color: '#828282',
    paddingLeft: '5px',
  },
  marginLeftSm: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginTop: '0px',
    },
  },
  rightBlockPadding: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      paddingLeft: theme.spacing(2),
    },
  },
  formContainer: {
    paddingTop: theme.spacing(2),
  },
  followUpButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  followUpButton: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: 0,
    },
  },
}));

export default useStyles;
