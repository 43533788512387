import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  defaultClipboardButton: {
    width: '100%',
    minWidth: 'auto',
    paddingLeft: '0',
    paddingRight: '0',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  hiddenTextArea: {
    display: 'none',
    visibility: 'hidden',
  },
}));

export default useStyles;
