import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as globalSearchApi from '../../apis/globalSearch';
import { actions as toastActions } from '../../store/toast';
import { Dispatch } from 'react';
import { getIntl } from '../../utils/language';

const initialState: GlobalSearchStoreType = {
  loading: false,
  error: '',
  searchCount: 0,
  globalSearchList: {
    draftApplications: {
      count: 0,
      rows: [],
    },
    submittedApplications: {
      count: 0,
      rows: [],
    },
    users: {
      count: 0,
      rows: [],
    },
    quotes: {
      count: 0,
      rows: [],
    },
  },
};

const slice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    cleanState: (state) => {
      state.error = '';
      state.searchCount = 0;
      state.globalSearchList = initialState.globalSearchList;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    fetchSearchError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    updateSearchList: (state, action: PayloadAction<GlobalSearchStoreType['globalSearchList']>) => {
      state.globalSearchList = action.payload;
      state.searchCount = 0;
      state.searchCount += state.globalSearchList['draftApplications'].count;
      state.searchCount += state.globalSearchList['submittedApplications'].count;
      state.searchCount += state.globalSearchList['users'].count;
      state.searchCount += state.globalSearchList['quotes'].count;
    },
  },
});

export const fetchSearchQuery =
  (searchQuery: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let allSearches;
    dispatch(actions.setLoading(true));
    try {
      allSearches = await globalSearchApi.getSearch(searchQuery);
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));
      dispatch(actions.setLoading(false));
      return;
    }

    dispatch(actions.updateSearchList(allSearches));
    dispatch(actions.setLoading(false));
  };

export const actions = {
  ...slice.actions,
  fetchSearchQuery,
};

export default slice.reducer;
