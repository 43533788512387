import React from 'react';
import {
  IconButton,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Collapse,
} from '@material-ui/core';
import { useStyles, useRowStyles, StyledTableCell, StyledTableRow } from './styles';
import Loading from '../../components/common/Loading';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';
import CurrencyFormat from '../../components/common/currency-format';
import { getFullUserName } from '../../utils/common';

function ApplicationSummary(props: {
  applicationInfo: ApplicationStoreType['application'];
  loading: boolean;
}) {
  const classes = useStyles();
  const intl = useIntl();
  const { applicationInfo } = props;
  const operatingName = applicationInfo?.applicant?.entity?.operatingName;

  const getVendorRep = () => {
    let vendorRepFullName: string;

    if (applicationInfo?.application?.user) {
      vendorRepFullName = getFullUserName(
        applicationInfo.application.user.firstName,
        applicationInfo.application.user.lastName,
      );
    } else {
      vendorRepFullName = 'N/A';
    }

    return vendorRepFullName;
  };

  return (
    <React.Fragment>
      {props.loading && (
        <Paper className={clsx(classes.paper, classes.smallMarginTop)}>
          <Loading isOpen={props.loading} />
        </Paper>
      )}
      {!props.loading && (
        <React.Fragment>
          <div className={classes.applicationHeader}>
            <div className={classes.applicationTitle}>
              <div className={classes.applicationTitleLabel}>
                {intl.formatMessage({ id: 'application.application_name' })}
              </div>
              {applicationInfo?.application?.applicationName}
            </div>
            {applicationInfo?.application?.contractId && (
              <div className={classes.applicationTitle}>
                <div className={classes.applicationTitleLabel}>
                  {intl.formatMessage({ id: 'application.contract_id' })}
                </div>
                {applicationInfo?.application?.contractId}
              </div>
            )}
          </div>
          <div data-cy="summary-vendor-rep" className={classes.vendorRep}>
            <div className={classes.vendorRepLabel}>
              {intl.formatMessage({ id: 'company.vendor_rep' })}
            </div>
            {getVendorRep()}
          </div>
          <div data-cy="applicant-summary-title" className={classes.summarySection}>
            {intl.formatMessage({ id: 'applicant.applicant' })}
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead data-cy="applicant-summary-header">
                <PartyHeaderRow hideExpand={!applicationInfo?.applicant} />
              </TableHead>
              <TableBody data-cy="applicant-summary-body">
                {applicationInfo?.applicant ? <PartyRow {...applicationInfo?.applicant} /> : null}
              </TableBody>
            </Table>
            {operatingName && (
              <div className={classes.operatingName}>
                <div className={classes.operatingNameTitle}>
                  {intl.formatMessage({ id: 'applicant.operating_name' })}
                </div>
                <span className={classes.operatingNameData}>{operatingName}</span>
              </div>
            )}
          </TableContainer>
          {!!applicationInfo?.parties.length && (
            <React.Fragment>
              <div data-cy="colessee-summary-title" className={classes.summarySection}>
                {intl.formatMessage({ id: 'applicant.co_lessees' })}
              </div>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table" size="small">
                  <TableHead data-cy="colessee-summary-header">
                    <PartyHeaderRow hideExpand={applicationInfo.parties.length === 0} />
                  </TableHead>
                  <TableBody data-cy="colessee-summary-body">
                    {applicationInfo.parties.map((entity) => (
                      <PartyRow key={entity.uuid} {...entity} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}
          <div data-cy="equipment-summary-title" className={classes.summarySection}>
            {intl.formatMessage({ id: 'application.equipment' })}
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead data-cy="equipment-summary-header">
                <EquipmentHeaderRow />
              </TableHead>
              <TableBody data-cy="equipment-summary-table-body">
                {applicationInfo?.equipments.map((row) => (
                  <EquipmentRow
                    key={row.uuid}
                    row={row}
                    currency={applicationInfo?.application.currency}
                  />
                ))}
                <TotalRow
                  equipmentItems={applicationInfo?.equipments ?? []}
                  currency={applicationInfo?.application?.currency}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function EquipmentRow(props: { row: Equipment; currency: string | null | undefined }) {
  const { row, currency } = props;
  const classes = useRowStyles();

  const sub_total = (row.cost ?? 0) * (row.quantity ?? 1);

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <TableCell data-cy="eq-description" component="th" scope="row">
          {row.description}
        </TableCell>
        <TableCell data-cy="eq-quantity" align="center">
          {row.quantity}
        </TableCell>
        <TableCell data-cy="eq-cost" align="center">
          <CurrencyFormat value={row.cost} currencyType={currency} />
        </TableCell>
        <TableCell data-cy="eq-sub-total" align="right">
          <CurrencyFormat value={sub_total} currencyType={currency} />
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

const EquipmentHeaderRow = () => (
  <TableRow>
    <StyledTableCell component="th" scope="row" align="left">
      <FormattedMessage id="equipment.description" />
    </StyledTableCell>
    <StyledTableCell component="th" scope="row" align="center">
      <FormattedMessage id="equipment.quantity" />
    </StyledTableCell>
    <StyledTableCell component="th" scope="row" align="center">
      <FormattedMessage id="equipment.unit_cost" />
    </StyledTableCell>
    <StyledTableCell component="th" scope="row" align="right">
      <FormattedMessage id="equipment.subtotal" />
    </StyledTableCell>
  </TableRow>
);

const PartyHeaderRow = ({ hideExpand }: { hideExpand: boolean }) => (
  <TableRow>
    {!hideExpand && <StyledTableCell component="th" scope="row" align="center" />}
    <StyledTableCell component="th" scope="row" align="left">
      <FormattedMessage id="application.file_name" />
    </StyledTableCell>
    <StyledTableCell component="th" scope="row" align="center">
      <FormattedMessage id="common.role" />
    </StyledTableCell>
    <StyledTableCell component="th" scope="row" align="center">
      <FormattedMessage id="applicant.type" />
    </StyledTableCell>
  </TableRow>
);

const TotalRow = ({
  equipmentItems,
  currency,
}: {
  equipmentItems: Equipment[];
  currency: string | null | undefined;
}) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.equipmentTotalRow}>
      <TableCell align="right" colSpan={5}>
        <b>Total:</b>
        <div style={{ width: '100%', marginLeft: '0.5rem' }}>
          <CurrencyFormat
            value={equipmentItems?.reduce(
              (sum, i) => (sum += (i.quantity ?? 1) * (i.cost ?? 0)),
              0,
            )}
            currencyType={currency}
            dataCy="equipment-summary-total"
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

const rolesMapping: { [role in ApplicationRole]: string } = {
  'Co-lessee': 'applicant.co_lessee',
  Applicant: 'applicant.applicant',
  Guarantor: 'constants.guarantor',
};

function PartyRow({ entity, role }: EntityApplicationRoleType) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const intl = useIntl();
  const applicantEmail = entity?.isBusiness ? entity.companyEmail : entity.email;
  const contact = entity?.isBusiness ? entity.contact : null;
  const phone = entity.formattedPhone;
  const mobilePhone = entity.formattedMobilePhone;

  let partyName = null;
  const businessContactName =
    contact && contact?.lastName
      ? `${contact.firstName ?? ''} ${contact.middleName ?? ''} ${contact.lastName ?? ''}`
      : null;
  if (entity.isBusiness) {
    partyName = entity.companyLegalName;
  } else {
    partyName = `${entity.firstName ?? ''} ${entity.middleName ?? ''} ${entity.lastName ?? ''}`;
  }

  let partyAddress = '';
  if (entity.unit) {
    partyAddress += entity.unit;
  }
  if (entity.streetNumber) {
    partyAddress += partyAddress ? ' - ' + entity.streetNumber : entity.streetNumber;
  }
  if (entity.streetName) {
    partyAddress += partyAddress ? ' ' + entity.streetName : entity.streetName;
  }
  if (entity.city) {
    partyAddress += partyAddress ? ', ' + entity.city : entity.city;
  }
  if (entity.province) {
    partyAddress += partyAddress ? ', ' + entity.province : entity.province;
  }
  if (entity.postalCode) {
    partyAddress += partyAddress ? ', ' + entity.postalCode : entity.postalCode;
  }
  if (entity.country && entity.country !== 'Canada') {
    partyAddress += partyAddress ? ', ' + entity.country : entity.country;
  }

  return (
    <React.Fragment>
      <TableRow data-cy="brief-info" className={classes.root}>
        <TableCell className={classes.expandCell}>
          <IconButton
            data-cy="expand-icon"
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell data-cy="name-value" align="left" component="th" scope="row">
          {partyName}
        </TableCell>
        <TableCell data-cy="role-value" align="center">
          {intl.formatMessage({ id: rolesMapping[role as ApplicationRole] ?? role })}
        </TableCell>
        <TableCell data-cy="type-value" align="center">
          {entity.isBusiness
            ? intl.formatMessage({ id: 'applicant.business' })
            : intl.formatMessage({ id: 'applicant.individual' })}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.flexPaddingRight}>
                <Paper elevation={1} className={classes.partyDetailsSection}>
                  <div className={classes.detailsLabel}>
                    {entity.isBusiness
                      ? intl.formatMessage({ id: 'applicant.business_address' })
                      : intl.formatMessage({ id: 'common.address' })}
                  </div>
                  <div data-cy="applicant-address">{partyAddress}</div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Paper elevation={1} className={classes.partyDetailsSection}>
                  <div className={classes.detailsLabel}>
                    {entity.isBusiness
                      ? intl.formatMessage({ id: 'applicant.business_email' })
                      : intl.formatMessage({ id: 'common.email' })}
                  </div>
                  <div data-cy="applicant-email">
                    {applicantEmail || intl.formatMessage({ id: 'common.unspecified' })}
                  </div>
                  <div className={classes.detailsLabel}>
                    {entity.isBusiness
                      ? intl.formatMessage({ id: 'applicant.business_phone' })
                      : intl.formatMessage({ id: 'common.phone' })}
                  </div>
                  <div data-cy="applicant-phone">
                    {phone || intl.formatMessage({ id: 'common.unspecified' })}
                  </div>
                  {!entity.isBusiness && (
                    <React.Fragment>
                      <div className={classes.detailsLabel}>
                        {intl.formatMessage({ id: 'common.mobile' })}
                      </div>
                      <div data-cy="applicant-mobile">
                        {mobilePhone || intl.formatMessage({ id: 'common.unspecified' })}
                      </div>
                    </React.Fragment>
                  )}
                </Paper>
                {!entity.isBusiness && (
                  <Paper elevation={1} className={classes.partyDetailsSection}>
                    <div className={classes.detailsLabel}>
                      {intl.formatMessage({ id: 'common.dob' })}
                    </div>
                    <div data-cy="applicant-date-of-birth">
                      {entity.dateOfBirth
                        ? entity.dateOfBirth
                        : intl.formatMessage({ id: 'common.unspecified' })}
                    </div>
                    <div className={classes.detailsLabel}>
                      {intl.formatMessage({ id: 'common.social_insurance_number' })}
                    </div>
                    <div data-cy="applicant-sin">
                      {entity.sin ? entity.sin : intl.formatMessage({ id: 'common.unspecified' })}
                    </div>
                  </Paper>
                )}
              </Grid>
              {contact && (
                <React.Fragment>
                  <div className={classes.detailsSectionLabel}>
                    {intl.formatMessage({ id: 'company.business_contact' })}
                  </div>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={1} className={classes.partyDetailsSection}>
                      <div className={classes.detailsLabel}>
                        {intl.formatMessage({ id: 'application.file_name' })}
                      </div>
                      <div data-cy="business-contact-name">{businessContactName}</div>
                      <div className={classes.detailsLabel}>
                        {intl.formatMessage({ id: 'user_managment.email' })}
                      </div>
                      <div data-cy="business-contact-email">
                        {contact!.email || contact!.companyEmail
                          ? contact!.email || contact!.companyEmail
                          : intl.formatMessage({ id: 'common.unspecified' })}
                      </div>
                      {contact.formattedPhone ? (
                        <>
                          <div className={classes.detailsLabel}>
                            {intl.formatMessage({ id: 'user_managment.phone' })}
                          </div>
                          <div data-cy="business-contact-phone">
                            {contact.formattedPhone ||
                              intl.formatMessage({ id: 'common.unspecified' })}
                          </div>
                        </>
                      ) : null}
                      {contact.formattedMobilePhone ? (
                        <>
                          <div className={classes.detailsLabel}>
                            {intl.formatMessage({ id: 'user_managment.mobile' })}
                          </div>
                          <div data-cy="business-contact-mobile">
                            {contact.formattedMobilePhone ||
                              intl.formatMessage({ id: 'common.unspecified' })}
                          </div>
                        </>
                      ) : null}
                    </Paper>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ApplicationSummary;
