import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F8F8F8',
    fontWeight: 'bolder',
    color: '#000000',
  },
  body: {
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  documentRoot: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    width: '100%' + theme.spacing(3),
    margin: theme.spacing(-3, -3, -3),
    padding: '15px',
    '& hr': {
      opacity: '.3',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperTitle: {
    position: 'relative',
    top: '-15px',
    left: '-15px',
    marginBottom: theme.spacing(3),
    fontSize: '20px',
    fontWeight: 500,
    color: '#000000',
    textAlign: 'left',
  },
  paperActions: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(0),
  },
  pullLeft: {
    float: 'left',
  },
  pullRight: {
    float: 'right',
  },
  generateButton: {
    float: 'right',
  },
  documentButton: {
    margin: theme.spacing(1),
  },
  applicationHeader: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& $applicationTitle:first-child': {
      width: '70%',
      paddingRight: '20px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingRight: '0',
      },
    },
  },
  applicationTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  applicationTitleLabel: {
    fontSize: '0.7rem',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
  },
  summarySection: {
    fontSize: '0.9rem',
    fontWeight: 'bolder',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  equipmentTotalRow: {
    borderTop: '1px solid #777777',
  },
  headerRow: {
    borderBottom: '1px solid #777777',
  },
  smallMarginTop: {
    marginTop: theme.spacing(3),
  },
  smallMarginRight: {
    marginRight: theme.spacing(3),
  },
  tabPanelBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  tabPanelCaption: {
    fontSize: '20px',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: '#000000',
  },
  tabPanelSecondCaption: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  mirRow: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  role: {
    textTransform: 'uppercase',
    fontSize: 12,
  },
  applicantEmail: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  statusAdditionalInfo: {
    width: '100%',
    fontSize: '0.9rem',
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mainTable: {
    marginTop: theme.spacing(3),
  },
  activeRow: {
    background: 'rgba(65, 83, 175, 0.15)',
  },
  noPadding: {
    padding: 0,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  expandCell: {
    width: '5%',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    margin: '0 -15px',
  },
  labelSuccess: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: 'rgb(237, 247, 237)',
    color: '#009688',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
  },
  labelInfo: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: 'rgba(33, 150, 243, 0.2)',
    color: '#2196F3',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
  },
  labelPending: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#FFF0D9',
    color: '#FF9800',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
  },
  labelError: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#FFDDD3',
    color: '#FF5722',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
  },
  notAvailableContainer: {
    marginBottom: theme.spacing(12),
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  notAvailableIcon: {
    marginTop: theme.spacing(12),
    width: '80px',
    height: '80px',
  },
  overrideDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '800px',
    },
  },
  dialogContent: {
    paddingTop: 0,
    background: '#fafafa',
  },
  errorMessage: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  dialogLargeContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: '#fafafa',
  },
  dialogTitle: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    width: '800px',
    maxWidth: '100%',
    textAlign: 'right',
    '& button': {
      margin: theme.spacing(2),
    },
  },
  iframePreview: {
    padding: theme.spacing(0),
    marginLeft: theme.spacing(-3),
    border: 0,
    width: 'calc(100% + ' + theme.spacing(6) + 'px)',
    minHeight: '600px',
  },
  closeModalBtn: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export { useStyles, StyledTableCell };
