import * as applicationApi from '../../apis/application';
import { actions as toastActions } from '../../store/toast';
import { getFormattedDate } from '../../services/formatter-service';
import { actions } from './reduces';
import { actions as formValuesActions } from '../formValues';
import { Dispatch } from 'react';
import { lsSetApplicationAccessToken } from '../../utils/localStorage';
import { getIntl } from '../../utils/language';
import { DATE_FORMAT_INLINE } from '../../constants/date';
import {
  addFileToApplication as addFileToApplicationRequest,
  addFileToApplicationPublic as addFileToApplicationPublicRequest,
  deleteFileFromApplication as deleteFileFromApplicationRequest,
  deleteFileFromApplicationPublic as deleteFileFromApplicationRequestPublic,
  UpdateApplicationApplicationPayload,
} from '../../apis/application';
import { ProgressHandler } from '../../services/websockets';
import { getVendorBySiteUrl, getVendorUsers } from '../vendorSettings/actions';

export const createApplication =
  ({
    application,
    routeParams,
  }: {
    application: Partial<ApplicationToBEType>;
    routeParams: PublicRouteParams;
  }) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    let createdApplication;
    const intl = getIntl(getState);
    try {
      createdApplication = routeParams.vendorSiteUrl
        ? (await applicationApi.createApplicationPublic(application, routeParams)).data
        : (await applicationApi.createApplication(application)).data;
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));

      return false;
    }

    if (createdApplication?.application?.accessToken && createdApplication?.application.uuid) {
      lsSetApplicationAccessToken(
        createdApplication.application.uuid,
        createdApplication.application.accessToken,
      );
    }

    dispatch(actions.fetchApplication(createdApplication));

    return createdApplication?.application?.uuid;
  };

export const updateApplication =
  ({
    applicationUuid,
    application,
    routeParams,
    noDraftMessage,
  }: {
    applicationUuid: string;
    application: Partial<ApplicationToBEType>;
    routeParams: PublicRouteParams;
    noDraftMessage?: boolean;
  }) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    let updatedApplication;
    const intl = getIntl(getState);
    try {
      updatedApplication = routeParams.vendorSiteUrl
        ? (await applicationApi.updateApplicationPublic(applicationUuid, application, routeParams))
            .data
        : (await applicationApi.updateApplication(applicationUuid, application)).data;
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));

      return false;
    }
    if (application?.application?.accessToken && application?.application.uuid) {
      lsSetApplicationAccessToken(
        application.application.uuid,
        application.application.accessToken,
      );
    }
    const dateTime = getFormattedDate(new Date(), null, DATE_FORMAT_INLINE);

    if (!noDraftMessage) {
      const toastOptions = {
        toastType: 'info' as const,
        message: intl.formatMessage({ id: 'application.draft_saved_at' }, { dateTime }),
      };

      dispatch(toastActions.open(toastOptions));
    }

    dispatch(actions.fetchApplication(updatedApplication));

    return updatedApplication?.application?.uuid;
  };

export const submitApplication =
  ({ applicationUuid, routeParams }: { applicationUuid: string; routeParams: PublicRouteParams }) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    let submittedApplication;
    const intl = getIntl(getState);
    try {
      submittedApplication = (await applicationApi.submitApplication(applicationUuid, routeParams))
        .data;
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));

      return false;
    }
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'application.successfully_submitted' }),
    };
    dispatch(toastActions.open(toastOptions));
    dispatch(actions.fetchApplication(submittedApplication));

    return true;
  };

export const createApplicationParty =
  ({
    parties,
    applicationUuid,
    routeParams,
  }: {
    applicationUuid: string;
    parties: EntityApplicationRoleType[];
    routeParams: PublicRouteParams;
  }) =>
  async (dispatch: Dispatch<unknown>) => {
    dispatch(actions.setLoading(true));
    try {
      const { data } = await applicationApi.createApplicationParty(
        applicationUuid,
        parties,
        routeParams,
      );
      dispatch(actions.fetchApplication(data));
      dispatch(actions.setLoading(false));

      return true;
    } catch (err) {
      dispatch(actions.setLoading(false));

      return false;
    }
  };

// This is for Edit Draft Application
export const getDraftApplication =
  (applicationId: string, routeParams: PublicRouteParams) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    let application;
    dispatch(actions.setLoading(true));
    const intl = getIntl(getState);
    try {
      application = routeParams.vendorSiteUrl
        ? (await applicationApi.getDraftApplicationPublic(applicationId, routeParams)).data
        : (await applicationApi.getDraftApplication(applicationId)).data;
    } catch (error) {
      dispatch(actions.setLoading(false));
      return false;
    }
    dispatch(actions.fetchApplication(application));

    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'application.loaded' }),
    };

    dispatch(toastActions.open(toastOptions));
    dispatch(actions.setLoading(false));
    return true;
  };

export const prepareApplicationForm =
  () => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const { user } = getState();
    const intl = getIntl(getState);
    try {
      dispatch(formValuesActions.getNaicsList());
      if (user.uuid) {
        // should not be called in case of not authenticated user
        dispatch(formValuesActions.getEntityList());
        dispatch(getVendorUsers());
      }
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.failed_prepare_application_form' }),
      };

      return dispatch(toastActions.open(toastOptions));
    }
  };

export const preparePublicApplicationForm =
  (vendorSiteUrl: string, userSiteUrl?: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      dispatch(formValuesActions.getNaicsList());
      dispatch(getVendorBySiteUrl(vendorSiteUrl, userSiteUrl));
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.failed_prepare_application_form' }),
      };

      return dispatch(toastActions.open(toastOptions));
    }
  };

export const deletePartyFromApplication =
  (applicationUuid: string, partyUuid: string, routeParams: PublicRouteParams) =>
  async (dispatch: Dispatch<unknown>) => {
    dispatch(actions.setLoading(true));
    try {
      routeParams.vendorSiteUrl
        ? await applicationApi.deletePartyFromApplicationPublic(
            applicationUuid,
            partyUuid,
            routeParams,
          )
        : await applicationApi.deletePartyFromApplication(applicationUuid, partyUuid);
      dispatch(actions.setLoading(false));
    } catch (err) {
      dispatch(actions.setLoading(false));
    }
  };

export const createApplicationEquipment =
  ({
    applicationUuid,
    routeParams,
    equipments,
  }: {
    applicationUuid: string;
    equipments: Equipment[];
    routeParams: PublicRouteParams;
  }) =>
  async (dispatch: Dispatch<unknown>) => {
    try {
      const { data } = await applicationApi.createApplicationEquipment(
        applicationUuid,
        equipments,
        routeParams,
      );
      dispatch(actions.fetchApplication(data));
    } catch {
      // do nothing
    }
  };

export const updateApplicationEquipment =
  ({
    equipment,
    applicationUuid,
    routeParams,
  }: {
    applicationUuid: string;
    equipment: Equipment;
    routeParams: PublicRouteParams;
  }) =>
  async (dispatch: Dispatch<unknown>) => {
    try {
      const { data } = await applicationApi.updateApplicationEquipment(
        applicationUuid,
        equipment,
        routeParams,
      );
      dispatch(actions.fetchApplication(data));
    } catch {
      // do nothing
    }
  };

export const deleteApplicationEquipment =
  (applicationUuid: string, equipmentUuid: string, routeParams: PublicRouteParams) =>
  async (dispatch: Dispatch<unknown>) => {
    try {
      const { data } = await applicationApi.deleteApplicationEquipment(
        applicationUuid,
        equipmentUuid,
        routeParams,
      );
      dispatch(actions.fetchApplication(data));
    } catch {
      // do nothing
    }
  };

export const createApplicationNote =
  ({
    applicationUuid,
    routeParams,
    note,
  }: {
    applicationUuid: string;
    note: ApplicationNote;
    routeParams: PublicRouteParams;
  }) =>
  async (dispatch: Dispatch<unknown>) => {
    dispatch(actions.setLoading(true));
    try {
      const { data } = await applicationApi.createApplicationNote(
        applicationUuid,
        note,
        routeParams,
      );
      dispatch(actions.fetchApplication(data));
      dispatch(actions.setLoading(false));
    } catch (err) {
      dispatch(actions.setLoading(false));
    }
  };

export const updateApplicationNote =
  ({
    applicationUuid,
    routeParams,
    note,
  }: {
    applicationUuid: string;
    note: ApplicationNote;
    routeParams: PublicRouteParams;
  }) =>
  async (dispatch: Dispatch<unknown>) => {
    dispatch(actions.setLoading(true));
    try {
      const { data } = await applicationApi.updateApplicationNote(
        applicationUuid,
        note,
        routeParams,
      );
      dispatch(actions.fetchApplication(data));
      dispatch(actions.setLoading(false));
    } catch (err) {
      dispatch(actions.setLoading(false));
    }
  };

export const deleteApplicationNote =
  ({
    applicationUuid,
    routeParams,
    noteUuid,
  }: {
    applicationUuid: string;
    noteUuid: string;
    routeParams: PublicRouteParams;
  }) =>
  async (dispatch: Dispatch<unknown>) => {
    dispatch(actions.setLoading(true));
    try {
      const { data } = await applicationApi.deleteApplicationNote(
        applicationUuid,
        noteUuid,
        routeParams,
      );
      dispatch(actions.fetchApplication(data));
      dispatch(actions.setLoading(false));
    } catch (err) {
      dispatch(actions.setLoading(false));
    }
  };

export const updateApplicationParty =
  ({
    party,
    applicationUuid,
    routeParams,
    addContactAsParty,
  }: {
    applicationUuid: string;
    party: EntityApplicationRoleType;
    routeParams: PublicRouteParams;
    addContactAsParty?: boolean;
  }) =>
  async (dispatch: Dispatch<unknown>) => {
    dispatch(actions.setLoading(true));
    try {
      const { data } = await applicationApi.updateApplicationParty(
        applicationUuid,
        party,
        routeParams,
        addContactAsParty,
      );
      dispatch(actions.fetchApplication(data));
      dispatch(actions.setLoading(false));
    } catch (err) {
      dispatch(actions.setLoading(false));
    }
  };

export const updateApplicationApplication =
  ({
    application,
    applicationUuid,
    routeParams,
  }: {
    application: UpdateApplicationApplicationPayload;
    applicationUuid: string;
    routeParams: PublicRouteParams;
  }) =>
  async (dispatch: Dispatch<unknown>) => {
    try {
      const { data } = await applicationApi.updateApplicationApplication(
        application,
        applicationUuid,
        routeParams,
      );
      dispatch(actions.fetchApplication(data));
    } catch {
      // do nothing
    }
  };

export const deleteApplicationParty =
  (applicationUuid: string, partyUuid: string, routeParams: PublicRouteParams) =>
  async (dispatch: Dispatch<unknown>) => {
    dispatch(actions.setLoading(true));
    try {
      const { data } = await applicationApi.deleteApplicationParty(
        applicationUuid,
        partyUuid,
        routeParams,
      );
      dispatch(actions.fetchApplication(data));
      dispatch(actions.setLoading(false));

      return true;
    } catch (err) {
      dispatch(actions.setLoading(false));

      return false;
    }
  };

export const addFilesToNewApplication =
  ({
    applicationUuid,
    routeParams,
    files,
    onProgress,
  }: {
    applicationUuid: string;
    files: File[];
    onProgress: ProgressHandler;
    routeParams: PublicRouteParams;
  }) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    const formData = new FormData();
    files.map((file) => {
      formData.append('file', file);
    });
    let savedFiles;
    try {
      savedFiles = routeParams.vendorSiteUrl
        ? (
            await addFileToApplicationPublicRequest({
              applicationUuid,
              files: formData,
              routeParams,
              onProgress,
            })
          ).data
        : (await addFileToApplicationRequest(applicationUuid, formData, onProgress)).data;
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'quote.attachments_file_save_error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.addApplicationFiles(savedFiles));
  };

export const removeFileFromNewApplication =
  ({
    applicationUuid,
    fileUuid,
    routeParams,
  }: {
    applicationUuid: string;
    fileUuid: string;
    routeParams: PublicRouteParams;
  }) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      routeParams.vendorSiteUrl
        ? await deleteFileFromApplicationRequestPublic(applicationUuid, fileUuid, routeParams)
        : await deleteFileFromApplicationRequest(applicationUuid, fileUuid);
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.error_removing_file' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.removeApplicationFile(fileUuid));
  };
