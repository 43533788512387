import { BaseDataGrid } from '../../components/common/VpDataGrid';
import { AgGridColumn } from '@ag-grid-community/react';
import React from 'react';
import { getLogInActivityList } from '../../apis/activity';

import { AllCommunityModules, GridApi, IDatasource } from '@ag-grid-community/all-modules';
import useStyles from '../../components/common/VpDataGrid/styles';
import clsx from 'clsx';

const LoginAsVendorRep = () => {
  const gridApi = React.useRef<GridApi | null>(null);
  const classes = useStyles();

  const dataSource: IDatasource = {
    getRows: async (params) => {
      const queryArray: Array<[string, string | number]> = [];
      queryArray.push(['limit', params.endRow - params.startRow]);
      queryArray.push(['offset', params.startRow]);
      queryArray.push(['sortingField', params.sortModel[0]?.colId]);
      queryArray.push(['sortingDirection', params.sortModel[0]?.sort]);
      queryArray.push(['adminFullName', params.filterModel['adminFullName']?.filter]);
      queryArray.push(['role', params.filterModel['role']?.filter]);
      queryArray.push(['userFullName', params.filterModel['userFullName']?.filter]);
      queryArray.push(['startTimeFrom', params.filterModel['startTime']?.dateFrom]);
      if (params.filterModel['startTime']?.dateTo) {
        const date = new Date(params.filterModel['startTime']?.dateTo);
        date.setHours(23, 59, 59);
        queryArray.push(['startTimeTo', date.toISOString()]);
      }
      queryArray.push(['endTimeFrom', params.filterModel['endTime']?.dateFrom]);
      if (params.filterModel['endTime']?.dateTo) {
        const date = new Date(params.filterModel['endTime']?.dateTo);
        date.setHours(23, 59, 59);
        queryArray.push(['endTimeTo', date.toISOString()]);
      }

      const queryString = queryArray
        .filter((a) => a[1])
        .map((a) => a.join('='))
        .join('&');

      getLogInActivityList(queryString)
        .then((res) => {
          const vendors = res.data;
          params.successCallback(vendors.rows, vendors.count);
          setTimeout(() => {
            gridApi.current?.sizeColumnsToFit();
          });
        })
        .catch(() => {
          params.failCallback();
        });
    },
  };

  return (
    <div className={clsx(classes.alpineThemeContainer, classes.borderRadius, 'ag-theme-alpine')}>
      <BaseDataGrid
        modules={AllCommunityModules}
        rowModelType="infinite"
        datasource={dataSource}
        onInit={(api) => (gridApi.current = api)}
        pagination
        paginationPageSize={10}
        cacheBlockSize={10}
      >
        <AgGridColumn floatingFilter type="logInAsVendorRepAdminFullName" />
        <AgGridColumn floatingFilter type="logInAsVendorRepUserFullName" />
        <AgGridColumn floatingFilter type="logInAsVendorRepRole" />
        <AgGridColumn floatingFilter type="logInAsVendorRepStartTime" />
        <AgGridColumn floatingFilter type="logInAsVendorRepEndTime" />
      </BaseDataGrid>
    </div>
  );
};

export default LoginAsVendorRep;
