import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function InstantQuoteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M11.11 23.0001C10.9941 23 10.879 22.9797 10.77 22.9401C10.5598 22.8631 10.3815 22.7178 10.2636 22.5274C10.1458 22.337 10.0952 22.1126 10.12 21.8901L10.89 14.8001H5.00004C4.81837 14.8004 4.64006 14.7512 4.48426 14.6577C4.32845 14.5643 4.20104 14.4302 4.11571 14.2698C4.03038 14.1094 3.99035 13.9288 3.99992 13.7474C4.0095 13.566 4.06831 13.3906 4.17004 13.2401L12.06 1.4401C12.1848 1.25544 12.3677 1.11775 12.5797 1.04891C12.7917 0.980061 13.0206 0.983999 13.23 1.0601C13.4314 1.13486 13.6034 1.27248 13.7204 1.45259C13.8375 1.63269 13.8935 1.84571 13.88 2.0601L13.11 9.2001H19C19.1817 9.19982 19.36 9.24904 19.5158 9.34247C19.6716 9.4359 19.799 9.57001 19.8844 9.73039C19.9697 9.89077 20.0097 10.0714 20.0002 10.2528C19.9906 10.4342 19.9318 10.6096 19.83 10.7601L11.94 22.5601C11.8483 22.6958 11.7247 22.8069 11.5799 22.8836C11.4352 22.9603 11.2738 23.0003 11.11 23.0001ZM6.87004 12.8001H12C12.1396 12.8004 12.2775 12.83 12.405 12.8868C12.5324 12.9436 12.6465 13.0265 12.74 13.1301C12.8344 13.2351 12.9055 13.3589 12.9486 13.4933C12.9917 13.6278 13.0058 13.7698 12.99 13.9101L12.54 18.0601L17.13 11.2001H12C11.8589 11.201 11.7192 11.1719 11.5901 11.1149C11.461 11.058 11.3455 10.9743 11.251 10.8695C11.1566 10.7646 11.0854 10.641 11.0421 10.5066C10.9989 10.3723 10.9845 10.2304 11 10.0901L11.45 5.9401L6.87004 12.8001Z" />
      <path d="M21 3H20V2C20 1.73478 19.8946 1.48043 19.7071 1.29289C19.5196 1.10536 19.2652 1 19 1C18.7348 1 18.4804 1.10536 18.2929 1.29289C18.1054 1.48043 18 1.73478 18 2V3H17C16.7348 3 16.4804 3.10536 16.2929 3.29289C16.1054 3.48043 16 3.73478 16 4C16 4.26522 16.1054 4.51957 16.2929 4.70711C16.4804 4.89464 16.7348 5 17 5H18V6C18 6.26522 18.1054 6.51957 18.2929 6.70711C18.4804 6.89464 18.7348 7 19 7C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6V5H21C21.2652 5 21.5196 4.89464 21.7071 4.70711C21.8946 4.51957 22 4.26522 22 4C22 3.73478 21.8946 3.48043 21.7071 3.29289C21.5196 3.10536 21.2652 3 21 3Z" />
    </SvgIcon>
  );
}
