import React from 'react';
import { Grid, Typography, Container, IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import useStyles from './styles';
import {
  defaultEntityContact,
  defaultEntity as globalDefaultEntity,
  entityPropertiesMap,
  useApplicationInputs,
  useHandleEntityChange,
} from './inputs';
import { useFormContext, useWatch } from 'react-hook-form';
import { SOLE_BUSINESS_TYPE } from '../../../constants/formValues';
import CorporationForm from './CorporationForm';
import AddressForm from './AddressForm';
import { useIntl } from 'react-intl';
import SoleProprietorshipForm from './SoleProprietorshipForm';
import ContactForm from './ContactForm';
import { buildStringPath } from '../../../utils/common';
import IndividualForm from './IndividualForm';
import SameAsContext from '../../../pages/new-application/same-as.context';
import { getDatesDifferenceInYears } from '../../../utils/date';
import { useSelector } from 'react-redux';

type EntityFormType = {
  defaultEntity: EntityWithContact | null;
  path: string;
  title: string;
  role?: ApplicationRole;
  sameAsName: string;
  onDelete?: () => unknown;
};

export default function EntityForm({
  title,
  defaultEntity,
  path,
  role,
  sameAsName,
  onDelete,
}: EntityFormType) {
  const classes = useStyles();
  const intl = useIntl();
  const entityChangeHandler = useHandleEntityChange();
  const entityPath = buildStringPath([path, 'entity.']);
  const { ApplicationRoleInput } = useApplicationInputs(path);
  const { BusinessTypeInput, EntityTypeInput } = useApplicationInputs(entityPath);
  const { updateSameAsList, getSameAsList } = React.useContext(SameAsContext);
  const establishedMinYears = useSelector<AppStoreType, number>(
    (state) => state.contacts?.broker?.establishedMinYears ?? 0,
  );

  const { watch, setValue, getValues, control } = useFormContext();

  const businessType = watch(entityPath + entityPropertiesMap.businessType);
  const inBusinessSince = watch(entityPath + entityPropertiesMap.inBusinessSince);
  const isBusiness = watch(entityPath + entityPropertiesMap.isBusiness);

  const entityType = !isBusiness
    ? 'individual'
    : businessType === SOLE_BUSINESS_TYPE.value
    ? 'soleProprietorship'
    : 'corporation'; // includes both corporation and partnership
  const contactPath =
    entityType === 'corporation' ? buildStringPath([entityPath, 'contact.']) : entityPath;
  const hasContact = entityType === 'corporation';
  const sameAsPropertyPath = buildStringPath([
    hasContact ? contactPath : entityPath,
    entityPropertiesMap.sameAs,
  ]);

  const hasMinCoLesseeWarning = React.useMemo(() => {
    if (
      role === 'Applicant' &&
      businessType === 'Corporation' &&
      getDatesDifferenceInYears(new Date(), new Date(inBusinessSince ?? '')) < establishedMinYears
    ) {
      return intl.formatMessage(
        { id: 'application.corporation_colessees_error' },
        { years: establishedMinYears },
      );
    }

    return null;
  }, [businessType, inBusinessSince, role]);

  const sameAs = useWatch({
    name: sameAsPropertyPath,
    control,
    defaultValue: getValues(sameAsPropertyPath),
  });

  React.useEffect(() => {
    const label = hasContact
      ? sameAsName + ' ' + intl.formatMessage({ id: 'common.contact' })
      : sameAsName;

    updateSameAsList({
      key: sameAsName,
      label,
      value: hasContact ? contactPath : entityPath,
    });
  }, [hasContact]);

  React.useEffect(() => {
    if (role !== 'Applicant') {
      setValue(path + entityPropertiesMap.role, role, { shouldDirty: false });
    }
    entityChangeHandler(defaultEntity ?? globalDefaultEntity, entityPath, true);
    entityChangeHandler(
      defaultEntity?.contact ?? defaultEntityContact,
      buildStringPath([entityPath, 'contact']),
      true,
    );
  }, []);

  const sameAsValues = getSameAsList(sameAsName);

  return (
    <>
      <Typography variant="h2" gutterBottom className={classes.headerPlate}>
        {title}
        {!!onDelete && (
          <IconButton
            color="inherit"
            aria-label={intl.formatMessage({ id: 'company.remove_party' })}
            component="span"
            onClick={() => onDelete()}
          >
            <DeleteOutlineIcon />
          </IconButton>
        )}
      </Typography>

      <Container className={classes.container}>
        <Grid container spacing={2}>
          {role !== 'Applicant' && (
            <>
              <Grid item xs={12} sm={4}>
                <ApplicationRoleInput />
              </Grid>
              <Grid item xs={12} sm={4}>
                <EntityTypeInput />
              </Grid>
            </>
          )}

          {isBusiness && (
            <Grid item xs={12} sm={4}>
              <BusinessTypeInput />
            </Grid>
          )}
        </Grid>

        {entityType === 'corporation' && (
          <CorporationForm path={entityPath} minCoLesseeWarning={hasMinCoLesseeWarning} />
        )}
        {entityType === 'soleProprietorship' && (
          <SoleProprietorshipForm
            sameAsValues={sameAsValues}
            sameAsPath={sameAs}
            path={entityPath}
          />
        )}
        {entityType === 'individual' && (
          <IndividualForm sameAsValues={sameAsValues} sameAsPath={sameAs} path={entityPath} />
        )}
      </Container>

      {hasContact && (
        <>
          <Typography variant="h2" gutterBottom className={classes.headerPlate}>
            {intl.formatMessage({ id: 'company.business_contact' })}
          </Typography>

          <Container className={classes.container}>
            <ContactForm path={contactPath} sameAsPath={sameAs} sameAsValues={sameAsValues} />
          </Container>
        </>
      )}
      <>
        <Typography variant="h2" gutterBottom className={classes.headerPlate}>
          {entityType !== 'individual'
            ? intl.formatMessage({ id: 'applicant.business_address' })
            : intl.formatMessage({ id: 'common.address' })}
        </Typography>

        <Container className={classes.container}>
          <AddressForm path={entityPath} sameAsPath={sameAs} />
        </Container>
      </>
    </>
  );
}
