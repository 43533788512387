import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Tabs from './tab';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));

type CustomizeViewPropsType = {
  open: boolean;
  onCloseModel: () => void;
  columns: GridViewColumnType[];
  filterList: GridViewFilterListType;
  updateFilterList: (filterList: GridViewFilterListType) => void;
  addCustomGridView: (customView: {
    label: string;
    columns: GridViewColumnType[];
    filterList: GridViewFilterListType;
  }) => void;
  selectedGridView: string;
};

export default function CustomizeView(props: CustomizeViewPropsType) {
  const classes = useStyles();
  const intl = useIntl();

  const { open, onCloseModel, columns, filterList, updateFilterList, addCustomGridView } = props;
  const [label, setLabel] = useState('');

  const handleSubmit = () => {
    const customView = {
      label,
      columns,
      filterList,
    };
    addCustomGridView(customView);
    onCloseModel();
  };
  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={onCloseModel}>
        <DialogTitle id="alert-dialog-title">
          {intl.formatMessage({ id: 'grid.customize_grid_view' })}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  id="view-name"
                  label={intl.formatMessage({ id: 'grid.view_name' })}
                  defaultValue={intl.formatMessage({ id: 'grid.view_name' })}
                  value={label}
                  onChange={(event) => setLabel(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Tabs columns={columns} filterList={filterList} updateFilterList={updateFilterList} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModel} color="primary">
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            {intl.formatMessage({ id: 'button.save_changes' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
