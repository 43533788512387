import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useStyles from './styles';
import Cancel from '@material-ui/icons/Cancel';
import { IconButton } from '@material-ui/core';

type PdfModalPropsType = {
  url: string;
  isOpen: boolean;
  handleClose: () => unknown;
};

export default function PdfModalBox({ url, isOpen, handleClose }: PdfModalPropsType) {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth={false}
      maxWidth="md"
      PaperProps={{
        style: {
          overflow: 'visible',
        },
      }}
      scroll="body">
      <IconButton
        onClick={handleClose}
        color="inherit"
        component="span"
        className={classes.closeBtn}>
        <Cancel />
      </IconButton>
      <DialogContent className={classes.modalContent}>
        <iframe src={url} className={classes.previewContainer} />
      </DialogContent>
    </Dialog>
  );
}
