import VendorSettingsEdit from './vendor-settings-edit.container';
import * as Navigations from '../../constants/navigations';
const { path } = Navigations.VENDOR_SETTINGS_EDIT_PAGE;
const route = [
  {
    path,
    name: 'VendorSettings',
    component: VendorSettingsEdit,
    exact: true,
  },
];
export default route;
