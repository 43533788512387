import React, { useEffect, useState, useRef } from 'react';
import omit from 'lodash/omit';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';

import Loading from '../../components/common/Loading';
import EditVendorForm from '../../components/common/edit-vendor-form';
import { updateVendor } from '../../store/vendorSettings/actions';
import { VendorSettingsConnectedProps } from './vendor-settings-edit.container';
import ReactRouterPause from '@allpro/react-router-pause';
import { useIntl } from 'react-intl';
import { OnLeavePopup } from '../../components/common/on-leave-popup';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function VendorSettingsEdit(props: VendorSettingsConnectedProps) {
  const {
    vendorsList,
    vendor,
    brokerLogo,
    fetchVendorsList,
    vendorUuid,
    userRole,
    getVendorById,
    changeVendorLogo,
    deleteVendorLogo,
  } = props;
  const formMethods = useForm<VendorCompanyType>({
    defaultValues: {
      vendorCompany: '',
      companyName: '',
      active: false,
      email: '',
      defaultLanguage: 'EN',
      phoneAreaCode: '',
      phone: '',
      phoneExt: '',
      siteUrl: '',
      siteActive: false,
      notificationsEmail: '',
      addressType: 'poBox',
      country: 'CA',
      poBox: '',
      streetNumber: null,
      streetName: '',
      unit: '',
      city: '',
      province: '',
      postalCode: '',
      logoImage: '',
      vendorDocumentsLogo: false,
      companyDocumentsLogo: false,
    },
  });
  const { setValue, watch, handleSubmit, getValues, reset, formState } = formMethods;
  const dispatch = useDispatch();
  const intl = useIntl();
  const isFormDirty = useRef(false);
  const params = useParams<{ vendorUuid: string }>();
  const history = useHistory();

  const [pageLeaveDialogProps, setPageLeaveDialogProps] = useState<null | {
    save: () => unknown;
    leave: () => unknown;
    stay: () => unknown;
  }>(null);
  const [displayChangesModal, setDisplayChangesModal] = useState<string | false>(false);
  const isPoBox = watch('addressType', 'poBox') === 'PO Box';

  const currentCompany = params.vendorUuid;
  const isPortalAdmin = userRole === 'Portal Admin';
  const currentVendorUuid = isPortalAdmin ? vendor.uuid : vendorUuid;
  const isLoading = !vendor.uuid;

  const handleSaveVendor = async (data: VendorCompanyType) => {
    const updatedData = omit(data, 'vendorCompany');

    await dispatch(updateVendor(vendor.uuid, updatedData));
    if (isPortalAdmin) {
      fetchVendorsList();
      return getVendorById(params.vendorUuid);
    }
    getVendorById(params.vendorUuid);
  };

  function handleNavigationAttempt(navigation: { cancel: () => unknown; resume: () => unknown }) {
    setDisplayChangesModal('leaving');
    setPageLeaveDialogProps({
      save: async () => {
        await handleSaveVendor(getValues());
        getVendorById(currentCompany);

        setDisplayChangesModal(false);
        setPageLeaveDialogProps(null);
        navigation.resume();
      },
      stay: () => {
        setDisplayChangesModal(false);
        setPageLeaveDialogProps(null);
        navigation.cancel();
      },
      leave: () => {
        setDisplayChangesModal(false);
        setPageLeaveDialogProps(null);
        navigation.resume();
      },
    });

    return null;
  }

  const beforeUnloadListener = (e: BeforeUnloadEvent) => {
    if (!isFormDirty.current) return;
    e.preventDefault();
    e.returnValue = intl.formatMessage({ id: 'common.unsaved_changes' }); // in all modern browsers this message will be ignored
    return e.returnValue;
  };

  const handleVendorChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    history.push(e.target.value as string);
  };

  isFormDirty.current = formState.isDirty;

  useEffect(() => {
    if (isPortalAdmin) {
      fetchVendorsList();
    }
    window.addEventListener('beforeunload', beforeUnloadListener);
    return () => {
      window.addEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);

  useEffect(() => {
    Object.entries(vendor).forEach(([key, value]) =>
      setValue(key as keyof VendorCompanyType, value),
    );
    reset(getValues());
  }, [vendor]);

  useEffect(() => {
    setValue('poBox', vendor.poBox);
    setValue('streetNumber', vendor.streetNumber);
    setValue('streetName', vendor.streetName);
    setValue('unit', vendor.unit);
  }, [isPoBox]);

  useEffect(() => {
    if (displayChangesModal && !pageLeaveDialogProps) {
      setPageLeaveDialogProps({
        save: async () => {
          await handleSaveVendor(getValues());

          if (displayChangesModal === 'deleting') setDisplayChangesModal('deleting_saved');
          if (displayChangesModal === 'replacing') setDisplayChangesModal('replacing_saved');

          setPageLeaveDialogProps(null);
        },
        stay: () => {
          setDisplayChangesModal(false);
          setPageLeaveDialogProps(null);
        },
        leave: () => {
          if (displayChangesModal === 'deleting') setDisplayChangesModal('deleting_saved');
          if (displayChangesModal === 'replacing') setDisplayChangesModal('replacing_saved');

          setPageLeaveDialogProps(null);
        },
      });
    }
  }, [displayChangesModal]);
  useEffect(() => {
    getVendorById(params.vendorUuid);
  }, [params.vendorUuid]);

  const changeLogo = (
    logo: FormData,
    vendorDocumentsLogo?: boolean,
    companyDocumentsLogo?: boolean,
  ) => {
    const isCurrentVendor = vendor.uuid === vendorUuid;
    return changeVendorLogo(
      vendor.uuid,
      logo,
      isCurrentVendor,
      vendorDocumentsLogo,
      companyDocumentsLogo,
    );
  };

  return (
    <FormProvider {...formMethods}>
      {isLoading ? (
        <Paper>
          <Loading isOpen={isLoading} />
        </Paper>
      ) : (
        <EditVendorForm
          changeLogo={changeLogo}
          handleSubmit={handleSubmit}
          isPortalAdmin={isPortalAdmin}
          vendorsList={vendorsList.data}
          onVendorChange={handleVendorChange}
          isPoBox={isPoBox}
          handleSaveVendor={handleSaveVendor}
          vendorUuid={currentVendorUuid}
          vendorLogo={vendor.logoImage}
          brokerLogo={brokerLogo}
          deleteVendorLogo={deleteVendorLogo}
          currentCompany={params.vendorUuid}
          vendorDocumentsLogo={vendor.vendorDocumentsLogo}
          companyDocumentsLogo={vendor.companyDocumentsLogo}
          displayChangesModal={displayChangesModal}
          setDisplayChangesModal={setDisplayChangesModal}
          isVendorFormDirty={isFormDirty.current}
          siteUrl={vendor.siteUrl}
          hideVendorMenu
        />
      )}

      <ReactRouterPause handler={handleNavigationAttempt} when={isFormDirty.current} />
      <OnLeavePopup
        open={!!displayChangesModal}
        leave={pageLeaveDialogProps?.leave}
        cancel={pageLeaveDialogProps?.stay}
        saveAndLeave={pageLeaveDialogProps?.save}
      />
    </FormProvider>
  );
}
export default VendorSettingsEdit;
