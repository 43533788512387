import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbarWrapper: {
    paddingLeft: '60px',
    paddingRight: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '40px',
    },
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: '0 1px 0 0 ' + theme.palette.divider,
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      width: 0,
    },
  },
  publicUrl: {
    width: '100%',
  },
  menuButtonWrapper: {
    height: '100%',
    position: 'absolute',
    width: '60px',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        backgroundColor: '#e0e0e0',
        width: '40px',
      },
    },
    '& .MuiIconButton-edgeStart': {
      marginLeft: 0,
    },
  },
  IconButton: {},
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    borderRight: 'none',
  },
  userLink: {
    padding: theme.spacing(3) - 4,
  },
  userLinkButton: {
    width: '100%',
  },
  drawerOpen: {
    borderRight: '1px solid ' + theme.palette.divider,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  drawerClose: {
    borderRight: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 4,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 4,
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      '&.MuiDrawer-paper:hover': {
        borderRight: '1px solid ' + theme.palette.divider,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  },
  menuOverContent: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    borderBottom: '1px solid ' + theme.palette.divider,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contacts: {
    display: 'flex',
    flexGrow: 1,
    height: '56px',
  },
}));

export default useStyles;
