import React from 'react';
import { useVaultDropzone } from '../VaultDropzone';
import { ProgressHandler } from '../../../services/websockets';
import { useForm, FormProvider } from 'react-hook-form';
import { v4 } from 'uuid';

export type AttachmentsRendererType = (props: {
  removeFile: (fileUuid: string) => Promise<unknown>;
  openSelection: () => void;
  files: FileType[];
  sendApplicationDocuments?: (files: string[]) => Promise<boolean>;
  canSubmitDocuments?: boolean;
  uploadingFiles: Array<File & { uuid: string }>;
}) => React.ReactNode;

type AttachmentsPropsType = {
  files: FileType[];
  addFiles: (
    file: File[],
    onProgress: ProgressHandler,
    onFinish: () => unknown,
  ) => Promise<unknown> | null;
  sendApplicationDocuments?: (files: string[]) => Promise<boolean>;
  canSubmitDocuments?: boolean;
  removeFile: (fileUuid: string) => Promise<unknown>;
  renderer: AttachmentsRendererType;
};

export default function Attachments({
  files,
  addFiles,
  removeFile,
  renderer,
  sendApplicationDocuments,
  canSubmitDocuments,
}: AttachmentsPropsType) {
  const [stateUploadingFiles, setUploadingFiles] = React.useState<Array<File & { uuid: string }>>(
    [],
  );
  const uploadingFiles = React.useRef<Array<File & { uuid: string }>>([]);
  const formMethods = useForm({
    shouldUnregister: false,
  });
  const onDrop = (files: File[]) => {
    const processingFiles = files.map((f) => {
      const uuid = v4();
      const onFinish = () => {
        formMethods.setValue(uuid, undefined);
        uploadingFiles.current = uploadingFiles.current.filter((f) => f.uuid !== uuid);
        setUploadingFiles(uploadingFiles.current);
      };
      const handlerReturnValue = addFiles(
        [f],
        (p) => {
          if (p.type !== 'buffer') {
            formMethods.setValue(uuid, p.progress);
          } else {
            formMethods.setValue(uuid + '-buffer', p.progress);
          }
        },
        onFinish,
      );
      if (handlerReturnValue?.then) {
        handlerReturnValue.then(onFinish);
      }

      return { ...f, uuid };
    });
    setUploadingFiles(processingFiles);
    uploadingFiles.current = processingFiles;
  };

  const { open, getInputProps } = useVaultDropzone({ onDrop });

  return (
    <FormProvider {...formMethods}>
      <input {...getInputProps()} />
      {renderer({
        sendApplicationDocuments,
        openSelection: open,
        uploadingFiles: stateUploadingFiles,
        removeFile,
        files,
        canSubmitDocuments,
      })}
    </FormProvider>
  );
}
