import * as React from 'react';

import { Button } from '@material-ui/core';
import { styled } from '@material-ui/core';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

type CustomizedDialogsType = {
  open: boolean;
  handleClose: () => void;
  handleOk: () => void;
  textClose: string;
  textOk: string;
  textTitle: string;
  textBody: string;
};
type BootstrapDialogTitleType = {
  onClose: () => void;
  children: React.ReactNode;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = ({ onClose, children }: BootstrapDialogTitleType) => {
  return (
    <DialogTitle>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'gray',
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialog({
  open,
  handleClose,
  handleOk,
  textClose,
  textOk,
  textTitle,
  textBody,
}: CustomizedDialogsType) {
  const classes = useStyles();

  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <BootstrapDialogTitle onClose={handleClose}>{textTitle}</BootstrapDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Typography gutterBottom>{textBody}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogBtn}>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            handleClose();
          }}>
          {textClose}
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          onClick={() => {
            handleOk();
            handleClose();
          }}>
          {textOk}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
