import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  userSite: {
    '& input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '25px',
    },
  },
  formControl: {
    textAlign: 'left',
    margin: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
      minWidth: 120,
    },
    '& label': {
      maxWidth: 'calc(100% - 40px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    width: '100%',
  },
  formWrapper: {
    padding: '12px',
  },
  controlButtonsWrap: {
    margin: '15px 0 5px 0',
  },
  controlButtons: {
    margin: '0 10px',
  },
  siteUrl: {
    position: 'relative',
    paddingTop: '24px',
  },
  siteApplicationUrl: {
    position: 'relative',
  },
  booleanInputRight: {
    marginBottom: '10px',
    '& .MuiCheckbox-root': {
      paddingLeft: '0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiFormLabel-root': {
      textAlign: 'start',
    },
    '& label': {
      color: '#000000',
      fontSize: '17px',
      fontWeight: 'normal',
    },
  },
  copyButton: {
    position: 'absolute',
    top: '49px',
    right: '-20px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '16px',
    height: '15px',
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '&.copyFr': {
      top: '93px',
    },
  },
  copyApplicationButton: {
    position: 'absolute',
    top: '25px',
    right: '-20px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '16px',
    height: '15px',
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '&.copyApplicationButtonFr': {
      top: '69px',
    },
  },
}));

export default useStyles;
