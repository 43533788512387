import { connect, ConnectedProps } from 'react-redux';
import { actions } from '../../../store/applicationDocuments';
import ApplicationDocumentList from './application-document-list.component';

const mapStateToProps = (state: AppStoreType) => ({
  vendorEsignatureEnabled: (state.user as UserBasedStoreType)?.vendor?.esignatureEnabled,
  brokerApplication: state.application.brokerApplication,
  documents: state.applicationDocuments.documents,
  activeDocument: state.applicationDocuments.activeDocument,
  isLoading: state.applicationDocuments.isLoading,
});

const mapDispatchToProps = {
  getApplicationDocument: actions.getApplicationDocument,
  getApplicationDocuments: actions.getApplicationDocuments,
  generateApplicationDocument: actions.generateApplicationDocument,
  signApplicationDocument: actions.signApplicationDocument,
  sendApplicationDocument: actions.sendApplicationDocument,
  deleteApplicationDocument: actions.deleteApplicationDocument,
  cancelSignApplicationDocument: actions.cancelSignApplicationDocument,
  changeActiveDocumentId: actions.changeActiveDocumentId,
  cleanDocumentState: actions.cleanDocumentState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ApplicationDocumentListConnectedProps = ConnectedProps<typeof connector>;

export default connector(ApplicationDocumentList);
