import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogSteps: {
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderBottom: '1px solid #E0E0E0',
    borderTop: '1px solid #E0E0E0',
    textAlign: 'center',
    backgroundColor: '#ffffff',
  },
  dialogStep: {
    position: 'relative',
  },
  dialogStepNumber: {
    display: 'block',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
    verticalAlign: 'middle',
    background: '#9E9E9E',
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    fontSize: '14px',
    textAlign: 'center',
    borderRadius: '50%',
    color: '#ffffff',
    '& > svg': {
      marginTop: '2px',
    },
  },
  separator: {
    position: 'absolute',
    top: '15px',
    left: 'calc(50% + 30px)',
    width: 'calc(100% - 60px)',
    height: '1px',
    background: '#DEE2EA',
  },
  activeDialogStepNumber: {
    background: '#4153AF',
  },
  dialogStepTitle: {
    display: 'block',
    margin: '0 auto',
    verticalAlign: 'middle',
    color: 'rgba(158, 158, 158, 1)',
  },
  activeDialogStepTitle: {
    color: '#000000',
  },
}));

export default useStyles;
