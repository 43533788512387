import React from 'react';

const useSafeSetState = () => {
  const isUnmounted = React.useRef(false);
  React.useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );

  // eslint-disable-next-line
  return (fn: (...args: any[]) => unknown, ...args: any) => {
    if (!isUnmounted.current) {
      fn(...args);
    }
  };
};

export default useSafeSetState;
