import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { generatePath, Link } from 'react-router-dom';
import QuoteTable from '../QuoteTable';
import { useStyles } from './style';
import { INSTANT_QUOTE_PAGE } from '../../../constants/navigations';
import { useIntl } from 'react-intl';
import CurrencyFormat from '../currency-format';
import QuoteNotAvailableIcon from '../../icons/quote-not-available';

type ApplicationQuoteInfoProps = {
  quoteInfo?: QuoteType;
  selectedGeneratedQuote?: GeneratedQuoteType;
  selectedPaymentOption?: QuotePaymentOption;
};

export default function ApplicationQuoteInfo({
  quoteInfo,
  selectedGeneratedQuote,
  selectedPaymentOption,
}: ApplicationQuoteInfoProps) {
  const classes = useStyles();
  const intl = useIntl();

  if (!quoteInfo || !selectedPaymentOption || !selectedGeneratedQuote) {
    return (
      <React.Fragment>
        <Paper className={classes.documentRoot} variant="outlined">
          <div className={classes.notAvailableContainer}>
            <QuoteNotAvailableIcon viewBox="0 0 80 80" className={classes.notAvailableIcon} />
            <p data-cy="quote-na-texting">
              {intl.formatMessage({ id: 'application.application_has_no_linked_quote' })}
            </p>
          </div>
        </Paper>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Paper className={classes.documentRoot} variant="outlined">
        <Grid container spacing={0} alignItems="flex-start">
          <Grid item xs={12} sm={12}>
            <h3 data-cy="associated_quote">
              {intl.formatMessage({ id: 'application.associated_quote' })} #{' '}
              <Link to={generatePath(INSTANT_QUOTE_PAGE.path, { id: quoteInfo.uuid })}>
                {quoteInfo.idLabel}
              </Link>
            </h3>
            {intl.formatMessage({ id: 'application.equipment_cost' })}:&nbsp;
            <CurrencyFormat
              data-cy="equipment_cost"
              value={quoteInfo.equipmentCost}
              currencyType={quoteInfo.equipmentCostCurrencyType}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {intl.formatMessage({ id: 'common.amount' })}:&nbsp;
            <CurrencyFormat
              data-cy="payment-option-amount"
              value={selectedPaymentOption.amount}
              currencyType={quoteInfo.equipmentCostCurrencyType}
            />
          </Grid>
          <Grid data-cy="term" item xs={12} sm={6}>
            {intl.formatMessage({ id: 'application.term' })}: {selectedPaymentOption.term}
          </Grid>
          <Grid data-cy="purchase_option" item xs={12} sm={6}>
            {intl.formatMessage({ id: 'application.purchase_option' })}:{' '}
            {selectedPaymentOption.purchaseOption}
          </Grid>
          <Grid item xs={12} sm={6}>
            {intl.formatMessage({ id: 'application.purchase_option_amount' })}:&nbsp;
            <CurrencyFormat
              data-cy="purchase_option_amount"
              value={selectedPaymentOption.purchaseOptionAmount}
              currencyType={quoteInfo.equipmentCostCurrencyType}
            />
          </Grid>
        </Grid>
        <QuoteTable
          data-cy="quote-table"
          terms={JSON.parse(selectedGeneratedQuote.terms)}
          purchaseOptions={JSON.parse(selectedGeneratedQuote.purchaseOptions)}
          skipPayment={selectedGeneratedQuote.skipAmount}
          skipMonth={selectedGeneratedQuote.skipMonth}
          quotePaymentOptions={selectedGeneratedQuote.quotePaymentOptions}
          selectedPaymentOptions={[quoteInfo.selectedPaymentOptionUuid ?? '']}
          currency={quoteInfo.equipmentCostCurrencyType ?? 'CAD'}
        />
      </Paper>
    </React.Fragment>
  );
}
