import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function FileNotAvailableIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M49.2025 38.6497H26.8506C26.3419 38.6481 25.8508 38.4617 25.4681 38.1248L16.2615 29.9625C15.9454 29.6819 15.5382 29.5271 15.1165 29.5274H7.17108V1.9805C7.17167 1.45542 7.37944 0.952015 7.7488 0.580729C8.11816 0.209442 8.61895 0.000593445 9.1413 0H47.2323C47.7546 0.000593127 48.2554 0.209442 48.6248 0.580729C48.9941 0.952015 49.2019 1.45542 49.2025 1.9805L49.2025 38.6497Z"
          fill="#E6E6E6"
        />
        <path
          d="M71.8615 38.6496H31.0015C30.5947 38.6503 30.1967 38.5309 29.857 38.306L29.8301 38.2883V10.2625C29.8307 9.73746 30.0384 9.23406 30.4078 8.86277C30.7772 8.49149 31.2779 8.28264 31.8003 8.28204H69.8913C70.4136 8.28264 70.9144 8.49149 71.2838 8.86277C71.6531 9.23406 71.8609 9.73746 71.8615 10.2625L71.8615 38.6496Z"
          fill="#4153AF"
        />
        <path
          d="M44.3195 17H36.558C36.4155 17 36.2788 17.0569 36.1781 17.1582C36.0773 17.2595 36.0207 17.3969 36.0207 17.5401C36.0207 17.6834 36.0773 17.8208 36.1781 17.9221C36.2788 18.0234 36.4155 18.0803 36.558 18.0803H44.3195C44.462 18.0803 44.5987 18.0234 44.6995 17.9221C44.8002 17.8208 44.8568 17.6834 44.8568 17.5401C44.8568 17.3969 44.8002 17.2595 44.6995 17.1582C44.5987 17.0569 44.462 17 44.3195 17Z"
          fill="white"
        />
        <path
          d="M55.5868 20.4208H36.4554C36.2153 20.4208 36.0207 20.6632 36.0207 20.9609C36.0207 21.2586 36.2153 21.501 36.4554 21.501H55.5868C55.8268 21.501 56.0214 21.2586 56.0214 20.9609C56.0214 20.6632 55.8268 20.4208 55.5868 20.4208Z"
          fill="white"
        />
        <path
          d="M77.9701 38.3496H27.0224C26.6187 38.3501 26.2268 38.213 25.9107 37.9607C25.8856 37.9415 25.8617 37.9211 25.8378 37.8995L22.2831 34.7487L16.6303 29.7374C16.2598 29.4085 15.7825 29.2271 15.2882 29.2273H2.02993C1.49179 29.2281 0.97591 29.4433 0.59539 29.8258C0.21487 30.2083 0.000761585 30.7269 0 31.2678V77.9595C0.000761585 78.5004 0.21487 79.019 0.59539 79.4015C0.97591 79.784 1.49179 79.9992 2.02993 80H77.9701C78.5082 79.9992 79.0241 79.784 79.4046 79.4015C79.7851 79.019 79.9992 78.5004 80 77.9595V40.3901C79.9992 39.8491 79.7851 39.3306 79.4046 38.9481C79.0241 38.5656 78.5082 38.3503 77.9701 38.3496ZM79.7612 77.9595C79.7606 78.4368 79.5717 78.8944 79.2359 79.2319C78.9002 79.5695 78.4449 79.7593 77.9701 79.7599H2.02993C1.55507 79.7593 1.09983 79.5695 0.764057 79.2319C0.428284 78.8944 0.239393 78.4368 0.238815 77.9595V31.2678C0.239393 30.7905 0.428284 30.3329 0.764057 29.9953C1.09983 29.6578 1.55507 29.4679 2.02993 29.4674H15.2882C15.7244 29.467 16.1458 29.6271 16.4727 29.9175L21.9225 34.7487L25.679 38.0795C26.0509 38.407 26.528 38.5882 27.0224 38.5896H77.9701C78.4449 38.5902 78.9002 38.7801 79.2359 39.1176C79.5717 39.4551 79.7606 39.9128 79.7612 40.3901V77.9595Z"
          fill="#3F3D56"
        />
        <path
          d="M15.4699 71H7.70842C7.56591 71 7.42923 71.0569 7.32846 71.1582C7.22769 71.2595 7.17108 71.3969 7.17108 71.5401C7.17108 71.6834 7.22769 71.8208 7.32846 71.9221C7.42923 72.0234 7.56591 72.0803 7.70842 72.0803H15.4699C15.6124 72.0803 15.7491 72.0234 15.8499 71.9221C15.9506 71.8208 16.0072 71.6834 16.0072 71.5401C16.0072 71.3969 15.9506 71.2595 15.8499 71.1582C15.7491 71.0569 15.6124 71 15.4699 71Z"
          fill="#4153AF"
        />
        <path
          d="M26.7372 68H7.60574C7.3657 68 7.17108 68.2425 7.17108 68.5401C7.17108 68.8378 7.3657 69.0803 7.60574 69.0803H26.7372C26.9772 69.0803 27.1718 68.8378 27.1718 68.5401C27.1718 68.2425 26.9772 68 26.7372 68Z"
          fill="#4153AF"
        />
      </g>
    </SvgIcon>
  );
}
