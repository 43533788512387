import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import Editor from '@monaco-editor/react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

type CodeEditorPropsType = {
  label: string;
  height?: string;
  language: 'html' | 'json' | 'handlebars';
  required?: boolean;
  value?: string | null;
  onChange: (name: string | undefined) => void;
};

export default function CodeEditor({
  label,
  height,
  language,
  required,
  value,
  onChange,
}: CodeEditorPropsType) {
  const classes = useStyles();
  const intl = useIntl();

  const defaultMessage = intl.formatMessage({ id: 'email_templates.default_body_text' });
  const [isValid, setIsValid] = useState(true);

  const changeValue = (value: string | undefined) => {
    onChange(value);
    setIsValid(!!(value && value.length));
  };

  useEffect(() => {
    onChange(defaultMessage);
  }, []);

  return (
    <div className={isValid ? classes.editorWrapper : clsx(classes.editorWrapper, classes.invalid)}>
      <label>
        {label}
        {required && <span> *</span>}
      </label>
      <Editor
        className={classes.bodyInput}
        height={height ?? '500px'}
        defaultLanguage={language}
        defaultValue={defaultMessage}
        value={value ?? undefined}
        onChange={changeValue}
      />
    </div>
  );
}
