import { BaseDataGrid } from '../../components/common/VpDataGrid';
import { AgGridColumn } from '@ag-grid-community/react';
import React from 'react';
import { getBrokersUsersList } from '../../apis/broker-rep';

import { AllCommunityModules, GridApi, IDatasource } from '@ag-grid-community/all-modules';
import useStyles from '../../components/common/VpDataGrid/styles';
import { useQuery } from '../../utils/common';
import { useHistory } from 'react-router-dom';
import { BROKER_VENDOR_INFO_PAGE } from '../../constants/navigations';

const UserList = () => {
  const query = useQuery();
  const history = useHistory();
  const [gridApi, setGridApi] = React.useState<GridApi | null>(null);
  const classes = useStyles();

  React.useEffect(() => {
    const companyName = query.get('companyName');
    if (gridApi && companyName) {
      gridApi.setFilterModel({
        companyName: {
          filterType: 'string',
          type: 'contains',
          filter: companyName,
        },
      });
      gridApi?.sizeColumnsToFit();
      history.replace(location.pathname);
    } else if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  const dataSource: IDatasource = {
    getRows: async (params) => {
      const queryArray: Array<[string, string | number]> = [];
      queryArray.push(['limit', params.endRow - params.startRow]);
      queryArray.push(['offset', params.startRow]);
      queryArray.push(['sortingField', params.sortModel[0]?.colId]);
      queryArray.push(['sortingDirection', params.sortModel[0]?.sort]);

      Reflect.ownKeys(params.filterModel).forEach((field) => {
        const filter = encodeURIComponent(params.filterModel[field]?.filter);
        return queryArray.push([field as string, filter]);
      });

      const queryString = queryArray
        .filter((a) => a[1])
        .map((a) => a.join('='))
        .join('&');

      getBrokersUsersList(queryString)
        .then((res) => {
          const users = res.data;
          params.successCallback(users.rows, users.count);
          setTimeout(() => {
            gridApi?.sizeColumnsToFit();
          });
        })
        .catch(() => {
          params.failCallback();
        });
    },
  };

  return (
    <div className={`${classes.alpineThemeContainer} ag-theme-alpine`}>
      <BaseDataGrid
        modules={AllCommunityModules}
        rowModelType="infinite"
        datasource={dataSource}
        onInit={setGridApi}
        onViewportChanged={() => gridApi?.sizeColumnsToFit()}
        pagination
        paginationPageSize={10}
        cacheBlockSize={10}
        onGridSizeChanged={(params) => params.api.sizeColumnsToFit()}
      >
        <AgGridColumn
          floatingFilter
          cellRendererParams={{
            path: BROKER_VENDOR_INFO_PAGE.path,
            pathParams: {
              vendorUuid: 'companyUuid',
            },
          }}
          flex={2}
          minWidth={200}
          type="vendorCompanyName"
        />
        <AgGridColumn minWidth={100} suppressSizeToFit type="userDetailsLink" />
        <AgGridColumn minWidth={150} floatingFilter sortable type="firstName" />
        <AgGridColumn minWidth={150} floatingFilter sortable type="lastName" />
        <AgGridColumn minWidth={150} floatingFilter sortable type="vendorRepRole" />
        <AgGridColumn minWidth={200} floatingFilter type="formattedPhone" />
        <AgGridColumn minWidth={200} floatingFilter sortable type="email" />
        <AgGridColumn
          type="active"
          flex={1}
          resizable
          cellClass={'ag-custom-text-align-center'}
          headerClass={'ag-custom-text-align-center'}
        />
        <AgGridColumn
          type="userLoginAsButton"
          flex={1}
          resizable
          cellClass={'ag-custom-text-align-center'}
          headerClass={'ag-custom-text-align-center'}
        />
      </BaseDataGrid>
    </div>
  );
};

export default UserList;
