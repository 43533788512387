import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';

const BACKEND = getBackendUrl() + '/gridView';
const api = getApi(BACKEND);

export const updateGridView = async (gridView: GridViewApiType) => {
  return await api.post<GridViewApiType>('/', { gridView });
};

export const getGridViewList = async (vendorUuid: string) => {
  return await api.get<GridViewApiType[]>(`/${vendorUuid}`);
};
