import * as Navigations from '../../constants/navigations';
import DeclineQuote from './decline.component';
import UnsubscribeQuote from './unsubscribe.component';
const declineRoute = Navigations.EMAIL_ACTION_DECLINE;
const unsubscribeRoute = Navigations.EMAIL_ACTION_UNSUBSCRIBE;
export default [
  { path: declineRoute.path, name: declineRoute.title, component: DeclineQuote, exact: true },
  {
    path: unsubscribeRoute.path,
    name: unsubscribeRoute.title,
    component: UnsubscribeQuote,
    exact: true,
  },
];
