import { useFormContext, useWatch } from 'react-hook-form';
import React, { useContext } from 'react';
import NewApplicationContext from './new-application.context';
import VpButton from '../../components/common/VpButton';
import useStyles from './styles';
import { useIntl } from 'react-intl';

// need to have it as separate component to have useWatch
// scoped to this small component and not rerender main component
const FormButtons = ({ application }: { application: VpCompleteApplicationType | null }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { save, submit, hasChanges: checkChanges } = useContext(NewApplicationContext);
  const { control } = useFormContext();
  const [hasChanges, setHasChanges] = React.useState(false);
  const valuesChanged = useWatch({ control });

  React.useEffect(() => {
    setHasChanges(checkChanges());
  }, [valuesChanged, application, checkChanges]);

  return (
    <div className={classes.formButtons}>
      <VpButton
        onClick={save}
        color="primary"
        disabled={!hasChanges}
        className={classes.marginButton}
        variant="outlined">
        {intl.formatMessage({ id: 'button.save' })}
      </VpButton>
      <VpButton onClick={submit} color="primary" variant="contained">
        {intl.formatMessage({ id: 'button.submit_application' })}
      </VpButton>
    </div>
  );
};

export default FormButtons;
