import InstantQuote from './instant-quote.container';
import InstantQuotePublic from './instant-quote.public.container';
import * as Navigations from '../../constants/navigations';
const { path } = Navigations.INSTANT_QUOTE_PAGE;
const { path: newQuotePath } = Navigations.INSTANT_NEW_QUOTE_PAGE;

export const authRoute = [{ path, name: 'InstantQuote', component: InstantQuote, exact: true }];

export const publicRoute = [
  { path: newQuotePath, name: 'InstantQuote', component: InstantQuotePublic, exact: true },
];
