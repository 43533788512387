import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApplicationDocumentListConnectedProps } from './application-document-list.containter';
import {
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useStyles, StyledTableCell } from './styles';
import Loading from '../Loading';
import clsx from 'clsx';
import ApplicationStatus from '../../../pages/application/application-status';
import DocumentNotAvailableIcon from '../../icons/document-not-available';
import AddIcon from '@material-ui/icons/Add';
import { DOCUMENT_OVERRIDE_TYPES, DOCUMENT_SIGNATURE_STATUSES } from '../../../constants/document';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import ApplicationDocumentListSteps from '../ApplicationDocumentListSteps';
import ApplicationDocumentTemplateForm from '../ApplicationDocumentTemplateForm';
import ApplicationDocumentOverrideForm from '../ApplicationDocumentOverrideForm';
import { useForm, FormProvider } from 'react-hook-form';
import { KeyboardArrowRight } from '@material-ui/icons';
import { getFormattedDate } from '../../../services/formatter-service';
import { DATETIME_FORMAT_INLINE } from '../../../constants/date';
import ApplicationDocumentSigners from '../ApplicationDocumentSigners';
import ApplicationDocumentContent from '../ApplicationDocumentContent';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IoResponse, subscribe } from '../../../services/websockets';
import { AVAILABLE_STATUSES_FOR_GENERATE_DOCUMENT } from '../../../constants/application';
import { Alert } from '@material-ui/lab';
import { getBackendUrl } from '../../../utils/getBackendUrl';

type ApplicationDocumentListPropsType = {
  applicationStatus?: ApplicationStatus | null;
  applicationUuid: string;
  loading: boolean;
};

function ApplicationDocumentList(
  props: ApplicationDocumentListConnectedProps & ApplicationDocumentListPropsType,
) {
  const {
    applicationStatus,
    documents,
    activeDocument,
    brokerApplication,
    applicationUuid,
    isLoading,
    vendorEsignatureEnabled,
  } = props;

  const intl = useIntl();
  const classes = useStyles();
  const overrideFormRef = useRef<HTMLFormElement | null>(null);

  const [activeDocumentFileUrl, setActiveDocumentFileUrl] = React.useState<string | null>(null);
  const [activeDocumentSigned, setActiveDocumentSigned] = React.useState<boolean>(false);
  const [activeDocumentFileName, setActiveDocumentFileName] = React.useState<string | null>(null);
  const [signerAttachmentsByDocument, setSignerAttachmentsByDocument] = useState<
    { [uuid: string]: ApplicationDocumentSignerAttachment[] } | false
  >(false);
  const [activeDocumentFields, setActiveDocumentFields] = useState<
    Array<ApplicationDocumentOverridableFields>
  >([]);
  const [isCustomerSwitcherDisabled, setIsCustomerSwitcherDisabled] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openOverrideModal, setOpenOverrideModal] = useState(false);
  const [overrideModalType, setOverrideModalType] = useState('');
  const [overrideFormValues, setOverrideFormValues] = useState<ApplicationDocumentValueTypes[]>([]);
  const [resetAvailability, setResetAvailability] = useState<boolean>(false);
  const [documentStep, setDocumentStep] = useState<number>(1);
  const [signersFormLoader, setSignersFormLoader] = useState(false);
  const [formTemplates, setFormTemplates] = useState<ApplicationDocumentTemplate[]>([]);
  const [invalidTemplates, setInvalidTemplates] = useState<boolean>(false);
  const [generateBtnMessage, setGenerateBtnMessage] = useState('');
  const [generatedData, setGeneratedData] = useState<ApplicationDocumentData>({
    templates: [],
    overrides: [],
    hasCustomerInvoiceField: false,
    uuid: null,
  });

  const formMethods = useForm<ApplicationDocumentValueTypes>();
  const { register, handleSubmit } = formMethods;

  React.useEffect(() => {
    if (!isLoading && applicationUuid) {
      props.getApplicationDocuments(applicationUuid);
    }

    return subscribe<IoResponse>({
      entity: 'applicationDocument',
      filter: applicationUuid,
      handler: () => {
        props.getApplicationDocuments(applicationUuid);
      },
    });
  }, []);

  React.useEffect(() => {
    const documentAttachments: { [key: string]: ApplicationDocumentSignerAttachment[] } = {};

    for (const document of documents) {
      documentAttachments[document.id] = [];

      if (document.signers) {
        for (const signer of document.signers) {
          for (const attachment of signer.attachments) {
            documentAttachments[document.id].push({
              signerUuid: signer.uuid,
              attachmentType: attachment.type,
              checked: attachment.required,
              status: attachment.status,
            });
          }
        }
      }
    }

    setSignerAttachmentsByDocument(documentAttachments);
  }, [documents]);

  React.useEffect(() => {
    try {
      if (activeDocument) {
        if (activeDocument.signers) {
          const attachments = [];
          for (const signer of activeDocument.signers) {
            for (const attachment of signer.attachments) {
              attachments.push({
                signerUuid: signer.uuid,
                attachmentType: attachment.type,
                checked: attachment.required,
                status: attachment.status,
              });
            }
          }
        }

        if (activeDocument.providerId) {
          setActiveDocumentSigned(true);
        } else {
          setActiveDocumentSigned(false);
        }

        setActiveDocumentFileUrl(
          getBackendUrl() + `/application/${applicationUuid}/documents/${activeDocument.id}/file`,
        );
        setActiveDocumentFields(activeDocument.overridableFields ?? []);
        setActiveDocumentFileName(activeDocument.fileName);
      }
    } catch (e) {
      setActiveDocumentFileUrl(null);
      setActiveDocumentFileName(null);
      setActiveDocumentSigned(false);
      setActiveDocumentFields([]);
      setFormTemplates([]);
    }
  }, [activeDocument]);

  React.useEffect(() => {
    if (generatedData.overrides.length > 0) {
      generate();
    }
  }, [generatedData]);

  React.useEffect(() => {
    handleButton();
  }, [overrideModalType]);

  const changeSignerAttachments = async (
    documentId: string,
    signerAttachments: ApplicationDocumentSignerAttachment[],
  ) => {
    if (signerAttachmentsByDocument && signerAttachmentsByDocument[documentId]) {
      const newAttachments = {
        ...signerAttachmentsByDocument,
        [documentId]: signerAttachments,
      };

      setSignerAttachmentsByDocument(newAttachments);
    }
  };

  const generate = async () => {
    let previousActiveDocumentId = null;

    if (overrideModalType === DOCUMENT_OVERRIDE_TYPES.SIGNATURE && activeDocument) {
      previousActiveDocumentId = activeDocument.id;
    }

    if (overrideModalType === DOCUMENT_OVERRIDE_TYPES.GENERATE) {
      setIsCustomerSwitcherDisabled(false);
    }

    setActiveDocumentFileUrl(null);
    const newDocument = await props.generateApplicationDocument(applicationUuid, generatedData);

    if (newDocument && newDocument.documentUuid) {
      if (previousActiveDocumentId) {
        props.cleanDocumentState(previousActiveDocumentId);
      }

      await props.getApplicationDocument(applicationUuid, newDocument.documentUuid);
    }
  };

  const changeActiveDocumentId = async (uuid: string | null) => {
    await props.changeActiveDocumentId(uuid);
  };

  const openOverride = (type: string = DOCUMENT_OVERRIDE_TYPES.INTERMEDIATE) => {
    if (type === DOCUMENT_OVERRIDE_TYPES.SIGNATURE) {
      setDocumentStep(2);
    }

    if (type === DOCUMENT_OVERRIDE_TYPES.GENERATE) {
      setIsCustomerSwitcherDisabled(false);
    } else if (type === DOCUMENT_OVERRIDE_TYPES.SIGNATURE) {
      setIsCustomerSwitcherDisabled(
        activeDocument ? !activeDocument.hasCustomerInvoiceField : false,
      );
    }

    setOverrideModalType(type);
    setOpenOverrideModal(true);
  };

  const closeOverrideModal = () => {
    setDocumentStep(1);
    setOpenOverrideModal(false);
    setFormTemplates([]);
  };

  const switchToStep = (step: number) => {
    if (documentStep === 1 && step === 2 && formTemplates.length === 0) {
      setInvalidTemplates(true);
      return false;
    }

    setDocumentStep(step);
  };

  const completeSignStep = async () => {
    await signApplicationDocument();
    closeOverrideModal();
  };

  const resetDocumentValues = () => {
    setResetAvailability(false);
    setActiveDocumentFields([]);
  };

  const handleButton = () => {
    if (overrideModalType === DOCUMENT_OVERRIDE_TYPES.SIGNATURE) {
      setGenerateBtnMessage(intl.formatMessage({ id: 'application.generate_and_preview' }));
    } else if (overrideModalType === DOCUMENT_OVERRIDE_TYPES.GENERATE) {
      setGenerateBtnMessage(intl.formatMessage({ id: 'application.generate_document' }));
    } else {
      setGenerateBtnMessage(intl.formatMessage({ id: 'application.regenerate_document' }));
    }
  };

  const handleSubmitDocumentValue: Parameters<typeof handleSubmit>[0] = async () => {
    let templatesForGenerate = formTemplates;

    if (activeDocument && overrideModalType === DOCUMENT_OVERRIDE_TYPES.SIGNATURE) {
      templatesForGenerate = activeDocument.templates;
    }

    setGeneratedData({
      templates: templatesForGenerate,
      overrides: overrideFormValues,
      hasCustomerInvoiceField: !isCustomerSwitcherDisabled,
      uuid:
        overrideModalType === DOCUMENT_OVERRIDE_TYPES.SIGNATURE && activeDocument
          ? activeDocument.id
          : null,
    });

    if (overrideModalType === DOCUMENT_OVERRIDE_TYPES.SIGNATURE) {
      setDocumentStep(3);
    } else {
      closeOverrideModal();
    }
  };

  const overrideFormHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    if (overrideFormRef && overrideFormRef.current) {
      overrideFormRef.current.dispatchEvent(new Event('submit'));
    }
  };

  const signApplicationDocument = async () => {
    if (activeDocument) {
      setSignersFormLoader(true);
      const signerAttachments =
        signerAttachmentsByDocument && signerAttachmentsByDocument[activeDocument.id]
          ? signerAttachmentsByDocument[activeDocument.id]
          : [];

      const signedDocument = await props.signApplicationDocument(
        applicationUuid,
        activeDocument.id,
        signerAttachments,
      );

      setSignersFormLoader(false);

      if (signedDocument && signedDocument.documentUuid) {
        await props.getApplicationDocuments(applicationUuid);
      }
    }
  };

  const sendEmailParams = async (data: ApplicationDocumentEmailFormData) => {
    if (activeDocument) {
      await props.sendApplicationDocument(applicationUuid, activeDocument.id, data);
      await props.getApplicationDocument(applicationUuid, activeDocument.id);
    }
  };

  const cancelSignDocument = async () => {
    if (activeDocument) {
      await props.cancelSignApplicationDocument(applicationUuid, activeDocument.id);
      await props.getApplicationDocument(applicationUuid, activeDocument.id);
    }
  };

  const deleteDocument = async () => {
    if (activeDocument) {
      await props.deleteApplicationDocument(applicationUuid, activeDocument.id);
    }

    setOpenDeleteModal(false);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      {props.loading && (
        <Paper className={clsx(classes.paper, classes.smallMarginTop)}>
          <Loading isOpen={props.loading} />
        </Paper>
      )}
      {!props.loading && (
        <Paper variant="outlined" className={classes.documentRoot}>
          <React.Fragment>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography data-cy="application-docs-header" variant="h6" gutterBottom>
                  {intl.formatMessage({ id: 'application.generated_documents' })}
                </Typography>
              </Grid>
              {applicationStatus &&
                AVAILABLE_STATUSES_FOR_GENERATE_DOCUMENT.indexOf(applicationStatus.status) !==
                  -1 && (
                  <Grid item xs={12} sm={6}>
                    <Button
                      data-cy="generate-contract-btn"
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={classes.generateButton}
                      startIcon={<AddIcon />}
                      disabled={isLoading ? true : false}
                      onClick={() => openOverride(DOCUMENT_OVERRIDE_TYPES.GENERATE)}
                    >
                      {intl.formatMessage({ id: 'application.generate_document' })}
                    </Button>
                  </Grid>
                )}
            </Grid>
            {documents.length > 0 &&
              applicationStatus &&
              AVAILABLE_STATUSES_FOR_GENERATE_DOCUMENT.indexOf(applicationStatus.status) !== -1 && (
                <>
                  <ApplicationDocumentContent
                    documentFileName={activeDocumentFileName}
                    docSignAvailability={
                      brokerApplication.data.one_doc_esign_available && vendorEsignatureEnabled
                    }
                    document={activeDocument}
                    documentSigned={activeDocumentSigned}
                    previewUrl={`${activeDocumentFileUrl}?preview=1`}
                    downloadUrl={activeDocumentFileUrl}
                    changeSignerAttachments={changeSignerAttachments}
                    openOverride={openOverride}
                    cancelSignDocument={cancelSignDocument}
                    signerAttachments={
                      signerAttachmentsByDocument &&
                      activeDocument &&
                      signerAttachmentsByDocument[activeDocument.id]
                        ? signerAttachmentsByDocument[activeDocument.id]
                        : []
                    }
                    sendEmailParams={sendEmailParams}
                    setOpenDeleteModal={setOpenDeleteModal}
                  />
                  <TableContainer component={Paper} className={classes.mainTable}>
                    <Table aria-label="collapsible table" size="small" data-cy="documents-table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell component="th" scope="row" align="left" />
                          <StyledTableCell component="th" scope="row" align="left">
                            <FormattedMessage id="application.documents" />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="left">
                            <FormattedMessage id="common.status" />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="left">
                            <FormattedMessage id="application.created_date" />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {documents.map((document: ApplicationDocumentType, index: number) => (
                          <React.Fragment key={index}>
                            <TableRow
                              className={
                                activeDocument && activeDocument.id === document.id
                                  ? clsx(classes.root, classes.activeRow)
                                  : classes.root
                              }
                            >
                              <TableCell className={classes.expandCell}>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() =>
                                    changeActiveDocumentId(
                                      activeDocument && activeDocument.id === document.id
                                        ? null
                                        : document.id,
                                    )
                                  }
                                >
                                  {activeDocument && activeDocument.id === document.id ? (
                                    <KeyboardArrowDownIcon />
                                  ) : (
                                    <KeyboardArrowRight />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {document.templates
                                  .map((template: ApplicationDocumentTemplate) => {
                                    return template.title;
                                  })
                                  .join(', ')}
                              </TableCell>
                              <TableCell align="left">
                                <Chip
                                  color="default"
                                  size="small"
                                  className={
                                    document.status === DOCUMENT_SIGNATURE_STATUSES.PENDING
                                      ? document.providerId || document.emailSent
                                        ? classes.labelPending
                                        : classes.labelInfo
                                      : document.status === DOCUMENT_SIGNATURE_STATUSES.SIGNED
                                      ? classes.labelSuccess
                                      : classes.labelError
                                  }
                                  variant="default"
                                  label={
                                    document.status === DOCUMENT_SIGNATURE_STATUSES.PENDING
                                      ? document.providerId
                                        ? intl.formatMessage({
                                            id: 'application.signer_status_pending',
                                          })
                                        : document.emailSent
                                        ? intl.formatMessage({
                                            id: 'application.emailed',
                                          })
                                        : intl.formatMessage({
                                            id: 'application.generated',
                                          })
                                      : document.status === DOCUMENT_SIGNATURE_STATUSES.SIGNED
                                      ? intl.formatMessage({
                                          id: 'application.signer_status_completed',
                                        })
                                      : intl.formatMessage({
                                          id: 'application.signer_status_declined',
                                        })
                                  }
                                />
                              </TableCell>
                              <TableCell align="left" className={classes.noWrap}>
                                {getFormattedDate(document.createdAt, null, DATETIME_FORMAT_INLINE)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={4} className={classes.noPadding}>
                                <Collapse
                                  in={!!(activeDocument && activeDocument.id === document.id)}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <FormProvider {...formMethods}>
                                    {document && document.status === 'DECLINED' && (
                                      <Alert severity="error" className={classes.errorMessage}>
                                        {intl.formatMessage({
                                          id: 'application.e_sign_error_message',
                                        })}
                                      </Alert>
                                    )}
                                    <ApplicationDocumentSigners
                                      docSignAvailability
                                      document={document}
                                      documentSigned={!!document.providerId}
                                      changeSignerAttachments={changeSignerAttachments}
                                      allAttachments={signerAttachmentsByDocument}
                                      fullWidth={false}
                                      isModalContent={false}
                                      readonly
                                    />
                                  </FormProvider>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            {!documents.length && (
              <div className={classes.notAvailableContainer}>
                <DocumentNotAvailableIcon
                  viewBox="0 0 80 80"
                  className={classes.notAvailableIcon}
                />
                <p data-cy="doc-generation-na-texting">
                  {intl.formatMessage({ id: 'application.document_generation_not_available' })}
                </p>
              </div>
            )}

            <Dialog
              disableBackdropClick
              open={openOverrideModal}
              onClose={closeOverrideModal}
              className={classes.overrideDialog}
            >
              <DialogTitle className={classes.dialogTitle} id="scroll-dialog-title">
                {overrideModalType === DOCUMENT_OVERRIDE_TYPES.SIGNATURE &&
                  intl.formatMessage({ id: 'application.start_esign' })}
                {overrideModalType !== DOCUMENT_OVERRIDE_TYPES.SIGNATURE &&
                  intl.formatMessage({ id: 'application.generate_document' })}
                <IconButton onClick={closeOverrideModal} className={classes.closeModalBtn}>
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <ApplicationDocumentListSteps step={documentStep} type={overrideModalType} />
                {documentStep === 1 && (
                  <ApplicationDocumentTemplateForm
                    templates={brokerApplication.data.document_templates}
                    formTemplates={formTemplates}
                    setFormTemplates={setFormTemplates}
                    error={invalidTemplates}
                  />
                )}
                {documentStep === 2 && (
                  <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(handleSubmitDocumentValue)} ref={overrideFormRef}>
                      <ApplicationDocumentOverrideForm
                        brokerApplication={brokerApplication}
                        setFormValues={setOverrideFormValues}
                        setResetAvailability={setResetAvailability}
                        isCustomerSwitcherDisabled={isCustomerSwitcherDisabled}
                        setIsCustomerSwitcherDisabled={setIsCustomerSwitcherDisabled}
                        templates={
                          activeDocument && overrideModalType === DOCUMENT_OVERRIDE_TYPES.SIGNATURE
                            ? activeDocument.templates.map((template) => {
                                return {
                                  path: template.path,
                                  title: template.title,
                                  alias: template.alias,
                                };
                              })
                            : formTemplates
                        }
                        savedFields={activeDocumentFields}
                        type={overrideModalType}
                        register={register}
                        isReadOnly={false}
                      />
                    </form>
                  </FormProvider>
                )}
                {documentStep === 3 && (
                  <>
                    {isLoading && <Loading isOpen={isLoading} />}
                    {!isLoading && activeDocumentFileUrl && (
                      <iframe
                        src={`${activeDocumentFileUrl}?preview=1`}
                        className={classes.iframePreview}
                      />
                    )}
                  </>
                )}
                {documentStep === 4 && activeDocument && (
                  <FormProvider {...formMethods}>
                    <ApplicationDocumentSigners
                      docSignAvailability
                      document={activeDocument}
                      documentSigned={!!activeDocument.providerId}
                      changeSignerAttachments={changeSignerAttachments}
                      allAttachments={signerAttachmentsByDocument}
                      fullWidth={false}
                      readonly={signersFormLoader}
                      isModalContent
                    />
                  </FormProvider>
                )}
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Grid item xs={12}>
                  {documentStep === 1 && (
                    <>
                      <Button
                        data-cy="generate-doc-cancel"
                        variant="contained"
                        color="default"
                        className={classes.button}
                        onClick={closeOverrideModal}
                      >
                        {intl.formatMessage({ id: 'button.cancel' })}
                      </Button>
                      <Button
                        data-cy="generate-doc-next"
                        type="button"
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={() => switchToStep(2)}
                        disabled={!formTemplates.length}
                      >
                        {intl.formatMessage({ id: 'common.next' })}
                      </Button>
                    </>
                  )}
                  {documentStep === 2 && (
                    <>
                      <Button
                        data-cy="generate-doc-reset-overrides"
                        onClick={resetDocumentValues}
                        variant="contained"
                        color="secondary"
                        className={clsx(classes.button, classes.pullLeft)}
                        disabled={!resetAvailability}
                      >
                        {intl.formatMessage({ id: 'application.reset_overrides' })}
                      </Button>
                      <Button
                        data-cy="generate-doc-btn"
                        onClick={(e) => overrideFormHandler(e)}
                        type="button"
                        variant="contained"
                        className={clsx(classes.button, classes.pullRight)}
                        color="primary"
                      >
                        {generateBtnMessage}
                      </Button>
                      <Button
                        data-cy="generate-doc-cancel"
                        onClick={() => closeOverrideModal()}
                        variant="contained"
                        color="default"
                        className={clsx(classes.button, classes.pullRight, classes.cancelButton)}
                        disabled={false}
                      >
                        {intl.formatMessage({ id: 'button.cancel' })}
                      </Button>
                    </>
                  )}
                  {documentStep === 3 && (
                    <>
                      <Button
                        data-cy="generate-doc-back"
                        variant="contained"
                        color="default"
                        className={classes.button}
                        onClick={() => switchToStep(2)}
                      >
                        {intl.formatMessage({ id: 'button.back' })}
                      </Button>
                      <Button
                        data-cy="generate-doc-next"
                        type="button"
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={() => switchToStep(4)}
                      >
                        {intl.formatMessage({ id: 'common.next' })}
                      </Button>
                    </>
                  )}
                  {documentStep === 4 && (
                    <>
                      <Button
                        data-cy="generate-doc-back"
                        variant="contained"
                        color="default"
                        className={classes.button}
                        onClick={() => switchToStep(3)}
                      >
                        {intl.formatMessage({ id: 'button.back' })}
                      </Button>
                      <Button
                        data-cy="generate-doc-sign-invites"
                        type="button"
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        disabled={signersFormLoader}
                        onClick={completeSignStep}
                      >
                        {intl.formatMessage({ id: 'application.send_sign_invites' })}
                      </Button>
                    </>
                  )}
                </Grid>
              </DialogActions>
            </Dialog>

            <Dialog open={openDeleteModal}>
              <DialogTitle className={classes.dialogTitle}>
                {intl.formatMessage({ id: 'application.delete_document' })}
                <IconButton onClick={closeDeleteModal} className={classes.closeModalBtn}>
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              </DialogTitle>
              <DialogContent className={classes.dialogLargeContent}>
                {activeDocument &&
                  intl.formatMessage(
                    { id: 'application.are_you_sure_delete_document' },
                    {
                      documentName: activeDocument.templates
                        .map((template: ApplicationDocumentTemplate) => {
                          return template.title;
                        })
                        .join(', '),
                    },
                  )}
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={closeDeleteModal}
                  >
                    {intl.formatMessage({ id: 'button.cancel' })}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    className={classes.button}
                    color="primary"
                    onClick={deleteDocument}
                  >
                    {intl.formatMessage({ id: 'button.delete' })}
                  </Button>
                </Grid>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </Paper>
      )}
    </>
  );
}
export default ApplicationDocumentList;
