import React, { ComponentProps } from 'react';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import get from 'lodash/get';
import { useIntl } from 'react-intl';

type PhoneFormatterPropsType = Omit<ComponentProps<typeof Controller>, 'as'>;

export default function PhoneFormatter({
  name,
  defaultValue,
  disabled,
  required,
  ...props
}: PhoneFormatterPropsType) {
  const intl = useIntl();
  const { control, errors } = useFormContext();
  const error = get(errors, name);

  return (
    <Controller
      id={name}
      as={
        <NumberFormat
          required={required}
          error={Boolean(error)}
          customInput={TextField}
          format="###-####"
          mask="_"
          disabled={disabled}
        />
      }
      {...props}
      name={name}
      control={control}
      rules={{
        required,
        validate: (value: string) => {
          if (required && value.replace(/\D/gm, '').length < 7) {
            return intl.formatMessage({ id: 'common.required' });
          }
        },
      }}
      defaultValue={defaultValue || ''}
    />
  );
}
