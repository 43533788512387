import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useIntl } from 'react-intl';
import { useStyles } from './styles';
import clsx from 'clsx';

export default function ModalBox(props) {
  const {
    header,
    body,
    isOpen,
    handleClose,
    actions,
    dataCy,
    isQuoteTable,
    isEmailPreview,
  } = props;
  const [scroll] = React.useState('paper');
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div>
      <Dialog
        data-cy={dataCy ?? 'modal'}
        open={isOpen}
        onClose={handleClose}
        scroll={scroll}
        className={clsx({
          [classes.tableWrapper]: isQuoteTable,
          [classes.modalFullWIdth]: isEmailPreview,
        })}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle align="center" data-cy="modal-title" id="scroll-dialog-title">
          {header}
        </DialogTitle>
        <DialogContent data-cy="modal-body" dividers={scroll === 'paper'}>
          {typeof body === 'string' ? (
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              {body}
            </DialogContentText>
          ) : isEmailPreview ? (
            <div className={classes.emailPreview}>{body}</div>
          ) : (
            body
          )}
        </DialogContent>
        <DialogActions>
          {actions}
          <Button data-cy="close-button" onClick={handleClose} color="primary">
            {intl.formatMessage({ id: 'button.close' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
