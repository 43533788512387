import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import LanguageSwitcherBar from '../language-switcher-bar';
import { useStyles } from './styles';

export const SignInToolbar = () => {
  const classes = useStyles();
  const langSwitcherClass = 'langSwitcherSignIn';
  return (
    <Toolbar className={classes.toolbarWrapper}>
      <div className={classes.languageHolder}>
        <LanguageSwitcherBar className={langSwitcherClass} />
      </div>
    </Toolbar>
  );
};
