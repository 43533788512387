import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';
import { IoProgress, ProgressHandler, subscribe } from '../services/websockets';
import { v4 } from 'uuid';
import { formatBufferProgress } from '../utils/common';

const BACKEND = getBackendUrl() + '/file';
const api = getApi(BACKEND);

export const uploadFiles = async (files: FormData, onProgress?: ProgressHandler) => {
  const uuid = v4();
  const unsubscribe = onProgress
    ? subscribe<IoProgress>({
        entity: 'progress',
        filter: uuid,
        handler: onProgress,
      })
    : null;

  return await api
    .post<FileStoreType['attachments']>('/', files, {
      headers: { 'action-uuid': uuid },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        onProgress?.(formatBufferProgress(loaded, total));
      },
    })
    .then((res) => {
      unsubscribe?.();

      return res;
    });
};

export const uploadFilesPublic = async (
  files: FormData,
  routeParams: PublicRouteParams,
  onProgress?: ProgressHandler,
) => {
  const uuid = v4();
  const unsubscribe = onProgress
    ? subscribe<IoProgress>({
        entity: 'progress',
        filter: uuid,
        handler: onProgress,
      })
    : null;

  return await api
    .post<FileStoreType['attachments']>('/public', files, {
      headers: {
        'action-uuid': uuid,
        'vendor-site-url': routeParams.vendorSiteUrl,
        'user-site-url': routeParams.userSiteUrl ?? '',
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        onProgress?.(formatBufferProgress(loaded, total));
      },
    })
    .then((res) => {
      unsubscribe?.();

      return res;
    });
};

export const deleteFile = async (uuid: string) => {
  return await api.delete<FileType['uuid']>('/' + uuid);
};
