import React from 'react';
import { Grid, Grow } from '@material-ui/core';
import { AgeWarnings, ApplicationEntitySelect, useApplicationInputs } from './inputs';
import { useIntl } from 'react-intl';

type SoleProprietorshipFormType = {
  path: string;
  sameAsPath: string;
  sameAsValues?: Array<{ label: string; value: string }>;
};

export default function SoleProprietorshipForm({
  path,
  sameAsPath,
  sameAsValues,
}: SoleProprietorshipFormType) {
  const intl = useIntl();
  const {
    CompanyLegalNameInput,
    CompanyOperatingNameInput,
    NaicsInput,
    CompanyEmailInput,
    InBusinessSinceInput,
    FirstNameInput,
    LastNameInput,
    MiddleNameInput,
    PhoneInput,
    PhoneAreaCodeInput,
    ExtensionInput,
    MobilePhoneAreaCodeInput,
    MobilePhoneInput,
    DateOfBirthInput,
    SINInput,
    SameAsInput,
  } = useApplicationInputs(path, sameAsPath || undefined);

  const hasSameAsDD = !!sameAsValues?.length;

  return (
    <Grow in>
      <Grid container spacing={2}>
        {hasSameAsDD && (
          <Grid item xs={4} sm={4}>
            <SameAsInput
              sameAsValues={
                sameAsValues?.map((v) => ({
                  label: v.label as string,
                  value: v.value as string,
                })) ?? []
              }
            />
          </Grid>
        )}
        <Grid item xs={hasSameAsDD ? 8 : 12} sm={hasSameAsDD ? 8 : 12}>
          <ApplicationEntitySelect
            path={path}
            type="soleProprietorship"
            label={intl.formatMessage({ id: 'application.search_sole_proprietorship' })}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FirstNameInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MiddleNameInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LastNameInput />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CompanyLegalNameInput required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyOperatingNameInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NaicsInput />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InBusinessSinceInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyEmailInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={5}>
              <PhoneAreaCodeInput />
            </Grid>
            <Grid item xs={12} sm={5}>
              <PhoneInput />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ExtensionInput />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4}>
              <MobilePhoneAreaCodeInput />
            </Grid>
            <Grid item xs={12} sm={8}>
              <MobilePhoneInput />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SINInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateOfBirthInput />
        </Grid>
        <Grid item xs={12}>
          <AgeWarnings path={path} />
        </Grid>
      </Grid>
    </Grow>
  );
}
