import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import React, { MouseEventHandler, useRef } from 'react';

type DropDownPropsType = {
  children: React.ReactChild;
  options: {
    label: React.ReactChild | string;
    handler: MouseEventHandler;
  }[];
};

export default function DropDown({ children, options }: DropDownPropsType) {
  const [open, setOpen] = React.useState<boolean>();
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  React.useLayoutEffect(() => {
    if (open === false) {
      if (anchorRef.current && 'focus' in anchorRef.current) {
        anchorRef.current.focus();
      }
    }
  }, [open]);

  const handleClose = (event: React.MouseEvent<Document | HTMLElement>) => {
    if (anchorRef.current?.contains(event.target as HTMLElement | Document)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent<unknown>) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <div style={{ display: 'inline-flex' }} ref={anchorRef} onClick={handleToggle}>
      {children}
      <Popper
        open={!!open}
        anchorEl={anchorRef.current}
        transition
        style={{ zIndex: 10 }}
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper variant="outlined">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList onKeyDown={handleListKeyDown} autoFocusItem>
                  {options.map((option, inx) => (
                    <MenuItem onClick={option.handler} key={inx}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
