import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { initialState } from './initialState';
import * as gridViewApi from '../../apis/gridView';
import * as applicationApi from '../../apis/application';
import { actions as toastActions } from '../toast';
import { getIntl } from '../../utils/language';

const slice = createSlice({
  name: 'submittedApplication',
  initialState,
  reducers: {
    updateGridViews: (state, action: PayloadAction<GridViewType[]>) => {
      state.gridViews = action.payload;
    },
    updateFilterList: (state, action: PayloadAction<GridViewFilterListType>) => {
      state.filterList = action.payload;
    },
    updateColumnList: (state, action: PayloadAction<GridViewColumnType[]>) => {
      state.columns = action.payload;
    },
    updateSelectedGridView: (state, action: PayloadAction<string>) => {
      state.selectedGridView = action.payload;
    },
    updateSubmittedApplicationList: (
      state,
      action: PayloadAction<SubmittedApplicationsStoreType['submittedApplicationList']>,
    ) => {
      state.submittedApplicationList = action.payload;
    },
  },
});

const SUBMITTED_GRID = 'SubmittedApplication';

const addCustomGridView = (gridView: GridViewType) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStoreType,
) => {
  const intl = getIntl(getState);
  const { gridViews } = getState().submittedApplications;
  const { uuid, vendorUuid } = getState().user as UserBasedStoreType;

  const gridViewObject = {
    grid: SUBMITTED_GRID,
    userUuid: uuid,
    gridView: [...gridViews, gridView],
    vendorUuid,
  };
  try {
    await gridViewApi.updateGridView(gridViewObject);
  } catch (e) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'application.error_failed_update_grid' }),
    };
    return dispatch(toastActions.open(toastOptions));
  }
  dispatch(slice.actions.updateGridViews(gridViews));
};

const getGridViewList = () => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
  const intl = getIntl(getState);
  const { vendorUuid } = getState().user as UserBasedStoreType;
  const { gridViews } = getState().submittedApplications;
  let gridList;
  try {
    gridList = (await gridViewApi.getGridViewList(vendorUuid)).data;
  } catch (e) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'application.error_failed_fetch_grid' }),
    };
    return dispatch(toastActions.open(toastOptions));
  }
  const submittedList = gridList.filter((x) => x.grid === SUBMITTED_GRID);
  const submittedGridViewList = submittedList.flatMap((x) => x.gridView);
  const newGridViews = [...gridViews, ...submittedGridViewList];
  dispatch(slice.actions.updateGridViews(newGridViews));
};

const getSubmittedApplicationList = () => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStoreType,
) => {
  const intl = getIntl(getState);
  let applicationList;
  try {
    applicationList = (await applicationApi.getSubmittedApplicationList()).data;
  } catch (error) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'application.something_wrong' }),
    };
    dispatch(toastActions.open(toastOptions));
  }
  if (!applicationList) {
    return;
  }

  // customize list for grid component
  const formattedApplicationList = applicationList.map(
    (application: ApplicationListItemBEResponseType) => {
      return {
        uuid: application.uuid,
        table: 'Application',
        id: '0',
        updatedAt: '',
        applicationName: application.applicationName,
        equipment: application.description,
        equipmentCost: application.totalCost,
        status: {
          status: application.statusName,
          color: application.statusColor,
        },
        email: application.customerEmail,
        createdAt: application.createdAt,
        phone: application.customerPhone,
      } as ApplicationListItemType;
    },
  );

  dispatch(slice.actions.updateSubmittedApplicationList(formattedApplicationList));
  const toastOptions = {
    toastType: 'success' as const,
    message: intl.formatMessage({ id: 'application.submitted_applications_deleted' }),
  };
  dispatch(toastActions.open(toastOptions));
};

const deleteSubmittedApplications = (uuidList: string[]) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStoreType,
) => {
  const intl = getIntl(getState);
  try {
    await applicationApi.deleteDraftApplications(uuidList);
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'application.draft_applications_deleted' }),
    };
    dispatch(toastActions.open(toastOptions));

    dispatch(getSubmittedApplicationList());
  } catch (error) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'application.draft_applications_deleted_error' }),
    };
    dispatch(toastActions.open(toastOptions));
  }
};

export const actions = {
  ...slice.actions,
  getSubmittedApplicationList,
  addCustomGridView,
  getGridViewList,
  deleteSubmittedApplications,
};
export default slice.reducer;
