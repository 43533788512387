import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';

type AlertModalPropsType = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: React.ReactChild;
};

export default function EmailPreviewModal(props: AlertModalPropsType) {
  const { title, isOpen, onClose, onSubmit, children } = props;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color="primary" variant="contained">
          <FormattedMessage id="common.ok" />
        </Button>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id="common.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
