import React from 'react';
import MaterialLink, { LinkProps as MaterialLinkProps } from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

type LinkPropsType = {
  children: React.ReactChild;
  to: string;
};

function Link(props: MaterialLinkProps & LinkPropsType) {
  const { to, children } = props;

  return (
    <MaterialLink to={to} component={RouterLink}>
      {children}
    </MaterialLink>
  );
}

export default Link;
