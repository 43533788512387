import { connect, ConnectedProps } from 'react-redux';
import { actions as applicationAmendmentsActions } from '../../../store/applicationAmendments';
import ApplicationAmendments from './application-amendments.component';

const mapStateToProps = (state: AppStoreType) => ({
  gridViews: state.applicationAmendments.gridViews,
  selectedGridView: state.applicationAmendments.selectedGridView,
  filterList: state.applicationAmendments.filterList,
  columns: state.applicationAmendments.columns,
  amendments: state.applicationAmendments.amendmentsList,
  isLoading: state.applicationAmendments.isLoading,
  fileAttachments: state.file.attachments,
  fileLoading: state.file.loading,
});

const mapDispatchToProps = {
  getApplicationAmendments: applicationAmendmentsActions.getApplicationAmendments,
  postApplicationAmendment: applicationAmendmentsActions.postApplicationAmendment,
  putApplicationAmendment: applicationAmendmentsActions.putApplicationAmendment,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ApplicationAmendmentsConnectedProps = ConnectedProps<typeof connector>;

export default connector(ApplicationAmendments);
