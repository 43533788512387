import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { frCA, enCA } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { useIntl } from 'react-intl';
import useStyles from './styles';
import get from 'lodash/get';
import { formattedDate, isValidDateString, parseDate } from '../../../services/formatter-service';
import { DatePickerToolbar } from '../DatePickerToolbar';
import { APPLICATION_DATE_FORMAT } from '../../../constants/date';
import { translateLabel } from '../../../utils/translateLabel';
type VaultDatePickerPropsType = Partial<
  Omit<React.ComponentProps<typeof KeyboardDatePicker>, 'onChange'>
> & {
  name: string;
  label?: string;
  required?: boolean;
  onChange?: (val: string | null, name: string) => unknown;
};

const datePickerFormatMap = {
  'MM/dd/yyyy': {
    format: APPLICATION_DATE_FORMAT,
    label: APPLICATION_DATE_FORMAT,
  },
};

const VaultDatePicker = ({
  name,
  label,
  onChange: propOnChange,
  disabled,
  defaultValue,
  required,
  ...props
}: VaultDatePickerPropsType) => {
  const classes = useStyles();
  const intl = useIntl();
  const inputFormat = datePickerFormatMap[APPLICATION_DATE_FORMAT].format;
  const { control, setValue, errors } = useFormContext();
  const defaultOnChange = (val: string | null, name: string) => setValue(name, val);
  const onChange = propOnChange || defaultOnChange;
  const [isBlurred, setIsBlurred] = useState(false);

  const validate = (value: string) => {
    if (props.maxDate && isBlurred) {
      const maxDate = new Date(String(props.maxDate));
      const valueDate = new Date(value);

      if (isNaN(valueDate.getTime()) || maxDate.getTime() < valueDate.getTime()) {
        return true;
      }
    }
    return !!get(errors, name);
  };
  const invalidDateMessage = () =>
    isBlurred ? (
      <span className={classes.error}>
        {intl.formatMessage({ id: 'email.error.invalid_date' })}
      </span>
    ) : (
      <></>
    );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={intl.locale === 'FR' ? frCA : enCA}>
      <Controller
        render={({ ref, onChange: controllerOnChange, value }) => (
          <KeyboardDatePicker
            className={classes.container}
            id={name}
            ToolbarComponent={(props) => <DatePickerToolbar {...props} />}
            name={name}
            label={translateLabel(intl, label as string)}
            value={value}
            required={required}
            error={validate(value)}
            invalidDateMessage={invalidDateMessage()}
            format={APPLICATION_DATE_FORMAT}
            minDate={props.minDate || undefined}
            maxDate={props.maxDate || undefined}
            maxDateMessage={intl.formatMessage({ id: 'application.max_date_error_message' })}
            mask="__/__/____"
            onChange={(date, input) => {
              controllerOnChange(date, input);
              const isManual = input?.includes('/');
              const usedFormat = isManual ? inputFormat : APPLICATION_DATE_FORMAT;
              setIsBlurred(false);
              if (input && !input?.includes('_') && isValidDateString(input, usedFormat)) {
                const date = parseDate(input, usedFormat);
                const newDate = date ? formattedDate(date, APPLICATION_DATE_FORMAT) : null;
                onChange(newDate, name);
                ref.current.blur();
              }
            }}
            onBlur={() => setIsBlurred(true)}
            onFocus={(e) => {
              e.persist();
              const val = e.target.value;
              setTimeout(() => {
                if (val && isValidDateString(val, APPLICATION_DATE_FORMAT)) {
                  e.target.value = formattedDate(new Date(val), inputFormat);
                  props.inputValue = formattedDate(new Date(val), inputFormat);
                  setIsBlurred(false);
                }
              });
            }}
            disabled={disabled}
            InputProps={{
              placeholder: 'mm/dd/yyyy',
              autoComplete: 'disabled',
            }}
            inputRef={ref}
          />
        )}
        rules={{
          required,
          min: props.minDate ? String(props.minDate) : undefined,
          max: props.maxDate ? String(props.maxDate) : undefined,
        }}
        name={name}
        control={control}
        defaultValue={defaultValue || null}
      />
    </MuiPickersUtilsProvider>
  );
};
export default VaultDatePicker;
