import { Env } from './getBackendUrl';

const getGaTagId = () => {
  const windowEnv = (window as Env).env;

  if (windowEnv?.REACT_APP_GOOGLE_TAG_ID) {
    const googleTagId = windowEnv.REACT_APP_GOOGLE_TAG_ID;

    return `G-${googleTagId}`;
  }

  const env = process.env;
  const trackingId = `G-${env.REACT_APP_GOOGLE_TAG_ID}`;

  return trackingId;
};

export const setGaScripts = () => {
  const trackingId = getGaTagId();

  const asyncGaScript = document.getElementById('asyncGaScript');
  asyncGaScript?.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingId}`);

  const scriptId = 'ga-gtag';
  const gaScriptTag = document.createElement('script');
  const { head } = document;

  gaScriptTag.id = scriptId;
  gaScriptTag.type = 'text/javascript';
  gaScriptTag.text = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${trackingId}');`;

  return head.insertBefore(gaScriptTag, head.lastChild);
};
