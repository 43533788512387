import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function QuoteNotAvailableIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M107.215 61.1296V61.1302C107.218 61.6226 107.029 62.0953 106.691 62.4444C106.353 62.7935 105.894 62.9905 105.414 62.9931H20.7059C20.4686 62.9918 20.2337 62.9428 20.0147 62.8487C19.7957 62.7546 19.5969 62.6173 19.4295 62.4445C19.2621 62.2716 19.1295 62.0666 19.0395 61.8411C18.9494 61.6155 18.9037 61.374 18.9049 61.1302V61.1296L18.9049 1.98852L18.9049 1.98788C18.9024 1.49552 19.0914 1.02282 19.4294 0.673659C19.7673 0.324668 20.2264 0.12765 20.7056 0.125H105.415C105.894 0.127636 106.353 0.32464 106.691 0.673622L106.781 0.586671L106.691 0.673623C107.029 1.02278 107.218 1.49546 107.215 1.98783V1.98846V61.1296Z"
          fill="white"
          stroke="#A3A3A3"
        />
        <path
          d="M24.2597 3.54376H102.007C102.304 3.54376 102.588 3.664 102.797 3.87803C103.006 4.09206 103.124 4.38235 103.124 4.68503V58.2383C103.124 58.541 103.006 58.8313 102.797 59.0453C102.588 59.2593 102.304 59.3796 102.007 59.3796H24.2597C23.9635 59.3796 23.6795 59.2593 23.4701 59.0453C23.2607 58.8313 23.1431 58.541 23.1431 58.2383V4.68503C23.1431 4.38235 23.2607 4.09206 23.4701 3.87803C23.6795 3.664 23.9635 3.54376 24.2597 3.54376Z"
          fill="#E6E6E6"
        />
        <path
          d="M87.0386 78.0773C87.0386 78.5549 86.8529 79.0125 86.5232 79.3494C86.1936 79.6863 85.7473 79.875 85.2824 79.875H2.54085C2.07597 79.875 1.62957 79.6863 1.3 79.3494C0.970343 79.0125 0.784668 78.5549 0.784668 78.0773V20.9003C0.784668 20.4226 0.970343 19.9651 1.3 19.6281C1.62957 19.2913 2.07597 19.1025 2.54085 19.1025H85.286C85.7502 19.1035 86.1957 19.2927 86.5245 19.6294C86.8534 19.9662 87.0386 20.4233 87.0386 20.9003V78.0773Z"
          fill="white"
          stroke="black"
        />
        <path
          d="M6.06594 22.8694H81.7609C82.023 22.8694 82.275 22.9758 82.4611 23.1661C82.6474 23.3565 82.7525 23.6153 82.7525 23.8857V74.5812C82.7525 74.8516 82.6474 75.1104 82.4611 75.3008C82.275 75.4911 82.023 75.5975 81.7609 75.5975H6.06594C5.80384 75.5975 5.55191 75.4911 5.36573 75.3008C5.17946 75.1104 5.07434 74.8516 5.07434 74.5812V23.8857C5.07434 23.6153 5.17946 23.3565 5.36573 23.1661C5.55191 22.9758 5.80384 22.8694 6.06594 22.8694Z"
          fill="white"
          stroke="black"
        />
        <path
          d="M62.0054 33.7261V68.8025C62.0052 69.0076 61.9254 69.2043 61.7835 69.3494C61.6415 69.4945 61.4491 69.576 61.2483 69.5762H25.2902C25.0895 69.576 24.897 69.4945 24.7551 69.3494C24.6132 69.2043 24.5334 69.0076 24.5332 68.8025V29.5995C24.5334 29.3943 24.6132 29.1976 24.7551 29.0525C24.897 28.9075 25.0895 28.8259 25.2902 28.8257H56.7063L62.0054 33.7261Z"
          fill="#4153AF"
        />
        <path
          d="M30.8969 37.3626C30.7726 37.3647 30.6542 37.4167 30.5671 37.5072C30.48 37.5978 30.4312 37.7197 30.4312 37.8467C30.4312 37.9737 30.48 38.0956 30.5671 38.1862C30.6542 38.2768 30.7726 38.3287 30.8969 38.3309H43.3882C43.4505 38.3322 43.5125 38.3209 43.5706 38.2977C43.6287 38.2744 43.6818 38.2397 43.7267 38.1954C43.7716 38.1512 43.8075 38.0983 43.8324 38.0398C43.8573 37.9814 43.8706 37.9185 43.8716 37.8547C43.8727 37.7909 43.8614 37.7276 43.8384 37.6683C43.8154 37.6091 43.7812 37.555 43.7377 37.5093C43.6942 37.4635 43.6423 37.427 43.585 37.4019C43.5277 37.3767 43.4661 37.3633 43.4037 37.3626C43.3985 37.3625 43.3933 37.3625 43.3882 37.3626H30.8969Z"
          fill="white"
        />
        <path
          d="M30.8969 41.9042C30.7727 41.9064 30.6542 41.9583 30.5671 42.0489C30.48 42.1395 30.4312 42.2614 30.4312 42.3884C30.4312 42.5154 30.48 42.6373 30.5671 42.7279C30.6542 42.8185 30.7727 42.8704 30.8969 42.8726H55.6266C55.7519 42.8742 55.8727 42.825 55.9626 42.7357C56.0525 42.6464 56.1042 42.5244 56.1062 42.3963C56.1083 42.2682 56.0606 42.1446 55.9736 42.0523C55.8866 41.9601 55.7675 41.9068 55.6422 41.9042C55.637 41.9042 55.6318 41.9042 55.6266 41.9042H30.8969Z"
          fill="white"
        />
        <path
          d="M62.0055 33.7261H57.2111C57.0772 33.7261 56.9489 33.6718 56.8542 33.575C56.7596 33.4783 56.7064 33.3471 56.7064 33.2103V28.8257L62.0055 33.7261Z"
          fill="#4153AF"
        />
      </g>
    </SvgIcon>
  );
}
