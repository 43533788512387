import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainTitle: {
    marginBottom: theme.spacing(3),
  },
  secondTitle: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));
