import { SOLE_BUSINESS_TYPE } from '../constants/formValues';

export const getIndividualEntityLabelFactory = () =>
  function EtityLabel(entity: Entity) {
    if (!entity.uuid) {
      return '';
    }

    return `${entity.firstName} ${entity.lastName} ${
      entity.formattedPhone ? `tel: ${entity.formattedPhone}` : ''
    }`;
  };

export const getBusinessEntityLabelFactory = () =>
  function EtityLabel(entity: Entity) {
    if (!entity.uuid) {
      return '';
    }

    return `${entity.companyLegalName || entity.operatingName} ${
      entity.formattedPhone ? `tel: ${entity.formattedPhone}` : ''
    }`;
  };

export const getEntityType = (isBusiness: boolean, businessType: Entity['businessType']) =>
  !isBusiness
    ? 'individual'
    : businessType === SOLE_BUSINESS_TYPE.value
    ? 'soleProprietorship'
    : 'corporation';
