import React, { ComponentProps } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { isSinValid, SIN_NUMBERS_LENGTH } from '../../../utils/common';
import { useIntl } from 'react-intl';
import get from 'lodash/get';

type SinFormatterPropsType = {
  name: ComponentProps<typeof Controller>['name'];
  defaultValue?: ComponentProps<typeof Controller>['defaultValue'];
  value?: string;
} & Partial<ComponentProps<typeof NumberFormat>>;

export default function SinFormatter({
  name,
  onBlur,
  defaultValue,
  ...props
}: SinFormatterPropsType) {
  const intl = useIntl();
  const { control, errors } = useFormContext();
  const error = get(errors, name);

  return (
    <Controller
      render={({ value }) => (
        <NumberFormat
          {...props}
          name={name}
          customInput={TextField}
          value={value}
          format="###-###-###"
          mask="_"
          onBlur={onBlur}
          error={Boolean(error)}
          helperText={error?.message}
        />
      )}
      rules={{
        validate: (input) => {
          const value = input.replaceAll('-', '').replaceAll('_', '');
          if (value.length === 0 && !props.required) {
            return;
          }
          if (value.length !== SIN_NUMBERS_LENGTH) {
            return intl.formatMessage({ id: 'common.short_sin_format' });
          } else if (!isSinValid(value)) {
            return intl.formatMessage({ id: 'common.wrong_sin_format' });
          }

          return true;
        },
      }}
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
    />
  );
}

SinFormatter.propTypes = {
  name: PropTypes.string.isRequired,
};
