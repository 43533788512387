import { GridOptions } from '@ag-grid-community/all-modules';
import ButtonLink from './ButtonLink';
import LoginAsButton from './LoginAsButton';
import DateRenderer from './DateRenderer';
import StateRenderer from './StateRenderer';
import CostRenderer from './CostRenderer';
import StatusRenderer from './StatusRenderer';
import EmailRenderer from './EmailRenderer';
import ButtonConfirmation from './ButtonConfirmation';

// headers
import SelectAllHeader from './SelectAllHeader';

export default {
  buttonLink: ButtonLink,
  loginAsButton: LoginAsButton,
  dateRenderer: DateRenderer,
  stateRenderer: StateRenderer,
  costRenderer: CostRenderer,
  statusRenderer: StatusRenderer,
  selectAllHeader: SelectAllHeader,
  emailRenderer: EmailRenderer,
  buttonConfirmation: ButtonConfirmation,
} as GridOptions['frameworkComponents'];
