import React from 'react';
import Chip from '../../chip';
import { useIntl } from 'react-intl';

export default function StateRenderer(props: {
  value: boolean;
  data?: { [key: string]: string | number };
}) {
  const intl = useIntl();

  return props.value ? (
    <Chip label={intl.formatMessage({ id: 'common.active' })} backgroundColor="#4caf50" />
  ) : (
    <Chip label={intl.formatMessage({ id: 'common.inactive' })} backgroundColor="#ff784e" />
  );
}
