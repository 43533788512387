import { connect, ConnectedProps } from 'react-redux';
import { actions as emailTemplatesListActions } from '../../store/emailTemplatesList';
import EmailTemplatesList from './email-templates-list.component';

export const mapStateToProps = (state: AppStoreType) => ({
  vendorUuid: (state.user as UserBasedStoreType).vendorUuid,
});

export const mapDispatchToProps = {
  fetchEmailTemplateList: emailTemplatesListActions.fetchEmailTemplateList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type EmailTemplatesListConnectedProps = ConnectedProps<typeof connector>;
export default connector(EmailTemplatesList);
