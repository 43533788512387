import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { deleteVendor } from '../../../../apis/vendor';
import { deleteUser } from '../../../../apis/users';
import { deleteSubmittedApplication } from '../../../../apis/application';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { GridApi } from 'ag-grid-community';

export default function ButtonConfirmation(props: {
  value: string;
  data?: { [key: string]: string | number | boolean };
  api: GridApi;
  variant: 'vendor' | 'user' | 'application';
}) {
  const uuid = props.data?.uuid;
  const variant = props.variant;

  const [isOpen, setIsOpen] = useState(false);
  const translateIdMap = {
    vendor: { title: 'common.remove_vendor', dialog: 'common.remove_vendor_dialog', prefix: null },
    user: { title: 'common.remove_user', dialog: 'common.remove_user_dialog', prefix: null },
    application: {
      title: 'common.remove_application',
      dialog: null,
      prefix: props.data?.applicationName,
    },
  };

  const onClose = () => setIsOpen(false);
  const onSubmit = async () => {
    try {
      if (variant === 'vendor') await deleteVendor(uuid as string);
      if (variant === 'user') await deleteUser(uuid as string);
      if (variant === 'application') await deleteSubmittedApplication(uuid as string);
    } catch (error) {
      setIsOpen(false);
    }
    setIsOpen(false);
    props.api.refreshInfiniteCache();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          <FormattedMessage id={translateIdMap[variant].title} />
        </DialogTitle>
        <DialogContent>
          {translateIdMap[variant].dialog && (
            <FormattedMessage id={translateIdMap[variant].dialog as string} />
          )}
          {translateIdMap[variant].prefix && <>&nbsp;{translateIdMap[variant].prefix}</>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} color="primary" variant="contained">
            <FormattedMessage id="common.confirm" />
          </Button>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="common.cancel" />
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <DeleteOutlineIcon />
      </Button>
    </>
  );
}
