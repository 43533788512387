import React, { Dispatch } from 'react';
import { Column, ColumnApi, GridApi, RowNode } from 'ag-grid-community';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import { generatePath, Link } from 'react-router-dom';

import Chip from '../chip';
import DateFormatter from '../date-formatter';
import CurrencyFormat from '../currency-format';

type RowNodeProp = {
  node: RowNode;
};

export const useDataGrid = (
  gridApi: GridApi | null,
  classes: ClassNameMap<
    | 'container'
    | 'button'
    | 'mainTitle'
    | 'title'
    | 'actionsContainer'
    | 'rightSpacing'
    | 'applicationNameButton'
    | 'alpineThemeContainer'
    | 'tableHolder'
    | 'MuiDataGrid'
  >,
) => {
  const onSelectionChanged = (
    setDisableButtons: Dispatch<boolean>,
    customOnSelectionChange?: (selectedRows?: RowNode[]) => void,
  ) => {
    customOnSelectionChange && customOnSelectionChange(gridApi?.getSelectedNodes());
    if (gridApi && gridApi.getSelectedNodes().length > 0) {
      setDisableButtons(false);
    } else {
      setDisableButtons(true);
    }
  };

  const onGridSizeChanged = ({
    clientWidth,
    columnApi,
  }: {
    clientWidth: number;
    columnApi: ColumnApi;
  }) => {
    const preferredWidth = columnApi.getAllDisplayedColumns().reduce((sum: number, acc: Column) => {
      const { width, minWidth } = acc.getColDef();
      return (width || minWidth || acc.getActualWidth()) + sum;
    }, 0);

    const borderOffset = 5;
    const newWidth = clientWidth - borderOffset;
    if (newWidth > preferredWidth) {
      columnApi.sizeColumnsToFit(newWidth);
    } else {
      columnApi.sizeColumnsToFit(preferredWidth);
    }
  };

  const onChange = (
    value: string,
    setIsSearchResult: Dispatch<boolean>,
    setSearchText: Dispatch<string>,
  ) => {
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
    setSearchText(value);
    setIsSearchResult(true);
  };

  const onCancel = (setIsSearchResult: Dispatch<boolean>, setSearchText: Dispatch<string>) => {
    if (gridApi) {
      gridApi.setQuickFilter('');
    }
    setIsSearchResult(false);
    setSearchText('');
  };

  const handleDeleteSelected = (
    setShowDeleteModal: Dispatch<boolean>,
    onDeleteSelected?: (uuids: Array<string>) => void,
  ) => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const uuidArray = selectedNodes.map((x) => x.data.uuid);

      if (onDeleteSelected) {
        onDeleteSelected(uuidArray);
      }

      setShowDeleteModal(false);
    }
  };

  const handleOpenDeleteModal = (setShowDeleteModal: Dispatch<boolean>) => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      if (selectedNodes.length > 0) {
        setShowDeleteModal(true);
      }
    }
  };

  const onMaskButtonClick = (maskButtonClick?: (uuids: Array<string>) => void) => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const uuidArray = selectedNodes.map((x) => x.data.uuid);

      if (maskButtonClick) {
        maskButtonClick(uuidArray);
      }
    }
  };

  const onDropdownSelect = (
    item: EmailTemplateListType,
    dropdown: {
      options: Array<EmailTemplateListType>;
      renderItem: (t: EmailTemplateListType) => string;
      onSelect: (item: EmailTemplateListType, uuids: string[]) => void;
      label: string;
    },
  ) => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const uuidArray = selectedNodes.map((x) => x.data.uuid);
      dropdown?.onSelect(item, uuidArray);
    }
  };

  const ViewDetailRenderer1 = (
    props: RowNodeProp,
    editButtonLabel: string,
    handleEdit1?: ((key: string) => void) | false,
  ) => {
    return (
      !props.node.data.disableEdit &&
      handleEdit1 && (
        <Button
          className={classes.button}
          color="primary"
          onClick={() => {
            handleEdit1(props.node.data.uuid);
          }}
        >
          {editButtonLabel}
        </Button>
      )
    );
  };

  const loginAsRenderer = (
    props: RowNodeProp,
    label: string,
    handleLoginAs?: ((key: string) => void) | false,
  ) => {
    return (
      handleLoginAs && (
        <Button
          className={classes.button}
          disabled={!(props.node.data.active && props.node.data.vendor.active)}
          color="primary"
          onClick={() => {
            handleLoginAs(props.node.data.uuid);
          }}
        >
          {label}
        </Button>
      )
    );
  };

  const handleLinkPagePath2Renderer = (props: RowNodeProp, linkPagePath2?: string) => {
    return props.node.data.uuid2 ? (
      <Link to={generatePath(linkPagePath2 ?? '', { id: props.node.data.uuid2 })}>
        {props.node.data.idLabel2}
      </Link>
    ) : null;
  };

  const idLabelRenderer = (props: RowNodeProp, handleEdit1?: ((key: string) => void) | false) => {
    return props.node.data.idLabel && handleEdit1 ? (
      <Button
        className={classes.button}
        color="primary"
        onClick={() => {
          handleEdit1(props.node.data.uuid);
        }}
      >
        {props.node.data.idLabel}
      </Button>
    ) : null;
  };

  const applicationNameRenderer = (
    props: RowNodeProp,
    handleEdit1?: ((key: string) => void) | false,
  ) => {
    return props.node.data.applicationName && handleEdit1 ? (
      <Button
        className={classes.applicationNameButton}
        color="primary"
        onClick={() => {
          handleEdit1(props.node.data.uuid);
        }}
      >
        {props.node.data.applicationName}
      </Button>
    ) : null;
  };

  const contractIdRenderer = (props: RowNodeProp) => {
    return props.node.data.contractId ?? 'N/A';
  };

  const statusRenderer = (
    { node: { data } }: RowNodeProp,
    statusesMap?: { [key: string]: string },
  ) => {
    let status: string = data.status?.status || data.status;

    if (statusesMap) {
      status = statusesMap[status];
    }

    const statusColor = data.status?.color || data.statusColor;
    return <Chip label={status} backgroundColor={statusColor} />;
  };

  const dateFieldRenderer = (props: {
    column: { colId: number };
    node: { data: { [x: string]: string } };
  }) => {
    const colId = props.column.colId;
    const date = props.node.data[colId];

    return <DateFormatter date={date} showTime />;
  };

  const filesRenderer = (props: { node: { data: { files: string | string[] } } }) => {
    return props.node.data.files.length || '-';
  };

  const currencyRenderer = (props: {
    colDef: { field: string };
    data: { [x: string]: string | number | undefined };
  }) => {
    const { field } = props.colDef;
    const currency = props.data[`${field}CurrencyType`];
    return <CurrencyFormat value={props.data[field]} currencyType={currency as string} />;
  };

  return {
    onSelectionChanged,
    onGridSizeChanged,
    onChange,
    onCancel,
    handleDeleteSelected,
    handleOpenDeleteModal,
    onMaskButtonClick,
    onDropdownSelect,
    ViewDetailRenderer1,
    loginAsRenderer,
    handleLinkPagePath2Renderer,
    idLabelRenderer,
    applicationNameRenderer,
    contractIdRenderer,
    statusRenderer,
    dateFieldRenderer,
    filesRenderer,
    currencyRenderer,
  };
};
