import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '0 -15px',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    width: '100%',
  },
  formControlLabel: {
    display: 'block',
  },
  labelSuccess: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0),
    borderRadius: 0,
    backgroundColor: 'rgb(237, 247, 237)',
    color: 'rgb(30, 70, 32)',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  labelPending: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0),
    borderRadius: 0,
    backgroundColor: 'rgba(117, 117, 117, 0.2)',
    color: 'rgba(117, 117, 117, 1)',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  labelError: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0),
    borderRadius: 0,
    backgroundColor: 'rgb(253, 236, 234)',
    color: 'rgb(97, 26, 21)',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  generateButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  documentButton: {
    margin: theme.spacing(1),
  },
  firstButton: {
    marginLeft: 0,
  },
  role: {
    textTransform: 'uppercase',
    fontSize: 10,
    letterSpacing: '1.5px',
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  applicantEmail: {
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '0.15px',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dialogTitle: {
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(0),
  },
}));

export default useStyles;
