import * as emailTemplateApi from '../../apis/emailTemplates';
import { actions as toastActions } from '../../store/toast';
import { actions } from './reducer';
import { Dispatch } from 'react';
import { IGetRowsParams } from '@ag-grid-community/all-modules';
import { getIntl } from '../../utils/language';
import { previewEmail } from '../../apis/email';

export const updateEmailTemplate =
  (templateUuid: string, templateObj: EmailTemplateUpdateType) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      const result = await emailTemplateApi.updateEmailTemplateById(templateUuid, templateObj);

      if (result) {
        getEmailTemplate(templateUuid);
      }
    } catch (e) {
      let toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };

      if (e.response.data && e.response.data.code === 623) {
        toastOptions = {
          toastType: 'error' as const,
          message: e.response.data.message, // TODO server side translation
        };
      }

      dispatch(toastActions.open(toastOptions));

      return;
    }

    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'email_templates.updated_successfully' }),
    };

    dispatch(toastActions.open(toastOptions));
  };

export const createEmailTemplate =
  (templateObj: EmailTemplateUpdateType) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      const result = await emailTemplateApi.createEmailTemplateById(templateObj);
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'email_templates.created_successfully' }),
      };

      dispatch(toastActions.open(toastOptions));

      return result.data.uuid;
    } catch (e) {
      let toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };

      if (e.response.data && e.response.data.code === 623) {
        toastOptions = {
          toastType: 'error' as const,
          message: e.response.data.message, // TODO server side translation
        };
      }
      dispatch(toastActions.open(toastOptions));

      return false;
    }
  };

export const deleteEmailTemplate =
  (uuid: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await emailTemplateApi.deleteEmailTemplateById(uuid);
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'email_templates.deleted_successfully' }),
      };

      dispatch(toastActions.open(toastOptions));

      return true;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return false;
    }
  };

export const fetchEmailTemplateList =
  (params: IGetRowsParams) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let allTemplates;
    let formattedTemplates: PaginationEmailTemplateType;
    try {
      allTemplates = (await emailTemplateApi.getAllEmailTemplateList(params)).data;
      formattedTemplates = {
        total: allTemplates.total,
        data: allTemplates.data.map((template: EmailTemplateListBEResponse) => {
          return {
            name: template.name,
            relatedTo: template.relatedTo,
            companyName: template.companyName,
            active: template.active,
            updatedAt: template.updatedAt,
            createdAt: template.createdAt,
            uuid: template.uuid,
            type: template.type,
          };
        }),
      };
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    return formattedTemplates;
  };

export const getEmailTemplate =
  (templateId: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let emailTemplate;

    try {
      emailTemplate = (await emailTemplateApi.getEmailTemplate(templateId)).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.updateEmailTemplateOnEdit(emailTemplate));
    return emailTemplate;
  };

export const duplicateEmailTemplate =
  (templateId: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);

    try {
      const result = await emailTemplateApi.duplicateEmailTemplateById(templateId);
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'email_templates.copied_successfully' }),
      };

      dispatch(toastActions.open(toastOptions));

      return result.data.uuid;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return false;
    }
  };

export const previewEmailTemplate =
  (templateUuid: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let templateData;

    try {
      templateData = (await previewEmail(templateUuid)).data;
      dispatch(actions.updatePreviewEmail(templateData));

      return templateData;
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));

      return false;
    }
  };
