import BusinessIcon from '@material-ui/icons/Business';
import UserIcon from '../components/icons/user';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import CreateApplicationIcon from '../components/icons/create-application';
import SubmittedApplicationIcon from '../components/icons/submitted-application';
import DraftApplicationIcon from '../components/icons/draft-application';
import InstantQuoteIcon from '../components/icons/instant-quote';
import QuoteListIcon from '../components/icons/quote-list';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MailOutline from '@material-ui/icons/MailOutline';

export const DASHBOARD_PAGE = {
  path: '/dashboard',
  title: 'navigations.dashboard',
  showTitle: true,
};
export const INSTANT_QUOTE_PAGE = {
  path: '/instant-quote/:id',
  title: 'navigations.instant_quote',
  showTitle: true,
};

export const PUBLIC_QUOTE_TO_APPLICATION_PAGE = {
  path: '/quote-to-application/:paymentOptionUuid',
  title: 'navigations.quote_to_application',
  showTitle: true,
};

export const QUOTE_TO_APPLICATION_PAGE = {
  path: '/quote-to-application/:paymentOptionUuid',
  title: 'navigations.quote_to_application',
  showTitle: true,
};

export const INSTANT_NEW_QUOTE_PAGE = {
  path: '/instant-quote',
  title: 'navigations.instant_quote',
  showTitle: true,
};

export const VENDORS_LIST_PAGE = {
  path: '/vendors',
  title: 'navigations.vendors',
  showTitle: true,
};

export const EMAIL_TEMPLATES_LIST_PAGE = {
  path: '/email-templates',
  title: 'navigations.templates',
  showTitle: false,
};

export const EMAIL_TEMPLATE_PAGE = {
  path: '/email-template/:emailTemplateUuid',
  title: 'navigations.edit_template',
  showTitle: false,
};

export const NEW_EMAIL_TEMPLATE_PAGE = {
  path: '/email-template',
  title: 'navigations.create_template',
  showTitle: false,
};

export const AUDIT_TRAIL = {
  path: '/audit-trail',
  title: 'navigations.activity_history',
  showTitle: true,
};

export const AUDIT_TRAIL_LOGIN_AS = {
  path: AUDIT_TRAIL.path + '/log-in-as',
  title: AUDIT_TRAIL.title,
  showTitle: AUDIT_TRAIL.showTitle,
};

export const BROKER_VENDOR_REPS_LIST_PAGE = {
  path: '/vendor-reps-list',
  title: 'navigations.vendor_reps',
  showTitle: true,
};

export const BROKER_VENDOR_INFO_PAGE = {
  path: '/vendor-info/:vendorUuid',
  title: 'navigations.vendor_information',
  showTitle: true,
};

export const APPLICATION_PAGE = {
  path: '/application/:id',
  title: 'navigations.application',
  showTitle: true,
};
export const NEW_APPLICATION_PAGE = {
  path: '/create-application',
  title: 'navigations.create_application',
  showTitle: true,
};

export const EDIT_DRAFT_APPLICATION_PAGE = {
  path: '/create-application/:applicationUuid',
  title: 'navigations.edit_draft_application',
  showTitle: false,
};

export const SUBMITTED_APPLICATION_PAGE = {
  path: '/submitted-applications',
  title: 'navigations.applications_queue',
  showTitle: false,
};
export const DRAFT_APPLICATION_PAGE = {
  path: '/draft-applications',
  title: 'navigations.draft_applications',
  showTitle: false,
};

export const QUOTE_LIST = {
  path: '/quote-list',
  title: 'navigations.quotes',
  showTitle: false,
};

export const USER_MANAGEMENT = {
  path: '/user-management',
  title: 'navigations.user_management',
  showTitle: false,
};

export const CREATE_USER = {
  path: '/create-user',
  title: 'navigations.create_user',
  showTitle: true,
};

export const USER_EDIT_PAGE = {
  path: '/user-edit/:userVendorUuid',
  title: 'navigations.user_edit',
  showTitle: true,
};

export const VENDOR_SETTINGS_PAGE = {
  path: '/vendor-settings',
  title: 'navigations.vendor_settings',
  showTitle: true,
};
export const VENDOR_SETTINGS_EDIT_PAGE = {
  path: '/vendor-settings/:vendorUuid',
  title: 'navigations.vendor_settings',
  showTitle: true,
};

export const EMAIL_ACTION_DECLINE = {
  path: '/email-action/quote-decline/:generatedQuoteUuid',
  title: 'navigations.decline_quote',
  showTitle: true,
};

export const EMAIL_ACTION_UNSUBSCRIBE = {
  path: '/email-action/quote-unsubscribe/:quoteUuid',
  title: 'navigations.unsubscribe_quote',
  showTitle: true,
};

export const SETTINGS_PAGE = {
  path: '/settings',
  title: 'navigations.settings',
  showTitle: true,
};
export const QUOTES_PAGE = {
  path: '/quotes',
  title: 'navigations.quotes',
  showTitle: true,
};

export const MY_PROFILE_PAGE = {
  path: '/my-profile',
  title: 'navigations.my_profile',
  showTitle: true,
};

export const MY_BROKER_REP_PROFILE_PAGE = {
  path: '/my-broker-rep-profile',
  title: 'navigations.my_profile',
  showTitle: true,
};

export const SIGN_IN_PAGE = {
  path: '/sign-in',
  title: 'navigations.sign_in',
  showTitle: false,
};

export const SEARCH_RESULT_PAGE = {
  path: '/search-result/:searchQuery?/:searchSection?',
  title: 'navigations.search_results',
  showTitle: false,
};

export const ALL_PAGES = [
  DASHBOARD_PAGE,
  INSTANT_QUOTE_PAGE,
  APPLICATION_PAGE,
  NEW_APPLICATION_PAGE,
  SUBMITTED_APPLICATION_PAGE,
  DRAFT_APPLICATION_PAGE,
  INSTANT_NEW_QUOTE_PAGE,
  SETTINGS_PAGE,
  QUOTES_PAGE,
  EMAIL_ACTION_UNSUBSCRIBE,
  EMAIL_ACTION_DECLINE,
  QUOTE_LIST,
  USER_MANAGEMENT,
  USER_EDIT_PAGE,
  VENDOR_SETTINGS_PAGE,
  CREATE_USER,
  VENDORS_LIST_PAGE,
  BROKER_VENDOR_REPS_LIST_PAGE,
  EMAIL_TEMPLATES_LIST_PAGE,
  EMAIL_TEMPLATE_PAGE,
  MY_BROKER_REP_PROFILE_PAGE,
  AUDIT_TRAIL,
  AUDIT_TRAIL_LOGIN_AS,
  BROKER_VENDOR_INFO_PAGE,
];

export const navigationLinks = [
  {
    route: INSTANT_NEW_QUOTE_PAGE,
    Icon: InstantQuoteIcon,
    isVendorAdmin: false,
    isPortalAdmin: false,
    mobileOnly: false,
    isUser: true,
    isBrokerRep: false,
    selectedRoutes: [INSTANT_QUOTE_PAGE, INSTANT_NEW_QUOTE_PAGE],
  },
  {
    route: NEW_APPLICATION_PAGE,
    Icon: CreateApplicationIcon,
    isVendorAdmin: false,
    isPortalAdmin: false,
    mobileOnly: false,
    isUser: true,
    isBrokerRep: false,
    selectedRoutes: [NEW_APPLICATION_PAGE],
  },
  {
    route: QUOTE_LIST,
    Icon: QuoteListIcon,
    isVendorAdmin: false,
    isPortalAdmin: false,
    mobileOnly: false,
    isUser: true,
    isBrokerRep: false,
    selectedRoutes: [QUOTE_LIST],
  },
  {
    route: DRAFT_APPLICATION_PAGE,
    Icon: DraftApplicationIcon,
    isVendorAdmin: false,
    isPortalAdmin: false,
    mobileOnly: false,
    isUser: true,
    isBrokerRep: false,
    selectedRoutes: [DRAFT_APPLICATION_PAGE, EDIT_DRAFT_APPLICATION_PAGE],
  },
  {
    route: SUBMITTED_APPLICATION_PAGE,
    Icon: SubmittedApplicationIcon,
    isVendorAdmin: false,
    isPortalAdmin: false,
    mobileOnly: false,
    isUser: true,
    isBrokerRep: false,
    selectedRoutes: [SUBMITTED_APPLICATION_PAGE, APPLICATION_PAGE],
  },
  {
    route: USER_MANAGEMENT,
    Icon: PeopleIcon,
    isVendorAdmin: true,
    isPortalAdmin: false,
    mobileOnly: false,
    isUser: true,
    isBrokerRep: false,
    selectedRoutes: [USER_MANAGEMENT, USER_EDIT_PAGE],
  },
  {
    route: VENDOR_SETTINGS_PAGE,
    Icon: BusinessIcon,
    isVendorAdmin: true,
    isPortalAdmin: false,
    mobileOnly: false,
    isUser: true,
    isBrokerRep: false,
    selectedRoutes: [VENDOR_SETTINGS_PAGE],
  },
  {
    route: VENDORS_LIST_PAGE,
    Icon: BusinessIcon,
    isVendorAdmin: false,
    isPortalAdmin: false,
    mobileOnly: false,
    isUser: false,
    isBrokerRep: true,
    selectedRoutes: [VENDORS_LIST_PAGE],
  },
  {
    route: BROKER_VENDOR_REPS_LIST_PAGE,
    Icon: PeopleIcon,
    isVendorAdmin: false,
    isPortalAdmin: false,
    mobileOnly: false,
    isUser: false,
    isBrokerRep: true,
    selectedRoutes: [BROKER_VENDOR_REPS_LIST_PAGE],
  },
  {
    route: MY_PROFILE_PAGE,
    Icon: UserIcon,
    isVendorAdmin: false,
    isPortalAdmin: false,
    mobileOnly: true,
    isUser: true,
    isBrokerRep: false,
    selectedRoutes: [MY_PROFILE_PAGE],
  },
  {
    route: MY_BROKER_REP_PROFILE_PAGE,
    Icon: UserIcon,
    isVendorAdmin: false,
    isPortalAdmin: false,
    mobileOnly: true,
    isUser: false,
    isBrokerRep: true,
    selectedRoutes: [MY_PROFILE_PAGE],
  },
  {
    route: AUDIT_TRAIL,
    Icon: AccessTimeIcon,
    isVendorAdmin: false,
    isPortalAdmin: true,
    mobileOnly: false,
    isUser: false,
    isBrokerRep: false,
    selectedRoutes: [AUDIT_TRAIL_LOGIN_AS, AUDIT_TRAIL],
  },
  {
    route: EMAIL_TEMPLATES_LIST_PAGE,
    Icon: MailOutline,
    isVendorAdmin: false,
    isPortalAdmin: true,
    mobileOnly: false,
    isUser: false,
    isBrokerRep: false,
    selectedRoutes: [EMAIL_TEMPLATES_LIST_PAGE],
  },
];
