import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inHeader: {
    '& $infoWrapper': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '&$noImage': {
      '& $infoWrapper': {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          fontSize: '0.5rem',
        },
      },
    },
    '&$root': {
      paddingRight: '5px',
      paddingLeft: '5px',
      width: '310px',
      borderRight: '1px solid #ccc',
      [theme.breakpoints.down('sm')]: {
        width: '92px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '87px',
      },
      '&:last-child': {
        borderRight: 'none',
      },
      '& $logo': {
        minWidth: 'auto',
        minHeight: '48px',
        width: '100%',
        marginRight: '0',
        [theme.breakpoints.up('md')]: {
          marginLeft: '5px',
          marginRight: '5px',
        },
      },
    },
  },
  inMenu: {
    '& $logo': {
      minWidth: '70px',
    },
    '&$root': {
      padding: '10px',
      borderTop: '1px solid #ccc',
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  noImage: {},
  infoWrapper: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  logo: {
    minWidth: '100px',
    minHeight: '50px',
    width: '28%',
    marginRight: '10px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    imageRendering: '-webkit-optimize-contrast',
  },
  name: {
    fontSize: '0.9rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    [theme.breakpoints.up(900)]: {
      paddingRight: '20px',
    },
  },
  nameWrapper: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.text.hint,
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up(900)]: {
      paddingRight: '20px',
    },
  },
}));

export default useStyles;
