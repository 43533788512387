import React from 'react';
import { Route, Switch, BrowserRouter, RouteProps, Redirect } from 'react-router-dom';
import VaultSnackbar from '../components/common/VaultSnackbar';
import { INSTANT_NEW_QUOTE_PAGE, VENDORS_LIST_PAGE } from '../constants/navigations';
import AuthRoutes from './AuthRoutes';
import VendorRoutes from './VendorRoutes';
import { useSelector } from 'react-redux';
import { subscribe } from '../services/websockets';
import { logout } from '../store/user';
import { useDispatch } from 'react-redux';

export type AppRoute = {
  name: string;
  path: string;
  component: RouteProps['component'];
  exact: boolean;
};

type RouterPropsType = {
  routes: AppRoute[];
  routesRequireLogin: AppRoute[];
  publicRoutes: AppRoute[];
  brokerRepRoutes: AppRoute[];
  closeToast: () => void;
};

const Router = ({
  routes,
  routesRequireLogin,
  brokerRepRoutes,
  publicRoutes,
  closeToast,
}: RouterPropsType) => {
  const userType = useSelector((s: AppStoreType) => s.user?.type);
  const userUuid = useSelector((s: AppStoreType) => s.user?.uuid);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (userUuid && userType === 'user') {
      const unsubscribe = subscribe({
        entity: 'userDeactivated',
        filter: userUuid,
        handler: logoutUser,
      });

      return () => {
        unsubscribe();
      };
    }
  }, [userUuid]);

  const logoutUser = async () => {
    try {
      await dispatch(logout());
      location.href = '/sign-in';
    } catch (e) {
      return;
    }
  };

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              switch (userType) {
                case 'user':
                  return <Redirect to={INSTANT_NEW_QUOTE_PAGE.path} />;
                case 'brokerRep':
                  return <Redirect to={VENDORS_LIST_PAGE.path} />;
                default:
                  return <AuthRoutes />;
              }
            }}
          />
          {routes.map((route) => (
            <Route
              exact={route.exact ?? true}
              path={route.path}
              key={route.name}
              component={route.component}
            />
          ))}
          {publicRoutes.map((route, ind) => {
            const C = route.component as React.FC;

            return [
              <Route
                exact={route.exact ?? true}
                path={`/:vendorSiteUrl/:userSiteUrl${route.path}`}
                key={ind + 'user'}
                render={(...args: unknown[]) => (
                  <VendorRoutes {...args}>
                    <C />
                  </VendorRoutes>
                )}
              />,
              <Route
                exact={route.exact ?? true}
                path={`/:vendorSiteUrl${route.path}`}
                key={ind + 'vendor'}
                render={(...args: unknown[]) => (
                  <VendorRoutes {...args}>
                    <C />
                  </VendorRoutes>
                )}
              />,
            ];
          })}
          <Route
            render={() => {
              return (
                <AuthRoutes>
                  {(userType === 'brokerRep' ? brokerRepRoutes : routesRequireLogin).map(
                    (route, ind) => (
                      <Route
                        exact={route.exact ?? true}
                        path={route.path}
                        key={ind}
                        component={route.component}
                      />
                    ),
                  )}
                </AuthRoutes>
              );
            }}
          />
        </Switch>
        <VaultSnackbar closeToast={closeToast} />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default Router;
