import { actions } from './reduces';
import { actions as toastActions } from '../../store/toast';
import {
  addFileToQuote as addFileToQuoteRequest,
  createInstantQuote as createInstantQuoteRequest,
  deleteFileFromQuote as deleteFileFromQuoteRequest,
  getQuote,
  updateInstantQuote as updateInstantQuoteRequest,
  createInstantQuotePublic as createInstantQuotePublicRequest,
  addFileToQuotePublic as addFileToQuotePublicRequest,
  deleteFileFromQuotePublic as deleteFileFromQuotePublicRequest,
} from '../../apis/quote';
import { Dispatch } from 'react';
import {
  generateQuoteFollowUpEmailByTemplateUuid,
  generateQuoteEmailByTemplateUuid,
  sendQuoteDetailsEmail as sendQuoteDetailsEmailRequest,
  sendQuoteDetailsEmailPublic as sendQuoteDetailsEmailPublicRequest,
  SendQuoteDetailsEmail,
  sendQuoteFollowUpEmails,
  SendQuoteFollowUpEmailsArgs,
} from '../../apis/email';
import { validateRecipients } from '../../utils/emailActions';
import { getIntl } from '../../utils/language';
import omit from 'lodash/omit';
import { QuoteEmailType } from '../../components/common/QuoteEmail';
import { ProgressHandler } from '../../services/websockets';
import { getFormattedDate } from '../../services/formatter-service';
import { QUOTE_DATE_FORMAT } from '../../constants/date';

//actual method which is called from the components
export const generateInstantQuote =
  (customerInfo: CustomerInfo) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    dispatch(actions.setLoading(true));
    let quoteUuid;
    try {
      if (!customerInfo.quoteInfo?.quoteLanguage) {
        customerInfo.quoteInfo.quoteLanguage = intl.locale.toUpperCase();
      }
      quoteUuid = (await createInstantQuoteRequest(customerInfo)).data.uuid;
    } catch (e) {
      let message = intl.formatMessage({ id: 'common.something_went_wrong' });

      if (e.response.data.code === 400 && e.response?.data.message) {
        message = e.response?.data.message;
      }
      const toastOptions = {
        toastType: 'error' as const,
        message,
      };

      dispatch(toastActions.open(toastOptions));
      dispatch(actions.quoteError(''));
      dispatch(actions.setLoading(false));

      return;
    }
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'quote.loaded' }),
    };
    dispatch(toastActions.open(toastOptions));
    dispatch(actions.setLoading(false));

    return quoteUuid;
  };

export const generateInstantQuotePublic =
  (customerData: PublicGenerateQuoteRequest, vendorSiteUrl: string, userSiteUrl?: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    dispatch(actions.setLoading(true));
    let quoteData;
    try {
      if (!customerData.quoteLanguage) {
        customerData.quoteLanguage = intl.locale.toUpperCase();
      }
      quoteData = (await createInstantQuotePublicRequest(customerData, vendorSiteUrl, userSiteUrl))
        .data;
    } catch (e) {
      let message = intl.formatMessage({ id: 'common.something_went_wrong' });
      if (e.response.data.code === 400 && e.response?.data.message) {
        message = e.response?.data.message; // TODO server side translation
      }
      const toastOptions = {
        toastType: 'error' as const,
        message: message,
      };
      dispatch(toastActions.open(toastOptions));
      dispatch(actions.quoteError(''));
      dispatch(actions.setLoading(false));

      return;
    }

    dispatch(actions.fetchQuoteSuccess(quoteData));
    dispatch(actions.setLoading(false));

    if (quoteData.generatedQuotes[0] && quoteData.generatedQuotes[0].lastFollowUpDate) {
      dispatch(
        actions.updateLastFollowUpDate(
          getFormattedDate(quoteData.generatedQuotes[0].lastFollowUpDate, null, QUOTE_DATE_FORMAT),
        ),
      );
    } else {
      dispatch(actions.updateLastFollowUpDate(null));
    }

    return true;
  };

//actual method which is called from the components
export const updateInstantQuote =
  (customerInfo: CustomerInfo, quoteUuid: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    dispatch(actions.setLoading(true));
    try {
      await updateInstantQuoteRequest(customerInfo, quoteUuid);
    } catch (e) {
      let message = intl.formatMessage({ id: 'common.something_went_wrong' });

      if (e.response.data.code === 400 && e.response?.data.message) {
        message = e.response?.data.message; // TODO server side translation
      }
      const toastOptions = {
        toastType: 'error' as const,
        message,
      };

      dispatch(toastActions.open(toastOptions));
      dispatch(actions.quoteError(''));
      dispatch(actions.setLoading(false));

      return;
    }
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'quote.loaded' }),
    };
    dispatch(toastActions.open(toastOptions));
    dispatch(actions.setLoading(false));
  };

export const getQuoteInfo =
  (quoteId: string) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    dispatch(actions.setLoading(true));
    let quoteData;
    try {
      quoteData = (await getQuote(quoteId)).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'quote.error_fetching_quote' }),
      };
      if ('message' in e) {
        dispatch(actions.quoteError((e as { message: string }).message));
      }
      dispatch(actions.setLoading(false));
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.fetchQuoteSuccess(quoteData));
    dispatch(actions.setLoading(false));

    if (quoteData.generatedQuotes[0] && quoteData.generatedQuotes[0].lastFollowUpDate) {
      dispatch(
        actions.updateLastFollowUpDate(
          getFormattedDate(quoteData.generatedQuotes[0].lastFollowUpDate, null, QUOTE_DATE_FORMAT),
        ),
      );
    } else {
      dispatch(actions.updateLastFollowUpDate(null));
    }
  };

export const addFilesToQuote =
  (quoteUuid: string, files: File[], onProgress: ProgressHandler) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    const formData = new FormData();
    files.map((file) => {
      formData.append('file', file);
    });
    let savedFiles;
    try {
      savedFiles = (await addFileToQuoteRequest(quoteUuid, formData, onProgress)).data;
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'quote.attachments_file_save_error' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.addFiles(savedFiles));
  };

export const addFilesToQuotePublic =
  (args: {
    quoteUuid: string;
    files: File[];
    vendorSiteUrl: string;
    userSiteUrl?: string;
    onProgress: ProgressHandler;
  }) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    const formData = new FormData();
    args.files.map((file) => {
      formData.append('file', file);
    });
    let savedFiles;
    try {
      savedFiles = (
        await addFileToQuotePublicRequest(formData, omit(args, 'files'), args.onProgress)
      ).data;
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.addFiles(savedFiles));
  };

export const removeFileFromQuote =
  (quoteUuid: string, file: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await deleteFileFromQuoteRequest(quoteUuid, file);
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.error_removing_file' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.removeFile(file));
  };

export const removeFileFromQuotePublic =
  (args: Parameters<typeof deleteFileFromQuotePublicRequest>[0]) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    try {
      await deleteFileFromQuotePublicRequest(args);
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));

      return;
    }

    dispatch(actions.removeFile(args.fileUuid));
  };

export const sendQuoteDetailsEmail =
  (emailParam: SendQuoteDetailsEmail, generatedQuoteUuid: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    const errorKey = validateRecipients(emailParam.recipients);

    if (errorKey) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: errorKey }),
      };
      dispatch(toastActions.open(toastOptions));
      dispatch(actions.setLoading(false));

      return false;
    }

    dispatch(actions.setLoading(true));

    try {
      await sendQuoteDetailsEmailRequest(emailParam, generatedQuoteUuid);
    } catch (e) {
      dispatch(actions.setLoading(false));

      return false;
    }
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'email.sent' }),
    };
    dispatch(toastActions.open(toastOptions));
    dispatch(actions.setLoading(false));

    return true;
  };

export const sendQuoteDetailsEmailPublic =
  (
    emailParam: SendQuoteDetailsEmail,
    routeParams: { userSiteUrl?: string; vendorSiteUrl: string },
    generatedQuoteUuid: string,
  ) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    const errorKey = validateRecipients(emailParam.recipients);

    if (errorKey) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: errorKey }),
      };
      dispatch(toastActions.open(toastOptions));
      dispatch(actions.setLoading(false));

      return false;
    }

    dispatch(actions.setLoading(true));

    try {
      await sendQuoteDetailsEmailPublicRequest(emailParam, routeParams, generatedQuoteUuid);
    } catch (e) {
      dispatch(actions.setLoading(false));

      return false;
    }
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'email.sent' }),
    };
    dispatch(toastActions.open(toastOptions));
    dispatch(actions.setLoading(false));

    return true;
  };

export const previewQuoteDetailsEmail =
  (
    generatedQuoteUuid: string,
    templateUuid: string,
    selectedPO?: string[],
    emailList?: QuoteEmailType[],
  ) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    dispatch(actions.setLoading(true));
    let email;
    try {
      email = (
        await generateQuoteEmailByTemplateUuid(
          templateUuid,
          generatedQuoteUuid,
          selectedPO,
          emailList,
        )
      ).data;
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));

      return false;
    }

    if (email) {
      dispatch(
        actions.previewEmail({
          body: email.body,
          subject: email.subject,
          relatedTo: 'quoteDetails',
        }),
      );
    }
    dispatch(actions.setLoading(false));

    return true;
  };

export const sendQuoteFollowUps =
  (quoteData: SendQuoteFollowUpEmailsArgs) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    const validationErrorKeys = quoteData.quotes
      .map((q) => {
        return q.recipients ? validateRecipients(q.recipients) : null;
      })
      .filter(Boolean) as string[];
    let result;

    if (validationErrorKeys.length) {
      dispatch(
        toastActions.open({
          toastType: 'error',
          // todo need to handle all messages once we will be able to show several errors with toast
          message: intl.formatMessage({ id: validationErrorKeys[0] }),
        }),
      );

      return false;
    }
    dispatch(actions.setLoading(true));
    try {
      result = (await sendQuoteFollowUpEmails(quoteData)).data;
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));
      dispatch(actions.setLoading(false));

      return false;
    }
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'email.sent' }),
    };
    dispatch(toastActions.open(toastOptions));
    dispatch(actions.setLoading(false));
    dispatch(
      actions.updateLastFollowUpDate(
        getFormattedDate(result?.lastFollowUpDate, null, QUOTE_DATE_FORMAT),
      ),
    );

    return true;
  };

export const previewQuoteFollowUp =
  (quoteUuid: string, templateUuid: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    let templateData;
    dispatch(actions.setLoading(true));
    try {
      templateData = (await generateQuoteFollowUpEmailByTemplateUuid({ quoteUuid, templateUuid }))
        .data;
    } catch {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.something_went_wrong' }),
      };
      dispatch(toastActions.open(toastOptions));
      dispatch(actions.setLoading(false));

      return false;
    }

    dispatch(
      actions.previewEmail({
        subject: templateData.subject,
        body: templateData.body,
        relatedTo: 'quoteFollowup',
      }),
    );
    dispatch(actions.setLoading(false));

    return true;
  };
