import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  previewContainer: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    border: 0,
    width: '1200px',
    minHeight: '800px',
    maxWidth: '100%',
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(-4),
    top: theme.spacing(-4),
  },
}));

export default useStyles;
