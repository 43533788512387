import React from 'react';
import MaterialChip from '@material-ui/core/Chip';
import { useStyles } from './styles';
import { getApplicationMirStatusesMap } from '../../../constants/language';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { translateLabel } from '../../../utils/translateLabel';

type MirStatusPropsType = {
  status: MirItem['status'];
};

function MirStatus({ status }: MirStatusPropsType) {
  const intl = useIntl();
  const classes = useStyles();
  const mirSatusesMap = getApplicationMirStatusesMap(intl);
  const label = mirSatusesMap[status];
  if (!status || !label) {
    return null;
  }

  switch (status) {
    case 'Completed':
      return (
        <MaterialChip
          label={translateLabel(intl, label)}
          className={clsx(classes.chip, classes.completed)}
        />
      );
    case 'Incomplete':
      return (
        <MaterialChip
          label={translateLabel(intl, label)}
          className={clsx(classes.chip, classes.incomplete)}
        />
      );
    case 'Pending':
      return (
        <MaterialChip
          label={translateLabel(intl, label)}
          className={clsx(classes.chip, classes.pending)}
        />
      );
    default:
      return (
        <MaterialChip
          label={translateLabel(intl, label)}
          className={clsx(classes.chip, classes.pending)}
        />
      );
  }
}

export default MirStatus;
