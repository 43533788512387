import { connect, ConnectedProps } from 'react-redux';
import { actions } from '../../store/application';

import { actions as submittedApplicationActions } from '../../store/submittedApplication';
import SubmittedApplications from './submitted-applications.component';

const mapStateToProps = (state: AppStoreType) => ({
  vendorUuid: (state.user as UserBasedStoreType).vendorUuid,
  applicationList: state.application.applicationList,
  gridViews: state.submittedApplications.gridViews,
  selectedGridView: state.submittedApplications.selectedGridView,
  filterList: state.submittedApplications.filterList,
  columns: state.submittedApplications.columns,
  userRole: state.user.role,
});

const mapDispatchToProps = {
  fetchApplicationList: actions.fetchApplicationList,
  updateApplicationListById: actions.updateApplicationListById,
  getApplicationInfo: actions.getApplicationInfo,
  updateFilterList: submittedApplicationActions.updateFilterList,
  handleColumnsChange: submittedApplicationActions.updateColumnList,
  updateSelectedGridView: submittedApplicationActions.updateSelectedGridView,
  addCustomGridView: submittedApplicationActions.addCustomGridView,
  getGridViewList: submittedApplicationActions.getGridViewList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SubmittedApplicationsConnectedProps = ConnectedProps<typeof connector>;

export default connector(SubmittedApplications);
