import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone';
import React, { ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import useStyles from './styles';
import { Fade } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDispatch } from 'react-redux';
import { actions as toastActions } from '../../../store/toast';

type DropzoneContext = {
  setGetRootProps: (fn: DropzoneState['getRootProps']) => unknown;
  setDragActive: (flag: boolean) => unknown;
};

const DropzoneContext = React.createContext<DropzoneContext>({
  setGetRootProps: () => null,
  setDragActive: () => null,
});

type DropzonePropsType = {
  children: ReactNode;
};

export const Dropzone = ({ children }: DropzonePropsType) => {
  const classes = useStyles();
  const [getRootProps, setGetRootProps] = useState<DropzoneState['getRootProps']>();

  const [isDragActive, setDragActive] = useState(false);

  return (
    <DropzoneContext.Provider value={{ setGetRootProps, setDragActive }}>
      <div {...(getRootProps && { ...getRootProps() })}>
        {children}
        <Fade in={isDragActive} timeout={300} unmountOnExit>
          <div className={classes.dropzone}>
            <CloudUploadIcon className={classes.dropzoneIcon} />
          </div>
        </Fade>
      </div>
    </DropzoneContext.Provider>
  );
};

type WithDropzoneProps = {
  onDrop: DropzoneOptions['onDrop'];
};

export const useVaultDropzone = ({ onDrop }: WithDropzoneProps) => {
  const context = useContext(DropzoneContext);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { getRootProps, fileRejections, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: 'image/*,.pdf,.doc,.docx',
    validator: nameLengthValidator,
  });

  React.useEffect(() => {
    context.setGetRootProps((() => getRootProps) as DropzoneState['getRootProps']);
  }, []);

  React.useEffect(() => {
    context.setDragActive(isDragActive);
  }, [isDragActive]);

  React.useEffect(() => {
    if (fileRejections.length > 0) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'common.upload_file_hint' }),
      };
      dispatch(toastActions.open(toastOptions));
    }
  }, [fileRejections]);

  return {
    open,
    getInputProps,
  };
};

function nameLengthValidator(file: File) {
  if (file.name.length > 100) {
    return {
      code: 'name-too-large',
      message: `Name is larger than ${100} characters`,
    };
  }

  return null;
}
