import React from 'react';
import { Grid, IconButton, Divider } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useFormContext } from 'react-hook-form';
import AmountTextField from '../amount-text-field';
import useStyles from './styles';
import TextInput from '../TextInput';
import NumberInput from '../NumberInput';
import VaultMenu from '../VaultMenu';
import { useIntl } from 'react-intl';
import { frequencies, paymentOptions } from '../../../constants/formValues';
import { CURRENCY_TYPE } from '../../../constants/currency';

type EquipmentListFormPropsType = {
  currency: CURRENCY_TYPE;
  setCurrency: (c: CURRENCY_TYPE) => unknown;
  equipments: Equipment[];
  remove: (i: number) => unknown;
};

export default function EquipmentListForm({
  equipments,
  remove,
  setCurrency,
  currency,
}: EquipmentListFormPropsType) {
  const classes = useStyles();
  const intl = useIntl();
  const { setValue, getValues } = useFormContext();

  const setTotal = () => {
    const totalCost = (getValues('equipments') as Equipment[])?.reduce((acc, eq) => {
      return acc + +(eq.quantity ?? 1) * +(eq.cost ?? '0');
    }, 0);

    setValue('application.totalCost', totalCost, { shouldDirty: false, shouldValidate: false });
  };

  React.useEffect(() => {
    if (equipments.length) {
      setTotal();
    }
  }, [equipments.length]);

  const handleEquipmentChange = () => {
    setTotal();
  };

  const handleRemoveEquipment = async (index: number) => {
    remove(index);
    setTotal();
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <NumberInput
            name="application.term"
            label={intl.formatMessage({ id: 'application.lease_term' })}
            fullWidth
            inputProps={{ min: '0' }}
            allowNegative={false}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <VaultMenu
            name="application.frequency"
            label={intl.formatMessage({ id: 'application.frequency' })}
            fullWidth
            options={frequencies}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <VaultMenu
            name="application.endOfTermOption"
            label={intl.formatMessage({ id: 'application.end_of_term_option' })}
            fullWidth
            options={paymentOptions}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.container}>
        {equipments.map((equipment, index, array) => (
          <React.Fragment key={equipment.uuid ?? index}>
            <Grid item xs={12} sm={6}>
              <TextInput
                required
                name={`equipments[${index}].description`}
                label={intl.formatMessage({ id: 'equipment.equipment_description' })}
                defaultValue={equipment.description}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <NumberInput
                required
                name={`equipments[${index}].quantity`}
                label={intl.formatMessage({ id: 'equipment.quantity' })}
                fullWidth
                inputProps={{ min: '1' }}
                allowNegative={false}
                defaultValue={equipment.quantity}
                onBlur={handleEquipmentChange}
              />
            </Grid>
            <Grid item xs={12} sm>
              <AmountTextField
                required
                name={`equipments[${index}].cost`}
                label={intl.formatMessage({ id: 'equipment.cost' })}
                fullWidth
                currency={currency}
                defaultValue={equipment.cost || undefined}
                allowZero={false}
                setCurrency={setCurrency}
                onBlur={handleEquipmentChange}
              />
            </Grid>
            {array.length > 1 && (
              <Grid item xs className={classes.iconContainer}>
                <IconButton
                  className={classes.delete}
                  color="inherit"
                  component="span"
                  onClick={() => handleRemoveEquipment(index)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={9} sm={6} md={8} />
        <Grid item xs={12} sm={6} md={4}>
          <AmountTextField
            disabled
            label={intl.formatMessage({ id: 'equipment.total_cost' })}
            currency={currency}
            setCurrency={setCurrency}
            fixedDecimalScale
            name="application.totalCost"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}
