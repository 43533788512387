import React from 'react';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import Link from '../link';

const SectionHeader = (props: { sectionName: string; count: number; searchQuery: string }) => {
  const { sectionName, count, searchQuery } = props;
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.searchListTitle}>
      {intl.formatMessage({ id: `common.search_${sectionName}` })}
      <Link to={`/search-result/${encodeURI(searchQuery)}/${sectionName}`}>
        {count + ' ' + intl.formatMessage({ id: 'common.search_results' })}
      </Link>
    </div>
  );
};

export default SectionHeader;
