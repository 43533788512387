export enum DOCUMENT_SIGNATURE_STATUSES {
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
}

export enum DOCUMENT_SIGNATURE_ATTACHMENTS {
  VOID_CHEQUE = 'Void Cheque',
  DRIVER_LICENSE = 'Driver License',
}

export enum DOCUMENT_OVERRIDE_TYPES {
  INTERMEDIATE = 'intermediate',
  SIGNATURE = 'Signature',
  GENERATE = 'Generate',
  RE_GENERATE = 'Regenerate',
}

export enum DOCUMENT_SIGNER_ATTACHMENT_STATUSES {
  INCOMPLETE = 'INCOMPLETE',
  COMPLETE = 'COMPLETE',
}

export enum DOCUMENT_OVERRIDE_FORM_FIELD_TYPES {
  STRING = 'string',
  BOOLEAN = 'boolean',
  PHONE = 'phone',
  DATE = 'date',
  EMAIL = 'email',
  NUMBER = 'number',
  SIGNOR_TITLE = 'signor-title',
  PURCHASE_OPTION = 'purchase-option',
  PAYMENT_FREQUENCY = 'payment-frequency',
  LANGUAGE = 'language',
}

export enum DOCUMENT_OVERRIDE_FORM_SIGNOR_INVALID_AUTHORITY_TITLE_LIST {
  SALES_REP = 'Sales Rep',
  SALES_MANAGER = 'Sales Manager',
  IT_MANAGER = 'IT Manager',
}

export enum DOCUMENT_OVERRIDE_FORM_SIGNOR_INVALID_TITLE_LIST {
  OWNER = 'Owner',
  PRINCIPAL = 'Principal',
  SHAREHOLDER = 'Shareholder',
}

export enum DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST {
  END_10 = '$10 END',
  PERCENT_10 = '10%',
  FMV = 'FMV',
  TRAC = 'TRAC',
  RENTAL = 'Rental',
  STRAIGHT = 'Straight',
  OTHER = 'Other',
}

export const DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_VALUES = [
  {
    value: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.END_10,
    label: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.END_10,
  },
  {
    value: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.PERCENT_10,
    label: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.PERCENT_10,
  },
  {
    value: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.FMV,
    label: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.FMV,
  },
  {
    value: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.TRAC,
    label: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.TRAC,
  },
  {
    value: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.RENTAL,
    label: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.RENTAL,
  },
  {
    value: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.STRAIGHT,
    label: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.STRAIGHT,
  },
  {
    value: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.OTHER,
    label: DOCUMENT_OVERRIDE_FORM_PURCHASE_OPTIONS_LIST.OTHER,
  },
];

export enum DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST {
  EN = 'EN',
  FR = 'FR',
}

export const DOCUMENT_OVERRIDE_FORM_LANGUAGE_VALUES = [
  {
    value: DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST.EN,
    label: DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST.EN,
  },
  {
    value: DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST.FR,
    label: DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST.FR,
  },
];

export const ATTENTION_TO = 'attention to';
