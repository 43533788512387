import React from 'react';
import { useIntl } from 'react-intl';

export default function EmailRenderer(props: { value: string }) {
  const intl = useIntl();

  if (props.value === null)
    return (
      <div>
        <i>{intl.formatMessage({ id: 'common.no_email' })}</i>
      </div>
    );
  if (props.value === '')
    return (
      <div>
        <i>{intl.formatMessage({ id: 'common.empty_email_record' })}</i>
      </div>
    );
  return <>{props.value}</>;
}
