import React, { useContext } from 'react';
import { Container, Button, Grid } from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NewApplicationContext, { NewApplicationContextProps } from './new-application.context';
import useStyles from './styles';
import EquipmentListForm from '../../components/common/EquipmentListForm';
import { toFlatPropertyMap } from '../../utils/common';
import get from 'lodash/get';
import { CURRENCY_TYPE } from '../../constants/currency';

type EquipmentSectionPropsType = {
  defaultData: {
    equipments: Equipment[];
    endOfTermOption: string;
    term: number | null;
    frequency: Frequency;
    currency: CURRENCY_TYPE;
  };
};

export default function EquipmentSection({ defaultData }: EquipmentSectionPropsType) {
  const intl = useIntl();
  const classes = useStyles();

  const {
    control,
    getValues,
    setValue,
    formState: { dirtyFields },
    trigger,
  } = useFormContext();
  const { subscribeOnPageLeave, unSubscribeOnPageLeave, currency, changeCurrency } =
    useContext<NewApplicationContextProps>(NewApplicationContext);
  const {
    fields: equipments,
    append,
    remove,
  } = useFieldArray<Equipment, 'formId'>({
    control,
    name: 'equipments',
    keyName: 'formId',
  });

  React.useEffect(() => {
    if (defaultData.equipments.length) {
      setValue('equipments', []);
      defaultData.equipments.forEach((equipment) => {
        return append({
          currency: equipment.currency,
          quantity: equipment.quantity,
          cost: equipment.cost,
          description: equipment.description,
        });
      });
    }
    if (!defaultData.equipments?.length && !equipments.length) {
      // create default equipment
      addEquipment();
    }
    setValue('application.term', defaultData.term);
    setValue('application.endOfTermOption', defaultData.endOfTermOption);
    setValue('application.frequency', defaultData.frequency);
  }, []);

  const addEquipment = () => {
    append({
      currency,
      quantity: 1,
      cost: 0,
    });
  };

  const deleteEquipment = async (i: number) => {
    await remove(i);
    trigger();
  };

  const leaveListener = React.useCallback(() => {
    const flatKeys = Reflect.ownKeys(
      toFlatPropertyMap({
        equipments: dirtyFields?.equipments,
        application: dirtyFields?.application,
      }),
    );
    const data = getValues();
    const diff = flatKeys
      .map((key) => {
        if (
          ['application.totalCost', 'application', 'equipments', 'application.userUuid'].includes(
            key as string,
          )
        ) {
          return false;
        }
        let prevVal = get(defaultData, key);
        if ((key as string).startsWith('application.')) {
          const prop = (key as string).split('.')?.[1];
          prevVal = defaultData[prop as keyof typeof defaultData];
        }

        if (!prevVal) {
          prevVal = null;
        }
        const currentVal = get(data, key);
        if (
          (key === 'equipments.0.currency' && !defaultData.equipments.length) ||
          (key === 'equipments.0.cost' && !defaultData.equipments.length && currentVal === 0) ||
          (key === 'equipments.0.quantity' && !defaultData.equipments.length && currentVal === 1)
        ) {
          return null;
        }

        if ((key as string).endsWith('currency')) {
          return (defaultData.currency || null) !== (currency || null);
        }

        return prevVal != (currentVal || null) ? key : null;
      })
      .filter(Boolean);

    return !!diff.length;
  }, [dirtyFields, defaultData, getValues, currency]);

  React.useEffect(() => {
    subscribeOnPageLeave(leaveListener);
    return () => {
      unSubscribeOnPageLeave(leaveListener);
    };
  }, [leaveListener]);

  return (
    <Container className={classes.container}>
      <EquipmentListForm
        currency={currency}
        setCurrency={changeCurrency}
        equipments={equipments}
        remove={deleteEquipment}
      />
      <div className={classes.liftEquipmentBtn}>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <Button
              data-cy="add-equipment"
              fullWidth
              variant="outlined"
              color="primary"
              onClick={addEquipment}
            >
              + {intl.formatMessage({ id: 'button.add_equipment' })}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
