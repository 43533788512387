import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  controlButtonsWrap: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down(959)]: {
      paddingTop: '5px',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: '10px',
    },
  },
  changeStatusButton: {
    padding: '5px',
    height: '36px',
    margin: '2px 0 2px 0',
    minWidth: '117px',
  },
  loginAsButton: {
    padding: '5px',
    height: '36px',
    margin: '2px 0 2px 15px',
    minWidth: '170px',
  },
  resetPassButton: {
    padding: '5px',
    height: '36px',
    margin: '2px 0 2px 0',
    minWidth: '156px',
    marginRight: '16px',
  },
  resetPassButtonAligned: {
    marginRight: '0',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '71px',
    alignItems: 'center',
    padding: '0 24px',
    boxShadow: '0px 1px #eaeaea',
  },
  userNameWrap: {
    width: 'auto',
    overflowWrap: 'break-word',
    fontWeight: 600,
    marginRight: '9px',
  },
  userInfoWrapper: {
    paddingTop: '24px',
    width: '100%',
    padding: '0 24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  userInfoTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: '#666666',
  },
  userInfoText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000000',
  },
  cardContentWrapper: {
    padding: 0,
  },
  userStatusWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
}));

export default useStyles;
