import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { actions as toastActions } from '../toast';
import { initialState } from './initialState';
import { getIntl } from '../../utils/language';
import * as applicationApi from '../../apis/application';

const slice = createSlice({
  name: 'applicationDocuments',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    resetDocumentsList: () => initialState,
    updateDocumentsList: (state, action: PayloadAction<ApplicationDocumentType[]>) => {
      state.documents = action.payload;
      state.activeDocument = state.documents[0] ?? null;
      state.isLoading = false;
    },
    updateDocument: (state, action: PayloadAction<ApplicationDocumentType>) => {
      state.documents = [
        action.payload,
        ...state.documents.filter((d: ApplicationDocumentType) => {
          return d.id !== action.payload.id;
        }),
      ];
      state.activeDocument = action.payload;
      state.isLoading = false;
    },
    deleteDocument: (state, action: PayloadAction<string>) => {
      state.documents = state.documents.filter((d: ApplicationDocumentType) => {
        return d.id !== action.payload;
      });
      state.activeDocument = state.documents[0] ?? null;
      state.isLoading = false;
    },
    changeActiveDocument: (state, action: PayloadAction<string | null>) => {
      state.activeDocument =
        state.documents.find((d: ApplicationDocumentType) => {
          return d.id === action.payload;
        }) ?? null;
    },
  },
});

const getApplicationDocument = (applicationUuid: string, documentUuid: string) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStoreType,
) => {
  const intl = getIntl(getState);
  let document = null;

  try {
    dispatch(slice.actions.startLoading());
    document = (await applicationApi.getApplicationDocument(applicationUuid, documentUuid)).data;
  } catch (error) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'common.something_went_wrong' }),
    };
    dispatch(toastActions.open(toastOptions));
  }

  if (document) {
    dispatch(slice.actions.updateDocument(document));
  } else {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'application.document_not_found' }),
    };
    dispatch(toastActions.open(toastOptions));
  }

  return document;
};

const getApplicationDocuments = (applicationUuid: string) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStoreType,
) => {
  const intl = getIntl(getState);
  dispatch(slice.actions.startLoading());
  let documents;

  try {
    documents = (await applicationApi.getApplicationDocuments(applicationUuid)).data;
  } catch (error) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'common.something_went_wrong' }),
    };
    dispatch(toastActions.open(toastOptions));
  }

  if (!documents) {
    return;
  }

  dispatch(slice.actions.updateDocumentsList(documents));
};

const getApplicationDocumentFile = (applicationUuid: string, documentUuid: string) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStoreType,
) => {
  const intl = getIntl(getState);
  let file = null;

  try {
    file = (await applicationApi.getApplicationDocumentFile(applicationUuid, documentUuid)).data;
  } catch (error) {
    const errorData = error.response?.data;
    if (errorData?.code === 401) {
      return (location.href = `/sign-in?redirect=application/${applicationUuid}`);
    }
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'common.something_went_wrong' }),
    };
    dispatch(toastActions.open(toastOptions));
  }

  return file;
};

const generateApplicationDocument = (
  applicationUuid: string,
  documentData: ApplicationDocumentData,
) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
  const intl = getIntl(getState);
  dispatch(slice.actions.startLoading());

  try {
    const document = (
      await applicationApi.generateApplicationDocument(applicationUuid, documentData)
    ).data;

    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'application.document_created' }),
    };
    dispatch(toastActions.open(toastOptions));

    return document;
  } catch (error) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'common.something_went_wrong' }),
    };
    dispatch(toastActions.open(toastOptions));
  }
};

const signApplicationDocument = (
  applicationUuid: string,
  documentUuid: string,
  attachments: Array<ApplicationDocumentSignerAttachment>,
) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
  dispatch(slice.actions.startLoading());
  const intl = getIntl(getState);

  try {
    const document = (
      await applicationApi.signApplicationDocument(applicationUuid, documentUuid, attachments)
    ).data;
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'application.signature_request_sent' }),
    };
    dispatch(toastActions.open(toastOptions));

    return document;
  } catch (error) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'common.something_went_wrong' }),
    };
    dispatch(toastActions.open(toastOptions));
  }
};

const sendApplicationDocument = (
  applicationUuid: string,
  documentUuid: string,
  data: ApplicationDocumentEmailFormData,
) => async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
  const intl = getIntl(getState);
  dispatch(slice.actions.startLoading());

  try {
    await applicationApi.sendApplicationDocument(applicationUuid, documentUuid, data);
    const document = (await applicationApi.getApplicationDocument(applicationUuid, documentUuid))
      .data;

    if (document) {
      dispatch(slice.actions.updateDocument(document));
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'application.document_sent' }),
      };
      dispatch(toastActions.open(toastOptions));
    }

    return document;
  } catch (error) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'common.something_went_wrong' }),
    };
    dispatch(toastActions.open(toastOptions));
  }
};

const deleteApplicationDocument = (applicationUuid: string, documentUuid: string) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStoreType,
) => {
  const intl = getIntl(getState);

  try {
    await applicationApi.deleteApplicationDocument(applicationUuid, documentUuid);
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'application.document_successfully_deleted' }),
    };
    dispatch(toastActions.open(toastOptions));
    dispatch(slice.actions.deleteDocument(documentUuid));
    return true;
  } catch (error) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'common.something_went_wrong' }),
    };
    dispatch(toastActions.open(toastOptions));
    return false;
  }
};

const cancelSignApplicationDocument = (applicationUuid: string, documentUuid: string) => async (
  dispatch: Dispatch<unknown>,
  getState: () => AppStoreType,
) => {
  const intl = getIntl(getState);
  dispatch(slice.actions.startLoading());

  try {
    await applicationApi.cancelSignApplicationDocument(applicationUuid, documentUuid);
    const document = (await applicationApi.getApplicationDocument(applicationUuid, documentUuid))
      .data;

    if (document) {
      dispatch(slice.actions.updateDocument(document));
      const toastOptions = {
        toastType: 'success' as const,
        message: intl.formatMessage({ id: 'application.document_sign_successfully_canceled' }),
      };
      dispatch(toastActions.open(toastOptions));
    }
    return true;
  } catch (error) {
    const toastOptions = {
      toastType: 'error' as const,
      message: intl.formatMessage({ id: 'common.something_went_wrong' }),
    };
    dispatch(toastActions.open(toastOptions));
    return false;
  }
};

const changeActiveDocumentId = (documentUuid: string | null) => async (
  dispatch: Dispatch<unknown>,
) => {
  if (documentUuid) {
    dispatch(slice.actions.changeActiveDocument(documentUuid));
  } else {
    dispatch(slice.actions.changeActiveDocument(null));
  }
};

const cleanDocumentState = (documentUuid: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(slice.actions.deleteDocument(documentUuid));
};

export const actions = {
  ...slice.actions,
  getApplicationDocuments,
  getApplicationDocument,
  getApplicationDocumentFile,
  generateApplicationDocument,
  signApplicationDocument,
  sendApplicationDocument,
  deleteApplicationDocument,
  cancelSignApplicationDocument,
  changeActiveDocumentId,
  cleanDocumentState,
};
export default slice.reducer;
