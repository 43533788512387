import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  documentRoot: {
    backgroundColor: theme.palette.background.paper,
    width: '100%' + theme.spacing(3),
    margin: theme.spacing(-3, -3, -3),
    padding: '15px',
  },
  notAvailableContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  notAvailableIcon: {
    width: '136px',
    height: '80px',
  },
}));

export { useStyles };
