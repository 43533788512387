import { AgGridColumn } from 'ag-grid-react';
import { ascendingSort, localeCompareSort } from '../../../utils/common';
import React from 'react';
import { HeaderComponent } from '../VpDataGrid/components/HeaderComponent';

type RenderColumnProps = {
  column: GridViewColumnType;
  title: string;
};

export const RenderColumn = (props: RenderColumnProps) => {
  const { column, title } = props;
  const headerParams = { name: column.label };

  switch (column.field) {
    case 'idLabel':
      return (
        <AgGridColumn
          key="idLabel"
          sortable={column.sortable}
          filter={column.filter}
          field="idLabel"
          headerName="ID"
          headerComponentParams={headerParams}
          headerComponentFramework={HeaderComponent}
          width={column.width}
          cellRenderer="idLabelRenderer"
          comparator={ascendingSort}
        />
      );
    case 'applicationName':
      return (
        <AgGridColumn
          key="applicationName"
          sortable={column.sortable}
          filter={column.filter}
          field="Application Name"
          width={column.width}
          cellRenderer="applicationNameRenderer"
          comparator={ascendingSort}
          headerComponentParams={headerParams}
          headerComponentFramework={HeaderComponent}
        />
      );
    case 'contractId':
      return (
        <AgGridColumn
          key="contractId"
          sortable
          filter
          field="contractId"
          width={column.width}
          cellRenderer="contractIdRenderer"
          comparator={localeCompareSort}
          headerComponentParams={headerParams}
          headerComponentFramework={HeaderComponent}
        />
      );
    case 'status':
      return (
        <AgGridColumn
          key="status"
          sortable
          filter
          field="status"
          cellRenderer="statusRenderer"
          comparator={ascendingSort}
          width={column.width}
          minWidth={column.width || 100}
          headerComponentParams={headerParams}
          headerComponentFramework={HeaderComponent}
        />
      );
    case 'idLabel2':
      return (
        <AgGridColumn
          key={title}
          field={title}
          cellRenderer="linkPagePath2Renderer"
          comparator={ascendingSort}
          headerComponentParams={headerParams}
          headerComponentFramework={HeaderComponent}
        />
      );
    case 'files':
      return (
        <AgGridColumn
          key="files"
          field="files"
          width={column.width}
          cellRenderer="filesRenderer"
          comparator={ascendingSort}
          headerComponentParams={headerParams}
          headerComponentFramework={HeaderComponent}
        />
      );
    case 'equipmentCost':
      return (
        <AgGridColumn
          key={column.field}
          field={column.field}
          width={column.width}
          cellRenderer="currencyRenderer"
          comparator={ascendingSort}
          headerComponentParams={headerParams}
          headerComponentFramework={HeaderComponent}
        />
      );
    case 'createdAt':
    case 'lastLogin':
    case 'updatedAt':
      return (
        <AgGridColumn
          key={column.field}
          hide={column.hidden}
          field={column.field}
          sortable
          filter
          headerComponentFramework={HeaderComponent}
          headerComponentParams={headerParams}
          width={column.width || 170}
          minWidth={170}
          cellRenderer="dateFieldRenderer"
          comparator={ascendingSort}
        />
      );
    default:
      return (
        <AgGridColumn
          hide={column.hidden}
          field={column.field}
          key={column.field}
          sortable
          filter
          checkboxSelection={column.checkboxSelection}
          headerCheckboxSelection={column.checkboxSelection}
          headerCheckboxSelectionFilteredOnly={column.checkboxSelection}
          headerComponentParams={headerParams}
          headerComponentFramework={HeaderComponent}
          width={column.width}
          minWidth={column.width || 100}
          flex={column.flex ? 1 : 0}
          suppressMovable={column.suppressMovable}
          suppressMenu={column.suppressMenu}
          lockPosition={column.lockPosition}
          comparator={ascendingSort}
        />
      );
  }
};
