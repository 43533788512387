import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function SubmittedApplicationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M20 11.83C19.7348 11.83 19.4804 11.9354 19.2929 12.1229C19.1054 12.3104 19 12.5648 19 12.83V18.4C19 18.5591 18.9368 18.7117 18.8243 18.8243C18.7117 18.9368 18.5591 19 18.4 19H5.6C5.44087 19 5.28826 18.9368 5.17574 18.8243C5.06321 18.7117 5 18.5591 5 18.4V5.6C5 5.44087 5.06321 5.28826 5.17574 5.17574C5.28826 5.06321 5.44087 5 5.6 5H15.17C15.4352 5 15.6896 4.89464 15.8771 4.70711C16.0646 4.51957 16.17 4.26522 16.17 4C16.17 3.73478 16.0646 3.48043 15.8771 3.29289C15.6896 3.10536 15.4352 3 15.17 3H5.6C4.91125 3.00263 4.25146 3.27741 3.76443 3.76443C3.27741 4.25146 3.00263 4.91125 3 5.6V18.4C3.00263 19.0888 3.27741 19.7485 3.76443 20.2356C4.25146 20.7226 4.91125 20.9974 5.6 21H18.4C19.0888 20.9974 19.7485 20.7226 20.2356 20.2356C20.7226 19.7485 20.9974 19.0888 21 18.4V12.83C21 12.5648 20.8946 12.3104 20.7071 12.1229C20.5196 11.9354 20.2652 11.83 20 11.83Z" />
      <path d="M10.72 10.9998C10.5323 10.8311 10.2878 10.7395 10.0354 10.7435C9.78308 10.7475 9.54158 10.8468 9.35936 11.0214C9.17715 11.196 9.0677 11.4331 9.05297 11.685C9.03825 11.937 9.11933 12.1852 9.27996 12.3798L11.5 14.7098C11.5929 14.8073 11.7046 14.885 11.8283 14.9383C11.952 14.9916 12.0853 15.0193 12.22 15.0198C12.3539 15.0206 12.4867 14.9944 12.6104 14.9429C12.7341 14.8914 12.8462 14.8155 12.94 14.7198L19.72 7.71982C19.8119 7.62526 19.8843 7.51353 19.933 7.391C19.9818 7.26847 20.0059 7.13753 20.004 7.00567C20.0022 6.87382 19.9744 6.74361 19.9222 6.6225C19.87 6.50139 19.7945 6.39174 19.7 6.29982C19.6054 6.20789 19.4937 6.13549 19.3711 6.08674C19.2486 6.038 19.1177 6.01387 18.9858 6.01572C18.854 6.01758 18.7238 6.04539 18.6026 6.09757C18.4815 6.14974 18.3719 6.22526 18.28 6.31982L12.23 12.5798L10.72 10.9998Z" />
    </SvgIcon>
  );
}
