import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  formControl: {
    textAlign: 'left',
    margin: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
      minWidth: 120,
    },
    '& label': {
      maxWidth: 'calc(100% - 40px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    width: '100%',
  },
  formTitleWrapper: {
    marginBottom: '24px',
  },
  formWrapper: {
    padding: '12px',
  },
  controlButtons: {
    margin: '0 10px',
  },
  controlButtonsWrapper: {
    marginBottom: '18px',
  },
  extraPadding: {
    paddingTop: '26px',
    paddingBottom: '46px',
  },
  checkboxItem: {
    marginBottom: '14px',
  },
  centeredCheckbox: {
    '& label': {
      textAlign: 'left',
      width: '100%',
      color: '#000000',
      fontSize: '17px',
      fontWeight: 'normal',
      '& .MuiButtonBase-root': {
        padding: 0,
        marginRight: '10px',
      },
    },
  },
}));

export default useStyles;
