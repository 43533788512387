import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  modalFullWIdth: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1200px',
    },
  },
  tableWrapper: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '750px',
      width: 'auto',
    },
  },
  emailPreview: {
    '& a': {
      pointerEvents: 'none',
    },
  },
}));

export { useStyles };
