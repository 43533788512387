import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './store';

export default function configureAppStore() {
  const store = configureStore({
    reducer: rootReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: {
          // it increased from default 32, if it will not be enough we probably need to investigate the issues
          warnAfter: 70,
        },
      }),
  });

  return { store };
}
