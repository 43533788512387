import React, { Dispatch, SetStateAction, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Paper } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import SearchBar from '../DataGrid/search-bar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

type ApplicationDocumentTemplateFormPropsType = {
  templates: OverrideDocumentTemplate[];
  formTemplates: ApplicationDocumentTemplate[];
  setFormTemplates: Dispatch<SetStateAction<ApplicationDocumentTemplate[]>>;
  error?: boolean;
};

type TemplateVisibilityType = {
  label: string;
  visibility: boolean;
};

export default function ApplicationDocumentTemplateForm(
  props: ApplicationDocumentTemplateFormPropsType,
) {
  const { templates, formTemplates, setFormTemplates } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [searchText, setSearchText] = useState('');
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [allTemplates, setAllTemplates] = useState<ApplicationDocumentTemplate[]>([]);
  const [templateVisibilities, setTemplateVisibilities] = useState<Array<TemplateVisibilityType>>(
    [],
  );

  React.useEffect(() => {
    if (templates.length) {
      const overridableTemplates = [];

      for (const template of templates) {
        if (
          !formTemplates.find((formTemplate) => {
            return formTemplate.alias === template.template_info.developer_name;
          })
        ) {
          overridableTemplates.push({
            title: template.template_info.label,
            path: template.template_info.workflow_path,
            alias: template.template_info.developer_name,
          });
        }
      }

      setAllTemplates(overridableTemplates);
      defineTemplateVisibility();
    }
  }, []);

  React.useEffect(() => {
    defineTemplateVisibility();
  }, [searchText]);

  const defineTemplateVisibility = () => {
    const allVisibility: Array<TemplateVisibilityType> = [];

    for (const t of templates) {
      if (t.template_info.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
        allVisibility.push({
          label: t.template_info.label,
          visibility: true,
        });
      } else {
        allVisibility.push({
          label: t.template_info.label,
          visibility: false,
        });
      }
    }

    setTemplateVisibilities(allVisibility);
  };

  const onChange = (
    value: string,
    setIsSearchResult: Dispatch<boolean>,
    setSearchText: Dispatch<string>,
  ) => {
    setSearchText(value);
    setIsSearchResult(true);
  };

  const onCancel = (setIsSearchResult: Dispatch<boolean>, setSearchText: Dispatch<string>) => {
    setIsSearchResult(false);
    setSearchText('');
  };

  const onCheckAllTemplates = (template: ApplicationDocumentTemplate, checked: boolean) => {
    let updated = [];

    if (checked) {
      updated = [...formTemplates, template];
    } else {
      updated = formTemplates.filter((checkedTemplate: ApplicationDocumentTemplate) => {
        return checkedTemplate.alias !== template.alias;
      });
    }

    setFormTemplates(updated);
  };

  const checkAllTemplates = (checked: boolean) => {
    if (checked) {
      setFormTemplates(allTemplates);
    } else {
      setFormTemplates([]);
    }
  };

  return (
    <div className={classes.templateForm}>
      <Grid container>
        <Grid item xs={12}>
          <SearchBar
            searchText={searchText}
            searchLabel={intl.formatMessage({ id: 'common.type_to_search' })}
            onChange={(value) => onChange(value, setIsSearchResult, setSearchText)}
            isSearching={isSearchResult}
            onCancel={() => onCancel(setIsSearchResult, setSearchText)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} data-cy="available-templates">
          <Typography variant="h3" className={classes.title}>
            {intl.formatMessage({ id: 'application.available_templates' })}
          </Typography>
          <Paper variant="outlined" className={classes.templateContainer}>
            <FormControlLabel
              className={clsx(classes.templateItem, classes.mainItem)}
              label={intl.formatMessage({ id: 'application.template_name' })}
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  onChange={(e) => checkAllTemplates(e.target.checked)}
                />
              }
            />
            {allTemplates.map((template, index) => (
              <FormControlLabel
                key={index}
                className={
                  templateVisibilities.find(
                    (templateVisibility) => templateVisibility.label === template.title,
                  )?.visibility
                    ? classes.templateItem
                    : clsx(classes.templateItem, classes.hideTemplate)
                }
                label={template.title}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={
                      !!formTemplates.find((formTemplate) => formTemplate.title === template.title)
                    }
                    onChange={(e) => onCheckAllTemplates(template, e.target.checked)}
                  />
                }
              />
            ))}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
