import React, { ComponentProps } from 'react';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import get from 'lodash/get';

type AreaCodeFormatterPropsType = Omit<ComponentProps<typeof Controller>, 'as'>;

export default function AreaCodeFormatter({
  name,
  defaultValue,
  disabled,
  ...props
}: AreaCodeFormatterPropsType) {
  const { control, errors } = useFormContext();
  const error = get(errors, name);

  return (
    <Controller
      {...props}
      id={name}
      as={
        <NumberFormat
          customInput={TextField}
          format="###"
          mask="_"
          disabled={disabled}
          onValueChange={(input) => {
            return (
              input.value.length === 3 &&
              setTimeout(() => document.getElementById(props.phoneformatter.id)?.focus(), 100)
            );
          }}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      error={Boolean(error)}
      helperText={error?.message}
    />
  );
}
