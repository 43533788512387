import React, { useState, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import useStyles from './styles';
import { Paper, Grid } from '@material-ui/core';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import InstantQuoteForm from '../../components/common/InstantQuoteForm';
import QuoteTable from '../../components/common/QuoteTable';
import Loading from '../../components/common/Loading';
import { useEffect } from 'react';
import { RECIPIENT_TYPE } from '../../components/common/QuoteEmail';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { InstantQuoteConnectedPropsType } from './instant-quote.public.container';
import { QUOTE_TO_APPLICATION_PAGE } from '../../constants/navigations';
import { CURRENCY_TYPE, defaultCurrency } from '../../constants/currency';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';

export type InstantQuoteFormType = {
  [key in keyof QuoteType | 'emailTemplate']: string;
};

function InstantQuotePublic({
  loading,
  quoteInfo,
  generateInstantQuotePublic,
  getAllEmailTemplates,
  getVendorSettingsPublic,
  generatedQuotes,
  vendorProgramList,
  getDefaultVendorProgram,
  getVendorPublicRep,
  vendorReps,
  isSiteDisabled,
}: InstantQuoteConnectedPropsType) {
  const params = useParams<{ vendorSiteUrl: string; userSiteUrl?: string }>();
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const quoteHtmlData = useRef();
  const [currency, setCurrency] = useState<CURRENCY_TYPE>(defaultCurrency.name as CURRENCY_TYPE);
  const [lastGeneratedQuote, setLastGeneratedQuote] = React.useState<
    GetInstantQuoteResponse['generatedQuotes'][number] | null
  >(null);

  const formMethods = useForm<InstantQuoteFormType & { equipmentCost: number }>({
    defaultValues: {},
  });
  const { handleSubmit, setValue, getValues, reset } = formMethods;

  const resetQuote = () => {
    const values = getValues();

    return reset({ vendorRep: values.vendorRep });
  };

  useEffect(() => {
    getAllEmailTemplates({ relatedToObj: ['quoteDetails'], routeParams: params });
    getVendorSettingsPublic(params);
    getDefaultVendorProgram(params);
    getVendorPublicRep(params);
  }, []);

  useEffect(() => {
    const lastGeneratedQuoteFromList = generatedQuotes[0];
    if (lastGeneratedQuoteFromList) {
      setLastGeneratedQuote(lastGeneratedQuoteFromList);
    } else {
      setLastGeneratedQuote(null);
    }
  }, [quoteInfo, generatedQuotes]);

  useEffect(() => {
    const keys = Object.keys(quoteInfo ?? {}) as Array<keyof QuoteType>;
    keys.forEach((key) => {
      setValue(key, (quoteInfo as QuoteType)[key]);
    });
  }, [quoteInfo, setValue]);

  useEffect(() => {
    setValue('vendorProgram', vendorProgramList[0]?.value);
  }, [vendorProgramList, setValue]);

  useEffect(() => {
    if (vendorReps.length) {
      setValue('vendorRep', vendorReps[0]?.value);
    }
  }, [vendorReps, setValue]);

  useEffect(() => {
    //Populate email list with customer's email
    if (quoteInfo) {
      const emails = [];
      const customerEmail = {
        uuid: uuidv4(),
        email: quoteInfo.customerEmail,
        name: `${quoteInfo.firstName} ${quoteInfo.middleName} ${quoteInfo.lastName}`,
        isSelected: true,
        type: RECIPIENT_TYPE.TO,
        isRemovable: true,
      };
      emails.push(customerEmail);
    }
  }, [quoteInfo]);

  const handlePaymentOptionSelection = async (selectedPaymentOption: { uuid: string }) => {
    const publicPrefix = params.userSiteUrl
      ? `/${params.vendorSiteUrl}/${params.userSiteUrl}`
      : `/${params.vendorSiteUrl}`;
    history.push(
      publicPrefix +
        generatePath(QUOTE_TO_APPLICATION_PAGE.path, {
          paymentOptionUuid: selectedPaymentOption.uuid,
        }),
    );
  };

  const handleSubmitQuote = async (values: InstantQuoteFormType) => {
    const vendorProgram = values.vendorProgram || vendorProgramList[0]?.value || null;

    const newQuoteInfo = {
      ...values,
      vendorProgram,
      equipmentCostCurrencyType: currency,
    } as PublicGenerateQuoteRequest;

    // creating new quote
    await generateInstantQuotePublic(newQuoteInfo, params.vendorSiteUrl, params.userSiteUrl);
  };

  return (
    <>
      {isSiteDisabled && (
        <div className={classes.textAlignCenter}>
          {intl.formatMessage({ id: 'quote.site_is_not_active' })}
        </div>
      )}

      {!isSiteDisabled && (
        <FormProvider {...formMethods}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={8}>
              <Typography variant="h5" className={classes.headerTitle}>
                {intl.formatMessage({ id: 'common.instant_quote' })}
              </Typography>
              <Paper className={classes.paper} variant="outlined">
                <form onSubmit={handleSubmit(handleSubmitQuote)}>
                  <InstantQuoteForm
                    isAdmin={false}
                    vendorProgramList={vendorProgramList}
                    vendorReps={vendorReps}
                    currency={currency}
                    setCurrency={setCurrency}
                    reset={resetQuote}
                    isReadOnly={false}
                    isPublic
                  />
                </form>
              </Paper>

              {loading && (
                <Paper className={clsx(classes.paper, classes.smallMarginTop)}>
                  <Loading isOpen={loading} />
                </Paper>
              )}

              {lastGeneratedQuote && (
                <Paper
                  data-cy="quote-table"
                  className={clsx(classes.paper, classes.smallMarginTop)}
                  ref={quoteHtmlData}
                >
                  <QuoteTable
                    title={intl.formatMessage({ id: 'quote.quote_table' })}
                    tagline={intl.formatMessage({ id: 'quote.please_select_purchase_option' })}
                    terms={JSON.parse(lastGeneratedQuote.terms)}
                    purchaseOptions={JSON.parse(lastGeneratedQuote.purchaseOptions)}
                    skipPayment={lastGeneratedQuote.skipAmount}
                    skipMonth={lastGeneratedQuote.skipMonth}
                    quotePaymentOptions={lastGeneratedQuote.quotePaymentOptions}
                    selectedPaymentOptions={[]}
                    handleQuoteDataUpdate={handlePaymentOptionSelection}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  );
}

export default InstantQuotePublic;
