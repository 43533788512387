import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const defaultLanguage = () => {
  const queryString: URLSearchParams = new URLSearchParams(window.location.search);
  const navigatorLang = queryString.get('lang')?.toUpperCase() || navigator.language.toUpperCase();

  return navigatorLang.indexOf('EN') === -1 ? navigatorLang : 'EN';
};

const initialState: SettingsStoreType = {
  currentLanguage: defaultLanguage() as Languages,
  isSidebarOpened: false,
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<Languages>) => {
      state.currentLanguage = action.payload;
    },
    changeSidebarStatus: (state, action: PayloadAction<SettingsStoreType['isSidebarOpened']>) => {
      state.isSidebarOpened = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
