import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menu: {
    marginTop: '50px',
  },
  smallText: {
    fontSize: 14,
  },
  menuLink: {
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  dropdownWrapper: {
    '& .MuiPaper-root': {
      width: '300px',
    },
  },
  labelText: {
    fontSize: '14px',
    paddingLeft: '10px',
    width: 'calc(100% - 10px)',
    whiteSpace: 'normal',
  },
}));

export default useStyles;
