import { combineReducers } from 'redux';
import user from './user';
import instantQuote from './instantQuote';
import application from './application';
import toast from './toast';
import formValues from './formValues';
import submittedApplications from './submittedApplication';
import newApplication from './newApplication';
import file from './file';
import userOnEdit from './userOnEdit';
import applicationAmendments from './applicationAmendments';
import applicationDocuments from './applicationDocuments';
import vendorSettings from './vendorSettings';
import quoteToApplication from './quoteToApplication';
import contacts from './contacts';
import settings from './settings';
import globalSearch from './globalSearch';
import emailTemplateList from './emailTemplatesList';

const reducer = () =>
  combineReducers({
    formValues,
    user,
    toast,
    instantQuote,
    application,
    submittedApplications,
    newApplication,
    file,
    userOnEdit,
    applicationAmendments,
    applicationDocuments,
    vendorSettings,
    quoteToApplication,
    contacts,
    settings,
    globalSearch,
    emailTemplateList,
  });

export default reducer;
