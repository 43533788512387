import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import { useApplicationInputs } from '../ApplicationEntityForm/inputs';

type ConsentFormPropsType = {
  title?: string;
  path: string;
};

export default function ConsentForm({ title, path }: ConsentFormPropsType) {
  const { ConsentConfirmationInput } = useApplicationInputs(path);
  const classes = useStyles();
  const intl = useIntl();

  return (
    <React.Fragment>
      <Container className={classes.container}>
        {title && (
          <Typography data-cy="consent-title" variant="h2" gutterBottom>
            {title}
          </Typography>
        )}
        <Grid item xs={12}>
          <Typography data-cy="consent-text">
            {intl.formatMessage({ id: 'consent.message' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ConsentConfirmationInput />
        </Grid>
      </Container>
    </React.Fragment>
  );
}
