import React from 'react';
import { generatePath } from 'react-router-dom';
import toPairs from 'lodash/toPairs';
import { Link } from 'react-router-dom';

export default function ButtonLink(props: {
  title?: string;
  path: string;
  value: string;
  pathParams?: { [param: string]: string };
  queryParams?: { [param: string]: string };
  data?: { [key: string]: string | number };
}) {
  const pathParams: Parameters<typeof generatePath>[1] = {};
  if (props.pathParams) {
    Reflect.ownKeys(props.pathParams).forEach((key) => {
      if (props.pathParams?.[key as string]) {
        pathParams[key as string] = props.data?.[props.pathParams?.[key as string]];
      }
    });
  }
  let linkPath = props.data ? generatePath(props.path, pathParams) : '';

  if (props.queryParams) {
    const query = toPairs(props.queryParams)
      .map((a) => {
        return a[0] + '=' + props.data?.[a[1]];
      })
      .join('&');
    linkPath += '?' + query;
  }

  if (!linkPath) {
    return '  ';
  }

  return (
    <Link to={linkPath} color="primary">
      {props.title || props.value}
    </Link>
  );
}
