import { connect, ConnectedProps } from 'react-redux';

import { actions as vendorSettingsActions } from '../../store/vendorSettings';
import VendorWithUsersList from './vendor-with-users-list.component';

const mapStateToProps = (state: AppStoreType) => ({
  vendor: state.vendorSettings.vendor,
  brokerLogo: state.contacts.broker?.logo,
});

const mapDispatchToProps = {
  getVendorById: vendorSettingsActions.getVendorById,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VendorWithUsersListConnectedProps = ConnectedProps<typeof connector>;

export default connector(VendorWithUsersList);
