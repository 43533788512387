import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  mainTitle: {
    paddingLeft: theme.spacing(1),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  actionsContainer: {
    textAlign: 'right',
  },
  rightSpacing: {
    marginRight: theme.spacing(2),
  },
  button: {
    padding: 0,
    justifyContent: 'flex-start',
  },
  applicationNameButton: {
    padding: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
  },
  alpineThemeContainer: {
    height: 570,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .ag-header-cell-resize::after': {
      backgroundColor: 'transparent',
    },
    '& .ag-header-row:not(:first-child) .ag-header-cell': {
      borderTop: 'none',
    },
  },
  borderRadius: {
    borderRadius: '4px',
  },
  tableHolder: {
    height: '100%',
    '& .ag-body-viewport div[unselectable="on"]': {
      userSelect: 'text',
    },
  },
  MuiDataGrid: {
    backgroundColor: 'red',
  },
  cancelFilter: {
    display: 'flex',
    cursor: 'pointer',
  },
  pointer: {
    cursor: 'pointer',
  },
  fullWidth: {
    width: '100%',
  },
  filterInputDefault: {
    width: '100%',
    fontSize: '11px',
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '5px',
  },
  filterSelectDefault: {
    fontSize: '11px',
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '5px',
    width: '100%',
    minWidth: '120px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  withoutPadding: {
    padding: 0,
  },
  popover: {
    height: '200px',
  },
  dateLabel: {
    fontSize: '11px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  dateContainer: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px',
  },
  dateRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    height: '200px',
  },
  applyContainer: {
    textAlign: 'center',
    paddingTop: '3px',
    marginTop: '10px',
    borderTop: '1px solid #DEE2EA',
  },
  selectAllCheckboxAlignment: {
    marginLeft: '-13px',
  },
}));

export default useStyles;
