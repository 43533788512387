import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GlobalSearchListConnectedProps } from './search-result.container';
import {
  AppBar,
  Grid,
  Tab,
  Tabs,
  Paper,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SearchAllResults from './search-all-results';
import { Sections } from '../../constants/search';
import clsx from 'clsx';
import debounce from 'lodash/debounce';

function SearchResult(props: GlobalSearchListConnectedProps) {
  const { fetchSearchQuery, cleanState, loading, searchCount, globalSearchList } = props;

  const [selectedSection, setSelectedSection] = useState(Sections.allResults as string);
  const [searchQuery, setSearchQuery] = useState('');

  const urlSearchQuery = decodeURI(useParams<{ searchQuery: string }>().searchQuery || '');
  const urlSearchSection = useParams<{ searchSection: string }>().searchSection;
  const classes = useStyles();
  const intl = useIntl();
  const desktopQuery = useMediaQuery('(max-width: 700px)');
  const isSearchResults = searchCount ? true : false;

  const getCountBySection = (section: SearchSection) => globalSearchList[section].count;

  const handleSwitchSection = (_: unknown, newValue: string) => {
    setSelectedSection(newValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    debounceFetchSearchQuery(event.target.value);
  };

  const debounceFetchSearchQuery = useCallback(
    debounce((query) => {
      query.length > 2 ? fetchSearchQuery(query) : cleanState();
    }, 300),
    [],
  );

  const debounceDelayedResponse = () => !loading && searchQuery.length <= 2 && cleanState();

  useEffect(() => {
    debounceDelayedResponse();
  }, [loading, searchQuery]);

  useEffect(() => {
    setSelectedSection(Sections.allResults);
    if (urlSearchSection) setSelectedSection(urlSearchSection);
    if (urlSearchQuery) {
      setSearchQuery(urlSearchQuery);
      debounceFetchSearchQuery(urlSearchQuery);
    }
  }, []);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={12} md={10} xs={12}>
          <h3 data-cy="search-results-header" className={classes.titleH3}>
            {searchQuery
              ? `${intl.formatMessage({ id: 'common.search_results_for' })} “${searchQuery}”`
              : intl.formatMessage({ id: 'common.search_search' })}
          </h3>
          <div
            data-cy="results-found"
            className={clsx(classes.resultsFound, {
              [classes.resultsFoundOpaque]: !searchQuery,
            })}>
            {searchCount + ' ' + intl.formatMessage({ id: 'common.search_results_found' })}
          </div>
          <Paper className={classes.paper} variant="outlined">
            <OutlinedInput
              data-cy="search-input"
              className={classes.searchInput}
              placeholder={intl.formatMessage({ id: 'grid.search' })}
              endAdornment={
                <InputAdornment position="end">
                  {loading ? <CircularProgress size={24} /> : <SearchIcon />}
                </InputAdornment>
              }
              onChange={handleChange}
              value={searchQuery}
            />
            {isSearchResults && (
              <>
                <AppBar position="static" color="transparent" elevation={0}>
                  <Tabs
                    data-cy="tabs"
                    className={classes.scrollButtons}
                    value={selectedSection}
                    onChange={handleSwitchSection}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={desktopQuery ? 'scrollable' : 'fullWidth'}
                    scrollButtons="on"
                    aria-label={intl.formatMessage({
                      id: 'common.search_results_for',
                    })}>
                    <Tab
                      data-cy="search-all-results-tab"
                      value={Sections.allResults}
                      label={intl.formatMessage({ id: 'common.search_all_results' })}
                      id={`full-width-tab-${0}`}
                      aria-controls={`full-width-tabpanel-${0}`}
                      classes={{
                        root: classes.tabRoot,
                      }}
                    />
                    {getCountBySection(Sections.quotes) && (
                      <Tab
                        data-cy="search-quotes-tab"
                        value={Sections.quotes}
                        label={intl.formatMessage({ id: 'common.search_quotes' })}
                        id={`full-width-tab-${1}`}
                        aria-controls={`full-width-tabpanel-${1}`}
                        classes={{
                          root: classes.tabRoot,
                        }}
                      />
                    )}
                    {getCountBySection(Sections.submittedApplications) && (
                      <Tab
                        data-cy="search-submitted-apps-tab"
                        value={Sections.submittedApplications}
                        label={intl.formatMessage({ id: 'common.search_submittedApplications' })}
                        id={`full-width-tab-${2}`}
                        aria-controls={`full-width-tabpanel-${2}`}
                        classes={{
                          root: classes.tabRoot,
                        }}
                      />
                    )}
                    {getCountBySection(Sections.draftApplications) && (
                      <Tab
                        data-cy="search-draft-apps-tab"
                        value={Sections.draftApplications}
                        label={intl.formatMessage({ id: 'common.search_draftApplications' })}
                        id={`full-width-tab-${3}`}
                        aria-controls={`full-width-tabpanel-${3}`}
                        classes={{
                          root: classes.tabRoot,
                        }}
                      />
                    )}
                    {getCountBySection(Sections.users) && (
                      <Tab
                        data-cy="search-users"
                        value={Sections.users}
                        label={intl.formatMessage({ id: 'common.search_users' })}
                        id={`full-width-tab-${4}`}
                        aria-controls={`full-width-tabpanel-${4}`}
                        classes={{
                          root: classes.tabRoot,
                        }}
                      />
                    )}
                  </Tabs>
                </AppBar>
                <div>
                  <SearchAllResults searchQuery={searchQuery} selectedTab={selectedSection} />
                </div>
              </>
            )}
            {!isSearchResults && searchQuery.length > 2 && !loading && (
              <h3 data-cy="no-search-results">
                {intl.formatMessage({ id: 'common.search_no_results_1' })}
              </h3>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default SearchResult;
