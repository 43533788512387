import { connect, ConnectedProps } from 'react-redux';

import { actions } from '../../store/instantQuote';
const {
  generateInstantQuotePublic,
  cleanState,
  removeFileFromQuotePublic,
  sendQuoteDetailsEmailPublic,
  previewQuoteDetailsEmail,
  addFilesToQuotePublic,
} = actions;
import { actions as formValuesActions } from '../../store/formValues';
import { actions as toastActions } from '../../store/toast';

import InstantQuotePublic from './instant-quote.public.component';

const mapStateToProps = (state: AppStoreType) => ({
  loading: state.instantQuote.loading,
  error: state.instantQuote.error,
  quoteInfo: state.instantQuote.quoteInfo,
  generatedQuotes: state.instantQuote.generatedQuotes,
  files: state.instantQuote.files,
  emailPreview: state.instantQuote.emailPreview,
  emailTemplates: state.formValues.emailTemplateList,
  vendorSetting: state.formValues.vendorSetting,
  vendorProgramList: state.formValues.vendorProgramList,
  vendorReps: state.formValues.vendorReps,
  isSiteDisabled: state.formValues.isSiteDisabled,
});

const mapDispatchToProps = {
  generateInstantQuotePublic,
  addFilesToQuotePublic,
  getAllEmailTemplates: formValuesActions.getAllEmailTemplates,
  updateEmailTemplateList: formValuesActions.updateEmailTemplateList,
  getVendorSettingsPublic: formValuesActions.getVendorSettingsPublic,
  getDefaultVendorProgram: formValuesActions.getDefaultVendorProgram,
  getVendorPublicRep: formValuesActions.getVendorPublicRep,
  openToast: toastActions.open,
  cleanState,
  removeFileFromQuotePublic,
  sendQuoteDetailsEmailPublic,
  previewQuoteDetailsEmail,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type InstantQuoteConnectedPropsType = ConnectedProps<typeof connector>;

export default connector(InstantQuotePublic);
