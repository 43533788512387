import React from 'react';
import Paper from '@material-ui/core/Paper';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Loading';
import { useHistory, useParams } from 'react-router-dom';
import { getPublicRoute } from '../../../utils/common';
import { INSTANT_NEW_QUOTE_PAGE, NEW_APPLICATION_PAGE } from '../../../constants/navigations';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import { actions as quoteActions } from '../../../store/instantQuote';

type HappyEndProps = {
  text: string;
  header: string;
  isPublicPage: boolean;
  loading?: boolean;
  fromApplication?: boolean;
};

export default function HappyEnd({
  loading,
  header,
  text,
  isPublicPage,
  fromApplication,
}: HappyEndProps) {
  const styles = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ vendorSiteUrl: string; userSiteUrl?: string }>();
  const contacts = useSelector((state: AppStoreType) => state.contacts);
  const userRole = useSelector<AppStoreType, AppStoreType['user']['role']>(
    (state) => state.user.role,
  );

  let name;
  let email;

  if (userRole && contacts.broker) {
    name = contacts.broker?.name;
    email = contacts.broker?.email;
  } else if (contacts.vendorRep) {
    name = `${contacts.vendorRep.firstName} ${contacts.vendorRep.lastName}`;
    email = contacts.vendorRep.email;
  } else {
    name = contacts.vendor?.name;
    email = contacts.vendor?.email;
  }

  const linkToQuote = getPublicRoute(INSTANT_NEW_QUOTE_PAGE.path, params);
  const linkToNewApplication = getPublicRoute(NEW_APPLICATION_PAGE.path, params);

  const navigateToInstantQuote = () => {
    dispatch(quoteActions.cleanState());
    history.push(linkToQuote);
  };
  const navigateToNewApplication = async () => {
    if (document.location.href === linkToNewApplication) document.location.reload();
    document.location.href = linkToNewApplication;
  };

  return (
    <div data-cy="application-submission-modal" className={styles.root}>
      <Loading isOpen={!!loading} />
      <Paper className={styles.paper}>
        <CheckCircleOutlineIcon fontSize="large" style={{ color: 'green' }} />
        <h2 data-cy="header" className={styles.textCenter}>
          {header}
        </h2>
        <h3 data-cy="text" className={styles.textCenter}>
          {text}
        </h3>
        <p>
          <b data-cy="rep-info">{name}</b>
          <span>&nbsp;:&nbsp;</span>
          <a data-cy="rep-email" href={`mailto:${email}`}>
            {email}
          </a>
        </p>
        <div className={styles.formButtons}>
          {isPublicPage && !fromApplication && (
            <Button
              data-cy="create-another-quote-btn"
              color="primary"
              variant="contained"
              onClick={navigateToInstantQuote}
            >
              {intl.formatMessage({ id: 'application.create_quote' })}
            </Button>
          )}
          {isPublicPage && (
            <Button
              data-cy="create-another-application-btn"
              color="primary"
              variant="contained"
              onClick={navigateToNewApplication}
            >
              {intl.formatMessage({ id: 'application.create_new_application' })}
            </Button>
          )}
        </div>
      </Paper>
    </div>
  );
}
