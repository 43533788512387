import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import EmailIcon from '@material-ui/icons/Email';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useIntl } from 'react-intl';

export default function EmailTemplateDialog(props) {
  const { onClose, selectedValue, open, templateList } = props;
  const intl = useIntl();

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {intl.formatMessage({ id: 'common.select_email_template' })}
      </DialogTitle>
      <List>
        {templateList.map((template) => (
          <ListItem button onClick={() => handleListItemClick(template)} key={template.value}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={intl.formatMessage({ id: template.label })} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
