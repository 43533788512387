import React from 'react';
import { fetchUserData } from '../store/user';
import AppWithDrawer from '../components/app';
import { useDispatch, useSelector } from 'react-redux';
import { actions as contactsActions } from '../store/contacts';
import { Backdrop } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { initWS } from '../services/websockets';

export default function AuthRoutes({ children }: { children?: React.ReactNode }) {
  const user = useSelector<AppStoreType, AppStoreType['user']>((state) => state.user);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  React.useEffect(() => {
    if (user.type === 'user' && user.vendorUuid) {
      dispatch(contactsActions.loadContacts(user.vendorUuid));
    } else if (user.type === 'brokerRep' && user.brokerUuid) {
      dispatch(contactsActions.loadBrokerContacts(user.brokerUuid));
    }
  }, [(user as UserBasedStoreType).vendorUuid, (user as BrokerRepUserStoreType).brokerUuid]);

  if (!Object.keys(user).length) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    initWS();
  }

  return <AppWithDrawer type={user.type}>{children}</AppWithDrawer>;
}
