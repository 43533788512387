import { useFormContext } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

export default function UploadProgress({ uuid }: { uuid: string }) {
  const { watch } = useFormContext();

  const progress: number = watch(uuid);
  const buffer: number = watch(uuid + '-buffer');

  if (!progress && !buffer) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="buffer" value={progress ?? 0} valueBuffer={buffer || 0} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${(
          progress || 0
        ).toFixed()}%`}</Typography>
      </Box>
    </Box>
  );
}
