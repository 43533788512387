import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Grid, TextField, Tooltip } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import { UseFormMethods } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import {
  DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST,
  DOCUMENT_OVERRIDE_FORM_SIGNOR_INVALID_AUTHORITY_TITLE_LIST,
  DOCUMENT_OVERRIDE_FORM_SIGNOR_INVALID_TITLE_LIST,
} from '../../../constants/document';
import VaultMenu from '../VaultMenu';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import WarningIcon from '@material-ui/icons/Warning';
import { getMessages } from '../../../utils/language';
import { translateLabel } from '../../../utils/translateLabel';

type ApplicationDocumentOverrideFormTitleFieldPropsType = {
  index: string;
  field: ApplicationDocumentOverridableFields;
  originalValue: string | number | null | boolean;
  value: string | null;
  onChangeField: (
    path: string,
    value: string,
    control?: UseFormMethods['control'],
    name?: string,
    isDateField?: boolean,
  ) => void;
  register: ReturnType<typeof useForm>['register'];
  documentLanguage: DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST;
};

export default function ApplicationDocumentOverrideFormTitleField({
  index,
  field,
  originalValue,
  value,
  onChangeField,
  register,
  documentLanguage,
}: ApplicationDocumentOverrideFormTitleFieldPropsType) {
  const classes = useStyles();
  const intl = useIntl();
  const { setValue, errors } = useFormContext();
  const error = get(errors, field.path);
  const [isDifferent, setIsDifferent] = useState<boolean>(false);
  const [hideSignorTitle, setHideSignorTitle] = useState<boolean>(true);
  const [customTitleValue, setCustomTitleValue] = useState<string>('');
  const [helperText, setHelperText] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<string>();

  const titles = useMemo(() => {
    const translationMessagesByFormLanguage = getMessages(documentLanguage);

    return {
      PRESIDENT_OF_CORPORATE:
        translationMessagesByFormLanguage['document.purchase_president_for_corporate'],
      CEO_FOR_CORPORATE: translationMessagesByFormLanguage['document.purchase_ceo_for_corporate'],
      PARTNER: translationMessagesByFormLanguage['document.purchase_partner_for_partnership'],
      GENERAL_PARTNER: translationMessagesByFormLanguage['document.purchase_general_partner'],
      SECRETARY: translationMessagesByFormLanguage['document.purchase_secretary'],
      EXECUTIVE_DIRECTOR: translationMessagesByFormLanguage['document.purchase_executive_director'],
      VICE_PRESIDENT: translationMessagesByFormLanguage['document.purchase_vice_president'],
      CONTROLLER: translationMessagesByFormLanguage['document.purchase_controller'],
      OTHER: translationMessagesByFormLanguage['document.purchase_other'],
    };
  }, [documentLanguage]);

  const availableValues = useMemo(() => {
    return Object.values(titles).map((column) => ({
      label: column,
      value: column,
    }));
  }, [titles]);

  useEffect(() => {
    setIsDifferent(checkDifferent(value, originalValue));

    if (value) {
      const saveValue = (Object.keys(titles) as (keyof typeof titles)[]).find(
        (key) => titles[key] === value,
      );
      setSelectedValue(saveValue);

      if (Object.values<string>(titles).includes(value)) {
        setValue(field.path, value);
        setCustomTitleValue('');
        setHideSignorTitle(true);
      } else {
        setValue(field.path, titles.OTHER);
        setCustomTitleValue(value);
        setHideSignorTitle(false);
      }
    } else {
      setValue(field.path, undefined);
      setCustomTitleValue('');
      setHideSignorTitle(true);
    }
  }, []);

  useEffect(() => {
    if (selectedValue) {
      const oldValue = (Object.keys(titles) as (keyof typeof titles)[]).find(
        (key) => key === selectedValue,
      );

      if (oldValue) {
        setValue(field.path, titles[oldValue]);
        onChangeField(field.path, titles[oldValue]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentLanguage]);

  const onChange = useCallback(
    (path: string, newValue: string) => {
      if (newValue === titles.OTHER) {
        setIsDifferent(checkDifferent(customTitleValue, originalValue));
      } else {
        setIsDifferent(checkDifferent(newValue, originalValue));
      }

      const saveValue = (Object.keys(titles) as (keyof typeof titles)[]).find(
        (key) => titles[key] === newValue,
      );
      setSelectedValue(saveValue);

      onChangeField(path, newValue);
      setHelperText('');

      if (newValue === titles.OTHER) {
        setCustomTitleValue('');
        setHideSignorTitle(false);
      } else {
        setHideSignorTitle(true);
      }
    },
    [onChangeField, titles, documentLanguage],
  );

  const onChangeSignorTitle = useCallback(
    (path: string, newValue: string) => {
      if (newValue === '') {
        setHelperText('');
      }

      setIsDifferent(checkDifferent(newValue, originalValue));
      setCustomTitleValue(newValue);
      onChangeField(path, newValue);
    },
    [onChangeField],
  );

  const checkDifferent = (
    value: string | number | boolean | null,
    originalValue: string | number | boolean | null,
  ) => {
    if (value === '' && originalValue === null) {
      return false;
    }

    return value !== originalValue;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={hideSignorTitle ? 12 : 6} className={classes.fieldGroup}>
          <VaultMenu
            className={classes.signorSelector}
            id={`${index}`}
            name={field.path}
            label={translateLabel(intl, field.path, field.label)}
            required={field.required}
            value={value ?? undefined}
            defaultValue={value ?? ''}
            options={availableValues}
            onChange={(e) => {
              setValue(field.path, e.target.value);
              onChange(field.path, e.target.value as string);
            }}
          />
          {hideSignorTitle && isDifferent && (
            <Tooltip
              title={`${intl.formatMessage({
                id: 'application.original_value',
              })}: ${originalValue}`}
              placement="top"
            >
              <WarningIcon className={classes.warningSelectIcon} />
            </Tooltip>
          )}
        </Grid>
        {!hideSignorTitle && (
          <Grid item xs={12} sm={6} className={classes.fieldGroup}>
            <TextField
              className={classes.signorElement}
              name={`signor-${index}`}
              defaultValue={customTitleValue}
              label={intl.formatMessage({ id: 'application.custom_title' })}
              error={Boolean(error)}
              helperText={error?.message}
              required={field.required}
              inputRef={register({
                required: true,
                validate: (titleValue: string) => {
                  if (!titleValue) {
                    return false;
                  }

                  if (
                    Object.values<string>(
                      DOCUMENT_OVERRIDE_FORM_SIGNOR_INVALID_AUTHORITY_TITLE_LIST,
                    ).some((item) => item.toLowerCase() === titleValue.toLowerCase()) ||
                    Object.values<string>(DOCUMENT_OVERRIDE_FORM_SIGNOR_INVALID_TITLE_LIST).some(
                      (item) => item.toLowerCase() === titleValue.toLowerCase(),
                    )
                  ) {
                    setHelperText(
                      intl.formatMessage({ id: 'application.entered_title_incorrect' }),
                    );
                    return false;
                  }

                  return undefined;
                },
              })}
              onChange={(e) => onChangeSignorTitle(field.path, e.target.value as string)}
              fullWidth
            />
            {isDifferent && (
              <Tooltip
                title={`${intl.formatMessage({
                  id: 'application.original_value',
                })}: ${originalValue}`}
                placement="top"
              >
                <WarningIcon className={classes.warningIcon} />
              </Tooltip>
            )}
            <span className={classes.helperText}>{helperText}</span>
          </Grid>
        )}
      </Grid>
    </>
  );
}
