import React from 'react';
import { Paper, Grid, Button, Typography } from '@material-ui/core';
import Loading from '../../components/common/Loading';
import useStyles from './styles';
import { QuoteToApplicationConnectedPropsType } from './quote-to-application.container';
import { generatePath, useHistory } from 'react-router-dom';
import {
  EDIT_DRAFT_APPLICATION_PAGE,
  INSTANT_NEW_QUOTE_PAGE,
  INSTANT_QUOTE_PAGE,
} from '../../constants/navigations';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useIntl } from 'react-intl';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useSelector } from 'react-redux';
import CurrencyFormat from '../../components/common/currency-format';

function QuoteToApplication({
  selectPaymentOption,
  loading,
  getPaymentOption,
  paymentOption,
  createApplicationFromQuote,
  associatedApplicationUuid,
  fetchAssociatedApplicationUuid,
  fetchPaymentOption,
}: QuoteToApplicationConnectedPropsType) {
  const [appExistError, setAppExistError] = React.useState(false);
  const [quoteError, setQuoteError] = React.useState(false);
  const brokerInfo = useSelector((state: AppStoreType) => state.contacts.broker);
  const history = useHistory();
  const intl = useIntl();

  const { paymentOptionUuid, userSiteUrl, vendorSiteUrl } = useParams<{
    userSiteUrl?: string;
    vendorSiteUrl: string;
    paymentOptionUuid: string;
  }>();
  const isPublic = Boolean(userSiteUrl || vendorSiteUrl);

  React.useEffect(
    () => () => {
      fetchPaymentOption(null);
      fetchAssociatedApplicationUuid(null);
    },
    [],
  );

  React.useEffect(() => {
    if (associatedApplicationUuid && !isPublic) {
      const url = generatePath(EDIT_DRAFT_APPLICATION_PAGE.path, {
        applicationUuid: associatedApplicationUuid,
      });
      fetchAssociatedApplicationUuid(null);

      history.push(url);
    } else if (associatedApplicationUuid && isPublic) {
      const publicPrefix = userSiteUrl ? `/${vendorSiteUrl}/${userSiteUrl}` : `/${vendorSiteUrl}`;
      const url = generatePath(publicPrefix + EDIT_DRAFT_APPLICATION_PAGE.path, {
        applicationUuid: associatedApplicationUuid,
      });
      const lang = intl.locale === 'FR' ? 'fr' : 'en';
      fetchAssociatedApplicationUuid(null);

      history.push(`${url}?lang=${lang}`);
    }
  }, [associatedApplicationUuid]);

  useEffect(() => {
    getPaymentOption(paymentOptionUuid).then((hasOption) => {
      if (!hasOption) {
        setQuoteError(true);
      }
    });
  }, [paymentOptionUuid]);

  useEffect(() => {
    if (isPublic && paymentOption?.hasApplication) {
      setAppExistError(true);
    }
  }, [isPublic, paymentOption?.hasApplication]);

  const handleCreateApplication = async () => {
    const poResponse = await selectPaymentOption(paymentOptionUuid);
    if (poResponse.code === 616) {
      setAppExistError(true);
    } else if (poResponse.code === 607 || poResponse.code === 608) {
      // quote not found or wrong quote status
      setQuoteError(true);
    } else if (paymentOption) {
      await createApplicationFromQuote(
        paymentOption?.quoteUuid,
        { vendorSiteUrl, userSiteUrl },
        intl.locale.toLowerCase(),
      );
    }
  };

  const handleCancel = () => {
    if (!isPublic) {
      const url = generatePath(INSTANT_QUOTE_PAGE.path, {
        id: paymentOption?.quoteUuid,
      });
      history.push(url);
    } else {
      const publicPrefix = userSiteUrl ? `/${vendorSiteUrl}/${userSiteUrl}` : `/${vendorSiteUrl}`;
      const url = generatePath(publicPrefix + INSTANT_NEW_QUOTE_PAGE.path);

      history.push(url);
    }
  };
  const classes = useStyles();

  return (
    <Dialog open>
      {loading && (
        <Paper>
          <Loading isOpen={loading} />
        </Paper>
      )}
      {appExistError && (
        <div className={classes.errorRoot}>
          <ErrorOutlineIcon fontSize="large" color="error" />
          <h2>{intl.formatMessage({ id: 'decline.app_processed' })}</h2>
          <br />
          <p>{intl.formatMessage({ id: 'decline.contact' })}</p>
          <p>
            {brokerInfo?.name} : {brokerInfo?.email}
          </p>
        </div>
      )}
      {quoteError && (
        <div className={classes.errorRoot}>
          <ErrorOutlineIcon fontSize="large" color="error" />
          <h2>{intl.formatMessage({ id: 'decline.app_failed_with_quote' })}</h2>
          <br />
          <p>{intl.formatMessage({ id: 'decline.app_failed_contact' })}</p>
          <p>
            {brokerInfo?.name} : {brokerInfo?.email}
          </p>
        </div>
      )}
      {paymentOption && !appExistError && !quoteError && (
        <div className={classes.root}>
          <Paper
            data-cy="quote-to-application-modal"
            elevation={3}
            className={classes.quoteToApplicationPaperContainer}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8} md={8} lg={6}>
                    <Typography data-cy="quote-pound" variant="h6" gutterBottom>
                      {intl.formatMessage({ id: 'quote.quote' })}#
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6}>
                    <Typography data-cy="quote-label" variant="h6" gutterBottom>
                      {paymentOption.quoteIdLabel}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8} md={8} lg={6}>
                    <Typography data-cy="payment-option-title" variant="h6" gutterBottom>
                      {intl.formatMessage({ id: 'application.selected_payment_option' })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="caption" gutterBottom>
                          {paymentOption.term
                            ? intl.formatMessage({ id: 'application.term' })
                            : null}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography data-cy="application-term" variant="h6" gutterBottom>
                          {paymentOption.term}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography data-cy="application-amount" variant="caption" gutterBottom>
                          {paymentOption.amount
                            ? intl.formatMessage({ id: 'common.amount' })
                            : null}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography data-cy="application-currency-type" variant="h6" gutterBottom>
                          <CurrencyFormat
                            value={paymentOption?.amount}
                            currencyType={paymentOption?.generatedQuoteCurrencyType}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="caption" gutterBottom>
                          {paymentOption.purchaseOption
                            ? intl.formatMessage({ id: 'quote.option' })
                            : null}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography data-cy="application-purchase-option" variant="h6" gutterBottom>
                          {paymentOption.purchaseOption}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    data-cy="cancel-button"
                    variant="contained"
                    className={classes.button}
                    onClick={handleCancel}
                  >
                    {intl.formatMessage({ id: 'button.cancel' })}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    data-cy="start-application-button"
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={handleCreateApplication}
                  >
                    {intl.formatMessage({ id: 'button.start_application' })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
    </Dialog>
  );
}

export default QuoteToApplication;
