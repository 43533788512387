import { connect, ConnectedProps } from 'react-redux';

import CreateUser from './create-user.component';
import { actions as vendorSettingsActions } from '../../store/vendorSettings';

const mapStateToProps = (state: AppStoreType) => ({
  userRole: state.user.role,
  vendorsList: state.vendorSettings.vendorsList,
});

const mapDispatchToProps = {
  fetchVendorsList: vendorSettingsActions.fetchVendorsList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CreateUserConnectedProps = ConnectedProps<typeof connector>;

export default connector(CreateUser);
