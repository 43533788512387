import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { MyProfileConnectedProps } from './my-profile.container';
import EditUserForm from '../../components/common/edit-user-form';
import { updateUserProfile } from '../../store/user';
import UserHeaderCard from '../../components/common/user-header-card';

function MyProfile(props: MyProfileConnectedProps) {
  const { user, resetUserPass } = props;

  const dispatch = useDispatch();
  const formMethods = useForm<UserType>({
    defaultValues: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneAreaCode: user.phoneAreaCode,
      userSiteUrl: user.userSiteUrl,
      userSiteApplicationUrl: user.userSiteApplicationUrl,
      phone: user.phone,
      phoneExt: user.phoneExt,
      mobileAreaCode: user.mobileAreaCode,
      mobilePhone: user.mobilePhone,
      siteActive: user.siteActive,
      siteApplicationActive: user.siteApplicationActive,
      defaultLanguage: 'EN',
      replyTo: user.replyTo,
    },
  });
  const { handleSubmit, getValues, setValue } = formMethods;

  const handleSaveUser = async () => {
    const values = getValues();
    dispatch(
      updateUserProfile({
        ...values,
        phoneAreaCode: values.phoneAreaCode || null,
        phone: values.phone || null,
        phoneExt: values.phoneExt || null,
        mobileAreaCode: values.mobileAreaCode || null,
        mobilePhone: values.mobilePhone || null,
        replyTo: values.replyTo || '',
      }),
    );
  };

  const handleResetUserPassword = () => {
    if (user.type === 'user') {
      resetUserPass(user.email, 'vendorRep');
    } else {
      resetUserPass(user.email, 'brokerRep');
    }
  };

  useEffect(() => {
    Object.entries(user).forEach(([key, value]) => setValue(key as keyof UserType, value));
  }, [user]);

  if (user.type !== 'user') {
    return null;
  }

  const { firstName, lastName, vendor, lastLogin, active, status, statusColor, updatedAt } = user;
  const statusObject = {
    status,
    color: statusColor,
  };

  return (
    <FormProvider {...formMethods}>
      <UserHeaderCard
        firstName={firstName}
        lastName={lastName}
        vendorName={vendor.companyName}
        lastLogin={lastLogin}
        createdAt={lastLogin}
        handleResetPass={handleResetUserPassword}
        active={active}
        status={statusObject}
        statusColor={statusColor}
        isUserManagement={false}
        updatedAt={updatedAt}
      />
      <EditUserForm
        // need to pass userSiteUrl to trigger component rerender
        userSiteUrl={user.userSiteUrl}
        vendorSiteUrl={user.vendor.siteUrl}
        handleSubmit={handleSubmit}
        handleSaveUser={handleSaveUser}
        myProfile
      />
    </FormProvider>
  );
}

export default MyProfile;
