import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    padding: '40px',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  formButtons: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      marginLeft: '5px',
      marginRight: '5px',
    },
  },
});
