import { isPublicRoute } from './common';
import { lsGetApplicationAccessToken } from './localStorage';

export const getUrl = (url: string, isPublic = false) => {
  if (isPublic) {
    return `/public${url}`;
  }
  return url;
};

export const getHeaders = (routeParams: PublicRouteParams, applicationUuid?: string | null) => {
  const isPublic = isPublicRoute(routeParams);
  if (isPublic) {
    return {
      'vendor-site-url': routeParams.vendorSiteUrl,
      'user-site-url': routeParams.userSiteUrl ?? '',
      'access-token': lsGetApplicationAccessToken(applicationUuid ?? ''),
    };
  }
  return {};
};
