import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dropdown: {
    width: '100%',
  },
  label: {
    fontSize: '0.5rem',
    paddingBottom: '0.3rem',
  },
}));
export default useStyles;
