import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  templateForm: {},
  templateActions: {
    textAlign: 'center',
    '& > button': {
      marginBottom: theme.spacing(1),
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    fontSize: '14px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,.6)',
  },
  templateContainer: {
    marginBottom: theme.spacing(2),
    height: '200px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      backgroundColor: '#fff',
      width: '16px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      borderRadius: '16px',
      border: '4px solid #fff',
    },
  },
  hideTemplate: {
    display: 'none !important',
  },
  templateItem: {
    display: 'block',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: 0,
    borderBottom: '1px solid #DEE2EA',
    '&:last-child': {
      borderBottom: 0,
    },
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'middle',
      fontSize: '0.875rem',
    },
  },
  mainItem: {
    borderBottom: '1px solid #BBBFC6',
    '&:last-child': {
      borderBottom: 0,
    },
    '& > span': {
      fontWeight: 'bold',
    },
  },
  sortButtons: {
    float: 'right',
    marginTop: theme.spacing(0.5),
  },
}));

export default useStyles;
