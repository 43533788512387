import { GridOptions } from '@ag-grid-community/all-modules';
import { useIntl } from 'react-intl';
import { commonColumnTypes } from './common';
import { EMAIL_TEMPLATE_PAGE } from '../../../../constants/navigations';
import {
  getEmailTemplateRelatedToMap,
  getEmailTemplateTypesMap,
} from '../../../../constants/language';
import { HeaderComponent } from '../components/HeaderComponent';

export const useEmailTemplatesColumnTypes = () => {
  const intl = useIntl();

  const columnTypes: GridOptions['columnTypes'] = {
    'emailTemplates:name': {
      ...commonColumnTypes['common:button'],
      field: 'name',
      minWidth: 350,
      width: 350,
      headerComponentParams: { name: 'application.email_templates.name' },
      headerComponentFramework: HeaderComponent,
      cellRendererParams: {
        path: EMAIL_TEMPLATE_PAGE.path,
        pathParams: {
          emailTemplateUuid: 'uuid',
        },
      },
    },
    'emailTemplates:companyName': {
      ...commonColumnTypes['common:text'],
      field: 'companyName',
      width: 150,
      headerComponentParams: { name: 'email_templates.companyName' },
      headerComponentFramework: HeaderComponent,
    },
    'emailTemplates:relatedTo': {
      ...commonColumnTypes['common:status'],
      field: 'relatedTo',
      width: 150,
      headerComponentParams: { name: 'email_templates.relatedTo' },
      headerComponentFramework: HeaderComponent,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        statusesMap: getEmailTemplateRelatedToMap(intl),
      },
    },
    'emailTemplates:active': {
      ...commonColumnTypes['common:state'],
      field: 'active',
      width: 50,
      headerComponentParams: { name: 'email_templates.status' },
      headerComponentFramework: HeaderComponent,
    },
    'emailTemplates:createdAt': {
      ...commonColumnTypes['common:date'],
      field: 'createdAt',
      width: 150,
      headerComponentParams: { name: 'email_templates.createdAt' },
      headerComponentFramework: HeaderComponent,
    },
    'emailTemplates:updatedAt': {
      ...commonColumnTypes['common:date'],
      field: 'updatedAt',
      width: 150,
      headerComponentParams: { name: 'email_templates.updatedAt' },
      headerComponentFramework: HeaderComponent,
    },
    'emailTemplates:type': {
      ...commonColumnTypes['common:status'],
      field: 'type',
      width: 50,
      headerComponentParams: { name: 'email_templates.type' },
      headerComponentFramework: HeaderComponent,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        statusesMap: getEmailTemplateTypesMap(intl),
      },
    },
  };

  return columnTypes;
};
