import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));
export default useStyles;

export const PopperExpandedInlineStyles = {
  width: '95%',
  maxWidth: '400px',
};
