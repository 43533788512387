import React, { useContext } from 'react';
import { Container, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import NewApplicationContext, { NewApplicationContextProps } from './new-application.context';
import useStyles from './styles';
import {
  addFilesToNewApplication,
  removeFileFromNewApplication,
} from '../../store/newApplication/actions';
import Attachments from '../../components/common/Attachments';
import getDefaultRenderer from '../../components/common/Attachments/defaultRenderer';
import { ProgressHandler } from '../../services/websockets';

type AttachmentsSectionPropsType = {
  defaultData: FileType[];
};

export default function AttachmentsSection({
  defaultData: defaultFiles,
}: AttachmentsSectionPropsType) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filesToUpload, setFilesToUpload] = React.useState<
    Array<{ file: File; onProgress: ProgressHandler; onFinish: () => unknown }>
  >([]);
  const [filesToDelete, setFilesToDelete] = React.useState<string[]>([]);
  const hasChangesRef = React.useRef(false);
  const [uploadInProgress, setUploadInProgress] = React.useState(false);
  const attachmentsRenderer = React.useMemo(
    () => getDefaultRenderer({ title: intl.formatMessage({ id: 'common.attachments' }) }),
    [],
  );

  const { subscribeOnPageLeave, unSubscribeOnPageLeave, routeParams, subscribeOnSave } =
    useContext<NewApplicationContextProps>(NewApplicationContext);

  React.useEffect(() => {
    hasChangesRef.current = !!(filesToDelete.length || filesToUpload.length);
  }, [!!filesToDelete.length, !!filesToUpload.length]);

  const leaveListener = React.useCallback(() => {
    return hasChangesRef.current;
  }, [hasChangesRef]);

  React.useEffect(() => {
    subscribeOnPageLeave(leaveListener);
    return () => {
      unSubscribeOnPageLeave(leaveListener);
    };
  }, [leaveListener]);

  const onAddFile: React.ComponentProps<typeof Attachments>['addFiles'] = (
    f,
    onProgress,
    onFinish,
  ) => {
    filesToUpload.push(...f.map((file) => ({ file, onProgress, onFinish })));
    setFilesToUpload([...filesToUpload]);

    return null;
  };

  const onDeleteFile: React.ComponentProps<typeof Attachments>['removeFile'] = async (fileUuid) => {
    if (defaultFiles.some((f) => f.uuid === fileUuid)) {
      setFilesToDelete([...filesToDelete, fileUuid]);
    } else {
      setFilesToUpload(filesToUpload.filter((f) => f.file.name !== fileUuid));
    }
  };

  React.useEffect(() => {
    const unsubscribe = subscribeOnSave(async (applicationUuid) => {
      setUploadInProgress(true);
      try {
        await Promise.all([
          ...(filesToUpload?.length
            ? filesToUpload.map((file) =>
                addFile([file.file], file.onProgress, applicationUuid).then(file.onFinish),
              )
            : [Promise.resolve()]),
          ...(filesToDelete?.length
            ? filesToDelete.map((f) => deleteFile(f, applicationUuid))
            : [Promise.resolve()]),
        ]);
      } catch (err) {
        setUploadInProgress(false);

        return {
          success: false,
          message: err.message,
        };
      }
      setUploadInProgress(false);

      return {
        success: true,
      };
    });

    return () => {
      unsubscribe();
    };
  }, [filesToUpload, filesToDelete]);

  const addFile = async (files: File[], onProgress: ProgressHandler, applicationUuid: string) => {
    await dispatch(
      addFilesToNewApplication({
        applicationUuid,
        files,
        routeParams,
        onProgress,
      }),
    );
    filesToUpload.splice(
      filesToUpload.indexOf(
        filesToUpload.find((f) => f.file.name === files[0]?.name) as typeof filesToUpload[number],
      ),
      1,
    );
    setFilesToUpload([...filesToUpload]);
  };

  const deleteFile = async (fileUuid: string, applicationUuid: string) => {
    await dispatch(
      removeFileFromNewApplication({
        applicationUuid,
        fileUuid,
        routeParams,
      }),
    );
    filesToDelete.splice(filesToDelete.indexOf(fileUuid), 1);
    setFilesToDelete([...filesToDelete]);
  };

  const filesToRender: FileType[] = [
    ...defaultFiles,
    ...filesToUpload.map(({ file }) => ({
      name: file.name,
      uuid: file.name,
      path: '',
      type: file.type,
      size: file.size + '',
      url: null,
      createdAt: '',
    })),
  ].filter((f) => !filesToDelete.includes(f.uuid));

  return (
    <Paper data-cy="files" variant="outlined">
      <Container className={classes.container}>
        <div>
          <Attachments
            addFiles={onAddFile}
            removeFile={onDeleteFile}
            files={uploadInProgress ? [] : filesToRender}
            renderer={attachmentsRenderer}
          />
        </div>
      </Container>
    </Paper>
  );
}
