import React from 'react';
import { useFormContext } from 'react-hook-form';
import omit from 'lodash/omit';
import { useDispatch } from 'react-redux';

import { updateVendor } from '../../../store/vendorSettings/actions';
import VendorLogoSettings from '../VendorLogoSettings';
import CompanyLogo from '../CompanyLogo';

type LogoSettingsProps = {
  changeVendorLogo?: (
    logo: FormData,
    vendorDocumentsLogo?: boolean,
    companyDocumentsLogo?: boolean,
  ) => Promise<boolean>;
  deleteVendorLogo?: (
    vendorUuid: string,
    vendorDocumentsLogo?: boolean,
    companyDocumentsLogo?: boolean,
  ) => Promise<boolean>;
  vendorUuid: string;
  vendorDocumentsLogo: boolean;
  companyDocumentsLogo: boolean;
  vendorLogo?: string;
  brokerLogo?: string;
  isVendorFormDirty?: boolean;
  setDisplayChangesModal?: (state: string | false) => void;
  displayChangesModal?: string | false;
};

export default function LogoSettings({
  changeVendorLogo,
  deleteVendorLogo,
  vendorUuid,
  vendorLogo,
  brokerLogo,
  vendorDocumentsLogo,
  companyDocumentsLogo,
  isVendorFormDirty,
  setDisplayChangesModal,
  displayChangesModal,
}: LogoSettingsProps) {
  const { getValues } = useFormContext<VendorCompanyType>();
  const dispatch = useDispatch();

  const handleLogoDocumentsStates = () => {
    const values = getValues();
    if (values.email) {
      const updatedData = omit(values, 'vendorCompany');

      dispatch(updateVendor(vendorUuid, updatedData));
    }
  };

  return (
    <React.Fragment>
      <VendorLogoSettings
        changeLogo={changeVendorLogo}
        handleLogoDocumentsStates={handleLogoDocumentsStates}
        currentLogo={vendorLogo}
        deleteLogo={deleteVendorLogo}
        vendorUuid={vendorUuid}
        vendorDocumentsLogoState={vendorDocumentsLogo}
        companyDocumentsLogoState={companyDocumentsLogo}
        isVendorFormDirty={isVendorFormDirty}
        setDisplayChangesModal={setDisplayChangesModal}
        displayChangesModal={displayChangesModal}
      />
      <CompanyLogo
        brokerLogo={brokerLogo}
        companyDocumentsLogoState={companyDocumentsLogo}
        handleLogoDocumentsStates={handleLogoDocumentsStates}
      />
    </React.Fragment>
  );
}
