import React from 'react';
import { Container } from '@material-ui/core';
import { useIntl } from 'react-intl';
import useStyles from './styles';
import ConsentForm from '../../components/common/ConsentForm';

type ConsentSectionPropsType = {
  path: string;
};

export default function ConsentSection({ path }: ConsentSectionPropsType) {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Container className={classes.containerConsent}>
      <ConsentForm
        path={path}
        data-cy="form-consent-step"
        title={intl.formatMessage({ id: 'consent.consent' })}
      />
    </Container>
  );
}
