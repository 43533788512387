import React from 'react';
import { useIntl } from 'react-intl';
import Chip from '../chip';

export function UserStatus(props: { status?: string; color?: string }) {
  const intl = useIntl();
  const { status, color } = props;
  const intlStatus =
    status && status.toLowerCase() === 'active'
      ? intl.formatMessage({ id: 'user.active' })
      : intl.formatMessage({ id: 'user.inactive' });
  return <Chip dataCy="status" label={intlStatus} backgroundColor={color} />;
}
