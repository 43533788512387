import React from 'react';
import AppWithDrawer from '../components/app';
import { useDispatch } from 'react-redux';
import { actions as contactsActions } from '../store/contacts';
import { actions as settingsActions } from '../store/settings';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export default function VendorRoutes({ children }: { children: React.ReactNode }) {
  const params = useParams<{ vendorSiteUrl: string; userSiteUrl?: string }>();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const langQuery = new URLSearchParams(location.search);
  const routeLanguage = ['EN', 'FR'].includes(langQuery.get('lang') ?? '')
    ? langQuery.get('lang')
    : null;
  if (routeLanguage) {
    langQuery.delete('lang');
    history.replace({
      search: langQuery.toString(),
    });
    dispatch(settingsActions.changeLanguage(routeLanguage as Languages));
  }

  React.useEffect(() => {
    if (params.vendorSiteUrl || params.userSiteUrl) {
      dispatch(contactsActions.loadContactsBySiteUrls(params.vendorSiteUrl, params.userSiteUrl));
    }
  }, [params.vendorSiteUrl, params.userSiteUrl]);

  return <AppWithDrawer type="public">{children}</AppWithDrawer>;
}
