import UserManagement from './user-management.container';
import * as Navigations from '../../constants/navigations';
const { path } = Navigations.USER_MANAGEMENT;
const route = [
  {
    path,
    name: 'UserManagement',
    component: UserManagement,
    exact: true,
  },
];
export default route;
