import React from 'react';
import { Typography, Button, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { UseFormMethods, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import TextInput from '../TextInput';
import { DEFAULT_LANGUAGE, USER_ROLE } from '../../../constants/formValues';
import VaultMenu from '../VaultMenu';
import AreaCodeFormatter from '../AreaCodeFormatter';
import PhoneFormatter from '../PhoneFormatter';
import ExtensionFormatter from '../ExtensionFormatter';
import { USER_EMAIL_PATTERN } from '../../../constants/regex';
import BooleanInput from '../BooleanInput';
import clsx from 'clsx';
import useStyles from './styles';

type CreateUserFormProps = {
  vendorsList: VendorType[];
  isPortalAdmin?: boolean;
  isActivate: boolean;
  handleSubmit: UseFormMethods['handleSubmit'];
  handleSaveUser: () => void;
};

function CreateUserForm(props: CreateUserFormProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { control, errors, reset, register } = useFormContext();

  const { vendorsList, isPortalAdmin, isActivate, handleSubmit, handleSaveUser } = props;

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} md={6}>
        <Paper
          component="form"
          onSubmit={handleSubmit(handleSaveUser)}
          className={classes.formWrapper}
          variant="outlined"
        >
          <Grid item xs={12} sm={12} className={classes.formTitleWrapper}>
            <Typography variant="h2" align="left">
              {intl.formatMessage({ id: 'user.create_new_user' })}
            </Typography>
          </Grid>
          {isPortalAdmin && (
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <VaultMenu
                  required
                  inputRef={register({})}
                  options={vendorsList}
                  className={classes.formControl}
                  label={intl.formatMessage({ id: 'company.name' })}
                  name="vendorUuid"
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <VaultMenu
                required
                inputRef={register({})}
                options={USER_ROLE}
                disabled={false}
                className={classes.formControl}
                label={intl.formatMessage({ id: 'user.user_role' })}
                name="role"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="email"
                label={intl.formatMessage({ id: 'common.email_login' })}
                fullWidth
                required
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: 'common.input_email' }),
                  },
                  pattern: USER_EMAIL_PATTERN,
                }}
                defaultValue={''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="firstName"
                label={intl.formatMessage({ id: 'common.first_name' })}
                fullWidth
                required
                rules={{ required: intl.formatMessage({ id: 'common.please_input_first_name' }) }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="lastName"
                label={intl.formatMessage({ id: 'common.last_name' })}
                fullWidth
                required
                rules={{ required: intl.formatMessage({ id: 'common.please_input_middle_name' }) }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography variant="button">{intl.formatMessage({ id: 'common.phone' })}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={4}>
              <AreaCodeFormatter
                control={control}
                id="phoneAreaCode"
                name="phoneAreaCode"
                label={intl.formatMessage({ id: 'common.area_code' })}
                fullWidth
                error={errors.phoneAreaCode}
                helperText={errors.phoneAreaCode?.message}
                phoneformatter={{ id: 'phone' }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PhoneFormatter
                control={control}
                name="phone"
                error={errors.phone}
                helperText={errors.phone?.message}
                id="phone"
                label={intl.formatMessage({ id: 'common.phone' })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ExtensionFormatter
                control={control}
                id="phoneExt"
                name="phoneExt"
                label={intl.formatMessage({ id: 'common.phone_ext' })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography variant="button">
                {intl.formatMessage({ id: 'common.mobile_phone' })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={4}>
              <AreaCodeFormatter
                control={control}
                id="mobileAreaCode"
                name="mobileAreaCode"
                label={intl.formatMessage({ id: 'common.area_code' })}
                fullWidth
                error={errors.mobilePhoneAreaCode}
                helperText={errors.mobilePhoneAreaCode?.message}
                phoneformatter={{ id: 'mobilePhone' }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <PhoneFormatter
                control={control}
                name="mobilePhone"
                error={errors.mobilePhone}
                helperText={errors.mobilePhone?.message}
                id="mobilePhone"
                label={intl.formatMessage({ id: 'common.mobile_phone' })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <VaultMenu
                required
                inputRef={register({})}
                options={DEFAULT_LANGUAGE}
                disabled={false}
                className={classes.formControl}
                label={intl.formatMessage({ id: 'common.default_language' })}
                name="defaultLanguage"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="replyTo"
                label={intl.formatMessage({ id: 'common.reply_to_email' })}
                fullWidth
                rules={{
                  required: false,
                  pattern: USER_EMAIL_PATTERN,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            className={clsx(classes.extraPadding, classes.centeredCheckbox)}
          >
            <Grid item sm={12} md={12} className={classes.checkboxItem}>
              <BooleanInput
                label={intl.formatMessage({ id: 'user.activate_user' })}
                name="active"
                size="medium"
                color="primary"
                labelPosition="after"
              />
            </Grid>
            <Grid item sm={12} md={12} className={classes.centeredCheckbox}>
              <BooleanInput
                disabled={!isActivate}
                label={intl.formatMessage({ id: 'user.reset_password_and_notify_user' })}
                name="resetPass"
                size="medium"
                color="primary"
                labelPosition="after"
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" className={classes.controlButtonsWrapper}>
            <Button
              onClick={() =>
                reset({
                  defaultLanguage: 'EN',
                  role: intl.formatMessage({ id: 'role.vendor_user' }),
                })
              }
              variant="contained"
              color="default"
              className={classes.controlButtons}
            >
              {intl.formatMessage({ id: 'button.reset' })}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.controlButtons}
            >
              {intl.formatMessage({ id: 'button.save' })}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CreateUserForm;
