import { getBackendUrl } from '../utils/getBackendUrl';
import { buildAGPaginationFilterQuery } from '../utils/common';
import { getApi } from './httpCommon';
import { IGetRowsParams } from '@ag-grid-community/all-modules';

const BACKEND = getBackendUrl() + '/email-template';
const api = getApi(BACKEND);

export const updateEmailTemplateById = async (
  templateUuid: string,
  templateObj: EmailTemplateUpdateType,
) => {
  return await api.put(`/${templateUuid}`, templateObj);
};

export const createEmailTemplateById = async (templateObj: EmailTemplateUpdateType) => {
  return await api.post('/', templateObj);
};

export const duplicateEmailTemplateById = async (templateUuid: string) => {
  return await api.post(`/${templateUuid}`);
};

export const deleteEmailTemplateById = async (templateUuid: string) => {
  return await api.delete(`/${templateUuid}`);
};

export const getEmailTemplate = async (templateId: string) => {
  return await api.get<EmailTemplateEditBEResponse>(`/${templateId}`);
};

export const getAllEmailTemplateList = async (params: IGetRowsParams) => {
  return await api.get<PaginationEmailTemplateBEResponse>(
    `/list${buildAGPaginationFilterQuery(params)}`,
  );
};
