import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { ApplicationEntitySelect, useApplicationInputs } from './inputs';
import useStyles from './styles';
import { useIntl } from 'react-intl';

type ContactFormType = {
  path: string;
  sameAsPath: string;
  title?: string;
  sameAsValues?: Array<{ label: string; value: string }>;
};

export default function ContactForm({ path, sameAsPath, title, sameAsValues }: ContactFormType) {
  const classes = useStyles();
  const intl = useIntl();
  const {
    FirstNameInput,
    MiddleNameInput,
    LastNameInput,
    EmailInput,
    PhoneAreaCodeInput,
    MobilePhoneInput,
    PhoneInput,
    ExtensionInput,
    MobilePhoneAreaCodeInput,
    SameAsInput,
  } = useApplicationInputs(path, sameAsPath || undefined);

  const hasSameAsDD = !!sameAsValues?.length;

  return (
    <Grid className={classes.verticalSpacing}>
      {title && (
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
      )}
      <Grid container spacing={2}>
        {hasSameAsDD && (
          <Grid item xs={4} sm={4}>
            <SameAsInput
              sameAsValues={
                sameAsValues?.map((v) => ({
                  label: v.label as string,
                  value: v.value as string,
                })) ?? []
              }
            />
          </Grid>
        )}
        <Grid item xs={hasSameAsDD ? 8 : 12} sm={hasSameAsDD ? 8 : 12}>
          <ApplicationEntitySelect
            disabled={!!sameAsPath}
            path={path}
            type="individual"
            label={intl.formatMessage({ id: 'company.search_business_contact' })}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FirstNameInput />
        </Grid>

        <Grid item xs={12} sm={4}>
          <MiddleNameInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LastNameInput />
        </Grid>

        <Grid item xs={12} sm={4}>
          <EmailInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={5}>
              <PhoneAreaCodeInput />
            </Grid>
            <Grid item xs={12} sm={5}>
              <PhoneInput />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ExtensionInput />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Grid container spacing={0}>
            <Grid item xs={4} sm={4}>
              <MobilePhoneAreaCodeInput />
            </Grid>
            <Grid item xs={8} sm={8}>
              <MobilePhoneInput />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
