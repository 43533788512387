import { Recipients } from '../apis/email';

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateRecipients = (recipients: Recipients): string | null => {
  if (!recipients.some((r) => r.type === 'to' && r.email.trim())) {
    return 'email.error.to_required';
  } else if (recipients.some((r) => r.isSelected && !r.email.trim())) {
    return 'email.error.missing_email';
  } else if (!recipients.every((r) => !r.email.trim() || validateEmail(r.email.trim()))) {
    return 'email.error.invalid_email';
  }

  return null;
};
