import EmailTemplate from './email-template.container';
import { EMAIL_TEMPLATE_PAGE, NEW_EMAIL_TEMPLATE_PAGE } from '../../constants/navigations';

export default [
  {
    path: EMAIL_TEMPLATE_PAGE.path,
    name: 'EmailTemplate',
    component: EmailTemplate,
    exact: true,
  },
  {
    path: NEW_EMAIL_TEMPLATE_PAGE.path,
    name: 'EmailTemplate',
    component: EmailTemplate,
    exact: true,
  },
];
