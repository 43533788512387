import { IntlShape } from 'react-intl';
export const translateLabel = (intl: IntlShape, label: string, altLabel?: string) => {
  try {
    if (intl.messages[label]) {
      return intl.formatMessage({ id: label });
    }
    return altLabel || label;
  } catch (e) {
    return altLabel || label;
  }
};
