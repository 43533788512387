import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: FileStoreType = {
  attachments: [],
  loading: false,
};

const slice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    fetchFiles: (state, action: PayloadAction<FileStoreType['attachments']>) => {
      state.attachments = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetFiles: () => initialState,
    removeFile: (state, action: PayloadAction<string>) => {
      state.attachments = state.attachments.filter((file) => file.uuid !== action.payload);
    },
  },
});

export default slice.reducer;
export const actions = slice.actions;
