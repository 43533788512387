import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { validateChangeEmail } from '../../store/user';
import { Copyright } from '../../components/common/Copyright';
import Link from '../../components/common/link';

type UrlParams = {
  token: string;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const userErrorSelector = (state: AppStoreType) => state.user;

function ChangeEmail() {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { token } = useParams<UrlParams>();
  const { error } = useSelector<AppStoreType, ReturnType<typeof userErrorSelector>>(
    userErrorSelector,
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const changeUserEmail = (token: string) => {
    try {
      dispatch(validateChangeEmail(token));
      setShowSuccessMessage(true);
    } catch {
      return;
    }
  };

  useEffect(() => {
    if (token) {
      changeUserEmail(token);
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <SettingsBackupRestoreIcon />
        </Avatar>
        {showSuccessMessage && (
          <Alert severity="success">
            {intl.formatMessage({ id: 'application.successfully_created_email' })}
          </Alert>
        )}
        {error && (
          <Typography variant="subtitle2" color="error" noWrap>
            {error}
          </Typography>
        )}
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link to="/sign-in">{intl.formatMessage({ id: 'common.sign_in' })}</Link>
          </Grid>
        </Grid>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default ChangeEmail;
