import React from 'react';
import { EmailTemplatesListConnectedProps } from './email-templates-list.container';
import { useIntl } from 'react-intl';
import ServerSideDataGrid from '../../components/common/VpDataGrid/ServerSideDataGrid';
import { GridApi, IDatasource } from '@ag-grid-community/all-modules';
import { AgGridColumn } from '@ag-grid-community/react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import useStyles from './styles';

function EmailTemplatesList({ fetchEmailTemplateList }: EmailTemplatesListConnectedProps) {
  const intl = useIntl();
  const [gridApi, setGridApi] = React.useState<null | GridApi>(null);
  const classes = useStyles();

  const datasource: IDatasource = {
    getRows: async (params) => {
      const templates = await fetchEmailTemplateList(params);
      params.successCallback(templates?.data ?? [], templates?.total);
    },
  };

  return (
    <div>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        alignItems="center"
        className={classes.headerSpaceBottom}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Hidden smDown>
            <Typography variant="h1" gutterBottom>
              {intl.formatMessage({ id: 'email_templates.templates' })}
            </Typography>
          </Hidden>
        </Grid>
      </Grid>

      <ServerSideDataGrid onInit={setGridApi} api={gridApi} datasource={datasource} fitType="auto">
        <AgGridColumn floatingFilter type="emailTemplates:name" />
        <AgGridColumn floatingFilter type="emailTemplates:relatedTo" />
        <AgGridColumn floatingFilter type="emailTemplates:active" />
        <AgGridColumn floatingFilter type="emailTemplates:companyName" />
        <AgGridColumn floatingFilter type="emailTemplates:createdAt" />
        <AgGridColumn floatingFilter type="emailTemplates:updatedAt" />
        <AgGridColumn floatingFilter type="emailTemplates:type" />
      </ServerSideDataGrid>
    </div>
  );
}

export default EmailTemplatesList;
