import { getBackendUrl } from '../utils/getBackendUrl';
import { getApi } from './httpCommon';

const BACKEND = getBackendUrl() + '/vendorProgram';
const api = getApi(BACKEND);

export const getVendorProgram = async () => {
  return await api.get<Array<{ value: string; label: string; isDefault: boolean }>>('');
};

export const getDefaultVendorProgram = async (vendorSiteUrl: string, userSiteUrl?: string) => {
  return await api.get<Array<{ value: string; label: string; isDefault: boolean }>>('/default', {
    headers: {
      'vendor-site-url': vendorSiteUrl ?? '',
      'user-site-url': userSiteUrl ?? '',
    },
  });
};

type GetVendorSettingResponse = {
  getVPNotifications__c: boolean;
  Vendor_Notification_Emails__c: string;
  Owner: {
    Email: string;
    Name: string;
  };
  Vendor__r: {
    Owner: {
      Email: string;
      Name: string;
    };
  };
};

export const getVendorSetting = async () => {
  return await api.get<GetVendorSettingResponse[]>('/vendorSetting');
};

export const getVendorSettingPublic = async (vendorSiteUrl: string, userSiteUrl?: string) => {
  return await api.get<GetVendorSettingResponse[]>('/vendor-setting-public', {
    headers: {
      'vendor-site-url': vendorSiteUrl,
      'user-site-url': userSiteUrl ?? '',
    },
  });
};

export const getVendorRepPublic = async (vendorSiteUrl: string, userSiteUrl: string) => {
  return await api.get<Array<{ name: string; salesforceId: string }>>('/vendor-rep-public', {
    headers: {
      'vendor-site-url': vendorSiteUrl,
      'user-site-url': userSiteUrl ?? '',
    },
  });
};

export const getVendorReps = async () => {
  return await api.get<
    Array<{
      name: string;
      salesforceId: string;
      isDefault?: boolean;
      uuid?: string;
      email?: string;
    }>
  >('/vendor-reps');
};
