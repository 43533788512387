import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonsRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    margin: '0 15px 0 5px',
    [theme.breakpoints.down('lg')]: {
      margin: 0,
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginLeft: '-10px',
      '& button': {
        marginBottom: '10px',
      },
    },
  },
  spaceLeft: {
    marginLeft: theme.spacing(1),
  },
  headerSpaceBottom: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  paperPadding: {
    padding: '30px 20px',
  },
  selectValue: {},
  switchFormatting: {
    '& .MuiFormControlLabel-label ': {
      fontSize: '0.8rem',
    },
  },
  dateCreated: {},
  recipientsLabel: {
    transform: 'scale(0.75)',
  },
  recipientsSelect: {
    height: '2.2em',
  },
  dialogBtn: {
    justifyContent: 'flex-end',
  },
  dialogTitle: {
    padding: theme.spacing(2),
  },
  dialogContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: '#fafafa',
  },
  recipientList: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    paddingBottom: '10px',
    '& .MuiGrid-item': {
      paddingRight: '5px',
    },
    '& .MuiTextField-root': {
      position: 'relateive',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '45px',
    },
  },
  recipientRemoveBtn: {
    padding: '3px 0',
  },
  title: {
    display: 'block',
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
    '& small:last-child': {
      marginLeft: '5px',
      color: 'red',
    },
  },
  previewButton: {
    float: 'right',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  previewMessage: {
    float: 'right',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
