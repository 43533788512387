import { ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { useIntl } from 'react-intl';
import useStyles from './styles';
import { MY_PROFILE_PAGE } from '../../../constants/navigations';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Link from '../link';
import ExitToApp from '@material-ui/icons/ExitToApp';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { switchUserAccount } from '../../../store/user';
import { green } from '@material-ui/core/colors';

type UserProfilePropsType = {
  logout?: () => unknown;
  logoutAsVendorUser?: () => unknown;
  user: UserStoreType | Record<string, never>;
  children?: React.ReactChild;
};

export default function UserProfile({
  logout,
  logoutAsVendorUser,
  children,
  user,
}: UserProfilePropsType) {
  const [open, setOpen] = React.useState<boolean>();
  const anchorRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  React.useLayoutEffect(() => {
    if (open === false) {
      if (anchorRef.current && 'focus' in anchorRef.current) {
        anchorRef.current.focus();
      }
    }
  }, [open]);

  const handleClose = (event: React.MouseEvent<Document | HTMLElement>) => {
    if (anchorRef.current?.contains(event.target as HTMLElement | Document)) {
      return;
    }

    setOpen(false);
  };

  const switchAccount = async (vendorUuid: string) => {
    try {
      await dispatch(switchUserAccount(vendorUuid));
    } catch (e) {
      return;
    }

    const loc = new URLSearchParams(location.search);
    const redirect = loc.get('redirect');

    history.push(redirect ? (redirect as string) : '/');
  };

  return (
    <div style={{ display: 'inline-flex' }} ref={anchorRef} onClick={handleToggle}>
      {children}
      <Popper
        open={!!open}
        anchorEl={anchorRef.current}
        transition
        style={{ zIndex: 10 }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper variant="outlined">
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <div className={classes.accountTop}>
                    <div className={classes.profileLink}>
                      <Link to={generatePath(MY_PROFILE_PAGE.path)}>
                        <>
                          <PersonOutlineIcon color="primary" />
                          <span>{intl.formatMessage({ id: MY_PROFILE_PAGE.title })}</span>
                        </>
                      </Link>
                    </div>
                    <a className={classes.logoutLink} onClick={logout}>
                      <ExitToApp color="secondary" />
                      <span>{intl.formatMessage({ id: 'common.logout' })}</span>
                    </a>
                    {logoutAsVendorUser && (
                      <a className={classes.logoutAsVendorLink} onClick={logoutAsVendorUser}>
                        <ExitToApp color="secondary" />
                        <span>{intl.formatMessage({ id: 'common.logout_from_user' })}</span>
                      </a>
                    )}
                    <div className={classes.clearFix} />
                  </div>
                  {!logoutAsVendorUser && (
                    <>
                      <p className={classes.separator}>
                        {intl.formatMessage({ id: 'common.available_accounts' })}
                      </p>
                      <div>
                        {user.accounts.map((account: UserAccount) => (
                          <div key={account.brokerUuid} className={classes.accountsWrapper}>
                            <div className={classes.accountWrapperHeader}>
                              <span className={classes.brokerPhoto}>
                                <img
                                  src={`data:image/jpeg;base64,${account.brokerLogo?.content}`}
                                />
                              </span>
                              <span>{account.brokerName}</span>
                            </div>
                            <div>
                              {account.vendors.map((vendor) => (
                                <a
                                  href="#"
                                  onClick={() => switchAccount(vendor.uuid)}
                                  key={vendor.uuid}
                                >
                                  <span>{vendor.companyName}</span>
                                  {vendor.choosed && (
                                    <span>
                                      <CheckBoxIcon
                                        className={classes.activeVendorIcon}
                                        style={{ color: green[500] }}
                                      />
                                    </span>
                                  )}
                                </a>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
