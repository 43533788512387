import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  signerContent: {
    padding: theme.spacing(2),
    width: '100%',
  },
  attachmentContainer: {
    '& > span': {
      display: 'block',
    },
    '& > span:first-child': {
      fontSize: '12px',
    },
    '& > span:last-child': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    '& > span *': {
      display: 'inline-block',
      verticalAlign: 'bottom',
      lineHeight: 'initial',
    },
    '& > span svg': {
      marginLeft: theme.spacing(0.5),
      fontSize: '18px',
      fill: '#009688',
    },
  },
  divider: {
    margin: '0 -15px',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    width: '100%',
  },
  formControlLabel: {
    display: 'block',
    '&:first-child': {
      marginTop: theme.spacing(2),
    },
  },
  labelSuccess: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0),
    borderRadius: 0,
    backgroundColor: 'rgb(237, 247, 237)',
    color: 'rgb(30, 70, 32)',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  labelPending: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0),
    borderRadius: 0,
    backgroundColor: 'rgba(117, 117, 117, 0.2)',
    color: 'rgba(117, 117, 117, 1)',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  labelError: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0),
    borderRadius: 0,
    backgroundColor: 'rgb(253, 236, 234)',
    color: 'rgb(97, 26, 21)',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  signerItem: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #EAEAEA',
    '&:last-child': {
      marginBottom: 0,
      borderBottom: 0,
      paddingBottom: 0,
    },
  },
  role: {
    textTransform: 'uppercase',
    fontSize: 10,
    letterSpacing: '1.5px',
  },
  applicantName: {
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '0.15px',
  },
  email: {
    marginTop: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: 10,
    letterSpacing: '1.5px',
  },
  applicantEmail: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    wordBreak: 'break-all',
  },
}));

export default useStyles;
