import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: 'center',
    marginLeft: '5px',
  },
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  header: {
    display: 'flex',
    color: theme.palette.text.secondary,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '6px',
    paddingBottom: '10px',
    '& > span': {
      fontSize: '20px',
      fontWeight: 500,
      color: '#000000',
      textAlign: 'left',
    },
  },
  preview: {
    width: '256px',
    height: '160px',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    imageRendering: '-webkit-optimize-contrast',
  },
  previewSection: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  checkboxLabel: {
    fontSize: '14px',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 7px 0 3px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  addLogoButton: {
    width: '100%',
    padding: '0 15px 0 15px',
    display: 'flex',
    justifyContent: 'center',
  },
  noPhotoIcon: {
    width: '56px',
    height: '56px',
  },
  checkboxSpan: {
    paddingLeft: '5px',
  },
}));

export default useStyles;
