import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

type VpButtonProps = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<unknown> | unknown;
  loaderColor?: React.ComponentProps<typeof CircularProgress>['color'];
} & ButtonProps;

export default function VpButton({ children, onClick, loaderColor, ...restProps }: VpButtonProps) {
  const [loading, setLoading] = React.useState(false);
  const isUnmounted = React.useRef(false);

  React.useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );

  const safeSetState = (flag: boolean) => {
    if (!isUnmounted.current) {
      setLoading(flag);
    }
  };

  const handleButtonClick: ButtonProps['onClick'] = (...args) => {
    const output = onClick?.(...args);
    if (typeof (output as Promise<unknown>)?.then === 'function') {
      setLoading(true);
      return (output as Promise<unknown>)
        .then((res) => {
          safeSetState(false);
          return res;
        })
        .catch((e) => {
          safeSetState(false);
          throw e;
        });
    }
  };

  return (
    <Button disabled={loading} onClick={handleButtonClick} {...restProps}>
      {loading && (
        <CircularProgress
          style={{ marginRight: '10px' }}
          color={loaderColor || 'inherit'}
          size={20}
        />
      )}
      {children}
    </Button>
  );
}
