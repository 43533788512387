import React from 'react';
import { formattedDate } from '../../../../services/formatter-service';
import { APPLICATION_DATE_FORMAT } from '../../../../constants/date';
import { useIntl } from 'react-intl';

export default function DateRenderer(props: {
  value: string;
  format?: string;
  data?: { [key: string]: string | number };
}) {
  const intl = useIntl();
  const dateString = formattedDate(
    new Date(props.value),
    props.format ?? APPLICATION_DATE_FORMAT,
    intl.locale.toLowerCase(),
  );

  return <div>{dateString}</div>;
}
