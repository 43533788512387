import { connect, ConnectedProps } from 'react-redux';
import { actions as toastActions } from '../../store/toast';
import { actions as quoteToApplicationActions } from '../../store/quoteToApplication';
import QuoteToApplication from './quote-to-application.component';

const {
  fetchAssociatedApplicationUuid,
  fetchPaymentOption,
  createApplicationFromQuote,
  selectPaymentOption,
  getPaymentOption,
} = quoteToApplicationActions;
const mapStateToProps = (state: AppStoreType) => ({
  vendorId: (state.user as UserBasedStoreType).vendorId,
  vendorUuid: (state.user as UserBasedStoreType).vendorUuid,
  loading: state.quoteToApplication.loading,
  error: state.instantQuote.error,
  quoteInfo: state.instantQuote.quoteInfo,
  generatedQuotes: state.instantQuote.generatedQuotes,
  selectedPaymentOption: state.instantQuote.selectedPaymentOption,
  associatedApplicationUuid: state.quoteToApplication.associatedApplicationUuid,
  paymentOption: state.quoteToApplication.paymentOption,
});

const mapDispatchToProps = {
  openToast: toastActions.open,
  selectPaymentOption,
  getPaymentOption,
  createApplicationFromQuote,
  fetchAssociatedApplicationUuid,
  fetchPaymentOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type QuoteToApplicationConnectedPropsType = ConnectedProps<typeof connector>;

export default connector(QuoteToApplication);
