import { useTheme } from '@material-ui/core/styles';
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import ExpandInIcon from '../../icons/expand-in';

import ApplicationSummary from '../../../pages/application/application-summary';
import ApplicationStatus from '../../../pages/application/application-status';
import ApplicationAmendments from '../ApplicationAmendments';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from '../TabPanel';
import ApplicationQuoteInfo from '../ApplicationQuoteInfo';
import { useIntl } from 'react-intl';
import { Dropzone } from '../VaultDropzone';
import { ProgressHandler } from '../../../services/websockets';
import Attachments from '../Attachments';
import getApplicationAttachmentsRenderer from '../Attachments/applicationAttachmentsRenderer';
import ApplicationDocumentList from '../ApplicationDocumentList/application-document-list.containter';
import useStyles from './styles';

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function ApplicationTabs({
  applicationInfo,
  brokerApplicationInfo,
  addFiles,
  deleteFile,
  applicationFiles,
  loading,
  isApproved,
  sendApplicationDocuments,
}: {
  applicationInfo: ApplicationStoreType['application'];
  brokerApplicationInfo: ApplicationStoreType['brokerApplication'];
  applicationFiles: FileType[];
  loading: boolean;
  isApproved: boolean;
  addFiles: (
    applicationUuid: string,
    files: File[],
    onProgress: ProgressHandler,
    routeParams: PublicRouteParams,
  ) => Promise<unknown>;
  sendApplicationDocuments: (applicationUuid: string, files: string[]) => Promise<boolean>;
  deleteFile: (applicationUuid: string, fileUuid: string) => Promise<unknown>;
}) {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const attachmentsRenderer = useCallback(
    getApplicationAttachmentsRenderer({
      applicationUuid: applicationInfo?.application.uuid ?? '',
    }),
    [applicationInfo?.application.uuid],
  );
  const isSidebarOpened = useSelector<AppStoreType, AppStoreType['settings']['isSidebarOpened']>(
    (state: AppStoreType) => state.settings.isSidebarOpened,
  );

  const handleChange = (_: unknown, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const canSubmitDocuments =
    // when it was not submitted
    !applicationInfo?.application.documentsSubmittingInProgress &&
    // and
    // when application has Approved or Drafting Contract status
    (['applicationApproved', 'applicationDraftingContract'].includes(
      applicationInfo?.application.status?.status ?? '',
    ) ||
      // or Documents sent out status with substate "Submitted by Vendor"
      (applicationInfo?.application.status?.status === 'applicationDocumentsSentOut' &&
        applicationInfo.application.statusDescription === 'Docs Sent by Vendor'));

  return (
    <Dropzone>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={expanded ? 8 : 4}>
          <Paper variant="outlined" className={classes.paperSummary}>
            {applicationInfo && (
              <ApplicationSummary loading={loading} applicationInfo={applicationInfo} />
            )}
            <ExpandInIcon
              className={classes.expandIcon}
              onClick={() => setExpanded(expanded ? false : true)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={expanded ? 4 : 8}>
          <Paper variant="outlined" className={classes.paperTabs}>
            <AppBar
              position="static"
              color="transparent"
              elevation={0}
              className={classes.detailsHeader}>
              <Tabs
                className={
                  isSidebarOpened ? classes.tabsButtonSidebar : classes.tabsButtonNoSidebar
                }
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
                aria-label={intl.formatMessage({
                  id: 'application.submitted_application_tabbed_view',
                })}>
                <Tab
                  data-cy="status-tab"
                  label={intl.formatMessage({ id: 'common.status' })}
                  {...a11yProps(0)}
                />
                <Tab
                  data-cy="quote-tab"
                  label={intl.formatMessage({ id: 'common.quote' })}
                  {...a11yProps(1)}
                />
                <Tab
                  data-cy="amendments-tab"
                  label={intl.formatMessage({ id: 'common.amendments' })}
                  {...a11yProps(2)}
                />
                <Tab
                  data-cy="documentation-tab"
                  disabled={
                    !(
                      isApproved &&
                      brokerApplicationInfo?.data?.document_templates?.length > 0 &&
                      applicationInfo?.application?.uuid
                    )
                  }
                  label={intl.formatMessage({ id: 'common.documentation' })}
                  {...a11yProps(3)}
                />
                <Tab
                  data-cy="files-tab"
                  label={intl.formatMessage({ id: 'common.files' })}
                  {...a11yProps(4)}
                />
              </Tabs>
              <ExpandInIcon
                className={classes.expandIcon}
                onClick={() => setExpanded(expanded ? false : true)}
              />
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}>
              <TabPanel value={value} index={0} dir={theme.direction}>
                {applicationInfo && (
                  <ApplicationStatus
                    mirReason={applicationInfo.application.mirReason}
                    approvalTerms={applicationInfo.application.approvalTerms}
                    declineReason={applicationInfo.application.declineReason}
                    mirItems={applicationInfo.mirItems}
                    status={applicationInfo.application.status}
                    statusDescription={applicationInfo.application.statusDescription}
                  />
                )}
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <ApplicationQuoteInfo
                  selectedGeneratedQuote={applicationInfo?.quote.selectedGeneratedQuote}
                  selectedPaymentOption={applicationInfo?.quote.selectedPaymentOption}
                  quoteInfo={applicationInfo?.quote.quoteInfo}
                />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                {applicationInfo?.application?.uuid && (
                  <ApplicationAmendments
                    applicationUuid={applicationInfo.application.uuid}
                    disableEdit={!applicationInfo.application.salesforceOpportunityUuid}
                  />
                )}
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                {brokerApplicationInfo?.data?.document_templates?.length > 0 &&
                  applicationInfo?.application?.uuid && (
                    <ApplicationDocumentList
                      applicationStatus={applicationInfo.application.status}
                      applicationUuid={applicationInfo.application.uuid}
                      loading={loading}
                    />
                  )}
              </TabPanel>
              <TabPanel value={value} index={4} dir={theme.direction}>
                {!!applicationInfo && (
                  <Attachments
                    canSubmitDocuments={canSubmitDocuments}
                    sendApplicationDocuments={(files) =>
                      sendApplicationDocuments(applicationInfo!.application.uuid, files)
                    }
                    removeFile={(fileUuid) =>
                      deleteFile(applicationInfo?.application.uuid ?? '', fileUuid)
                    }
                    addFiles={(files, onProgress) =>
                      addFiles(applicationInfo?.application.uuid ?? '', files, onProgress, {})
                    }
                    files={applicationFiles}
                    renderer={attachmentsRenderer}
                  />
                )}
              </TabPanel>
              <TabPanel value={value} index={5} dir={theme.direction}>
                {intl.formatMessage({ id: 'application.activity_history' })}
              </TabPanel>
            </SwipeableViews>
          </Paper>
        </Grid>
      </Grid>
    </Dropzone>
  );
}
