import { connect, ConnectedProps } from 'react-redux';

import { actions } from '../../store/globalSearch';
const { fetchSearchQuery, cleanState } = actions;

import SearchResult from './search-result.component';

const mapStateToProps = (state: AppStoreType) => ({
  loading: state.globalSearch.loading,
  searchCount: state.globalSearch.searchCount,
  error: state.globalSearch.error,
  globalSearchList: state.globalSearch.globalSearchList,
});

const mapDispatchToProps = {
  fetchSearchQuery,
  cleanState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type GlobalSearchListConnectedProps = ConnectedProps<typeof connector>;

export default connector(SearchResult);
