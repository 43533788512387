import React, { useEffect } from 'react';
import './App.css';
import Router from './router';
import 'fontsource-roboto';
import LanguageProvider from './components/language/language';
import { createTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { setGaScripts } from '../src/utils/setGaScripts';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      inputMarginDense: {
        paddingTop: '6px',
      },
      input: {},
    },
    MuiInputLabel: {
      formControl: {
        top: '-2px',
      },
    },
    MuiFormLabel: {
      filled: {
        marginTop: '2px',
      },
      root: {
        fontSize: '0.8rem',
        width: '90%',
        textAlign: 'left',
        [breakpoints.down('xs')]: {
          fontSize: '0.9rem',
        },
        [breakpoints.down('sm')]: {
          fontSize: '0.7rem',
        },

        '&$focused': {
          marginTop: '2px',
        },
      },
      asterisk: {
        display: 'ruby', //displaying as guide for pronunciation - always stick to last word
        color: 'red',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#F2F2F2',
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '24px',
        fontWeight: 400,
        color: '#000000',
        textAlign: 'left',
      },
      h2: {
        fontSize: '20px',
        fontWeight: 500,
        color: '#000000',
        textAlign: 'left',
      },
    },
    MuiFormHelperText: {
      root: {
        color: '#f44336',
        '&$error': {
          color: '#f44336',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '.ag-custom-text-align-center': {
          textAlign: 'center',
          justifyContent: 'center',
          '& .ag-header-cell-label': {
            textAlign: 'center',
            justifyContent: 'center',
          },
        },
      },
    },
  },
});

function App() {
  useEffect(() => {
    setGaScripts();
  }, []);

  return (
    <LanguageProvider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </MuiThemeProvider>
    </LanguageProvider>
  );
}

export default App;
