import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  mainTitle: {
    paddingLeft: theme.spacing(1),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  actionsContainer: {
    textAlign: 'right',
  },
  deleteBtnContainer: {
    textAlign: 'right',
  },
  rightSpacing: {
    marginRight: theme.spacing(2),
  },
  button: {
    padding: 0,
    justifyContent: 'flex-start',
  },
  applicationNameButton: {
    padding: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
  },
  alpineThemeContainer: {
    minHeight: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: 600,
    },
  },
  tableHolder: {
    height: 550,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    '& .ag-body-viewport div[unselectable="on"]': {
      userSelect: 'text',
    },
  },
  MuiDataGrid: {
    backgroundColor: 'red',
  },
  PaginationContainer: {
    paddingTop: '10px',
  },
  PaginationPages: {
    marginRight: '10px',
  },
  PaginationButton: {
    padding: 0,
    marginRight: '5px',
    '& svg': {
      width: '12px',
    },
  },

  TitleContainer: {
    display: 'flex',
    alignItems: 'flexEnd',
  },
  SortingIcon: {
    width: '15px',
    height: '15px',
    '& svg': {
      width: '15px',
      height: '15px',
      marginTop: '2px',
    },
  },
  FilterContainer: {
    height: '37px',
    paddingTop: '10px',
  },
  FilterInputDefault: {
    fontSize: '11px',
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '5px',
    '& .MuiInputBase-input': {
      padding: 0,
    },
  },
  SelectInputDefault: {
    fontSize: '11px',
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '5px',
    width: '100%',
    minWidth: '120px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  cancelFilter: {
    display: 'flex',
    cursor: 'pointer',
    '& svg': {
      color: '#DADADA',
      width: '14px',
    },
  },
  popover: {
    height: '200px',
  },
  dateRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    height: '160px',
  },
  dateContainer: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px',
    '& label': {
      fontSize: '11px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiInputBase-root': {
      border: '1px solid #DEE2EA',
      borderRadius: '4px',
      fontSize: '11px',
      marginTop: 0,
      '&::before': {
        borderBottom: 'none',
      },
    },
    '& .MuiInputBase-input': {
      paddingLeft: '10px',
    },
    '& svg': {
      width: '15px',
    },
  },
  datePicker: {},
  applyContainer: {
    textAlign: 'center',
    paddingTop: '3px',
    marginTop: '10px',
    borderTop: '1px solid #DEE2EA',
  },
}));

export default useStyles;
