import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  alert: {
    borderRadius: '20px',
    padding: '0 15px',
    '&.MuiAlert-filledWarning': {
      background: '#ff876e',
    },
    '& .MuiAlert-message': {
      fontSize: '0.7rem',
      padding: '3px 0'
    }
  },
}));

export { useStyles };
