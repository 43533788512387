import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    margin: '0 15px 0 5px',
    [theme.breakpoints.down('lg')]: {
      margin: 0,
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  controlButton: {
    flexDirection: 'column',
    minHeight: '36px',
    maxHeight: '44px',
    margin: '4px 5px',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      margin: 0,
      width: '32%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginBottom: theme.spacing(1),
      width: '100%',
    },
  },
  resetButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: '2%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  },
  newButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: '2%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  },

  buttonsRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    margin: '0 15px 0 5px',
    [theme.breakpoints.down('lg')]: {
      margin: 0,
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  spaceLeft: {
    marginLeft: theme.spacing(3),
  },
  headerSpaceBottom: {
    marginBottom: theme.spacing(4),
  },
}));

export default useStyles;
