export const setCookieValue = (name: string, value: string) => {
  return (document.cookie = `${name}=${value};`);
};

export const getCookieValue = (name: string) => {
  const cookies = document.cookie;

  if (cookies) {
    const cookieArray = cookies.split('; ').find((row) => row.startsWith(`${name}=`));

    if (cookieArray && cookieArray.length) {
      const value = cookieArray.split('=')[1];

      return value;
    }

    return undefined;
  }

  return undefined;
};

export const isSidebarOpened = (status: string | undefined) => {
  if (status === 'opened') {
    return true;
  }

  return false;
};
