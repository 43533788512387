import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { entityPropertiesMap, useApplicationInputs } from './inputs';
import AddressSearchBar from '../address-searchbar';
import useStyles from './styles';
import { useFormContext } from 'react-hook-form';

type AddressFormType = {
  path: string;
  sameAsPath?: string;
  title?: string;
};

export default function AddressForm({ path, title, sameAsPath }: AddressFormType) {
  const classes = useStyles();
  const [searchAddress, setSearchAddress] = React.useState('');
  const { setValue } = useFormContext();
  const {
    UnitInput,
    StreetNumberInput,
    StreetNameInput,
    CountryInput,
    CityInput,
    ProvinceInput,
    PostalCodeInput,
  } = useApplicationInputs(path, sameAsPath || undefined);

  const handleAddressSelect = ({
    country,
    streetNumber,
    city,
    postalCode,
    province,
    streetName,
    unit,
  }: {
    unit?: string;
    streetNumber?: string;
    streetName?: string;
    city?: string;
    province?: string;
    country?: string;
    postalCode?: string;
  }) => {
    setValue(path + entityPropertiesMap.country, country ?? null);
    setValue(path + entityPropertiesMap.province, province ?? null);
    setValue(path + entityPropertiesMap.city, city ?? null);
    setValue(path + entityPropertiesMap.postalCode, postalCode ?? null);
    setValue(path + entityPropertiesMap.streetName, streetName ?? null);
    setValue(path + entityPropertiesMap.streetNumber, streetNumber ?? null);
    setValue(path + entityPropertiesMap.unit, unit ?? null);
  };

  return (
    <Grid className={classes.verticalSpacing}>
      {title && (
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
      )}

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          <AddressSearchBar
            id={`${path}.addressSearchBar`}
            searchAddress={searchAddress}
            setSearchAddress={setSearchAddress}
            handlePlaceSelect={handleAddressSelect}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <UnitInput />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StreetNumberInput />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StreetNameInput />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CityInput />
        </Grid>
        <Grid item xs={12} sm={3}>
          <PostalCodeInput />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ProvinceInput />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CountryInput />
        </Grid>
      </Grid>
    </Grid>
  );
}
