import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { InputAdornment, Input, MenuItem, List, Box, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { localStorageService } from '../../../utils/localStorage';
import useClickOutsideListenerRef from './outsideClickHook';
import useKeyPress from './keyPressHook';
import { actions } from '../../../store/globalSearch';
import Link from '../link';
import { SEARCH_RESULT_PAGE } from '../../../constants/navigations';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import SectionComponents from './SectionComponents';

const SEARCH_HISTORY_KEY = 'searchHistory';
declare type SearchQueryType = { section: string; query: string; uuid: string };

export const setSearchResult = (search: SearchQueryType) => {
  const lastSearches = getLastSearches();
  lastSearches.unshift(search);

  if (lastSearches.length >= 6) lastSearches.length = 6;
  localStorageService.set(SEARCH_HISTORY_KEY, lastSearches);
};

export const replaceSearchResults = (results: SearchQueryType[]) => {
  localStorageService.set(SEARCH_HISTORY_KEY, results);
};

export const getLastSearches = () => {
  const lastSearches: SearchQueryType[] | null = localStorageService.get(SEARCH_HISTORY_KEY);
  return lastSearches || [];
};

const RESULTS_COUNT = 5;

const SearchSelector = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const ref = useClickOutsideListenerRef(() => setDisplaySearchDropdown(false));
  const globalSearchList = useSelector(
    (state: AppStoreType) => state.globalSearch.globalSearchList,
  );
  const isLoading = useSelector((state: AppStoreType) => state.globalSearch.loading);
  const searchResultCount = useSelector((state: AppStoreType) => state.globalSearch.searchCount);

  const { fetchSearchQuery, cleanState } = actions;
  const desktopQuery = useMediaQuery('(max-width: 900px)');
  const isSearchPage = useRouteMatch(SEARCH_RESULT_PAGE.path);

  const [searchList, setSearchList] = useState<SearchQueryType[] | null>(null);
  const [displaySearchDropdown, setDisplaySearchDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [selected, setSelected] = useState('');
  const [cursor, setCursor] = useState(-1);
  const [navigateToSelected, setNavigateToSelected] = useState(false);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');

  const isSearchResults = displaySearchDropdown && searchResultCount ? true : false;
  const isNewResults =
    displaySearchDropdown &&
    !isSearchResults &&
    !isLoading &&
    searchList &&
    searchList.length > 0 &&
    searchQuery.length <= 2;

  const listToNavigate = () => {
    if (isSearchResults)
      return Object.values(globalSearchList)
        .map((section) => section.rows.slice(0, RESULTS_COUNT).map((item) => item.uuid))
        .flat(2);
    if (isNewResults && searchList) return searchList.map((item) => item.uuid);
    return [];
  };

  const handleOpen = () => {
    setSearchList(getLastSearches());
    setDisplaySearchDropdown(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    debounceFetchSearchQuery(event.target.value);
  };

  const debounceFetchSearchQuery = useCallback(
    debounce((query) => {
      setSelected('');
      setCursor(-1);
      setNavigateToSelected(false);
      query.length > 2 ? dispatch(fetchSearchQuery(query)) : dispatch(cleanState());
    }, 300),
    [],
  );

  const debounceDelayedResponse = () =>
    !isLoading && searchQuery.length <= 2 && dispatch(cleanState());

  const removeFromLocalstorage = (index: number) => {
    if (searchList) {
      searchList?.splice(index, 1);
      replaceSearchResults(searchList);
    }
    setSearchList(getLastSearches());
  };

  useEffect(() => {
    debounceDelayedResponse();
  }, [isLoading, searchQuery]);

  useEffect(() => {
    setDisplaySearchDropdown(false);
    setSearchQuery('');
    dispatch(cleanState());
  }, [location]);

  useEffect(() => {
    setSelected('');
    setCursor(-1);
    setNavigateToSelected(false);
  }, [displaySearchDropdown]);

  useEffect(() => {
    const list = listToNavigate();
    if (list && downPress) {
      setCursor((prevState) => {
        const pos = prevState < list.length - 1 ? prevState + 1 : prevState;
        setSelected(list[pos]);
        return pos;
      });
    }
  }, [downPress]);

  useEffect(() => {
    const list = listToNavigate();
    if (list && upPress) {
      setCursor((prevState) => {
        const pos = prevState > 0 ? prevState - 1 : prevState;
        setSelected(list[pos]);
        return pos;
      });
    }
  }, [upPress]);

  useEffect(() => {
    const list = listToNavigate();
    if (list && enterPress) {
      setNavigateToSelected(true);
      if (isNewResults && selected) history.push(selected);
    }
  }, [cursor, enterPress]);

  return (
    <div
      data-cy="search"
      className={clsx(classes.searchContainer, { [classes.hidden]: isSearchPage })}
      ref={ref}
    >
      {desktopQuery && (
        <Link to="/search-result/" className={classes.searchMobileLink}>
          <>
            <SearchIcon />
            <span>{intl.formatMessage({ id: 'common.search_search' })}</span>
          </>
        </Link>
      )}
      {!desktopQuery && (
        <>
          <Input
            disableUnderline
            placeholder={intl.formatMessage({ id: 'grid.search' })}
            className={classes.searchInput}
            endAdornment={
              <InputAdornment position="end">
                {isLoading ? <CircularProgress size={24} /> : <SearchIcon />}
              </InputAdornment>
            }
            onClick={handleOpen}
            onChange={handleChange}
            value={searchQuery}
          />
          {isNewResults && (
            <List data-qa="list-box" className={classes.listBox}>
              <div className={classes.searchListTitle}>
                {intl.formatMessage({ id: 'common.search_recent_searches' })}
              </div>
              {searchList &&
                searchList.map((item, index) => (
                  <MenuItem
                    key={`item_${index}`}
                    className={clsx(classes.searchListItem, {
                      [classes.searchListItemSelected]: item.uuid === selected,
                    })}
                  >
                    <div className={classes.textBlock} onClick={() => history.push(item.uuid)}>
                      <AccessTimeIcon className={classes.searchListTime} />
                      <Box className={classes.searchListText}>
                        {item.query}
                        <span>
                          &nbsp;{intl.formatMessage({ id: 'common.search_in' })}
                          &nbsp;{intl.formatMessage({ id: `common.search_${item.section}` })}
                        </span>
                      </Box>
                    </div>
                    <CloseIcon
                      className={classes.searchListClose}
                      onClick={() => removeFromLocalstorage(index)}
                    />
                  </MenuItem>
                ))}
            </List>
          )}
          {!isNewResults && !isSearchResults && displaySearchDropdown && !isLoading && (
            <List className={classes.listBox}>
              <div data-cy="search-no-results" className={classes.searchListTitle}>
                {intl.formatMessage({ id: 'common.search_no_results_1' })}
              </div>
            </List>
          )}
          {isSearchResults && (
            <List className={classes.listBox}>
              <SectionComponents
                globalSearchList={globalSearchList}
                searchQuery={searchQuery}
                headers
                foundIn
                cutResults
                selectedItem={selected}
                isNavigate={navigateToSelected}
              />
              <div className={classes.searchListFooter}>
                <SearchIcon />
                <Link to={`/search-result/${encodeURI(searchQuery)}`}>
                  {`
                    ${intl.formatMessage({ id: 'common.search_view' })} 
                    ${searchResultCount} 
                    ${intl.formatMessage({ id: 'common.search_results' })}
                  `}
                </Link>
              </div>
            </List>
          )}
        </>
      )}
    </div>
  );
};

export default SearchSelector;
