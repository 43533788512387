import React from 'react';
import { useIntl } from 'react-intl';
import { TextField } from '@material-ui/core';

// Import React Scrit Libraray to load Google object
import Script from 'react-load-script';
import { COUNTRY_TYPE, getStateOptionsByCode } from '../../../constants/formValues';
import { getGoogleMapApiKey } from '../../../utils/getBackendUrl';

const AddressSearchBar = ({ searchAddress, id, setSearchAddress, ...props }) => {
  const inputId = `autocomplete.${id}`;
  const intl = useIntl();
  const [inputValue, setInputValue] = React.useState(null);
  let autocomplete = null;
  const url = `https://maps.googleapis.com/maps/api/js?key=${getGoogleMapApiKey()}&libraries=places`;

  const handleScriptLoad = () => {
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocomplete = new google.maps.places.Autocomplete(document.getElementById(inputId));

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocomplete.setFields(['address_components', 'formatted_address']);
    autocomplete.setComponentRestrictions({ country: ['us', 'ca'] });

    // Fire Event when a suggested name is selected
    autocomplete.addListener('place_changed', handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    try {
      const addressObject = autocomplete.getPlace();
      setSearchAddress(addressObject.formatted_address, id);

      const formattedArray = addressObject.address_components.map((a) => ({
        value: a.long_name,
        shortValue: a.short_name,
        key: a.types[0],
      }));

      const addressMap = {
        unit: formattedArray.find((x) => x.key === 'subpremise'),
        streetNumber: formattedArray.find((x) => x.key === 'street_number'),
        streetName: formattedArray.find((x) => x.key === 'route'),
        city: formattedArray.find((x) => x.key === 'locality'),
        province: formattedArray.find((x) => x.key === 'administrative_area_level_1'),
        country: formattedArray.find((x) => x.key === 'country'),
        postalCode: formattedArray.find((x) => x.key === 'postal_code'),
      };

      const country = COUNTRY_TYPE.find((c) => c.value === addressMap.country?.shortValue);
      const province = getStateOptionsByCode(country?.value, intl.locale.toLowerCase()).find(
        (s) => s.value === addressMap.province?.shortValue,
      );

      setInputValue(null);
      props.handlePlaceSelect({
        unit: addressMap.unit?.value,
        streetNumber: addressMap.streetNumber?.value,
        streetName: addressMap.streetName?.value,
        city: addressMap.city?.value,
        province: province?.value,
        country: country?.value,
        postalCode: addressMap.postalCode?.value,
        name: id,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err', err);
    }
  };

  return (
    <div>
      <Script url={url} onLoad={handleScriptLoad} />
      <TextField
        variant="outlined"
        value={!inputValue && inputValue !== '' ? searchAddress : inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        id={inputId}
        label={intl.formatMessage({ id: 'common.search_address' })}
        style={{
          width: '100%',
        }}
      />
    </div>
  );
};

export default AddressSearchBar;
