import { connect, ConnectedProps } from 'react-redux';
import { actions as draftApplicationActions } from '../../store/draftApplication';
import { actions as applicationAction } from '../../store/newApplication';

import DraftApplications from './draft-applications.component';

const mapStateToProps = (state: AppStoreType) => ({
  vendorUuid: (state.user as UserBasedStoreType).vendorUuid,
});

const mapDispatchToProps = {
  fetchDraftApplicationList: draftApplicationActions.fetchDraftApplicationList,
  getDraftApplication: applicationAction.getDraftApplication,
  deleteDraftApplications: draftApplicationActions.deleteDraftApplications,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DraftApplicationsConnectedProps = ConnectedProps<typeof connector>;

export default connector(DraftApplications);
