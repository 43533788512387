import React from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const SortingIcon = ({ direction }: { direction: string }) => {
  if (direction == 'desc') return <ArrowDownward />;
  if (direction == 'asc') return <ArrowUpward />;
  return <></>;
};

export default SortingIcon;
