import { Box } from '@material-ui/core';
import React from 'react';

type TabPanelPropsType = {
  children?: React.ReactNode | string;
  value: number;
  index: number;
} & { [key: string]: unknown };

export default function TabPanel(props: TabPanelPropsType) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
