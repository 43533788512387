import React from 'react';

export type SameAsListItem = {
  key: string;
  value: string;
  label: string;
};

export type SameAsContextProps = {
  sameAsList: SameAsListItem[];
  getSameAsList: (currentKey: string) => SameAsListItem[];
  updateSameAsList: (sameAsItem: SameAsListItem) => unknown;
};

const SameAsContext = React.createContext<SameAsContextProps>({
  getSameAsList: () => [],
  sameAsList: [],
  updateSameAsList: () => null,
});

export default SameAsContext;
