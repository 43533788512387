import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  titleH3: {
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: '10px',
  },
  searchInput: {
    width: '100%',
    height: '40px',
    marginBottom: '40px',
  },
  tabRoot: {
    minWidth: '100px',
    height: '40px',
    boxShadow: 'inset 0 -4px 0 -2px #eaeaea',
  },
  resultsFound: {
    color: '#828282',
    marginBottom: '15px',
  },
  resultsFoundOpaque: {
    opacity: '0',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  smallMarginTop: {
    marginTop: theme.spacing(3),
  },
  smallMarginRight: {
    marginRight: theme.spacing(3),
  },
  headerTitle: {
    marginBottom: theme.spacing(3),
  },
  conditionsList: {
    listStyleType: '"*"',
    width: '100%',
    textAlign: 'left',
    paddingLeft: '6px',
    fontSize: '16px',
  },
  conditionName: {
    color: '#828282',
    paddingLeft: '5px',
  },
  listBox: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
  scrollButtons: {
    '& .MuiTabs-scrollButtons': {
      width: '20px',
      '&.Mui-disabled': {
        opacity: '0.3',
      },
    },
  },
  searchListItem: {
    color: 'black',
    fontSize: '14px',
    paddingLeft: '16px',
    '& span': {
      color: '#828282',
      paddingLeft: '5px',
    },
  },
  searchListText: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
