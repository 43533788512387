import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fieldGroup: {
    position: 'relative',
  },
  languageSelector: {
    marginRight: theme.spacing(1.5),
  },
  warningIcon: {
    position: 'absolute',
    right: 0,
    top: '20px',
    color: '#ff9800',
  },
  warningSelectIcon: {
    position: 'absolute',
    right: '30px',
    top: '20px',
    color: '#ff9800',
  },
}));

export default useStyles;
