import React, { useEffect } from 'react';
import { VendorWithUsersListConnectedProps } from './vendor-with-users-list.container';
import { FormProvider, useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/common/Loading';
import EditVendorForm from '../../components/common/edit-vendor-form';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import { useIntl } from 'react-intl';
import { AllCommunityModules, GridApi, IDatasource } from '@ag-grid-community/all-modules';
import { getBrokersUsersList } from '../../apis/broker-rep';
import useGridStyles from '../../components/common/VpDataGrid/styles';
import { BaseDataGrid } from '../../components/common/VpDataGrid';
import { AgGridColumn } from '@ag-grid-community/react';

const VendorWithUsersList = ({
  vendor,
  brokerLogo,
  getVendorById,
}: VendorWithUsersListConnectedProps) => {
  const classes = useStyles();
  const gridClasses = useGridStyles();
  const [gridApi, setGridApi] = React.useState<GridApi | null>(null);

  const intl = useIntl();
  const params = useParams<{ vendorUuid: string }>();
  const formMethods = useForm<VendorCompanyType>({
    defaultValues: {
      vendorCompany: '',
      companyName: '',
      active: false,
      email: '',
      defaultLanguage: 'EN',
      phoneAreaCode: '',
      phone: '',
      phoneExt: '',
      siteUrl: '',
      siteActive: false,
      notificationsEmail: '',
      addressType: 'poBox',
      country: 'CA',
      poBox: '',
      streetNumber: null,
      streetName: '',
      unit: '',
      city: '',
      province: '',
      postalCode: '',
      logoImage: '',
      vendorDocumentsLogo: false,
      companyDocumentsLogo: false,
    },
  });
  const { setValue, watch, getValues } = formMethods;
  const isPoBox = watch('addressType', 'poBox') === 'PO Box';

  useEffect(() => {
    getVendorById(params.vendorUuid);
  }, [params.vendorUuid]);

  useEffect(() => {
    Object.entries(vendor).forEach(([key, value]) =>
      setValue(key as keyof VendorCompanyType, value),
    );
    gridApi?.refreshInfiniteCache();
  }, [vendor]);

  const dataSource: IDatasource = {
    getRows: async (params) => {
      const queryArray: Array<[string, string | number]> = [
        ['companyName', getValues('companyName')],
      ];
      queryArray.push(['limit', params.endRow - params.startRow]);
      queryArray.push(['offset', params.startRow]);
      queryArray.push(['sortingField', params.sortModel[0]?.colId]);
      queryArray.push(['sortingDirection', params.sortModel[0]?.sort]);

      Reflect.ownKeys(params.filterModel).forEach((field) => {
        const filter = encodeURIComponent(params.filterModel[field]?.filter);
        return queryArray.push([field as string, filter]);
      });

      const queryString = queryArray
        .filter((a) => a[1])
        .map((a) => a.join('='))
        .join('&');

      getBrokersUsersList(queryString)
        .then((res) => {
          const users = res.data;
          params.successCallback(users.rows, users.count);
          setTimeout(() => {
            gridApi?.sizeColumnsToFit();
          });
        })
        .catch(() => {
          params.failCallback();
        });
    },
  };

  const isLoading = !vendor.uuid;

  if (isLoading) {
    return (
      <Paper>
        <Loading isOpen />
      </Paper>
    );
  }

  return (
    <Container>
      <Typography variant="h5" noWrap className={classes.mainTitle}>
        {intl.formatMessage({ id: 'common.vendor_information' })}
      </Typography>
      <FormProvider {...formMethods}>
        <EditVendorForm
          isPortalAdmin={false}
          vendorsList={[]}
          isPoBox={isPoBox}
          vendorUuid={params.vendorUuid}
          vendorLogo={vendor.logoImage}
          brokerLogo={brokerLogo}
          currentCompany={params.vendorUuid}
          vendorDocumentsLogo={vendor.vendorDocumentsLogo}
          companyDocumentsLogo={vendor.companyDocumentsLogo}
          siteUrl={vendor.siteUrl}
        />
      </FormProvider>
      <Typography variant="h5" noWrap className={classes.secondTitle}>
        {intl.formatMessage({ id: 'common.vendor_reps' })}
      </Typography>
      <div className={`${gridClasses.alpineThemeContainer} ag-theme-alpine`}>
        <BaseDataGrid
          modules={AllCommunityModules}
          rowModelType="infinite"
          datasource={dataSource}
          onViewportChanged={() => gridApi?.sizeColumnsToFit()}
          onInit={setGridApi}
          pagination
          paginationPageSize={10}
          cacheBlockSize={10}
        >
          <AgGridColumn maxWidth={100} suppressSizeToFit type="userDetailsLink" />
          <AgGridColumn floatingFilter sortable type="firstName" />
          <AgGridColumn floatingFilter sortable type="lastName" />
          <AgGridColumn floatingFilter sortable type="vendorRepRole" />
          <AgGridColumn floatingFilter type="formattedPhone" />
          <AgGridColumn floatingFilter sortable type="email" />
          <AgGridColumn width={150} maxWidth={150} suppressSizeToFit type="active" />
          <AgGridColumn width={200} maxWidth={200} suppressSizeToFit type="userLoginAsButton" />
        </BaseDataGrid>
      </div>
    </Container>
  );
};

export default VendorWithUsersList;
