import VendorWithUsersList from './vendor-with-users-list.container';
import { BROKER_VENDOR_INFO_PAGE } from '../../constants/navigations';

export default [
  {
    path: BROKER_VENDOR_INFO_PAGE.path,
    name: 'vendorUsersList',
    component: VendorWithUsersList,
    exact: true,
  },
];
