import SimpleFloatingTextFilter from './SimpleFloatingTextFilter';
import SimpleFloatingDateFilter from './SimpleFloatingDateFilter';
import StatusFloatingFilter from './StatusFloatingFilter';
import StateFloatingFilter from './StateFloatingFilter';
import BooleanFilter from './BooleanFilter';

export default {
  simpleFloatingTextFilter: SimpleFloatingTextFilter,
  simpleFloatingDateFilter: SimpleFloatingDateFilter,
  statusFloatingFilter: StatusFloatingFilter,
  stateFloatingFilter: StateFloatingFilter,
  booleanFilter: BooleanFilter,
};
