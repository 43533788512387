import { createIntl, createIntlCache } from 'react-intl';
import messageEn from '../locale/en/common.json';
import messageFr from '../locale/fr/common.json';
import { DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST } from '../constants/document';

const cache = createIntlCache();

export const getMessages = (locale?: Languages | DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST) => {
  switch (locale) {
    case 'FR':
      return messageFr;
    case 'EN':
    default:
      return messageEn;
  }
};

export const getIntl = (getState: () => AppStoreType) => {
  const { settings } = getState();
  const userLocale = settings.currentLanguage;
  const messages = getMessages(userLocale);

  return createIntl(
    {
      locale: userLocale,
      messages,
    },
    cache,
  );
};
