import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  chip: {
    fontSize: '14px',
    fontWeight: 'bolder',
    borderRadius: 0,
    padding: 0,
    height: 'auto',
    margin: '0 10px',
    '& .MuiChip-label': {
      padding: '0 5px',
      textTransform: 'uppercase',
      fontSize: '0.8rem',
    },
  },
  completed: {
    color: '#009688',
    backgroundColor: 'rgba(0, 150, 136, 0.2)',
  },
  incomplete: {
    color: '#E46252',
    backgroundColor: '#FFD9D9',
  },
  pending: {
    color: '#212121',
    backgroundColor: '#E8E4E4',
  },
}));

export { useStyles };
