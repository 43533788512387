import React from 'react';
import { DraftApplicationsConnectedProps } from './draft-applications.container';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import ServerSideDataGrid from '../../components/common/VpDataGrid/ServerSideDataGrid';
import { GridApi, IDatasource } from '@ag-grid-community/all-modules';
import { AgGridColumn } from '@ag-grid-community/react';
import AlertModal from '../../components/common/alert-modal';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import useStyles from './styles';

function DraftApplications({
  fetchDraftApplicationList,
  deleteDraftApplications,
}: DraftApplicationsConnectedProps) {
  const intl = useIntl();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<QuoteListItem[]>([]);
  const [gridApi, setGridApi] = React.useState<null | GridApi>(null);
  const classes = useStyles();

  React.useEffect(() => {
    if (gridApi) {
      gridApi.addEventListener('select', () => {
        setSelectedRows([]);
      });
    }
  }, [gridApi]);

  const handleDeleteList = async () => {
    if (selectedRows?.length) {
      const uuidArray = selectedRows.map((q) => q.uuid);
      await deleteDraftApplications(uuidArray);
      gridApi?.refreshInfiniteCache();
      gridApi?.deselectAll();
    }

    setShowDeleteModal(false);
  };

  const datasource: IDatasource = {
    getRows: async (params) => {
      const quotes = await fetchDraftApplicationList(params);

      params.successCallback(quotes?.data ?? [], quotes?.total);
    },
  };

  const checkSelectedNodes = () => {
    const selectedNodes = gridApi?.getSelectedNodes();
    if (selectedNodes) {
      setSelectedRows(selectedNodes?.map((n) => n.data as QuoteListItem));
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <div>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        alignItems="center"
        className={classes.headerSpaceBottom}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Hidden smDown>
            <Typography data-cy="quotes-header" variant="h1" gutterBottom>
              {intl.formatMessage({ id: 'application.draft_applications' })}
            </Typography>
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.buttonsRoot}>
          <Button
            data-cy="delete-quote-btn"
            size="medium"
            variant="contained"
            color="primary"
            className={classes.spaceLeft}
            disabled={!selectedRows.length}
            onClick={() => setShowDeleteModal(true)}
          >
            <FormattedMessage id="grid.delete" />
          </Button>
        </Grid>
      </Grid>

      <ServerSideDataGrid
        onInit={setGridApi}
        api={gridApi}
        onSelectionChanged={checkSelectedNodes}
        datasource={datasource}
        fitType="fit"
        rowSelection="multiple"
      >
        <AgGridColumn type="quote:selectAll" />
        <AgGridColumn floatingFilter type="draftApplication:customer" />
        <AgGridColumn floatingFilter type="draftApplication:idLabelWithLink" />
        <AgGridColumn floatingFilter type="draftApplication:equipment" />
        <AgGridColumn floatingFilter type="draftApplication:equipmentCost" />
        <AgGridColumn floatingFilter type="draftApplication:customerEmail" />
        <AgGridColumn floatingFilter type="draftApplication:phoneNumber" />
        <AgGridColumn floatingFilter type="draftApplication:createdAt" />
      </ServerSideDataGrid>
      <AlertModal
        title={intl.formatMessage({ id: 'grid.delete_question_title' })}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={handleDeleteList}
      />
    </div>
  );
}

export default DraftApplications;
