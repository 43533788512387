export const commonColumnTypes = {
  'common:text': {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
    filterParams: {
      filterOptions: ['contains'],
    },
    floatingFilterComponent: 'simpleFloatingTextFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  'common:cost': {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
    filterParams: {
      filterOptions: ['contains'],
    },
    cellRenderer: 'costRenderer',
    floatingFilterComponent: 'simpleFloatingTextFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  'common:status': {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponent: 'statusFloatingFilter',
    sortable: true,
    filterParams: {
      filterOptions: ['equals'],
    },
    width: 240,
    minWidth: 240,
    cellRenderer: 'statusRenderer',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  'common:state': {
    filter: 'booleanFilter',
    floatingFilter: true,
    floatingFilterComponent: 'stateFloatingFilter',
    sortable: true,
    width: 200,
    minWidth: 200,
    cellRenderer: 'stateRenderer',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  'common:date': {
    cellRenderer: 'dateRenderer',
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    sortable: true,
    floatingFilterComponent: 'simpleFloatingDateFilter',
    filterParams: {
      filterOptions: ['inRange'],
    },
    cellRendererParams: {
      format: 'd MMM yyyy d h:mm a',
    },
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  'common:button': {
    filter: 'agTextColumnFilter',
    field: 'companyName',
    cellRenderer: 'buttonLink',
    sortable: true,
    suppressMenu: true,
    floatingFilter: true,
    filterParams: {
      filterOptions: ['contains'],
    },
    floatingFilterComponent: 'simpleFloatingTextFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  'common:selectAll': {
    suppressMenu: true,
    checkboxSelection: true,
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    headerComponent: 'selectAllHeader',
  },
  'common:email': {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
    filterParams: {
      filterOptions: ['contains'],
    },
    cellRenderer: 'emailRenderer',
    floatingFilterComponent: 'simpleFloatingTextFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
};
