import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { IFilter, IFilterParams } from '@ag-grid-community/all-modules';

const BooleanFilter = forwardRef((props: IFilterParams, ref) => {
  const [state, setState] = useState<null | boolean>(null);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        return [true, false, null].includes(params.data);
      },

      isFilterActive() {
        return typeof state === 'boolean';
      },

      getModel() {
        return { filter: state, type: 'equals' };
      },

      onFloatingFilterChanged(comparator: string, value: boolean | null) {
        setState(value);
      },

      setModel() {
        // do nothing
      },
    } as IFilter;
  });

  useEffect(() => {
    props.filterChangedCallback();
  }, [state, setState]);

  return <></>;
});

BooleanFilter.displayName = 'BooleanFilter';

export default BooleanFilter;
