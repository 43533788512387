import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: QuoteToApplicationStoreTypes = {
  associatedApplicationUuid: null,
  loading: false,
  paymentOption: null,
};

const slice = createSlice({
  name: 'quoteToApplication',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    fetchAssociatedApplicationUuid: (state, action: PayloadAction<string | null>) => {
      state.associatedApplicationUuid = action.payload;
    },
    fetchPaymentOption: (state, action: PayloadAction<PaymentQuoteOptionBE | null>) => {
      state.paymentOption = action.payload;
    },
  },
});

export const actions = slice.actions;

export default slice.reducer;
