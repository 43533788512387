import React, { useState, useEffect } from 'react';
import { IHeaderParams } from '@ag-grid-community/all-modules';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from '../styles';

export default function CustomHeader({ api, displayName }: IHeaderParams) {
  const classes = useStyles();
  const [allSelected, setAllSelected] = useState(false);
  const [partialSelected, setPartialSelected] = useState(false);

  const onChange = () => {
    setPartialSelected(false);
    if (allSelected) {
      api.deselectAll();
      setAllSelected(false);
    } else {
      api.getRenderedNodes().forEach((n) => n.setSelected(true));
      setAllSelected(true);
    }
  };

  const clearSelection = () => {
    api.deselectAll();
    setAllSelected(false);
  };

  const viewportChangedEvent = () => {
    clearSelection();
  };
  const selectionChangedEvent = () => {
    setPartialSelected(false);
    setAllSelected(false);

    if (api.getSelectedRows().length === api.getRenderedNodes().length) return setAllSelected(true);
    if (api.getSelectedRows().length) setPartialSelected(true);
  };

  useEffect(() => {
    api.addEventListener('viewportChanged', viewportChangedEvent);
    api.addEventListener('selectionChanged', selectionChangedEvent);
    return () => {
      document.removeEventListener('viewportChanged', viewportChangedEvent);
      document.removeEventListener('selectionChanged', selectionChangedEvent);
    };
  }, []);

  return (
    <div>
      {displayName}
      <Checkbox
        data-cy="select-all-records"
        className={classes.selectAllCheckboxAlignment}
        indeterminate={partialSelected}
        size="small"
        color="primary"
        checked={allSelected}
        onChange={onChange}
      />
    </div>
  );
}
