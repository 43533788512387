import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  previewWide: {
    '& .MuiDialog-paperWidthMd': {
      maxWidth: '748px',
    },
    '& #logo-container': {
      maxHeight: '150px',
    },
  },
}));

export default useStyles;
