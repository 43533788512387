export const currencyTypes = [
  {
    name: 'CAD',
    language: 'CA',
    prefix: '$',
    extendedPrefix: 'CA',
    translationKey: 'currency.cad',
    thousandSeparator: ' ',
    decimalSeparator: ',',
    decimalScale: 2,
  },
  {
    name: 'USD',
    language: 'EN',
    prefix: '$',
    extendedPrefix: 'US',
    translationKey: 'currency.usd',
    thousandSeparator: ',',
    decimalSeparator: '.',
    decimalScale: 2,
  },
];

export const defaultCurrency = currencyTypes[0];

export enum CURRENCY_TYPE {
  CAD = 'CAD',
  USD = 'USD',
}
