import NewApplication from './new-application.container';
import * as Navigations from '../../constants/navigations';
const { NEW_APPLICATION_PAGE } = Navigations;

export const routeWithAuth = [
  {
    path: NEW_APPLICATION_PAGE.path,
    name: 'NewApplication',
    component: NewApplication,
    exact: false,
  },
];

export const publicRoute = [
  {
    path: NEW_APPLICATION_PAGE.path,
    name: 'NewApplication',
    component: NewApplication,
    exact: false,
  },
];
