import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dropzone: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#008015b8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1301,
  },
  dropzoneIcon: {
    fontSize: '150px',
    color: 'white',
  },
}));

export default useStyles;
