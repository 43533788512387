import React from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TranslateIcon from '@material-ui/icons/Translate';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './styles';
import DropDown from '../dropdown';
import { changeLanguage } from '../../../store/user';

type LanguageSwitcherBarProps = {
  className?: string;
};

export default function LanguageSwitcherBar(props: LanguageSwitcherBarProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userLanguage = useSelector((state: AppStoreType) => state.settings.currentLanguage);
  const showTranslateIcon = useMediaQuery('(max-width:900px)');

  const change = async (code: string) => {
    const languageType = { code };
    await dispatch(changeLanguage(languageType));
  };

  return (
    <React.Fragment>
      <div className={clsx(props.className, classes.languageSwitcher)}>
        <DropDown
          options={[
            {
              label: (
                <>
                  <input
                    type="radio"
                    className={classes.radioElement}
                    defaultChecked={userLanguage === 'EN'}
                  />
                  <label>English</label>
                </>
              ),
              handler: () => change('EN'),
            },
            {
              label: (
                <>
                  <input
                    type="radio"
                    className={classes.radioElement}
                    defaultChecked={userLanguage === 'FR'}
                  />
                  <label>Français</label>
                </>
              ),
              handler: () => change('FR'),
            },
          ]}
        >
          <IconButton data-cy="lang-switcher" className={classes.languageTitle} color="inherit">
            {showTranslateIcon ? (
              <TranslateIcon />
            ) : (
              <>
                {userLanguage}
                <ArrowDropDownIcon />
              </>
            )}
          </IconButton>
        </DropDown>
      </div>
    </React.Fragment>
  );
}
