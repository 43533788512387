import { actions } from './reduces';
import { actions as quoteActions } from '../../store/instantQuote';
import { actions as applicationActions } from '../../store/newApplication';
import { actions as toastActions } from '../../store/toast';
import { Dispatch } from 'react';
import {
  getSelectedPaymentInfo,
  selectPaymentOption as selectPaymentOptionRequest,
} from '../../apis/quote';
import * as applicationApi from '../../apis/application';
import { getIntl } from '../../utils/language';
import { lsSetApplicationAccessToken } from '../../utils/localStorage';

export const selectPaymentOption =
  (paymentOptionUuid: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    dispatch(actions.setLoading(true));
    const intl = getIntl(getState);
    let response;
    try {
      response = await selectPaymentOptionRequest(paymentOptionUuid);
    } catch (e) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.error_selecting_payment_option' }),
      };
      dispatch(toastActions.open(toastOptions));
      dispatch(actions.setLoading(false));

      return e?.response?.data as { code: number };
    }
    if (response.data) {
      dispatch(quoteActions.fetchQuoteSuccess(response.data as GetInstantQuoteResponse));
    }
    dispatch(actions.setLoading(false));

    return { code: null };
  };

export const getPaymentOption =
  (paymentOptionUuid: string) => async (dispatch: Dispatch<unknown>) => {
    dispatch(actions.setLoading(true));
    let paymentOption;
    try {
      paymentOption = (await getSelectedPaymentInfo(paymentOptionUuid)).data;
    } catch {
      dispatch(actions.setLoading(false));
      return false;
    }
    dispatch(actions.fetchPaymentOption(paymentOption));
    dispatch(actions.setLoading(false));

    return true;
  };

export const createApplicationFromQuote =
  (quoteUuid: string, routeParams: PublicRouteParams, language?: string) =>
  async (dispatch: Dispatch<unknown>, getState: () => AppStoreType) => {
    const intl = getIntl(getState);
    dispatch(actions.setLoading(true));
    dispatch(actions.fetchAssociatedApplicationUuid(null));
    let application;
    try {
      application = routeParams.vendorSiteUrl
        ? (await applicationApi.createApplicationFromQuotePublic(quoteUuid, routeParams!, language))
            .data
        : (await applicationApi.createApplicationFromQuote(quoteUuid)).data;
    } catch (error) {
      const toastOptions = {
        toastType: 'error' as const,
        message: intl.formatMessage({ id: 'application.error_fetch' }),
      };
      dispatch(actions.setLoading(false));
      return dispatch(toastActions.open(toastOptions));
    }

    if (application?.application.accessToken && application?.application.uuid) {
      lsSetApplicationAccessToken(
        application.application.uuid,
        application.application.accessToken,
      );
    }

    dispatch(applicationActions.fetchApplication(application));
    const applicationUuid = application?.application?.uuid ?? null;

    dispatch(actions.fetchAssociatedApplicationUuid(applicationUuid));
    const toastOptions = {
      toastType: 'success' as const,
      message: intl.formatMessage({ id: 'application.loaded' }),
    };

    dispatch(toastActions.open(toastOptions));
    dispatch(actions.setLoading(false));
  };
