import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ToastStoreTypes = {
  open: false,
};

const slice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<Omit<ToastStoreTypes, 'open'>>) => {
      Object.assign(state, action.payload, { open: true });
    },
    close: (state) => {
      Object.assign(state, { open: false, toastType: undefined, message: undefined });
    },
  },
});

export const actions = slice.actions;

export default slice.reducer;
