import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import { UseFormMethods } from 'react-hook-form';
import { DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST } from '../../../constants/document';
import VaultMenu from '../VaultMenu';
import { useFormContext } from 'react-hook-form';
import WarningIcon from '@material-ui/icons/Warning';
import { getMessages } from '../../../utils/language';
import { translateLabel } from '../../../utils/translateLabel';

type ApplicationDocumentOverrideFormPaymentFrequencyFieldPropsType = {
  index: string;
  field: ApplicationDocumentOverridableFields;
  originalValue: string | number | null | boolean;
  value: string | null;
  onChangeField: (
    path: string,
    value: string,
    control?: UseFormMethods['control'],
    name?: string,
    isDateField?: boolean,
  ) => void;
  documentLanguage: DOCUMENT_OVERRIDE_FORM_LANGUAGE_LIST;
};

export default function ApplicationDocumentOverrideFormPaymentFrequencyField({
  index,
  field,
  originalValue,
  value,
  onChangeField,
  documentLanguage,
}: ApplicationDocumentOverrideFormPaymentFrequencyFieldPropsType) {
  const classes = useStyles();
  const intl = useIntl();
  const { setValue } = useFormContext();
  const [isDifferent, setIsDifferent] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<string>();

  const titles = useMemo(() => {
    const translationMessagesByFormLanguage = getMessages(documentLanguage);

    return {
      MONTHLY: translationMessagesByFormLanguage['document.payment_monthly'],
      QUARTERLY: translationMessagesByFormLanguage['document.payment_quarterly'],
      SEMI_ANNUALLY: translationMessagesByFormLanguage['document.payment_semi_annually'],
      ANNUALLY: translationMessagesByFormLanguage['document.payment_annually'],
      SEASONALLY: translationMessagesByFormLanguage['document.payment_seasonally'],
    };
  }, [documentLanguage]);

  const availableValues = useMemo(() => {
    return Object.values(titles).map((column) => ({
      label: column,
      value: column,
    }));
  }, [titles]);

  useEffect(() => {
    setIsDifferent(checkDifferent(value, originalValue));

    if (value) {
      const saveValue = (Object.keys(titles) as (keyof typeof titles)[]).find(
        (key) => titles[key] === value,
      );
      setSelectedValue(saveValue);
      setValue(field.path, value);
    } else {
      setValue(field.path, undefined);
    }
  }, []);

  useEffect(() => {
    if (selectedValue) {
      const oldValue = (Object.keys(titles) as (keyof typeof titles)[]).find(
        (key) => key === selectedValue,
      );

      if (oldValue) {
        setValue(field.path, titles[oldValue]);
        onChangeField(field.path, titles[oldValue]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentLanguage]);

  const onChange = useCallback(
    (path: string, newValue: string) => {
      setIsDifferent(checkDifferent(newValue, originalValue));

      const saveValue = (Object.keys(titles) as (keyof typeof titles)[]).find(
        (key) => titles[key] === newValue,
      );
      setSelectedValue(saveValue);

      onChangeField(path, newValue);
      setHelperText('');
    },
    [onChangeField, titles, documentLanguage],
  );

  const checkDifferent = (
    value: string | number | boolean | null,
    originalValue: string | number | boolean | null,
  ) => {
    if (value === '' && originalValue === null) {
      return false;
    }

    return value !== originalValue;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} className={classes.fieldGroup}>
          <VaultMenu
            className={classes.signorSelector}
            id={`${index}`}
            name={field.path}
            label={translateLabel(intl, field.path, field.label)}
            required={field.required}
            value={value ?? undefined}
            defaultValue={value ?? ''}
            options={availableValues}
            onChange={(e) => {
              setValue(field.path, e.target.value);
              onChange(field.path, e.target.value as string);
            }}
          />
          {isDifferent && (
            <Tooltip
              title={`${intl.formatMessage({
                id: 'application.original_value',
              })}: ${originalValue}`}
              placement="top"
            >
              <WarningIcon className={classes.warningSelectIcon} />
            </Tooltip>
          )}
          <span className={classes.helperText}>{helperText}</span>
        </Grid>
      </Grid>
    </>
  );
}
