import React from 'react';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { QuoteRow } from './QuoteRow';
import { useIntl } from 'react-intl';
import CurrencyFormat from '../currency-format';

type QuoteTablePropsType = {
  tagline?: string;
  title?: string;
  terms: number[];
  purchaseOptions: string[];
  quotePaymentOptions: QuotePaymentOption[];
  selectedPaymentOptions: string[];
  skipPayment?: number;
  skipMonth?: number;
  handleQuoteDataUpdate?: (q: QuotePaymentOption) => unknown;
  currency?: string;
};

export default function QuoteTable({
  tagline,
  title,
  terms,
  purchaseOptions,
  quotePaymentOptions,
  selectedPaymentOptions,
  skipPayment,
  skipMonth,
  handleQuoteDataUpdate,
  currency,
}: QuoteTablePropsType) {
  const classes = useStyles();
  const intl = useIntl();

  const calculateActualTerm = React.useCallback(
    (term: number) => {
      return skipMonth ? term - skipMonth : term;
    },
    [skipMonth],
  );

  return (
    <>
      {title && (
        <Typography variant="h2" data-cy="quote-table-title" gutterBottom>
          {title}
        </Typography>
      )}
      {tagline && (
        <Typography variant="subtitle1" data-cy="quote-table-title" gutterBottom align="left">
          {tagline}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {intl.formatMessage({ id: 'quote.purchase_option' })}
              </StyledTableCell>
              {skipMonth && (
                <StyledTableCell>
                  {skipMonth} {intl.formatMessage({ id: 'quote.skip_months' })}
                </StyledTableCell>
              )}
              {terms.map((term) => (
                <StyledTableCell key={term}>{calculateActualTerm(term)}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaseOptions.map((purchaseOption) => (
              <StyledTableRow key={purchaseOption}>
                <StyledTableCell component="th" scope="row">
                  {purchaseOption}
                </StyledTableCell>

                {skipPayment && (
                  <StyledTableCell>
                    <CurrencyFormat value={skipPayment} currencyType={currency} />
                  </StyledTableCell>
                )}

                {terms.map((term) => (
                  <QuoteRow
                    key={term}
                    handlePaymentSelection={handleQuoteDataUpdate}
                    purchaseOptionValue={purchaseOption}
                    termValue={term}
                    quotePaymentOptions={quotePaymentOptions}
                    selectedPaymentOptions={selectedPaymentOptions}
                    currency={currency}
                  />
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
