import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: NewApplicationStoreTypes = {
  application: null,
  loading: false,
  actionInProgress: false,
  loaded: false,
};

const slice = createSlice({
  name: 'newApplication',
  initialState,
  reducers: {
    fetchApplication: (state, action: PayloadAction<NewApplicationStoreTypes['application']>) => {
      state.application = action.payload;
      state.loaded = true;
    },
    addApplicationFiles: (state, action: PayloadAction<FileType[]>) => {
      if (state.application) {
        state.application.attachments = [...state.application.attachments, ...action.payload];
      }
    },
    removeApplicationFile: (state, action: PayloadAction<string>) => {
      if (state.application) {
        state.application.attachments = state.application.attachments.filter(
          (f) => f.uuid !== action.payload,
        );
      }
    },
    resetApplication: (state) => {
      state.application = null;
      state.loaded = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export default slice.reducer;
export const actions = slice.actions;
