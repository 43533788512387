import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid, FormControl } from '@material-ui/core';
import VaultMenu from '../VaultMenu';
import { FILTER_OPTIONS } from '../../../constants/formValues';
import VaultButton from '../vault-button';
import Table from './table';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  formControl: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

type FilterPropsType = {
  filterList: GridViewFilterListType;
  updateFilterList: (filterList: GridViewFilterListType) => void;
  columns: GridViewColumnType[];
};

export default function Filter(props: FilterPropsType) {
  const { columns, filterList, updateFilterList } = props;

  const classes = useStyles();
  const intl = useIntl();
  const [type, setType] = useState<string>('');
  const [filter, setFilter] = useState('');
  const [columnType, setColumnType] = useState<string>('');

  const menuOptions = columns.map((column) => ({
    label: column.label,
    value: column.field,
  }));

  const handleAdd = () => {
    const list = filterList;
    list[columnType] = {
      condition1: {
        type,
        filter,
        filterType: 'text',
      },
    };
    updateFilterList(list);
  };

  return (
    <React.Fragment>
      <Grid className={classes.root} container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.formControl}>
                <VaultMenu
                  id="columnType"
                  name="columnType"
                  options={menuOptions}
                  value={columnType}
                  onChange={(event) => setColumnType(event.target.value as string)}
                  label={intl.formatMessage({ id: 'grid.select_column' })}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.formControl}>
                <VaultMenu
                  id="filterType"
                  name="columnType"
                  options={FILTER_OPTIONS}
                  value={type}
                  onChange={(event) => setType(event.target.value as string)}
                  label={intl.formatMessage({ id: 'grid.filter_type' })}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  id="filter-text"
                  label={intl.formatMessage({ id: 'grid.filter_text' })}
                  value={filter}
                  defaultValue={intl.formatMessage({ id: 'grid.filter_text' })}
                  onChange={(event) => setFilter(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <VaultButton label={intl.formatMessage({ id: 'grid.add' })} onClick={handleAdd} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Table rows={filterList} columns={columns} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
